import * as React from 'react'

import { TableorderCatalogMenu } from '@rocket/types'
import { COLOR, FONT, IColors } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import { StyleSheet, Text, View } from 'react-native'
import { MenuLabel, MenuThumbnail } from '../../..'
import ButtonIcon from '../../../ButtonIcon'
import { useTableorderFile } from '../../../providers'
import { getNumberWithComma } from '../../../utils'
import { baseStyles } from './menuItem'

interface Props {
  menu: TableorderCatalogMenu
  mobileView?: boolean
  onBack?: () => void
}

const MenuDetailInfo = ({ menu, mobileView = false, onBack }: Props) => {
  const { readFileUrl } = useTableorderFile()
  const [previewImageUrl, setPreviewImageUrl] = React.useState<string>()

  React.useEffect(() => {
    const representative = menu.images.filter(({ isRepresentative }) => isRepresentative)
    if (representative.length > 0) {
      readFileUrl(representative[0].path).then(setPreviewImageUrl)
    }
  }, [menu])
  return (
    <View style={{ flexDirection: 'row' }}>
      {!mobileView && previewImageUrl !== undefined && (
        <MenuThumbnail
          uri={previewImageUrl}
          isSoldout={menu.isSoldout}
          width={155}
          height={155}
          containerStyle={{ marginBottom: 16 }}
        />
      )}
      <View style={[baseStyles.container, styles.container, { flex: 1 }]}>
        <View style={baseStyles.itemInfo}>
          <View style={baseStyles.infoContainer}>
            <Text style={[styles.itemName]}>{menu.name}</Text>
            <Text
              style={[baseStyles.itemDetail, styles.itemDetail]}
              ellipsizeMode="tail"
              // numberOfLines={2}
            >
              {menu.description}
            </Text>
            <View style={baseStyles.labels}>
              {menu.labels.map((label, idx) => (
                <MenuLabel key={idx} label={label.name} labelColor={label.color as IColors} />
              ))}
            </View>
            {menu.estimatedCookingMinute && (
              <Text style={[baseStyles.itemTime, { ...FONT.txtXs }]}>
                <XEIcon name={'time-o'} size={12} color={'gray.g450'} /> 예상시간
                {menu.estimatedCookingMinute}분
              </Text>
            )}
          </View>
        </View>
        <View style={[baseStyles.priceContainer, styles.priceContainer]}>
          {menu.isSoldout ? (
            <Text style={{ color: COLOR.main.red }}>품절 상품입니다.</Text>
          ) : (
            <>
              <Text style={[baseStyles.price, styles.price]}>{getNumberWithComma(menu.price)}</Text>
              <Text style={[baseStyles.priceUnit, styles.price]}>원</Text>
            </>
          )}
        </View>
      </View>
      {!mobileView ? (
        <View>
          <ButtonIcon iconName="close" onPress={onBack} />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export default MenuDetailInfo

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottomWidth: 0,
    padding: 0,
  },
  priceContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: 8,
  },
  itemDetail: {
    height: '100%',
    marginBottom: 4,
  },
  itemName: {
    ...FONT.H5Bold,
  },
  price: {
    ...FONT.H7Bold,
  },
})
