import type { IconName } from '@rui/foundations'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import AxisButton from '../Button/AxisButton'

export interface IconButtonGroupProps {
  itemCode: string
  text: string
  iconName: IconName
}

interface Props {
  itemCode: string
  iconMode: boolean
  list: IconButtonGroupProps[]
  onPressButton?: (code: string) => void
  onPressIcon?: (code: string) => void
  onPressAdd?: () => void
}

const IconButtonGroup: React.FC<Props> = ({ itemCode, list, iconMode, onPressButton, onPressIcon, onPressAdd }) => {
  const IconButtonItem = ({ item }: { item: IconButtonGroupProps; idx: number }) => {
    return (
      <View
        style={[
          styles.innerContent,
          {
            backgroundColor: itemCode === item.itemCode ? COLOR.main.blue : COLOR.mono.pale,
          },
        ]}
      >
        <TouchableOpacity
          style={[styles.innerButton, styles.textButton]}
          onPress={() => {
            onPressButton && onPressButton(item.itemCode)
          }}
        >
          <View>
            <Text
              style={[
                {
                  color: itemCode === item.itemCode ? COLOR.mono.white : COLOR.mono.black,
                },
              ]}
            >
              {item.text}
            </Text>
          </View>
        </TouchableOpacity>
        {iconMode && item.iconName && (
          <TouchableOpacity
            style={[
              styles.iconButton,
              {
                backgroundColor: itemCode === item.itemCode ? COLOR.main.navy : COLOR.mono.gray,
              },
            ]}
            onPress={() => onPressIcon && onPressIcon(item.itemCode)}
          >
            <View style={styles.iconStyle}>
              <XEIcon
                name={item.iconName}
                color={itemCode === item.itemCode ? 'mono.white' : 'mono.paleBlack'}
                size={16}
              />
            </View>
          </TouchableOpacity>
        )}
      </View>
    )
  }
  return (
    <View style={styles.buttonGroupContainer}>
      {list.map((item, idx) => (
        <View key={`button-idx-${idx}`} style={styles.buttonGroupStyle}>
          <IconButtonItem item={item} idx={idx} />
        </View>
      ))}
      <View style={styles.buttonGroupStyle}>
        {onPressAdd && (
          <AxisButton
            onPress={onPressAdd}
            isWeb={false}
            wide={true}
            iconName="plus"
            addText="추가"
            style={{ height: 36, paddingHorizontal: 12 }}
          />
        )}
      </View>
    </View>
  )
}

export default IconButtonGroup

const styles = StyleSheet.create({
  buttonGroupContainer: {
    flexDirection: 'row',
  },
  buttonGroupStyle: {
    marginRight: 8,
  },
  innerContent: {
    flexDirection: 'row',
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLOR.mono.pale,
    borderRadius: 4,
    alignSelf: 'flex-start',
  },
  innerButton: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 8,
  },
  textButton: {
    padding: 12,
  },
  iconButton: {
    flex: 1,
    backgroundColor: COLOR.mono.lightGray,
    borderRadius: 4,
    width: 28,
    height: 28,
    marginRight: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconStyle: {},
})
