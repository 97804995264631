import { WorkCardProvider, WorkChannelProvider, WorkProjectProvider } from '@rocket-mono/providers'
import { Skeleton, SkeletonWorkCardNewTabView } from '@rocket/skeleton'
import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

interface Props {
  projectId: string
  channelId: string
  children: JSX.Element | JSX.Element[]
}

const Provider: React.FC<Props> = ({ projectId, channelId, children }) => {
  const { cardId } = useParams()
  const navigate = useNavigate()

  const handleUnauthorized = useCallback(() => {
    navigate('/error/403')
  }, [])

  const handleDeleteWork = useCallback(() => {
    navigate('/')
  }, [])

  const handleDeleteCard = useCallback(() => {
    navigate('/error/404')
  }, [])

  useEffect(() => {
    console.debug('provider-CardLayout', { projectId, channelId })
  }, [])

  return (
    <WorkProjectProvider
      projectId={projectId}
      onUnauthorized={() => {
        console.log('?')
      }}
      onDeleted={handleDeleteWork}
      fallback={<Skeleton path={location.pathname} />}
    >
      <WorkChannelProvider
        channelId={channelId}
        fallback={
          location.pathname.endsWith('/board') ? (
            <SkeletonWorkCardNewTabView isModal />
          ) : (
            <Skeleton path={location.pathname} />
          )
        }
      >
        <WorkCardProvider
          cardId={cardId}
          onUnauthorized={handleUnauthorized}
          onDelete={handleDeleteCard}
          fallback={
            location.pathname.endsWith('/board') ? (
              <SkeletonWorkCardNewTabView isModal />
            ) : (
              <Skeleton path={location.pathname} />
            )
          }
        >
          <>{children}</>
        </WorkCardProvider>
      </WorkChannelProvider>
    </WorkProjectProvider>
  )
}

export default Provider
