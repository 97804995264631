import React, { useCallback, useState } from 'react'
import { PageProvider } from '../providers'
import { NotificationContext } from './context'

import {
  Notification as AstroNotification,
  Project as AstroProject,
  User as AstroUser,
  ProjectWithdrawal,
  User,
} from '@rocket/types'

import { createDenyOrApproveItem, createNotifyItem, createRequestItem } from './legacy/createItem'
import { Cache, Converted, NotificationError, NotificationItem } from './types'

import { useAstro } from '@rocket-mono/providers'
import { ReactiveAstro } from '@rocket/astronaut-rxjs'
import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { AppState, AppStateStatus, Platform } from 'react-native'
import { Subscription } from 'rxjs'
import { createAcceptPayload, createRejectPayload } from './legacy/createPayload'

interface Props {
  children: React.ReactNode
}

export const NotificationProvider: React.FC<Props> = (props: Props) => {
  const { astro, option } = useAstro()
  const { t } = useTranslation()
  const { show: showToastMessage } = useToast()
  const reactiveAstro = new ReactiveAstro({
    notificationBaseUrl: option.notificationBaseUrl,
  })

  const [appState, setAppState] = useState<AppStateStatus>('active')
  const handleAppStateChange = React.useCallback((nextAppState: AppStateStatus) => setAppState(nextAppState), [])
  React.useEffect(() => {
    const subscribe = AppState.addEventListener('change', handleAppStateChange)
    return () => subscribe.remove()
  }, [])

  // FIXME: userId Astro 에서 제공해야 함
  const thumbnailUri = ''

  const [error, setError] = React.useState<NotificationError>('NO_ERROR')

  // projectCache dictionary by projectId
  const projectCache: { [key: string]: Cache<AstroProject> } = {}

  const meCache: Cache<AstroUser> = {
    data: null,
    state: 'idle',
  }

  const userCache: { [key: string]: Cache<User> } = {}

  const [notificationCache, setNotificationCache] = React.useState<{
    [key: string]: Cache<AstroNotification>
  }>({})

  const resolveProject = (projectId: string) => {
    // console.debug(`projectCache[${projectId}]: `, projectCache[projectId])
    if (projectCache[projectId]) {
      switch (projectCache[projectId].state) {
        case 'idle':
        case 'pending':
          return projectCache[projectId].promise
        case 'resolved':
          console.debug('return cached project')
          return Promise.resolve(projectCache[projectId].data)
        case 'rejected':
          return Promise.reject()
      }
    } else {
      // console.debug('no project cache')
      const promise = astro
        .readProject(projectId)
        .then((project) => {
          // console.debug('pullProject: ', { project, projectId })
          projectCache[projectId] = {
            data: project,
            state: 'resolved',
          }
          return project
        })
        .catch(() => {
          projectCache[projectId] = {
            data: null,
            state: 'rejected',
          }
          throw new Error('project not found')
        })

      projectCache[projectId] = {
        data: null,
        state: 'pending',
        promise,
      }
      return promise
    }
  }

  const resolveMe = React.useCallback(() => {
    if (meCache.state === 'pending') {
      return meCache.promise
    } else if (meCache.state === 'resolved') {
      return Promise.resolve(meCache.data)
    } else if (meCache.state === 'rejected') {
      return Promise.reject()
    } else {
      console.debug('no me cache')
      const promise = astro
        .readMe()
        .then((me) => {
          meCache.data = me
          meCache.state = 'resolved'
          return me
        })
        .catch(() => {
          meCache.data = null
          meCache.state = 'rejected'
          throw new Error('me not found')
        })
      meCache.state = 'pending'
      meCache.promise = promise

      return promise
    }
  }, [astro, meCache])

  const resolveUser = (userId: string) => {
    if (userId === '0') return null
    if (userCache[userId]) {
      switch (userCache[userId].state) {
        case 'idle':
        case 'pending':
          return userCache[userId].promise
        case 'resolved':
          console.debug('return cached project')
          return Promise.resolve(userCache[userId].data)
        case 'rejected':
          return Promise.reject()
      }
    } else {
      const promise = astro
        .readUser(userId)
        .then((user) => {
          userCache[userId] = {
            data: user,
            state: 'resolved',
          }
          return user
        })
        .catch(() => {
          userCache[userId] = {
            data: null,
            state: 'rejected',
          }
          throw new Error('user not found')
        })

      userCache[userId] = {
        data: null,
        state: 'pending',
        promise,
      }
      return promise
    }
  }

  const resolveNotification = React.useCallback(
    (notificationId: string) => {
      if (notificationCache[notificationId]) {
        switch (notificationCache[notificationId].state) {
          case 'idle':
          case 'pending':
            return notificationCache[notificationId].promise
          case 'resolved':
            console.debug('return cached notification')
            return Promise.resolve(notificationCache[notificationId].data)
          case 'rejected':
            return Promise.reject()
        }
      } else {
        console.debug('no notification cache')
        const promise = astro
          .readNotification(notificationId)
          .then((notification: AstroNotification) => {
            setNotificationCache((prev) => ({
              ...prev,
              [notificationId]: {
                data: notification,
                state: 'resolved',
              },
            }))
            return notification
          })
          .catch(() => {
            setNotificationCache((prev) => ({
              ...prev,
              [notificationId]: {
                data: null,
                state: 'rejected',
              },
            }))
            throw new Error('notification not found')
          })

        setNotificationCache((prev) => ({
          ...prev,
          [notificationId]: {
            data: null,
            state: 'pending',
            promise,
          },
        }))

        return promise
      }
    },
    [astro, notificationCache],
  )

  const markNotificationAsRead = React.useCallback(
    (notificationId: string) => {
      return astro.markNotificationAsRead(notificationId).then(() => {
        fetchNotifications()
      })
    },
    [astro],
  )

  const updateNoticeMethod = useCallback((eventType: string, payload: ProjectWithdrawal) => {
    if (eventType === 'REQUEST_ATTEND') {
      return astro.updateProjectInvitation(payload)
    } else if (eventType === 'REQUEST_LEAVE') {
      return astro.updateProjectWithdrawal(payload)
    } else if (eventType === 'REQUEST_TRANSFER_OWNERSHIP') {
      return astro.updateOwnershipTransfer(payload)
    } else if (eventType === 'REQUEST_JOIN') {
      return astro.updateCoinRequestByInvitedInfoToken(payload)
    } else {
      return Promise.reject()
    }
  }, [])

  const acceptProjectInvitation = React.useCallback(
    (notificationId: string) => {
      return Promise.all([resolveNotification(notificationId), resolveMe()])
        .then(([notification, me]) => {
          if (!notification || !me) {
            setError('BUSINESS_FAILURE')
            throw new Error('notification or me is null')
          }

          updateNoticeMethod(notification.eventType, createAcceptPayload({ notification, userId: String(me.id) }))
            .then(() => {
              setError('BUSINESS_FAILURE')
              markNotificationAsRead(notification.id)
                .then(() => {
                  showToastMessage({
                    title: t('notification.confirmed'),
                    // animated: true,
                    position: 'TOP_CENTER',
                    type: 'Success',
                  })
                })
                .catch((error) => {
                  console.debug('markNotificationAsRead error: ', error)
                })
            })
            .catch((error) => {
              console.debug('updateProjectInvitation error: ', error)
            })
        })
        .catch((error) => {
          setError('BUSINESS_FAILURE')
          console.debug('error while resolve me and notification: ', error)
        })
    },
    [astro, markNotificationAsRead, resolveMe],
  )

  const rejectProjectInvitation = React.useCallback(
    (notificationId: string) => {
      return Promise.all([resolveNotification(notificationId), resolveMe()])
        .then(([notification, me]) => {
          if (!notification || !me) {
            setError('BUSINESS_FAILURE')
            throw new Error('notification or me is null')
          }

          updateNoticeMethod(notification.eventType, createRejectPayload({ notification, userId: String(me.id) }))
            .then(() => {
              markNotificationAsRead(notification.id)
                .then(() => {
                  showToastMessage({
                    title: t('notification.confirmed'),
                    // animated: true,
                    position: 'TOP_CENTER',
                    type: 'Success',
                  })
                })
                .catch((error) => {
                  throw new Error('markNotificationAsRead error: ' + error)
                })
            })
            .catch((error) => {
              setError('BUSINESS_FAILURE')
              throw new Error('updateProjectInvitation error: ' + error)
            })
        })
        .catch((error) => {
          setError('BUSINESS_FAILURE')
          console.debug('error while resolve me and notification: ', error)
        })
    },
    [astro, markNotificationAsRead, resolveMe],
  )

  const convert = React.useCallback(
    (notification: AstroNotification): Promise<Converted> => {
      // FIXME: notification.addonProject.id가 null인 경우, Sentry 통해 전달해야 함
      if (!notification.addonProject?.id) console.info('addonProject.id is null', { notification })
      return notification.addonProject?.id
        ? Promise.all([resolveProject(notification.addonProject.id), resolveMe(), resolveUser(notification.regUserId)])
            .then(([project, user, regUser]) => {
              if (!project) {
                setError('OPERATION_NOT_FOUND')
                console.debug('project is null', { notification })
                return null
              }

              // console.debug('readProject', { id: project.id, project })

              const userId = `${user?.id ?? ''}`

              const requestUser = project.members.find((aMember) => aMember.userId === notification.regUserId)

              const targetUser = project.members.find((aMember) => aMember.userId === userId)

              if (notification.eventType.includes('REQUEST')) {
                const created = createRequestItem({
                  astro,
                  notification,
                  thumbnailUri,
                  project,
                  userId,
                  requestUser,
                  targetUser,
                  onAccept: () => {
                    console.debug('created::onAccept', { notification })
                    // approve(notification)
                  },
                  onReject: () => {
                    console.debug('created::onReject', { notification })
                    // deny(notification)
                  },
                  onDismiss: () => {
                    console.debug('created::onDismiss', { notification })
                  },
                })
                // console.debug('create REQUEST', {
                //   notification,
                //   created,
                // })
                return created
              }

              if (notification.eventType.includes('REQUEST_JOIN')) {
                const $requestUser = project.members.find((aMember) => {
                  return regUser && aMember.email === regUser.userEmail
                })
                const created = createRequestItem({
                  astro,
                  notification,
                  thumbnailUri,
                  project,
                  userId,
                  requestUser: requestUser ? requestUser : $requestUser,
                  onAccept: () => {
                    console.debug('created::onAccept', { notification })
                    // approve(notification)
                  },
                  onReject: () => {
                    console.debug('created::onReject', { notification })
                    // deny(notification)
                  },
                  onDismiss: () => {
                    console.debug('created::onDismiss', { notification })
                  },
                })
                // console.debug('create REQUEST', {
                //   notification,
                //   created,
                // })
                return created
              }

              if (
                notification.eventType.includes('DENY') ||
                notification.eventType.includes('APPROVE') ||
                notification.eventType.includes('DENY_JOIN') ||
                notification.eventType.includes('APPROVE_JOIN')
              ) {
                // console.debug('create DENY or APPROVE', {
                //   notification,
                // })
                return createDenyOrApproveItem({
                  notification,
                  thumbnailUri,
                  project,
                  requestUser,
                  onDismiss: () => {
                    //
                    markNotificationAsRead(notification.id).catch((error) => {
                      setError('BUSINESS_FAILURE')
                      console.debug('markNotificationAsRead error: ', error)
                    })
                  },
                })
              } else {
                console.debug('create NOTIFY', {
                  notification,
                })
                return createNotifyItem({
                  notification,
                  thumbnailUri: '',
                  project,
                  requestUser,
                  onDismiss: () => {
                    //
                    markNotificationAsRead(notification.id).catch((error) => {
                      console.debug('markNotificationAsRead error: ', error)
                    })
                  },
                })
              }
            })
            .catch((error) => {
              console.debug('readProject error: ', error)
              return null
            })
        : Promise.resolve(null)
    },
    [astro, resolveMe, resolveProject],
  )

  const [notifications, setNotifications] = React.useState<NotificationItem[]>()

  const fetchNotifications = useCallback(() => {
    astro.readUnreadNotifications('').then((received) =>
      Promise.all(
        received
          .filter((o) => o.eventType)
          .map((notification) =>
            convert(notification).then((o) => ({
              ...o,
              isCheck: notification.isCheck,
            })),
          ),
      ).then((list: any) => {
        console.debug('fetchNotifications', list)
        setNotifications(list)
      }),
    )
  }, [convert])

  React.useEffect(() => {
    console.debug('notificationChange$-useEffect', appState)
    let subscription: Subscription | null = null
    if (appState === 'active') {
      if (Platform.OS === 'web') {
        subscription = reactiveAstro.notificationChange$.subscribe({
          next: (received) => {
            console.debug('notificationChange$-next', { received })
            Promise.all(
              received
                .filter((o) => o.eventType)
                .map((notification) =>
                  convert(notification).then((o) => ({
                    ...o,
                    isCheck: notification.isCheck,
                  })),
                ),
            ).then((list: any) => {
              // console.debug('converted', list)
              setNotifications(list)
            })
          },
          error: (err) => console.debug('notificationChange$-error', err),
          complete: () => console.debug('notificationChange$-complete'),
        })
      }

      fetchNotifications()
    }

    return () => {
      if (subscription) subscription.unsubscribe()
    }
  }, [appState])

  const [checked, setChecked] = React.useState<NotificationItem[]>()
  const [unchecked, setUnchecked] = React.useState<NotificationItem[]>()

  React.useEffect(() => {
    console.debug('notifications changed: ', {
      notifications,
    })
    const to = setTimeout(() => {
      setNotifications([])
    }, 1000)
    if (notifications) {
      clearTimeout(to)
      setChecked(notifications.filter((notification) => notification.isCheck))
      setUnchecked(notifications.filter((notification) => !notification.isCheck))
    }

    return () => {
      clearTimeout(to)
    }
  }, [notifications])

  React.useEffect(() => {
    console.debug('notifications changed: ', {
      notifications,
      checked,
      unchecked,
    })
  }, [checked, notifications, unchecked])

  return (
    <PageProvider>
      <NotificationContext.Provider
        value={{
          unchecked,
          checked,
          error,
          markNotificationAsRead,
          acceptProjectInvitation,
          rejectProjectInvitation,
          fetchNotifications,
        }}
      >
        {props.children}
      </NotificationContext.Provider>
    </PageProvider>
  )
}
