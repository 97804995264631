import * as React from 'react'

import { getRealColor } from '@rui/foundations'
import { useWorkBoard } from '../WorkBoardScreen'

import { Card as RCCard } from '../Card'

import { CardRelationResponse, CardType, Legacy, LegacyCardResponse } from '@rocket/types'
import { Draggable, DraggableStateSnapshot, DraggingStyle, NotDraggingStyle } from 'react-beautiful-dnd-grid-support'
import { View } from 'react-native'
import { useLongPress } from 'react-use'
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil'
import { cardListState, dropAnimatingState, placeholderPropsState } from '../../recoil'
import CardLine from '../CardLine'

import { LayoutPayload, replaceItemAtIndex } from '@rocket-mono/libs'
import { useAstro, useSubscription } from '@rocket-mono/providers'
import { useTranslation } from 'react-i18next'

interface Props {
  index: number
  projectId: string
  channelId: string
  channelRoomId: string
  item: CardRelationResponse
  isLast: boolean
  isDragDisabled: boolean
}
const BoardCard: React.FC<Props> = ({ index, projectId, channelId, item, isLast, isDragDisabled }) => {
  const { t } = useTranslation()
  const { astro, astroNav } = useAstro()
  const { filterCode } = useWorkBoard()
  const [isDragable, setIsDragable] = React.useState(false)

  const setCardList = useSetRecoilState(cardListState(channelId))

  const { card } = item

  const [cardHeight, setCardHeight] = React.useState(54)

  const placeholderProps = useRecoilValue(placeholderPropsState)
  const dropAnimating = useRecoilValue(dropAnimatingState)

  const isLastPlaceholder = React.useMemo(() => {
    if (placeholderProps) {
      const { sourceId, destinationId, destinationIndex } = placeholderProps
      if (sourceId === destinationId) {
        return isLast && destinationId === channelId && destinationIndex === index ? true : false
      }
      return isLast && destinationId === channelId && destinationIndex === index + 1 ? true : false
    }

    return false
  }, [placeholderProps])

  const isPlaceholder = React.useMemo(() => {
    if (
      placeholderProps &&
      placeholderProps.destinationId &&
      typeof placeholderProps.destinationIndex !== 'undefined'
    ) {
      const { sourceId, sourceIndex, destinationId, destinationIndex } = placeholderProps

      if (sourceId === channelId && sourceIndex === index) return false

      if (destinationId === sourceId && destinationIndex > sourceIndex) {
        if (destinationId === channelId && destinationIndex + 1 === index) return true
      } else {
        if (destinationId === channelId && destinationIndex === index) return true
      }
    }

    return false
  }, [placeholderProps])

  const onLongPress = () => {
    setIsDragable(true)
  }
  const onDismiss = () => {
    setTimeout(() => {
      setIsDragable(false)
    }, 100)
  }

  const openCard = React.useCallback(async () => {
    const action = 'openCard'
    const { type, no } = card
    const payload = {
      isWindow: false,
      isEdit: false,
      isFold: false,
      cardType: type,
      projectId,
      channelId,
      cardId: String(no),
    }
    astroNav.emit(action, payload)
  }, [card, projectId, channelId])

  const defaultOptions = {
    isPreventDefault: false,
    delay: 1000,
  }
  const longPressEvent = useLongPress(onLongPress, defaultOptions)

  const queryAttr = 'data-rbd-drag-handle-draggable-id'
  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${queryAttr}='${draggableId}']`
    const draggedDOM = document.querySelector(domQuery)

    return draggedDOM as HTMLElement
  }

  const getStyle = useRecoilCallback(
    ({ snapshot }) =>
      (style: DraggingStyle | NotDraggingStyle | undefined, draggableSnapshot: DraggableStateSnapshot) => {
        if (!draggableSnapshot.isDragging) return { ...style, transform: 'none' }
        if (!draggableSnapshot.isDropAnimating) return style
        if (draggableSnapshot.dropAnimation) {
          const ms = 400

          if (placeholderProps && placeholderProps.destinationId && placeholderProps.destinationIndex !== undefined) {
            const { draggableId, sourceId, sourceIndex, destinationId, destinationIndex } = placeholderProps

            const filtered = (item: CardRelationResponse) => {
              const { card } = item
              if (filterCode === 'DONE') {
                return card.todoDoneCount !== undefined &&
                  card.todoTotalCount !== undefined &&
                  card.todoDoneCount > 0 &&
                  card.todoDoneCount === card.todoTotalCount
                  ? true
                  : false
              }

              if (filterCode === 'PROGRESS') {
                return card.todoDoneCount !== undefined &&
                  card.todoTotalCount !== undefined &&
                  card.todoDoneCount > 0 &&
                  card.todoDoneCount === card.todoTotalCount
                  ? false
                  : true
              }
              return true
            }
            const sourceList = snapshot.getPromise(cardListState(sourceId)).then((list) => list.filter(filtered))
            const destinationList = snapshot
              .getPromise(cardListState(destinationId))
              .then((list) => list.filter(filtered))

            const px = destinationIndex < sourceIndex ? `${cardHeight + 8}px` : `-${cardHeight + 8}px`
            if (destinationId === sourceId) {
              sourceList.then((cardList) => {
                const sliceList =
                  destinationIndex < sourceIndex
                    ? cardList.slice(destinationIndex, sourceIndex)
                    : cardList.slice(sourceIndex, destinationIndex + 1)

                sliceList.map((item) => {
                  const draggedDOM = getDraggedDom(item.id)
                  if (draggedDOM) {
                    if (item.id !== draggableId) {
                      draggedDOM.style.transform = `translateY(${px})`
                    }
                    draggedDOM.style.transition = `${ms}ms`
                  }
                })
              })
            } else {
              destinationList.then((cardList) => {
                cardList.slice(destinationIndex).map((item) => {
                  const draggedDOM = getDraggedDom(item.id)
                  if (draggedDOM) {
                    draggedDOM.style.transform = `translateY(${cardHeight + 8}px)`
                    draggedDOM.style.transition = `${ms}ms`
                  }
                })
              })
              sourceList.then((cardList) => {
                cardList.slice(sourceIndex + 1).map((item) => {
                  const draggedDOM = getDraggedDom(item.id)
                  if (draggedDOM) {
                    draggedDOM.style.transform = `translateY(-${cardHeight + 8}px)`
                    draggedDOM.style.transition = `${ms}ms`
                  }
                })
              })
            }
          }

          const { moveTo } = draggableSnapshot.dropAnimation
          const translate = `translate(${moveTo.x}px, ${moveTo.y}px) `

          // console.log('draggableSnapshot', draggableSnapshot, translate)

          return {
            ...style,
            transform: `${translate}`,
            transition: `all ${ms}ms`,
          }
        }
        return style
      },
    [placeholderProps, filterCode, cardHeight],
  )

  const type = React.useMemo(() => {
    if (card.type === 'ATTEND') {
      return 'MEETING'
    } else {
      return card.type
    }
  }, [card.type])

  const cardProps = React.useMemo(() => {
    // 할일
    const total = card.todoTotalCount || 0
    const complete = card.todoDoneCount || 0
    const progress = total > 0 ? Math.round((complete / total) * 100) : 0
    const task = {
      total,
      complete,
      progress,
    }
    // 일정
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    let timeRed: boolean | undefined = undefined
    let timeGray: boolean | undefined = undefined
    const fromPeriodDate = card.fromPeriodDate ? t('format.date.L', { date: new Date(card.fromPeriodDate) }) : undefined
    const toPeriodDate = card.toPeriodDate ? t('format.date.L', { date: new Date(card.toPeriodDate) }) : undefined
    const timeLimit =
      card.fromPeriodDate || card.toPeriodDate ? `${fromPeriodDate || ''} - ${toPeriodDate || ''}` : undefined

    const assign = card?.assignees?.filter(
      (o, index) => card.assignees?.map((o) => o.userId).indexOf(o.userId) === index,
    )

    const isCopy = item.copiedDetail ? item.copiedDetail.originBoardRoomName : undefined
    const isShare = item.sharedDetail
      ? item.sharedDetail.isOrigin
        ? 'origin'
        : item.sharedDetail.originBoardRoomName
      : undefined
    const isOrigin = item.sharedDetail ? item.sharedDetail.isOrigin : undefined

    if (card.fromPeriodDate && card.toPeriodDate) {
      const fromDate = new Date(card.fromPeriodDate)
      const toDate = new Date(card.toPeriodDate)
      const fromDateSevenDaysAgo = new Date(fromDate.getTime() - 7 * 24 * 60 * 60 * 1000)
      timeRed = fromDateSevenDaysAgo.getTime() < today.getTime() && toDate.getTime() > today.getTime()
      timeGray = fromDate.getTime() < today.getTime()
    }

    if (card.fromPeriodDate && typeof timeRed === 'undefined' && typeof timeGray === 'undefined') {
      const fromDate = new Date(card.fromPeriodDate)
      const fromDateSevenDaysAgo = new Date(fromDate.getTime() - 7 * 24 * 60 * 60 * 1000)
      timeRed = fromDateSevenDaysAgo.getTime() < today.getTime() && fromDate.getTime() > today.getTime()
      timeGray = fromDate.getTime() < today.getTime()
    }

    if (card.toPeriodDate && typeof timeRed === 'undefined' && typeof timeGray === 'undefined') {
      const toDate = new Date(card.toPeriodDate)
      const toDateSevenDaysAgo = new Date(toDate.getTime() - 7 * 24 * 60 * 60 * 1000)
      timeRed = toDateSevenDaysAgo.getTime() < today.getTime() && toDate.getTime() > today.getTime()
      timeGray = toDate.getTime() < today.getTime()
    }

    return {
      isSkeleton: card.isSkeleton,
      timeRed,
      timeGray,
      item: {
        type,
        title: card.title,
        task: card.type === 'MISSION' && total > 0 ? task : undefined,
        timeLimit,
        option: {
          location: !!card.location,
          assign: assign && assign.length > 0 ? String(assign.length) : undefined,
          gathering:
            card.type === 'COLLECTION' &&
            typeof card.gatheringTotalCount !== 'undefined' &&
            card.gatheringTotalCount > 0
              ? String(card.gatheringTotalCount)
              : undefined,
        },
        closed: card.isDone,
        isCopy,
        isShare,
        isOrigin,
      },
    }
  }, [item, card])

  const onLayout = React.useCallback((payload: LayoutPayload) => {
    setCardHeight(payload.height)
  }, [])

  const [height, setHeight] = React.useState<number>()
  const [opacity, setOpacity] = React.useState(1)

  useSubscription([`/subscribe/card/${card.no}/done`], () => {
    if (filterCode === 'PROGRESS') {
      setOpacity(0)
      setTimeout(() => setHeight(0), 1000)
    }
  })

  const convertLegacyCard = React.useCallback((card: Legacy.LegacyCardNotice): LegacyCardResponse => {
    const { type, no, title, isDone, regDate, modDate, updateUserNo, updateUserName, isDel, projectNo } = card
    return {
      type: type as CardType,
      no,
      title,
      isDone,
      regDate: new Date(regDate),
      modDate: new Date(modDate),
      updateUserNo: updateUserNo || -1,
      updateUserName: updateUserName || '',
      isDel,
      projectNo,
      userNo: -1,
      timezone: 'Asia/Seoul',
    }
  }, [])

  useSubscription([`/subscribe/card/${card.no}/update`, `/subscribe/card/${card.no}/update/`], () => {
    Promise.all([astro.readCard(String(card.no)), astro.readAssignee('card', String(card.no))]).then(
      ([cardItem, assignees]) => {
        if (cardItem.type === 'MISSION') {
          astro.readTodoList('card', String(card.no)).then((list) => {
            const todoTotalCount = list.length
            const todoDoneCount = list.filter((o) => o.isDone).length
            setCardList((cards) => {
              const index = cards.findIndex((o) => o.cardId === card.no)
              if (index !== -1) {
                const item = cards[index]
                return replaceItemAtIndex(cards, index, {
                  ...item,
                  card: {
                    ...convertLegacyCard(cardItem),
                    assignees,
                    todoTotalCount,
                    todoDoneCount,
                    isSkeleton: false,
                  },
                })
              }
              return cards
            })
          })
        } else if (card.type === 'COLLECTION') {
          let count = 0
          astro
            .readCardCollectionList(String(card.no))
            .then((gatherings) =>
              Promise.all(
                gatherings.map((gathering) =>
                  astro.readCardCollectionPieceListGroup(gathering.id).then((groups) =>
                    Promise.all(
                      groups.map((group) =>
                        astro.readCardCollectionPieceList(gathering.id, group.id).then((pieces) => {
                          count += pieces.length
                        }),
                      ),
                    ),
                  ),
                ),
              ),
            )
            .then(() => {
              setCardList((cards) => {
                const index = cards.findIndex((o) => o.cardId === card.no)
                if (index !== -1) {
                  const item = cards[index]
                  return replaceItemAtIndex(cards, index, {
                    ...item,
                    card: {
                      ...convertLegacyCard(cardItem),
                      assignees,
                      gatheringTotalCount: count,
                      isSkeleton: false,
                    },
                  })
                }
                return cards
              })
            })
            .catch(() => {
              console.log('readCardCollectionList error')
            })
        } else {
          setCardList((cards) => {
            const index = cards.findIndex((o) => o.cardId === card.no)
            if (index !== -1) {
              const item = cards[index]
              return replaceItemAtIndex(cards, index, {
                ...item,
                card: {
                  ...convertLegacyCard(cardItem),
                  assignees,
                  isSkeleton: false,
                },
              })
            }
            return cards
          })
        }
      },
    )
  })

  return (
    <div
      style={{
        height: height !== undefined ? height : cardHeight + 8,
        transition: 'all 1s',
      }}
    >
      {isPlaceholder ? <CardLine /> : <View style={{ height: 8 }} />}
      <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <>
            <div
              ref={provided.innerRef}
              {...longPressEvent}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...getStyle(provided.draggableProps.style, snapshot),
                userSelect: 'none',
                left: 'auto',
                top: 'auto',
              }}
            >
              <div
                style={
                  isDragable
                    ? {
                        backgroundColor: '#EDF2F9',
                        borderColor: '#D0DCEB',
                        borderWidth: 1,
                        borderRadius: 8,
                      }
                    : {}
                }
              >
                <div
                  style={{
                    position: 'absolute',
                    width: 246,
                    height: cardHeight,
                    opacity: dropAnimating && dropAnimating.cardId === item.id ? 1 : 0,
                    borderRadius: 8,
                    boxShadow: `0 0 6px 1px ${getRealColor('main.blue')}`,
                    transition: 'all 1s',
                  }}
                />
                <div
                  onMouseUp={onDismiss}
                  onMouseMove={onDismiss}
                  onTouchMove={onDismiss}
                  style={{
                    transform: snapshot.isDragging || isDragable ? 'rotate(-0.01turn)' : undefined,
                    transition: 'all 1s',
                    opacity,
                  }}
                >
                  <RCCard onPress={openCard} onLayout={onLayout} {...cardProps} />
                </div>
              </div>
            </div>
            {snapshot.isDragging && (
              <div
                style={{
                  userSelect: 'none',
                  backgroundColor: '#EDF2F9',
                  borderColor: '#D0DCEB',
                  borderWidth: 1,
                  borderRadius: 8,
                  width: 246,
                  height: cardHeight,
                }}
              />
            )}
          </>
        )}
      </Draggable>
      {isLastPlaceholder && <CardLine />}
    </div>
  )
}

export default BoardCard
