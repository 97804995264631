import type { TableorderTable, TableorderTableCreatePayload, TableorderTableUpdatePayload } from '@rocket/types'
import React, { useEffect } from 'react'

import { useTableorderShop } from '../ShopProvider'
import Context from './context'
import { TableInfoType, type ProviderProps } from './types'

const Provider = ({ astro, subscribe, children }: ProviderProps) => {
  const { shopId, shopLayoutId, shopLayoutList } = useTableorderShop()
  const [currentTable, setCurrentTable] = React.useState<TableorderTable>()
  const [tableListData, setTableList] = React.useState<TableorderTable[]>([])
  const [tableInfo, setTableInfo] = React.useState<TableInfoType>()

  const tableList = React.useMemo(
    () => tableListData.filter((o) => !o.isDeleted && o.shopLayoutId === shopLayoutId),
    [tableListData, shopLayoutId],
  )

  const openTable = React.useCallback((tableInfo: TableInfoType) => {
    setTableInfo(tableInfo)
  }, [])

  const closeTable = React.useCallback(() => {
    setCurrentTable(undefined)
    setTableInfo(undefined)
  }, [])

  const onChangeTableList = React.useCallback((list: TableorderTable[]) => {
    setTableList(list)
  }, [])

  const updateTableList = React.useCallback((table: TableorderTable) => {
    if (tableInfo && table.id === tableInfo.id) {
      setCurrentTable(table.isDeleted ? undefined : table)
    }
    setTableList((prev) => [...prev.filter((o) => o.id !== table.id), table])
  }, [])
  const moveTable = React.useCallback(
    (id: string, locationX: number, locationY: number) => {
      astro.moveTable(id, { locationX, locationY }).then(updateTableList)
    },
    [tableList],
  )
  const cleanupTable = React.useCallback(
    (id: string) =>
      astro.cleanupTable(id).then(() => {
        astro.readTable(id).then((table) => {
          if (table !== undefined) updateTableList(table)
        })
      }),
    [tableList],
  )

  const readTable = React.useCallback((id: string) => astro.readTable(id), [])

  const createTable = React.useCallback((payload: TableorderTableCreatePayload) => {
    return astro.createTable(payload).then((table) => {
      updateTableList(table)
    })
  }, [])
  const updateTable = React.useCallback(
    (id: string, payload: Pick<TableorderTableUpdatePayload, 'name' | 'allowableHeadcount'>) => {
      const table = tableList.find((o) => o.id === id)
      if (table === undefined) return Promise.reject('')
      return astro.updateTable(id, { ...table, ...payload }).then((table) => {
        updateTableList(table)
      })
    },
    [tableList],
  )
  const deleteTable = React.useCallback((id: string) => {
    return astro.deleteTable(id).then((table) => {
      updateTableList(table)
    })
  }, [])

  // React.useEffect(() => {
  //   if (shopLayoutId)
  //     astro
  //       .readTableList(shopLayoutId)
  //       .then((list) => {
  //         if (list.length === 0) {
  //           return Promise.all(
  //             Array.from({ length: 6 }, (_, idx) => {
  //               const name = `테이블${idx + 1}`
  //               const allowableHeadcount = 4
  //               const locationX = idx % 3
  //               const locationY = idx > 2 ? 1 : 0
  //               const payload = {
  //                 shopId,
  //                 shopLayoutId,
  //                 name,
  //                 allowableHeadcount,
  //                 locationX,
  //                 locationY,
  //               }

  //               return astro.createTable(payload).then((res) => {
  //                 console.log('createTable', res)
  //                 return res
  //               })
  //             }),
  //           )
  //         }
  //         return list
  //       })
  //       // .then(setTableList)
  // }, [shopLayoutId])

  React.useEffect(() => {
    if (tableInfo && tableInfo.id) astro.readTable(tableInfo.id).then(setCurrentTable)
    else setCurrentTable(undefined)
  }, [tableInfo])

  useEffect(() => {
    console.log('shopLayoutList', shopLayoutList)
    if (shopLayoutList) {
      Promise.all(
        shopLayoutList.map(({ id: shopLayoutId }) => {
          return astro.readTableList(shopLayoutId).then((list) => {
            if (list.length === 0) {
              return Promise.all(
                Array.from({ length: 6 }, (_, idx) => {
                  const name = `테이블${idx + 1}`
                  const allowableHeadcount = 4
                  const locationX = idx % 3
                  const locationY = idx > 2 ? 1 : 0
                  const payload = {
                    shopId,
                    shopLayoutId,
                    name,
                    allowableHeadcount,
                    locationX,
                    locationY,
                  }

                  return astro.createTable(payload).then((res) => {
                    return res
                  })
                }),
              )
            }
            return list
          })
        }),
      )
        .then((list) => {
          return list.flatMap((o) => o)
        })
        .then((list) => {
          setTableList(list)
        })
    }
  }, [shopLayoutList])

  subscribe([`/subscribe/table-order/table/updated`], ({ body }: any) => {
    console.log('subscribe-/subscribe/table-order/table/updated', body)
    const tableId = body
    astro.readTable(tableId).then((table) => {
      if (table !== undefined) updateTableList(table)
    })
  })

  return (
    <Context.Provider
      value={{
        currentTable,
        tableList,
        moveTable,
        onChangeTableList,
        tableInfo,
        openTable,
        closeTable,
        createTable,
        readTable,
        updateTable,
        deleteTable,
        cleanupTable,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
