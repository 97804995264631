import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Dimensions, Image, Platform, Pressable, StyleSheet, Text, View } from 'react-native'

export interface CardButtonVerticalProps {
  title: string
  info: string
  uri: string | JSX.Element
  ctaButton: boolean
  ctaText: string
  onPress: () => void
}

interface Props {
  list: CardButtonVerticalProps[]
}

const Item = ({ item, mobileView }: { item: CardButtonVerticalProps; mobileView: boolean }) => {
  const [isHover, setIsHover] = React.useState(false)
  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        mobileView
          ? {
              flexDirection: 'column',
              alignItems: 'center',
              width: 290,
              height: 'auto',
              marginBottom: 16,
            }
          : { flex: 1, marginBottom: 0 },
      ]}
      onPress={item.onPress}
    >
      <View style={styles.thumbBox}>
        {typeof item.uri === 'string' ? (
          <Image source={{ uri: item.uri }} style={{ width: '100%', height: 180, resizeMode: 'cover' }} />
        ) : (
          <>{item.uri}</>
        )}
      </View>
      <View style={styles.infoBox}>
        <View>
          <Text style={styles.title}>{item.title}</Text>
          <Text style={styles.info}>{item.info}</Text>
        </View>
        {item.ctaButton && (
          <View>
            <Pressable style={styles.ctaButton} onPress={item.onPress}>
              <Text style={styles.buttonText}>{item.ctaText}</Text>
              <View style={[styles.arrowIcon, isHover && styles.arrowIconHover]}>
                <XEIcon name={'arrow-right'} size={16} color={'gray.g450'} style={{ marginTop: -2 }} />
              </View>
            </Pressable>
          </View>
        )}
      </View>
    </Pressable>
  )
}

const CardButtonVertical = ({ list }: Props) => {
  const chartWidth = Dimensions.get('window').width
  const mobileView = 765 > chartWidth
  return (
    <View
      style={[
        mobileView
          ? {}
          : {
              flex: 1,
              flexDirection: 'row',
              height: 328,
              ...Platform.select({
                web: {
                  gap: 16,
                },
              }),
            },
      ]}
    >
      {list.map((item, idx) => (
        <Item item={item} key={`templet-button${idx}`} mobileView={mobileView} />
      ))}
    </View>
  )
}

export default CardButtonVertical

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g100,
    borderRadius: 8,
    ...getBoxShadow(0, 0, 0, 0.1, 5, 0, 2, 0),
  },
  thumbBox: {
    overflow: 'hidden',
    alignItems: 'center',
    backgroundColor: COLOR.mono.white,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  infoBox: {
    flex: 3,
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingVertical: 16,
    paddingHorizontal: 20,
    width: '100%',
  },
  title: {
    ...FONT.H7Bold,
    marginBottom: 8,
  },
  info: {
    ...FONT.txtXs,
    color: COLOR.gray.g700,
  },
  buttonText: {
    ...FONT.txtSmMedium,
    color: COLOR.primary.blue500,
  },
  ctaButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  arrowIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.gray.g050,
    borderRadius: 50,
    width: 28,
    height: 28,
    marginLeft: 8,
  },
  arrowIconHover: {
    backgroundColor: COLOR.gray.g150,
  },
})
