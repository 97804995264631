import { useToast } from '@rui/atoms'
import { NoContentsIcon } from '@rui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, View } from 'react-native'
import { useNotification } from '../Notification'
import NotificationHeader from './NotificationHeader'
import type { NotificationItemProps } from './NotificationItem'
import NotificationItem from './NotificationItem'

type NotificationListItem = Omit<NotificationItemProps, 'onAccept' | 'onReject' | 'onDismiss'>

interface Props {
  titleVisible?: boolean
  list?: NotificationListItem[]
}

const NotificationList: React.FC<Props> = ({ list, titleVisible = true }) => {
  const { t } = useTranslation()
  const { show: showToastMessage } = useToast()
  const { acceptProjectInvitation, rejectProjectInvitation, markNotificationAsRead } = useNotification()

  return (
    <View style={{ flex: 1 }}>
      <NotificationHeader
        titleVisible={titleVisible}
        onPressAllChecked={function () {
          const filtered =
            list?.filter((o) => {
              if (o.type.startsWith('REQUEST_') && !o.isExpired) {
                return false
              }

              return true
            }) ?? []

          console.log('모두 읽음', filtered)
          if (filtered.length > 0) {
            Promise.all(filtered.map((o) => markNotificationAsRead(o.id))).then(() => {
              showToastMessage({
                title: t('notification.confirmed'),
                // animated: true,
                position: 'TOP_CENTER',
                type: 'Success',
              })
            })
          }
        }}
        onPressButtonSearch={function () {
          console.log('검색')
        }}
      />

      {list && list.length > 0 ? (
        <ScrollView style={{ paddingHorizontal: 12 }}>
          {list.map((item, idx) => {
            return (
              <NotificationItem
                id={item.id}
                profileUrl={item.profileUrl}
                profileAlt={item.profileAlt}
                type={item.type}
                user={item.user}
                work={item.work}
                time={item.time}
                newUnread={item.newUnread}
                period={item.period}
                isExpired={item.isExpired}
                key={`notification-list${idx}`}
                onDismiss={() => {
                  markNotificationAsRead(item.id).then(() => {
                    showToastMessage({
                      title: t('notification.confirmed'),
                      // animated: true,
                      position: 'TOP_CENTER',
                      type: 'Success',
                    })
                    console.debug('item:onDismiss')
                  })
                }}
                onAccept={() => {
                  // markNotificationAsRead(item.id).then(() => {
                  // })
                  acceptProjectInvitation(item.id)
                  console.debug('item:onAccept')
                }}
                onReject={() => {
                  // markNotificationAsRead(item.id).then(() => {

                  // })
                  rejectProjectInvitation(item.id)
                  console.debug('item:onReject')
                }}
              />
            )
          })}
        </ScrollView>
      ) : list && list.length === 0 ? (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <NoContentsIcon
            type={'notification'}
            textSize={'medium'}
            text={''}
            subText={t('nocontentsicon.notifications.subtext')}
            iconWidth={75}
          />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export default NotificationList
export type { NotificationItemProps }
