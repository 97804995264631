import * as React from 'react'

import { useRecoilCallback, useSetRecoilState } from 'recoil'
import { cardListState, dropAnimatingState, placeholderPropsState } from '../recoil'

import { DragDropContext, DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd-grid-support'

import { View } from '@rocket-mono/foundations'
import { CardRelationResponse, Channel, ProjectElement } from '@rocket/types'
import { Button, Text, useToast } from '@rui/atoms'
import {
  addItemAtIndex,
  changeItemAtIndex,
  COLOR,
  getFontStyle,
  getRealColor,
  removeItemAtIndex,
} from '@rui/foundations'
import { NoContentsIcon } from '@rui/icons'
import Dimmed from './Dimmed'
import ModalBottomAlert from './ModalBottomAlert'
import { useWorkBoard } from './WorkBoardScreen'

import {
  useAstro,
  useCurrentUser,
  useModalDialog,
  useSubscription,
  useWork,
  useWorkProject,
} from '@rocket-mono/providers'
import { useTranslation } from 'react-i18next'
import { Platform, ScrollView } from 'react-native'
import Board from '../components/Board'
import { getDraggedDom } from '../utils'
import ModalView, { ModalState } from './ModalView'

const PAGE_SIZE = 20

type CardDropType = {
  cardId: string
  fromChannelId: string
  toChannelId: string
  idx: number
}

interface Props {
  handlePressBoardRole?: (selectedBoardId: string) => void
}
const BoardView: React.FC<Props> = ({ handlePressBoardRole }) => {
  const { t } = useTranslation()
  const { astro, astroNav } = useAstro()

  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const { show: showToastMessage } = useToast()

  const { guestList } = useWork()

  const { currentUser } = useCurrentUser()
  const { currentProject, projectId, currentProjectMember } = useWorkProject()
  const { isArchive, filterCode } = useWorkBoard()
  const [unarchiveList, setChannelList] = React.useState<Channel[]>()
  const [archiveList, setArchiveList] = React.useState<Channel[]>()

  const channelList = React.useMemo(() => {
    return isArchive ? archiveList : unarchiveList
  }, [isArchive, unarchiveList, archiveList])
  const [projectElementList, setProjectElementList] = React.useState<ProjectElement[]>()

  const [isAlert, setIsAlert] = React.useState(false)
  const [modalState, setModalState] = React.useState<ModalState>('CLOSE')

  const extraCard = React.useCallback(async (item: CardRelationResponse) => {
    const { card, cardId } = item
    const assignees = await astro.readAssignee('card', String(cardId))
    if (card.type === 'MISSION') {
      const { todoTotalCount, todoDoneCount } = await astro.readTodoList('card', String(cardId)).then((list) => {
        return {
          todoTotalCount: list.length,
          todoDoneCount: list.filter((o) => o.isDone).length,
        }
      })
      return {
        ...item,
        card: {
          ...card,
          isSkeleton: false,
          assignees,
          todoTotalCount,
          todoDoneCount,
        },
      }
    } else if (card.type === 'COLLECTION') {
      let gatheringTotalCount = 0
      const gatherings = await astro.readCardCollectionList(String(cardId))

      for (const gathering of gatherings) {
        const groupList = await astro.readCardCollectionPieceListGroup(gathering.id)
        for (const group of groupList) {
          const pieceList = await astro.readCardCollectionPieceList(gathering.id, group.id)
          gatheringTotalCount = gatheringTotalCount + pieceList.length
        }
      }

      return {
        ...item,
        card: {
          ...card,
          isSkeleton: false,
          assignees,
          gatheringTotalCount,
        },
      }
    } else {
      return {
        ...item,
        card: {
          ...card,
          isSkeleton: false,
          assignees,
        },
      }
    }
  }, [])

  const resetCardList = useRecoilCallback(
    ({ set }) =>
      () => {
        channelList?.map((channel) => {
          astro
            .readCardRelationListPage({ boardId: Number(channel.id), page: 0 })
            .then((res) => res.content.filter((o) => o.card.isDel === 'N'))
            .then((list) => {
              set(cardListState(channel.id), (cards) => {
                return list.map((o) => {
                  const card = cards.find((c) => c.cardId === o.cardId)
                  if (card) {
                    const { assignees, todoTotalCount, todoDoneCount, gatheringTotalCount } = card.card
                    return {
                      ...o,
                      assignees,
                      todoTotalCount,
                      todoDoneCount,
                      gatheringTotalCount,
                      isSkeleton: false,
                    }
                  } else {
                    return { ...o, isSkeleton: true }
                  }
                })
              })
              return Promise.all(
                list.map((o) =>
                  extraCard({
                    ...o,
                    card: {
                      ...o.card,
                      isSkeleton: false,
                    },
                  }),
                ),
              )
            })
            .then((list) => {
              set(cardListState(channel.id), list)

              list.map((card) => {
                const draggedDOM = getDraggedDom(card.id)
                if (draggedDOM) {
                  draggedDOM.style.transition = ``
                  draggedDOM.style.transform = ``
                }
              })
            })
        })
      },
    [channelList],
  )

  React.useEffect(() => {
    console.log('resetChannelList', { archiveList, unarchiveList, projectElementList })
  }, [archiveList, unarchiveList, projectElementList])

  const resetChannelList = React.useCallback(() => {
    console.log('resetChannelList', projectId)
    if (projectId) {
      astro.readChannelList({ type: 'G', isArchive: false, projectId }).then(setChannelList)
      astro.readChannelList({ type: 'G', isArchive: true, projectId }).then(setArchiveList)
      astro.readProjectElements(projectId).then(setProjectElementList)
    } else {
      const projectLevel = 0
      astro.readChannelList({ type: 'G', isArchive: false, projectLevel }).then(setChannelList)
      astro.readChannelList({ type: 'G', isArchive: true, projectLevel }).then(setArchiveList)
      if (guestList) setProjectElementList(guestList.flatMap((o) => o.elements))
      else {
        astro.readProjectList(false, 0).then((res) => {
          setProjectElementList(res.flatMap((o) => o.elements))
        })
      }
    }
  }, [projectId])

  React.useEffect(() => {
    const event = (message: MessageEvent) => {
      const { action } = message.data
      if (action === 'hideLoading') {
        resetCardList()
      }
    }
    if (Platform.OS === 'web') window.addEventListener('message', event)
    return () => {
      if (Platform.OS === 'web') window.removeEventListener('message', event)
    }
  }, [channelList])

  const setDropAnimating = useSetRecoilState(dropAnimatingState)

  const setPlaceholderProps = useSetRecoilState(placeholderPropsState)

  const [beforeRect, setBeforeRect] = React.useState<DOMRect>()

  const onBeforeCapture = React.useCallback((capture) => {
    console.log('onBeforeCapture', capture)
    const draggableId = capture.draggableId

    const draggedDom = getDraggedDom(draggableId)
    setBeforeRect(draggedDom.getBoundingClientRect())
  }, [])

  const onDragStart = React.useCallback((initial: DragStart) => {
    console.log('onDragStart', initial)
    const draggableId = initial.draggableId
    const sourceId = initial.source.droppableId
    const sourceIndex = initial.source.index

    setPlaceholderProps({
      draggableId,
      sourceId,
      sourceIndex,
    })
  }, [])

  const onDragUpdate = React.useCallback((update: DragUpdate) => {
    console.log('onDragUpdate', update)
    const draggableId = update.draggableId
    const sourceId = update.source.droppableId
    const sourceIndex = update.source.index
    const destinationId = update.destination ? update.destination.droppableId : undefined
    const destinationIndex = update.destination ? update.destination.index : undefined
    setPlaceholderProps({
      draggableId,
      sourceId,
      sourceIndex,
      destinationId,
      destinationIndex,
    })
  }, [])

  // const showCopyMessage = React.useCallback((dropType: CardDropType) => {
  //   const title = '현재 카드 상태(체크,응답 등) 그대로 복사하시겠습니까?'

  //   const list = [
  //     {
  //       name: '내용유지',
  //       action: () => {
  //         const { cardId, idx, toChannelId } = dropType
  //         astro.copyCard({ cardId, idx, deep: true, toChannelId }).finally(() => {
  //           hideDialogMessage()
  //         })
  //       },
  //     },
  //     {
  //       name: '폼만복사',
  //       action: () => {
  //         const { cardId, idx, toChannelId } = dropType
  //         astro.copyCard({ cardId, idx, deep: false, toChannelId }).finally(() => {
  //           hideDialogMessage()
  //         })
  //       },
  //     },
  //   ]
  //   showDialogMessage({
  //     type: 'BOTTOM',
  //     title,
  //     list,
  //     cancelText: '뒤로',
  //     onCancel: () => {
  //       hideDialogMessage()
  //       showDropMessage(dropType)
  //     },
  //   })
  // }, [])

  const readCardListPage = React.useCallback(
    (channelId: string, page: number) => {
      const promises: Promise<any>[] = []
      for (let i = 0; i <= page; i++) {
        promises.push(astro.readCardRelationListPage({ boardId: Number(channelId), page: i }))
      }
      return Promise.all(promises).then((res) => {
        return res.reduce((acc, res) => {
          return acc.concat(res.content.filter((o) => o.card?.isDel === 'N'))
        }, [])
      })
    },
    [astro],
  )

  const showDropMessage = useRecoilCallback(
    ({ snapshot, set }) =>
      (
        placeholderProps: {
          draggableId: string
          sourceId: string
          sourceIndex: number
          destinationId?: string
          destinationIndex?: number
        },
        dropType: CardDropType,
      ) => {
        // const title = '선택된 카드를 해당 보드로 이동/복사/공유를 할 수 있습니다.'
        const title = t('common.toast.cardmove') // '선택된 카드를 해당 보드로 이동/공유를 할 수 있습니다.'

        const list = [
          // {
          //   name: t('addcontainer.copy'), // '복사',
          //   action: () => {
          //     hideDialogMessage()
          //     showCopyMessage(dropType)
          //   },
          // },
          {
            name: t('addcontainer.move'), // '이동',
            action: () => {
              setBeforeRect(undefined)
              astro.moveCard({ ...dropType, deep: true }).finally(() => {
                showToastMessage({
                  type: 'Success',
                  title: t('common.toast.moved'),
                  position: 'BOTTOM_CENTER',
                })
                hideDialogMessage()
              })
            },
          },
        ]
        if (currentProject?.type.code !== 'WFP') {
          list.push({
            name: t('addcontainer.coop'), // '공유',
            action: () => {
              setBeforeRect(undefined)
              astro.shareCard(dropType).finally(() => {
                set(cardListState(dropType.fromChannelId), (cards) => {
                  const page = Math.ceil(cards.length / PAGE_SIZE)
                  readCardListPage(dropType.fromChannelId, page)
                    .then((list) => {
                      return Promise.all(
                        list.map((o: CardRelationResponse) =>
                          extraCard({ ...o, card: { ...o.card, isSkeleton: false } }),
                        ),
                      )
                    })
                    .then((list) => set(cardListState(dropType.fromChannelId), list))
                  return cards
                })
                showToastMessage({
                  type: 'Success',
                  title: t('common.toast.shared'),
                  position: 'BOTTOM_CENTER',
                })
                hideDialogMessage()
              })
            },
          })
        }
        if (currentProject?.type.code === 'DSP') {
          list.push({
            name: t('addcontainer.copy'), // '폼복사',
            action: () => {
              const toChannel = channelList?.find((o) => o.id === dropType.toChannelId)
              const toChannelRoomId = toChannel?.roomId
              setBeforeRect(undefined)
              astro
                .copyCard({
                  ...dropType,
                  toChannelRoomId: toChannelRoomId || '',
                  deep: false,
                })
                .finally(() => {
                  set(cardListState(dropType.fromChannelId), (cards) => {
                    const page = Math.ceil(cards.length / PAGE_SIZE)
                    readCardListPage(dropType.fromChannelId, page)
                      .then((list) => {
                        return Promise.all(
                          list.map((o: CardRelationResponse) =>
                            extraCard({ ...o, card: { ...o.card, isSkeleton: false } }),
                          ),
                        )
                      })
                      .then((list) => set(cardListState(dropType.fromChannelId), list))
                    return cards
                  })
                  set(cardListState(dropType.toChannelId), (cards) => {
                    const page = Math.ceil(cards.length / PAGE_SIZE)
                    readCardListPage(dropType.toChannelId, page)
                      .then((list) => {
                        return Promise.all(
                          list.map((o: CardRelationResponse) =>
                            extraCard({ ...o, card: { ...o.card, isSkeleton: false } }),
                          ),
                        )
                      })
                      .then((list) => set(cardListState(dropType.toChannelId), list))
                    return cards
                  })
                  showToastMessage({
                    type: 'Success',
                    title: t('common.toast.copied'),
                    position: 'BOTTOM_CENTER',
                  })
                  hideDialogMessage()
                })
            },
          })
        }
        showDialogMessage({
          type: 'BOTTOM',
          title,
          list,
          cancelText: t('common.cancel'), // '취소',
          onCancel: async () => {
            showToastMessage({
              type: 'Success',
              title: t('common.toast.canceled'),
              position: 'BOTTOM_CENTER',
            })
            console.log('취소')
            // resetCardList()

            const { draggableId, sourceId, sourceIndex, destinationId, destinationIndex } = placeholderProps

            if (destinationId && destinationIndex !== undefined) {
              const sourceList = await snapshot.getPromise(cardListState(sourceId))
              const destinationList = await snapshot.getPromise(cardListState(destinationId))

              const getBoardDom = (dropableId: string) => {
                const queryAttr = 'data-rbd-droppable-id'
                const domQuery = `[${queryAttr}='${dropableId}']`
                const draggedDOM = document.querySelector(domQuery)
                const el = draggedDOM as HTMLElement
                return el.firstElementChild as HTMLElement
              }

              const ms = 300
              const draggedDom = getDraggedDom(draggableId)
              const currentRect = draggedDom.getBoundingClientRect()
              const cardHeight = currentRect.height

              if (beforeRect && currentRect) {
                const boardDom = getBoardDom(dropType.toChannelId)
                console.log('draggedDom', beforeRect, boardDom, draggedDom, currentRect)

                boardDom.style.zIndex = '1'
                draggedDom.style.width = '246px'
                draggedDom.style.position = 'fixed'
                draggedDom.style.zIndex = '5000'
                draggedDom.style.transition = `all ${ms}ms`
                draggedDom.style.transform = `translate(${beforeRect.x - currentRect.x}px, ${
                  beforeRect.y - currentRect.y
                }px)`

                setTimeout(() => {
                  set(
                    cardListState(sourceId),
                    addItemAtIndex(sourceList, sourceIndex, destinationList[destinationIndex]),
                  )
                  set(cardListState(destinationId), removeItemAtIndex(destinationList, destinationIndex))

                  boardDom.style.zIndex = '0'
                }, ms)
              }

              const filtered = (item: CardRelationResponse) => {
                const { card } = item
                if (filterCode === 'DONE') {
                  return card.todoDoneCount !== undefined &&
                    card.todoTotalCount &&
                    card.todoDoneCount > 0 &&
                    card.todoDoneCount === card.todoTotalCount
                    ? true
                    : false
                }

                if (filterCode === 'PROGRESS') {
                  return card.todoDoneCount !== undefined &&
                    card.todoTotalCount &&
                    card.todoDoneCount > 0 &&
                    card.todoDoneCount === card.todoTotalCount
                    ? false
                    : true
                }
                return true
              }
              destinationList
                .filter(filtered)
                .slice(destinationIndex + 1)
                .map((item) => {
                  const { card } = item
                  const draggedDOM = getDraggedDom(String(card.no))
                  if (draggedDOM) {
                    draggedDOM.style.transform = `translateY(-${cardHeight + 8}px)`
                    draggedDOM.style.transition = `${ms}ms`

                    setTimeout(() => {
                      draggedDOM.style.transform = ``
                      draggedDOM.style.transition = ``
                    }, ms)
                  }
                })
              sourceList
                .filter(filtered)
                .slice(sourceIndex)
                .map((item) => {
                  const { card } = item
                  const draggedDOM = getDraggedDom(String(card.no))
                  if (draggedDOM) {
                    draggedDOM.style.transform = `translateY(${cardHeight + 8}px)`
                    draggedDOM.style.transition = `${ms}ms`
                    setTimeout(() => {
                      draggedDOM.style.transform = ``
                      draggedDOM.style.transition = ``
                    }, ms)
                  }
                })
            }

            hideDialogMessage()

            setBeforeRect(undefined)
          },
        })
      },
    [filterCode, channelList, resetCardList, beforeRect, currentProject],
  )

  const onDragEnd = useRecoilCallback(
    ({ set, snapshot }) =>
      async (result: DropResult, provided) => {
        console.log('onDragEnd', result, provided)
        setPlaceholderProps(undefined)

        const { draggableId, source, destination } = result

        if (!destination) return

        const sourceList = await snapshot.getPromise(cardListState(source.droppableId))
        const destinationList = await snapshot.getPromise(cardListState(destination.droppableId))

        const filtered = (item: CardRelationResponse) => {
          const { card } = item
          if (filterCode === 'DONE') return card.isDone === true ? true : false
          if (filterCode === 'PROGRESS') return card.isDone === undefined || card.isDone === false ? true : false
          return true
        }
        const filteredSourceList = sourceList.filter(filtered)
        const filteredDestinationList = destinationList.filter(filtered)

        const sourceIndex = sourceList.findIndex(({ cardId }) => cardId === filteredSourceList[source.index].cardId)
        let destinationIndex = destinationList.findIndex(
          ({ cardId }) => cardId === filteredDestinationList[destination.index]?.cardId,
        )

        if (destinationIndex < 0) destinationIndex = filteredDestinationList.length

        setTimeout(() => {
          setDropAnimating({
            channelId: destination.droppableId,
            cardId: draggableId,
            index: destinationIndex,
          })
        }, 300)
        setTimeout(() => {
          setDropAnimating(undefined)
        }, 600)

        if (source.droppableId === destination.droppableId) {
          const changedList = changeItemAtIndex(sourceList, sourceIndex, destinationIndex)
          set(cardListState(source.droppableId), changedList)
          const item = sourceList.find((o) => o.id === result.draggableId)
          if (item) {
            const { card } = item
            astro.changeIdxCard({
              cardId: String(card.no),
              channelId: source.droppableId,
              idx: destinationIndex,
            })
          }

          sourceList.map((item) => {
            const draggedDOM = getDraggedDom(item.id)
            if (draggedDOM) {
              draggedDOM.style.transition = ``
              draggedDOM.style.transform = ``
            }
          })
        } else {
          set(
            cardListState(destination.droppableId),
            addItemAtIndex(destinationList, destinationIndex, sourceList[sourceIndex]),
          )
          set(cardListState(source.droppableId), removeItemAtIndex(sourceList, sourceIndex))

          const item = sourceList.find((o) => String(o.id) === result.draggableId)
          if (item) {
            const { card } = item
            const draggableId = result.draggableId
            const sourceId = result.source.droppableId
            const sourceIndex = result.source.index
            const destinationId = result.destination ? result.destination.droppableId : undefined
            const destinationIndex = result.destination ? result.destination.index : undefined

            showDropMessage(
              {
                draggableId,
                sourceId,
                sourceIndex,
                destinationId,
                destinationIndex,
              },
              {
                cardId: String(card.no),
                fromChannelId: source.droppableId,
                toChannelId: destination.droppableId,
                idx: destinationIndex,
              },
            )
          }
          sourceList.map((item) => {
            const draggedDOM = getDraggedDom(item.id)
            if (draggedDOM) {
              draggedDOM.style.transition = ``
              draggedDOM.style.transform = ``
            }
          })
          destinationList.map((item) => {
            const draggedDOM = getDraggedDom(item.id)
            if (draggedDOM) {
              draggedDOM.style.transition = ``
              draggedDOM.style.transform = ``
            }
          })
        }
      },
    [filterCode, showDropMessage],
  )

  React.useEffect(() => {
    resetChannelList()
  }, [projectId])

  useSubscription(`/subscribe/${currentUser.id}/rooms/leave`, resetChannelList)
  useSubscription(`/subscribe/project/${projectId}/update`, resetChannelList)

  return channelList && channelList.length > 0 ? (
    <View style={{ height: '100%' }}>
      <ScrollView horizontal style={{ flex: 1 }}>
        {/* <div
        className="scroll-y"
        style={{
          display: 'flex',
          backgroundColor: getRealColor('mono.white'),
          flexDirection: 'row',
          // overflow-y: 'auto',
          // overflowY: 'auto',
        }}
      > */}
        <DragDropContext
          onBeforeCapture={onBeforeCapture}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragUpdate={onDragUpdate}
        >
          {channelList.map((channel, index) => {
            return (
              // <BackdropProvider key={channel.id}>
              //   <Modal.Provider>
              //     <ModalBottom.Provider>
              //       <ModalDialog.Provider>
              <Board
                key={channel.id}
                channel={channel}
                projectElement={
                  projectElementList
                    ? projectElementList.find((o) => o.order === channel.projectElementOrder)
                    : undefined
                }
                isFirst={channel.projectTypeCode === 'WFP' && index === 0}
                isLast={channel.projectTypeCode === 'WFP' && index === channelList.length - 1}
                isBoardFirst={index === 0}
                isBoardLast={index === channelList.length - 1}
                resetChannelList={resetChannelList}
                handlePressBoardRole={() => handlePressBoardRole?.(channel.id)}
                extraCard={extraCard}
              />
              //       </ModalDialog.Provider>
              //     </ModalBottom.Provider>
              //   </Modal.Provider>
              // </BackdropProvider>
            )
          })}
        </DragDropContext>
      </ScrollView>
    </View>
  ) : channelList ? (
    <View
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View style={{ alignItems: 'center' }}>
        {projectId !== undefined ? (
          <NoContentsIcon
            type={isArchive ? 'board-archive' : 'board'}
            textSize={'medium'}
            text={isArchive ? t('nocontentsicon.boardarchive.text') : t('nocontentsicon.board.text')}
            subText={''}
            iconWidth={115}
          />
        ) : (
          <NoContentsIcon
            type={isArchive ? 'board-archive' : 'board'}
            textSize={'medium'}
            text={t('nocontentsicon.workguest.subtext')}
            subText={''}
            iconWidth={115}
          />
        )}
        {!isArchive && currentProjectMember && currentProjectMember.auth !== 'OWNER' ? (
          <Button
            size="sm"
            // backgroundColor="gray.g100"
            style={{
              paddingHorizontal: 8,
              marginTop: 8,
              flexDirection: 'row',
              backgroundColor: COLOR.gray.g100,
            }}
            onPress={() => {
              setIsAlert(true)
            }}
            text={
              <Text
                style={{
                  ...getFontStyle('txtXs'),
                  color: getRealColor('gray.g700'),
                }}
              >
                {t('nocontentsicon.board.button')}
              </Text>
            }
          />
        ) : (
          <></>
        )}
      </View>

      {isAlert ? (
        <Dimmed onDismiss={() => console.log('onDismiss')} backgroundColor="mono.black" opacity={0.75}>
          <ModalBottomAlert
            message={t('modalbottomalert.workleave.message')}
            list={[
              {
                name: t('modalbottomalert.workleave.button'),
                action: () => {
                  setModalState('OPEN')
                },
              },
            ]}
            cancelText={t('modalbottomalert.cancel')}
            submitText=""
            visible={true}
            onCancel={() => console.log('onCancel')}
            onClose={() => setIsAlert(false)}
            onDismiss={() => setIsAlert(false)}
          />
        </Dimmed>
      ) : (
        <></>
      )}
      {projectId !== undefined && (
        // <BackdropProvider>
        //   <Modal.Provider>
        //     <ModalBottom.Provider>
        //       <ModalDialog.Provider>
        <ModalView
          projectId={projectId}
          userId={String(currentUser.id)}
          state={modalState}
          onClose={() => {
            setModalState('CLOSE')
          }}
        />
        //       </ModalDialog.Provider>
        //     </ModalBottom.Provider>
        //   </Modal.Provider>
        // </BackdropProvider>
      )}
    </View>
  ) : (
    <></>
  )
}

export default BoardView
