import * as React from 'react'
import { Path, Svg } from 'react-native-svg'

export const RightIcon = () => {
  return (
    <Svg width="22" height="47" viewBox="0 0 22 47" fill="none">
      <Path d="M1 46L20 23.5L0.999996 1" stroke="#B4BEC5" strokeWidth="2" />
    </Svg>
  )
}
