export interface Props {
  onPressBack?: () => void
  onPressNav: (page: string) => void
  onLogout: () => void
}

export const langData = [
  { label: 'English', key: 'en' },
  { label: '한국어', key: 'ko' },
  { label: 'Español', key: 'es' },
  { label: 'Deutsch', key: 'de' },
  { label: 'Tiếng Việt', key: 'vi' },
  { label: 'Монгол', key: 'mn' },
  { label: '日本語', key: 'ja' },
]
