import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT } from '@rocket-mono/libs'
import { useAstro, useModal } from '@rocket-mono/providers'
// import { SkeletonLinkInviteModal } from '@rocket/skeleton'
import { Button, FAIcon, Text } from '@rocket/atoms'
import { useToast } from '@rui/atoms'
import { getRealColor } from '@rui/foundations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, View } from 'react-native'
import { QRCodeViewer } from '../QRCodeViewer'

interface Props {
  url: string
  onDismiss: () => void
}

const LinkInviteModal: React.FC<Props> = ({ url, onDismiss }) => {
  const { t } = useTranslation()
  const { astro, option } = useAstro()
  const [shortenerHashCode, setShortenerHashCode] = React.useState<string>()
  const { Modal, open, close, visible } = useModal('LinkInviteModal')

  const { show: showToastMessage } = useToast()

  React.useEffect(() => {
    open()
  }, [])

  const shortenerUrl = React.useMemo(() => {
    if (!shortenerHashCode) return ''
    return `${option.shortenerBaseUrl}/${shortenerHashCode}`
  }, [shortenerHashCode])

  const onPressCopy = React.useCallback(() => {
    if (shortenerUrl)
      navigator.clipboard.writeText(shortenerUrl).then(() => {
        showToastMessage({
          type: 'Success',
          title: t('board.linkqrmodal.copydone'),
          position: 'BOTTOM_CENTER',
        })
      })
  }, [shortenerUrl])

  const handlerClose = () => {
    return onDismiss(), close()
  }

  const isSkeleton = React.useMemo(() => {
    return !shortenerHashCode
  }, [shortenerHashCode])

  React.useEffect(() => {
    astro
      .readShortenerUrlList(encodeURIComponent(url))
      .then((res) => res.find((o) => o.linkUrl === url))
      .then((res) => {
        return res ? res.hashCode : astro.createShortenerUrl(url).then((res) => res.hashCode)
      })
      .then(setShortenerHashCode)
      .catch(() => {
        showToastMessage({
          type: 'Danger',
          title: t('board.linkqrmodal.error'),
          position: 'BOTTOM_CENTER',
        })
        handlerClose()
      })
  }, [url])

  return (
    <Modal visible={visible} containerStyle={{ maxWidth: 500, backgroundColor: COLOR.mono.white }}>
      {isSkeleton ? (
        <View style={{ width: 460, height: 487, margin: 20 }}>{/* <SkeletonLinkInviteModal /> */}</View>
      ) : (
        <>
          <Modal.Header textAlign="center">
            <View
              style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Text style={{ ...FONT.txtMdMedium }}>{t('board.linkqrmodal.title')}</Text>
              <Pressable style={{ padding: 4, paddingRight: 0 }} onPress={handlerClose}>
                <FAIcon iconName={faXmark} size={'lg'} color={'gray.g700'} />
              </Pressable>
            </View>
          </Modal.Header>
          <Modal.Body style={{ width: '100%', paddingTop: 16, paddingBottom: 80 }}>
            <View>
              <View>
                <Text fontName="subTitleBold" fontColor="mono.black">
                  {t('board.linkqrmodal.subtitle')}
                </Text>
                <Text style={{ marginTop: 12 }} fontName="subTextRegular" fontColor="mono.darkGray">
                  {t('board.linkqrmodal.desc')}
                </Text>
              </View>
              <View
                style={{
                  width: '100%',
                  marginTop: 20,
                  paddingHorizontal: 12,
                  height: 34,
                  justifyContent: 'center',
                  borderWidth: 1,
                  borderColor: getRealColor('gray.g200'),
                  borderRadius: 5,
                }}
              >
                <Text fontName="txtMd" fontColor="gray.g450" numberOfLines={1}>
                  {shortenerUrl}
                </Text>
              </View>
              <View style={{ marginTop: 4, alignItems: 'flex-end' }}>
                <Button
                  // color="mono.paleWhite"
                  // backgroundColor="main.blue"
                  // borderColor="main.blue"
                  onPress={onPressCopy}
                  style={{ paddingHorizontal: 4 }}
                >
                  {t('board.linkqrmodal.copy')}
                </Button>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 60,
                }}
              >
                {shortenerUrl && <QRCodeViewer url={shortenerUrl} size={146} />}
              </View>
            </View>
          </Modal.Body>
        </>
      )}
    </Modal>
  )
}

export default LinkInviteModal
