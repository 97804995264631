import * as React from 'react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FlatList,
  ListRenderItemInfo,
  Platform,
  Pressable,
  SectionList,
  SectionListData,
  SectionListRenderItemInfo,
  StyleSheet,
  TextInput,
} from 'react-native'

import { COLOR, FONT } from '@rui/foundations'

import { faGear, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { XEIcon } from '@rocket-atoms/icon'
import { View } from '@rocket-mono/foundations'
import { useModalDialog, WorkProjectProvider } from '@rocket-mono/providers'
import { Button, Text, useToast } from '@rui/atoms'
import { getRealColor } from '@rui/foundations'
import { NoContentsIcon } from '@rui/icons'
import { CreateWorkButtonInChatList, ScreenTitle, TopTab, TopTabItem, WorkList } from '../../components'
import { ChatLeaving } from './ChatLeaving'
import { ChatsEmpty } from './ChatsEmpty'
import { ChatsItem } from './ChatsItem'
import { ChatsUnread } from './ChatsUnread'
import { WorkSetting } from './WorkSetting'
import type { ChatsScreenTabType, ChatsViewProps, RenderItemType, SectionItemType } from './types'

export const ChatsView: React.FC<ChatsViewProps> = ({
  titleVisible = true,
  secureCdnUrl,
  astro,
  userId,
  userName,
  subscribe,
  tabCode,
  stateList,
  sectionList,
  itemList,
  showSearch = false,
  searchKeyword,
  onChangeSearchKeyword,
  onPressSettingItem,
  onPressButtonCreate,
  onPressButtonSearch,
  onPressButtonSearchClose,
  onPressTab,
  onPressSection,
  onPressItem,
  onLoadItem,
  scrollTime = 300,
  insetsBottom = 0,
  setEmptyVisible,
  getEmptyVisible,
}) => {
  const [leaveProjectId, setLeaveProjectId] = React.useState<string>('')
  const [isSettingVisible, setIsSettingVisible] = React.useState(false)
  const { t } = useTranslation()
  const sectionListRef = React.useRef<SectionList<RenderItemType, SectionItemType>>(null)

  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const { show: showToastMessage } = useToast()

  const handlePressRequest = useCallback(
    (targetUserId: string) => {
      const requestDate = new Date()
      // const expirationDate = moment(requestDate).add('1', 'weeks').toDate()
      astro
        .createProjectWithdrawalSelf({
          requestDate,
          expirationDate: null,
          projectId: leaveProjectId,
          requestUserId: userId,
          targetUserId,
        })
        .catch(() => {
          setLeaveProjectId('')
          setTimeout(() => {
            showToastMessage({
              title: '나가기 요청 중 오류가 발생했습니다.',
              type: 'Danger',
              position: 'BOTTOM_CENTER',
            })
          }, 100)
          throw new Error('withdrawal error')
        })
        .then(() => {
          setLeaveProjectId('')
        })
        .then(() => {
          const list = [
            {
              name: '확인',
              action: hideDialogMessage,
            },
          ]
          showDialogMessage({
            title: '나가기 요청이 완료되었습니다.',
            list,
          })
        })
    },
    [leaveProjectId, userId, astro],
  )

  const TabList: TopTabItem[] = [
    {
      name: t('screen.chats.tab1'),
      code: 'WORK',
      isShow: true,
    },
    {
      name: t('screen.chats.tab2'),
      code: 'NEW',
      isShow: true,
    },
  ]

  const unreadItem = React.useMemo(() => {
    return itemList?.filter((o) => o.unreadCount > 0) || []
  }, [itemList])

  const onPressSectionHandler = useCallback(
    (sectionKey: string, is: boolean) => {
      onPressSection && onPressSection(sectionKey)
      const idx = sectionList.findIndex(({ key }) => key === sectionKey)
      if (sectionListRef.current && idx >= 0 && !is) {
        const params = {
          sectionIndex: idx,
          itemIndex: 1,
        }
        setTimeout(() => {
          sectionListRef.current?.scrollToLocation(params)
        }, scrollTime)
      }
    },
    [onPressSection, sectionListRef, sectionList],
  )

  const keyExtractor = React.useCallback((item: RenderItemType) => `ChatsScreen-item-${item.key}`, [])

  const renderSection = React.useCallback(
    ({ section }: { section: SectionListData<RenderItemType, SectionItemType> }) => {
      if (searchKeyword && section.data.length === 0) return <></>
      return (
        <WorkList
          isSkeleton={section.isLoading}
          sectionKey={section.key}
          title={section.title}
          isFold={section.isFold}
          isNoIcon={section.data.length === 0}
          typeCode={section.typeCode}
          onPress={(is: boolean) => onPressSectionHandler(section.key, is)}
          onPressLeave={() => {
            if (!['GUEST', 'DIRECT'].includes(section.key)) setLeaveProjectId(section.key)
          }}
        />
      )
    },
    [sectionList, searchKeyword],
  )
  const renderSectionFooter = React.useCallback(
    ({ section }: { section: SectionListData<RenderItemType, SectionItemType> }) => {
      if (!searchKeyword && section.data.length === 0) {
        return (
          <>
            {section.key === 'GUEST' && !section.isFold && (
              <View style={styles.noList}>
                <NoContentsIcon
                  type={'work-guest'}
                  textSize={'medium'}
                  text={''}
                  subText={t('nocontentsicon.workguest.subtext')}
                  iconWidth={58}
                />
              </View>
            )}
            {section.key !== 'GUEST' && !section.isFold && (
              <View style={styles.noList}>
                <NoContentsIcon
                  type={'work-guest'}
                  textSize={'medium'}
                  text={''}
                  subText={t('nocontentsicon.board.subtext')}
                  iconWidth={58}
                />
                <Button
                  size="sm"
                  // backgroundColor={'gray.g100'}
                  style={{ paddingHorizontal: 8, marginTop: 8, backgroundColor: COLOR.gray.g100 }}
                  onPress={() => {
                    if (!['GUEST', 'DIRECT'].includes(section.key)) setLeaveProjectId(section.key)
                  }}
                  text={
                    <Text fontName="txtXs" fontColor="gray.g700">
                      {t('nocontentsicon.board.button')}
                    </Text>
                  }
                />
              </View>
            )}
          </>
        )
      }
      return <></>
    },
    [sectionList, searchKeyword],
  )
  const renderSectionItem = React.useCallback(
    ({ item, section }: SectionListRenderItemInfo<RenderItemType, SectionItemType>) => {
      if (section.isFold) return <></>
      let state
      if (item.type === 'DIRECT') {
        state = stateList.find(({ userId }) => userId === item.profile?.userId)
      }
      const label = section.key === 'GUEST' ? item.label : undefined
      return <ChatsItem item={{ ...item, label }} state={state?.userState} onPress={onPressItem} onLoad={onLoadItem} />
    },
    [sectionList, stateList],
  )
  const renderItem = React.useCallback(
    ({ item }: ListRenderItemInfo<RenderItemType>) => {
      let state
      if (item.type === 'DIRECT') {
        state = stateList.find(({ userId }) => userId === item.profile?.userId)
      }

      return <ChatsItem item={item} state={state?.userState} onPress={onPressItem} onLoad={onLoadItem} />
    },
    [sectionList, stateList],
  )

  const [visible, setVisible] = React.useState(false)
  React.useEffect(() => {
    if (Platform.OS === 'web') {
      // README: rocket-qa#444 web에서 노출되지 않게 처리
      // const emptyVisible = localStorage.getItem('chats-empty-visible')
      // setVisible(emptyVisible === 'false' ? false : true)
    } else {
      getEmptyVisible &&
        getEmptyVisible().then((emptyVisible) => {
          setVisible(emptyVisible === 'false' ? false : true)
        })
    }
  }, [])
  return (
    <View style={styles.container}>
      <WorkSetting
        bottom={insetsBottom}
        visible={isSettingVisible}
        sectionList={sectionList}
        onPressItem={(projectId) => {
          setIsSettingVisible(false)
          onPressSettingItem && onPressSettingItem(projectId)
        }}
        onPressClose={() => setIsSettingVisible(false)}
      />
      {titleVisible && (
        <ScreenTitle
          title={t('screen.chats.title')}
          buttonIconElements={
            <>
              {onPressButtonCreate && (
                <Pressable style={styles.buttonIcon} onPress={onPressButtonCreate}>
                  <FontAwesomeIcon icon={faPlus} size={15} color={getRealColor('gray.g600')} />
                </Pressable>
              )}
              {onPressButtonSearch && (
                <Pressable style={styles.buttonIcon} onPress={onPressButtonSearch}>
                  <FontAwesomeIcon icon={faSearch} size={15} color={getRealColor('gray.g600')} />
                </Pressable>
              )}
              {Platform.OS !== 'web' && (
                <Pressable style={styles.buttonIcon} onPress={() => setIsSettingVisible(true)}>
                  <FontAwesomeIcon icon={faGear} size={15} color={getRealColor('gray.g600')} />
                </Pressable>
              )}
            </>
          }
        />
      )}
      {showSearch ? (
        <View
          style={{
            flexDirection: 'row',
            borderWidth: 1,
            borderColor: getRealColor('gray.g200'),
            marginHorizontal: 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextInput
            style={{ height: 36, flex: 1, paddingLeft: 12, ...FONT.txtSm }}
            value={searchKeyword}
            onChangeText={onChangeSearchKeyword}
            placeholder={t('placesearch.search')}
            placeholderTextColor={getRealColor('gray.g450')}
          />
          <Button
            style={{ margin: 5, backgroundColor: COLOR.gray.g100, height: 26, width: 26 }}
            // containerSize={26}
            // iconSize={12}
            // iconName="close"
            // iconColor="gray.g700"
            // backgroundColor="gray.g100"
            onPress={onPressButtonSearchClose}
            text={<XEIcon name="close" color="gray.g700" size={12} />}
          />
        </View>
      ) : (
        <></>
      )}
      <TopTab list={TabList} initCode={tabCode} onCode={(code) => onPressTab(code as ChatsScreenTabType)} />
      {/* {itemList === undefined ? <ActivityIndicator /> : <></>} */}
      {!searchKeyword && itemList && itemList.length < 2 ? (
        <>
          <View style={{ padding: 8 }}>
            <CreateWorkButtonInChatList onPress={onPressButtonCreate} />
          </View>
          {visible ? (
            <ChatsEmpty
              userName={userName}
              visible={visible}
              onPressClose={() => {
                setVisible(false)
                if (Platform.OS === 'web') {
                  localStorage.setItem('chats-empty-visible', 'false')
                } else {
                  setEmptyVisible && setEmptyVisible()
                }
              }}
              onPress={() => {
                setVisible(false)
                onPressButtonCreate && onPressButtonCreate()
              }}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {/* {itemList && itemList.length > 0 ? ( */}
      {tabCode === 'WORK' ? (
        <SectionList
          ref={sectionListRef}
          style={styles.listContainer}
          stickySectionHeadersEnabled
          sections={sectionList}
          keyExtractor={keyExtractor}
          renderSectionHeader={renderSection}
          renderSectionFooter={renderSectionFooter}
          renderItem={renderSectionItem}
          ListHeaderComponent={
            <ChatsUnread
              list={unreadItem.sort((a, b) => {
                const aCount = a.unreadCount > 0 ? 1 : 0
                const bCount = b.unreadCount > 0 ? 1 : 0
                const sortA = bCount - aCount
                const sortB = a.date > b.date ? -1 : a.date < b.date ? 1 : 0
                return sortA || sortB
              })}
              onPressItem={onPressItem}
            />
          }
        />
      ) : tabCode === 'NEW' ? (
        <>
          <FlatList
            style={styles.listContainer}
            data={itemList
              ?.filter((o) => {
                return o.messageCount === undefined || o.messageCount > 1 || o.type === 'ME'
              })
              .sort((a, b) => {
                const aCount = a.unreadCount > 0 ? 1 : 0
                const bCount = b.unreadCount > 0 ? 1 : 0
                const sortA = bCount - aCount
                const sortB = a.date > b.date ? -1 : a.date < b.date ? 1 : 0
                return sortA || sortB
              })}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
          />
          {!searchKeyword && itemList?.length === 1 && (
            <View style={{ flex: 2 }}>
              <NoContentsIcon
                type={'chat'}
                textSize={'medium'}
                text={''}
                subText={t('nocontentsicon.chat.subtext')}
                iconWidth={52}
              />
            </View>
          )}
        </>
      ) : (
        <></>
      )}
      {!!searchKeyword && itemList?.length === 0 && (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <NoContentsIcon
            type={'search'}
            textSize={'medium'}
            text={''}
            subText={t('nocontentsicon.search.text')}
            iconWidth={52}
          />
        </View>
      )}
      {leaveProjectId ? (
        <WorkProjectProvider
          projectId={leaveProjectId}
          onUnauthorized={() => console.log('unauthorized')}
          onDeleted={() => console.log('deleted')}
        >
          <ChatLeaving
            secureCdnUrl={secureCdnUrl}
            leaveVisible={!!leaveProjectId}
            onPressClose={() => setLeaveProjectId('')}
            onPressRequest={handlePressRequest}
          />
        </WorkProjectProvider>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.mono.white,
  },
  listContainer: {
    flex: 1,
  },
  noList: {
    minHeight: 176,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20,
  },
  buttonIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    height: 36,
  },
})
