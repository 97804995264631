import { useMobileView } from '@rui/foundations'
import * as React from 'react'
import MobileView from './mobile'
import { Props } from './types'
import WebView from './web'

const SettingProfile: React.FC<Props> = (props) => {
  const isMobile = useMobileView(400)

  if (isMobile) return <MobileView {...props} />
  else return <WebView {...props} />
}

export default SettingProfile
