import * as React from 'react'
import { Platform, SectionList, SectionListData, SectionListRenderItemInfo, StyleSheet, View } from 'react-native'

import { Text } from '@rui/atoms'
import { WorkModelCategory } from './category'
import { WorkModelItem } from './item'
import type { WorkModelItemProps, WorkModelItemType, WorkModelListProps, WorkModelSectionType } from './types'

export const WorkModelList = (Props: WorkModelListProps) => {
  const { list, titleFont = 'txtMdMedium', isCheckIcon = true, title, onPressSection } = Props
  const headerComponent = React.useCallback(() => {
    return (
      <View style={{ marginTop: 24, marginBottom: 10 }}>
        <Text fontName={titleFont} fontColor="mono.black">
          {title}
        </Text>
      </View>
    )
  }, [])

  const renderItem = React.useCallback(
    ({ item, section }: SectionListRenderItemInfo<WorkModelItemProps, WorkModelSectionType>) => {
      if (section.isFold) return <></>
      return <WorkModelItem {...item} isCheckIcon={isCheckIcon} />
    },
    [list],
  )

  const renderSectionHeader = React.useCallback(
    ({ section }: { section: SectionListData<WorkModelItemProps, WorkModelSectionType> }) => {
      const index = list.findIndex((item) => item.title === section.title)
      return (
        <WorkModelCategory
          text={section.title}
          textFont={section.titleFont}
          isFold={section.isFold}
          index={index}
          onPress={(isFold) => onPressSection && onPressSection(index, isFold)}
        />
      )
    },
    [list],
  )

  const keyExtractor = React.useCallback((item: WorkModelItemProps) => `work-model-key-${item.id}`, [list])

  return (
    <SectionList
      style={styles.container}
      sections={list}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      ListHeaderComponent={headerComponent}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    ...Platform.select({ native: { paddingHorizontal: 20 } }),
  },
})

export { WorkModelItemProps, WorkModelItemType, WorkModelListProps, WorkModelSectionType }
