import { Icon } from '@rocket-atoms/icon'
import { COLOR, FONT } from '@rocket-mono/libs'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

interface StepperProps {
  step: number
  title: string
  checked: boolean
}

interface Props {
  list: StepperProps[]
  listLength: number
  onCode?: (arg0: number) => void
  here: number
  mobileView: boolean
}

const Stepper: React.FC<Props> = ({ list, listLength, onCode, here, mobileView }: Props) => {
  // const [selectedHere, setSelectedHere] = React.useState(here ?? 1)
  const StepItem = ({ item }: { item: StepperProps; idx: number }) => {
    const stepLineSetting = item.step > 1 && item.step <= listLength
    const test = item.step <= here
    return (
      <>
        {stepLineSetting && <View style={[styles.stepLine, test && styles.stepLineActive]}></View>}
        <Pressable
          style={[here === item.step ? styles.hereStyles : styles.basicStyles, mobileView && { height: 34 }]}
          onPress={() => {
            if (onCode) {
              onCode(item.step)
            }
          }}
        >
          <View
            style={[
              styles.stepNum,
              // 현재 위치일때
              here === item.step && styles.hereStepNum,
              // 내용이 입력됐을때
              item.checked && styles.checkedNum,
              // 모바일 일때
              mobileView && styles.stepNumMobile,
              // 모바일이면서 현재 위치일떄
              here === item.step && mobileView && { width: 24, height: 24 },
            ]}
          >
            {item.checked ? (
              <Icon name={'check-min'} size={20} color={'mono.white'} style={{ marginTop: -2 }} />
            ) : (
              <Text
                style={[
                  styles.numText,
                  here === item.step && { color: COLOR.mono.white },
                  mobileView && { ...FONT.txtSmBold },
                ]}
              >
                {item.step}
              </Text>
            )}
          </View>
          {here === item.step && (
            <Text style={[{ ...FONT.txtXs, paddingHorizontal: 4 }, mobileView && { ...FONT.txtSm }]}>{item.title}</Text>
          )}
        </Pressable>
      </>
    )
  }

  return (
    <View style={styles.container}>
      {list.map((item, idx) => (
        <StepItem item={item} idx={idx} key={`stepper-${idx}`} />
      ))}
    </View>
  )
}

export { Stepper }
export type { StepperProps }

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  basicStyles: {
    justifyContent: 'center',
  },
  stepLine: {
    width: 8,
    height: 2,
    backgroundColor: COLOR.gray.g200,
  },
  stepLineActive: {
    backgroundColor: COLOR.sub.paleSky,
  },
  hereStyles: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderWidth: 2,
    borderRadius: 50,
    borderColor: COLOR.sub.paleSky,
    height: 28,
    paddingHorizontal: 4,
  },
  stepNum: {
    width: 22,
    height: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    backgroundColor: COLOR.gray.g200,
    color: COLOR.gray.g700,
  },
  stepNumMobile: {
    width: 28,
    height: 28,
  },
  hereStepNum: {
    width: 18,
    height: 18,
    backgroundColor: COLOR.sub.paleSky,
  },
  checkedNum: {
    backgroundColor: COLOR.sub.paleSky,
  },
  numText: {
    ...FONT.txt2XsBold,
    color: COLOR.gray.g700,
  },
})
