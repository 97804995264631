import { Button } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import Dropdown from '../Dropdown'
import type { WorkSettingLanguageType } from './types'

const WorkSettingLanguageWeb: React.FC<WorkSettingLanguageType> = ({ defaultIndex, data, onSelected, onPressSave }) => {
  const { t } = useTranslation()

  return (
    <View>
      <View>
        <Text style={styles.pageTitle}>{t('setting.language.title')}</Text>
      </View>
      <View style={styles.contents}>
        <View style={styles.formGroup}>
          {data && onSelected && (
            <Dropdown
              defaultIndex={defaultIndex}
              label={'Select Item'}
              list={data}
              onSelect={(item) => {
                onSelected(item.key)
              }}
            />
          )}
        </View>
        <View style={styles.bottomArea}>
          <Button
            onPress={onPressSave}
            style={{ paddingHorizontal: 12 }}
            text={<Text style={{ ...FONT.txtSm, color: COLOR.mono.white }}>{t('setting.button.apply')}</Text>}
          />
        </View>
      </View>
    </View>
  )
}

export default WorkSettingLanguageWeb

const styles = StyleSheet.create({
  pageTitle: {
    ...FONT.H6Medium,
  },
  contents: {
    marginTop: 20,
  },
  formGroup: {
    marginBottom: 12,
  },
  bottomArea: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
    paddingTop: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g300,
  },
})
