import { View } from '@rocket-mono/foundations'
import { Text, useToast } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { replaceItemAtIndex } from '@rui/foundations'
import { useTranslation } from 'react-i18next'
import { Button } from '../../Button'
import ButtonIcon from '../../ButtonIcon'
import { useTableorderCatalog, useTableorderOrder } from '../../providers'
import MenuOptionGroupItem from './MenuOptionGroupItem'

interface Props {
  catalogCategoryId: string
  menuId: string
  onPressClose?: () => void
}

const TableorderOrderAdditionalMenuView: React.FC<Props> = ({ catalogCategoryId, menuId, onPressClose }) => {
  const { t } = useTranslation()
  const { show: showToastMessage } = useToast()
  const { currentCatalog } = useTableorderCatalog()

  const [errorKey, setErrorKey] = React.useState<string[]>([])

  const menuList = React.useMemo(() => currentCatalog?.categories.flatMap(({ menus }) => menus), [currentCatalog])
  const { createBasketItem } = useTableorderOrder()

  const [optionIds, setOptionIds] = React.useState<{ key: string; ids: string[]; originIds: string[] }[]>([])

  const menu = React.useMemo(() => menuList?.find(({ originId }) => originId === menuId), [menuList, menuId])

  const requireGroupList = React.useMemo(
    () =>
      menu?.optionGroups
        .filter(
          ({ originId, type, options }) =>
            options.length > 0 && menu?.originOptionGroupIds.includes(originId) && type === 'REQUIRED',
        )
        .sort((a, b) => {
          const aSeq = menu.originOptionGroupIds.findIndex((o) => o === a.originId)
          const bSeq = menu.originOptionGroupIds.findIndex((o) => o === b.originId)
          return aSeq - bSeq
        }) || [],
    [menu],
  )

  const optionGroupList = React.useMemo(
    () =>
      menu?.optionGroups
        .filter(
          ({ originId, type, options }) =>
            options.length > 0 && menu?.originOptionGroupIds.includes(originId) && type === 'OPTIONAL',
        )
        .sort((a, b) => {
          const aSeq = menu.originOptionGroupIds.findIndex((o) => o === a.originId)
          const bSeq = menu.originOptionGroupIds.findIndex((o) => o === b.originId)
          return aSeq - bSeq
        }) || [],
    [menu],
  )

  const updateOptionIds = React.useCallback(
    (key: string, ids: string[], originIds: string[]) => {
      setOptionIds((prev) => {
        const idx = prev.findIndex((o) => o.key === key)
        return idx < 0 ? [...prev, { key, ids, originIds }] : replaceItemAtIndex(prev, idx, { key, ids, originIds })
      })
    },
    [currentCatalog],
  )

  const onPressCreate = React.useCallback(() => {
    if (menu) {
      const errorKey = optionIds
        .filter(({ key, ids }) => requireGroupList.map(({ id }) => id).includes(key) && ids.length === 0)
        .map(({ key }) => key)

      if (errorKey.length > 0) {
        setErrorKey(errorKey)
        showToastMessage({
          type: 'Danger',
          title: t('workmodal.top.bankchoose'),
          position: 'TOP_RIGHT',
        })
      } else {
        createBasketItem({
          catalogMenuId: menu.id,
          catalogCategoryId: catalogCategoryId,
          catalogMenuOriginId: menu.originId,
          quantity: 1,
          selectedCatalogOptionIds: optionIds.flatMap(({ ids }) => ids),
          selectedCatalogOptionOriginIds: optionIds.flatMap(({ originIds: ids }) => ids),
        }).then(() => {
          onPressClose && onPressClose()
        })
      }
    }
  }, [menu, optionIds, requireGroupList])

  return (
    <View style={[styles.container]}>
      <Pressable
        style={[
          styles.container,
          {
            backgroundColor: COLOR.mono.black,
            opacity: 0.7,
          },
        ]}
        onPress={onPressClose}
      />
      <View
        style={{
          minHeight: 314,
          backgroundColor: COLOR.mono.white,
          position: 'absolute',
          right: 20,
          bottom: 20,
          left: 20,
          zIndex: 11,
          borderRadius: 8,
          paddingTop: 16,
        }}
      >
        <ButtonIcon
          style={{ position: 'absolute', right: 12, top: 6, zIndex: 12 }}
          iconName="close"
          onPress={onPressClose}
        />
        {menu && (
          <View
            style={{
              flex: 1,
              paddingHorizontal: 20,
            }}
          >
            <Text fontName="textBold" fontColor="mono.black">
              {menu.name}
            </Text>

            {[...requireGroupList, ...optionGroupList]
              .filter(({ options }) => options.length > 0)
              .map((group) => {
                return (
                  <MenuOptionGroupItem
                    key={group.id}
                    error={errorKey.includes(group.id)}
                    group={group}
                    updateOptionIds={(ids, originIds) => {
                      updateOptionIds(group.id, ids, originIds)
                    }}
                  />
                )
              })}
          </View>
        )}
        <View style={{ height: 50, flexDirection: 'row', marginTop: 4 }}>
          <View style={{ flex: 1 }}>
            <Button
              wide
              style={{
                height: 50,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 8,
              }}
              backgroundColor="mono.lightGray"
              onPress={onPressClose}
            >
              <Text fontName="subTitleBold" fontColor="mono.paleBlack">
                취소
              </Text>
            </Button>
          </View>

          <View style={{ flex: 1 }}>
            <Button
              wide
              style={{
                height: 50,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 8,
              }}
              backgroundColor="main.blue"
              disabled={menu?.isSoldout}
              onPress={menu?.isSoldout ? undefined : onPressCreate}
            >
              <Text fontName="subTitleBold" fontColor="mono.white">
                {menu?.isSoldout ? '품절' : '담기'}
              </Text>
            </Button>
          </View>
        </View>
      </View>
    </View>
  )
}

export default TableorderOrderAdditionalMenuView

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 10,
  },
})
