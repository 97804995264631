import React from 'react'
import { NotificationContext } from './context'

export * from './legacy/types'
export * from './provider'
export * from './types'

export const useNotification = () => {
  const context = React.useContext(NotificationContext)

  return context
}
