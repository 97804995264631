import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { StyleSheet } from 'react-native'

import TableInfo, { OrderItemType } from './components/TableInfo'

import moment from 'moment'
import { useTableorderCatalog, useTableorderOrder } from '../providers'
import { getNumberWithComma } from '../utils'
import type { ViewProps } from './types'

const ScreenView: React.FC<ViewProps> = ({ currentTable }) => {
  const { catalogMenuList, catalogOptionList } = useTableorderCatalog()
  const { orderList } = useTableorderOrder()

  const filteredOrderList = React.useMemo(() => {
    console.log(
      'cleanup-filteredOrderList',
      currentTable,
      orderList
        ?.filter(
          ({ tableId, tableActiveNumber }) =>
            tableId === currentTable.id && tableActiveNumber === currentTable.activeNumber,
        )
        .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1)),
    )
    return orderList
      ?.filter(
        ({ tableId, tableActiveNumber }) =>
          tableId === currentTable.id && tableActiveNumber === currentTable.activeNumber,
      )
      .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1))
  }, [orderList, currentTable])

  const estimatedTime = React.useMemo(() => {
    if (filteredOrderList === undefined || filteredOrderList.length === 0) return undefined

    const cookingList = filteredOrderList
      .filter(
        ({ status, estimatedCookingCompletionAt }) =>
          status === 'COOKING' && estimatedCookingCompletionAt !== undefined,
      )
      .sort((a, b) => (moment(a.estimatedCookingCompletionAt).isBefore(b.estimatedCookingCompletionAt) ? -1 : 1))

    if (cookingList.length === 0) return undefined
    return cookingList[0].estimatedCookingCompletionAt
  }, [filteredOrderList])
  console.log('estimatedTime', estimatedTime)

  console.log('orderList', filteredOrderList)

  const orderStatus = React.useMemo<'WAITING_PAYMENT' | 'COOKING' | 'DONE' | 'SERVING_DONE' | undefined>(() => {
    if (filteredOrderList?.find((o) => o.status === 'COOKING')) return 'COOKING'
    else if (filteredOrderList?.find((o) => o.status === 'WAITING_PAYMENT')) return 'WAITING_PAYMENT'
    else if (filteredOrderList?.find((o) => o.status === 'DONE')) return 'DONE'
    else if (filteredOrderList?.find((o) => o.status === 'SERVING_DONE')) return 'SERVING_DONE'
    else return undefined
  }, [filteredOrderList])

  const tableOrderList = React.useMemo<OrderItemType[] | undefined>(
    () =>
      filteredOrderList?.map(
        ({ id, status, items, estimatedCookingCompletionAt: estimatedTime, createdAt: orderTime }, idx) => {
          const complete = status === 'SERVING_DONE'

          console.log('items', items)
          const menu = items.map(({ catalogMenuId, catalogSelectedOptionIds, quantity: count }) => {
            const menuItem = catalogMenuList.find(({ id }) => id === catalogMenuId)
            return {
              menu: menuItem?.name || '',
              option: catalogOptionList
                .filter(({ id }) => catalogSelectedOptionIds.includes(id))
                .map(({ name }) => name)
                .join(', '),
              count,
              estimatedCookingMinute: menuItem?.estimatedCookingMinute || 0,
            }
          })
          return {
            id,
            idx,
            complete,
            menu,
            orderTime,
            estimatedTime,
          }
        },
      ),
    [filteredOrderList, catalogMenuList, catalogOptionList],
  )

  const price = React.useMemo(
    () => ({
      prePay: getNumberWithComma(
        filteredOrderList?.filter((o) => o.paymentWhen === 'PRE').reduce((value, prev) => value + prev.price, 0),
      ),
      postPay: getNumberWithComma(
        filteredOrderList?.filter((o) => o.paymentWhen === 'POST').reduce((value, prev) => value + prev.price, 0),
      ),
      cash: getNumberWithComma(
        filteredOrderList?.filter((o) => o.paymentMethod === 'CASH').reduce((value, prev) => value + prev.price, 0),
      ),
      totalPrice: getNumberWithComma(filteredOrderList?.reduce((value, prev) => value + prev.price, 0)),
    }),
    [filteredOrderList],
  )

  return (
    <View style={styles.container}>
      <TableInfo
        shopId={currentTable.shopId}
        tableId={currentTable.id}
        data={{
          tableName: currentTable.name,
          progress: 0,
          status: orderStatus,
          order: tableOrderList,
          price,
          estimatedTime,
        }}
        allowableHeadcount={currentTable.allowableHeadcount}
        onItemPress={(item, index) => {
          console.log('item', item, 'index', index)
        }}
      />
    </View>
  )
}

export default ScreenView

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: 400,
    top: 0,
    bottom: 0,
    right: 0,
  },
})
