import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import * as React from 'react'
import { StyleSheet, ViewStyle } from 'react-native'

interface Props {
  title: string
  buttonIconElements?: JSX.Element | JSX.Element[]
  style?: ViewStyle
}

const SettingTitle: React.FC<Props> = ({ title, buttonIconElements, style }): JSX.Element => {
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.text} fontName="H5Bold" fontColor="mono.black">
        {title}
      </Text>
      {buttonIconElements && <View style={styles.buttonContainer}>{buttonIconElements}</View>}
    </View>
  )
}

export default SettingTitle

const styles = StyleSheet.create({
  container: {
    height: 64,
    backgroundColor: COLOR.mono.white,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    marginRight: -4,
  },
  text: {
    flex: 1,
  },
})
