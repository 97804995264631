import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { Dimensions, DimensionValue, Platform, Modal as RNModal, StyleSheet, ViewStyle } from 'react-native'

export interface ModalProps {
  visible: boolean
  children: JSX.Element[] | JSX.Element
  height?: DimensionValue
  heightMax?: boolean
  bottom?: number
  containerStyle?: ViewStyle
}

export const ModalBase: React.FC<ModalProps> = ({
  visible,
  children,
  height,
  heightMax,
  bottom = 0,
  containerStyle,
}) => {
  const dimensions = Dimensions.get('window')

  return (
    <>
      <RNModal transparent animationType="slide" visible={visible}>
        <View style={[styles.centeredView, containerStyle]}>
          <View
            style={[
              styles.modalView,
              {
                height: heightMax ? dimensions.height - 50 : height,
                paddingBottom: bottom,
              },
            ]}
          >
            {children}
          </View>
        </View>
      </RNModal>
    </>
  )
}

ModalBase.displayName = 'Modal'

const styles = StyleSheet.create({
  centeredView: {
    position: 'absolute',
    flex: 1,
    flexDirection: 'column',
    ...Platform.select({
      web: {
        height: '100%',
        width: '100%',
      },
      native: {
        height: '100%',
        width: Dimensions.get('window').width,
      },
    }),
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex: 99,
  },
  modalView: {
    width: '100%',
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
})
