import React, { useCallback, useMemo, useState } from 'react'

import { faCheck, faCircleXmark, faPen } from '@fortawesome/free-solid-svg-icons'
import { PopListSectionTabs, SelectList } from '@rocket-molecules/popselector'
import { DropdownButton, SecondaryButton, Select, TextInput, View } from '@rocket-mono/foundations'
import { COLOR, FONT, getBoxShadow, removeItemAtIndex } from '@rocket-mono/libs'
import { FAIcon, Icon, Text } from '@rocket/atoms'
import { Channel } from '@rocket/types'
import { Avatar } from '@rui/atoms'
import { ClickOutside } from '@rui/foundations'
import { useTranslation } from 'react-i18next'
import { Animated, Dimensions, Pressable, StyleSheet } from 'react-native'

export interface MemberInviteItemType {
  key: string
  name: string
  email: string
  selectedBoardList: string[]
  isOpen?: boolean
  addIndication?: boolean
  isPriorityInvite?: boolean
  authority: string
  errorMessage?: string
}

const InviteMemberItem = ({
  item,
  idx,
  // authorityValue,
  // renderAuthorityPop,
  // renderBoardPop,
  unarchiveList,
  archiveList,
  handleRemove,
  handleValueChange,
  handleBoardPopup,
  secureCdnUrl,
}: {
  item: MemberInviteItemType
  idx: number
  // authorityValue: string
  // renderAuthorityPop: React.ReactNode
  // renderBoardPop: React.ReactNode
  unarchiveList?: Channel[]
  archiveList?: Channel[]
  handleRemove: (index: number) => void
  handleValueChange: (key: string, value: string, type?: string) => void
  handleBoardPopup: (list: string[]) => void
  secureCdnUrl?: string
}) => {
  const { t } = useTranslation()
  const dimensions = Dimensions.get('window')
  const [isEdit, setIsEdit] = useState(false)
  const [isEditButtonHover, setIsEditButtonHover] = useState(false)
  const [deleteButtonHover, setDeleteButtonHover] = useState(false)
  // const [nameValue, setNameValue] = useState(item.name)
  const [boardPop, setBoardPop] = useState(false)
  const [authorityPop, setAuthorityPop] = useState(false)

  const [selectedBoardList, setSelectedBoardList] = useState<string[]>([])

  const authority = useMemo(() => {
    if (item.authority === 'admin') {
      // return '관리자'
      return t('workinvite.memberoption.authority.admin')
    } else if (item.authority === 'normal') {
      // return '일반'
      return t('workinvite.memberoption.authority.normal')
    } else if (item.authority === 'guest') {
      // return '게스트'
      return t('workinvite.memberoption.authority.guest')
    } else {
      return ''
    }
  }, [item.authority])

  const boardButton = useMemo(() => {
    if (item.isPriorityInvite) {
      return '우선 초대'
    } else if (item.selectedBoardList.length > 0) {
      // return item.selectedBoardList.length + '개의 보드 참여'
      return t('workinvite.memberoption.board.invite', { count: item.selectedBoardList.length })
    } else {
      // return '보드 선택'
      return t('workinvite.memberoption.board.select')
    }
  }, [item.isPriorityInvite, item.selectedBoardList])

  const initUnarchiveListIndex = useCallback(() => {
    const selectedList: number[] = []
    if (!unarchiveList) return selectedList
    unarchiveList.map((item, index) => {
      if (selectedBoardList.includes(item.projectElementId)) {
        selectedList.push(index)
      }
    })
    return selectedList
  }, [selectedBoardList, unarchiveList])
  const initArchiveListIndex = useCallback(() => {
    const selectedList: number[] = []
    if (!archiveList) return selectedList
    archiveList.map((item, index) => {
      if (selectedBoardList.includes(item.projectElementId)) {
        selectedList.push(index)
      }
    })
    return selectedList
  }, [selectedBoardList, archiveList])

  React.useEffect(() => {
    handleBoardPopup(selectedBoardList)
  }, [selectedBoardList])

  // 새로 추가 된 멤버 인디케이터 애니메이션
  const fadeAddIndi = React.useRef(new Animated.Value(0)).current

  React.useEffect(() => {
    handleAnimation()
  }, [item.addIndication])

  const handleAnimation = () => {
    Animated.timing(fadeAddIndi, {
      toValue: 1,
      duration: 800,
      useNativeDriver: false,
    }).start()
  }
  const colorChange = fadeAddIndi.interpolate({
    inputRange: [0, 1],
    outputRange: [`rgba(194,224,255, 1)`, `rgba(255,255,255, 1)`],
  })

  return (
    <Animated.View
      style={[
        { zIndex: 10000 - idx },
        item.addIndication && { backgroundColor: colorChange },
        deleteButtonHover && { backgroundColor: COLOR.gray.g050 },
      ]}
    >
      <View style={styles.container}>
        <Avatar size="medium" profileUrl={`${secureCdnUrl}/profile/${item.email}`} />
        <View style={styles.contentsArea}>
          <View style={styles.nameInfoArea}>
            <View style={styles.nameInfo}>
              <View style={styles.nameInput}>
                {isEdit ? (
                  <TextInput
                    value={item.name}
                    autoFocus={true}
                    onBlur={() => setIsEdit(false)}
                    onChangeText={(text) => handleValueChange('name', text)}
                    onKeyPress={(e) => {
                      if (e.nativeEvent.key === 'Enter') {
                        setIsEdit(false)
                      }
                    }}
                    inputStyle={{ width: 'auto', ...FONT.txtSmMedium, height: 'auto', padding: 0 }}
                  />
                ) : (
                  <Pressable onPress={() => setIsEdit(true)}>
                    <Text style={{ ...FONT.txtSmMedium }}>{item.name}</Text>
                  </Pressable>
                )}
                {!isEdit && (
                  <Pressable
                    onPress={() => setIsEdit(true)}
                    onHoverIn={() => setIsEditButtonHover(true)}
                    onHoverOut={() => setIsEditButtonHover(false)}
                    style={styles.editButton}
                  >
                    <FAIcon iconName={faPen} size={'2xs'} color={isEditButtonHover ? 'gray.g600' : 'gray.g300'} />
                  </Pressable>
                )}
              </View>
              <Text style={styles.emailText}>{item.email}</Text>
            </View>
          </View>
          <View style={styles.settingArea}>
            <View style={{ position: 'relative' }}>
              {authorityPop && (
                <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 9 }} />
              )}
              <SecondaryButton
                onPress={() => setAuthorityPop(!authorityPop)}
                isOpen={authorityPop}
                title={authority}
                isEnable
              />
              {authorityPop && (
                <View style={{ position: 'absolute', top: 36, left: 0 }}>
                  <ClickOutside onClickOutside={() => setAuthorityPop(false)}>
                    <Select
                      mode={'LIGHT'}
                      width={200}
                      list={[
                        {
                          beforeDiv: false,
                          afterDiv: true,
                          group: [
                            {
                              type: 'COMMON',
                              label: t('workinvite.memberoption.authority.admin'),
                              aboutLabel: t('workinvite.memberoption.authority.aboutadmin'),
                              afterIconName: faCheck,
                              selected: item.authority === 'admin',
                              onPress: () => handleValueChange('authority', 'admin'),
                            },
                          ],
                        },
                        {
                          beforeDiv: false,
                          afterDiv: true,
                          group: [
                            {
                              type: 'COMMON',
                              label: t('workinvite.memberoption.authority.normal'),
                              aboutLabel: t('workinvite.memberoption.authority.aboutnormal'),
                              afterIconName: faCheck,
                              selected: item.authority === 'normal',
                              onPress: () => handleValueChange('authority', 'normal'),
                            },
                          ],
                        },
                        {
                          beforeDiv: false,
                          afterDiv: true,
                          group: [
                            {
                              type: 'COMMON',
                              label: t('workinvite.memberoption.authority.guest'),
                              aboutLabel: t('workinvite.memberoption.authority.aboutguest'),
                              afterIconName: faCheck,
                              selected: item.authority === 'guest',
                              onPress: () => handleValueChange('authority', 'guest'),
                            },
                          ],
                        },
                        // FIXME: 권한 정보는 다음 마일스톤으로 보류됨
                        // {
                        //   beforeDiv: false,
                        //   afterDiv: false,
                        //   group: [
                        //     {
                        //       type: 'INFO',
                        //       beforeIconName: faCircleInfo,
                        //       beforeIconSize: '2xs',
                        //       beforeIconColor: 'gray.g400',
                        //       label: t('workinvite.memberoption.authority.info'),
                        //       labelSize: 12,
                        //       onPress: () => console.log('탭'),
                        //     },
                        //   ],
                        // },
                      ]}
                      visible={true}
                    />
                  </ClickOutside>
                </View>
              )}
            </View>

            <View style={[styles.boardSetting, { marginLeft: 8 }]}>
              {boardPop && <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 9 }} />}
              <DropdownButton
                text={boardButton}
                isOpen={boardPop}
                onOpen={(open) => {
                  setBoardPop(open)
                  setSelectedBoardList(item.selectedBoardList)
                }}
                dropdownList={[]} //filter 오류 막는 용도로 빈 배열 넣어둠
                isEnable
                divide
              />
              {boardPop && (
                <View style={{ position: 'absolute', width: '100%', top: 36, left: 0 }}>
                  <ClickOutside
                    onClickOutside={() => {
                      setBoardPop(false)
                      handleValueChange('errorMessage', '')
                    }}
                  >
                    <View style={styles.popover}>
                      <View style={{ overflow: 'scroll', maxHeight: dimensions.height / 4 }}>
                        {/* FIXME: 우선 초대는 다음 마일스톤으로 보류됨 */}
                        {/* <SelectList
                          initialSelectedIndexes={item.isPriorityInvite ? [0] : []}
                          onPressItem={() => {
                            if (item.isPriorityInvite) {
                              handleValueChange('isPriorityInvite', 'false', 'boolean')
                            } else {
                              handleValueChange('isPriorityInvite', 'true', 'boolean')
                            }
                          }}
                        >
                          <SelectList.Item text={t('workinvite.memberoption.board.defulat')} />
                        </SelectList> */}
                        <PopListSectionTabs
                          label={t('workinvite.memberoption.board.progress')}
                          itemCount={unarchiveList ? unarchiveList.length : 0}
                          countTextColor={'main.red'}
                        >
                          <SelectList
                            initialSelectedIndexes={initUnarchiveListIndex()}
                            onPressItem={(unarchiveListIndex) => {
                              // console.log('onPressItem unarchiveList', item, index)
                              if (unarchiveList) {
                                setSelectedBoardList((prev) => {
                                  const board = unarchiveList[unarchiveListIndex]
                                  const newList = [...prev]
                                  const index = newList.findIndex((item) => item === board.projectElementId)
                                  if (index > -1) {
                                    return removeItemAtIndex(newList, index)
                                  } else {
                                    newList.push(board.projectElementId)
                                    return newList
                                  }
                                })
                              }
                            }}
                          >
                            {unarchiveList && unarchiveList.map((board) => <SelectList.Item text={board.roomName} />)}
                          </SelectList>
                        </PopListSectionTabs>
                        <PopListSectionTabs
                          label={t('workinvite.memberoption.board.archive')}
                          itemCount={archiveList ? archiveList.length : 0}
                          countTextColor={'gray.g450'}
                          divStyle
                        >
                          <SelectList
                            initialSelectedIndexes={initArchiveListIndex()}
                            onPressItem={(archiveListIndex) => {
                              if (archiveList) {
                                setSelectedBoardList((prev) => {
                                  const board = archiveList[archiveListIndex]
                                  const newList = [...prev]
                                  const index = newList.findIndex((item) => item === board.projectElementId)
                                  if (index > -1) {
                                    newList.splice(index, 1)
                                  } else {
                                    newList.push(board.projectElementId)
                                  }
                                  return newList
                                })
                              }
                            }}
                          >
                            {archiveList && archiveList.map((board) => <SelectList.Item text={board.roomName} />)}
                          </SelectList>
                        </PopListSectionTabs>
                      </View>
                      {/* <ListFooter>
                        <View style={{ padding: 8, backgroundColor: COLOR.gray.g100 }}>
                          <Button
                            wide={true}
                            backgroundColor={selectedBoardList.length === 0 ? 'gray.g200' : 'primary.blue500'}
                            borderColor={selectedBoardList.length === 0 ? 'gray.g300' : 'primary.blue500'}
                            disabled={selectedBoardList.length === 0 ? true : false}
                            style={{ width: '100%', paddingHorizontal: 4 }}
                            onPress={() => {
                              handleBoardPopup(selectedBoardList)
                              setBoardPop(false)
                            }}
                          >
                            <Text
                              numberOfLines={1}
                              ellipsizeMode="tail"
                              style={{ color: selectedBoardList.length === 0 ? COLOR.gray.g400 : COLOR.mono.white }}
                            >
                              {t('workinvite.memberoption.board.submit')}
                            </Text>
                          </Button>
                        </View>
                      </ListFooter> */}
                    </View>
                  </ClickOutside>
                </View>
              )}
            </View>
          </View>
          {item.errorMessage ? (
            <View style={styles.errorMessageContainer}>
              {boardPop || authorityPop ? (
                <View style={styles.errorMessageEmptyContainer} />
              ) : (
                <>
                  <Icon name="info" size={16} color="status.busy" />
                  <Text fontName="txtXsMedium" fontColor="status.busy">
                    {item.errorMessage}
                  </Text>
                </>
              )}
            </View>
          ) : (
            <></>
          )}
        </View>
        <Pressable
          onPress={() => handleRemove(idx)}
          onHoverIn={() => setDeleteButtonHover(true)}
          onHoverOut={() => setDeleteButtonHover(false)}
          style={styles.deleteButton}
        >
          <FAIcon iconName={faCircleXmark} color={deleteButtonHover ? 'gray.g600' : 'gray.g400'} />
        </Pressable>
      </View>
    </Animated.View>
  )
}

export default InviteMemberItem

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    paddingVertical: 12,
  },
  contentsArea: {
    flex: 1,
    marginLeft: 8,
  },
  nameInfoArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameInfo: {
    flex: 1,
    marginLeft: 4,
    marginRight: 8,
  },
  nameInput: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  emailText: {
    ...FONT.txtXs,
    color: COLOR.gray.g500,
  },
  settingArea: {
    flexDirection: 'row',
    flex: 1,
    marginTop: 8,
  },
  boardSetting: {
    position: 'relative',
    flex: 1,
  },
  deleteButton: {
    margin: 4,
  },
  editButton: {
    padding: 4,
  },
  popover: {
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingTop: 4,
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 0),
    zIndex: 100,
  },
  errorMessageContainer: {
    flexDirection: 'row',
    marginTop: 7,
  },
  errorMessageEmptyContainer: {
    height: 16,
  },
})
