import { COLOR } from '@rui/foundations'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { AxisContainer } from './Container'
import { AppList } from './Popup/AddPopup'

interface Props {
  isOwner: boolean
  isManager: boolean
  isArchive: boolean
  isDelete: boolean
  auth: string
  ownershipTransfer: boolean
  onPressState: (state: string) => void
}
const WorkMembersTableMore = ({
  isOwner,
  isManager,
  isArchive,
  isDelete,
  auth,
  ownershipTransfer,
  onPressState,
}: Props) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(false)

  const appList = useMemo(() => {
    const list: AppList[] = []

    if (isArchive && isDelete) {
      return [
        {
          state: 'delete',
          title: t('workmember.table.more.delete') /*'삭제하기'*/,
        },
      ]
    }

    if (isArchive) {
      return [
        {
          state: 'invite',
          title: t('workmember.table.more.invite') /*'재초대하기'*/,
        },
        {
          state: 'delete',
          title: t('workmember.table.more.delete') /*'삭제하기'*/,
        },
      ]
    }

    if (isOwner && auth === 'MANAGER') {
      list.push({
        state: 'transfer',
        title: t('workmember.table.more.transfer') /*'워크 소유권 이전'*/,
        disabled: ownershipTransfer,
      })
      list.push({
        state: 'member',
        title: t('workmember.table.more.member') /*'관리 권한 제외'*/,
      })
    }
    if (isOwner && auth === 'MEMBER') {
      list.push({
        state: 'manager',
        title: t('workmember.table.more.manager') /*'관리 권한 부여'*/,
      })
    }
    if ((isManager && ['MEMBER', 'GUEST'].includes(auth)) || (isOwner && auth === 'MANAGER')) {
      list.push({
        state: 'leave',
        title: t('workmember.table.more.leave') /*'멤버에서 제외'*/,
      })
    }

    list.push({
      state: 'chat',
      title: t('workmember.table.more.chat') /*'1:1 대화하기'*/,
      divider: list.length > 0,
    })

    if ((!isOwner && ['OWNER'].includes(auth)) || (!isOwner && !isManager && ['MANAGER'].includes(auth))) {
      list.push({
        state: 'exit',
        title: t('workmember.table.more.exit') /*'나가기 요청'*/,
        divider: true,
      })
    }

    return list
  }, [isOwner, isManager, isArchive, isDelete, auth, ownershipTransfer])
  return (
    <AxisContainer
      isIconOnly
      axisIconName="ellipsis-h"
      iconSize={12}
      buttonAddButtonStyle={{ backgroundColor: COLOR.mono.white }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      appList={appList}
      onPopupState={onPressState}
      onAppsPress={() => {}}
      isWeb={true}
    />
  )
}

export default WorkMembersTableMore
