import { COLOR } from '@rui/foundations'
import React from 'react'
import { StyleSheet, View } from 'react-native'

interface Props {
  border?: boolean
  width?: number
  children?: JSX.Element | JSX.Element[]
}

const TableBodyCell: React.FC<Props> = ({ border = true, width, children }) => {
  return (
    <View style={[styles.cell, width ? { width } : { flex: 1 }, border && { borderRightWidth: 1 }]}>{children}</View>
  )
}

export default TableBodyCell

const styles = StyleSheet.create({
  cell: {
    borderColor: COLOR.gray.g100,
  },
})
