import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { StyleSheet } from 'react-native'

export interface ModalFooterProps {
  children: JSX.Element[] | JSX.Element
}

export const ModalFooter: React.FC<ModalFooterProps> = ({ children }) => {
  return <View style={styles.container}>{children}</View>
}

ModalFooter.displayName = 'ModalBottomSheetType.Footer'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 50,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
})
