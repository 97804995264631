import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

const UnreadDot: React.FC = () => {
  return <View style={styles.dot}></View>
}

export default UnreadDot

const styles = StyleSheet.create({
  dot: {
    width: 6,
    height: 6,
    backgroundColor: COLOR.main.red,
    borderRadius: 12,
  },
})
