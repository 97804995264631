import type { IColors } from '@rocket-mono/libs'
import { COLOR, FONT, changeItemAtIndex, useMobileView } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { WorkmodelDragList } from '../WorkmodelDragList'
import type { UpdateWorkModelCounsultProps } from './types'

const EditWorkModeCousult = ({
  data,
  onChangeTitle,
  onChangeProjectElements,
  onChangeElement,
  onPressAdd,
  onPressDelete,
  onPressDeleteInfo,
}: UpdateWorkModelCounsultProps) => {
  const { t } = useTranslation()
  const { title, projectElements } = data
  const mobileView = useMobileView(500)
  const [scrollEnabled, setScrollEnabled] = React.useState(true)

  const dragList = React.useMemo(
    () =>
      projectElements?.map((o) => ({
        text: o.name,
        labelColor: o.labelObject as IColors,
        isDelete: o.isDelete,
      })),
    [projectElements],
  )

  const onChangeList = React.useCallback(
    (from: number, to: number) => {
      onChangeProjectElements && onChangeProjectElements(changeItemAtIndex(projectElements, from, to))
    },
    [projectElements],
  )

  return (
    <View style={!!mobileView && styles.mobileContainer}>
      <ScrollView scrollEnabled={scrollEnabled} style={[styles.workInfoSection, !mobileView && { minHeight: 300 }]}>
        <View>
          <View>
            <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodeledit.workname')}</Text>
            <TextInput
              value={title}
              placeholder={t('workmodal.basic.inputplaceholder')}
              maxLength={50}
              onChangeText={onChangeTitle}
              style={styles.inputStyle}
            />
            <Text
              style={{
                ...FONT.txt2Xs,
                color: COLOR.gray.g500,
                textAlign: 'right',
              }}
            >
              ({title.length}/50)
            </Text>
          </View>
          <View style={[styles.labelSection, mobileView ? { paddingBottom: 50 } : { paddingBottom: 32 }]}>
            <View>
              <Text style={styles.labelName}>{t('workmodeledit.csp.part')}</Text>
            </View>
            <WorkmodelDragList
              editType={true}
              list={dragList}
              setScrollEnabled={setScrollEnabled}
              onChangeList={onChangeList}
              onClickRemoveButton={onPressDelete}
              onPressAdd={onPressAdd}
              onChangeElement={onChangeElement}
              onPressDeleteInfo={onPressDeleteInfo}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default EditWorkModeCousult

export const styles = StyleSheet.create({
  mobileContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  workInfoSection: {
    flex: 1,
  },
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelSection: {
    marginTop: 12,
  },
  labelName: {
    ...FONT.txtSmMedium,
    marginBottom: 8,
  },
  inputStyle: {
    ...FONT.txtSm,
    width: '100%',
    height: 36,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
  },
  dateSection: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingTop: 12,
  },
})
