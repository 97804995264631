import { TableorderMenuOptionGroup, TableorderMenuOptionGroupType } from '@rocket/types'
import { changeItemAtIndex, removeItemAtIndex, replaceItemAtIndex } from '@rui/foundations'
import * as React from 'react'
import { useTableorderShop } from '../ShopProvider'
import Context from './context'
import type { MenuOptionGroupType, OptionGroupType, ProviderProps } from './types'

const Provider = ({ astro, children }: ProviderProps) => {
  const { shopId } = useTableorderShop()

  const [menuOptionGroupList, setMenuOptionGroupList] = React.useState<MenuOptionGroupType[]>([])
  const [optionGroupForm, setOptionGroupForm] = React.useState<OptionGroupType[]>([])

  const convertOptionGroup = React.useCallback(
    (group: TableorderMenuOptionGroup): Promise<OptionGroupType> =>
      astro.readMenuOptionList(group.id).then((list) => ({
        ...group,
        optionList: list.map(({ id, name, price }) => ({
          id,
          name,
          price: String(price),
        })),
      })),
    [],
  )

  const saveOptionGroup = React.useCallback(() => {
    const deleteIds = menuOptionGroupList
      .map(({ id }) => id)
      .filter(
        (id) =>
          !optionGroupForm
            .filter((o) => !!o.id)
            .map(({ id }) => id)
            .includes(id),
      )

    /**
     * 사용하지 않는 옵션그룹 제거
     */
    return Promise.all(deleteIds.map((id) => astro.deleteMenuOptionGroup(id)))
      .then(() =>
        /**
         * 사용하지 않는 옵션 제거
         */
        Promise.all(
          optionGroupForm
            .filter(({ id }) => id !== undefined)
            .map(({ id: groupId, optionList }) => {
              return !!groupId
                ? astro
                    .readMenuOptionList(groupId)
                    .then((list) =>
                      list.map(({ id }) => id).filter((id) => !optionList.map(({ id }) => id).includes(id)),
                    )
                    .then((deleteIds) => Promise.all([deleteIds.map((id) => astro.deleteMenuOption(groupId, id))]))
                : true
            }),
        ),
      )
      .then(async () => {
        let sequenceNumber = 0
        for await (const form of optionGroupForm) {
          const { id, type, name, isMultipleChoicesAvailable, optionList } = form

          const payload = {
            shopId,
            name,
            sequenceNumber,
            isMultipleChoicesAvailable,
            type,
            isDeleted: false,
          }
          await Promise.resolve(
            !!id ? astro.updateMenuOptionGroup(id, { id, ...payload }) : astro.createMenuOptionGroup(payload),
          ).then(async (group) => {
            for await (const option of optionList) {
              const { id, name, price } = option
              const payload = {
                name,
                price: Number(price),
                sequenceNumber,
              }
              await Promise.resolve(
                !!id
                  ? astro.updateMenuOption(group.id, id, {
                      id,
                      ...payload,
                    })
                  : astro.createMenuOption(group.id, payload),
              )
            }
          })
          sequenceNumber++
        }
      })
      .then(() => fetchMenuOptionGroupList(shopId))
  }, [shopId, menuOptionGroupList, optionGroupForm])

  const createOptionGroup = React.useCallback((type: TableorderMenuOptionGroupType) => {
    const group = {
      type,
      name: '',
      isMultipleChoicesAvailable: true,
      optionList: [],
    }
    setOptionGroupForm((prev) => [...prev, group])
  }, [])

  const updateOptionGroup = React.useCallback((index: number, group: OptionGroupType) => {
    setOptionGroupForm((prev) => replaceItemAtIndex(prev, index, group))
  }, [])

  const deleteOptionGroup = React.useCallback((index: number) => {
    setOptionGroupForm((prev) => removeItemAtIndex(prev, index))
  }, [])

  const createOption = React.useCallback(
    (groupIndex: number) => {
      const group = optionGroupForm[groupIndex]
      const optionList = [...group.optionList, { name: '', price: '' }]
      updateOptionGroup(groupIndex, { ...group, optionList })
    },
    [optionGroupForm, updateOptionGroup],
  )

  const deleteOption = React.useCallback(
    (groupIndex: number, index: number) => {
      const group = optionGroupForm[groupIndex]
      const optionList = removeItemAtIndex(group.optionList, index)
      updateOptionGroup(groupIndex, { ...group, optionList })
    },
    [optionGroupForm, updateOptionGroup],
  )

  const changeOptionList = React.useCallback(
    (groupIndex: number, from: number, to: number) => {
      const group = optionGroupForm[groupIndex]
      const optionList = changeItemAtIndex(group.optionList, from, to)
      updateOptionGroup(groupIndex, { ...group, optionList })
    },
    [optionGroupForm, updateOptionGroup],
  )

  const fetchMenuOptionGroupList = React.useCallback((shopId: string) => {
    astro
      .readMenuOptionGroupList(shopId)
      .then((list) => list.filter(({ isDeleted }) => !isDeleted))
      .then((list) =>
        Promise.all(list.map((group) => astro.readMenuOptionList(group.id).then((options) => ({ ...group, options })))),
      )
      .then(setMenuOptionGroupList)
      .catch((err) => {
        console.error(err)
      })
  }, [])

  React.useEffect(() => {
    fetchMenuOptionGroupList(shopId)
  }, [shopId])

  React.useEffect(() => {
    if (menuOptionGroupList !== undefined)
      Promise.all(menuOptionGroupList.map(convertOptionGroup)).then((list) => {
        setOptionGroupForm(
          list.map(({ id, name, type, isMultipleChoicesAvailable, optionList }) => ({
            id,
            name,
            type,
            isMultipleChoicesAvailable,
            optionList,
          })),
        )
      })
  }, [menuOptionGroupList])

  return (
    <Context.Provider
      value={{
        optionGroupForm,
        menuOptionGroupList,
        saveOptionGroup,
        createOptionGroup,
        updateOptionGroup,
        deleteOptionGroup,
        createOption,
        deleteOption,
        changeOptionList,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
