import React, { useEffect, useState } from 'react'

import { Modal } from '@rocket-mono/foundations'
import { COLOR, useMobileView } from '@rocket-mono/libs'
import { SkeletonMemberInviteScreen } from '@rocket/skeleton'
import { Channel, Project } from '@rocket/types'
import { i18n } from 'i18next'
import { DimensionValue, Dimensions, StyleSheet } from 'react-native'
import type { MemberInviteItemType } from '../InviteMemberItem'
import type { WorkListType } from '../MemberInviteWorkList'
import type { MemberListItem, MemberStateType } from '../MemberList/types'
import Providers from '../providers'
import MemberInviteScreen from './contents'

interface Props {
  i18n: i18n
  skeleton?: boolean
  currentProject?: Project
  secureCdnUrl?: string
  onPressClose: () => void
  inviteWorkList: WorkListType[]
  inviteMemberList: MemberInviteItemType[]
  inviteWorkDetail: MemberListItem[]
  unarchiveList?: Channel[]
  archiveList?: Channel[]
  onEmailInvite: (name: string, email: string) => void
  inviteMemberHandleRemove: (index: number) => void
  inviteMemberHandleValueChange: (idx: number, key: string, value: string, type?: string) => void
  inviteMemberHandleBoardPopup: (index: number, selectedBoardList: string[]) => void
  onInvite: () => void
}

const MemberInviteScreenModal: React.FC<Props> = ({
  i18n,
  skeleton = true,
  secureCdnUrl,
  currentProject,
  onPressClose,
  inviteWorkList,
  inviteMemberList,
  // inviteWorkDetail,
  unarchiveList,
  archiveList,
  onEmailInvite,
  inviteMemberHandleRemove,
  inviteMemberHandleValueChange,
  inviteMemberHandleBoardPopup,
  onInvite,
}) => {
  const windowWidth = Dimensions.get('window').width
  const [modalWidth, setModalWidth] = useState<DimensionValue>(820)
  const [modalHeight, setModalHeight] = useState<DimensionValue>(522)
  const columnPoint = useMobileView(768)

  useEffect(() => {
    if (820 > windowWidth) {
      setModalWidth('100%')
    } else {
      setModalWidth(820)
    }
    columnPoint ? setModalHeight('100%') : setModalHeight(552)
  }, [Dimensions, columnPoint])

  return (
    <Providers i18n={i18n}>
      <Modal
        animationType={'fade'}
        visible={true}
        containerStyle={{
          width: modalWidth,
          height: modalHeight,
          maxWidth: 820,
          minWidth: 'auto',
          backgroundColor: COLOR.mono.white,
          borderRadius: columnPoint ? 0 : 8,
        }}
      >
        {skeleton ? (
          <>
            <SkeletonMemberInviteScreen columnPoint={columnPoint} onClose={onPressClose} />
          </>
        ) : (
          <MemberInviteScreen
            currentProject={currentProject}
            secureCdnUrl={secureCdnUrl}
            columnPoint={columnPoint}
            inviteWorkList={inviteWorkList}
            inviteMemberList={inviteMemberList}
            onPressClose={onPressClose}
            unarchiveList={unarchiveList}
            archiveList={archiveList}
            onEmailInvite={onEmailInvite}
            inviteMemberHandleRemove={inviteMemberHandleRemove}
            inviteMemberHandleValueChange={inviteMemberHandleValueChange}
            inviteMemberHandleBoardPopup={inviteMemberHandleBoardPopup}
            onInvite={onInvite}
          />
        )}
      </Modal>
    </Providers>
  )
}

export default MemberInviteScreenModal

export { MemberInviteItemType, MemberInviteScreenModal, MemberListItem, MemberStateType, WorkListType }

const styles = StyleSheet.create({
  container: {
    width: 424,
    minHeight: 560,
    backgroundColor: COLOR.mono.white,
  },
  headerTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
    width: 350,
  },
  headerBottomContainer: {
    marginTop: 4,
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerButton: {
    height: 50,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  bodyArea: {
    flexDirection: 'row',
  },
  searchArea: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: COLOR.gray.g050,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  emailSearch: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    paddingBottom: 20,
    marginBottom: 20,
  },
  inviteListArea: {
    flex: 1,
  },
  inviteList: {
    paddingTop: 8,
    paddingBottom: 60,
    paddingHorizontal: 24,
  },
  itemCenter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  workDetailLayer: {
    position: 'absolute',
    zIndex: 99,
    top: 16,
    right: 20,
    bottom: 20,
    left: 20,
  },
})
