import React from 'react'

export const NEXT = 'NEXT'
export const PREV = 'PREV'

export const CarouselContainer: React.FC<{
  children?: React.ReactNode
  sliding: boolean
  dir: typeof PREV | typeof NEXT
}> = ({ children, sliding, dir }) => {
  const transition = sliding ? 'none' : 'transform 1s ease'
  const transform = !sliding ? 'translateX(-100%)' : dir === PREV ? 'translateX(-200%)' : 'translateX(0%)'
  return <div style={{ display: 'flex', transition, transform }}>{children}</div>
}

export const Wrapper: React.FC<{
  children?: React.ReactNode
}> = ({ children }) => {
  return <div style={{ width: '100%', overflow: 'hidden' }}>{children}</div>
}

export const CarouselSlot: React.FC<{
  children?: React.ReactNode
  order: number
}> = ({ children, order }) => {
  return (
    <div
      style={{
        flex: '1 0 100%',
        order,
      }}
    >
      {children}
    </div>
  )
}
