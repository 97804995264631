import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SelectList } from '@rocket-molecules/popselector'
import { View } from '@rocket-mono/foundations'
import { IColors } from '@rocket-mono/libs'
import React, { useEffect, useState } from 'react'
import { MemberInfoType } from './Table'
import { WorkMembersTableCategory } from './WorkMembers'

export type TableHeaderCellType = {
  field: string
  width: number
  border: boolean
  cell: (item: MemberInfoType) => JSX.Element
}

type TableHeaderCellSelectType = {
  code: string
  label: string
  mark?: string
  icon?: IconProp
  iconColor?: IColors
  rightIcon?: JSX.Element
}
export interface TableHeaderCellProps {
  iconOnly?: boolean
  iconName?: IconProp
  width?: number
  field: string
  isMark?: boolean
  markValue?: string
  staticSize?: boolean
  select?: TableHeaderCellSelectType[]
  onSelect?: (code: string) => void
}

const TableHeaderCell = ({
  iconOnly = false,
  iconName,
  width,
  field,
  isMark = true,
  markValue,
  staticSize,
  select,
  onSelect,
}: TableHeaderCellProps) => {
  const [mark, setMark] = useState('')
  const [isMoreActive, setIsMoreActive] = React.useState(false)

  // const []

  useEffect(() => {
    setMark(markValue || '')
  }, [markValue])
  return (
    <View style={width ? { width } : { flex: 1 }}>
      <WorkMembersTableCategory
        width={width}
        iconOnly={iconOnly}
        iconName={iconName}
        isMoreActive={isMoreActive}
        setIsMoreActive={setIsMoreActive}
        fieldText={field}
        isMark={isMark}
        markText={mark}
        staticSize={staticSize}
      >
        {select && (
          <SelectList
            onPressItem={(index) => {
              setMark(select[index].mark || '')
              onSelect && onSelect(select[index].code)
              setIsMoreActive(false)
            }}
          >
            {select.map((o, idx) => (
              <SelectList.Item
                key={idx}
                text={o.label}
                iconName={o.icon}
                iconColor={o.iconColor}
                rightIcon={o.rightIcon}
                // onPress={() => onSelect && onSelect(o.code)}
              />
            ))}
          </SelectList>
        )}
      </WorkMembersTableCategory>
    </View>
  )
}

export default TableHeaderCell
