import { i18n } from 'i18next'
import React from 'react'
import Providers from '../../providers'
import Provider from './provider'
import { MessageListView } from './view'

interface Props {
  i18n: i18n
  isShow: boolean
  setUnreadCount: (unread: number) => void
  onPressButtonCreate?: () => void
}

export const MessageListScreen: React.FC<Props> = ({ i18n, isShow, setUnreadCount, onPressButtonCreate }) => {
  console.log('MessageListScreen')

  return (
    <Providers i18n={i18n}>
      <Provider>
        <MessageListView isShow={isShow} setUnreadCount={setUnreadCount} onPressButtonCreate={onPressButtonCreate} />
      </Provider>
    </Providers>
  )
}
