import { Text } from '@rocket-atoms/text'
import React from 'react'
import type { Text as RNText } from 'react-native'
import type { ParserRule } from './types'

type ArgsType = { [key: string]: unknown }

export const MENTION_TEMPLATE = '<m chatRoomMemberNo=(?<chatRoomMemberNo>\\d+|all), memberName="(?<memberName>[^"]+)">'

export const CARD_LINK_TEMPLATE =
  '<m cardNo=(?<cardNo>\\d+|all), cardName="(?<cardName>[^"]+)", channelNo=(?<channelNo>\\d+|all)>'

export function parseMessage(
  source: string,
  entries: ParserRule[],
  textAttrs: Record<string, unknown> = {},
): JSX.Element[] {
  if (!source)
    return [
      <Text key={`empty-token-${Date.now()}-${source.length}`} {...textAttrs}>
        {''}
      </Text>,
    ]

  const elements: JSX.Element[] = []
  const timestamp = Date.now()

  const matcher = new RegExp(entries.map(({ format }) => format).join('|'), 'g')
  const matches = source.match(matcher)

  if (matches) {
    matches.forEach((match, index) => {
      if (!source) return

      const replacer = entries.find(({ format }) => {
        const matcher = new RegExp(format, 'g')
        const matches = match.match(matcher)
        return matches && matches?.length > 0 ? true : false
      })?.replacer

      const [before, _] = source.split(match)

      const after = source.replace(before + match, '')

      if (before)
        elements.push(
          <Text key={`token-${timestamp}-${source.length}-${index}`} {...textAttrs}>
            {before}
          </Text>,
        )

      replacer && elements.push(replacer(match, `${timestamp}`, index, React.createRef<RNText>()))

      source = after

      if (!source || source === '') {
        return
      }
    })
  }

  if (source) {
    elements.push(
      <Text key={`last-token-${timestamp}-${source.length}`} {...textAttrs}>
        {source}
      </Text>,
    )
  }

  return elements
}

export function JSXToPlainText(elm: JSX.Element): string {
  if (typeof elm === 'string') return elm
  if (elm.props.children instanceof Array) {
    return elm.props.children.map((child: JSX.Element) => JSXToPlainText(child)).join('')
  }
  return elm.props.children
}

export function parseSymbolicMessage(message: string | null): {
  key: string
  args: ArgsType
} {
  try {
    if (!message) {
      throw Error('symbol이 존재하지 않습니다.')
    }

    const parsed = JSON.parse(message)
    if (!parsed) {
      throw Error('JSON.parse 결과 null 입니다.')
    }
    return parsed as { key: string; args: ArgsType }
  } catch {
    return { key: '', args: {} }
  }
}
