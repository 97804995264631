import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native'
import type { DropdownItem } from './types'

interface Props {
  code: string | string[]
  notification?: boolean
  isOpen: boolean
  offsetHeight?: number
  offsetY: number
  onOpen?: (open: boolean) => void
  dropdownList: DropdownItem[]
  onCode: (code: string) => void
  containerStyle?: ViewStyle
  style?: ViewStyle
}
interface ItemProps {
  item: DropdownItem
}

const DropdownList = ({
  code,
  notification,
  isOpen,
  offsetHeight,
  offsetY,
  onOpen,
  dropdownList,
  onCode,
  containerStyle,
  style,
}: Props) => {
  const Item = ({ item }: ItemProps) => {
    const [isHover, setIsHover] = React.useState(false)
    const isActive = React.useMemo(
      () => (typeof code === 'string' ? code === item.code : code.includes(item.code)),
      [item, code],
    )
    return (
      <Pressable
        style={[
          styles.listButton,
          {
            backgroundColor: isActive ? COLOR.main.blue : isHover ? COLOR.gray.g050 : COLOR.mono.white,
          },
        ]}
        onPress={() => onCode(item.code)}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
      >
        <Text
          style={[
            styles.listText,
            {
              color: item.code === code ? COLOR.mono.white : COLOR.mono.black,
            },
          ]}
        >
          {item.title}
        </Text>
        {isActive && <XEIcon name="check" color="mono.white" size={12} />}
      </Pressable>
    )
  }
  return (
    <View
      style={[
        {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: offsetHeight ? offsetHeight : '100%',
          display: isOpen ? 'flex' : 'none',
        },
      ]}
    >
      <Pressable
        style={[
          {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
        ]}
        onPress={() => onOpen && onOpen(false)}
      />
      <View
        style={[
          containerStyle,
          style,
          { top: offsetY + 35 },
          styles.container,
          notification && { borderColor: COLOR.primary.blue500 },
        ]}
      >
        {dropdownList.map((item) => (
          <Item item={item} key={`dropdown-list-${item.id}`} />
        ))}
      </View>
    </View>
  )
}

export const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g300,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    paddingBottom: 4,
    ...getBoxShadow(0, 0, 0, 0.05, 5, 0, 2, 0),
    backgroundColor: COLOR.mono.white,
  },
  listButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    borderColor: COLOR.gray.g300,
    height: 32,
  },
  listText: {
    ...FONT.txtSm,
    color: COLOR.mono.black,
  },
})

export default React.memo(DropdownList)
