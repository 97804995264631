import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { LayoutChangeEvent, StyleSheet, ViewStyle } from 'react-native'

import { COLOR } from '@rui/foundations'

interface Props {
  style?: ViewStyle
  children: JSX.Element
  HeaderComponent?: JSX.Element
  FragmentsComponent?: JSX.Element
  isBorder?: boolean
}

const FragmentsLayout: React.FC<Props> = ({
  style,
  children,
  HeaderComponent,
  FragmentsComponent,
  isBorder = true,
}): JSX.Element => {
  const [windowWidth, setWindowWidth] = React.useState(0)

  const onLayout = React.useCallback((e: LayoutChangeEvent) => {
    const { layout } = e.nativeEvent
    setWindowWidth(layout.width)
  }, [])

  return (
    <View style={styles.containerCommon} nativeID="leftFragmentsLayout">
      {HeaderComponent}
      <View style={{ flex: 1 }}>
        <View
          onLayout={onLayout}
          style={[
            { flex: 1 },
            style,
            windowWidth > 800
              ? isBorder
                ? styles.expandContainer
                : { ...styles.expandContainer, ...styles.notborder }
              : styles.container,
          ]}
        >
          <View
            style={[
              styles.sectionCommon,
              windowWidth > 800
                ? isBorder
                  ? styles.expandSection
                  : { ...styles.expandSection, ...styles.notborder }
                : styles.section,
            ]}
          >
            {children}
          </View>
          {FragmentsComponent && (
            <View
              nativeID="rightFragmentsLayout"
              style={[
                styles.sectionCommon,
                windowWidth > 800 ? styles.expandThreadSection : styles.threadSection,
                windowWidth > 800
                  ? isBorder
                    ? styles.expandSection
                    : { ...styles.expandSection, ...styles.notborder }
                  : styles.section,
              ]}
            >
              {FragmentsComponent}
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default FragmentsLayout

const styles = StyleSheet.create({
  containerCommon: {
    height: '100%',
    flex: 1,
  },
  container: {},
  expandContainer: {
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 4,
    backgroundColor: COLOR.mono.paleWhite,
  },
  sectionCommon: {
    flex: 1,
    overflow: 'hidden',
    backgroundColor: COLOR.mono.white,
  },
  section: { backgroundColor: COLOR.mono.white },
  expandSection: {
    marginHorizontal: 4,
  },
  threadSection: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  expandThreadSection: {
    maxWidth: 400,
  },
  notborder: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    marginHorizontal: 0,
    marginVertical: 0,
    borderRadius: 0,
  },
})
