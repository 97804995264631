import * as React from 'react'

import { useCurrentUser, useModalDialog } from '@rocket-mono/providers'
import type {
  Project,
  ProjectInvitationAddon,
  ProjectMember,
  ProjectMemberAuth,
  UpdateProjectPayload,
} from '@rocket/types'
import { useToast } from '@rui/atoms'
import { replaceItemAtIndex } from '@rui/foundations'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import Context from './context'
import type { ProjectMemberType, ProviderProps } from './types'

const Provider = ({ projectId, astro, subscribe, onProjectLeave, children }: ProviderProps) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const [currentProject, setCurrentProject] = React.useState<Project>()
  const [projectMemberList, setProjectMemberList] = React.useState<ProjectMemberType[]>()

  const currentProjectMember = React.useMemo(
    () => projectMemberList?.find((o) => o.userId === String(currentUser.id)),
    [currentUser, projectMemberList],
  )

  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const ownershipTransfer = React.useCallback(
    (targetUserId: string) => {
      return astro
        .createOwnershipTransfer({
          projectId,
          targetUserId,
          requestUserId: String(currentUser.id),
          requestDate: new Date(),
          expirationDate: null,
        })
        .then(() => {
          return fetchProjectMemberList()
        })
        .then(() => Promise.resolve())
    },
    [projectId, currentUser],
  )

  const showInviteModal = React.useCallback(() => {
    window.postMessage(
      {
        action: 'openModalInvitation',
        payload: {
          projectId,
        },
      },
      '*',
    )
  }, [projectId])

  const updateProjectMemberList = React.useCallback(
    (member: ProjectMemberType) => {
      setProjectMemberList((prev) => {
        if (prev === undefined) return [member]
        const idx = prev?.findIndex((o) => o.id === member.id)
        return idx < 0 ? [...prev, member] : replaceItemAtIndex(prev, idx, member)
      })

      return member
    },
    [projectMemberList],
  )

  const updateProject = React.useCallback((payload: UpdateProjectPayload) => {
    return astro.updateProject(payload).then((project) => {
      setCurrentProject(project)
      return project
    })
  }, [])

  const withdrawalMember = React.useCallback(
    (targetUserId: string) => {
      return astro
        .createProjectWithdrawalSelf({
          projectId,
          requestUserId: String(currentUser.id),
          targetUserId,
          requestDate: new Date(),
          expirationDate: null,
        })
        .then(() => {
          fetchProjectMemberList()
        })
        .then(() => {
          const duration = 1500
          showToastMessage(
            {
              type: 'Success',
              title: t('toast.confirmed'),
              // animated: true,
              position: 'BOTTOM_CENTER',
              // duration,
            },
            duration,
          )
          if (Platform.OS === 'web') {
            setTimeout(() => {
              onProjectLeave && onProjectLeave()
            }, duration + 100)
          }
        })
    },
    [projectId, currentUser, onProjectLeave],
  )

  const inviteUpdateMember = React.useCallback(
    (memberId: string, roomIds: string[]) => {
      if (projectMemberList) {
        const member = projectMemberList.find((o) => o.id === memberId)
        if (member) {
          // const targetUserId = member.userId
          astro.readProjectInvitationTiny(projectId, member.email).then((res) => {
            console.log('readProjectInvitationTiny', res, roomIds)
            // if (res) {
            //   astro
            //     .updateProjectInvitation({ ...res, status: 'REJECT' })
            //     .then(() => {
            //       const addon: ProjectInvitationAddon =
            //         res && res.addon
            //           ? res.addon
            //           : {
            //               authorityType: member.auth,
            //               email: member.email,
            //               memberName: member.name,
            //               roomIds: roomIds || [],
            //             }

            //       return astro.createProjectInvitation([
            //         {
            //           projectId,
            //           requestUserId: String(currentUser.id),
            //           targetUserId,
            //           addon: roomIds ? { ...addon, roomIds } : addon,
            //           expirationDate: null,
            //           requestDate: res?.requestDate || new Date(),
            //         },
            //       ])
            //     })
            // }
            // if (res)
            // return astro.updateProjectInvitation({
            //   id: res.id,
            //     projectId,
            //     requestUserId: String(currentUser.id),
            //     targetUserId,
            //     // addon: {
            //     //     authorityType: member.auth,
            //     //     email: member.email,
            //     //     memberName: member.name,
            //     //     roomIds: roomIds,
            //     //   },
            //     expirationDate: null,
            //     requestDate: new Date(),
            //   })
            //   else return astro.createProjectInvitation([
            //   {
            //     projectId,
            //     requestUserId: String(currentUser.id),
            //     targetUserId,
            //     addon: roomIds ? { ...addon, roomIds } : addon,
            //     expirationDate: null,
            //     requestDate: new Date(),
            //   },
            // ])
          })
          // .then(() => {
          //   fetchProjectMemberList()
          // })
        }
        // astro.updateProjectInvitation({
        //   projectId,
        //   requestUserId: String(currentUser.id),
        //   targetUserId,
        //   addon: roomIds ? { ...addon, roomIds } : addon,
        //   expirationDate: null,
        //   requestDate: new Date(),
        // })
      }
    },
    [projectId, currentUser, projectMemberList],
  )

  const inviteMember = React.useCallback(
    (memberId: string, roomIds?: string[]) => {
      if (projectMemberList) {
        const member = projectMemberList.find((o) => o.id === memberId)

        if (member) {
          astro
            .readProjectInvitationTiny(projectId, member.email)
            .then((res) => {
              const targetUserId = member.userId
              const addon: ProjectInvitationAddon =
                res && res.addon
                  ? res.addon
                  : {
                      authorityType: member.auth,
                      email: member.email,
                      memberName: member.name,
                      roomIds: roomIds || [],
                    }
              return astro.createProjectInvitation([
                {
                  projectId,
                  requestUserId: String(currentUser.id),
                  targetUserId,
                  addon: roomIds ? { ...addon, roomIds } : addon,
                  expirationDate: null,
                  requestDate: new Date(),
                },
              ])
            })
            .then(() => {
              fetchProjectMemberList()
            })
        }
      }
    },
    [projectId, currentUser, projectMemberList],
  )

  const updateMember = React.useCallback(
    (member: ProjectMember) => {
      updateProjectMemberList(member)
      return astro
        .updateProjectMember(projectId, { ...member, updatedAt: new Date() })
        .then((member) =>
          member.userId !== 'null' && member.userId !== null
            ? astro.readUserState(member.userId).then((userState) => ({ ...member, userState }))
            : Promise.resolve(member),
        )
      // .then(updateProjectMemberList)
    },
    [projectId],
  )
  const archiveMember = React.useCallback(
    (memberId: string) => {
      return astro
        .deleteProjectMember(projectId, memberId)
        .then((member) =>
          member.userId !== 'null' && member.userId !== null
            ? astro.readUserState(member.userId).then((userState) => ({ ...member, userState }))
            : Promise.resolve(member),
        )
        .then(updateProjectMemberList)
        .then((member) => {
          showToastMessage({
            type: 'Success',
            title: t('toast.confirmed'),
            // animated: true,
            position: 'BOTTOM_CENTER',
          })
          return Promise.resolve(member)
        })
    },
    [projectId],
  )
  const deleteMember = React.useCallback(
    (memberId: string) => {
      return astro.deleteProjectMemberHard(projectId, memberId).then(() => fetchProjectMemberList())
    },
    [projectId],
  )

  const updateMemberName = React.useCallback(
    (memberId: string, name: string) => {
      if (projectMemberList) {
        const member = projectMemberList.find(({ id }) => id === memberId)
        if (member) {
          updateMember({ ...member, name }).then(() => {
            showToastMessage({
              type: 'Success',
              title: t('toast.confirmed'),
              // animated: true,
              position: 'BOTTOM_CENTER',
            })
          })
        }
      }
    },
    [projectMemberList],
  )

  const updateMemberAuth = React.useCallback(
    (memberId: string, auth: ProjectMemberAuth) => {
      if (projectMemberList) {
        const member = projectMemberList.find(({ id }) => id === memberId)
        if (member && member.auth !== 'OWNER' && member.auth !== auth) {
          const title = t('workmember.dialog.permission.title') // '권한을 변경하시겠습니까?'
          const list = [
            {
              name: t('workmember.dialog.permission.button'), // '변경',
              action: () => {
                updateMember({ ...member, auth })
                  .then(() => {
                    showToastMessage({
                      type: 'Success',
                      title: t('toast.confirmed'),
                      // animated: true,
                      position: 'BOTTOM_CENTER',
                    })
                  })
                  .then(hideDialogMessage)
              },
            },
          ]

          showDialogMessage({
            type: 'BOTTOM',
            title,
            list,
            cancelText: t('workmember.dialog.cancel'), // '취소',
            onCancel: hideDialogMessage,
          })
        }
      }
    },
    [projectMemberList],
  )

  const updateMemberEtc = React.useCallback(
    (memberId: string, memberEtc: string) => {
      const member = projectMemberList?.find(({ id }) => id === memberId)
      if (member) {
        updateMember({ ...member, memberEtc })
      }
    },
    [projectMemberList],
  )

  const fetchProject = React.useCallback(() => {
    return astro.readProject(projectId).then(setCurrentProject)
  }, [projectId])

  const fetchProjectMemberList = React.useCallback(
    (init?: boolean) => {
      return astro
        .readProjectMemberList(projectId, false, '&')
        .then((prev) => {
          if (init) setProjectMemberList(prev)
          return astro.readProjectMemberList(projectId, true, '&').then((list) => [...prev, ...list])
        })
        .then((list) => {
          if (init) setProjectMemberList(list)
          return list
        })
        .then((list) =>
          Promise.all(
            list.map((o) =>
              astro.readProjectInvitationTiny(projectId, o.email).then((res) => {
                return {
                  ...o,
                  inviteDate: res?.requestDate,
                  inviteRoomIds: res?.addon?.roomIds || [],
                }
              }),
            ),
          ),
        )
        .then((list) =>
          Promise.all(
            list.map((o) => {
              const requestUserId = String(currentUser.id)
              const targetUserId = o.userId
              if (targetUserId === 'null')
                return {
                  ...o,
                  ownershipTransfer: false,
                }
              else
                return astro
                  .readOwnershipTransfer({
                    projectId,
                    requestUserId,
                    targetUserId,
                  })
                  .then((res) => {
                    return {
                      ...o,
                      ownershipTransfer: res ? true : false,
                    }
                  })
                  .catch(() => {
                    return {
                      ...o,
                      ownershipTransfer: false,
                    }
                  })
            }),
          ),
        )
        .then(fetchUserState)
    },
    [projectId],
  )

  const fetchUserState = React.useCallback((projectMemberList: ProjectMember[]) => {
    if (projectMemberList) {
      Promise.all(
        projectMemberList.map((member) => {
          return member.userId !== 'null' && member.userId !== null
            ? astro.readUserState(member.userId).then((userState) => {
                return astro
                  .readUserStatePeriods(String(currentUser.id))
                  .then((periods) => {
                    const busyPeriods = periods.filter((o) => o.applyUserState === 3)
                    const offPeriods = periods.filter((o) => o.applyUserState === 4)

                    const busyStartDate =
                      busyPeriods.length > 0 && userState === 'busy'
                        ? new Date(busyPeriods[0].applyStartTimestamp)
                        : undefined
                    const busyEndDate =
                      busyPeriods.length > 0 && userState === 'busy'
                        ? new Date(busyPeriods[0].applyEndTimestamp)
                        : undefined
                    const offStartDate = offPeriods.length > 0 ? new Date(offPeriods[0].applyStartTimestamp) : undefined
                    const offEndDate = offPeriods.length > 0 ? new Date(offPeriods[0].applyEndTimestamp) : undefined

                    const userStateDate = {
                      busyStartDate,
                      busyEndDate,
                      offStartDate,
                      offEndDate,
                    }
                    return { ...member, userState, userStateDate }
                  })
                  .catch(() => {
                    // setStateData({ state })
                    return { ...member, userState }
                  })
              })
            : { ...member, userState: 'off' as const }
        }),
      ).then(setProjectMemberList)
    }
  }, [])

  subscribe(projectMemberList?.map((o) => `/subscribe/${o.userId}/state`) || '', () => {
    projectMemberList && fetchUserState(projectMemberList)
  })

  subscribe(`/subscribe/project/${projectId}/update`, () => {
    fetchProject()
      .then(() => new Promise<void>((resolve) => setTimeout(() => resolve(), 500)))
      .then(() => fetchProjectMemberList())
  })

  React.useEffect(() => {
    fetchProject()
      .then(() => fetchProjectMemberList(true))
      .then(() => setIsLoading(false))
  }, [projectId])

  return (
    <Context.Provider
      value={{
        isLoading,
        currentProject,
        projectMemberList,
        currentProjectMember,
        ownershipTransfer,
        showInviteModal,
        updateProject,
        inviteMember,
        inviteUpdateMember,
        withdrawalMember,
        archiveMember,
        deleteMember,
        updateMemberName,
        updateMemberAuth,
        updateMemberEtc,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
