import * as React from 'react'

import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { StyleSheet, Text, View } from 'react-native'
import QuantityButton from '../Button/QuantityButton'

interface Props {
  totalPrice: string
  initQuantity: number
  onQuantity?: (arg0: number) => void
}
const FooterPrice = ({ totalPrice, initQuantity, onQuantity }: Props) => {
  const [quantity, setQuantity] = React.useState(initQuantity ?? 1)
  return (
    <View style={styles.container}>
      <QuantityButton
        quantity={quantity}
        setQuantity={(quantity) => {
          setQuantity(quantity)
          onQuantity && onQuantity(quantity)
        }}
      />
      <View style={styles.priceArea}>
        <Text style={styles.price}>{totalPrice}</Text>
        <Text style={styles.unit}>원</Text>
      </View>
    </View>
  )
}

export default FooterPrice

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLOR.mono.white,
    paddingVertical: 12,
    paddingHorizontal: 16,
    ...getBoxShadow(0, 0, 0, 0.05, 6, 0, -4, 5),
  },
  priceArea: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  price: {
    ...FONT.H5Bold,
  },
  unit: {
    ...FONT.H6Medium,
    marginLeft: 4,
  },
})
