import React from 'react'
import Providers from '../../providers'
import NotificationListProvider from './provider'
import NotificationListView from './view'

import { i18n } from 'i18next'

interface Props {
  i18n: i18n
  isShow: boolean
  setUnreadCount: (unread: number) => void
}

export const NotificationListScreen: React.FC<Props> = ({ i18n, ...props }) => {
  console.log('NotificationListScreen')

  return (
    <Providers i18n={i18n}>
      <NotificationListProvider>
        <NotificationListView {...props} />
      </NotificationListProvider>
    </Providers>
  )
}
