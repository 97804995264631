import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { ActivityIndicator, LayoutChangeEvent, Pressable, StyleSheet } from 'react-native'

import TopControl from '../TopControl'

import type { IconName } from '@rui/foundations'
import moment from 'moment'
import BoardOrderList, { BoardOrderListProps } from '../BoardOrder/BoardOrderList'
import { useTableorderCatalog, useTableorderOrder, useTableorderShop, useTableorderTable } from '../providers'
import { useTableorderOrderDetail } from '../TableorderOrderDetailView'
import { TableInfoView } from '../TableorderTableInfoView'
import { TableorderBoardDrop } from './drop'
import { useEditMode } from './hooks/useEditMode'
import { TableorderBoardItem } from './item'
import type { ViewProps } from './types'

const ROW = [0, 1, 2, 3, 4]
const COL = [0, 1, 2, 3, 4]
export const TableorderBoardView: React.FC<ViewProps> = ({}) => {
  const [currentTableId, setCurrentTableId] = React.useState<string>()
  const { tableList, openTable, moveTable } = useTableorderTable()
  const { catalogMenuList } = useTableorderCatalog()
  const getItem = React.useCallback(
    (row: number, col: number) => {
      return tableList.find(({ locationX, locationY }) => locationX === row && locationY === col)
    },
    [tableList],
  )

  const currentTable = React.useMemo(
    () => (currentTableId !== undefined ? tableList.find((o) => o.id === currentTableId) : undefined),
    [tableList, currentTableId],
  )

  const onPressTable = React.useCallback(
    (id: string) => {
      setCurrentTableId(id)
    },
    [tableList],
  )

  const [boardTableHeight, setBoardTableHeight] = React.useState(0)
  const tableCardHeight = React.useMemo(() => {
    return boardTableHeight ? (boardTableHeight - 40) / 5 : 0
  }, [boardTableHeight])
  const onLayoutBoardTable = React.useCallback(
    ({
      nativeEvent: {
        layout: { height },
      },
    }: LayoutChangeEvent) => {
      setBoardTableHeight(height)
    },
    [],
  )

  const {
    shopLayoutId,
    onChangeShopLayoutId,
    onChangeShopLayoutName,
    shopLayoutList,
    addShopLayoutList,
    deleteShopLayoutList,
  } = useTableorderShop()
  const { editMode, onToggleEditMode } = useEditMode()

  const buttonList = React.useMemo(
    () =>
      shopLayoutList?.map((o) => ({
        itemCode: o.id,
        text: o.name,
        iconName: 'trash-o' as IconName,
      })) || [],
    [shopLayoutList],
  )

  const { orderList } = useTableorderOrder()
  const { onPressOrder } = useTableorderOrderDetail()
  const boardOrderList = React.useMemo<BoardOrderListProps[] | undefined>(() => {
    console.log('orderList', orderList)
    return (
      orderList
        ?.filter(({ status }) => status === 'DONE')
        .sort((a, b) => (moment(a.createdAt).isAfter(b.createdAt) ? 1 : -1))
        .map((o) => ({
          itemCode: o.id,
          menu: catalogMenuList
            .filter(({ id }) => o.items.map((item) => item.catalogMenuId).includes(id))
            .map(({ name }) => name),
          price: String(o.price),
          tableId: o.tableId,
          orderDate: o.createdAt,
        })) ?? undefined
    )
  }, [catalogMenuList, tableList, orderList])

  return (
    <View style={styles.container}>
      <BoardOrderList list={boardOrderList} onCode={onPressOrder} />
      <View style={styles.boardContainer}>
        {!!shopLayoutId && (
          <TopControl
            itemCode={shopLayoutId}
            list={buttonList}
            editMode={editMode}
            onChangeText={onChangeShopLayoutName}
            onChangeEditMode={onToggleEditMode}
            onPressItem={onChangeShopLayoutId}
            onPressAdd={addShopLayoutList}
            onPressDelete={deleteShopLayoutList}
          />
        )}
        <View onLayout={onLayoutBoardTable} style={{ flex: 1, flexDirection: 'row', marginTop: 10 }}>
          {tableCardHeight > 0 ? (
            ROW.map((x) => (
              <View key={`table-row-${x}`} style={{ flex: 1 }}>
                {COL.map((y) => {
                  const table = getItem(x, y)
                  return (
                    <TableorderBoardDrop
                      key={`table-column-${x}-${y}`}
                      isDrop={!table}
                      x={x}
                      y={y}
                      height={tableCardHeight}
                      onDrop={moveTable}
                    >
                      <TableorderBoardItem
                        table={table}
                        height={tableCardHeight}
                        editMode={editMode}
                        onPressTable={onPressTable}
                        onPressCreateTable={() => openTable({ id: table?.id, x, y })}
                      />
                    </TableorderBoardDrop>
                  )
                })}
              </View>
            ))
          ) : (
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator />
            </View>
          )}
        </View>
      </View>
      {!!currentTable && (
        <>
          <Pressable
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            }}
            onPress={() => setCurrentTableId(undefined)}
          />
          <TableInfoView currentTable={currentTable} />
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: COLOR.mono.white,
  },
  boardContainer: {
    flex: 1,
    margin: 20,
  },
})
