import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useRef } from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Pressable, StyleSheet, Text, TextInput, View } from 'react-native'
import { Button } from '../../Button'

interface Props {
  name: string
  menuLength: number
  onChangeName?: (name: string) => void
  onPressAddMenu?: () => void
  onPressDelete?: () => Promise<void>
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  children?: JSX.Element | JSX.Element[]
}

export const TableorderMenuCategory: React.FC<Props> = ({
  name,
  menuLength,
  onChangeName,
  onPressAddMenu,
  onPressDelete,
  dragHandleProps,
  children,
}) => {
  const iptEditRef = useRef<View>(null)

  const [categoryName, setCategoryName] = React.useState(name)
  const [isBlur, setIsBlur] = React.useState(true)

  const [iptErr, setIptErr] = React.useState(false)
  const [dragPress, setDregPress] = React.useState(false)
  const [stateFold, setStateFold] = React.useState(menuLength > 0 ? false : true)
  const [editMode, setEditMode] = React.useState(name.length > 0 ? false : true)

  /**
   * @description input에 값이 있는지 체크-> 없다면 에러 스타일
   */
  const checkIpt = React.useCallback(() => {
    if (categoryName === '') {
      setIptErr(true)
      setEditMode(true)
    } else {
      setIptErr(false)
      onChangeName && onChangeName(categoryName)
      if (menuLength === 0) setStateFold(true)
    }
  }, [menuLength, onChangeName, categoryName])

  const handlePressDelete = React.useCallback(() => {
    onPressDelete && onPressDelete().then(() => setEditMode(false))
  }, [onPressDelete])

  React.useEffect(() => {
    if (name.length === 0 && iptEditRef.current) {
      iptEditRef.current.focus()
    }
  }, [name, iptEditRef])

  return (
    <View style={[style.container, dragPress && style.containerPress]}>
      <View style={style.cateContainer}>
        <div {...dragHandleProps}>
          <Pressable
            style={style.buttonIconPress}
            onPressIn={() => setDregPress(true)}
            onPressOut={() => setDregPress(false)}
          >
            <XEIcon name="drag-vertical" size="16" />
          </Pressable>
        </div>
        <View style={style.iptContainer} ref={iptEditRef}>
          <View style={style.iptSection}>
            {editMode ? (
              <>
                <TextInput
                  autoFocus={true}
                  placeholder="카테고리명을 입력하세요 (예시: 커피류)"
                  value={categoryName}
                  onChangeText={(text) => setCategoryName(text)}
                  onBlur={() => {
                    if (isBlur) {
                      setEditMode(false)
                      checkIpt()
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.nativeEvent.key === 'Enter') {
                      setEditMode(false)
                      checkIpt()
                    }
                  }}
                  style={[style.iptItem, iptErr && style.iptErrItem]}
                />
                <Button
                  size="small"
                  style={style.buttonEdit}
                  onHoverIn={() => setIsBlur(false)}
                  onHoverOut={() => setIsBlur(true)}
                  onPress={() => {
                    setEditMode(false)
                    checkIpt()
                    console.log('수정 클릭')
                  }}
                >
                  <Text>수정</Text>
                </Button>
              </>
            ) : (
              <Text
                onPress={() => {
                  setEditMode(true)
                }}
                style={style.iptValue}
              >
                {categoryName}
              </Text>
            )}
          </View>
          {editMode && (
            <Button
              size="small"
              style={style.buttonDelete}
              onPress={handlePressDelete}
              onHoverIn={() => setIsBlur(false)}
              onHoverOut={() => setIsBlur(true)}
            >
              <Text style={{ color: COLOR.mono.white }}>삭제</Text>
            </Button>
          )}
        </View>
        <Pressable style={style.buttonIconPress} onPress={() => setStateFold(!stateFold)}>
          <XEIcon name={stateFold ? 'angle-up' : 'angle-down'} size="16" />
        </Pressable>
      </View>
      {stateFold && (
        <>
          <View style={style.menuContainer}>
            <Text>카테고리 포함 메뉴</Text>
            <Button size="small" onPress={onPressAddMenu}>
              <Text style={{ color: COLOR.mono.white, paddingHorizontal: 6 }}>메뉴 추가</Text>
            </Button>
          </View>
          {children}
        </>
      )}
    </View>
  )
}

const style = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 8,
    paddingVertical: 8,
    paddingRight: 8,
  },
  containerPress: {
    borderColor: COLOR.gray.g300,
    // boxShadow: `0px 0px 7px rgba(0, 0, 0, 0.1)`,
    ...getBoxShadow(0, 0, 0, 0.1, 7, 0, 0, 5),
  },
  cateContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iptContainer: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 4,
  },
  iptSection: {
    flex: 3,
    marginRight: 12,
  },
  menuContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 8,
    paddingHorizontal: 20,
  },
  buttonIconPress: {
    width: 34,
    alignItems: 'center',
  },
  buttonDelete: {
    backgroundColor: COLOR.main.red,
  },
  buttonEdit: {
    position: 'absolute',
    top: -31,
    right: 6,
    backgroundColor: COLOR.mono.paleWhite,
    borderWidth: 1,
    borderColor: COLOR.mono.lightGray,
  },
  iptItem: {
    height: 36,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLOR.mono.lightGray,
    paddingHorizontal: 8,
    ...FONT.txtMdMedium,
  },
  iptErrItem: {
    borderColor: COLOR.main.red,
  },
  iptValue: {
    ...FONT.H7Bold,
    height: 36,
    lineHeight: 36,
  },
})
