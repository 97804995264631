import * as React from 'react'

import type { ChannelMember, Project, ProjectElement } from '@rocket/types'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '@rocket-mono/providers'
import { useToast } from '@rui/atoms'
import { removeItemAtIndex, replaceItemAtIndex } from '@rui/foundations'
import type { InviteMemberListItem, MemberStateType } from '../../components/MemberList'
import ModalBottomAlert from '../../components/ModalBottomAlert'
import Context from './context'
import type { EmailInviteMemberItem, ModalBottomAlertType, ProjectmemberType, ProviderProps } from './types'

const Provider = ({ astro, channelId, roomId, projectId, projectElementId, children, subscribe }: ProviderProps) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  const { show: showToastMessage } = useToast()
  const [searchKeyword, changeSearchKeyword] = React.useState('')
  const [memberSearchKeyword, changeMemberSearchKeyword] = React.useState('')
  const [step, setStep] = React.useState('init')

  const [selected, setSelected] = React.useState<string[]>([])

  const [project, setProject] = React.useState<Project>()

  const [channelMemberList, setChannelMemberList] = React.useState<ChannelMember[]>([])

  const [inviteEmailMemberList, setInviteEmailMemberList] = React.useState<EmailInviteMemberItem[]>([])

  const [modalBottomAlert, setModalBottomAlert] = React.useState<ModalBottomAlertType>()

  const [loading, setLoading] = React.useState(false)

  subscribe(`/subscribe/v2/update/${roomId}`, () => {
    astro.readChannelMemberList(channelId).then(setChannelMemberList)
  })

  subscribe(`/subscribe/project/${projectId}/update`, () => {
    astro.readProject(projectId).then(setProject)
  })

  const isNewInvite = (createdAt?: Date) => {
    if (!createdAt) return false
    const now = new Date()
    const diff = now.getTime() - createdAt.getTime()
    return diff < 1000 * 60 * 60 * 24 * 1 // 1 days
  }

  const members = React.useMemo(() => {
    const sort = (a: ChannelMember, b: ChannelMember) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
    const inviteList = channelMemberList.filter((o) => !o.joined).sort(sort)
    const me = channelMemberList.filter((o) => o.userId === String(currentUser.id)).sort(sort)
    const managerList = channelMemberList
      .filter((o) => o.userId !== String(currentUser.id) && o.joined && o.isManager)
      .sort(sort)
    const memberList = channelMemberList
      .filter((o) => o.userId !== String(currentUser.id) && o.joined && !o.isManager)
      .sort(sort)

    return [...inviteList, ...me, ...managerList, ...memberList]
      .filter((o) => o.name.indexOf(searchKeyword) >= 0 || o.email.indexOf(searchKeyword) >= 0)
      .map((member) => {
        const { id, name, email, joined, userId, isManager, createdAt } = member
        const invite = !joined
        const isMe = userId === String(currentUser.id)

        let state: MemberStateType = ''
        if (isMe) {
          state = '나'
        } else if (isManager) {
          // state = '관리자'
        } else if (invite) {
          state = '초대'
        }

        if (project && project.members) {
          const member = project.members.find((o) => o.userId === userId)
          if (member && member.auth === 'MANAGER') {
            state = '관리자'
          }
        }

        return {
          id: String(id),
          userId,
          name,
          email,
          positionLeader: isManager,
          invite,
          state,
          isNewInvite: isNewInvite(createdAt),
        }
      })
  }, [channelMemberList, searchKeyword, project])

  const getSelected = React.useCallback((list: ChannelMember[], email: string) => {
    let selected = false
    for (const data of list) {
      if (data.email === email) {
        selected = true
      }
    }
    return selected
  }, [])

  const inviteMembers = React.useMemo(() => {
    const sort = (a: ProjectmemberType, b: ProjectmemberType) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)
    const inviteList = project
      ? project.members.filter((o) => !o.isJoined && !getSelected(channelMemberList, o.email)).sort(sort)
      : []
    const memberList = project
      ? project.members.filter((o) => o.isJoined && !getSelected(channelMemberList, o.email)).sort(sort)
      : []

    return [...inviteList, ...memberList]
      .filter((o) => o.name.indexOf(memberSearchKeyword) >= 0 || o.email.indexOf(memberSearchKeyword) >= 0)
      .map((member) => {
        const { id, name, email, auth, userId, isJoined } = member
        const isMe = userId === String(currentUser.id)

        let state: MemberStateType = ''
        if (isMe) {
          state = '나'
        } else if (auth === 'OWNER' || auth === 'MANAGER') {
          state = '관리자'
        } else if (!isJoined) {
          state = '초대'
        }

        return {
          id: String(id),
          userId,
          name,
          email,
          positionLeader: false,
          invite: false,
          state,
          selected: selected.includes(String(id)),
        }
      })
  }, [channelMemberList, project, selected, memberSearchKeyword])
  const currentChannelMember = React.useMemo(
    () => channelMemberList.find((o) => o.userId === String(currentUser.id)),
    [channelMemberList, currentUser],
  )
  const currentProjectMember = React.useMemo(
    () => project && project.members.find((o) => o.userId === String(currentUser.id)),
    [project, currentUser],
  )

  const isAdmin = React.useMemo(() => {
    let isAdmin = currentChannelMember && currentChannelMember.isManager ? true : false
    if (currentProjectMember && !isAdmin) {
      isAdmin = currentProjectMember.auth === 'OWNER' || currentProjectMember.auth === 'MANAGER' ? true : false
    }
    return isAdmin
  }, [currentChannelMember, currentProjectMember])

  const element: ProjectElement | undefined = React.useMemo(() => {
    if (project) {
      const element = project.elements.find((o) => o.id === projectElementId)
      if (element) {
        const isFirst = element.order === 0
        const isLast = element.order === element.maxOrder
        const labelObject =
          project.type.code === 'WFP' && isFirst
            ? 'main.yellow'
            : project.type.code === 'WFP' && isLast
            ? 'main.blue'
            : project.type.code === 'WFP'
            ? 'main.turquoise'
            : element.labelObject
        return { ...element, labelObject }
      } else {
        return undefined
      }
    } else {
      return undefined
    }
  }, [projectElementId, project])

  React.useEffect(() => {
    astro.readChannelMemberList(channelId).then(setChannelMemberList)
    astro.readProject(projectId).then(setProject)
  }, [currentUser])

  const updateChannelMember = React.useCallback((member: ChannelMember) => {
    setChannelMemberList((prev) => {
      const idx = prev.findIndex((o) => o.id === member.id)
      return idx < 0 ? [...prev, member] : replaceItemAtIndex(prev, idx, member)
    })
  }, [])

  const deleteChannelMember = React.useCallback((memberId: string) => {
    setChannelMemberList((prev) => {
      const idx = prev.findIndex((o) => String(o.id) === memberId)
      return idx < 0 ? prev : removeItemAtIndex(prev, idx)
    })
  }, [])

  const inviteMember = React.useCallback(
    ({ addUserId, addUserEmail }: { addUserId?: string; addUserEmail?: string }) => {
      return astro
        .inviteChannel(channelId, addUserId, addUserEmail, '')
        .then(updateChannelMember)
        .then(() => {
          showToastMessage({
            type: 'Success',
            title: t('workboardmemberview.toast.invite'),
            position: 'BOTTOM_CENTER',
          })
        })
    },
    [],
  )

  const deleteMember = React.useCallback(
    (memberId: string) => {
      const member = channelMemberList.find((o) => String(o.id) === memberId)
      if (isAdmin && member) {
        setModalBottomAlert({
          message: t('workboardmemberview.alert.remove.message'), // '보드멤버에서 제외하시겠습니까?',
          list: [
            {
              name: t('workboardmemberview.alert.remove.removeconfirm'), //'제외',
              action: () => {
                void astro.deleteChannelMember(channelId, memberId).then(() => {
                  deleteChannelMember(memberId)
                  showToastMessage({
                    type: 'Success',
                    title: t('workboardmemberview.alert.remove.removesuccess'), //'제외했습니다.',
                    position: 'BOTTOM_CENTER',
                  })
                })
              },
            },
          ],
          cancelText: t('setting.state.cancel'), //'취소',
        })
      } else {
        showToastMessage({
          type: 'Danger',
          title: t('workboardmemberview.toast.nopermission'), // '권한이 없습니다.',
          position: 'BOTTOM_CENTER',
        })
      }
    },
    [channelMemberList, isAdmin],
  )

  const cancelInvite = React.useCallback((memberId: string) => {
    setModalBottomAlert({
      message: t('workmember.dialog.invitecancel.title'), // '초대 취소하시겠습니까?',
      list: [
        {
          name: t('workmember.dialog.invitecancel.button'), // '초대 취소',
          action: () => {
            void astro.deleteChannelMember(channelId, memberId).then(() => {
              deleteChannelMember(memberId)
              showToastMessage({
                type: 'Success',
                title: t('workboardmemberview.alert.inviteremove.removesuccess'), //'초대 취소했습니다.',
                position: 'BOTTOM_CENTER',
              })
            })
          },
        },
      ],
      cancelText: t('setting.state.cancel'), // '취소',
    })
  }, [])

  const changeMemberManager = React.useCallback(
    (memberId: string, isManager: boolean) => {
      const member = channelMemberList.find((o) => String(o.id) === memberId)

      if (isAdmin && member) {
        setModalBottomAlert({
          message: t('workmember.dialog.permission.title'), // '멤버의 권한을 변경하시겠습니까?',
          list: [
            {
              name: t('workmember.dialog.permission.button'), //'변경',
              action: () => {
                void astro.updateChannelMemberManager(channelId, memberId, isManager).then((member) => {
                  updateChannelMember(member)
                  showToastMessage({
                    type: 'Success',
                    title: t('workboardmemberview.alert.permission.modifysuccess'), // '변경했습니다.',
                    position: 'BOTTOM_CENTER',
                  })
                })
              },
            },
          ],
          cancelText: t('setting.state.cancel'), //'취소',
        })
      } else {
        showToastMessage({
          type: 'Danger',
          title: t('workboardmemberview.toast.nopermission'), //'권한이 없습니다.',
          position: 'BOTTOM_CENTER',
        })
      }
    },
    [channelMemberList, isAdmin],
  )

  const changeStep = React.useCallback((step: string) => {
    setStep(step)
  }, [])

  const changeSelected = React.useCallback((item: InviteMemberListItem) => {
    if (item.selected) {
      setSelected((prev) => [...prev, item.id])
    } else {
      setSelected((prev) => prev.filter((id) => id !== item.id))
    }
  }, [])

  const changeAllChecked = React.useCallback(
    (count: number) => {
      if (inviteMembers.length === count) {
        setSelected([])
      } else {
        const list = inviteMembers.map((o) => o.id)
        setSelected(list)
      }
    },
    [inviteMembers],
  )

  const onInviteMembers = React.useCallback(() => {
    const selectedItem: InviteMemberListItem[] = []
    if (selected.length > 0) {
      inviteMembers.forEach((item) => {
        if (selected.includes(item.id)) {
          selectedItem.push(item)
        }
      })
    }
    if (selectedItem.length > 0) {
      setLoading(true)
      const promisesList: Promise<ChannelMember>[] = []
      for (const item of selectedItem) {
        promisesList.push(astro.inviteChannel(channelId, '', item.email, item.name))
      }
      void Promise.all(promisesList).then(() => {
        void astro.readChannelMemberList(channelId).then((list) => {
          setChannelMemberList(list)
          showToastMessage({
            type: 'Success',
            title: t('workboardmemberview.alert.permission.modifysuccess'), // '변경했습니다.',
            position: 'BOTTOM_CENTER',
          })
          setStep('init')
          setSelected([])
          setLoading(false)
        })
      })
    }
  }, [selected, inviteMembers])

  const addInviteEmailMemberList = React.useCallback(
    (item: EmailInviteMemberItem) => {
      setInviteEmailMemberList([item, ...inviteEmailMemberList])
    },
    [inviteEmailMemberList],
  )
  const changeInviteEmailMemberList = React.useCallback((index: number, item: EmailInviteMemberItem) => {
    setInviteEmailMemberList((prev) => {
      return replaceItemAtIndex(prev, index, item)
    })
  }, [])
  const deleteInviteEmailMemberList = React.useCallback((index: number) => {
    setInviteEmailMemberList((prev) => {
      return removeItemAtIndex(prev, index)
    })
  }, [])

  const clearInviteEmailMemberList = React.useCallback(() => {
    setInviteEmailMemberList([])
  }, [])

  const onInviteEmailMembers = React.useCallback(async () => {
    const emailInviteMemberCount = inviteEmailMemberList.length
    if (emailInviteMemberCount > 0) {
      let isReject = false
      setLoading(true)
      for (const item of inviteEmailMemberList) {
        await astro.inviteChannel(channelId, '', item.email, item.name).catch(() => {
          isReject = true
        })
      }
      let message = t('workboardmemberview.toast.invite') // '변경했습니다.'
      if (isReject) {
        message = t('workboardmemberview.alert.permission.modifyfail') // '일부는 이미 참여된 멤버입니다.'
      }
      void astro.readChannelMemberList(channelId).then((list) => {
        setChannelMemberList(list)
        showToastMessage({
          type: 'Success',
          title: message,
          position: 'BOTTOM_CENTER',
        })
        setStep('init')
        setInviteEmailMemberList([])
        setLoading(false)
      })
    }
    return emailInviteMemberCount
  }, [inviteEmailMemberList])

  const leaveMember = React.useCallback(
    (memberId: string) => {
      const member = channelMemberList.find((o) => String(o.id) === memberId)

      if (member) {
        setModalBottomAlert({
          message: t('workboardmemberview.alert.leave.message'), // '나가시겠습니까?',
          list: [
            {
              name: t('workboardmemberview.alert.leave.leaveconfirm'), //'나가기',
              action: () => {
                void astro.deleteChannelMember(channelId, memberId).then(() => {
                  deleteChannelMember(memberId)
                  showToastMessage({
                    type: 'Success',
                    title: t('workboardmemberview.alert.leave.leavesuccess'), // '나갔습니다.',
                    position: 'BOTTOM_CENTER',
                  })
                })
              },
            },
          ],
          cancelText: t('setting.state.cancel'), //'취소',
        })
      } else {
        showToastMessage({
          type: 'Danger',
          title: t('workboardmemberview.toast.nopermission'), //'권한이 없습니다.',
          position: 'BOTTOM_CENTER',
        })
      }
    },
    [channelMemberList],
  )

  return (
    <Context.Provider
      value={{
        loading,
        currentUser,
        isAdmin,
        members,
        inviteMembers,
        inviteMember,
        deleteMember,
        cancelInvite,
        changeMemberManager,
        changeSearchKeyword,
        memberSearchKeyword,
        changeMemberSearchKeyword,
        element,
        step,
        changeStep,
        changeSelected,
        changeAllChecked,
        onInviteMembers,
        inviteEmailMemberList,
        addInviteEmailMemberList,
        changeInviteEmailMemberList,
        deleteInviteEmailMemberList,
        clearInviteEmailMemberList,
        onInviteEmailMembers,
        leaveMember,
      }}
    >
      {children}
      <ModalBottomAlert
        message={modalBottomAlert ? modalBottomAlert.message : ''}
        list={modalBottomAlert ? modalBottomAlert.list : []}
        cancelText={modalBottomAlert ? modalBottomAlert.cancelText : ''}
        submitText=""
        visible={!!modalBottomAlert}
        onCancel={() => console.log('onCancel')}
        onClose={() => setModalBottomAlert(undefined)}
        onDismiss={() => setModalBottomAlert(undefined)}
      />
    </Context.Provider>
  )
}

export default Provider
