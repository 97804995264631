import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

interface Props {
  name: string
  price?: string
  selected?: boolean
  onPress?: () => void
}

const OrderOptionButton: React.FC<Props> = ({ name, price, selected = false, onPress }) => {
  return (
    <Pressable style={[styles.container, selected && { borderColor: COLOR.main.red }]} onPress={onPress}>
      <View style={[styles.contentsBox]}>
        <Text style={styles.optionName}>{name}</Text>
        <Text style={styles.price}>{price}</Text>
      </View>
      {selected && (
        <>
          <View style={styles.cover}></View>
          <View style={styles.selectIcon}>
            <XEIcon name={'check-min'} size={18} color={'mono.white'} style={{ marginLeft: 1 }} />
          </View>
        </>
      )}
    </Pressable>
  )
}

export default OrderOptionButton

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    width: 100,
    height: 76,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 8,
    padding: 8,
  },
  contentsBox: {
    flex: 1,
    justifyContent: 'space-between',
  },
  optionName: {
    ...FONT.txtMdMedium,
    lineHeight: 18,
  },
  price: {
    ...FONT.txtMd,
    textAlign: 'right',
  },
  cover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: COLOR.main.red,
    opacity: 0.3,
  },
  selectIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    width: 20,
    height: 20,
    backgroundColor: COLOR.main.red,
    borderRadius: 30,
  },
})
