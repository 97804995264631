import { View } from '@rocket-mono/foundations'
import { Button, Text } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import { FAFreeSolidSvgIcons } from '@rui/icons'
import * as React from 'react'
import { StyleSheet } from 'react-native'
const { faClose } = FAFreeSolidSvgIcons

export interface ModalHeaderProps {
  children: string
  textAlign: 'left' | 'center'
  onPressClose?: () => void
  onPressAction?: () => void
  onPressText?: string
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({
  children,
  textAlign,
  onPressClose,
  onPressAction,
  onPressText,
}) => {
  return (
    <View style={styles.headerView}>
      {!!onPressClose && (
        <View style={{ position: 'absolute', left: 0, zIndex: 99 }}>
          <Button
            type="SECONDARYWHITE"
            size="md"
            configuration="icon"
            beforeIcon={faClose}
            // iconName="close"
            // iconSize={20}
            // containerSize={56}
            onPress={onPressClose}
          />
        </View>
      )}
      <Text style={{ flex: 1, textAlign }} fontName="txtMdMedium" fontColor="gray.g900">
        {children}
      </Text>
      {onPressAction && (
        <View style={{ position: 'absolute', right: 20 }}>
          <Button
            size="sm"
            text={<Text style={{ ...FONT.txtSm, color: COLOR.mono.white }}>{onPressText}</Text>}
            disabled={false}
            onPress={onPressAction}
          />
        </View>
      )}
    </View>
  )
}

ModalHeader.displayName = 'ModalBottomSheetType.Header'

const styles = StyleSheet.create({
  headerView: {
    width: '100%',
    height: 54,
    paddingHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: COLOR.gray.g100,
  },
})
