import { Button } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import ScreenTitle from '../ScreenTitle'

interface Props {
  titleVisible: boolean
  onPressAllChecked: () => void
  onPressButtonSearch: () => void
}

const NotificationHeader: React.FC<Props> = ({
  titleVisible,
  onPressAllChecked,
  // onPressButtonSearch
}) => {
  const { t } = useTranslation()
  return (
    <View>
      {titleVisible && (
        <ScreenTitle
          title={t('notification.title')}
          // buttonIconElements={
          //   <ButtonIcon
          //     iconName="search"
          //     iconSize={26}
          //     onPress={() => onPressButtonSearch}
          //   />
          // }
        />
      )}
      <View style={styles.topArea}>
        <Button
          size="sm"
          style={{ height: 24, paddingHorizontal: 8, backgroundColor: COLOR.gray.g100 }}
          onPress={onPressAllChecked}
          text={<Text style={{ ...FONT.txtXs, color: COLOR.gray.g700 }}>{t('notification.allread')}</Text>}
        />
      </View>
    </View>
  )
}

export default NotificationHeader

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  topArea: {
    height: 52,
    alignSelf: 'flex-end',
    justifyContent: 'center',
    paddingBottom: 8,
    marginHorizontal: 12,
  },
})
