import * as React from 'react'
import { Pressable } from 'react-native'

import { COLOR } from '@rui/foundations'

import { XEIcon } from '@rocket-atoms/icon'
import { View } from '@rocket-mono/foundations'
import { Button, Text } from '@rui/atoms'
import { WorkIcon } from '@rui/icons'
import type { SectionItemType } from './types'

interface Props {
  item: SectionItemType
  onPress?: () => void
}

export const WorkSettingItem: React.FC<Props> = ({ item, onPress }) => {
  const [isHover, setIsHover] = React.useState(false)
  const workIcon = React.useMemo(() => {
    const size = 18
    if (item.typeCode === 'CSP') return <WorkIcon.CSP size={size} />
    else if (item.typeCode === 'WFP') return <WorkIcon.WFP size={size} />
    else if (item.typeCode === 'DSP') return <WorkIcon.DSP size={size} />
    else return undefined
  }, [item.typeCode])
  return (
    <Pressable
      style={{
        width: '100%',
        height: 36,
        paddingHorizontal: 20,
        paddingRight: 10,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: isHover ? COLOR.gray.g050 : COLOR.mono.white,
      }}
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      {workIcon}
      <View style={{ flex: 1, marginLeft: 4 }}>
        <Text fontName="txtMd" fontColor="gray.g900">
          {item.title}
        </Text>
      </View>
      <Button
        // iconName="angle-right-min"
        // iconSize={16}
        // iconColor={'gray.g450'}
        onPress={onPress}
        text={<XEIcon name="angle-right-min" size={16} color="gray.g450" />}
      />
    </Pressable>
  )
}
