import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Pressable, StyleSheet } from 'react-native'
import { IconDelete } from '../../icons'

interface Props {
  name: string
  onPressDelete?: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null
}

export const TableorderMenuDetailOptionItem: React.FC<Props> = ({ name, onPressDelete, dragHandleProps }) => {
  return (
    <View style={styles.container}>
      <div {...dragHandleProps}>
        <Pressable style={styles.dragIcon}>
          <XEIcon name="drag-vertical" size="16" />
        </Pressable>
      </div>
      <Text style={styles.flex}>{name}</Text>
      <View style={styles.deleteIcon}>{onPressDelete && <IconDelete onPressDelete={onPressDelete} />}</View>
    </View>
  )
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  container: {
    height: 36,
    marginBottom: 6,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLOR.gray.g050,
    borderRadius: 8,
  },
  dragIcon: { width: 22, alignItems: 'center' },
  deleteIcon: { width: 22 },
})
