import * as React from 'react'
import { useDrop } from 'react-dnd'

interface Props {
  isDrop?: boolean
  height: number
  x: number
  y: number
  children: JSX.Element
  onDrop: (id: string, x: number, y: number) => void
}

export const TableorderBoardDrop: React.FC<Props> = ({ isDrop = false, height, x, y, onDrop, children }) => {
  const [{}, drop] = useDrop(
    () => ({
      accept: 'CARD',
      canDrop: () => isDrop,
      drop: (item: { id: string }) => onDrop(item.id, x, y),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [isDrop, x, y, onDrop],
  )
  return (
    <div
      ref={drop}
      style={{
        height,
        marginBottom: 10,
      }}
    >
      {children}
    </div>
  )
}
