import { TableorderTable } from '@rocket/types'
import { COLOR, FONT } from '@rui/foundations'
import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { useTableorderTable } from '../providers'
import type { BoardOrderListProps } from './BoardOrderList'

interface Props {
  item: BoardOrderListProps
  idx: number
  onCode?: (code: string) => void
  selectedButton: string
}

const BoardOrderCard = ({ item, onCode, selectedButton }: Props) => {
  const { t } = useTranslation()

  const { readTable } = useTableorderTable()
  const [currentTable, setCurrentTable] = React.useState<TableorderTable>()

  React.useEffect(() => {
    readTable(item.tableId).then(setCurrentTable)
  }, [item.tableId])

  return (
    <Pressable
      style={[
        styles.container,
        {
          borderColor: selectedButton === item.itemCode ? '#6C67C1' : COLOR.mono.pale,
        },
      ]}
      onPress={() => {
        // setSelectedButton(item.itemCode)
        if (onCode) {
          onCode(item.itemCode)
        }
      }}
    >
      <View style={styles.orderTableInfo}>
        <Text style={styles.tableText}>{currentTable?.name ?? ''}</Text>
        <View style={styles.orderData}>
          <Text style={styles.orderDataText1}>{t('format.date.T', { date: new Date(item.orderDate) })} 주문</Text>
          <Text style={styles.orderDataText2}>· {moment().diff(item.orderDate, 'minutes')}분 전</Text>
        </View>
      </View>
      {item.menu.length && (
        <Text style={styles.menuText}>
          {item.menu[0].length < 9 ? item.menu[0] : item.menu[0].substring(0, 9) + '...'}{' '}
          {item.menu.length > 1 ? `외 ${item.menu.length - 1}개` : ''}
        </Text>
      )}
      <Text style={styles.priceText}>{item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</Text>
      {selectedButton === item.itemCode && <View style={styles.selectedArrow}></View>}
    </Pressable>
  )
}

export default BoardOrderCard

const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    borderWidth: 3,
    backgroundColor: COLOR.mono.white,
    padding: 8,
    marginRight: 12,
    marginBottom: 8,
  },
  orderTableInfo: {
    borderBottomWidth: 1,
    borderBottomColor: COLOR.mono.pale,
    paddingBottom: 4,
    marginBottom: 4,
  },
  orderDataText1: {
    ...FONT.txtSmMedium,
    marginRight: 4,
  },
  orderDataText2: {
    color: COLOR.mono.darkGray,
  },
  orderData: {
    flexDirection: 'row',
  },
  tableText: {
    ...FONT.txtMdBold,
    marginBottom: 4,
  },
  menuText: {
    ...FONT.txtSm,
    marginBottom: 4,
  },
  priceText: {
    ...FONT.txtSmMedium,
    textAlign: 'right',
  },
  selectedArrow: {
    position: 'absolute',
    top: '50%',
    right: -17,
    width: 7,
    height: 5,
    marginTop: -7,
    borderTopWidth: 7,
    borderTopColor: COLOR.transparent.base,
    borderBottomWidth: 7,
    borderBottomColor: COLOR.transparent.base,
    borderLeftWidth: 7,
    borderLeftColor: '#6C67C1',
    borderRightWidth: 7,
    borderRightColor: COLOR.transparent.base,
  },
})
