import { DivLine, View } from '@rocket-mono/foundations'
import { COLOR, FONT } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import React from 'react'
import { StyleSheet } from 'react-native'

const MetaText: React.FC<{ label: string; afterDivLine: boolean }> = ({ label, afterDivLine }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{label}</Text>
      <DivLine height={8} marginHorizontal={4} />
    </View>
  )
}

export default MetaText

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g500,
  },
})
