import { Icon } from '@rocket-atoms/icon'
import { Button, ButtonIcon } from '@rocket-mono/foundations'
import { Text } from '@rocket/atoms'
import * as React from 'react'
import { Keyboard, Platform, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

import { COLOR, FONT } from '@rocket-mono/libs'

import { IColors, useMobileView } from '@rocket-mono/libs'
import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import CreateWorkModelCousult from '../CreateWorkModel/Cousult'
import CreateWorkModelHierarchy from '../CreateWorkModel/Hierarchy'
import CreateWorkModelTableorder from '../CreateWorkModel/Tableorder'
import CreateWorkModelWorkflow from '../CreateWorkModel/Workflow'
import type { ViewProps } from './types'

const LABELS: IColors[] = [
  'mono.realBlack',
  'sub.green',
  'sub.darkPurple',
  'sub.ocean',
  'sub.pink',
  'sub.paleYellow',
  'sub.purple',
  'sub.lightGreen',
  'sub.paleBlue',
  'sub.paleSky',
  'sub.lightYellow',
  'sub.darkNavy',
  'sub.lightBlue',
  'main.blue',
]

export const WorkmodelCreateView: React.FC<ViewProps> = ({
  typeCode,
  data,
  keyboardHeight = 0,
  onChangeTitle,
  onChangeStartElement,
  onChangeEndElement,
  onPressCreate,
  onPressBack,
  onPressAddElement,
  onPressRemoveElement,
  ...viewProps
}) => {
  const { t } = useTranslation()
  const mobileView = useMobileView(1000)
  const [step, setStep] = React.useState(1)
  const [titleError, setTitleError] = React.useState(false)
  const { show: showToastMessage } = useToast()

  const handleChangeTitle = React.useCallback(
    (title: string) => {
      if (title.length > 0) setTitleError(false)
      onChangeTitle(title)
    },
    [data.title],
  )

  const handlePressBack = React.useCallback(() => {
    if (step < 2) {
      onPressBack && onPressBack()
    } else {
      setStep((prev) => prev - 1)
    }
  }, [step])

  const handlePressNext = React.useCallback(() => {
    if (step === 1 && data.title === '') {
      setTitleError(true)
      showToastMessage({
        type: 'Danger',
        title: t('workmodal.basic.nameplaceholder'),
        position: 'BOTTOM_CENTER',
      })
    } else
      setStep((step) => {
        return step + 1
      })
  }, [step, setStep, data])

  const handlePressCreate = React.useCallback(() => {
    return onPressCreate(typeCode)
  }, [onPressCreate, typeCode])

  const handlePressAddElement = React.useCallback(() => {
    const { projectElements } = data
    const idx = Math.floor(Math.random() * LABELS.length)
    onPressAddElement &&
      onPressAddElement({
        name: '',
        isMovable: true,
        labelObject: typeCode === 'DSP' || typeCode === 'CSP' ? LABELS[idx] : null,
        order: projectElements.length,
      })
  }, [onPressAddElement, data, typeCode])

  React.useEffect(() => {
    const { onChangeProjectElements } = viewProps
    if (onChangeProjectElements && typeCode === 'DSP') {
      onChangeProjectElements([
        { name: '', labelObject: 'sub.sky', isMovable: true, order: null },
        { name: '', labelObject: 'sub.purple', isMovable: true, order: null },
      ])
    }
    if (onChangeProjectElements && typeCode === 'WFP') {
      onChangeStartElement &&
        onChangeStartElement({
          name: '',
          labelObject: null,
          isMovable: false,
          order: null,
        })

      onChangeEndElement &&
        onChangeEndElement({
          name: '',
          labelObject: null,
          isMovable: false,
          order: null,
        })

      onChangeProjectElements([{ name: '', labelObject: null, isMovable: true, order: null }])
    }
    if (onChangeProjectElements && typeCode === 'CSP') {
      onChangeProjectElements([
        { name: '', labelObject: 'sub.sky', isMovable: true, order: null },
        { name: '', labelObject: 'sub.purple', isMovable: true, order: null },
      ])
    }
  }, [typeCode])

  return (
    <TouchableWithoutFeedback onPress={Platform.select({ native: Keyboard.dismiss })}>
      <View style={styles.container}>
        <View style={mobileView ? { backgroundColor: COLOR.gray.g050, padding: 12 } : { padding: 20 }}>
          {mobileView ? (
            <ButtonIcon
              containerSize={40}
              icon={<Icon name="arrow-left" size={16} color="gray.g700" />}
              backgroundColor="gray.g100"
              onPress={handlePressBack}
            />
          ) : (
            <Button
              style={{
                height: 36,
                flexDirection: 'row',
                paddingHorizontal: 12,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              backgroundColor="gray.g100"
              onPress={handlePressBack}
            >
              <Icon name="arrow-left" size={16} color="gray.g700" style={{ marginRight: 4 }} />
              <Text style={{ ...FONT.txtSm, color: COLOR.gray.g700 }}>{t('workmodal.basic.back')}</Text>
            </Button>
          )}
        </View>
        <View style={[{ flex: 1 }, !mobileView && { alignItems: 'center', marginTop: 74 }]}>
          {typeCode === 'DSP' ? (
            <CreateWorkModelHierarchy
              data={data}
              keyboardHeight={keyboardHeight}
              titleError={titleError}
              step={step}
              setStep={setStep}
              onChangeTitle={handleChangeTitle}
              onPressNext={handlePressNext}
              onPressBack={handlePressBack}
              onPressCreate={handlePressCreate}
              onPressAdd={handlePressAddElement}
              onPressRemove={onPressRemoveElement}
              {...viewProps}
            />
          ) : typeCode === 'WFP' ? (
            <CreateWorkModelWorkflow
              data={{
                ...data,
                preText: data.startElement?.name || '',
                endText: data.endElement?.name || '',
              }}
              keyboardHeight={keyboardHeight}
              titleError={titleError}
              step={step}
              setStep={setStep}
              onChangeTitle={handleChangeTitle}
              onPressNext={handlePressNext}
              onPressBack={handlePressBack}
              onChangePreText={(name) => {
                onChangeStartElement &&
                  data.startElement &&
                  onChangeStartElement({
                    ...data.startElement,
                    name,
                  })
              }}
              onChangeEndText={(name) => {
                onChangeEndElement &&
                  data.startElement &&
                  onChangeEndElement({
                    ...data.startElement,
                    name,
                  })
              }}
              onPressCreate={handlePressCreate}
              onPressAdd={handlePressAddElement}
              onPressRemove={onPressRemoveElement}
              {...viewProps}
            />
          ) : typeCode === 'CSP' ? (
            <CreateWorkModelCousult
              data={data}
              keyboardHeight={keyboardHeight}
              titleError={titleError}
              step={step}
              setStep={setStep}
              onChangeTitle={handleChangeTitle}
              onPressNext={handlePressNext}
              onPressBack={handlePressBack}
              onPressCreate={handlePressCreate}
              onPressAdd={handlePressAddElement}
              onPressRemove={onPressRemoveElement}
              {...viewProps}
            />
          ) : typeCode === 'TOP' ? (
            <CreateWorkModelTableorder
              data={data}
              keyboardHeight={keyboardHeight}
              titleError={titleError}
              step={step}
              setStep={setStep}
              onChangeTitle={handleChangeTitle}
              onPressNext={handlePressNext}
              onPressBack={handlePressBack}
              onPressCreate={handlePressCreate}
              onPressAdd={handlePressAddElement}
              onPressRemove={onPressRemoveElement}
              {...viewProps}
            />
          ) : (
            <></>
          )}
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.mono.white,
  },
})
