import { COLOR, useMobileView } from '@rocket-mono/libs'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Footer } from './footer'

interface Props {
  isValid: boolean
  step: number
  submitStep: number
  onPressNext: () => void
  onPressBack: () => void
  onPressCreate: () => void
  children: JSX.Element[] | JSX.Element
}
const ContainerView: React.FC<Props> = ({
  isValid,
  step,
  submitStep,
  onPressNext,
  onPressBack,
  onPressCreate,
  children,
}) => {
  const mobileView = useMobileView(1000)
  return (
    <View style={mobileView ? styles.mobileContainer : styles.container}>
      {children}
      {!mobileView && (
        <Footer
          isValid={isValid}
          submitStep={submitStep}
          mobileView={mobileView}
          step={step}
          onPressNext={onPressNext}
          onPressBack={onPressBack}
          onPressCreate={onPressCreate}
        />
      )}
    </View>
  )
}

export default ContainerView

const styles = StyleSheet.create({
  container: { width: 1000 },
  mobileContainer: {
    flex: 1,
    backgroundColor: COLOR.gray.g050,
  },
})
