import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'

interface Props {
  prePay: string
  postPay: string
  cash: string
  totalPrice: string
}

const TableInfoPrice: React.FC<Props> = ({ prePay, postPay, cash, totalPrice }) => {
  return (
    <View style={styles.priceInfo}>
      <View style={styles.priceHistory}>
        <View style={[styles.flexBox, styles.detailPrice]}>
          <Text style={styles.detailLabel}>선결제</Text>
          <Text style={{ ...FONT.txtMdMedium }}>{prePay}</Text>
        </View>
        <View style={[styles.flexBox, styles.detailPrice]}>
          <Text style={[styles.detailLabel, { color: COLOR.main.red }]}>후결제</Text>
          <Text style={{ ...FONT.txtMdMedium, color: COLOR.main.red }}>{postPay}</Text>
        </View>
        <View style={[styles.flexBox, styles.detailPrice]}>
          <Text style={styles.detailLabel}>현금</Text>
          <Text style={{ ...FONT.txtMdMedium }}>{cash}</Text>
        </View>
      </View>
      <View>
        <Text style={styles.orderLabel}>주문 합계</Text>
        <Text style={styles.orderTotalPrice}>{totalPrice}원</Text>
      </View>
    </View>
  )
}

export default TableInfoPrice

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  priceHistory: {
    marginBottom: 16,
    paddingVertical: 4,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: COLOR.gray.g200,
  },
  priceInfo: {
    paddingBottom: 4,
  },
  detailPrice: {
    justifyContent: 'space-between',
    paddingVertical: 2,
  },
  detailLabel: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g400,
  },
  orderLabel: {
    ...FONT.txtSm,
    textAlign: 'right',
  },
  orderTotalPrice: {
    ...FONT.H7Bold,
    textAlign: 'right',
  },
})
