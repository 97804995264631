import * as React from 'react'
import { ScrollView, StyleSheet, TextInput } from 'react-native'

import { Modal, View } from '@rocket-mono/foundations'
import type { TableorderMenuOptionGroupType } from '@rocket/types'
import { Text, useToast } from '@rui/atoms'
import { COLOR, replaceItemAtIndex } from '@rui/foundations'

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { useModalDialog } from '@rocket-mono/providers'
import { XEIcon } from '@rui/icons'
import { Button } from '../Button'
import BarButton from '../Button/BarButton'
import Switch from '../Switch'
import TopTab, { TopTabItem } from '../TopTab'

import { useTableorderMenuOption } from '../providers'
import { TableorderMenuGroupItem, TableorderMenuOptionItem } from './components'

const TabItemList: TopTabItem[] = [
  { name: '필수 옵션', code: 'REQUIRED', isShow: true },
  { name: '선택 옵션', code: 'OPTIONAL', isShow: true },
]
interface Props {
  groupType?: TableorderMenuOptionGroupType
  closeModal: () => void
}

const ModalView: React.FC<Props> = ({ groupType = 'REQUIRED', closeModal }) => {
  const [isReady, setIsReady] = React.useState(false)

  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const {
    optionGroupForm,
    saveOptionGroup,
    createOptionGroup,
    updateOptionGroup,
    deleteOptionGroup,
    createOption,
    deleteOption,
    changeOptionList,
  } = useTableorderMenuOption()

  const [tabCode, setTabCode] = React.useState<TableorderMenuOptionGroupType>(groupType)
  const [groupIndex, setGroupIndex] = React.useState<number>()

  const group = React.useMemo(
    () =>
      optionGroupForm && optionGroupForm.length > 0 && groupIndex !== undefined
        ? { ...optionGroupForm[groupIndex], index: groupIndex }
        : undefined,
    [optionGroupForm, groupIndex],
  )

  const isMultipleChoicesAvailable = React.useMemo(() => {
    if (group == undefined) return false
    return group.isMultipleChoicesAvailable
  }, [group])
  const toggleMultipleChoicesAvailable = React.useCallback(
    (isMultipleChoicesAvailable: boolean) => {
      if (group) updateOptionGroup(group.index, { ...group, isMultipleChoicesAvailable })
    },
    [group],
  )

  const handleUpdateGroupName = React.useCallback(
    (name: string) => {
      if (group) updateOptionGroup(group.index, { ...group, name })
    },
    [group, updateOptionGroup],
  )

  const handleUpdateOptionName = React.useCallback(
    (index: number, name: string) => {
      if (group) {
        const option = group.optionList[index]
        const optionList = replaceItemAtIndex(group.optionList, index, {
          ...option,
          name,
        })
        updateOptionGroup(group.index, { ...group, optionList })
      }
    },
    [group, updateOptionGroup],
  )
  const handleUpdateOptionPrice = React.useCallback(
    (index: number, price: string) => {
      if (group) {
        const option = group.optionList[index]
        const optionList = replaceItemAtIndex(group.optionList, index, {
          ...option,
          price,
        })
        updateOptionGroup(group.index, { ...group, optionList })
      }
    },
    [group, updateOptionGroup],
  )
  const handlePressGroupDelete = React.useCallback(() => {
    if (group !== undefined) {
      const list = [
        {
          name: '삭제',
          action: () => {
            setGroupIndex(undefined)
            deleteOptionGroup(group.index)
          },
        },
      ]
      showDialogMessage({
        title: '옵션 그룹을 삭제하시겠습니까?',
        message: '삭제하시면 해당 음식을 적용하고 있는 메뉴의 옵션도 같이 삭제됩니다.',
        list,
        cancelText: '취소',
        onCancel: hideDialogMessage,
      })
    }
  }, [group, deleteOptionGroup])

  const handlePressOptionDelete = React.useCallback(
    (index: number) => group !== undefined && deleteOption(group.index, index),
    [group, deleteOption],
  )

  const handleSaveOptionGroup = React.useCallback(() => {
    saveOptionGroup().then(() => {
      closeModal()
      setTimeout(() =>
        showToastMessage({
          type: 'Success',
          title: '옵션 그룹 수정내용을 반영했습니다.',
          position: 'BOTTOM_CENTER',
        }),
      ),
        300
    })
  }, [saveOptionGroup])

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      if (group && result.destination) {
        changeOptionList(group.index, result.source.index, result.destination.index)
      }
    },
    [group, changeOptionList],
  )

  React.useEffect(() => {
    setIsReady(true)
  }, [])

  React.useEffect(() => {
    setGroupIndex(undefined)
  }, [tabCode])

  React.useEffect(() => {
    setTabCode(groupType)
  }, [groupType])

  return (
    <View
      style={{
        width: 840,
        minHeight: 600,
        backgroundColor: COLOR.mono.white,
        borderRadius: 8,
      }}
    >
      <Modal.Header textAlign="center" onPressClose={closeModal}>
        옵션 그룹 관리
      </Modal.Header>
      <Modal.Body>
        <View style={styles.container}>
          <View
            style={{
              flex: 1,
              paddingRight: 28,
              borderRightWidth: 1,
              borderRightColor: COLOR.mono.gray,
            }}
          >
            <TopTab
              list={TabItemList}
              initCode={tabCode}
              onCode={(code) => setTabCode(code as TableorderMenuOptionGroupType)}
            />
            <Button
              wide
              style={{
                marginTop: 12,
                flexDirection: 'row',
                alignItems: 'center',
              }}
              borderStyle="dashed"
              borderColor="mono.darkGray"
              backgroundColor="mono.white"
              onPress={() => createOptionGroup(tabCode)}
            >
              <XEIcon name="plus" color="mono.paleBlack" size={14} viewBox="0 -100 1000 1000" />
              <Text fontName="subTextMedium" fontColor="mono.paleBlack">
                {' '}
                그룹 추가
              </Text>
            </Button>
            <ScrollView style={{ marginTop: 20, height: 350 }}>
              {optionGroupForm.map((group, index) =>
                group.type === tabCode ? (
                  <TableorderMenuGroupItem
                    key={`group-item-${index}`}
                    name={group.name}
                    selected={groupIndex === index}
                    onPress={() => setGroupIndex(index)}
                  />
                ) : null,
              )}
            </ScrollView>
          </View>
          <View style={{ flex: 1, paddingLeft: 28 }}>
            {group !== undefined && (
              <>
                <View
                  style={{
                    height: 64,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text fontName="txtSmMedium" fontColor="mono.black">
                      옵션 그룹명
                    </Text>
                    <Text fontName="textBold" fontColor="main.red">
                      *
                    </Text>
                  </View>
                  <View style={styles.inputContainer}>
                    <TextInput
                      style={[styles.input]}
                      value={group.name}
                      onChangeText={handleUpdateGroupName}
                      placeholder="옵션 그룹명을 입력하세요 (예 : 매운맛 옵션 )"
                      placeholderTextColor={COLOR.mono.gray}
                    />
                  </View>
                </View>
                <View
                  style={{
                    height: 50,
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottomWidth: 1,
                    borderBottomColor: COLOR.mono.pale,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      height: 50,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={{ marginRight: 12 }} fontName="subTextMedium" fontColor="mono.paleBlack">
                      옵션 중복 선택을 허용합니다.
                    </Text>
                    <Switch
                      activated={isMultipleChoicesAvailable}
                      onChange={toggleMultipleChoicesAvailable}
                      size="small"
                    />
                  </View>
                  <Button
                    style={{
                      padding: 4,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    backgroundColor="main.red"
                    onPress={handlePressGroupDelete}
                  >
                    <Text fontName="subTextRegular" fontColor="mono.white">
                      옵션그룹 삭제
                    </Text>
                  </Button>
                </View>
                <Button
                  wide
                  style={{
                    marginTop: 12,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  borderStyle="dashed"
                  borderColor="mono.darkGray"
                  backgroundColor="mono.white"
                  onPress={() => createOption(group.index)}
                >
                  <XEIcon name="plus" color="mono.paleBlack" size={14} viewBox="0 -100 1000 1000" />
                  <Text fontName="subTextMedium" fontColor="mono.paleBlack">
                    {' '}
                    옵션 추가
                  </Text>
                </Button>
                <ScrollView style={{ marginTop: 20, height: 286 }}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {isReady &&
                            group.optionList.map((item, index) => (
                              <Draggable
                                // key={`drag-${index}`}
                                draggableId={`drag-${index}`}
                                index={index}
                              >
                                {(provided) => {
                                  return (
                                    <div style={{ height: 46 }} ref={provided.innerRef} {...provided.draggableProps}>
                                      <TableorderMenuOptionItem
                                        key={item.id}
                                        name={item.name}
                                        price={item.price}
                                        dragHandleProps={provided.dragHandleProps}
                                        onChangeName={(name) => handleUpdateOptionName(index, name)}
                                        onChangePrice={(price) => handleUpdateOptionPrice(index, price)}
                                        onPressDelete={() => handlePressOptionDelete(index)}
                                      />
                                    </div>
                                  )
                                }}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </ScrollView>
              </>
            )}
          </View>
        </View>
      </Modal.Body>
      <Modal.Footer>
        <BarButton
          contanerStyle={{
            height: 50,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
          title="수정내용 반영"
          action={handleSaveOptionGroup}
          callToAction
        />
      </Modal.Footer>
    </View>
  )
}

export default ModalView

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: COLOR.mono.white,
  },
  inputContainer: {
    flex: 1,
    height: 34,
    marginRight: 4,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  input: {
    flex: 1,
    height: 34,
  },
})
