import { View } from '@rocket-mono/foundations'
import { Text } from '@rocket/atoms'
import { SkeletonBar, SkeletonCircle } from '@rocket/skeleton'
import { Avatar } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, TextInput } from 'react-native'
import { cellStyles } from '.'
import { ProfileSvg } from './ProfileSvg'

interface WorkMemberTableItemType {
  row: number
  column: number
  isLoading: boolean
  isMe?: boolean
  isOwner?: boolean
  isNew?: boolean
  isDelete?: boolean
  isJoined?: boolean
  onlyRead?: boolean
  profileImage: string
  userName: string
  userEmail: string
  onChangeName: (name: string) => void
  onPressTab: () => void
}
const WorkMembersTableUserItem = ({
  row,
  column,
  isLoading,
  isMe = false,
  isOwner = false,
  isNew = false,
  isDelete = false,
  isJoined = true,
  onlyRead,
  profileImage,
  userName,
  userEmail,
  onChangeName,
  onPressTab,
}: WorkMemberTableItemType) => {
  const { t } = useTranslation()
  const contentsInput = React.useRef<any>(null)
  const [name, setName] = useState(userName)
  const [cellHover, setCellHover] = React.useState(false)
  const [cellEdit, setCellEdit] = React.useState(false)

  useEffect(() => {
    setName(userName)
  }, [userName])

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      const { action, payload } = message.data
      if (action === 'member-table-tab' && payload.row === row && payload.column === column) {
        setCellEdit(true)
        console.log('row', row, message)
      }
    }
    window.addEventListener('message', handleMessage)
  }, [row, column])

  return (
    <Pressable
      onHoverIn={() => setCellHover(true)}
      onHoverOut={() => setCellHover(false)}
      onPress={() => {
        if (!onlyRead) setCellEdit(true)
      }}
      style={[cellStyles.cells, cellHover && { backgroundColor: COLOR.gray.g050 }]}
    >
      <View style={[cellStyles.flexBox, styles.userBox]}>
        {!isLoading ? (
          <View style={[styles.innerFlexBox, (isDelete || !isJoined) && { opacity: 0.4 }]}>
            {isDelete ? (
              <ProfileSvg size={28} />
            ) : (
              <Avatar
                // isLeader={isOwner}
                leader={isOwner}
                // size={28}
                size="small"
                profileUrl={profileImage}
                userName={userName}
              />
            )}
            <View style={styles.innerCon}>
              <View style={{ flexDirection: 'row' }}>
                {isMe && (
                  <View
                    style={{
                      marginRight: 4,
                      height: 16,
                      paddingHorizontal: 4,
                      borderRadius: 16,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: COLOR.main.blue,
                    }}
                  >
                    <Text fontName="subTextThin" fontColor="mono.white">
                      {t('workmember.table.user.me')}
                      {/*'나'*/}
                    </Text>
                  </View>
                )}
                {!isJoined && (
                  <View
                    style={{
                      marginRight: 4,
                      height: 18,
                      paddingHorizontal: 4,
                      borderRadius: 16,
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: COLOR.mono.white,
                      borderWidth: 1,
                      borderColor: COLOR.mono.lightGray,
                    }}
                  >
                    <Text fontName="subTextThin" fontColor="mono.darkGray">
                      {t('workmember.table.user.invite')}
                      {/*'초대'*/}
                    </Text>
                  </View>
                )}
                {cellEdit ? (
                  !onlyRead && (
                    <TextInput
                      ref={contentsInput}
                      value={name}
                      placeholderTextColor="gray.g400"
                      style={[cellStyles.cellInput, cellStyles.cellText]}
                      autoFocus={true}
                      onChangeText={(text) => {
                        setName(text)
                      }}
                      onKeyPress={(e) => {
                        console.log(e.nativeEvent.key)
                        if (e.nativeEvent.key === 'Enter') {
                          onChangeName(name)
                          setName(name)
                          setCellEdit(false)
                        } else if (e.nativeEvent.key === 'Escape') {
                          setName(userName)
                          setCellEdit(false)
                        } else if (e.nativeEvent.key === 'Tab') {
                          onChangeName(name)
                          setName(name)
                          onPressTab()
                          setCellEdit(false)
                        }
                      }}
                      onBlur={() => {
                        setName(userName)
                        setCellEdit(false)
                      }}
                    />
                  )
                ) : (
                  <Text style={[cellStyles.cellText, { ...FONT.txtSmMedium }]} numberOfLines={1} ellipsizeMode={'tail'}>
                    {isDelete && t('workmember.table.user.leave')}
                    {userName}
                  </Text>
                )}
                {isNew && (
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      marginLeft: 4,
                      borderRadius: 8,
                      backgroundColor: COLOR.main.red,
                    }}
                  />
                )}
              </View>
              {!isDelete && <Text style={{ ...FONT.txtXs, color: COLOR.gray.g500 }}>{userEmail}</Text>}
            </View>
          </View>
        ) : (
          <View style={styles.innerFlexBox}>
            <SkeletonCircle width={28} height={28} />
            <View style={styles.innerCon}>
              <View>
                <SkeletonBar width={'60%'} height={12} />
              </View>
              <Text>
                <SkeletonBar width={'40%'} height={8} />
              </Text>
            </View>
          </View>
        )}
      </View>
    </Pressable>
  )
}

export default WorkMembersTableUserItem

const styles = StyleSheet.create({
  userBox: {
    flexDirection: 'row',
  },
  innerFlexBox: {
    flex: 1,
    flexDirection: 'row',
  },
  innerCon: { flex: 1, marginLeft: 4, justifyContent: 'center' },
})
