import * as React from 'react'

import { User } from '@rocket/types'
import { PasswordProvider } from '../providers'
import { Provider } from './provider'
import { AstroType } from './types'
import View from './view'

interface Props {
  currentUser: User
  visible: boolean
  onPressClose: () => void
  astro: AstroType
  onStatus: (status: string) => void
}
export const PasswordModal: React.FC<Props> = ({ currentUser, onPressClose, visible, astro, onStatus }) => {
  return (
    <PasswordProvider currentUser={currentUser} astro={astro} onStatus={onStatus}>
      <Provider closeModal={onPressClose}>
        <View visible={visible} astro={astro} onPressClose={onPressClose} />
      </Provider>
    </PasswordProvider>
  )
}
