import { View } from '@rocket-mono/foundations'
import { TableorderCatalogOptionGroup } from '@rocket/types'
import * as React from 'react'

import MenuDetailOptionItem from './optionItem'
interface Props {
  list: TableorderCatalogOptionGroup[]
  optionIds: string[]
  onPress?: (optionId: string) => void
}
const MenuDetailOptionList: React.FC<Props> = ({ list, optionIds, onPress }) => {
  return (
    <View>
      {list.map((item, idx) => (
        <MenuDetailOptionItem key={`option-${idx}`} item={item} optionIds={optionIds} onPress={onPress} />
      ))}
    </View>
  )
}

export default MenuDetailOptionList
