import { Icon, TooltipPreviewWorkEditArchive } from '@rocket-atoms/icon'
import { Tooltip, TooltipPreview } from '@rocket-atoms/tooltip'
import { ButtonIcon } from '@rocket-mono/foundations'
import type { IColors } from '@rocket-mono/libs'
import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native'
import type { Axis } from '../components'
import { LabelPopup } from '../components'

interface Props {
  editType?: boolean
  isDelete?: boolean
  index: number
  drag?: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  text: string
  fieldError?: boolean
  labelColor?: IColors
  isDragable?: boolean
  autoFocus?: boolean
  onInputRef?: (ref: TextInput | null) => void
  onEnter?: (index: number) => void
  onTab?: (index: number) => void
  onChangeText: (text: string) => void
  onChangeLabelColor: (color: IColors) => void
  onPressDeleteInfo?: () => void
  onPressRemove: () => void
}

export const WorkmodelDragItem: React.FC<Props> = ({
  editType,
  isDelete = true,
  index,
  drag,
  dragHandleProps,
  text,
  fieldError,
  labelColor,
  isDragable = false,
  autoFocus,
  onInputRef,
  onEnter,
  onTab,
  onChangeText,
  onChangeLabelColor,
  onPressRemove,
  onPressDeleteInfo,
}) => {
  const { t, i18n } = useTranslation()
  const [isLabelPopupOpen, setIsLebelPopupOpen] = React.useState(false)
  const [isPress, setIsPress] = React.useState(false)
  const [isDeleteHover, setIsDeleteHover] = React.useState(false)
  const [axis, setAxis] = React.useState<Axis>({ x: 0, y: 0 })
  const labelRef = React.useRef<TouchableOpacity>(null)

  const getDragView = React.useCallback(
    (children: JSX.Element): JSX.Element =>
      dragHandleProps ? <div {...dragHandleProps}>{children}</div> : <>{children}</>,
    [dragHandleProps],
  )

  const onKeyPress = React.useCallback(
    (e: any) => {
      const { keyCode } = e.nativeEvent
      console.log('onKey', e.nativeEvent)

      if (keyCode === 13 && onEnter) {
        onEnter(index)
        e.preventDefault()
      } else if (keyCode === 9 && onTab) {
        onTab(index)
        e.preventDefault()
      }
    },
    [onEnter],
  )

  const tooltipInner = () => {
    return (
      <TooltipPreview
        render={() => (
          <Text>
            {t('workmodal.guidetooltip.archive.textbefore')}{' '}
            <Text style={{ color: COLOR.main.blue }}>{t('workmodal.guidetooltip.archive.textemphasis')}</Text>
            {t('workmodal.guidetooltip.archive.textafter')}
          </Text>
        )}
      >
        <TooltipPreviewWorkEditArchive global={i18n.language === 'ko' ? false : true} />
      </TooltipPreview>
    )
  }

  return (
    <View style={styles.itemGroup}>
      <View style={styles.dragIcon}>
        {isDragable &&
          getDragView(
            <Pressable
              onLongPress={() => {
                setIsPress(true)
                drag && drag()
              }}
              onPressOut={() => setIsPress(false)}
            >
              <Icon name={'drag-vertical'} size={16} style={{ width: 28 }} />
            </Pressable>,
          )}
      </View>
      <View style={[styles.inputGroup, isPress && { opacity: 0.5 }]}>
        {labelColor !== undefined && (
          <View style={[styles.labelArea]}>
            <TouchableOpacity
              ref={labelRef}
              onPress={() => setIsLebelPopupOpen(true)}
              onLayout={() => {
                labelRef?.current?.measureInWindow((x, y) => {
                  setAxis({
                    x: x - 12,
                    y: y - 50,
                  })
                })
              }}
            >
              <View style={[styles.labelView, labelColor && { backgroundColor: getRealColor(labelColor) }]}></View>
            </TouchableOpacity>
            <LabelPopup
              isOpen={isLabelPopupOpen}
              setIsOpen={setIsLebelPopupOpen}
              labelColor={labelColor}
              axis={axis}
              onLabelColor={onChangeLabelColor}
            />
          </View>
        )}
        <TextInput
          ref={onInputRef}
          style={[
            styles.inputStyle,
            labelColor ? { paddingLeft: 44 } : { paddingLeft: 12 },
            fieldError && text === '' && { borderColor: COLOR.status.busy },
            styles.editInputStyle,
          ]}
          placeholder={t('workmodal.basic.inputplaceholder')}
          value={text}
          placeholderTextColor={COLOR.gray.g400}
          textAlignVertical="center"
          underlineColorAndroid="transparent"
          onChangeText={onChangeText}
          onKeyPress={onKeyPress}
          maxLength={30}
          autoFocus={autoFocus}
        />
        <View style={editType && { width: 32 }}>
          {isDragable && (
            <>
              {/* 보드 삭제 불가한 경우 튤팁 아이콘 제공 */}
              {isDelete ? (
                <Pressable
                  style={[
                    styles.deleteArea,
                    // editType && { position: 'relative', right: 0 },
                  ]}
                  onPress={onPressRemove}
                  onHoverIn={() => setIsDeleteHover(true)}
                  onHoverOut={() => setIsDeleteHover(false)}
                >
                  <View style={[styles.removeIcon, isDeleteHover && { backgroundColor: COLOR.main.red }]}>
                    <Icon name={'close'} size={12} color={'mono.white'} style={{ marginTop: -2 }} />
                  </View>
                </Pressable>
              ) : Platform.OS === 'web' ? (
                <Tooltip
                  text={t('workmodal.guidetooltip.archive.title')}
                  textStyle={{ ...FONT.txtSmMedium }}
                  direction="left"
                  render={tooltipInner}
                >
                  {(props) => (
                    <View {...props} style={styles.tooltipArea}>
                      <Icon name="help" size={20} color={'gray.g700'} style={{ marginTop: -2 }} />
                    </View>
                  )}
                </Tooltip>
              ) : (
                <ButtonIcon icon={<Icon name="help" size={20} color="gray.g700" />} onPress={onPressDeleteInfo} />
              )}
            </>
          )}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  labelView: {
    width: 28,
    height: 28,
    borderRadius: 6,
  },
  itemGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    // marginBottom: 8,
    backgroundColor: COLOR.mono.white,
  },
  inputGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  labelArea: {
    position: 'absolute',
    left: 8,
    zIndex: 99,
  },
  inputStyle: {
    ...FONT.txtMd,
    width: '100%',
    height: 44,
    paddingRight: 44,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 8,
  },
  editInputStyle: {
    ...FONT.txtSm,
    flex: 1,
    width: 'auto',
    height: 44,
    paddingRight: 12,
  },
  deleteArea: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
  },
  tooltipArea: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
  },
  removeIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    backgroundColor: COLOR.gray.g400,
    borderRadius: 30,
  },
  dragIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
  },

  tooltipContainer: {
    maxWidth: 290,
    backgroundColor: COLOR.gray.g050,
    padding: 12,
    paddingBottom: 0,
  },
  tooltipText: {
    ...FONT.txtXs,
    paddingBottom: 12,
  },
  previewArea: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
