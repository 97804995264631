import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAstro, useCurrentUser } from '@rocket-mono/providers'
import { useToast } from '@rui/atoms'
import { WorkSettingLanguage } from '../../../components'
import { langData } from '../type'

interface Props {
  onPressBack?: () => void
}

const LanguageView: React.FC<Props> = ({ onPressBack }) => {
  const { t } = useTranslation()

  const { astro } = useAstro()
  const { show: showToastMessage } = useToast()

  const { currentUser, fetchCurrentUser } = useCurrentUser()

  const [currentUserLang, setCurrentUserLang] = useState(currentUser.lang || 'en')
  const [currentLangIndex, setCurrentLangIndex] = useState(langData.findIndex(({ key }) => key === currentUserLang))

  useEffect(() => {
    console.log('currentUser.lang', currentUser.lang)
  }, [currentUser])

  return (
    <WorkSettingLanguage
      defaultIndex={currentLangIndex}
      data={langData}
      onSelected={(code) => {
        console.log('onSelected', code)
        setCurrentUserLang(code)
      }}
      onPressSave={() => {
        const lang = currentUserLang
        astro
          .updateMe({
            userName: currentUser.userName,
            password: '',
            passwordConfirm: '',
            lang,
          })
          .then(fetchCurrentUser)
          .then(() => {
            const duration = 1000
            showToastMessage(
              {
                title: t('toast.confirmed'),
                position: 'BOTTOM_CENTER',
                type: 'Success',
              },
              duration,
            )
          })
        setCurrentLangIndex(langData.findIndex((item) => item.key === lang))
      }}
      onDismiss={onPressBack}
    />
  )
}

export default LanguageView
