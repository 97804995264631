import { Button } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'

interface Props {
  isValid: boolean
  mobileView: boolean
  step: number
  submitStep: number
  onPressNext: () => void
  onPressBack: () => void
  onPressCreate: () => void
}
export const Footer: React.FC<Props> = ({
  isValid,
  mobileView,
  step,
  submitStep,
  onPressNext,
  onPressBack,
  onPressCreate,
}) => {
  const { t } = useTranslation()
  return (
    <>
      {mobileView && (
        <View style={{ backgroundColor: COLOR.mono.white, marginTop: 20 }}>
          <View
            style={{
              width: '100%',
              height: 1,
              backgroundColor: COLOR.gray.g200,
            }}
          ></View>
        </View>
      )}
      <View
        style={[
          styles.flexBox,
          styles.buttonArea,
          mobileView && {
            backgroundColor: COLOR.mono.white,
          },
        ]}
      >
        <View style={[styles.flexBox]}>
          {/* {step !== 1 && (
          <Button
            style={{
              height: 36,
              marginRight: 8,
              minWidth: 'auto',
              paddingHorizontal: 12,
            }}
            borderColor="gray.g300"
            backgroundColor="mono.white"
            color="gray.g700"
            onPress={() => console.log('템플릿 변경')}
          >
            템플릿 변경
          </Button>
        )}
        {!mobileView && (
          <Button
            style={{ height: 36 }}
            borderColor="mono.white"
            backgroundColor="mono.white"
            color="gray.g700"
          >
            <View style={{ flexDirection: 'row' }}>
              <Icon name={'info'} size={16} />
              <Text>도움말</Text>
            </View>
          </Button>
        )} */}
        </View>
        <View style={[styles.flexBox]}>
          {step !== submitStep ? (
            <>
              {step > 1 && (
                <Button
                  style={{ height: 40, marginRight: 8, paddingHorizontal: 12 }}
                  backgroundColor="gray.g100"
                  onPress={onPressBack}
                >
                  <Text fontName="H7">{t('workmodal.basic.before')}</Text>
                </Button>
              )}
              <Button
                style={{ height: 40, paddingHorizontal: 12 }}
                backgroundColor="main.blue"
                onPress={onPressNext}
                disabled={!isValid}
              >
                <Text fontName="H7" fontColor="mono.white">
                  {t('workmodal.basic.next')}
                </Text>
              </Button>
            </>
          ) : (
            <>
              <Button
                style={{ height: 40, marginRight: 8, paddingHorizontal: 12 }}
                backgroundColor="gray.g100"
                onPress={onPressBack}
              >
                <Text fontName="H7">{t('workmodal.basic.before')}</Text>
              </Button>
              <Button
                style={{ height: 40, marginRight: 8, paddingHorizontal: 12 }}
                backgroundColor="main.blue"
                onPress={onPressCreate}
                disabled={!isValid}
              >
                <Text fontName="H7" fontColor="mono.white">
                  {t('workmodal.basic.workcreation')}
                </Text>
              </Button>
            </>
          )}
        </View>
      </View>
    </>
  )
}

export const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonArea: {
    paddingTop: 8,
  },
})
