import { DialogView } from '@rocket-mono/foundations'
import { COLOR, FONT } from '@rui/foundations'
import { DatePicker } from '@rui/molecules'
import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View, ViewStyle } from 'react-native'
import Dropdown from '../Dropdown'
import { useInterval } from '../hooks'
import { IconStateCircle } from '../Icons'

interface Props {
  visible: boolean
  type: 'BUSY' | 'OFF'
  busyApplyStartTimestamp?: number
  busyApplyEndTimestamp?: number
  offApplyStartTimestamp?: number
  offApplyEndTimestamp?: number
  onChangeDate: (startDate: Date | undefined, endDate: Date | undefined) => void
  onClose?: () => void
}

const getTimeDifferenceString = (_diff: number) => {
  const diff = _diff / 1000 // 시간 차이를 초로 계산
  const hours = Math.floor(diff / 3600)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((diff % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(diff % 60)
    .toString()
    .padStart(2, '0')
  return hours + ':' + minutes + ':' + seconds
}

const getDateString = (date: Date) => {
  return moment(date).format('YYYY-MM-DD')
}

const WorkSettingStateView = ({
  visible,
  type,
  // busyApplyStartTimestamp,
  busyApplyEndTimestamp,
  offApplyStartTimestamp,
  offApplyEndTimestamp,
  onChangeDate,
  onClose,
}: Props) => {
  const { t, i18n } = useTranslation()
  // const [calendarVisible, setCalendarVisible] = React.useState(false)
  const [time, setTime] = React.useState(0)
  const [startDate, setStartDate] = React.useState<string | undefined>(
    offApplyStartTimestamp ? getDateString(new Date(offApplyStartTimestamp)) : undefined,
  )
  const [endDate, setEndDate] = React.useState<string | undefined>(
    offApplyEndTimestamp ? getDateString(new Date(offApplyEndTimestamp)) : undefined,
  )

  const getDiff = () => {
    const todayTime = new Date().getTime()
    if (type === 'BUSY' && busyApplyEndTimestamp) {
      const diff = busyApplyEndTimestamp - todayTime
      // 5시간 = 18000000
      if (diff > 0 && diff < 18000000) {
        setTime(diff)
      } else {
        setTime(0)
      }
    }
  }

  React.useEffect(() => {
    getDiff()
  }, [])

  useInterval(() => {
    getDiff()
  }, 1000)

  return (
    <DialogView
      title={t('setting.state.title')}
      titleRender={() => (
        <View style={styles.stateArea}>
          <View style={{ marginLeft: -4 }}>
            {type === 'BUSY' && <IconStateCircle state={'busy'} />}
            {type === 'OFF' && <IconStateCircle state={'off'} />}
          </View>
          <Text style={styles.stateTitle}>
            {type === 'BUSY' && 'Busy'} {type === 'OFF' && 'Off'}
          </Text>
        </View>
      )}
      messageRender={() => (
        <View style={styles.messageArea}>
          {type === 'BUSY' && (
            <>
              <View>
                <Text style={{ ...FONT.txtSm, color: COLOR.gray.g700 }}>{t('setting.state.busydesc')}</Text>
              </View>
              {/* <Text style={styles.settingText}>00:59:49</Text> */}
              <Text style={styles.settingText}>{getTimeDifferenceString(time)}</Text>
            </>
          )}
          {type === 'OFF' && (
            <>
              <View>
                <Text style={{ ...FONT.txtSm, color: COLOR.gray.g700 }}>{t('setting.state.offdesc')}</Text>
              </View>
            </>
          )}
        </View>
      )}
      cancelText={t('setting.state.cancel')}
      visible={visible}
      onCancel={function () {
        onClose && onClose()
      }}
      onClose={() => {
        // setVisible(false)
        onClose && onClose()
      }}
      onDismiss={() => {
        // setVisible(false)
        onClose && onClose()
      }}
    >
      <View style={styles.contentsArea}>
        {type === 'BUSY' && (
          <>
            <Dropdown
              label={'-----'}
              list={[
                { label: '1hr', key: '1' },
                { label: '2hr', key: '2' },
                { label: '3hr', key: '3' },
                { label: '4hr', key: '4' },
                { label: '5hr', key: '5' },
              ]}
              listType={'basic'}
              onSelect={(item) => {
                onChangeDate(undefined, moment().add(item.key, 'hour').toDate())
              }}
              type={'group'}
              divide={true}
              buttonWidth={Platform.OS === 'web' ? 100 : 80}
              containerStyle={{
                height: 44,
                borderWidth: 0,
                justifyContent: 'center',
                alignItems: 'center',
                // ...Platform.select({ native: { paddingTop: 12 } }),
              }}
              buttonTextStyle={{ ...FONT.H4Bold } as ViewStyle}
            ></Dropdown>
          </>
        )}
        {type === 'OFF' && (
          <View style={{ width: '100%' }}>
            {/* <Pressable
              onPress={() => {
                setCalendarVisible(true)
              }}
            >
              <Text style={{ ...FONT.txtXs, color: COLOR.gray.g450 }}>
                {t('setting.state.periodmessage')}
              </Text>
            </Pressable> */}

            <DatePicker
              i18n={i18n}
              initialStart={startDate}
              initialEnd={endDate}
              onChangeStart={(date) => {
                if (date) {
                  setStartDate(moment(date).format('YYYY-MM-DD'))
                }
              }}
              onChangeEnd={(date) => {
                if (date) {
                  setEndDate(moment(date).format('YYYY-MM-DD'))
                  onChangeDate(new Date(startDate as string), date)
                }
              }}
            />
            {/* {calendarVisible && (
              <Calendar
                minDate={new Date()}
                // onDateChange={(date) => {
                //   console.log('date', date)
                //   onChangeDate(date as Date)
                // }}
                onClose={() => setCalendarVisible(false)}
                // changeDate={(arg) => {
                //   console.log('changeDate', arg)
                // }}
                onPressDate={onChangeDate}
              />
            )} */}
          </View>
        )}
      </View>
    </DialogView>
  )
}

export default WorkSettingStateView

const styles = StyleSheet.create({
  stateArea: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  stateTitle: {
    ...FONT.H6Bold,
    marginLeft: 0,
  },
  settingText: {
    marginTop: 4,
    ...FONT.txtSm,
    color: COLOR.gray.g700,
  },
  messageArea: {
    alignItems: 'center',
  },
  contentsArea: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 32,
  },
})
