import { Button, TextField } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import ErrorMessage from '../ErrorMessage'

export type PasswordType = {
  currentPassword: string
  newPassword: string
  passwordConfirm: string
}

interface Props {
  isCurrentUserPassword: boolean
  isBottomButton?: boolean
  onPress?: (password: PasswordType) => void
  status?: string
  setStatus?: (status: string) => void
  setIsEnable?: (isEnable: boolean) => void
}

const PasswordView = ({
  isCurrentUserPassword,
  isBottomButton = true,
  onPress,
  status = '',
  setStatus,
  setIsEnable,
}: Props) => {
  const { t } = useTranslation()
  const [isPassword, setIsPassword] = React.useState<boolean>()
  const [error, setError] = React.useState({
    currentError: '',
    newError: '',
    confirmError: '',
  })
  const [iptValue, setIptValue] = React.useState<PasswordType>({
    currentPassword: '',
    newPassword: '',
    passwordConfirm: '',
  })

  const checkHandlr = () => {
    setStatus && setStatus('')
    let newError = ''
    let confirmError = ''
    if (isCurrentUserPassword && iptValue.currentPassword === '') {
      setIsPassword(false)
      return
    } else {
      setIsPassword(true)
    }

    if (iptValue.newPassword.trim() === '') {
      newError = t('setting.password.errormessage.passwordempty')
    }

    if (iptValue.passwordConfirm.trim() === '') {
      confirmError = t('setting.password.errormessage.passwordempty')
    }

    if (iptValue.newPassword !== iptValue.passwordConfirm && !confirmError) {
      confirmError = t('setting.password.errormessage.newpasswordconfrirm')
    }

    setError({
      ...error,
      newError,
      confirmError,
    })

    if (newError || confirmError) {
      return
    }

    onPress && onPress(iptValue)
  }

  React.useEffect(() => {
    if (status === 'TOUCHED') {
      checkHandlr()
    }
  }, [status])

  const isEnable = React.useMemo(() => {
    let $isEnable = false
    if (isCurrentUserPassword) {
      if (iptValue.currentPassword !== '' && iptValue.newPassword !== '' && iptValue.passwordConfirm !== '') {
        $isEnable = true
      }
    } else {
      if (iptValue.newPassword !== '' && iptValue.passwordConfirm !== '') {
        $isEnable = true
      }
    }
    return $isEnable
  }, [isCurrentUserPassword, iptValue])

  React.useEffect(() => {
    setIsEnable && setIsEnable(isEnable)
  }, [setIsEnable, isEnable])

  return (
    <View>
      {isCurrentUserPassword ? (
        <View style={styles.formGroup}>
          <Text style={styles.label}>{t('setting.password.asislabel')}</Text>
          <TextField
            // height={36}
            value={iptValue.currentPassword}
            placeholder={t('setting.password.input')}
            onChangeValue={(text: string) => {
              setIptValue({ ...iptValue, currentPassword: text })
            }}
            // errorMessage={
            //   typeof isPassword === 'boolean'
            //     ? !isPassword
            //       ? t('setting.password.errormessage.passwordempty')
            //       : ''
            //     : ''
            // }
            // textContentType="password"
            secureTextEntry={true}
            style={{ width: '100%' }}
          />
          {typeof isPassword === 'boolean' && !isPassword ? (
            <ErrorMessage message={t('setting.password.errormessage.passwordempty')} />
          ) : (
            <></>
          )}
        </View>
      ) : (
        <></>
      )}
      <View style={styles.formGroup}>
        <Text style={styles.label}>{t('setting.password.newlabel')}</Text>
        <TextField
          // height={36}
          placeholder={t('setting.password.input')}
          value={iptValue.newPassword}
          onChangeValue={(text: string) => {
            setIptValue({ ...iptValue, newPassword: text })
          }}
          // errorMessage={error.newError}
          // textContentType="password"
          secureTextEntry={true}
          style={{ width: '100%' }}
        />
        {error.newError ? <ErrorMessage message={error.newError} /> : <></>}
      </View>
      <View style={styles.formGroup}>
        <Text style={styles.label}>{t('setting.password.confirmlabel')}</Text>
        <TextField
          value={iptValue.passwordConfirm}
          placeholder={t('setting.password.input')}
          onChangeValue={(text: string) => {
            setIptValue({ ...iptValue, passwordConfirm: text })
          }}
          // errorMessage={error.confirmError}
          // textContentType="password"
          secureTextEntry={true}
          style={{ width: '100%' }}
        />
        {error.confirmError ? <ErrorMessage message={error.confirmError} /> : <></>}
      </View>

      {isBottomButton ? (
        <View>
          <Button
            // wide={true}
            onPress={checkHandlr}
            disabled={!isEnable}
            style={{ width: '100%' }}
            text={<Text style={{ color: COLOR.mono.white }}>{t('setting.password.button')}</Text>}
          />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export default PasswordView

const styles = StyleSheet.create({
  formGroup: {
    marginBottom: 12,
  },
  label: {
    ...FONT.txtXsMedium,
    marginBottom: 4,
  },
})
