import { useModal } from '@rocket-mono/providers'
import { Sns } from '@rocket/types'
import * as React from 'react'
import { useWorkSettingUserInfo } from '../../components'
import { PasswordModal } from '../PasswordModal'
import WorkSettingUserInfoView from '../WorkSettingUserInfoView'
import type { ViewProps } from './types'

const InfoView: React.FC<ViewProps> = ({
  astro,
  profileFile,
  onProfile,
  onLeave,
  onPressBack,
  onUploadFileDone,
  onNicknameDone,
  containerStyle,
}) => {
  const { currentUser, onProfileSave, onSave } = useWorkSettingUserInfo()

  const { Modal, visible, close, open } = useModal('PasswordModal')

  const [userName, setUserName] = React.useState(currentUser.userName)

  const apiUrl = React.useMemo(() => astro.option.secureCdnUrl ?? '', [astro])
  const userProfilePath = React.useMemo(
    () => `/profile/${currentUser.userEmail}?time=${new Date().getTime()}`,
    [currentUser],
  )

  const userProfile = React.useMemo(() => apiUrl + userProfilePath, [apiUrl, userProfilePath])
  const userEmail = React.useMemo(() => currentUser.userEmail, [currentUser])
  const userSns = React.useMemo(() => {
    const sns: Sns[] = []
    const apple = currentUser.sns.filter((sns) => sns.snsCd === 'apple' || sns.snsCd === 'APPLE')
    const facebook = currentUser.sns.filter((sns) => sns.snsCd === 'facebook' || sns.snsCd === 'FACEBOOK')
    const kakao = currentUser.sns.filter((sns) => sns.snsCd === 'kakao' || sns.snsCd === 'KAKAOTALK')
    const google = currentUser.sns.filter((sns) => sns.snsCd === 'google' || sns.snsCd === 'GOOGLE')
    const naver = currentUser.sns.filter((sns) => sns.snsCd === 'naver' || sns.snsCd === 'NAVER')
    if (apple.length > 0) {
      sns.push(apple[0])
    }
    if (facebook.length > 0) {
      sns.push(facebook[0])
    }
    if (kakao.length > 0) {
      sns.push(kakao[0])
    }
    if (google.length > 0) {
      sns.push(google[0])
    }
    if (naver.length > 0) {
      sns.push(naver[0])
    }
    return sns
  }, [currentUser])

  React.useEffect(() => {
    if (profileFile) {
      onProfileSave(profileFile, onUploadFileDone)
    }
  }, [onProfileSave, onUploadFileDone, profileFile])

  return (
    <>
      <WorkSettingUserInfoView
        userName={currentUser.userName}
        userEmail={userEmail}
        userProfile={userProfile}
        userSns={userSns}
        onPressBack={onPressBack}
        onChangeName={setUserName}
        onPressPassword={open}
        onPressProfile={function () {
          onProfile()
        }}
        onPressSave={function () {
          onSave(userName, currentUser.lang ?? '', onNicknameDone)
        }}
        onLeave={onLeave}
        containerStyle={containerStyle}
      />
      <Modal animationType={'fade'} visible={visible}>
        <PasswordModal
          visible={visible}
          currentUser={currentUser}
          astro={astro}
          onPressClose={close}
          onStatus={(status) => {
            if (status === 'success') {
              close()
            }
          }}
        />
      </Modal>
    </>
  )
}

export default InfoView
