import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Text } from '@rui/atoms'
import { ClickOutside, COLOR, FONT, getBoxShadow } from '@rui/foundations'
import React, { useRef } from 'react'
import { Dimensions, PanResponder, Platform, Pressable, View as RNView, StyleSheet, View } from 'react-native'

interface TableCategoryType {
  width?: number
  iconOnly?: boolean
  iconName?: IconProp
  isMoreActive: boolean
  setIsMoreActive: (is: boolean) => void
  fieldText: string
  isMark?: boolean
  markText?: string
  children?: React.ReactNode
  staticSize?: boolean
}

const WorkMembersTableCategory = ({
  width,
  iconOnly = false,
  isMoreActive,
  setIsMoreActive,
  fieldText,
  isMark = true,
  markText,
  children,
  staticSize,
}: TableCategoryType) => {
  const dimensions = Dimensions.get('window')
  const [childrenWidth, setChildrenWidth] = React.useState(0)
  const [isHover, setIsHover] = React.useState(false)
  const [resizeIsHover, setResizeIsHover] = React.useState(false)
  const buttonRef = React.useRef<RNView>(null)
  const popoverRef = React.useRef<any>(null)

  const [dropdownMeasure, setDropdownMeasure] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    pageX: 0,
    pageY: 0,
  })
  const changeDropdownMeasure = React.useCallback(() => {
    buttonRef.current?.measure((x: number, y: number, width: number, height: number, pageX: number, pageY: number) => {
      setDropdownMeasure({ x, y, width, height, pageX, pageY })
    })
    setIsMoreActive(true)
  }, [buttonRef, dimensions, childrenWidth])

  const panResponder = useRef(
    PanResponder.create({
      // 화면을 터치한 것을 감지함
      onStartShouldSetPanResponder: () => {
        console.log('onStartShouldSetPanResponder')
        return true
      },

      // 실제 화면에 터치를 시작할 때 시작됨
      onPanResponderGrant: () => {
        console.log('onPanResponderGrant')
      },

      // 화면에 터치를 하고 움직일 때를 감지함
      // 이동에 대한 props로 event와 gesture 값을 받을 수 있다.
      // gesture내에 우리가 필요한 dx,dy 이동값이 들어있다.
      onPanResponderMove: (evt, gesture) => {
        console.log('onPanResponderMove', evt, gesture)
      },

      //화면에 터치를 하고 손을 뗏을 때를 감지함
      onPanResponderRelease: () => {
        console.log('onPanResponderRelease')
      },
    }),
  ).current

  return (
    <View style={width ? { width } : { flex: 1 }}>
      <View
        style={[
          styles.fieldContainer,
          isHover && {
            backgroundColor: COLOR.gray.g050,
            borderBottomWidth: 1,
            borderColor: COLOR.gray.g300,
          },
          iconOnly && { paddingHorizontal: 0 },
        ]}
      >
        <View style={styles.field}>
          <Pressable
            ref={buttonRef}
            onHoverIn={() => children && setIsHover(true)}
            onHoverOut={() => children && setIsHover(false)}
            onPress={changeDropdownMeasure}
          >
            <Text
              numberOfLines={1}
              ellipsizeMode={'tail'}
              fontName="txtXs"
              fontColor={isMark && markText ? 'main.blue' : 'gray.g500'}
            >
              {fieldText}
            </Text>
          </Pressable>
        </View>
        <View style={styles.option}>
          <Text style={styles.markText}>{markText}</Text>
          {!staticSize && (
            <Pressable
              style={styles.resizeButton}
              onHoverIn={() => setResizeIsHover(true)}
              onHoverOut={() => setResizeIsHover(false)}
            >
              <View
                style={[
                  {
                    width: 3,
                    height: 20,
                    backgroundColor: COLOR.transparent.base,
                  },
                  resizeIsHover && {
                    backgroundColor: COLOR.gray.g050,
                  },
                ]}
                {...panResponder.panHandlers}
              />
            </Pressable>
          )}
        </View>
      </View>
      {isMoreActive && (
        <ClickOutside onClickOutside={() => setIsMoreActive(false)}>
          <View
            ref={popoverRef}
            onLayout={(e) => {
              setChildrenWidth(e.nativeEvent.layout.width)
            }}
            style={[
              styles.popover,
              {
                top: dropdownMeasure.height,
                zIndex: 9,
              },
              { right: 0 },
            ]}
          >
            {children}
          </View>
        </ClickOutside>
      )}
    </View>
  )
}

export default WorkMembersTableCategory

const styles = StyleSheet.create({
  fieldContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 32,
    paddingHorizontal: 12,
    paddingVertical: 4,
    paddingRight: 4,
  },
  field: {
    flex: 1,
  },
  markText: {
    ...FONT.txt2Xs,
    color: COLOR.gray.g400,
    marginRight: 4,
  },
  option: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  resizeButton: {
    position: 'absolute',
    right: -4,
    ...Platform.select({
      web: { cursor: 'col-resize' },
    }),
  },
  popover: {
    position: 'absolute',
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingVertical: 4,
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 0),
  },
})
