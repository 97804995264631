import * as React from 'react'
import { View } from 'react-native'
import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg'

export type SnsType =
  | 'apple'
  | 'APPLE'
  | 'facebook'
  | 'FACEBOOK'
  | 'kakao'
  | 'KAKAOTALK'
  | 'google'
  | 'GOOGLE'
  | 'naver'
  | 'NAVER'

interface Props {
  type: SnsType
  size: number
}
const SnsSquareIcon: React.FC<Props> = ({ type, size = 20 }) => {
  return (
    <View>
      {type === 'APPLE' || type === 'apple' ? (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
          <Path
            d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
            fill="black"
          />
          <Path
            d="M13.9238 14.2188C13.6868 14.5833 13.427 14.916 13.1445 15.2168C12.8711 15.5085 12.5384 15.6589 12.1464 15.668C11.7545 15.6771 11.4446 15.6042 11.2168 15.4492C10.998 15.2852 10.6699 15.2031 10.2324 15.2031C9.78577 15.2031 9.44853 15.2806 9.22066 15.4355C8.9928 15.5814 8.69202 15.6634 8.31832 15.6816C7.93551 15.6999 7.59371 15.5495 7.29293 15.2305C7.00126 14.9023 6.73238 14.5605 6.48629 14.2051C6.24019 13.8405 6.01233 13.4167 5.80269 12.9336C5.60217 12.4414 5.45634 11.931 5.36519 11.4023C5.27405 10.8646 5.25126 10.3314 5.29683 9.80273C5.34241 9.26497 5.4928 8.77279 5.74801 8.32617C6.00321 7.87956 6.34501 7.52865 6.7734 7.27344C7.20178 7.00911 7.66207 6.8724 8.15426 6.86328C8.52795 6.85417 8.88342 6.9362 9.22066 7.10938C9.5579 7.28255 9.84501 7.36914 10.082 7.36914C10.3099 7.36914 10.6243 7.26888 11.0254 7.06836C11.4264 6.86784 11.8502 6.79036 12.2968 6.83594C12.4882 6.84505 12.8027 6.91797 13.2402 7.05469C13.6868 7.18229 14.0787 7.49674 14.416 7.99805C14.3977 8.01628 14.1836 8.20768 13.7734 8.57227C13.3632 8.92773 13.1627 9.47461 13.1718 10.2129C13.1809 11.097 13.4407 11.7122 13.9511 12.0586C14.4615 12.3958 14.7213 12.569 14.7304 12.5781C14.7213 12.5964 14.653 12.7741 14.5253 13.1113C14.4069 13.4486 14.2063 13.8177 13.9238 14.2188ZM10.6015 4.89453C10.8112 4.64844 11.0755 4.44336 11.3945 4.2793C11.7135 4.11523 12.0188 4.02865 12.3105 4.01953C12.347 4.35677 12.3059 4.68945 12.1875 5.01758C12.069 5.3457 11.9095 5.63281 11.7089 5.87891C11.5084 6.125 11.2532 6.33008 10.9433 6.49414C10.6334 6.6582 10.3144 6.72656 9.98629 6.69922C9.94071 6.37109 9.98173 6.04297 10.1093 5.71484C10.2461 5.38672 10.4101 5.11328 10.6015 4.89453Z"
            fill="white"
          />
        </Svg>
      ) : (
        <></>
      )}
      {type === 'FACEBOOK' || type === 'facebook' ? (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
          <Path
            d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
            fill="#3067A8"
          />
          <Path
            d="M6.96484 8.28507H8.76953V6.61711C8.76953 6.61711 8.78776 6.40291 8.82422 5.97453C8.86979 5.54614 9.09766 5.12687 9.50781 4.71672C9.92708 4.29744 10.4193 4.07414 10.9844 4.04679C11.5586 4.01034 12.2422 4.02856 13.0352 4.10148V5.96086H11.8594C11.8594 5.96086 11.7682 5.96997 11.5859 5.9882C11.4036 6.00643 11.2533 6.07935 11.1348 6.20695C11.0254 6.34367 10.9616 6.4895 10.9434 6.64445C10.9342 6.7994 10.9297 6.89966 10.9297 6.94523V8.28507H12.9668L12.707 10.3495H10.916V15.6816H8.76953V10.3495H6.96484V8.28507Z"
            fill="white"
          />
        </Svg>
      ) : (
        <></>
      )}
      {type === 'KAKAOTALK' || type === 'kakao' ? (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
          <Path
            d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
            fill="#F6E44C"
          />
          <Path
            d="M10 4.59375C9.19792 4.59375 8.44141 4.7168 7.73047 4.96289C7.01953 5.20898 6.39974 5.54167 5.87109 5.96094C5.34245 6.38021 4.92318 6.8724 4.61328 7.4375C4.3125 8.0026 4.16211 8.60417 4.16211 9.24219C4.16211 9.65234 4.22591 10.0488 4.35352 10.4316C4.48112 10.8053 4.65885 11.1608 4.88672 11.498C5.1237 11.8262 5.40625 12.1315 5.73438 12.4141C6.0625 12.6875 6.42253 12.929 6.81445 13.1387L6.37695 15.4219L8.90625 13.8086C9.07943 13.8359 9.25716 13.8587 9.43945 13.877C9.62174 13.8861 9.80859 13.8906 10 13.8906C10.8021 13.8906 11.5586 13.7721 12.2695 13.5352C12.9805 13.2891 13.6003 12.9564 14.1289 12.5371C14.6576 12.1087 15.0723 11.6165 15.373 11.0605C15.6829 10.4954 15.8379 9.88932 15.8379 9.24219C15.8379 8.60417 15.6829 8.0026 15.373 7.4375C15.0723 6.8724 14.6576 6.38021 14.1289 5.96094C13.6003 5.54167 12.9805 5.20898 12.2695 4.96289C11.5586 4.7168 10.8021 4.59375 10 4.59375Z"
            fill="#402021"
          />
        </Svg>
      ) : (
        <></>
      )}
      {type === 'GOOGLE' || type === 'google' ? (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
          <Path
            d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
            fill="white"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16 1H4C2.34315 1 1 2.34315 1 4V16C1 17.6569 2.34315 19 4 19H16C17.6569 19 19 17.6569 19 16V4C19 2.34315 17.6569 1 16 1ZM4 0C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0H4Z"
            fill="#E6E9ED"
          />
          <G clipPath="url(#clip0_3743_874430)">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16 10.1363C16 9.71069 15.9608 9.30202 15.8887 8.90918H10.1226V11.23H13.4177C13.2758 11.9801 12.8444 12.6154 12.1961 13.0411V14.5463H14.175C15.3327 13.5017 16.0006 11.9637 16.0006 10.1363H16Z"
              fill="#3E82F1"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.1226 16.0001C11.7757 16.0001 13.1617 15.4625 14.1745 14.5463L12.1956 13.041C11.6471 13.4011 10.9463 13.6136 10.122 13.6136C8.52724 13.6136 7.17756 12.5583 6.69595 11.1401H4.65125V12.6945C5.65889 14.6554 7.72897 16.0001 10.1226 16.0001Z"
              fill="#32A753"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.69647 11.14C6.57419 10.7799 6.5044 10.3956 6.5044 9.99991C6.5044 9.60424 6.57419 9.21988 6.69647 8.85982V7.30542H4.65119C4.23648 8.11541 4 9.03166 4 9.99991C4 10.9682 4.23648 11.8844 4.65119 12.6944L6.69647 11.14Z"
              fill="#F9BB00"
            />
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.1226 6.38643C11.0213 6.38643 11.8288 6.6894 12.4632 7.28347L14.2195 5.56232C13.1588 4.59463 11.7728 4 10.1226 4C7.72897 4 5.65889 5.3447 4.65125 7.30551L6.69652 8.85992C7.17814 7.44173 8.52781 6.38643 10.1226 6.38643Z"
              fill="#E74133"
            />
          </G>
          <Defs>
            <ClipPath id="clip0_3743_874430">
              <Rect width={12} height={12} fill="white" transform="translate(4 4)" />
            </ClipPath>
          </Defs>
        </Svg>
      ) : (
        <></>
      )}
      {type === 'NAVER' || type === 'naver' ? (
        <Svg width={size} height={size} viewBox="0 0 20 20" fill="none">
          <Path
            d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
            fill="#2DB404"
          />
          <Path
            d="M11.668 5.36328V10.2168L8.3457 5.36328H4.75V14.9746H8.33203V10.1211L11.6543 14.9746H15.25V5.36328H11.668Z"
            fill="white"
          />
        </Svg>
      ) : (
        <></>
      )}
    </View>
  )
}

export default SnsSquareIcon
