import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal, View } from '@rocket-mono/foundations'
import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon, NoContentsIcon, Text, WorkIcon } from '@rocket/atoms'
import { Channel, Project } from '@rocket/types'
import { XEIcon } from '@rui/icons'
import { Dimensions, ScrollView, StyleSheet } from 'react-native'
import type { MemberInviteItemType } from '../InviteMemberItem'
import MemberInviteEmail from '../MemberInviteEmail'
import MemberInviteList from '../MemberInviteList'
import MemberInviteWorkDetail from '../MemberInviteWorkDetail'
import type { WorkListType } from '../MemberInviteWorkList'
import MemberInviteWorkList from '../MemberInviteWorkList'

interface Props {
  currentProject?: Project
  columnPoint: boolean
  onPressClose: () => void
  inviteWorkList: WorkListType[]
  inviteMemberList: MemberInviteItemType[]
  secureCdnUrl?: string
  unarchiveList?: Channel[]
  archiveList?: Channel[]
  onEmailInvite: (name: string, email: string) => void
  inviteMemberHandleRemove: (index: number) => void
  inviteMemberHandleValueChange: (idx: number, key: string, value: string, type?: string) => void
  inviteMemberHandleBoardPopup: (index: number, selectedBoardList: string[]) => void
  onInvite: () => void
}

const MemberInviteScreen: React.FC<Props> = ({
  currentProject,
  columnPoint,
  onPressClose,
  inviteWorkList,
  inviteMemberList,
  secureCdnUrl,
  unarchiveList,
  archiveList,
  onEmailInvite,
  inviteMemberHandleRemove,
  inviteMemberHandleValueChange,
  inviteMemberHandleBoardPopup,
  onInvite,
}) => {
  const { t } = useTranslation()
  const windowHeight = Dimensions.get('window').height
  const workListDynamicHeight = windowHeight - 350
  const inviteListDynamicHeight = windowHeight - 350
  const popBodyHeight = 470
  // const [isWorkDetail, setIsWorkDetail] = useState<WorkListType>()
  const [selectedId, setSelectedId] = useState<string>()
  const [isMemberOptionScreen, setIsMemberOptionScreen] = useState(false)

  const workDetail = useMemo(() => {
    return inviteWorkList.find((item) => item.id === selectedId)
  }, [inviteWorkList, selectedId])

  // useEffect(() => {
  //   console.log('selectedId', selectedId)
  // }, [selectedId])

  // useEffect(() => {
  //   console.log('inviteWorkList', inviteWorkList)
  // }, [inviteWorkList])

  // useEffect(() => {
  //   console.log('workDetail', workDetail)
  // }, [workDetail])

  const submitButtonContainer = () => {
    return (
      <View style={styles.bottomButtonArea}>
        <Button
          wide
          disabled={inviteMemberList.length > 0 ? false : true}
          style={[
            {
              height: 50,
              borderRadius: 0,
              borderBottomRightRadius: 8,
            },
            columnPoint && { borderBottomRightRadius: 0 },
          ]}
          onPress={onInvite}
        >
          <Text style={{ ...FONT.H7Medium, color: COLOR.mono.white }}>
            {inviteMemberList.length > 0
              ? `${inviteMemberList.length}${t('workinvite.memberoption.submit.isValue')}`
              : t('workinvite.memberoption.submit.normal')}
          </Text>
        </Button>
      </View>
    )
  }

  return (
    <View style={[{ width: '100%' }, columnPoint ? { height: '100%' } : { height: 552 }]}>
      <Modal.Header
        height={82}
        textAlign="left"
        closeIcon={<XEIcon name={'close'} />}
        onPressClose={() => {
          onPressClose()
        }}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.headerTopContainer}>
            <Text fontName="H7Medium" fontColor="gray.g900">
              {t('workinvite.title')}
            </Text>
          </View>
          <View style={styles.headerBottomContainer}>
            <View style={[styles.labelContainer]}>
              <WorkIcon type={currentProject?.type.code || ''} size={20} />
              <Text
                fontName="txtMdMedium"
                fontColor="gray.g900"
                style={{ marginLeft: 4, marginTop: 2 }}
                numberOfLines={1}
                ellipsizeMode={'tail'}
              >
                {currentProject?.title}
              </Text>
            </View>
          </View>
        </View>
      </Modal.Header>
      <Modal.Body
        style={{ position: 'relative', padding: 0 }}
        maxHeight={'100%'}
        innerStyle={{ width: '100%', height: '100%' }}
        contentContainerStyle={{ flex: 1 }}
      >
        <View style={[styles.bodyArea, columnPoint && { flexDirection: 'column', flex: 1 }]}>
          {isMemberOptionScreen ? (
            <View style={{ position: 'relative' }}>
              <View
                style={{
                  backgroundColor: COLOR.mono.white,
                  marginHorizontal: 20,
                  marginBottom: 12,
                  borderBottomWidth: 1,
                  borderColor: COLOR.gray.g050,
                  paddingVertical: 8,
                }}
              >
                <Button
                  onPress={() => {
                    setIsMemberOptionScreen(false)
                    setSelectedId(undefined)
                  }}
                  backgroundColor={'transparent'}
                  style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                  <FAIcon iconName={faAngleLeft} size={'xs'} color={'gray.g400'} />
                  <Text style={{ marginLeft: 4 }}>{t('workinvite.title')}</Text>
                </Button>
              </View>
            </View>
          ) : (
            <View
              style={[
                styles.searchArea,
                { height: popBodyHeight },
                columnPoint && { flex: 1, width: '100%', paddingBottom: 70 },
                !columnPoint && { borderBottomLeftRadius: 8 },
              ]}
            >
              <View style={styles.emailSearch}>
                <MemberInviteEmail onPressAdd={onEmailInvite} />
              </View>
              <View style={{ flex: 1 }}>
                {inviteWorkList.length > 0 ? (
                  <MemberInviteWorkList
                    scrollViewHeight={columnPoint ? workListDynamicHeight : 230}
                    workList={inviteWorkList}
                    onPress={(item) => setSelectedId(item.id)}
                  />
                ) : (
                  <View style={styles.itemCenter}>
                    <NoContentsIcon
                      iconWidth={80}
                      type={'board'}
                      text={t('workinvite.invite.fromwork.empty')}
                      textSize={'normal'}
                    />
                  </View>
                )}
              </View>

              {selectedId && workDetail ? (
                <View style={[styles.workDetailLayer, columnPoint && { bottom: 70 }]}>
                  <MemberInviteWorkDetail
                    secureCdnUrl={secureCdnUrl}
                    workName={workDetail.name}
                    workType={workDetail.type}
                    inviteList={workDetail.detailWork}
                    onPressClose={() => setSelectedId(undefined)}
                  />
                </View>
              ) : (
                <></>
              )}

              {columnPoint && !isMemberOptionScreen && (
                <View
                  style={[
                    {
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      width: '100%',
                    },
                  ]}
                >
                  <Button
                    wide
                    disabled={inviteMemberList.length > 0 ? false : true}
                    style={[
                      {
                        height: 50,
                        borderRadius: 0,
                      },
                    ]}
                    onPress={() => setIsMemberOptionScreen(true)}
                  >
                    <Text style={{ ...FONT.H7Medium, color: COLOR.mono.white }}>
                      {inviteMemberList.length > 0
                        ? `${inviteMemberList.length}${t('workinvite.memberoption.submit.isValue')}`
                        : t('workinvite.memberoption.submit.normal')}
                    </Text>
                  </Button>
                </View>
              )}
            </View>
          )}

          {!columnPoint || (columnPoint && isMemberOptionScreen) ? (
            <View
              style={[
                styles.inviteListArea,
                columnPoint && styles.inviteListColumnArea,
                columnPoint && isMemberOptionScreen && { display: 'flex', height: '100%', zIndex: 999 },
              ]}
            >
              {columnPoint && (
                <Text
                  style={[
                    styles.sectionTitle,
                    { paddingLeft: 24 },
                    columnPoint && { ...FONT.txtMdMedium, color: COLOR.gray.g700 },
                  ]}
                >
                  {t('workinvite.memberoption.label')}
                </Text>
              )}
              {inviteMemberList.length > 0 ? (
                <ScrollView
                  style={[
                    styles.inviteList,
                    { maxHeight: columnPoint ? '100%' : popBodyHeight - 50 },
                    columnPoint && { height: inviteListDynamicHeight },
                  ]}
                >
                  <MemberInviteList
                    secureCdnUrl={secureCdnUrl}
                    list={inviteMemberList}
                    unarchiveList={unarchiveList}
                    archiveList={archiveList}
                    handleRemove={inviteMemberHandleRemove}
                    handleValueChange={inviteMemberHandleValueChange}
                    handleBoardPopup={inviteMemberHandleBoardPopup}
                  />
                </ScrollView>
              ) : (
                <View style={[styles.itemCenter, { marginTop: -50 }]}>
                  <NoContentsIcon
                    iconWidth={100}
                    type={'member'}
                    text={t('workinvite.memberoption.empty')}
                    textSize={'normal'}
                  />
                </View>
              )}
              {/* 웹 뷰: 권한 화면 초대 버튼 */}
              {!columnPoint && submitButtonContainer()}
            </View>
          ) : (
            <></>
          )}
          {/* 웹 모바일 뷰: 권한 화면 초대 버튼 */}
          {columnPoint && isMemberOptionScreen && submitButtonContainer()}
        </View>
      </Modal.Body>
    </View>
  )
}

export default MemberInviteScreen

const styles = StyleSheet.create({
  container: {
    width: 424,
    minHeight: 560,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
  },
  headerTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
    width: 350,
  },
  headerBottomContainer: {
    marginTop: 4,
  },
  labelContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerButton: {
    height: 50,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  bodyArea: {
    flexDirection: 'row',
    height: '100%',
  },
  searchArea: {
    width: '50%',
    backgroundColor: COLOR.gray.g050,
    paddingVertical: 16,
    paddingHorizontal: 24,
  },
  emailSearch: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    paddingBottom: 20,
    marginBottom: 20,
  },
  inviteListArea: {
    width: '50%',
    zIndex: 99,
  },
  inviteListColumnArea: {
    flex: 1,
    width: '100%',
    height: '50%',
    backgroundColor: COLOR.mono.white,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    zIndex: 999,
  },
  inviteList: {
    paddingTop: 8,
    paddingBottom: 80,
    paddingHorizontal: 24,
  },
  itemCenter: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  workDetailLayer: {
    position: 'absolute',
    zIndex: 99,
    top: 16,
    right: 20,
    bottom: 20,
    left: 20,
  },
  sectionTitle: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g600,
    marginBottom: 8,
  },
  bottomButtonArea: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: COLOR.mono.white,
    borderBottomRightRadius: 8,
    zIndex: 999,
  },
  dimmedCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: COLOR.dimmed.a40,
  },
})
