import { Icon } from '@rocket-atoms/icon'
import type { IColors } from '@rocket-mono/libs'
import { COLOR, FONT, changeItemAtIndex, useMobileView } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import { useToast } from '@rui/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { WorkmodelDragList } from '../../WorkmodelDragList'
import WorkmodelPreviewCousult from '../../WorkmodelPreview/WorkmodelPreviewCousult'
import Container from '../container'
import { Header } from '../header'
import LayoutView from '../layout'
import type { CreateWorkModelCounsultProps } from '../types'

const CreateWorkModelCousult = ({
  data,
  titleError,
  step,
  keyboardHeight,
  setStep,
  onPressNext,
  onPressBack,
  onChangeTitle,
  onChangeGoal,
  onChangeProjectElements,
  onChangeElement,
  onPressCreate,
  onPressAdd,
  onPressRemove,
}: CreateWorkModelCounsultProps) => {
  const { t } = useTranslation()
  const { title, goal, projectElements } = data

  const stepper = [
    { step: 1, title: t('workmodal.basic.basesetting'), checked: false },
    { step: 2, title: t('workmodal.csp.part'), checked: false },
  ]

  const mobileView = useMobileView(1000)
  const [scrollEnabled, setScrollEnabled] = React.useState(true)

  const dragList = React.useMemo(
    () =>
      projectElements.map((o) => ({
        text: o.name,
        labelColor: o.labelObject as IColors,
      })),
    [projectElements],
  )

  const previewList = React.useMemo(
    () =>
      projectElements.map((o) => ({
        title: o.name,
        labelColor: o.labelObject as IColors,
      })),
    [projectElements],
  )

  const [fieldError, setFieldError] = React.useState(false)
  const { show: showToastMessage } = useToast()

  const handlePressCreate = React.useCallback(() => {
    if (projectElements.filter((o) => o.name === '').length > 0) {
      setFieldError(true)
      showToastMessage({
        type: 'Danger',
        title: t('workmodal.error.subname'),
        position: 'BOTTOM_CENTER',
      })
    } else {
      setFieldError(false)
      onPressCreate()
    }
  }, [onPressCreate, projectElements])
  const onChangeList = React.useCallback(
    (from: number, to: number) => {
      onChangeProjectElements(changeItemAtIndex(projectElements, from, to))
    },
    [projectElements],
  )

  return (
    <Container
      isValid={true}
      step={step}
      submitStep={2}
      onPressNext={onPressNext}
      onPressBack={onPressBack}
      onPressCreate={handlePressCreate}
    >
      <Header
        title={t('workmodal.csp.title')}
        info={t('workmodal.basic.settingchangedesc')}
        stepper={stepper}
        step={step}
        setStep={setStep}
      />
      <LayoutView
        isValid={true}
        step={step}
        submitStep={2}
        scrollEnabled={scrollEnabled}
        keyboardHeight={keyboardHeight}
        onPressNext={onPressNext}
        onPressBack={onPressBack}
        onPressCreate={handlePressCreate}
        previewElement={
          <WorkmodelPreviewCousult
            mobileView={mobileView}
            title={title}
            goal={goal || ''}
            list={previewList}
            step={step}
          />
        }
      >
        {step === 1 ? (
          <ScrollView style={{ paddingHorizontal: 4, paddingBottom: 4 }}>
            <View>
              <View style={styles.sectionLabel}>
                <View style={styles.labelBlock}></View>
                <Text style={styles.labelName}>{t('workmodal.basic.name')}</Text>
                <Text style={[styles.labelName, { color: COLOR.main.red }]}>*</Text>
              </View>
              <View>
                <TextInput
                  value={title}
                  style={[styles.inputStyle, titleError && { borderColor: COLOR.status.busy }]}
                  placeholder={t('workmodal.basic.nameplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                  onChangeText={onChangeTitle}
                />
                {titleError && (
                  <View style={{ flexDirection: 'row' }}>
                    <Icon name="error" color="status.busy" />
                    <Text fontColor="status.busy">{t('workmodal.basic.nameplaceholder')}</Text>
                  </View>
                )}
              </View>
            </View>
            <View style={[{ marginTop: 24 }, mobileView && { marginTop: 16 }]}>
              <View style={styles.sectionLabel}>
                <View style={styles.labelBlock}></View>
                <Text style={styles.labelName}>{t('workmodal.csp.notice')}</Text>
              </View>
              <TextInput
                style={[styles.inputStyle]}
                placeholder={t('workmodal.csp.noticeplaceholder')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
                onChangeText={onChangeGoal}
              />
            </View>
          </ScrollView>
        ) : step === 2 ? (
          <ScrollView style={{ paddingHorizontal: 4, paddingBottom: 4 }}>
            <View style={styles.sectionLabel}>
              <View style={styles.labelBlock}></View>
              <Text style={styles.labelName}>{t('workmodal.csp.addconsultation')}</Text>
            </View>
            <WorkmodelDragList
              list={dragList}
              fieldError={fieldError}
              setScrollEnabled={setScrollEnabled}
              onChangeList={onChangeList}
              onPressAdd={onPressAdd}
              onClickRemoveButton={onPressRemove}
              onChangeElement={onChangeElement}
            />
          </ScrollView>
        ) : (
          <></>
        )}
      </LayoutView>
    </Container>
  )
}

export default CreateWorkModelCousult

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentBox: {
    flex: 1,
    position: 'relative',
    justifyContent: 'flex-start',
  },
  sectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  labelBlock: {
    width: 3,
    height: 14,
    marginRight: 6,
    backgroundColor: COLOR.mono.black,
  },
  labelName: {
    ...FONT.txtMdMedium,
  },
  inputStyle: {
    ...FONT.txtMd,
    width: '100%',
    height: 44,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 8,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
      native: {},
    }),
  },
})
