import React, { useState } from 'react'
import { WorkSettingUserInfo } from '../../../../components'

import { useAstro, useCurrentUser } from '@rocket-mono/providers'

interface Props {
  onPressBack?: () => void
}

const UserInfoView: React.FC<Props> = ({ onPressBack }) => {
  const { astro } = useAstro()
  const { fetchCurrentUser } = useCurrentUser()

  const [file, setFile] = useState<File>()

  return (
    <WorkSettingUserInfo
      astro={astro}
      profileFile={file}
      onProfile={() => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*'
        input.onchange = (e) => {
          const files = (e.target as HTMLInputElement).files
          if (files && files.length > 0) {
            setFile(files[0])
          }
        }
        input.click()
      }}
      onPressBack={onPressBack}
      onNicknameDone={() => {
        fetchCurrentUser()
      }}
    />
  )
}

export default UserInfoView
