import { useContext } from 'react'

import Context from './context'
import type { ContextProps } from './types'

export const useWorkBoard = () => {
  const context = useContext<ContextProps | undefined>(Context)

  if (context === undefined) throw new Error('There must be a WorkBoardProvider as Ancestor of all Hooks and HOCs')

  return context
}
