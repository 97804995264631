import * as React from 'react'
import { Dimensions, FlatList, ListRenderItemInfo, StyleSheet } from 'react-native'

import { COLOR } from '@rui/foundations'

import { View } from '@rocket-mono/foundations'
import { useTranslation } from 'react-i18next'
import { ModalBottomSheetType } from '../../components/ModalBottomSheetType'
import { WorkSettingItem } from './WorkSettingItem'
import type { SectionItemType } from './types'

interface Props {
  visible: boolean
  sectionList: SectionItemType[]
  bottom: number
  onPressItem?: (projectId: string) => void
  onPressClose?: () => void
}

export const WorkSetting: React.FC<Props> = ({ visible, sectionList, bottom = 0, onPressItem, onPressClose }) => {
  const { t } = useTranslation()
  const windowHeigt = Dimensions.get('window').height

  const renderItem = React.useCallback(
    ({ item }: ListRenderItemInfo<SectionItemType>) => {
      return (
        <WorkSettingItem
          item={item}
          onPress={() => {
            onPressItem && onPressItem(item.key)
          }}
        />
      )
    },
    [sectionList],
  )

  const keyExtractor = React.useCallback((item: SectionItemType) => item.key, [sectionList])

  return (
    <>
      <ModalBottomSheetType visible={visible} height={windowHeigt - 50 - bottom} bottom={bottom}>
        <ModalBottomSheetType.Header textAlign="center" onPressClose={onPressClose}>
          {t('screen.workmodelupdate.title')}
        </ModalBottomSheetType.Header>
        <ModalBottomSheetType.Body style={{ margin: 0 }}>
          <FlatList
            data={sectionList.filter((o) => !o.isDefault)}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
          />
        </ModalBottomSheetType.Body>
      </ModalBottomSheetType>
      {visible && <View style={styles.dimmedCover}></View>}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  dimmedCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: COLOR.dimmed.a40,
  },
})
