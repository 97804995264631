import React, { ForwardedRef, forwardRef, memo } from 'react'
import type { SvgProps } from 'react-native-svg'
import { Mask, Path, Svg } from 'react-native-svg'

interface Props extends SvgProps {
  borderColor?: string
}

const DoneIcon = (
  { borderColor = '#B4BEC5', ...props }: Props,
  ref: ForwardedRef<Svg>,
) => {
  return (
    <Svg
      width={18}
      height={15}
      viewBox="0 0 18 15"
      fill="none"
      ref={ref}
      {...props}
    >
      <Mask id="path-1-inside-1_7764_315057" fill="white">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 0C1.34315 0 0 1.34315 0 3V12C0 13.6569 1.34315 15 3 15H15C16.6569 15 18 13.6569 18 12V3C18 1.34315 16.6569 0 15 0H3ZM7.86069 10.7543L14.1079 5.45584L12.8849 4L7.96926 8.16916L5.63464 5.82331L4.29395 7.17044L7.86069 10.7543Z"
        />
      </Mask>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V12C0 13.6569 1.34315 15 3 15H15C16.6569 15 18 13.6569 18 12V3C18 1.34315 16.6569 0 15 0H3ZM7.86069 10.7543L14.1079 5.45584L12.8849 4L7.96926 8.16916L5.63464 5.82331L4.29395 7.17044L7.86069 10.7543Z"
        fill={borderColor}
      />
      <Path
        d="M14.1079 5.45584L15.0781 6.5998L16.2167 5.63414L15.2564 4.49103L14.1079 5.45584ZM7.86069 10.7543L6.79749 11.8125L7.77457 12.7942L8.83093 11.8983L7.86069 10.7543ZM12.8849 4L14.0334 3.03518L13.0641 1.88121L11.9147 2.85604L12.8849 4ZM7.96926 8.16916L6.90606 9.22727L7.88314 10.2091L8.9395 9.31312L7.96926 8.16916ZM5.63464 5.82331L6.69784 4.76519L5.63464 3.69688L4.57143 4.76519L5.63464 5.82331ZM4.29395 7.17044L3.23074 6.11233L2.1777 7.17044L3.23074 8.22856L4.29395 7.17044ZM1.5 3C1.5 2.17157 2.17157 1.5 3 1.5V-1.5C0.514719 -1.5 -1.5 0.514718 -1.5 3H1.5ZM1.5 12V3H-1.5V12H1.5ZM3 13.5C2.17157 13.5 1.5 12.8284 1.5 12H-1.5C-1.5 14.4853 0.514719 16.5 3 16.5V13.5ZM15 13.5H3V16.5H15V13.5ZM16.5 12C16.5 12.8284 15.8284 13.5 15 13.5V16.5C17.4853 16.5 19.5 14.4853 19.5 12H16.5ZM16.5 3V12H19.5V3H16.5ZM15 1.5C15.8284 1.5 16.5 2.17157 16.5 3H19.5C19.5 0.514719 17.4853 -1.5 15 -1.5V1.5ZM3 1.5H15V-1.5H3V1.5ZM13.1376 4.31189L6.89045 9.61038L8.83093 11.8983L15.0781 6.5998L13.1376 4.31189ZM11.7364 4.96482L12.9593 6.42066L15.2564 4.49103L14.0334 3.03518L11.7364 4.96482ZM8.9395 9.31312L13.8551 5.14396L11.9147 2.85604L6.99902 7.0252L8.9395 9.31312ZM4.57143 6.88142L6.90606 9.22727L9.03246 7.11105L6.69784 4.76519L4.57143 6.88142ZM5.35715 8.22856L6.69784 6.88142L4.57143 4.76519L3.23074 6.11233L5.35715 8.22856ZM8.92389 9.69623L5.35715 6.11233L3.23074 8.22856L6.79749 11.8125L8.92389 9.69623Z"
        fill={borderColor}
        mask="url(#path-1-inside-1_7764_315057)"
      />
    </Svg>
  )
}

export default memo(forwardRef(DoneIcon))
