import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'

const BoardOrderCardBlank: React.FC = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>접수된 주문이 없습니다.</Text>
    </View>
  )
}

export default BoardOrderCardBlank

const styles = StyleSheet.create({
  container: {
    height: 115,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    backgroundColor: COLOR.mono.pale,
    padding: 8,
    marginBottom: 8,
    marginRight: 12,
  },
  text: {
    ...FONT.txtSm,
    color: COLOR.mono.darkGray,
  },
})
