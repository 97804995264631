import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View, ViewStyle } from 'react-native'
import { Button } from './Button'
import QuantityButton from './Button/QuantityButton'
import OrderMenuOptionItem from './OrderMenuOptionItem'

export interface CartItemProps {
  status: string
  title: string
  option?: {
    name: string
    price: string
  }[]
  onePrice: string
  totalPrice: string
  quantity: number
}

interface Props {
  item: CartItemProps
  increaseQuantity?: () => void
  decreaseQuantity?: () => void
  onChange?: (item: CartItemProps) => void
  onDelete?: (item: CartItemProps) => void
  containerStyle?: ViewStyle | ViewStyle[]
  minQuantityNumber?: number
}
const CartItem: React.FC<Props> = ({
  item,
  increaseQuantity,
  decreaseQuantity,
  onChange,
  onDelete,
  containerStyle,
  minQuantityNumber,
}) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.flexbox}>
        <Text style={{ ...FONT.txtMdBold }}>{item.title}</Text>
        <Button
          backgroundColor="gray.g050"
          borderColor="gray.g300"
          color={'gray.g800'}
          size={'small'}
          onPress={() => onDelete && onDelete(item)}
        >
          삭제
        </Button>
      </View>
      <View style={{ marginTop: 8 }}>
        {item.option?.map((el, idx) => (
          <OrderMenuOptionItem item={el} idx={idx} key={`item-${idx}`} />
        ))}
      </View>
      <Text style={styles.onePrice}>{item.onePrice}원</Text>
      <View style={styles.flexbox}>
        {item.status === 'soldout' ? (
          <View style={{ flex: 1 }}>
            <Text
              style={{
                ...FONT.txtSm,
                color: COLOR.main.red,
                textAlign: 'right',
              }}
            >
              품절 상품입니다.
            </Text>
          </View>
        ) : (
          <>
            <QuantityButton
              quantity={item.quantity}
              setQuantity={(quantity) => {
                if (item.quantity < quantity && increaseQuantity) increaseQuantity()
                if (item.quantity > quantity && decreaseQuantity) decreaseQuantity()
                onChange && onChange({ ...item, quantity })
              }}
              minQuantityNumber={minQuantityNumber}
              countStyle={{ ...FONT.txtMdBold }}
            />
            <Text style={{ ...FONT.H7Bold }}>{item.totalPrice}원</Text>
          </>
        )}
      </View>
    </View>
  )
}

export default CartItem

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    paddingVertical: 8,
  },
  flexbox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  onePrice: {
    textAlign: 'right',
    ...FONT.txtMd,
    marginTop: 4,
    marginBottom: 16,
  },
})
