import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

interface Props {
  soldout?: boolean
  menuName: string
  time: number | null
  price: string
  onPress?: () => void
}

const OrderMenuButton: React.FC<Props> = ({ soldout, menuName, time, price, onPress }) => {
  const [inPress, setIsPress] = React.useState(false)
  return (
    <Pressable
      style={[styles.container, inPress && styles.containerPress]}
      onPress={onPress}
      onPressIn={() => setIsPress(true)}
      onPressOut={() => setIsPress(false)}
    >
      <View>
        <Text style={styles.menuName}>
          {menuName}
          {soldout ? '(품절)' : ''}
        </Text>
        {time !== null && (
          <View style={{ flexDirection: 'row' }}>
            <XEIcon name={'time-o'} size={13} color={'gray.g600'} style={{ marginTop: 1, marginRight: 1 }} />
            <Text style={styles.time}>예상시간 {time}분</Text>
          </View>
        )}
      </View>
      <Text style={styles.price}>{price}</Text>
    </Pressable>
  )
}

export default OrderMenuButton

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: COLOR.gray.g300,
    padding: 8,
    minHeight: 100,
  },
  containerPress: { borderColor: COLOR.gray.g400 },
  menuName: {
    ...FONT.txtMdBold,
  },
  price: {
    textAlign: 'right',
    ...FONT.txtMdMedium,
  },
  time: {
    ...FONT.txtXs,
    color: COLOR.gray.g600,
  },
})
