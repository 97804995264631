import type { TableorderOrder, TableorderOrderStatus } from '@rocket/types'
import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import moment from 'moment'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { styleTableCard } from './BoardTableCard'

export interface TableCardProps {
  state?: string
  name: string
  capacity: string
  order?: TableorderOrder[]
  orderDate?: Date
  orderStatus?: TableorderOrderStatus
}

interface Props {
  item: TableCardProps
  width?: number
  height?: number
  minWidth?: number
  minHeight?: number
  onPress?: () => void
}

const TableCard = ({ item, width, height, minWidth, minHeight, onPress }: Props) => {
  const { t } = useTranslation()
  const orderStatusText = React.useMemo(() => {
    console.log('item.orderStatus', item.orderStatus)
    if (item.orderStatus === 'WAITING_PAYMENT' || item.orderStatus === 'DONE') return '주문'
    else if (item.orderStatus === 'COOKING') return '조리'
    else if (item.orderStatus === 'CANCELED') return '취소'
    else if (item.orderStatus === 'SERVING_DONE') return '완료'
    else return ''
  }, [item.orderStatus])

  return item.name ? (
    <Pressable
      style={[
        styleTableCard.cardContainer,
        styles.container,
        { width },
        { height },
        { minWidth },
        { minHeight },
        {
          borderColor: item.state === 'use' ? COLOR.sub.darkPurple : COLOR.mono.paleWhite,
        },
      ]}
      onPress={onPress}
    >
      <View>
        <Text style={styleTableCard.infoText}>
          {item.capacity}인석
          {item.state === 'use' &&
            item.orderDate !== undefined &&
            `· ${t('format.date.T', {
              date: new Date(item.orderDate),
            })}(${moment().diff(moment(item.orderDate), 'minutes')}분)`}
        </Text>
        <Text
          style={[
            styleTableCard.nameText,
            {
              color: item.state === 'use' ? COLOR.mono.black : COLOR.mono.gray,
            },
          ]}
        >
          {item.name}
        </Text>
      </View>
      <View>
        {item.state === 'use' && item.order && item.order.length > 0 && (
          <View style={styles.orderPrice}>
            {item.order.length > 1 && (
              <View style={styles.iconAddOrder}>
                <XEIcon name="plus-min" color="mono.white" size={16} style={{ marginTop: -1 }} />
              </View>
            )}
            <Text style={styles.priceText}>{item.order.reduce((value, cur) => value + cur.price, 0)}</Text>
          </View>
        )}
        <View style={styles.gaugeContainer}>
          <View style={styles.gaugeBody}>
            <View
              style={[
                styles.gaugeStyle,
                item.orderStatus === 'WAITING_PAYMENT' && styles.stateReceipt,
                item.orderStatus === 'COOKING' && styles.stateCooking,
                item.orderStatus === 'SERVING_DONE' && styles.stateCompletion,
                item.state !== 'use' && styles.stateTableBlank,
              ]}
            />
          </View>
          <Text style={styles.stateText}>{orderStatusText}</Text>
        </View>
      </View>
    </Pressable>
  ) : (
    <View></View>
  )
}

export default TableCard

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
  },
  orderPrice: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  gaugeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  gaugeBody: {
    flex: 3,
    height: 6,
    borderRadius: 8,
    backgroundColor: COLOR.mono.whiteGray,
  },
  gaugeStyle: {
    height: 6,
    borderRadius: 8,
  },
  stateReceipt: {
    width: '0%',
  },
  stateCooking: {
    width: '50%',
    backgroundColor: COLOR.main.yellow,
  },
  stateCompletion: {
    width: '100%',
    backgroundColor: COLOR.main.turquoise,
  },
  stateTableBlank: {
    backgroundColor: COLOR.mono.paleWhite,
  },
  iconAddOrder: {
    width: 16,
    height: 16,
    backgroundColor: COLOR.main.red,
    borderRadius: 16,
    marginRight: 4,
  },
  priceText: {
    ...FONT.H7Bold,
  },
  stateText: {
    ...FONT.txt2Xs,
    color: COLOR.mono.darkGray,
    paddingLeft: 8,
  },
})
