import * as React from 'react'
import type { ViewProps } from './types'
import type { ProjectPositionCreation } from '@rocket/types'

export interface WorkmodelCreateContext extends Omit<ViewProps, 'typeCode'> {
  onChangePosition: (
    position: Omit<ProjectPositionCreation, 'projectId'>,
  ) => void
}
export const Context = React.createContext<WorkmodelCreateContext | undefined>(
  undefined,
)
