import React, { useRef, useState } from 'react'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { View } from '@rocket-mono/foundations'
import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon, Text } from '@rocket/atoms'
import { TextField } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet } from 'react-native'
import { ErrorMessage } from '../components'

const MemberInviteEmail = ({ onPressAdd }: { onPressAdd?: (name: string, email: string) => void }) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)
  const inputNameRef = useRef<any>(null)
  const inputEmailRef = useRef<any>(null)
  const [errorMailFiled, setErrorMailFiled] = React.useState<string>('')
  const [validationEmail, setValidationEmail] = React.useState<boolean>(false)

  const [inputNameValue, setInputNameValue] = React.useState<string>('')
  const [inputEmailValue, setInputEmailValue] = React.useState<string>('')

  const emailCheck = (email: string) => {
    const regEmail = new RegExp(/^([0-9a-zA-Z_\\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/)
    regEmail.exec(email) ? setValidationEmail(true) : setValidationEmail(false)
  }

  const allChecked = () => {
    if (!inputEmailValue) {
      setErrorMailFiled(t('emailinvitemember.error.email'))
    } else {
      if (validationEmail) {
        setErrorMailFiled('')
        onPressAdd && onPressAdd(inputNameValue, inputEmailValue)
        setInputNameValue('')
        setInputEmailValue('')
        if (inputNameRef.current) {
          inputNameRef.current.innerText = ''
        }
        if (inputEmailRef.current) {
          inputEmailRef.current.innerText = ''
        }
        inputNameRef.current?.focus()
      } else {
        setErrorMailFiled(t('emailinvitemember.error.emailvalid'))
      }
    }
  }

  const handleChangeMailChange = React.useCallback((value: string) => {
    emailCheck(value)
    setInputEmailValue(value)
  }, [])

  return (
    <View style={styles.container}>
      <Text style={styles.sectionTitle}>{t('workinvite.invite.formemail.label')}</Text>
      <View>
        <View>
          <TextField
            inputRef={inputNameRef}
            value={inputNameValue}
            autoFocus
            placeholder={t('workinvite.invite.formemail.placeholder.name')}
            // borderType="solid"
            // containerStyle={styles.inputStyle}
            onChangeValue={(value) => setInputNameValue(value)}
            onSubmitEditing={() => inputEmailRef.current?.focus()}
            style={{ width: '100%' }}
            containerBackgroundColor="mono.white"
          />
        </View>
        <View style={styles.formStyle}>
          <TextField
            inputRef={inputEmailRef}
            value={inputEmailValue}
            onChangeValue={handleChangeMailChange}
            onSubmitEditing={() => allChecked()}
            placeholder={t('workinvite.invite.formemail.placeholder.email')}
            style={{ width: '100%' }}
            containerBackgroundColor="mono.white"
            // errorMessage={errorMailFiled}
            // borderType="solid"
            // containerStyle={styles.inputStyle}
          />
          {errorMailFiled ? <ErrorMessage message={errorMailFiled} /> : <></>}
          <Pressable
            onPress={() => allChecked()}
            onHoverIn={() => setIsHover(true)}
            onHoverOut={() => setIsHover(false)}
            style={[styles.buttonArea, isHover && { opacity: 0.7 }]}
          >
            <FAIcon iconName={faPlus} color={'gray.g700'} />
          </Pressable>
        </View>
      </View>
      <Text style={styles.refText}>{t('workinvite.invite.formemail.forminfo')}</Text>
    </View>
  )
}

export default MemberInviteEmail

export const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  sectionTitle: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g600,
    marginBottom: 8,
  },
  inputStyle: {
    borderRadius: 4,
    borderColor: COLOR.gray.g200,
  },
  formStyle: {
    position: 'relative',
    marginTop: 4,
  },
  buttonArea: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    width: 32,
    height: 32,
    top: 2,
    right: 4,
  },
  refText: {
    marginTop: 4,
    ...FONT.txt2XsMedium,
    color: COLOR.gray.g500,
  },
})
