import { Checkbox } from '@rocket-atoms/checkbox'
import { Icon } from '@rocket-atoms/icon'
import { Button } from '@rocket-mono/foundations'
import { COLOR, FONT, useForm, useMobileView } from '@rocket-mono/libs'
import { useCurrentUser } from '@rocket-mono/providers'
import { Text } from '@rocket/atoms'
import { useToast } from '@rui/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, ScrollView, StyleSheet, TextInput, View } from 'react-native'
import WorkmodelPreviewTableorder from '../../WorkmodelPreview/WorkmodelPreviewTableorder'
import Container from '../container'
import { Header } from '../header'
import LayoutView from '../layout'
import { SectionLabel } from '../sectionLabel'
import type { CreateWorkModelTableorderProps } from '../types'

export type TabloeOrderFormType = {
  businessName?: string
  businessRegistrationNumber?: string
  contactNumber?: string
  responsibilityContactNumber?: string
  representativeName?: string
  representativeContactNumber?: string
  representativeBirthDate?: string

  bankCode?: string
  bankAccountNumber?: string
  bankAccountName?: string
}
export type TabloeOrderFormValidationProps = {}

const CreateWorkModelTableorder: React.FC<CreateWorkModelTableorderProps> = ({
  data,
  titleError,
  step,
  keyboardHeight,
  setStep,
  createShop,
  onPressNext,
  onPressBack,
  onChangeTitle,
  onPressCreate,
}) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  const { show: showToastMessage } = useToast()
  const { title } = data

  const stepper = [
    { step: 1, title: t('workmodal.top.step1'), checked: false },
    { step: 2, title: t('workmodal.top.step2'), checked: false },
    { step: 3, title: t('workmodal.top.step3'), checked: false },
  ]

  const [term1, setTerm1] = React.useState(false)
  const [term2, setTerm2] = React.useState(false)

  const mobileView = useMobileView(1000)

  const { values, handleChange, handleSubmit } = useForm<TabloeOrderFormType, TabloeOrderFormValidationProps>({
    initialValues: {
      businessName: '',
      bankAccountName: '',
      bankAccountNumber: '',
      bankCode: '',
      businessRegistrationNumber: '',
      representativeBirthDate: '',
      representativeContactNumber: '',
      representativeName: '',
      responsibilityContactNumber: '',
    },
    onSubmit: (values: TabloeOrderFormType) => {
      console.log('TabloeOrderFormType', { ...values, name: title })
      return onPressCreate().then((project) =>
        createShop({
          ...values,
          userId: currentUser.id,
          name: title,
          projectId: project.id,
        })
          .then(() => {
            showToastMessage({ title: '생성되었습니다', type: 'Success', position: 'TOP_CENTER' })
          })
          .then(() => project.id),
      )
    },
    validate: ({}: TabloeOrderFormType) => {
      const errors: TabloeOrderFormValidationProps = {}
      return errors
    },
  })

  const isValid = React.useMemo(() => {
    const step1 = step === 1 && title.length > 0
    const step2 =
      step === 2 &&
      values.representativeName &&
      values.representativeBirthDate &&
      values.representativeContactNumber &&
      values.businessRegistrationNumber

    const step3 = step === 3 && term1 && term2 && values.bankCode && values.bankAccountName && values.bankAccountNumber

    return step1 || step2 || step3 ? true : false
  }, [step, term1, term2, title, values])

  return (
    <Container
      isValid={isValid}
      step={step}
      submitStep={3}
      onPressNext={onPressNext}
      onPressBack={onPressBack}
      onPressCreate={handleSubmit}
    >
      <Header
        title={t('workmodal.top.title')}
        info={t('workmodal.basic.settingchangedesc')}
        stepper={stepper}
        step={step}
        setStep={setStep}
      />
      <LayoutView
        isValid={isValid}
        step={step}
        submitStep={3}
        scrollEnabled={true}
        keyboardHeight={keyboardHeight}
        onPressNext={onPressNext}
        onPressBack={onPressBack}
        onPressCreate={handleSubmit}
        previewElement={<WorkmodelPreviewTableorder mobileView={mobileView} title={title} step={step} />}
      >
        {step === 1 ? (
          <ScrollView style={{ paddingHorizontal: 4, paddingBottom: 4 }}>
            <View>
              <SectionLabel required text={t('workmodal.top.name')} />
              <View>
                <TextInput
                  value={title}
                  onChangeText={onChangeTitle}
                  style={[styles.inputStyle, titleError && { borderColor: COLOR.status.busy }]}
                  placeholder={t('workmodal.top.nameplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
                {titleError && (
                  <View style={{ flexDirection: 'row' }}>
                    <Icon name="error" color="status.busy" />
                    <Text fontColor="status.busy">{t('workmodal.top.nameplaceholder')}</Text>
                  </View>
                )}
              </View>
            </View>
          </ScrollView>
        ) : step === 2 ? (
          <ScrollView style={{ paddingHorizontal: 4, paddingBottom: 4 }}>
            <View>
              <SectionLabel required text={t('workmodal.top.representativename')} />
              <View>
                <TextInput
                  value={values.representativeName}
                  onChangeText={(text) => handleChange('representativeName', text)}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.top.representativenameplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
              </View>
            </View>

            <View style={[{ marginTop: 24 }, mobileView && { marginTop: 16 }]}>
              <SectionLabel required text={t('workmodal.top.representativebirth')} />
              <View>
                <TextInput
                  value={values.representativeBirthDate}
                  onChangeText={(text) => handleChange('representativeBirthDate', text)}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.top.representativebirthplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
              </View>
            </View>

            <View style={[{ marginTop: 24 }, mobileView && { marginTop: 16 }]}>
              <SectionLabel required text={t('workmodal.top.companyregistrationnumber')} />
              <View>
                <TextInput
                  value={values.businessRegistrationNumber}
                  onChangeText={(text) => handleChange('businessRegistrationNumber', text)}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.top.companyregistrationnumberplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
              </View>
            </View>

            <View style={[{ marginTop: 24 }, mobileView && { marginTop: 16 }]}>
              <SectionLabel required text={t('workmodal.top.shopcontact')} />
              <View>
                <TextInput
                  value={values.representativeContactNumber}
                  onChangeText={(text) => handleChange('representativeContactNumber', text)}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.top.shopcontactplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
              </View>
            </View>

            <View style={[{ marginTop: 24 }, mobileView && { marginTop: 16 }]}>
              <SectionLabel text={t('workmodal.top.contactperson')} />
              <View>
                <TextInput
                  value={values.responsibilityContactNumber}
                  onChangeText={(text) => handleChange('responsibilityContactNumber', text)}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.top.contactpersonplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
              </View>
            </View>
          </ScrollView>
        ) : step === 3 ? (
          <ScrollView style={{ paddingHorizontal: 4, paddingBottom: 4 }}>
            <View>
              <View style={styles.sectionLabel}>
                <View style={styles.labelBlock}></View>
                <Text style={styles.labelName}>{t('workmodal.top.account')}</Text>
              </View>
              <View style={styles.inputLabel}>
                <Text fontName="txtSmMedium">{t('workmodal.top.bank')}</Text>
              </View>
              <View>
                <TextInput
                  value={values.bankCode}
                  onChangeText={(text) => handleChange('bankCode', text)}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.top.bankchoose')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
              </View>
              <View style={styles.inputLabel}>
                <Text fontName="txtSmMedium">{t('workmodal.top.accountnumber')}</Text>
              </View>
              <View>
                <TextInput
                  value={values.bankAccountNumber}
                  onChangeText={(text) => handleChange('bankAccountNumber', text)}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.top.accountnumbeplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
              </View>
              <View style={styles.inputLabel}>
                <Text fontName="txtSmMedium">{t('workmodal.top.accountholdername')}</Text>
              </View>
              <View>
                <TextInput
                  value={values.bankAccountName}
                  onChangeText={(text) => handleChange('bankAccountName', text)}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.top.accountholdernameplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                />
              </View>
            </View>

            <View style={[{ marginTop: 24 }, mobileView && { marginTop: 16 }]}>
              <View style={styles.sectionLabel}>
                <View style={styles.labelBlock}></View>
                <Text style={styles.labelName}>{t('workmodal.top.userterms')}</Text>
              </View>
              <View>
                <View
                  style={[
                    styles.inputStyle,
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    },
                  ]}
                >
                  <Text>개인정보 취급방침</Text>
                  <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                    <Button
                      backgroundColor="mono.white"
                      borderColor="gray.g300"
                      size="small"
                      style={{ paddingHorizontal: 12 }}
                    >
                      <Text fontColor="gray.g700">약관 보기</Text>
                    </Button>
                    <View
                      style={{
                        width: 1,
                        height: 30,
                        backgroundColor: COLOR.gray.g100,
                        marginHorizontal: 12,
                      }}
                    />
                    <Pressable style={{ flexDirection: 'row' }} onPress={() => setTerm1((is) => !is)}>
                      <Checkbox checked={term1} type="DEFAULT" />
                      <Text style={{ marginLeft: 8 }}>동의</Text>
                    </Pressable>
                  </View>
                </View>
                <View
                  style={[
                    styles.inputStyle,
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: 4,
                    },
                  ]}
                >
                  <Text>개인정보 취급방침</Text>
                  <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                    <Button
                      backgroundColor="mono.white"
                      borderColor="gray.g300"
                      size="small"
                      style={{ paddingHorizontal: 12 }}
                    >
                      <Text fontColor="gray.g700">약관 보기</Text>
                    </Button>
                    <View
                      style={{
                        width: 1,
                        height: 30,
                        backgroundColor: COLOR.gray.g100,
                        marginHorizontal: 12,
                      }}
                    />

                    <Pressable style={{ flexDirection: 'row' }} onPress={() => setTerm2((is) => !is)}>
                      <Checkbox checked={term2} type="DEFAULT" />
                      <Text style={{ marginLeft: 8 }}>동의</Text>
                    </Pressable>
                  </View>
                </View>
              </View>
              <View
                style={[
                  styles.inputStyle,
                  {
                    borderWidth: 0,
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  },
                ]}
              >
                <Pressable
                  style={{ flexDirection: 'row' }}
                  onPress={() => {
                    if (term1 && term2) {
                      setTerm1(false)
                      setTerm2(false)
                    } else {
                      setTerm1(true)
                      setTerm2(true)
                    }
                  }}
                >
                  <Checkbox checked={term1 && term2} type="DEFAULT" />
                  <Text style={{ marginLeft: 8 }}>사용자 약관 전체 동의</Text>
                </Pressable>
              </View>
            </View>
          </ScrollView>
        ) : (
          <></>
        )}
      </LayoutView>
    </Container>
  )
}

export default CreateWorkModelTableorder

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentBox: {
    flex: 1,
    position: 'relative',
    justifyContent: 'flex-start',
  },
  sectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  labelBlock: {
    width: 3,
    height: 14,
    marginRight: 6,
    backgroundColor: COLOR.mono.black,
  },
  labelName: {
    ...FONT.txtMdMedium,
  },
  inputLabel: {
    marginTop: 12,
  },
  inputStyle: {
    ...FONT.txtMd,
    width: '100%',
    height: 44,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 8,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
      native: {},
    }),
  },
  flowLine: {
    position: 'absolute',
    top: '50%',
    left: 6,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g200,
  },
  flowGroup: {
    flexDirection: 'row',
  },
  groupLabel: {
    width: 16,
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  groupLabelMobile: {
    marginLeft: -30,
    width: 44,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  groupWrap: {
    flex: 1,
    marginLeft: 28,
    borderColor: COLOR.gray.g100,
  },
  labelRing: {
    width: 14,
    height: 14,
    borderRadius: 30,
    borderWidth: 3,
    backgroundColor: COLOR.mono.white,
    marginRight: 6,
  },
  labelRingMobile: {
    marginRight: 0,
  },
})
