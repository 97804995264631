import { ModalBase, ModalProps } from './Modal'
import { ModalBody, ModalBodyProps } from './Modal.Body'
import { ModalFooter, ModalFooterProps } from './Modal.Footer'
import { ModalHeader, ModalHeaderProps } from './Modal.Header'
import { Provider } from './provider'
import useModal from './useModal'

const ModalBottomSheetType = Object.assign(ModalBase, {
  Provider: Provider,
  Body: ModalBody,
  Footer: ModalFooter,
  Header: ModalHeader,
})

export { ModalBottomSheetType, useModal }
export type { ModalProps, ModalBodyProps, ModalFooterProps, ModalHeaderProps }
