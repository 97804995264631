import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import { COLOR, FONT, getRealColor, IColors } from '@rui/foundations'

interface Props {
  label: string
  labelColor: IColors
}

const MenuLabel: React.FC<Props> = ({ label, labelColor }) => {
  return (
    <View style={[styles.labelContainer, { backgroundColor: getRealColor(labelColor) }]}>
      <Text style={styles.labelText}>{label}</Text>
    </View>
  )
}

export default MenuLabel

const styles = StyleSheet.create({
  labelContainer: {
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 2,
    marginRight: 4,
    backgroundColor: COLOR.sub.lightBlue,
  },
  labelText: {
    ...FONT.txt2Xs,
    color: COLOR.mono.white,
  },
})
