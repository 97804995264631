import * as React from 'react'

import { ChatsItem } from './ChatsItem'
import type { RenderItemType } from './types'

interface Props {
  list: RenderItemType[]
  onPressItem?: (itemKey: string) => void
}

export const ChatsUnread: React.FC<Props> = ({ list, onPressItem }) => {
  return (
    <>
      {list.map((item) => {
        return <ChatsItem key={`unread-message-${item.key}`} item={item} onPress={onPressItem} />
      })}
    </>
  )
}
