import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { StyleSheet } from 'react-native'

import { useModalDialog } from '@rocket-mono/providers'
import { ProjectMemberAuth } from '@rocket/types'
import { useToast } from '@rui/atoms'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useWorkBoard, useWorkMember } from '../providers'
import WorkMembersHeader from './components/Header'
import WorkMembersTable, { MemberInfoType } from './components/Table'
import type { ViewProps } from './types'

const ScreenView: React.FC<ViewProps> = ({ projectId, secureCdn }) => {
  const {
    isLoading,
    currentProject,
    projectMemberList,
    currentProjectMember,
    showInviteModal,
    inviteMember,
    inviteUpdateMember,
    withdrawalMember,
    updateMemberName,
    updateMemberAuth,
    updateMemberEtc,
    archiveMember,
    deleteMember,
    ownershipTransfer,
  } = useWorkMember()
  const { t } = useTranslation()

  const { show: showToastMessage } = useToast()
  const { boardList, inviteBoardMember, leaveBoardMember } = useWorkBoard()
  const [tab, setTab] = React.useState('member')
  const [searchKeyword, setSearchKeyword] = React.useState('')

  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const handleUpdateMemberAuth = React.useCallback(
    (memberId: string, memberAuth: ProjectMemberAuth) => {
      updateMemberAuth(memberId, memberAuth)
    },
    [projectMemberList, updateMemberAuth],
  )

  const handlePressMore = React.useCallback(
    (memberId: string, state: string, userId: string) => {
      if (state === 'member') {
        updateMemberAuth(memberId, 'MEMBER')
      } else if (state === 'manager') {
        updateMemberAuth(memberId, 'MANAGER')
      } else if (state === 'leave') {
        const title = t('workmember.dialog.leave.title')
        const list = [
          {
            name: t('workmember.dialog.leave.button'),
            action: () => {
              archiveMember(memberId).then(hideDialogMessage)
            },
          },
        ]

        showDialogMessage({
          type: 'BOTTOM',
          title,
          list,
          cancelText: t('workmember.dialog.cancel'),
          onCancel: hideDialogMessage,
        })
      } else if (state === 'exit') {
        const title = t('workmember.dialog.exit.title') //'해당 워크에서 나가기를 요청하시겠습니까?'
        const list = [
          {
            name: t('workmember.dialog.exit.button'), //'삭제',
            action: () => {
              withdrawalMember(userId).then(hideDialogMessage)
            },
          },
        ]

        showDialogMessage({
          type: 'BOTTOM',
          title,
          list,
          cancelText: t('workmember.dialog.cancel'),
          onCancel: hideDialogMessage,
        })
      } else if (state === 'chat') {
        if (userId) {
          const action = 'openDirectChat'
          const payload = { projectId, userId }
          window.postMessage(
            {
              action,
              payload,
            },
            '*',
          )
        }
      } else if (state === 'invite') {
        const title = t('workmember.dialog.invite.title') // '재초대 하시겠습니까??'
        const list = [
          {
            name: t('workmember.dialog.invite.button'), //'재초대',
            action: () => {
              hideDialogMessage()
              inviteMember(memberId)
            },
          },
        ]

        showDialogMessage({
          type: 'BOTTOM',
          title,
          list,
          cancelText: t('workmember.dialog.cancel'),
          onCancel: hideDialogMessage,
        })
      } else if (state === 'delete') {
        const title = t('workmember.dialog.delete.title') //'삭제 하시겠습니까?'
        const list = [
          {
            name: t('workmember.dialog.delete.button'), //'삭제',
            action: () => {
              deleteMember(memberId).then(hideDialogMessage)
            },
          },
        ]

        showDialogMessage({
          type: 'BOTTOM',
          title,
          list,
          cancelText: t('workmember.dialog.cancel'),
          onCancel: hideDialogMessage,
        })
      } else if (state === 'transfer') {
        const title = t('workmember.dialog.transfer.title') //'소유자권한을 이관하시겠습니까?'
        const list = [
          {
            name: t('workmember.dialog.transfer.button'), //'이관',
            action: () => {
              hideDialogMessage()
              ownershipTransfer(userId).then(() => {
                setTimeout(() => {
                  showDialogMessage({
                    type: 'CENTER',
                    title: t('workmember.dialog.transfer.success.title'), // '소유권이전이 신청되었습니다.',
                    message: t('workmember.dialog.transfer.success.message'), // '대상자가 1주일기간 내에 승인 또는 거절 할 수 있습니다.',
                    list: [
                      {
                        name: t('workmember.dialog.transfer.success.button') /*'확인'*/,
                        action: hideDialogMessage,
                      },
                    ],
                  })
                }, 100)
              })
            },
          },
        ]

        showDialogMessage({
          type: 'BOTTOM',
          title,
          list,
          cancelText: t('workmember.dialog.cancel'),
          onCancel: hideDialogMessage,
        })
      }
    },
    [updateMemberAuth, archiveMember, withdrawalMember, inviteMember, deleteMember, ownershipTransfer],
  )

  const handleInviteCancel = React.useCallback(
    (memberId: string) => {
      const title = t('workmember.dialog.invitecancel.title') //'초대를 취소하시겠습니까?'
      const list = [
        {
          name: t('workmember.dialog.invitecancel.button'), //'초대취소',
          action: () => {
            archiveMember(memberId).then(hideDialogMessage)
          },
        },
      ]

      showDialogMessage({
        type: 'BOTTOM',
        title,
        list,
        cancelText: t('workmember.dialog.cancel'), //'취소',
        onCancel: hideDialogMessage,
      })
    },
    [archiveMember],
  )

  const handleInviteEdit = React.useCallback(
    (memberId: string, selected: string[]) => {
      const invite = () => {
        return new Promise((resolve) => {
          const projectMember = projectMemberList?.find((o) => o.id === memberId)

          if (boardList && projectMember && !projectMember.isJoined) {
            const inviteList = boardList.filter((o) => selected.includes(o.roomId))

            inviteUpdateMember(
              projectMember.id,
              inviteList.map((o) => o.projectElementId),
            )
          } else if (boardList && projectMemberList) {
            const inviteList = boardList
              .filter((o) => selected.includes(o.roomId))
              .filter(
                (o) =>
                  o.memberList && o.memberList.filter((member) => member.userId === projectMember?.userId).length === 0,
              )
            const deleteList = boardList
              .filter((o) => !selected.includes(o.roomId))
              .filter(
                (o) =>
                  o.memberList && o.memberList.filter((member) => member.userId === projectMember?.userId).length > 0,
              )
            if (projectMember)
              inviteList.map((o) => {
                inviteBoardMember(o.id, projectMember.userId, projectMember.email, projectMember.name)
              })
            deleteList.map((o) => {
              const channelMember = o.memberList?.find((o) => o.userId === projectMember?.userId)
              if (channelMember) leaveBoardMember(o.id, String(channelMember.id))
            })
          }
          return resolve(true)
        })
      }

      const title = t('workmember.dialog.board.title') //'참여 보드를 변경하시겠습니까?'
      const list = [
        {
          name: t('workmember.dialog.board.button'), //'변경',
          action: () => {
            invite()
              .then(() => {
                showToastMessage({
                  type: 'Success',
                  title: t('toast.confirmed'),
                  // animated: true,
                  position: 'BOTTOM_CENTER',
                })
              })
              .then(hideDialogMessage)
          },
        },
      ]

      showDialogMessage({
        type: 'BOTTOM',
        title,
        list,
        cancelText: t('workmember.dialog.cancel'),
        onCancel: hideDialogMessage,
      })
    },
    [boardList, projectMemberList, inviteBoardMember, leaveBoardMember],
  )

  const tableList = React.useMemo<MemberInfoType[]>(() => {
    const isManager = ['OWNER', 'MANAGER'].includes(currentProjectMember?.auth || '')

    if (projectMemberList === undefined) return []
    return projectMemberList
      .filter((o) => o.isArchive !== (tab === 'member'))
      .filter((o) => o.name.indexOf(searchKeyword) >= 0 || o.email.indexOf(searchKeyword) >= 0)
      .map((item) => {
        console.log('item.', item.userState, item)

        return item
      })
      .map((item) => {
        const memberEtc = !!item.memberEtc ? JSON.parse(item.memberEtc) : {}
        const { team, position, role } = memberEtc

        const setting =
          item.userStateDate &&
          item.userStateDate.offStartDate &&
          item.userStateDate.offEndDate &&
          item.userState === 'off'
            ? `${t('format.date.L', {
                date: item.userStateDate.offStartDate,
              })} ~ ${t('format.date.L', {
                date: item.userStateDate.offEndDate,
              })}`
            : item.userStateDate &&
              item.userStateDate.busyStartDate &&
              item.userStateDate.busyEndDate &&
              item.userState === 'busy'
            ? `${t('format.date.T', {
                date: item.userStateDate.busyEndDate,
              })}`
            : ''
        const state = {
          title: item.userState ? (item.isJoined ? item.userState : '') : undefined,
          setting,
        }
        return {
          id: item.id,
          user: {
            id: item.userId,
            onlyRead: !isManager,
            isMe: currentProjectMember && item.userId === currentProjectMember.userId ? true : false,
            isNew: item.inviteDate ? moment().diff(item.inviteDate, 'hours') < 24 : false,
            isDelete: item.isJoined && item.userId === 'null' ? true : false,
            isJoined: item.isJoined,
            name: item.name,
            profile: `${secureCdn}/profile/${item.email}`,
            email: item.email,
            accessLevel: item.auth,
            ownershipTransfer: item.ownershipTransfer || false,
            state,
          },
          add: { team, position, role },
          static: {
            onlyRead: !isManager,
            board:
              boardList !== undefined
                ? boardList
                    .filter((board) => {
                      if (isManager) return true
                      else {
                        return board.memberList && board.memberList.find((o) => o.email === item.email) ? true : false
                      }
                    })
                    .map((board) => ({
                      id: Number(board.id),
                      title: board.roomName,
                      code: board.roomId,
                      isArchive: board.closed,
                      invited: (!item.isJoined && item.inviteRoomIds?.includes(board.projectElementId)) || false,
                      selected: board.memberList && board.memberList.find((o) => o.email === item.email) ? true : false,
                    }))
                : [],
            assign: 0,
          },
        }
      })
  }, [tab, searchKeyword, currentProject, projectMemberList, boardList, currentProjectMember])

  return (
    <View style={styles.container}>
      <WorkMembersHeader
        showTab={['OWNER', 'MANAGER'].includes(currentProjectMember?.auth || '')}
        tab={tab}
        onTab={setTab}
        searchText={searchKeyword}
        setSearchText={setSearchKeyword}
        memberCount={projectMemberList?.filter(({ isArchive }) => !isArchive).length || 0}
        onPressInviteMember={showInviteModal}
      />
      <View style={{ flex: 1 }}>
        {tableList && (
          <WorkMembersTable
            isLoading={isLoading}
            tab={tab}
            searchKeyword={searchKeyword}
            isOwner={currentProjectMember?.auth === 'OWNER'}
            isManager={['OWNER', 'MANAGER'].includes(currentProjectMember?.auth || '')}
            list={tableList}
            updateMemberName={updateMemberName}
            updateMemberAuth={handleUpdateMemberAuth}
            updateMemberEtc={updateMemberEtc}
            inviteCancel={handleInviteCancel}
            inviteEdit={handleInviteEdit}
            onPressMore={handlePressMore}
            onPressInviteMember={showInviteModal}
          />
        )}
      </View>
    </View>
  )
}

export default ScreenView

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
