import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { StyleSheet, ViewStyle } from 'react-native'

export interface ModalBodyProps {
  style?: ViewStyle
  children?: JSX.Element[] | JSX.Element
}

export const ModalBody: React.FC<ModalBodyProps> = ({ style, children }) => {
  return <View style={[styles.contentsView, style]}>{children}</View>
}

ModalBody.displayName = 'ModalBottomSheetType.Body'

const styles = StyleSheet.create({
  contentsView: {
    flex: 1,
    width: '100%',
    marginHorizontal: 20,
  },
})
