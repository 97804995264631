import React, { ForwardedRef, forwardRef, memo } from 'react'
import type { SvgProps } from 'react-native-svg'
import { Path, Svg } from 'react-native-svg'

interface Props extends SvgProps {
  borderColor?: string
}

const AllIcon = ({ borderColor = '#B4BEC5', ...props }: Props, ref: ForwardedRef<Svg>) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" ref={ref} {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52941 1.5H2C1.72386 1.5 1.5 1.72386 1.5 2V5.52941C1.5 5.80556 1.72386 6.02941 2 6.02941H5.52941C5.80556 6.02941 6.02941 5.80555 6.02941 5.52941V2C6.02941 1.72386 5.80555 1.5 5.52941 1.5ZM2 0C0.895431 0 0 0.895429 0 2V5.52941C0 6.63398 0.895429 7.52941 2 7.52941H5.52941C6.63398 7.52941 7.52941 6.63398 7.52941 5.52941V2C7.52941 0.895431 6.63398 0 5.52941 0H2Z"
        fill={borderColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0001 1.5H10.4707C10.1946 1.5 9.9707 1.72386 9.9707 2V5.52941C9.9707 5.80556 10.1946 6.02941 10.4707 6.02941H14.0001C14.2763 6.02941 14.5001 5.80555 14.5001 5.52941V2C14.5001 1.72386 14.2763 1.5 14.0001 1.5ZM10.4707 0C9.36613 0 8.4707 0.895429 8.4707 2V5.52941C8.4707 6.63398 9.36613 7.52941 10.4707 7.52941H14.0001C15.1047 7.52941 16.0001 6.63398 16.0001 5.52941V2C16.0001 0.895431 15.1047 0 14.0001 0H10.4707Z"
        fill={borderColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.52941 9.9707H2C1.72386 9.9707 1.5 10.1946 1.5 10.4707V14.0001C1.5 14.2763 1.72386 14.5001 2 14.5001H5.52941C5.80556 14.5001 6.02941 14.2763 6.02941 14.0001V10.4707C6.02941 10.1946 5.80555 9.9707 5.52941 9.9707ZM2 8.4707C0.895431 8.4707 0 9.36613 0 10.4707V14.0001C0 15.1047 0.895429 16.0001 2 16.0001H5.52941C6.63398 16.0001 7.52941 15.1047 7.52941 14.0001V10.4707C7.52941 9.36613 6.63398 8.4707 5.52941 8.4707H2Z"
        fill={borderColor}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0001 9.9707H10.4707C10.1946 9.9707 9.9707 10.1946 9.9707 10.4707V14.0001C9.9707 14.2763 10.1946 14.5001 10.4707 14.5001H14.0001C14.2763 14.5001 14.5001 14.2763 14.5001 14.0001V10.4707C14.5001 10.1946 14.2763 9.9707 14.0001 9.9707ZM10.4707 8.4707C9.36613 8.4707 8.4707 9.36613 8.4707 10.4707V14.0001C8.4707 15.1047 9.36613 16.0001 10.4707 16.0001H14.0001C15.1047 16.0001 16.0001 15.1047 16.0001 14.0001V10.4707C16.0001 9.36613 15.1047 8.4707 14.0001 8.4707H10.4707Z"
        fill={borderColor}
      />
    </Svg>
  )
}
export default memo(forwardRef(AllIcon))
