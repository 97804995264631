import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

interface Props {
  message: string
}

const ErrorMessage: React.FC<Props> = ({ message }) => {
  return (
    <View style={styles.errText}>
      <XEIcon name={'info'} size={16} color={'status.busy'} />
      <Text style={{ ...FONT.txtXsMedium, color: COLOR.status.busy }}>{message}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  errText: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 4,
  },
})

export default ErrorMessage
