import { COLOR, FONT } from '@rui/foundations'
import React, { useState } from 'react'
import { Pressable, StyleSheet, Text } from 'react-native'

interface Props {
  name: string
  disabled?: boolean
  onPress: () => void
}

const OrderConfirmButton: React.FC<Props> = ({ name, onPress, disabled = false }) => {
  const [isPress, setIsPress] = useState(false)
  return (
    <Pressable
      style={[styles.wrap, isPress && styles.press, disabled && styles.disableButton]}
      disabled={disabled}
      onPress={onPress}
      onPressIn={() => setIsPress(true)}
      onPressOut={() => setIsPress(false)}
    >
      <Text style={styles.name}>{name}</Text>
    </Pressable>
  )
}

export default OrderConfirmButton

const styles = StyleSheet.create({
  wrap: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 84,
    backgroundColor: COLOR.main.blue,
    borderRadius: 4,
  },
  press: {
    opacity: 0.85,
  },
  name: {
    ...FONT.H6Bold,
    color: COLOR.mono.white,
  },
  disableButton: {
    backgroundColor: COLOR.mono.lightGray,
  },
})
