import * as React from 'react'
import { StyleSheet } from 'react-native'

import { isEqual } from 'lodash'

import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'

import { useModalDialog } from '@rocket-mono/providers'
import { useToast } from '@rui/atoms'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { Button } from '../Button'
import { useTableorderCatalog, useTableorderMenu } from '../providers'
import { TableorderMenuCategoryItem } from './components'
import type { ViewProps } from './types'

const ScreenView: React.FC<ViewProps> = ({
  blocked,
  onProceedBlock,
  onResetBlock,
  onChangeBlock,
  onPressLabel,
  onPressOption,
}) => {
  const [isReady, setIsReady] = React.useState(false)
  const { menuList, categoryList, changeCategoryList, createCategory, updateCategory, deleteCategory } =
    useTableorderMenu()
  const { currentCatalog, publishCatalog } = useTableorderCatalog()

  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const compareCategory = React.useMemo(() => {
    if (currentCatalog === undefined || categoryList === undefined) return undefined
    if (currentCatalog) {
      const catalogCategory = currentCatalog.categories.map(({ originId: id, name, sequenceNumber }) => ({
        id,
        name,
        sequenceNumber,
      }))
      const category = categoryList.map(({ id, name, sequenceNumber }) => ({
        id,
        name,
        sequenceNumber,
      }))
      return isEqual(catalogCategory, category)
    }
    return false
  }, [currentCatalog, categoryList])

  const compareMenu = React.useMemo(() => {
    if (currentCatalog === undefined || menuList === undefined) return undefined
    if (currentCatalog) {
      const catalogMenu = currentCatalog.categories
        .flatMap(({ menus }) => menus)
        .map(
          ({
            originId: id,
            name,
            price,
            sequenceNumber,
            description,
            isDisplayed,
            isSoldout,
            originLabelIds: labelIds,
            originOptionGroupIds: optionGroupIds,
            images,
            ...etc
          }) => {
            console.log('etc1', etc)

            return {
              id,
              name,
              price,
              sequenceNumber,
              description,
              isDisplayed,
              isSoldout,
              labelIds,
              optionGroupIds,
              images: images.map(({ originId }) => originId),
            }
          },
        )
      const menu = menuList.map(
        ({
          id,
          name,
          price,
          sequenceNumber,
          description,
          isDisplayed,
          isSoldout,
          labelIds,
          optionGroupIds,
          images,
          ...etc
        }) => {
          console.log('etc2', etc)

          return {
            id,
            name,
            price,
            sequenceNumber,
            description,
            isDisplayed,
            isSoldout,
            labelIds,
            optionGroupIds,
            images: images.map(({ id }) => id),
          }
        },
      )
      return isEqual(menu, catalogMenu)
    }
    return false
  }, [currentCatalog, menuList])

  const isPublishCatalog = React.useMemo(() => {
    if (currentCatalog === undefined || compareCategory === undefined || compareMenu === undefined) return false
    if (currentCatalog === null) return true

    if (compareCategory && compareMenu) {
      return false
    }

    return true
  }, [currentCatalog, compareCategory, compareMenu])

  const handlePublishCatalog = React.useCallback(() => {
    return publishCatalog()
      .then(() => {
        showToastMessage({
          type: 'Success',
          title: '반영되었습니다.',
          position: 'TOP_RIGHT',
        })
      })
      .catch(() => {
        showToastMessage({
          type: 'Danger',
          title: '반영을 실패하였습니다.',
          position: 'TOP_RIGHT',
        })
      })
  }, [publishCatalog])

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      if (result.destination) changeCategoryList(result.source.index, result.destination.index)
    },
    [categoryList],
  )

  const handleDeleteCategory = React.useCallback((id: string): Promise<void> => {
    return new Promise((resolve) => {
      const list = [
        {
          name: '삭제',
          action: () => {
            deleteCategory(id)
            resolve()
          },
        },
      ]
      showDialogMessage({
        list,
        title: '카테고리를 삭제하시겠습니까?',
        message: '삭제하시면 포함 메뉴도 같이 삭제됩니다.',
        cancelText: '취소',
        onCancel: () => {
          resolve()
        },
      })
    })
  }, [])

  React.useEffect(() => {
    setIsReady(true)
  }, [])

  React.useEffect(() => {
    onChangeBlock && onChangeBlock(isPublishCatalog)
  }, [isPublishCatalog])

  React.useEffect(() => {
    const list = [
      {
        name: '예',
        action: () => {
          handlePublishCatalog().then(() => {
            onProceedBlock && onProceedBlock()
          })
        },
      },
      {
        name: '아니요',
        action: () => {
          onProceedBlock && onProceedBlock()
        },
      },
    ]
    if (blocked)
      showDialogMessage({
        title: '변경사항을 반영하겠습니까?',
        list,
        cancelText: '취소',
        onCancel: () => {
          onResetBlock && onResetBlock()
          hideDialogMessage()
        },
      })
  }, [blocked, handlePublishCatalog])

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={{ flex: 1 }} fontName="textBold" fontColor="mono.black">
          메뉴판 관리
        </Text>
        <View style={{ flexDirection: 'row' }}>
          <Button
            backgroundColor="mono.white"
            color="mono.black"
            borderColor="mono.gray"
            style={{ marginRight: 8, paddingHorizontal: 16 }}
            onPress={createCategory}
          >
            카테고리 추가
          </Button>
          <Button
            backgroundColor="mono.white"
            color="mono.black"
            borderColor="mono.gray"
            style={{ marginRight: 8, paddingHorizontal: 16 }}
            onPress={onPressLabel}
          >
            라벨 관리
          </Button>
          <Button
            backgroundColor="mono.white"
            color="mono.black"
            borderColor="mono.gray"
            style={{ marginRight: 20, paddingHorizontal: 16 }}
            onPress={onPressOption}
          >
            옵션 관리
          </Button>
          <Button disabled={!isPublishCatalog} style={{ paddingHorizontal: 16 }} onPress={handlePublishCatalog}>
            메뉴판 출판
          </Button>
        </View>
      </View>

      <div style={{ overflow: 'auto' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {isReady &&
                  categoryList?.map((item, index) => (
                    <Draggable key={`drag-${index}`} draggableId={`drag-${index}`} index={index}>
                      {(provided) => {
                        return (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <TableorderMenuCategoryItem
                              key={item.id}
                              categoryId={item.id}
                              name={item.name}
                              menuList={menuList?.filter(({ categoryId }) => categoryId === item.id) || []}
                              dragHandleProps={provided.dragHandleProps}
                              // onPressAddMenu={() => {addMenu()}}
                              onPressUpdate={(name: string) => {
                                console.log(name)
                                updateCategory(item.id, { ...item, name })
                              }}
                              onPressDelete={() => handleDeleteCategory(item.id)}
                            />
                          </div>
                        )
                      }}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </View>
  )
}

export default ScreenView

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.mono.paleWhite,
    paddingHorizontal: 20,
  },
  titleContainer: {
    height: 69,
    flexDirection: 'row',
    alignItems: 'center',
  },
  boardContainer: {
    flex: 1,
    margin: 20,
  },
})
