import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'

interface Props {
  iconName?: 'forum-o' | 'user'
  title: string
  count?: number
}

const ChatName: React.FC<Props> = ({ iconName, title, count }) => {
  return (
    <View style={styles.container}>
      {!!iconName && (
        <View style={styles.iconLabel}>
          <XEIcon name={iconName} size={12} color={'gray.g600'} />
        </View>
      )}
      <View style={styles.titleContainer}>
        <Text
          style={[styles.chatTitle, iconName === 'forum-o' && styles.chatTitleThread]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {title}
        </Text>
        <Text style={styles.chatCount}>{count}</Text>
      </View>
    </View>
  )
}

export default ChatName

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconLabel: {
    backgroundColor: COLOR.gray.g100,
    width: 18,
    height: 18,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
  },
  titleContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  chatTitle: {
    ...FONT.txtMdMedium,
  },
  chatTitleThread: {
    ...FONT.txtSmMedium,
  },
  chatCount: {
    marginLeft: 4,
    ...FONT.txtSmMedium,
    color: COLOR.gray.g400,
  },
})
