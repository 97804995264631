import { removeItemAtIndex } from '@rocket-mono/libs'
import { useAstro, useCurrentUser, useModalBackdrop, useWork, useWorkProject } from '@rocket-mono/providers'
import {
  MemberInviteScreenModal,
  MemberStateType,
  MemberInviteItemType as SWMemberInviteItemType,
} from '@rocket-mono/screen-work'
import { Channel } from '@rocket/types'
import { useToast } from '@rui/atoms'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { uuid } from './util'

type MemberInviteItemType = SWMemberInviteItemType & {
  userId: string
  projectId: string
}

interface Props {
  onClose: () => void
}

const MemberInviteScreen: React.FC<Props> = ({ onClose }) => {
  const { t, i18n } = useTranslation()

  const { astro, option } = useAstro()
  const { currentUser } = useCurrentUser()
  const { currentProject, projectMemberList } = useWorkProject()
  const { projectList } = useWork()

  const { show, hide } = useModalBackdrop()
  const { show: showToastMessage } = useToast()

  const [inviteMemberList, setInviteMemberList] = useState<MemberInviteItemType[]>([])

  const [unarchiveList, setChannelList] = useState<Channel[]>()
  const [archiveList, setArchiveList] = useState<Channel[]>()

  useEffect(() => {
    if (currentProject) {
      show('member-invite-modal')
      astro.readChannelList({ type: 'G', isArchive: false, projectId: currentProject.id }).then(setChannelList)
      astro.readChannelList({ type: 'G', isArchive: true, projectId: currentProject.id }).then(setArchiveList)
    }
    return () => {
      hide('member-invite-modal')
    }
  }, [currentProject])

  const activeCount = React.useCallback(
    (project) => {
      const count = inviteMemberList.filter((item) => item.projectId === project.id).length
      return count > 0 ? count : undefined
    },
    [inviteMemberList],
  )

  const inviteWorkList = useMemo(() => {
    if (!projectList) return []
    if (!currentProject) return []
    return projectList
      .filter((project) => currentProject.id !== project.id)
      .filter((project) =>
        project.members.some((member) => {
          const isMe = String(currentUser.id) === member.userId
          return isMe && (member.auth === 'OWNER' || member.auth === 'MANAGER')
        }),
      )
      .map((project) => ({
        id: project.id,
        type: project.type.code,
        name: project.title,
        active: activeCount(project),
        detailWork: project.members.map((member) => {
          let state: MemberStateType = ''
          const { userId, name, email, auth, isJoined } = member
          const isMember = projectMemberList?.some((item) => item.userId === userId)
          const isMe = String(currentUser.id) === userId
          if (isMe) {
            state = '나'
          } else if (isMember) {
            state = '멤버'
          } else if (auth === 'OWNER' || auth === 'MANAGER') {
            state = '관리자'
          } else if (!isJoined) {
            state = '초대'
          }
          const selected = inviteMemberList.some((item) => item.projectId === project.id && item.userId === userId)
          const memberSelected = inviteMemberList.some((item) => item.userId === userId)
          const onInviteMember = (id: string) => {
            setInviteMemberList((prev) => {
              const index = prev.findIndex((item) => item.projectId === project.id && item.userId === id)
              if (index !== -1) {
                return removeItemAtIndex(prev, index)
              }
              return [
                ...prev,
                {
                  id: uuid(),
                  key: uuid(),
                  userId,
                  projectId: project.id,
                  title: '',
                  name,
                  email,
                  authority: 'normal',
                  addIndication: true,
                  selectedBoardList: [],
                },
              ]
            })
          }
          return {
            id: uuid(),
            userId,
            name,
            email,
            state,
            selected,
            memberSelected,
            onInviteMember,
            positionLeader: false,
          }
        }),
      }))
  }, [projectList, projectMemberList, currentUser, inviteMemberList, currentProject, activeCount])

  const skeleton = useMemo(() => {
    if (typeof unarchiveList === 'undefined' || typeof archiveList === 'undefined') {
      return true
    }
    return false
  }, [unarchiveList, archiveList])

  const onEmailInvite = (name: string, email: string) => {
    const is = inviteMemberList.some((item) => item.email === email)
    if (is) {
      showToastMessage({
        type: 'Danger',
        title: t('workinvite.memberoption.checttoast'),
        position: 'BOTTOM_CENTER',
      })
      return
    }
    astro
      .readUserId(email)
      .then((user) => {
        setInviteMemberList((prev) => {
          return [
            ...prev,
            {
              key: uuid(),
              userId: String(user.id),
              projectId: '',
              title: '',
              name,
              email,
              addIndication: true,
              selectedBoardList: [],
              authority: 'normal',
            },
          ]
        })
      })
      .catch(() => {
        setInviteMemberList((prev) => {
          return [
            ...prev,
            {
              key: uuid(),
              userId: '',
              projectId: '',
              title: '',
              name,
              email,
              addIndication: true,
              selectedBoardList: [],
              authority: 'normal',
            },
          ]
        })
      })
  }

  const getAuthorityType = (authority: string) => {
    if (authority === 'admin') {
      return 'MANAGER' as const
    } else if (authority === 'normal') {
      return 'MEMBER' as const
    } else if (authority === 'guest') {
      return 'GUEST' as const
    } else {
      return 'MEMBER' as const
    }
  }

  const invalidation = React.useCallback(() => {
    let isInValid = false
    setInviteMemberList((prev) => {
      const next = [...prev]
      for (const item of next) {
        if (item.selectedBoardList.length === 0 && item.authority !== 'admin') {
          isInValid = true
          item.errorMessage = t('workinvite.invite.error') // '참여 보드를 1개 이상 선택해주세요.'
        } else {
          item.errorMessage = undefined
        }
      }
      return next
    })

    return isInValid
  }, [inviteMemberList])

  const onInvite = React.useCallback(() => {
    if (currentProject === null) return
    if (projectMemberList === null) return
    if (invalidation()) return
    const emailList = projectMemberList.map((item) => item.email)
    const payload = inviteMemberList
      .filter((item) => !emailList.includes(item.email))
      .map((item) => {
        return {
          projectId: currentProject.id,
          requestUserId: String(currentUser.id),
          targetUserId: item.userId ? item.userId : null,
          requestDate: new Date(),
          expirationDate: null,
          addon: {
            roomIds: item.selectedBoardList,
            email: item.email,
            memberName: item.name,
            authorityType: getAuthorityType(item.authority),
          },
        }
      })
    if (payload.length !== 0) {
      astro.createProjectInvitation(payload).then(() => {
        if (inviteMemberList.length === payload.length) {
          onClose()
          showToastMessage({
            type: 'Success',
            title: t('toast.success.invite'),
            position: 'BOTTOM_CENTER',
          })
        } else {
          onClose()
          showToastMessage({
            type: 'Danger',
            title: t('workboardmemberview.alert.permission.modifyfail'),
            position: 'BOTTOM_CENTER',
          })
        }
      })
    } else {
      onClose()
      showToastMessage({
        type: 'Danger',
        title: t('workboardmemberview.alert.permission.modifyfail'),
        position: 'BOTTOM_CENTER',
      })
    }
  }, [invalidation, currentProject, projectMemberList])

  return (
    <MemberInviteScreenModal
      i18n={i18n}
      skeleton={skeleton}
      currentProject={currentProject !== null ? currentProject : undefined}
      onPressClose={onClose}
      inviteWorkList={inviteWorkList}
      inviteMemberList={inviteMemberList}
      inviteWorkDetail={[]}
      secureCdnUrl={option.secureCdnUrl}
      unarchiveList={unarchiveList}
      archiveList={archiveList}
      onEmailInvite={onEmailInvite}
      inviteMemberHandleRemove={(index: number) => {
        setInviteMemberList((prev) => {
          return removeItemAtIndex(prev, index)
        })
      }}
      inviteMemberHandleValueChange={(index: number, key: string, value: string, type?: string) => {
        setInviteMemberList((prev) => {
          const next = [...prev]
          if (type === 'boolean') {
            next[index][key] = value === 'true'
          } else if (type === 'number') {
            next[index][key] = Number(value)
          } else {
            next[index][key] = value
          }
          return next
        })
      }}
      inviteMemberHandleBoardPopup={(index: number, selectedBoardList: string[]) => {
        setInviteMemberList((prev) => {
          const next = [...prev]
          next[index].selectedBoardList = selectedBoardList
          return next
        })
      }}
      onInvite={onInvite}
    />
  )
}

export default MemberInviteScreen
