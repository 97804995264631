import * as React from 'react'
import { DragDropContext, Draggable, DraggingStyle, DropResult, Droppable, NotDraggingStyle } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { Dimensions, ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { AddButton } from '../components'
import { WorkmodelDragItem } from './WorkmodelDragItem'
import type { WorkmodelDragListProps } from './types'

export const WorkmodelDragList: React.FC<WorkmodelDragListProps> = ({
  editType,
  list,
  fieldError,
  onChangeElement,
  onChangeList,
  onClickRemoveButton,
  onPressAdd,
  onPressEndTab,
  onPressDeleteInfo,
}) => {
  const { t } = useTranslation()
  const windowHeight = Dimensions.get('window').height
  const [isReady, setIsReady] = React.useState(false)
  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      if (result.destination) onChangeList(result.source.index, result.destination.index)
    },
    [list],
  )

  const inputRefList = React.useRef<TextInput[]>([])

  const onEnter = React.useCallback(
    (index: number) => {
      if (list.length === index + 1) {
        onPressAdd()
      }
      const iv = setInterval(() => {
        const i = inputRefList.current[index + 1]
        if (i) {
          i.focus()
          clearInterval(iv)
        }
      }, 10)
    },
    [list, onPressAdd, inputRefList],
  )
  const onTab = React.useCallback(
    (index: number) => {
      if (list.length === index + 1 && onPressEndTab) {
        onPressEndTab()
      } else {
        const iv = setInterval(() => {
          const i = inputRefList.current[index + 1]
          if (i) {
            i.focus()
            clearInterval(iv)
          }
        }, 10)
      }
    },
    [list, onPressEndTab, inputRefList],
  )

  React.useEffect(() => {
    setIsReady(true)
  }, [])

  const divRef = React.useRef<HTMLDivElement>(null)

  const getItemStyle = React.useCallback(
    (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): React.CSSProperties => {
      const rectY = divRef.current?.getBoundingClientRect().y || 0
      const top = (draggableStyle as DraggingStyle).top || 0
      if (draggableStyle && top > 0) {
        return {
          ...draggableStyle,
          userSelect: 'none',
          position: isDragging ? 'absolute' : 'inherit',
          top: top - rectY,
          left: 0,
        }
      }

      return { ...draggableStyle }
    },
    [],
  )

  if (!isReady) return null

  return (
    <View>
      <ScrollView style={{ minHeight: 300, maxHeight: windowHeight - 600 }}>
        <div ref={divRef}>
          {isReady && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    onLoad={(e) => {
                      console.log('e3', e)
                    }}
                  >
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {isReady &&
                        list.map((item, index) => (
                          <Draggable key={`drag-${index}`} draggableId={`drag-${index}`} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={{
                                  ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                  marginBottom: 8,
                                }}
                              >
                                <WorkmodelDragItem
                                  editType={editType}
                                  isDelete={item.isDelete}
                                  index={index}
                                  fieldError={fieldError}
                                  dragHandleProps={provided.dragHandleProps}
                                  text={item.text || ''}
                                  labelColor={item.labelColor}
                                  isDragable={list.length > 1}
                                  onInputRef={(ref) => {
                                    if (ref) inputRefList.current[index] = ref
                                  }}
                                  autoFocus={item.focused}
                                  onEnter={onEnter}
                                  onTab={onTab}
                                  onPressRemove={() => index !== undefined && onClickRemoveButton(index)}
                                  onChangeText={(text) => {
                                    const { labelColor } = item
                                    index !== undefined &&
                                      onChangeElement(index, {
                                        text,
                                        labelColor,
                                      })
                                  }}
                                  onChangeLabelColor={(labelColor) => {
                                    const { text } = item
                                    index !== undefined &&
                                      onChangeElement(index, {
                                        text,
                                        labelColor,
                                      })
                                  }}
                                  onPressDeleteInfo={onPressDeleteInfo}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </ScrollView>
      <View style={[styles.buttonArea, editType && { paddingRight: 32 }]}>
        <AddButton text={t('workmodal.basic.addsubstructure')} fontName={'txtSm'} onPress={onPressAdd} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonArea: {
    marginTop: 4,
    marginLeft: 28,
  },
})
