import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import { View } from '@rocket-mono/foundations'
import type { TableorderMenu } from '@rocket/types'
import { Text } from '@rui/atoms'
import type { IColors } from '@rui/foundations'
import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import { MenuThumbnail } from '../..'
import Switch from '../../../components/Switch'
import { Button } from '../../Button'
import { useTableorderFile, useTableorderMenuLabel } from '../../providers'
import { useMenu } from '../hooks/useMenu'
import TableorderMenuLabelItem from './TableorderMenuLabelItem'

interface Props {
  item: TableorderMenu
  dragHandleProps?: DraggableProvidedDragHandleProps | null
  onPressMenuDetail?: (categoryId: string, id: string) => void
}

export const TableorderMenuCategoryMenuItem: React.FC<Props> = ({ item, dragHandleProps, onPressMenuDetail }) => {
  const { readFileUrl } = useTableorderFile()
  const { menuLabelList } = useTableorderMenuLabel()
  const { menuForm, toggleMenuSoldout, toggleMenuDisplay } = useMenu()

  const [previewImageUrl, setPreviewImageUrl] = React.useState<string>()

  const editItem = React.useMemo(() => menuForm?.id === item.id, [menuForm?.id, item.id])
  const optionHidden = React.useMemo(() => !item.isDisplayed, [item.isDisplayed])

  const optionSoldout = React.useMemo(() => item.isSoldout, [item.isSoldout])

  const toggleSoldout = React.useCallback(() => toggleMenuSoldout(item.id), [item])
  const toggleOptionHidden = React.useCallback(() => toggleMenuDisplay(item.id), [item])

  const handlePressMenuDetail = React.useCallback(() => {
    onPressMenuDetail && onPressMenuDetail(item.categoryId, item.id)
  }, [item])
  //드래그 아이콘 press
  const [pressItem, setPressItem] = React.useState(false)

  React.useEffect(() => {
    const representative = item.images.filter(({ isRepresentative }) => isRepresentative)
    if (representative.length > 0) {
      readFileUrl(representative[0].path).then(setPreviewImageUrl)
    }
  }, [item])

  return (
    <View style={[styles.wrap, pressItem && styles.pressContainer]}>
      <View style={[editItem && styles.editContainer]}></View>
      <View style={[baseStyles.container, styles.container, pressItem && { borderColor: COLOR.transparent.base }]}>
        <View style={[baseStyles.itemInfo, optionHidden && styles.hiddenItem]}>
          <div {...dragHandleProps}>
            <Pressable
              onPressIn={() => {
                setPressItem(true)
              }}
              onPressOut={() => setPressItem(false)}
              style={{ width: 32, height: 32, justifyContent: 'center' }}
            >
              <XEIcon name={'drag-vertical'} size={16} />
            </Pressable>
          </div>
          {previewImageUrl !== undefined && <MenuThumbnail uri={previewImageUrl} isSoldout={optionSoldout} />}
          <View style={baseStyles.infoContainer}>
            <Text style={baseStyles.itemName}>{item.name}</Text>
            <View style={baseStyles.priceContainer}>
              <Text style={baseStyles.price}>{item.price}</Text>
              <Text style={baseStyles.priceUnit}>원</Text>
            </View>
            <Text style={[baseStyles.itemDetail, styles.itemDetail]}>{item.description}</Text>
            <View style={baseStyles.labels}>
              {menuLabelList !== undefined &&
                menuLabelList
                  .filter(({ id }) => item.labelIds.includes(id))
                  .map((o, idx) => (
                    <TableorderMenuLabelItem key={`label-item-${idx}`} name={o.name} color={o.color as IColors} />
                  ))}
            </View>
          </View>
        </View>
        <View style={styles.optionButtons}>
          <Button
            borderColor={'gray.g300'}
            backgroundColor={'mono.white'}
            color={'mono.black'}
            onPress={handlePressMenuDetail}
            disabled={editItem && true}
          >
            편집
          </Button>
          <View style={styles.option}>
            <View style={styles.optionCon}>
              <Text style={styles.optionName}>품절</Text>
              <Switch size={'large'} activated={optionSoldout} onChange={toggleSoldout} />
            </View>
            <View style={styles.optionCon}>
              <Text style={styles.optionName}>숨김</Text>
              <Switch size={'large'} activated={optionHidden} onChange={toggleOptionHidden} />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  container: {
    minHeight: 120,
    alignItems: 'center',
    padding: 8,
  },
  wrap: {
    borderWidth: 2,
    borderColor: COLOR.transparent.base,
  },
  pressContainer: {
    borderColor: COLOR.main.blue,
    borderRadius: 8,
  },
  editContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 5,
    height: '100%',
    backgroundColor: COLOR.main.red,
  },
  hiddenItem: {
    opacity: 0.3,
  },
  itemDetail: { height: 'auto' },
  optionButtons: {
    height: ' 100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  option: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  optionCon: {
    textAlign: 'center',
    marginHorizontal: 4,
  },
  optionName: {
    ...FONT.txtXs,
    marginBottom: 4,
    color: COLOR.gray.g500,
  },
})

export const baseStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    alignItems: 'flex-end',
    paddingVertical: 12,
  },
  itemInfo: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labels: {
    flexDirection: 'row',
    marginVertical: 4,
  },
  itemName: {
    ...FONT.H7Bold,
  },
  itemDetail: {
    height: 40,
    ...FONT.txtSm,
    color: COLOR.gray.g600,
  },
  itemTime: {
    ...FONT.txt3Xs,
    color: COLOR.gray.g450,
  },
  infoContainer: {
    flex: 1,
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  price: {
    ...FONT.txtMdBold,
  },
  priceUnit: {
    ...FONT.txtSmMedium,
  },
})
