import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { Animated, Pressable, StyleSheet } from 'react-native'

interface Props {
  activated: boolean
  onChange: (activated: boolean) => void
  size: 'small' | 'large' | 'custom'
  customWidth?: number
  customHeight?: number
  customSwitchWidth?: number
  customSwitchHeight?: number
  customMarginVertical?: number
  customLeftToValue?: number
  customRightToValue?: number
  customBorderRadius?: number
}

function Switch({
  activated,
  onChange,
  size = 'small',
  customWidth = 0,
  customHeight = 0,
  customSwitchWidth = 0,
  customSwitchHeight = 0,
  customMarginVertical = 0,
  customLeftToValue = 0,
  customRightToValue = 0,
  customBorderRadius = 0,
}: Props) {
  const moveAni = React.useRef(new Animated.Value(0)).current

  const onSwitch = () => {
    onChange(!activated)
  }

  const { width, height, borderRadius, switchWidth, switchHeight, marginVertical, leftToValue, rightToValue } =
    React.useMemo(() => {
      if (size === 'small') {
        return {
          width: 30,
          height: 19,
          borderRadius: 16,
          switchWidth: 16,
          switchHeight: 16,
          marginVertical: 1.5,
          leftToValue: 2,
          rightToValue: 12,
        }
      } else if (size === 'large') {
        return {
          width: 53,
          height: 34,
          borderRadius: 17,
          switchWidth: 28,
          switchHeight: 28,
          marginVertical: 2.97,
          leftToValue: 3,
          rightToValue: 22,
        }
      } else {
        return {
          width: customWidth,
          height: customHeight,
          borderRadius: customBorderRadius,
          switchWidth: customSwitchWidth,
          switchHeight: customSwitchHeight,
          marginVertical: customMarginVertical,
          leftToValue: customLeftToValue,
          rightToValue: customRightToValue,
        }
      }
    }, [
      customBorderRadius,
      customHeight,
      customLeftToValue,
      customMarginVertical,
      customRightToValue,
      customSwitchHeight,
      customSwitchWidth,
      customWidth,
      size,
    ])

  React.useEffect(() => {
    if (activated) {
      Animated.timing(moveAni, {
        toValue: rightToValue,
        duration: 100,
        useNativeDriver: true,
      }).start()
    } else {
      Animated.timing(moveAni, {
        toValue: leftToValue,
        duration: 100,
        useNativeDriver: true,
      }).start()
    }
  }, [activated, leftToValue, moveAni, rightToValue])

  return (
    <Pressable style={[styles.container, { width, height, borderRadius }]} onPress={onSwitch}>
      <Animated.View
        style={[
          styles.switchHandler,
          {
            width: switchWidth,
            height: switchHeight,
            backgroundColor: activated ? COLOR.main.blue : COLOR.mono.white,
            transform: [{ translateX: moveAni }],
            marginVertical,
            borderRadius,
          },
        ]}
      ></Animated.View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f0f0f0',
  },
  switchHandler: {
    // borderRadius: 16,
  },
})

export default Switch
