import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { SectionList } from 'react-native'

import { removeItemAtIndex } from '@rui/foundations'

import { useTableorderCatalog, useTableorderOrder, useTableorderShop, useTableorderTable } from '../providers'
import type { ViewProps } from './types'

import { useMobileView } from '@rui/foundations'

import { TableorderCatalogMenu, TableorderTable } from '@rocket/types'
import { FragmentsLayout } from '../Layout'
import TableorderHeader from '../TableorderHeader'
import BasketButtonBar from './components/MenuDetail/basketButton'
import MenuCategory from './components/MenuDetail/menuCategory'
import MenuItem from './components/MenuDetail/menuItem'
import { TableorderOrderBasketListView } from './components/TableorderOrderBasketListView'
import TableorderOrderMenuDetailView from './components/TableorderOrderMenuDetailView'

type RenderItemType = {} & TableorderCatalogMenu
type SectionItemType = {
  id: string
  title: string
  isFold: boolean
  data: RenderItemType[]
}

const ScreenView: React.FC<ViewProps> = ({ tableId }) => {
  const isMobile = useMobileView(800)
  const sectionListRef = React.useRef<SectionList<RenderItemType, SectionItemType>>(null)

  const { shopId, shop } = useTableorderShop()
  const { readTable, tableList } = useTableorderTable()
  const { basket, createBasket, basketItemList, orderList } = useTableorderOrder()

  const { currentCatalog } = useTableorderCatalog()

  const [page, setPage] = React.useState('order')
  const [foldList, setFoldList] = React.useState<string[]>([])
  const [categoryId, setCategoryId] = React.useState('')
  const [orderId, setOrderId] = React.useState('')
  const [menuId, setMenuId] = React.useState('')

  const [currentTable, setCurrentTable] = React.useState<TableorderTable>()
  React.useEffect(() => {
    readTable(tableId).then(setCurrentTable)
  }, [tableId, readTable, tableList])

  const filteredOrderList = React.useMemo(
    () =>
      orderList?.filter(
        ({ tableActiveNumber, tableId }) =>
          currentTable && currentTable.id === tableId && tableActiveNumber === currentTable.activeNumber,
      ),
    [currentTable, orderList],
  )
  console.log('orderList-table', { currentTable, filteredOrderList })

  const tabList = React.useMemo(
    () =>
      currentCatalog?.categories.map(({ originId: id, name: title }) => ({
        id,
        title,
      })),
    [currentCatalog],
  )

  const filteredMenuList = React.useMemo(() => {
    if (!currentCatalog) return []
    return currentCatalog.categories.map((category) => {
      return {
        id: category.originId,
        title: category.name,
        isFold: foldList.findIndex((o) => o === category.originId) >= 0 ? true : false,
        data: category.menus.filter(({ isDisplayed }) => isDisplayed),
      }
    })
  }, [currentCatalog, foldList])

  const handlePressNotification = React.useCallback(() => {
    console.log('noti')
  }, [])

  const handlePressSection = React.useCallback(
    (categoryId: string) => {
      const idx = filteredMenuList.findIndex(({ id }) => id === categoryId)

      if (sectionListRef.current && idx >= 0) {
        const params = {
          sectionIndex: idx,
          itemIndex: 1,
        }
        setTimeout(() => {
          sectionListRef.current?.scrollToLocation(params)
        }, 100)
      }
    },
    [filteredMenuList],
  )

  React.useEffect(() => {
    if (currentCatalog && currentCatalog.categories.length > 0) setCategoryId(currentCatalog.categories[0].originId)
  }, [currentCatalog])

  React.useEffect(() => {
    if (basket === undefined) {
      createBasket({
        shopId,
        status: 'EMPTY',
        tableId,
        tableActiveNumber: 1,
      })
    }
  }, [shopId, basket])

  return (
    <FragmentsLayout
      HeaderComponent={
        isMobile && (page === 'basket' || !!menuId) ? undefined : (
          <TableorderHeader
            storeName={shop?.name || ''}
            tableName={currentTable?.name || ''}
            tabList={tabList}
            tabCode={categoryId}
            tabOnCode={handlePressSection}
            onPress={handlePressNotification}
            iconName="bell-o"
          />
        )
      }
      FragmentsComponent={
        (
          isMobile
            ? page === 'basket'
            : basketItemList.length > 0 || (filteredOrderList && filteredOrderList.length > 0)
        ) ? (
          <View
            style={{
              borderLeftWidth: 1,
              borderLeftColor: '#CCCCCC',
              flex: 1,
            }}
          >
            <TableorderOrderBasketListView
              tableId={tableId}
              orderId={orderId}
              orderList={filteredOrderList ?? []}
              onSuccessPayment={setOrderId}
              onBack={() => setPage('order')}
            />
          </View>
        ) : undefined
      }
      isBorder={false}
    >
      <View style={{ height: '100%' }}>
        {filteredMenuList !== undefined &&
          (!!menuId ? (
            <TableorderOrderMenuDetailView
              menuId={menuId}
              catalogCategoryId={categoryId}
              onBack={(page) => {
                setMenuId('')
                if (page !== undefined) {
                  setPage(page)
                }
              }}
              onPhotoPress={() => console.log('onPhotoPress')}
              isFooterPrice={true}
              cartCount={basketItemList.length}
            />
          ) : (
            <>
              <SectionList
                ref={sectionListRef}
                stickySectionHeadersEnabled
                onViewableItemsChanged={({ viewableItems }) => {
                  setCategoryId(viewableItems[0].section.id)
                }}
                sections={filteredMenuList}
                keyExtractor={(_, index) => `menu-section-${index}`}
                renderSectionHeader={({ section }) => {
                  const idx = filteredMenuList.findIndex((o) => o.id === section.id)

                  return (
                    <MenuCategory
                      title={section.title}
                      isFold={section.isFold}
                      onPress={() => {
                        setFoldList((prev) => {
                          const idx = prev.findIndex((o) => o === section.id)
                          return idx < 0 ? [...prev, section.id] : removeItemAtIndex(prev, idx)
                        })
                      }}
                      containerStyle={idx === 0 ? { borderTopWidth: 0 } : {}}
                    />
                  )
                }}
                renderItem={({ item, section }) => {
                  if (section.isFold) return <></>
                  return (
                    <MenuItem
                      menu={item}
                      containerStyle={{ marginHorizontal: 20 }}
                      onPressItem={() => {
                        setMenuId(item.id)
                      }}
                    />
                  )
                }}
              />
              {isMobile && (
                <BasketButtonBar
                  count={basketItemList.length}
                  onPress={() => {
                    setPage('basket')
                  }}
                />
              )}
            </>
          ))}
      </View>
    </FragmentsLayout>
  )
}

export default ScreenView
