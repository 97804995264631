import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import type { DropdownGroupItem } from './types'

interface Props {
  code: string
  isOpen: boolean
  offsetY: number | string
  onDismiss?: () => void
  // onOpen?: (open: boolean) => void
  dropdownList: DropdownGroupItem[]
  onCode: (code: string) => void
  containerStyle?: ViewStyle
  topGap?: number
  position?: 'left' | 'right' | 'center'
  isBackdrop?: boolean
  itemStyle?: ViewStyle
  textStyle?: StyleProp<TextStyle>
  setTop?: (arg: number) => void
  isForceCenter?: boolean
}

function DropdownListGroup({
  code,
  isOpen,
  offsetY,
  onDismiss,
  dropdownList,
  onCode,
  containerStyle,
  topGap = 0,
  position = 'right',
  isBackdrop = false,
  itemStyle,
  textStyle,
  isForceCenter = false,
}: // setTop,
Props) {
  // const dimensions = Dimensions.get('window')
  // const onLayout = React.useCallback(
  //   (e: LayoutChangeEvent) => {
  //     const { layout } = e.nativeEvent
  //     setTop && setTop((dimensions.height - layout.height) / 3)
  //     console.log(dimensions, layout, (dimensions.height - layout.height) / 2)
  //   },
  //   [dimensions],
  // )

  const GroupItemElement = ({
    el,
    elCode,
    gIdx,
    position,
  }: {
    el: {
      id: number
      title: string
      code: string
    }
    gIdx: number
    iIdx: number
    elCode: string
    position?: 'left' | 'right' | 'center'
  }) => {
    return (
      <Pressable
        style={[styles.listButton, gIdx > 0 ? styles.topBorder : undefined, itemStyle]}
        onPress={() => onCode(el.code)}
      >
        <Text
          style={[
            styles.listText,
            textStyle && textStyle,
            {
              color: el.code === elCode ? COLOR.main.blue : COLOR.mono.black,
            },
          ]}
        >
          {el.title}
        </Text>
        {position === 'center'
          ? el.code === code && <XEIcon name="check" color="main.blue" size={16} style={styles.checkIcon} />
          : el.code === code && <XEIcon name="check" color="main.blue" size={16} />}
      </Pressable>
    )
  }
  const alignItems = React.useMemo(() => {
    if (position === 'left') {
      return 'flex-start'
    } else if (position === 'right') {
      return 'flex-end'
    } else {
      return 'center'
    }
  }, [position])
  return (
    <View
      style={[
        styles.backdrop,
        {
          display: isOpen ? 'flex' : 'none',
          alignItems,
        },
        isBackdrop ? { backgroundColor: COLOR.dimmed.a60 } : undefined,
        isForceCenter ? { justifyContent: 'center' } : undefined,
      ]}
    >
      <Pressable style={styles.backdrop} onPress={() => onDismiss && onDismiss()} />
      <View
        style={[
          styles.container,
          containerStyle,
          isForceCenter ? {} : { top: typeof offsetY === 'string' ? Number(offsetY) : offsetY + topGap },
        ]}
      >
        {dropdownList.map((item, gidx) => (
          <View key={`dropdown-list-${gidx}}`}>
            {item.group.map((el, iidx) => (
              <GroupItemElement
                el={el}
                key={`dropdown-list-${iidx}`}
                gIdx={gidx}
                iIdx={iidx}
                elCode={code}
                position={position}
              />
            ))}
          </View>
        ))}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderRadius: 4,
    paddingVertical: 4,
    borderColor: COLOR.gray.g200,
    minWidth: 146,
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 3),
    justifyContent: 'flex-end',
    backgroundColor: COLOR.mono.white,
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
  },
  listButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 16,
    height: 35,
  },
  listText: {
    ...FONT.txtSm,
    color: COLOR.mono.black,
  },
  topBorder: {
    borderTopWidth: 1,
    borderTopColor: COLOR.gray.g150,
  },
  checkIcon: {
    position: 'absolute',
    top: '50%',
    right: 16,
    marginTop: 6,
  },
})

export default React.memo(DropdownListGroup)
export type { DropdownGroupItem }
