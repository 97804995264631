import { useAstro, useCurrentUser, useStomp } from '@rocket-mono/providers'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { WorkSettingProvider } from '../../components'

export const stateData = [
  {
    labelIcon: { name: 'full-moon', size: 14, color: 'status.active' },
    label: 'Active',
    key: '1',
  },
  {
    labelIcon: { name: 'full-moon', size: 14, color: 'status.busy' },
    label: 'Busy',
    key: '2',
  },
  {
    labelIcon: { name: 'full-moon', size: 14, color: 'status.off' },
    label: 'Off',
    key: '3',
  },
]

interface Props {
  children?: JSX.Element | JSX.Element[]
}
const Provider: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation()
  const { astro } = useAstro()
  const { client: stompClient } = useStomp()
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    console.log('i18n::-provider', i18n.language)
  }, [i18n])

  return (
    <WorkSettingProvider
      astro={astro}
      publishState={(userState, userStateDate) => {
        console.log('test')
        stompClient &&
          stompClient.publish({
            destination: '/publish/state',
            body: JSON.stringify({
              userNo: currentUser.id,
              platform: 'web',
              userState,
              stateDate: userStateDate,
            }),
            headers: {
              'content-type': 'application/json',
            },
          })
      }}
    >
      <>{children}</>
    </WorkSettingProvider>
  )
}

export default Provider
