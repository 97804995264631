import React from 'react'

import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ children }: ProviderProps) => {
  return <Context.Provider value={{}}>{children}</Context.Provider>
}

export default Provider
