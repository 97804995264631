import * as React from 'react'
import { Modal, StyleSheet, View } from 'react-native'

import { Button } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import { BoardDeleteInfoSvg } from './BoardDeleteInfoSvg'

interface Props {
  visible: boolean
  onPress?: () => void
}

export const BoardDeleteInfo: React.FC<Props> = ({ visible, onPress }) => {
  return (
    <>
      <Modal visible={visible}>
        {/* <ModalBottomSheetType visible={visible} height={300}>
        <ModalBottomSheetType.Body> */}
        <View style={styles.container}>
          <Text fontName="txtMd" fontColor="gray.g900">
            진행 중인 보드 삭제
          </Text>
          <Text style={{ marginTop: 4 }} fontName="txtSm" fontColor="gray.g700">
            진행 중인 보드는 보드 상단의 더보기 아이콘을 통해서{' '}
            <Text fontColor="main.blue">아카이브(종료 보관) 후 삭제</Text>할 수 있습니다
          </Text>
          <View
            style={{
              marginTop: 12,
              backgroundColor: COLOR.gray.g100,
              borderRadius: 8,
            }}
          >
            <BoardDeleteInfoSvg />
          </View>
        </View>
        {/* </ModalBottomSheetType.Body>
        <ModalBottomSheetType.Footer> */}
        <View style={styles.footerContainer}>
          <Button wide={true} onPress={onPress}>
            <Text fontColor="mono.white">확인</Text>
          </Button>
        </View>
        {/* </ModalBottomSheetType.Footer>
      </ModalBottomSheetType> */}
      </Modal>
      {visible && <View style={styles.dimmedCover}></View>}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 20,
    marginHorizontal: 20,
    width: '100%',
  },
  dimmedCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: COLOR.dimmed.a40,
  },
  footerContainer: {
    flex: 1,
    marginHorizontal: 20,
    marginBottom: 20,
  },
})
