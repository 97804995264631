import * as React from 'react'

import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ children, currentUser, astro, onStatus }: ProviderProps) => {
  const { t } = useTranslation()
  const { show: showToastMessage } = useToast()

  const onUpdatePassword = React.useCallback((currentPassword: string, newPassword: string) => {
    astro
      .changePassword(currentPassword, newPassword)
      .then(() => {
        onStatus('success')
        showToastMessage({
          type: 'Success',
          title: t('setting.password.successmessage'),
          position: 'BOTTOM_CENTER',
        })
      })
      .catch(() => {
        showToastMessage({
          type: 'Danger',
          title: t('setting.password.errormessage.passwordconfirm'),
          position: Platform.OS === 'web' ? 'BOTTOM_CENTER' : 'TOP_CENTER',
        })
      })
  }, [])

  return (
    <Context.Provider
      value={{
        currentUser,
        onUpdatePassword,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
