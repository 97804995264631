import type { IColors } from '@rui/foundations'
import { getRealColor } from '@rui/foundations'
import * as React from 'react'
import { Modal, StyleSheet, TouchableWithoutFeedback, View } from 'react-native'

interface Props {
  onDismiss: () => void
  backgroundColor?: IColors
  pressableDimmed?: boolean
  opacity?: number
  children: JSX.Element
}

const Dimmed: React.FC<Props> = ({
  onDismiss,
  backgroundColor = 'transparent',
  pressableDimmed = false,
  opacity = 0.6,
  children,
}) => {
  const onPressDimmed = pressableDimmed ? onDismiss : undefined
  return (
    <Modal transparent onRequestClose={onDismiss}>
      <TouchableWithoutFeedback onPress={onPressDimmed}>
        <View style={[styles.dimmed, { backgroundColor: getRealColor(backgroundColor), opacity }]}></View>
      </TouchableWithoutFeedback>
      <>{children}</>
    </Modal>
  )
}

export default Dimmed

const styles = StyleSheet.create({
  dimmed: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
})
