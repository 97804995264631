import { ChatsProvider } from './ChatsProvider'
import { ChatsContext } from './ChatsProviderContext'
import { ChatsView } from './ChatsView'

const ChatsScreen = Object.assign(ChatsView, {
  Provider: ChatsProvider,
  Consumer: ChatsContext.Consumer,
})

export { ChatsScreen }

export * from './types'
export * from './useChatsData'
