import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { useState } from 'react'
import { FlatList, SafeAreaView, StyleSheet, View } from 'react-native'
import type { TableCardProps } from './TableCard'
import TableCard from './TableCard'

interface Props {
  list: TableCardProps[]
}

const BoardTableCard = ({ list }: Props) => {
  const [containerWidth, setContainerWidth] = useState(0)
  const [containerHeight, setContainerHeight] = useState(0)
  const margins = 20 * 2
  const numColumns = 5

  const keyExtractor = React.useCallback((_item: TableCardProps, idx: number) => `tablecard-${idx}`, [])

  return (
    <View>
      <SafeAreaView>
        <View
          onLayout={(e) => {
            setContainerHeight(e.nativeEvent.layout.height)
          }}
        >
          <FlatList
            data={list}
            onLayout={(e) => {
              setContainerWidth(e.nativeEvent.layout.width)
            }}
            renderItem={({ item }) => (
              <TableCard
                width={(containerWidth - margins) / numColumns}
                height={(containerHeight - margins) / numColumns}
                minWidth={137}
                minHeight={107}
                // onPress={() => console.log('press')}
                item={item}
              />
            )}
            keyExtractor={keyExtractor}
            numColumns={numColumns}
            // @ts-ignore
            style={{ width: '100vw', height: '100vh' }}
          />
        </View>
        {/* <Text>{containerHeight}</Text> */}
      </SafeAreaView>
    </View>
  )
}

export default BoardTableCard

export const styleTableCard = StyleSheet.create({
  cardContainer: {
    padding: 8,
    borderRadius: 8,
    borderWidth: 2,
    minHeight: 107,
    margin: 4,
  },
  infoText: {
    ...FONT.txtXs,
    color: COLOR.mono.darkGray,
  },
  nameText: {
    ...FONT.txtSm,
  },
})
