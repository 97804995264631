import * as React from 'react'

import { i18n } from 'i18next'

import Providers from './providers'
import ProjectMemberView from './view'

interface Props {
  i18n: i18n
  projectId: string
  onProjectLeave: () => void
}

const ProjectMemberScreen: React.FC<Props> = ({ i18n, projectId, onProjectLeave }) => {
  return (
    <Providers i18n={i18n}>
      <ProjectMemberView projectId={projectId} onProjectLeave={onProjectLeave} />
    </Providers>
  )
}

export default ProjectMemberScreen
