/* eslint-disable @typescript-eslint/ban-ts-comment */
import { WorkIcon } from '@rocket/atoms'
import type { IColors } from '@rui/foundations'
import { COLOR, FONT, getRealColor } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Platform, Text, View } from 'react-native'
import { Button } from '../Button'
import { RoundLabel } from '../Label'
import { styles } from './styles'

interface SticktyHeaderProps {
  workIcon: string
  workTitle: string
  workInfo: string
  workPrice: string
  offsetTop: number
  backgroundColor?: IColors
  headerOffsetTop?: number
  onBackPress: () => void
  onStartPress: () => void
  onStick?: (isSticky: boolean) => void
  children: JSX.Element | JSX.Element[]
}

const StickyHeader = ({
  workIcon,
  workTitle,
  workInfo,
  workPrice,
  offsetTop,
  backgroundColor = 'gray.g050',
  headerOffsetTop = 218,
  onBackPress,
  onStartPress,
  onStick,
  children,
}: SticktyHeaderProps) => {
  const { t } = useTranslation()
  const chartWidth = Dimensions.get('window').width
  const mobileView = Platform.OS === 'web' ? 960 > chartWidth : true
  const [ifScroll, setIfScroll] = React.useState(offsetTop)
  const showHeader = React.useMemo(() => {
    return ifScroll > headerOffsetTop
  }, [ifScroll, headerOffsetTop])

  React.useEffect(() => {
    setIfScroll(offsetTop)
  }, [offsetTop])

  React.useEffect(() => {
    onStick && onStick(showHeader)
  }, [onStick, showHeader])

  return (
    <View>
      {/* sticky area */}
      {showHeader ? (
        // @ts-ignore
        <div style={styles.stickyContainerV2}>
          <View style={[{ margin: 20 }, mobileView ? { width: 'auto', margin: 12 } : { width: 100 }]}>
            <Button
              style={[styles.buttonIcon, mobileView && styles.buttonIconMobile, { backgroundColor: COLOR.gray.g100 }]}
              onPress={onBackPress}
            >
              <XEIcon name={'arrow-left'} size={16} color={'gray.g700'} style={{ marginTop: -2, marginRight: 4 }} />
              {!mobileView && <Text style={{ color: COLOR.gray.g700 }}>{t('stickyheader.back')}</Text>}
            </Button>
          </View>
          <View style={[styles.contentsArea, mobileView && { marginVertical: 'auto' }]}>
            <View style={styles.inner}>
              <View style={{ marginRight: mobileView ? 8 : 12 }}>
                {workIcon === 'DSP' && <WorkIcon.DSP size={mobileView ? 32 : 68} />}
                {workIcon === 'CSP' && <WorkIcon.CSP size={mobileView ? 32 : 68} />}
                {workIcon === 'WFP' && <WorkIcon.WFP size={mobileView ? 32 : 68} />}
              </View>
              <View>
                <Text style={[styles.title, mobileView ? { ...FONT.H7Bold } : { ...FONT.H6Bold }]}>{workTitle}</Text>
                {!mobileView && <Text style={styles.infoTxt}>{workInfo}</Text>}
              </View>
            </View>
            <View style={[styles.inner]}>
              {!mobileView && (
                <View style={{ marginRight: 12 }}>
                  <RoundLabel
                    text={workPrice}
                    fontName={'txtXsBold'}
                    fontColor={'mono.white'}
                    labelColor={'main.darkNavy'}
                  />
                </View>
              )}
              {!mobileView && (
                <Button style={[styles.buttonIcon, mobileView && styles.buttonIconMobile]} onPress={onStartPress}>
                  <Text style={{ color: COLOR.mono.white }}>{t('stickyheader.start')}</Text>
                  <XEIcon
                    name={'arrow-right'}
                    size={16}
                    color={'mono.white'}
                    style={{ marginTop: -2, marginLeft: 4 }}
                  />
                </Button>
              )}
            </View>
          </View>

          <View style={[{ margin: 12 }, mobileView ? { width: 'auto' } : { width: 100 }]}>
            {mobileView && (
              <Button style={styles.buttonIcon} onPress={onStartPress}>
                <Text style={{ color: COLOR.mono.white }}>{t('stickyheader.start')}</Text>
                <XEIcon name={'arrow-right'} size={16} color={'mono.white'} style={{ marginTop: -2, marginLeft: 4 }} />
              </Button>
            )}
          </View>
        </div>
      ) : (
        <></>
      )}
      {/* top area */}
      <View style={[styles.container, { backgroundColor: getRealColor(backgroundColor) }]}>
        <View style={[mobileView ? { width: 'auto' } : { width: 100 }, { marginRight: 8 }]}>
          <Button
            style={[styles.buttonIcon, mobileView && styles.buttonIconMobile, { backgroundColor: COLOR.gray.g100 }]}
            onPress={onBackPress}
          >
            <XEIcon name={'arrow-left'} size={16} color={'gray.g700'} style={{ marginTop: -2, marginRight: 4 }} />
            {!mobileView && <Text style={{ color: COLOR.gray.g700 }}>{t('stickyheader.back')}</Text>}
          </Button>
        </View>

        <View style={[mobileView ? { width: 'auto' } : { width: 100 }, { marginLeft: 8 }]}></View>
      </View>
      {children}
    </View>
  )
}

export default StickyHeader
