import { useModal } from '@rocket-mono/providers'
import { useMobileView } from '@rui/foundations'
import * as React from 'react'
import WorkSettingLanguageMobile from './mobile'
import type { WorkSettingLanguageType } from './types'
import WorkSettingLanguageWeb from './web'

const WorkSettingLanguage = (props: WorkSettingLanguageType) => {
  const isMobile = useMobileView(960)
  const { Modal, visible, close, open } = useModal('WorkSettingLanguage')

  React.useEffect(() => {
    if (isMobile) open()
  }, [isMobile])

  const handleSave = React.useCallback(() => {
    if (isMobile) {
      close()
      props.onPressSave && props.onPressSave()
    }
  }, [props.onPressSave])

  const handleClose = React.useCallback(() => {
    if (isMobile) {
      close()
      props.onDismiss && props.onDismiss()
    }
  }, [props.onDismiss])

  return isMobile ? (
    <Modal animationType={'fade'} visible={visible}>
      <WorkSettingLanguageMobile {...props} onPressSave={handleSave} onDismiss={handleClose} />
    </Modal>
  ) : (
    <WorkSettingLanguageWeb {...props} />
  )
}

export default WorkSettingLanguage
