import { ModalBottom } from '@rocket-mono/foundations'
import { ToastProvider } from '@rui/atoms'
import * as React from 'react'

interface Props {
  children?: JSX.Element
}

const ComponentsBaseProviders: React.FC<Props> = ({ children }) => {
  return (
    <ToastProvider offsetLayout={{ top: 0 }}>
      <ModalBottom.Provider>
        <>{children}</>
      </ModalBottom.Provider>
    </ToastProvider>
  )
}

export default ComponentsBaseProviders
