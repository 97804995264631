import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { Platform, Pressable, StyleSheet, Text, ViewStyle } from 'react-native'
import Check from './icon/Check'

interface Props {
  color: string
  label: string
  active: boolean
  onActive?: (active: boolean) => void
  containerStyle?: ViewStyle
}

const MenuLabel: React.FC<Props> = ({ color, label, active, onActive, containerStyle }) => {
  return (
    <Pressable
      style={[
        containerStyle,
        styles.container,
        active ? styles.selected : styles.unselected,
        {
          backgroundColor: active ? color : COLOR.mono.white,
        },
      ]}
      onPress={() => {
        onActive && onActive(!active)
      }}
    >
      <Check
        style={styles.icon}
        fillCircle={active ? COLOR.mono.white : COLOR.mono.pale}
        fillCheck={active ? color : COLOR.mono.white}
      />
      <Text style={[styles.label, { color: active ? COLOR.mono.white : COLOR.mono.realBlack }]} selectable={false}>
        {label}
      </Text>
    </Pressable>
  )
}

export default MenuLabel

const styles = StyleSheet.create({
  // @ts-ignore
  container: {
    height: 34,
    borderWidth: 1,
    flexDirection: 'row',
    borderRadius: 5,
    ...Platform.select({
      web: { width: 'fit-content' },
    }),
  },
  unselected: {
    borderColor: COLOR.mono.lightGray,
  },
  selected: {
    ...Platform.select({
      web: { borderColor: 'rgba(0, 0, 0, 0.1)' },
    }),
  },
  icon: {
    marginVertical: 8,
    marginLeft: 8,
    marginRight: 5,
  },
  label: {
    ...FONT.textRegular,
    marginVertical: 8,
    marginRight: 8,
  },
})
