/* eslint-disable @typescript-eslint/no-use-before-define */
import { Astro } from '@rocket/astronaut'
import {
  Notification as AstroNotification,
  ProjectMember as AstroProjectMember,
  ProjectWithdrawal as Payload,
  UserState,
} from '@rocket/types'
import { appCardList } from './constants'
import { getInvitationDateBy } from './noticeHelper'

interface CreatePayloadArgs {
  notification: AstroNotification
  userId: string
}

function createPayload({ notification, userId }: CreatePayloadArgs): Omit<Payload, 'status'> {
  if (!notification.addonProject) throw Error('project가 존재하지 않습니다.')
  return {
    id: notification.id,
    projectId: notification.addonProject.id,
    targetUserId: userId,
    requestUserId: notification.regUserId,
    requestDate: notification.regDate,
    expirationDate: notification.addonProject.expirationDate,
  }
}

export function createAcceptPayload(args: CreatePayloadArgs): Payload {
  return { ...createPayload(args), status: 'ACCEPT' }
}

export function createRejectPayload(args: CreatePayloadArgs): Payload {
  return { ...createPayload(args), status: 'REJECT' }
}

export async function createOpenModalActionPayload({
  astro,
  notification,
  userId,
}: {
  astro: Astro
  notification: AstroNotification
  userId: string
}): Promise<any> {
  if (!notification.addonProject) throw Error('project가 존재하지 않습니다.')
  const _project = await astro.readProject(notification.addonProject.id)
  if (!_project) {
    throw Error('project가 존재하지 않습니다.')
  }
  const _requestUser = _project.members.find((aMember) => aMember.userId === notification.regUserId)
  const _targetUser = _project.members.find((aMember) => aMember.userId === userId)

  if (!_requestUser) {
    throw Error('requestUser가 워크 멤버에 없습니다.')
  }

  if (!_targetUser) {
    throw Error('targetUser가 워크 멤버에 없습니다.')
  }

  const basePayload = {
    notification,
    user: {
      userName: _requestUser.name,
      userEmail: _requestUser.email,
      requestDate: notification.regDate,
      imageSrc: _requestUser.thumbnailUri ?? '',
      authorityType: _requestUser.auth,
    },
    projectInfo: {
      projectName: _project.title,
      projectGoal: _project.goal ?? '',
    },
  }

  if (notification.eventType === 'REQUEST_LEAVE') {
    return {
      ...basePayload,
      boardsInfo: {
        boards: await getRelatedBoards(_requestUser),
        totalCount: _project.elements.length,
      },
      user: {
        ...basePayload.user,
        invitationDate: getInvitationDateBy(_requestUser.daysOfActivity),
        dayOfActivity: _requestUser.daysOfActivity,
        status: await getUserStatus(),
      },
    }
  }

  if (notification.eventType === 'REQUEST_TRANSFER_OWNERSHIP') {
    return {
      ...basePayload,
      boardsInfo: {
        boards: await getRelatedBoards(_targetUser),
        totalCount: _project.elements.length,
      },
      user: {
        ...basePayload.user,
        expirationDate: notification.addonProject.expirationDate,
      },
      appCardList,
    }
  }

  return {
    ...basePayload,
    boardsInfo: {
      boards: await getInvitedBoards(),
      totalCount: _project.elements.length,
    },
    user: {
      ...basePayload.user,
      authorityType: _targetUser.auth,
      expirationDate: notification.addonProject.expirationDate,
    },
    appCardList,
  }

  async function getInvitedBoards(): Promise<{ id: string; label: string }[]> {
    let result = [] as { id: string; label: string }[]
    try {
      const invitation = await astro.readProjectInvitation({
        projectId: _project.id,
        requestUserId: _requestUser!.userId,
        targetUserId: _targetUser!.userId ?? null,
      })

      if (!invitation?.addon) {
        throw Error('addon이 없습니다.')
      }

      result = _project.elements
        .filter(({ id }) => invitation?.addon?.roomIds?.includes(id))
        .map(({ id, name }) => ({ id, label: name }))

      return result
    } catch (error) {
      console.error(error)
      return result
    }
  }

  async function getRelatedBoards(user: AstroProjectMember): Promise<{ id: string; label: string }[]> {
    let result = [] as { id: string; label: string }[]
    try {
      result = await astro
        .readChannelList({
          type: 'G',
          isArchive: false,
          projectId: _project.id,
          userId: user.userId,
        })
        .then((boards) => boards.map(({ id, roomName }) => ({ id, label: roomName })))
    } catch {
      result = [] as { id: string; label: string }[]
    }
    return result
  }

  async function getUserStatus(): Promise<UserState> {
    let result: UserState = 'invisible'
    try {
      result = await astro.readUserState(_requestUser!.userId)
    } catch (error) {
      console.error(error)
    }
    return result
  }
}
