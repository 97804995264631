import { Modal, View } from '@rocket-mono/foundations'
import { useModalDialog } from '@rocket-mono/providers'
import { Text, useToast } from '@rui/atoms'
import { COLOR, getRealColor } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { StyleSheet, TextInput } from 'react-native'
import { Button } from '../Button'
import { QRCodeViewer } from '../QRCodeViewer'

import { useTranslation } from 'react-i18next'
import BarButton from '../Button/BarButton'
import { useTableorderTable } from '../providers'
import type { ViewProps } from './types'
// import QRCode from 'easyqrcodejs'

export const TableorderTableInfoView: React.FC<ViewProps> = ({ readonly, shopId, shopLayoutId, location }) => {
  const { t } = useTranslation()
  const [id, setId] = React.useState('')
  const [name, setName] = React.useState('')
  const [count, setCount] = React.useState('')
  const allowableHeadcount = React.useMemo(() => Number(count), [count])

  const { currentTable, createTable, updateTable, deleteTable, closeTable } = useTableorderTable()

  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const isCreateButtonDisabled = React.useMemo(() => !name || !count, [name, count])

  const [qrData, setQrData] = React.useState<string>()
  const qrLink = React.useMemo(() => {
    if (!currentTable) return undefined
    return `${window.location.origin}/tableorder/${shopId}/order/${currentTable.id}/customer`
  }, [currentTable])

  console.log('qrLink', qrLink)

  const handlePressCreate = React.useCallback(() => {
    const { x: locationX, y: locationY } = location
    createTable({
      shopId,
      shopLayoutId,
      name,
      allowableHeadcount,
      locationX,
      locationY,
    }).then(() => {
      showToastMessage({ type: 'Success', title: '테이블이 등록되었습니다.', position: 'BOTTOM_CENTER' })
      closeTable()
    })
  }, [location, name, allowableHeadcount])
  const handlePressUpdate = React.useCallback(() => {
    if (currentTable?.status === 'USING') {
      showToastMessage({
        type: 'Info',
        title: '빈 테이블만 수정할 수 있습니다.',
        position: 'BOTTOM_CENTER',
      })
    } else {
      updateTable(id, {
        name,
        allowableHeadcount,
      }).then(() => {
        showToastMessage({
          type: 'Success',
          title: '테이블이 수정되었습니다.',
          position: 'TOP_RIGHT',
        })
        closeTable()
      })
    }
  }, [id, name, allowableHeadcount])
  const handlePressDelete = React.useCallback(() => {
    if (currentTable?.status === 'USING') {
      showToastMessage({
        type: 'Info',
        title: '빈 테이블만 삭제할 수 있습니다.',
        position: 'BOTTOM_CENTER',
      })
    } else {
      const list = [
        {
          name: '삭제',
          action: () => deleteTable(id).then(closeTable),
        },
      ]
      showDialogMessage({
        title: '테이블을 삭제하시겠습니까?',
        message: '삭제하시면 해당 테이블의 매출 이력과 주문 QR코드도 더이상 사용하실 수 없습니다.',
        list,
        cancelText: '취소',
        onCancel: hideDialogMessage,
      })
    }
  }, [id, name, allowableHeadcount])

  React.useEffect(() => {
    if (currentTable) {
      setId(currentTable.id)
      setName(currentTable.name)
      setCount(String(currentTable.allowableHeadcount))
    }
  }, [currentTable])

  return (
    <View
      style={{
        width: 480,
        minHeight: 362,
        backgroundColor: COLOR.mono.white,
        borderRadius: 8,
      }}
    >
      <Modal.Header textAlign="center" onPressClose={closeTable}>
        {readonly ? '테이블 주문QR' : '테이블 정보'}
      </Modal.Header>
      <Modal.Body>
        <View style={styles.container}>
          {!readonly && (
            <>
              <View style={{ height: 80 }}>
                <View
                  style={{
                    height: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <XEIcon name="restaurant" size={14} color="mono.black" />
                  <Text fontName="textMedium" fontColor="mono.black">
                    테이블 이름
                  </Text>
                </View>
                <View
                  style={{
                    height: 36,
                    borderWidth: 1,
                    borderColor: COLOR.gray.g200,
                    borderRadius: 5,
                  }}
                >
                  <TextInput
                    value={name}
                    onChangeText={(text) => {
                      setName(text)
                    }}
                    numberOfLines={1}
                    style={{ flex: 1, height: 36 }}
                    placeholder="테이블명을 입력하세요."
                    placeholderTextColor={getRealColor('gray.g450')}
                  />
                </View>
              </View>
              <View style={{ height: 80 }}>
                <View
                  style={{
                    height: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <XEIcon name="users-o" size={14} color="mono.black" />
                  <Text fontName="textMedium" fontColor="mono.black">
                    테이블 허용 인원
                  </Text>
                </View>
                <View
                  style={{
                    height: 36,
                    borderWidth: 1,
                    borderColor: COLOR.gray.g200,
                    borderRadius: 5,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <TextInput
                    value={count}
                    onChangeText={(text) => {
                      if (text === '') setCount(text)
                      const pattern = /[0-9]+/
                      const exec = pattern.exec(text)
                      if (exec && exec.length > 0) {
                        setCount(exec[0])
                      }
                    }}
                    numberOfLines={1}
                    style={{
                      flex: 1,
                      height: 36,
                      marginRight: 6,
                      textAlign: 'right',
                    }}
                    placeholder="0"
                    placeholderTextColor={getRealColor('gray.g450')}
                  />
                  <Text style={{ marginRight: 14 }} fontName="textThin">
                    인석
                  </Text>
                </View>
              </View>
            </>
          )}
          {!!id && (
            <View>
              {readonly ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Text fontName="subTextThin" fontColor="mono.black">
                    코드 생성일{' '}
                    {t('format.date.L', {
                      date: currentTable ? currentTable.createdAt : new Date(),
                    })}
                  </Text>
                </View>
              ) : (
                <>
                  <View
                    style={{
                      height: 20,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <XEIcon name="qr-code" size={14} color="mono.black" />
                    <Text fontName="textMedium" fontColor="mono.black">
                      테이블 주문 QR
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Text fontName="subTextThin" fontColor="mono.darkGray">
                      아래 QR코드를 다운로드해서 테이블에 붙여주세요.
                    </Text>
                    <Text fontName="subTextThin" fontColor="mono.black">
                      코드 생성일{' '}
                      {t('format.date.L', {
                        date: currentTable ? currentTable.createdAt : new Date(),
                      })}
                    </Text>
                  </View>
                </>
              )}
              <View
                style={{
                  width: 440,
                  height: 228,
                  marginTop: 8,
                  paddingVertical: 20,
                  alignItems: 'center',
                  backgroundColor: getRealColor('mono.paleWhite'),
                }}
              >
                {qrLink !== undefined && (
                  <QRCodeViewer
                    size={137}
                    url={qrLink}
                    onRenderingEnd={(_options, dataURL) => {
                      setQrData(dataURL)
                    }}
                  />
                )}
                <View style={{ flexDirection: 'row', marginTop: 24 }}>
                  <Button
                    style={{ width: 115, marginRight: 10 }}
                    onPress={() => {
                      if (qrData) {
                        var byteString = atob(qrData.split(',')[1])
                        var mimeString = qrData.split(',')[0].split(':')[1].split(';')[0]

                        var ab = new ArrayBuffer(byteString.length)
                        var ia = new Uint8Array(ab)

                        for (var i = 0; i < byteString.length; i++) {
                          ia[i] = byteString.charCodeAt(i)
                        }
                        var blob = new Blob([ab], { type: mimeString })

                        const url = window.URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.href = url
                        a.download = 'export.png'
                        document.body.appendChild(a)
                        a.click()
                        setTimeout(() => {
                          window.URL.revokeObjectURL(url)
                        }, 60000)
                        a.remove()
                      }
                    }}
                  >
                    PNG
                  </Button>
                  <Button
                    style={{ width: 115 }}
                    onPress={() => {
                      if (qrData) {
                        const size = '1370'
                        const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
                        const svgimg = document.createElementNS('http://www.w3.org/2000/svg', 'image')
                        // new
                        svgimg.setAttribute('width', size)
                        svgimg.setAttribute('height', size)

                        svgimg.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', qrData)

                        svgElement.append(svgimg)

                        const data = new XMLSerializer().serializeToString(svgElement)
                        const blob = new Blob([data], {
                          type: 'image/svg+xml;charset=utf-8',
                        })

                        const url = window.URL.createObjectURL(blob)
                        const a = document.createElement('a')
                        a.href = url
                        a.download = 'export.svg'
                        document.body.appendChild(a)
                        a.click()
                        setTimeout(() => {
                          window.URL.revokeObjectURL(url)
                        }, 60000)
                        a.remove()
                      }
                    }}
                  >
                    SVG
                  </Button>
                </View>
              </View>
            </View>
          )}
        </View>
      </Modal.Body>
      {!readonly && (
        <Modal.Footer>
          {id ? (
            <>
              <BarButton
                contanerStyle={{
                  height: 50,
                  borderBottomLeftRadius: 8,
                }}
                title="테이블 삭제"
                action={handlePressDelete}
              />
              <BarButton
                contanerStyle={{
                  height: 50,
                  borderBottomRightRadius: 8,
                }}
                title="테이블 수정"
                action={handlePressUpdate}
                callToAction
              />
            </>
          ) : (
            <BarButton
              disabled={isCreateButtonDisabled}
              contanerStyle={{
                height: 50,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
              }}
              title="테이블 등록"
              action={handlePressCreate}
              callToAction
            />
          )}
        </Modal.Footer>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: 440,
    backgroundColor: COLOR.mono.white,
  },
})
