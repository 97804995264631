import * as React from 'react'
import { StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { Button, Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { useTranslation } from 'react-i18next'
import { CreateWorkButtonInChatList, ModalBottomSheetType } from '../../components'

interface Props {
  userName: string
  visible: boolean
  onPress?: () => void
  onPressClose?: () => void
}

export const ChatsEmpty: React.FC<Props> = ({ userName, visible, onPress, onPressClose }) => {
  const { t } = useTranslation()
  return (
    <>
      <ModalBottomSheetType visible={visible} height={300}>
        <ModalBottomSheetType.Body>
          <View style={styles.container}>
            <Text fontName="H7Bold" style={{ marginBottom: 4 }}>
              {t('screen.chatsempty.title', { userName })}
            </Text>
            <Text fontName="txtMd">{t('screen.chatsempty.contents1')}</Text>
            <Text fontName="txtMd">{t('screen.chatsempty.contents2')}</Text>
            <View style={{ minWidth: 220, marginVertical: 12 }}>
              <CreateWorkButtonInChatList onPress={onPress} />
            </View>
            <Button
              // backgroundColor={'mono.white'}
              style={{ paddingHorizontal: 16, backgroundColor: COLOR.mono.white }}
              text={<Text style={{ color: COLOR.gray.g700 }}>다음에 만들기</Text>}
              onPress={onPressClose}
            />
          </View>
        </ModalBottomSheetType.Body>
      </ModalBottomSheetType>
      {visible ? <View style={styles.dimmedCover}></View> : <></>}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 32,
  },
  dimmedCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: COLOR.dimmed.a40,
  },
})
