import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import Gauge from '../Gauge'
import type { Props } from './type'

interface UIProps extends Props {
  isDragging?: boolean
}

export const UIFile = ({ progress, onPress, isDragging = false, disabled, disabledInfo }: UIProps) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = React.useState(false)

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: isDragging ? COLOR.mono.lightGray : COLOR.mono.white,
      }}
    >
      <Pressable
        style={[
          styles.container,
          disabled && {
            borderStyle: 'solid',
            borderColor: COLOR.gray.g200,
            backgroundColor: COLOR.gray.g050,
          },
          isHover && { backgroundColor: COLOR.gray.g050 },
        ]}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        onPress={onPress}
      >
        <View style={styles.downloadIcon}>
          <XEIcon name={disabled ? 'cloud-off' : 'cloud-upload-o'} color="mono.paleBlack" size={18} />
        </View>
        <View style={{ flex: 1, alignItems: 'center' }}>
          {disabled ? (
            <Text fontName="txtXs" fontColor="gray.g450" selectable={false}>
              {disabledInfo}
            </Text>
          ) : (
            <>
              <Text fontName="txtXs" fontColor="gray.g450" selectable={false}>
                {t('file.draghere')}
              </Text>
              <Text fontName="txtXs" fontColor="gray.g450" selectable={false}>
                {t('file.copyhere')}
              </Text>
            </>
          )}
        </View>
      </Pressable>
      {!!progress && progress > 0 && (
        <Gauge
          containerStyle={{ position: 'absolute', top: 0, left: 0, right: 0 }}
          showText={false}
          small
          rounded
          fillColor="main.blue"
          unfillColor="mono.paleWhite"
          progress={progress}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 58,
    flex: 1,
    flexDirection: 'row',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g450,
    alignItems: 'center',
    borderRadius: 4,
  },
  downloadIcon: {
    position: 'absolute',
    backgroundColor: COLOR.mono.pale,
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 16,
    borderRadius: 100,
  },
})
