import React from 'react'

import { View } from '@rocket-mono/foundations'
import { useAstro, useSubscription } from '@rocket-mono/providers'
import { use100vh } from 'react-div-100vh'
import { TableorderOrderCustomerScreen } from '../components'
import Providers from '../providers'

interface Props {
  shopId: string
  tableId?: string
}

const OrderCustomerScreen: React.FC<Props> = ({ shopId, tableId }) => {
  const { astro } = useAstro()
  const height = use100vh()
  if (!tableId) return <></>
  return (
    <Providers>
      <View style={{ height: height || '100%' }}>
        <TableorderOrderCustomerScreen astro={astro} subscribe={useSubscription} shopId={shopId} tableId={tableId} />
      </View>
    </Providers>
  )
}

export default OrderCustomerScreen
