import { IColors } from '@rocket-mono/libs'
import React from 'react'
import { AnimatableNumericValue, DimensionValue, StyleSheet, View } from 'react-native'

import { SkeletonBar, SkeletonSquare } from '../SkeletomParts'

interface Props {
  opacity?: AnimatableNumericValue
  squareColor?: IColors
  barWidth?: DimensionValue
}

const SkeletonSideNavItem: React.FC<Props> = ({ opacity = 1, squareColor = 'opacity.w30', barWidth = '80%' }) => {
  return (
    <View style={[styles.rowFlexbox, { opacity }]}>
      <SkeletonSquare width={16} height={16} color={squareColor} />
      <View style={{ width: 4 }} />
      <SkeletonBar width={barWidth} height={14} color="opacity.w10" />
    </View>
  )
}

const styles = StyleSheet.create({
  rowFlexbox: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingLeft: 28,
    paddingVertical: 8,
    marginVertical: 2,
  },
})

export default SkeletonSideNavItem
