import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { ScrollView, StyleSheet, Text, View } from 'react-native'
import { useTableorderOrderDetail } from '../TableorderOrderDetailView'
import BoardOrderCard from './BoardOrderCard'
import BoardOrderCardBlank from './BoardOrderCardBlank'

export interface BoardOrderListProps {
  itemCode: string
  tableId: string
  orderDate: Date
  menu: string[]
  price: string
}

interface Props {
  list?: BoardOrderListProps[]
  onCode?: (code: string) => void
}

const BoardOrderList: React.FC<Props> = ({ list, onCode }) => {
  const { orderItem } = useTableorderOrderDetail()

  return (
    <View style={styles.container}>
      <View style={styles.boardTitle}>
        <Text style={styles.boardTitleText1}>주문 리스트</Text>
        <Text style={styles.boardTitleText2}>{list?.length || 0}건</Text>
      </View>
      <ScrollView>
        {list && list.length === 0 && <BoardOrderCardBlank />}
        {list &&
          list.map((item, idx) => (
            <View key={`borderOrderCard-${idx}`}>
              <BoardOrderCard item={item} idx={idx} onCode={onCode} selectedButton={orderItem?.id ?? ''} />
            </View>
          ))}
      </ScrollView>
    </View>
  )
}

export default BoardOrderList

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.mono.paleWhite,
    width: 210,
    minHeight: 668,
    padding: 12,
    paddingTop: 4,
    paddingRight: 0,
  },
  boardTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingRight: 12,
  },
  boardTitleText1: {
    ...FONT.txtSmBold,
  },
  boardTitleText2: {
    ...FONT.txtSm,
  },
})
