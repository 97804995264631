import { Button } from '@rui/atoms'
import { COLOR, FONT, getRealColor } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useState } from 'react'
import { Dimensions, Modal, StyleSheet, Text, View } from 'react-native'
import type { ModalBottoButtonListItem } from './types'

interface Props {
  visible?: boolean
  message: string
  submitText: string
  cancelText: string
  onCancel: () => void
  onClose: () => void
  onDismiss: () => void
  list: ModalBottoButtonListItem[]
  bottom?: number
  onStateChange?: (state: string) => void
}

const ConfirmButton = ({
  item,
  onClose,
  onStateChange,
}: {
  item: ModalBottoButtonListItem
  idx: number
  onClose: () => void
  onStateChange?: (state: string) => void
}) => {
  const confirmAction = item.action
  return (
    <Button
      onPress={() => {
        confirmAction && confirmAction()
        onClose()
        onStateChange && onStateChange(item.state ?? '')
      }}
      // backgroundColor="main.blue"
      style={[styles.button, styles.nextButton, { backgroundColor: COLOR.main.blue }]}
      text={
        <Text style={[styles.buttonText, styles.buttonNextText]}>
          {item.icon && <XEIcon name={item.icon} size="14" color={'mono.white'} style={styles.innericon} />}
          {item.name}
        </Text>
      }
    />
  )
}

function ModalBottomAlert({
  visible,
  message,
  cancelText,
  onCancel,
  onClose,
  onDismiss,
  list,
  bottom = 0,
  onStateChange,
}: Props) {
  const [containerWidth, setContainerWidth] = useState(0)
  const [messageWidth, setMessageWidth] = useState(0)
  const chartWidth = Dimensions.get('window').width
  const maxMessageWidth = 310

  return (
    <Modal animationType="slide" transparent visible={visible}>
      <View style={styles.container}>
        <View style={{ overflow: 'hidden', paddingTop: 5 }}>
          <View
            style={[
              styles.modalView,
              { bottom },
              chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles.modalViewV2,
              containerWidth <= 400 && styles.modalViewV2,
            ]}
            onLayout={(e) => {
              setContainerWidth(e.nativeEvent.layout.width)
            }}
          >
            <View
              style={[
                styles.messageTextContainer,
                chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles.messageTextContainerV2,
                containerWidth <= 400 && styles.messageTextContainerV2,
              ]}
            >
              <Text
                style={styles.messageText}
                onLayout={(e) => {
                  setMessageWidth(e.nativeEvent.layout.width)
                }}
              >
                {message}
              </Text>
            </View>
            <View
              style={[
                styles.buttonContainerWrap,
                chartWidth > maxMessageWidth && messageWidth > maxMessageWidth && styles.buttonContainerWrapV2,
                containerWidth <= 400 && styles.buttonContainerWrapV2,
              ]}
            >
              <View style={styles.buttonContainer}>
                {list.map((item, idx) => (
                  <ConfirmButton
                    item={item}
                    idx={idx}
                    key={`confirm-btn-${idx}`}
                    onClose={onClose}
                    onStateChange={onStateChange}
                  />
                ))}
              </View>
              <View style={styles.buttonContainer}>
                <Button
                  onPress={() => {
                    onCancel()
                    onDismiss()
                  }}
                  // backgroundColor="mono.white"
                  style={[styles.button, { backgroundColor: COLOR.mono.white }]}
                  text={<Text style={styles.buttonText}>{cancelText}</Text>}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    // @ts-ignore
    width: 'calc(100vw - 20px)',
    maxWidth: 800,
    justifyContent: 'flex-end',
    alignSelf: 'center',
    margin: 20,
    paddingBottom: 30,
  },
  modalView: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 4,
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: getRealColor('mono.white'),
  },
  modalViewV2: {
    flexDirection: 'column',
  },
  buttonContainerWrap: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainerWrapV2: {
    justifyContent: 'flex-end',
    marginRight: -8,
  },
  messageTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  messageTextContainerV2: {
    paddingVertical: 4,
    marginBottom: 8,
  },
  messageText: {
    ...FONT.txtSm,
    lineHeight: 20.72,
    color: getRealColor('mono.black'),
  },
  button: {
    paddingHorizontal: 12,
    paddingVertical: 7,
  },
  innericon: {
    marginRight: 2,
  },
  nextButton: {
    marginHorizontal: 5,
  },
  buttonNextText: {
    color: getRealColor('mono.white'),
  },
  buttonText: {
    ...FONT.subTextMedium,
    fontWeight: '400',
    lineHeight: 19.24,
    color: getRealColor('mono.black'),
  },
})

export default React.memo(ModalBottomAlert)
