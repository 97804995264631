import * as React from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet } from 'react-native'
import { useTableorderCatalog, useTableorderOrder, useTableorderShop, useTableorderTable } from '../providers'

import { View } from '@rocket-mono/foundations'
import type { TableorderCatalogMenu } from '@rocket/types'
import { Text, useToast } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { getNumberWithComma } from '../utils'

import OrderMenuButton from '../OrderMenuButton'
import TableorderHeader from '../TableorderHeader'

import TableorderOrderAdditionalBasketView from './components/TableorderOrderAdditionalBasketView'
import TableorderOrderAdditionalMenuView from './components/TableorderOrderAdditionalMenuView'
import TableorderOrderSuccessView from './components/TableorderOrderSuccessView'
import type { ViewProps } from './types'

const NUM_CULUMNS = 4
const ScreenView: React.FC<ViewProps> = ({ tableId, onPressClose }) => {
  const { show: showToastMessage } = useToast()

  const { shopId, shop } = useTableorderShop()
  const { tableList } = useTableorderTable()
  const { currentCatalog } = useTableorderCatalog()
  const { orderList } = useTableorderOrder()

  const [categoryId, setCategoryId] = React.useState('')

  const category = React.useMemo(
    () => currentCatalog?.categories.find(({ originId }) => originId === categoryId),
    [categoryId, currentCatalog],
  )
  const [menuId, setMenuId] = React.useState<string>()
  const [orderId, setOrderId] = React.useState<string>()
  const currentOrder = React.useMemo(
    () => orderList?.find(({ id }) => orderId !== undefined && id === orderId),
    [orderId, orderList],
  )

  const table = React.useMemo(() => tableList.find(({ id }) => id === tableId), [tableList, tableId])

  const tabList = React.useMemo(
    () =>
      currentCatalog?.categories.map(({ originId: id, name: title }) => ({
        id,
        title,
      })),
    [currentCatalog],
  )

  const filteredMenuList: TableorderCatalogMenu[] = React.useMemo(() => {
    if (!currentCatalog || !categoryId) return []

    const list = currentCatalog.categories.find(({ originId }) => originId === categoryId)?.menus || []

    return [...list, ...Array(NUM_CULUMNS - (list.length % NUM_CULUMNS)).fill({} as TableorderCatalogMenu)]
  }, [categoryId, currentCatalog])

  const renderItem = React.useCallback(({ item }: ListRenderItemInfo<TableorderCatalogMenu>) => {
    return (
      <View style={[{ flex: 1, margin: 4 }, item.id !== undefined && { backgroundColor: COLOR.mono.white }]}>
        {item.id !== undefined && (
          <OrderMenuButton
            soldout={item.isSoldout}
            menuName={item.name}
            price={getNumberWithComma(item.price)}
            time={item.estimatedCookingMinute}
            onPress={() => setMenuId(item.originId)}
          />
        )}
      </View>
    )
  }, [])

  const keyExtractor = React.useCallback(
    (item: TableorderCatalogMenu, index: number) => `${item?.originId || index}`,
    [],
  )

  const handlePaymentSuccess = React.useCallback((orderId: string) => {
    setOrderId(orderId)
  }, [])

  React.useEffect(() => {
    if (currentCatalog && currentCatalog.categories.length > 0) setCategoryId(currentCatalog.categories[0].originId)
  }, [currentCatalog])

  React.useEffect(() => {
    if (currentOrder && ['COOKING', 'SERVING_DONE'].includes(currentOrder.status)) {
      showToastMessage({
        type: 'Info',
        title: '이미 조리중입니다',
        position: 'TOP_RIGHT',
      })
      setTimeout(() => {
        window.location.reload()
      }, 500)
    }
  }, [currentOrder])

  if (currentOrder && ['DONE'].includes(currentOrder.status)) {
    return <TableorderOrderSuccessView tableId={tableId} orderId={currentOrder.id} onPressClose={onPressClose} />
  } else
    return (
      <View style={styles.container}>
        <TableorderHeader
          storeName={shop?.name || ''}
          tableName={table?.name || ''}
          tabList={tabList}
          tabCode={categoryId}
          tabOnCode={setCategoryId}
          onPress={onPressClose}
          iconName="close"
        />
        <View style={{ flex: 1, flexDirection: 'row' }}>
          <View
            style={{
              flex: 1,
              backgroundColor: COLOR.mono.paleWhite,
              paddingTop: 20,
              paddingHorizontal: 20,
            }}
          >
            {!!categoryId && !!menuId && (
              <TableorderOrderAdditionalMenuView
                catalogCategoryId={categoryId}
                menuId={menuId}
                onPressClose={() => setMenuId(undefined)}
              />
            )}
            <Text fontName="subTitleBold" fontColor="mono.black">
              {category?.name || ''}
            </Text>
            <FlatList
              style={{ flex: 1, marginTop: 4 }}
              numColumns={NUM_CULUMNS}
              data={filteredMenuList}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
            />
          </View>
          <View style={{ width: 316, backgroundColor: COLOR.mono.white }}>
            <TableorderOrderAdditionalBasketView
              shopId={shopId}
              tableId={tableId}
              onPaymentSuccess={handlePaymentSuccess}
            />
          </View>
        </View>
      </View>
    )
}

export default ScreenView

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
