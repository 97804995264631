import { Switch } from '@rocket-atoms/toggle'
import { DateRangePicker } from '@rocket-molecules/date'
import { COLOR, FONT, changeItemAtIndex, useMobileView } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { WorkmodelDragList } from '../WorkmodelDragList'
import type { UpdateWorkModelWorkflowProps } from './types'

const EditWorkModelWorkflow = ({
  data,
  isDate = true,
  onChangeTitle,
  onChangeProjectElements,
  onChangeRangeDate,
  onChangePreText,
  onChangeEndText,
  onPressAdd,
  onPressDelete,
  onChangeElement,
  onPressDeleteInfo,
}: UpdateWorkModelWorkflowProps) => {
  const { t } = useTranslation()
  const { title, projectElements, rangeDate, preText, endText } = data
  const [activated, setActivated] = React.useState(rangeDate.startDate || rangeDate.endDate ? true : false)
  const [contentsHeight, setContentsHeight] = React.useState(0)
  const mobileView = useMobileView(500)
  const [scrollEnabled, setScrollEnabled] = React.useState(true)

  const dragList = React.useMemo(
    () =>
      projectElements.map((o) => ({
        text: o.name,
        isDelete: o.isDelete,
      })),
    [projectElements],
  )

  const onChangeList = React.useCallback(
    (from: number, to: number) => {
      onChangeProjectElements(changeItemAtIndex(projectElements, from, to))
    },
    [projectElements],
  )

  const endInputRef = React.useRef<TextInput>(null)
  const onPressEndTab = React.useCallback(() => {
    endInputRef.current?.focus()
  }, [endInputRef])

  React.useEffect(() => {
    if (!activated && rangeDate.startDate !== undefined && rangeDate.endDate !== undefined) {
      onChangeRangeDate &&
        onChangeRangeDate({
          startDate: undefined,
          endDate: undefined,
        })
    }
  }, [activated])

  return (
    <View style={[styles.container, !!mobileView && styles.mobileContainer]}>
      <ScrollView scrollEnabled={scrollEnabled} style={[styles.workInfoSection, !mobileView && { minHeight: 300 }]}>
        <View>
          <View>
            <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodeledit.workname')}</Text>
            <TextInput
              value={title}
              placeholder={t('workmodal.basic.inputplaceholder')}
              maxLength={50}
              onChangeText={onChangeTitle}
              style={styles.inputStyle}
            />
            <Text
              style={{
                ...FONT.txt2Xs,
                color: COLOR.gray.g500,
                textAlign: 'right',
              }}
            >
              ({title.length}/500)
            </Text>
          </View>
          <View style={[styles.labelSection, mobileView ? { paddingBottom: 50 } : { paddingBottom: 32 }]}>
            <View>
              <Text style={styles.labelName}>{t('workmodeledit.wfp.part')}</Text>
            </View>
            <>
              <View
                onLayout={(e) => {
                  setContentsHeight(e.nativeEvent.layout.height)
                }}
              >
                <View>
                  <View
                    style={[
                      styles.flowLine,
                      {
                        height: contentsHeight - 40,
                        marginTop: -(contentsHeight - 30) / 2,
                      },
                    ]}
                  />
                  <View style={styles.flowGroup}>
                    <View style={[styles.groupLabel, mobileView && styles.groupLabelMobile]}>
                      <View
                        style={[
                          styles.labelRing,
                          mobileView && styles.labelRingMobile,
                          { borderColor: COLOR.main.yellow },
                        ]}
                      ></View>
                    </View>
                    <View
                      style={[
                        styles.groupWrap,
                        {
                          paddingBottom: 12,
                          borderBottomWidth: 1,
                        },
                      ]}
                    >
                      <Text style={{ ...FONT.txtSmMedium, marginBottom: 4 }}>{t('workmodal.wfp.preparation')}</Text>
                      <View style={{ paddingRight: 32 }}>
                        <TextInput
                          value={preText}
                          style={[
                            styles.inputStyle,
                            //   fieldError &&
                            //     preText === '' && {
                            //       borderColor: COLOR.status.busy,
                            //     },
                          ]}
                          placeholder={t('workmodal.wfp.preparationplaceholder')}
                          placeholderTextColor={COLOR.gray.g400}
                          textAlignVertical="center"
                          underlineColorAndroid="transparent"
                          onChangeText={onChangePreText}
                          maxLength={30}
                        />
                      </View>
                    </View>
                  </View>
                  <View style={[styles.flowGroup, { minHeight: 210, paddingVertical: 12 }]}>
                    <View style={[styles.groupLabel, mobileView && styles.groupLabelMobile]}>
                      <View
                        style={[
                          styles.labelRing,
                          mobileView && styles.labelRingMobile,
                          { borderColor: COLOR.main.turquoise },
                        ]}
                      ></View>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          ...FONT.txtSmMedium,
                          marginLeft: 28,
                          marginBottom: 4,
                        }}
                      >
                        {t('workmodal.wfp.working')}
                      </Text>
                      <WorkmodelDragList
                        editType={true}
                        list={dragList}
                        setScrollEnabled={setScrollEnabled}
                        onPressAdd={onPressAdd}
                        onPressEndTab={onPressEndTab}
                        onClickRemoveButton={onPressDelete}
                        onChangeElement={onChangeElement}
                        onChangeList={onChangeList}
                        onPressDeleteInfo={onPressDeleteInfo}
                      />
                    </View>
                  </View>
                  <View style={styles.flowGroup}>
                    <View
                      style={[
                        styles.groupLabel,
                        mobileView && styles.groupLabelMobile,
                        mobileView
                          ? {
                              marginTop: 18,
                              height: 48,
                              backgroundColor: COLOR.mono.white,
                              justifyContent: 'flex-start',
                            }
                          : { marginTop: 12 },
                      ]}
                    >
                      <View
                        style={[
                          styles.labelRing,
                          mobileView && styles.labelRingMobile,
                          { borderColor: COLOR.main.blue },
                        ]}
                      ></View>
                    </View>
                    <View
                      style={[
                        styles.groupWrap,
                        {
                          paddingTop: 12,
                          borderTopWidth: 1,
                        },
                      ]}
                    >
                      <Text style={{ ...FONT.txtSmMedium, marginBottom: 4 }}>{t('workmodal.wfp.complete')}</Text>
                      <View style={{ paddingRight: 32 }}>
                        <TextInput
                          ref={endInputRef}
                          value={endText}
                          style={[
                            styles.inputStyle,
                            //   fieldError &&
                            //     endText === '' && {
                            //       borderColor: COLOR.status.busy,
                            //     },
                          ]}
                          placeholder={t('workmodal.wfp.completeplaceholder')}
                          placeholderTextColor={COLOR.gray.g400}
                          textAlignVertical="center"
                          underlineColorAndroid="transparent"
                          onChangeText={onChangeEndText}
                          maxLength={30}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </>
          </View>
        </View>
      </ScrollView>
      {isDate ? (
        <View style={styles.bottomFixedSection}>
          <View style={styles.dateSection}>
            <View style={[styles.flexBox, { alignItems: 'center' }]}>
              <View>
                <Text style={styles.labelName}>{t('workmodeledit.datesection')}</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Switch size="sm" activated={activated} onChange={setActivated} />
              </View>
            </View>
            {activated ? <DateRangePicker rangeDate={rangeDate} onChangeDate={onChangeRangeDate} height={37} /> : <></>}
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export default EditWorkModelWorkflow

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  mobileContainer: {
    flexDirection: 'column',
  },
  workInfoSection: {
    flex: 1,
  },
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentBox: {
    flex: 1,
    position: 'relative',
    justifyContent: 'flex-start',
  },
  sectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  labelSection: {
    marginTop: 12,
  },
  labelName: {
    ...FONT.txtSmMedium,
    marginBottom: 8,
  },
  inputStyle: {
    ...FONT.txtSm,
    width: '100%',
    height: 36,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
  },
  flowLine: {
    position: 'absolute',
    top: '50%',
    left: 6,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g200,
  },
  flowGroup: {
    flexDirection: 'row',
  },
  groupLabel: {
    width: 16,
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  groupLabelMobile: {
    marginLeft: -30,
    width: 44,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  groupWrap: {
    flex: 1,
    marginLeft: 28,
    borderColor: COLOR.gray.g100,
  },
  labelRing: {
    width: 14,
    height: 14,
    borderRadius: 30,
    borderWidth: 3,
    backgroundColor: COLOR.mono.white,
    marginRight: 6,
  },
  labelRingMobile: {
    marginRight: 0,
  },
  dateSection: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingTop: 12,
  },
  bottomFixedSection: {
    justifyContent: 'flex-end',
  },
})
