import * as React from 'react'
import { StyleSheet, TextInput } from 'react-native'

import { DialogView, View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Button } from './Button'
import OrderConfirmButton from './Button/OrderConfirmButton'
import OrderDurationControlButton from './Button/OrderDurationControlButton'
import type { OrderItemProps } from './OrderItem'
import OrderItem from './OrderItem'

interface Props {
  headerVisible?: boolean
  tableName: string
  orderDate: Date
  menuList: OrderItemProps[]
  price: number
  onPressCancel: () => void
  onPressStart: (minute: number) => void
}
const OrderRecipt = ({
  headerVisible = true,
  tableName,
  orderDate,
  menuList,
  price,
  onPressCancel,
  onPressStart,
}: Props) => {
  const { t } = useTranslation()
  const [duration, setDuration] = React.useState(menuList.reduce((value, cur) => value + cur.estimatedCookingMinute, 0))
  const [visible, setVisible] = React.useState(false)
  const minusDuration = React.useCallback(() => {
    setDuration((prev) => {
      const value = prev - 10
      return value < 0 ? 0 : value
    })
  }, [])
  const plusDuration = React.useCallback(() => {
    setDuration((prev) => prev + 10)
  }, [])

  const handlePressStart = React.useCallback(() => {
    onPressStart(duration)
  }, [duration])

  return (
    <>
      <View style={styles.container}>
        <View style={styles.innerContainer}>
          {headerVisible && (
            <View style={[styles.tableInfoTitle, styles.flexBox]}>
              <View>
                <Text style={styles.tableName}>{tableName}</Text>
                <Text style={styles.orderDate}>
                  {t('format.date.T', { date: new Date(orderDate) })} ({moment().diff(orderDate, 'minutes')}분 전) 주문
                </Text>
              </View>
              <Button borderColor="gray.g200" backgroundColor="gray.g050" color="gray.g600" onPress={onPressCancel}>
                주문취소
              </Button>
            </View>
          )}
          <View>
            <View style={[styles.orderListHeader, styles.flexBox]}>
              <View style={[styles.flexBox, { alignItems: 'center' }]}>
                <Text style={{ ...FONT.txtSm }}>주문 내용 </Text>
                <Text style={{ ...FONT.txtSm }}>{menuList.length}개</Text>
              </View>
              <Text style={{ ...FONT.txtMdBold }}>{price}원</Text>
            </View>

            <View style={[styles.menuList]}>
              {menuList.map((item, idx) => (
                <OrderItem item={item} key={`menuList-${idx}`} />
              ))}
            </View>
          </View>
        </View>
        <View style={styles.startControl}>
          <View style={[styles.columFlex]}>
            <View>
              <TextInput
                value={String(duration)}
                style={{
                  width: '100%',
                  height: 36,
                  textAlign: 'right',
                  paddingLeft: 84,
                  paddingRight: 8,
                  ...FONT.txtMdBold,
                  backgroundColor: COLOR.mono.white,
                  borderWidth: 1,
                  borderColor: COLOR.mono.gray,
                  borderRadius: 4,
                }}
              />
              <Text style={styles.controlUnit}>조리 시간(분)</Text>
            </View>

            <View style={[styles.flexBox, { marginTop: 8 }]}>
              <OrderDurationControlButton
                iconName={'minus'}
                text={'10분'}
                onPress={() => minusDuration()}
                style={{ marginRight: 8 }}
              />
              <OrderDurationControlButton iconName={'plus'} text={'10분'} onPress={() => plusDuration()} />
            </View>
          </View>
          <View style={[styles.columFlex]}>
            <View style={{ marginLeft: 20 }}>
              <OrderConfirmButton name={'조리 시작'} onPress={handlePressStart} />
            </View>
          </View>
        </View>
      </View>
      <DialogView
        title={'주문을 취소하시겠습니까?'}
        message={`취소하면 주문 카드를 삭제하고\n고객의 결제도 취소합니다.\n금액은 자동으로 환불됩니다.`}
        list={[{ name: '주문 취소', action: () => console.log('취소') }]}
        cancelText="취소"
        visible={visible}
        onCancel={() => console.log('test')}
        onClose={() => setVisible(false)}
        onDismiss={() => setVisible(false)}
      />
    </>
  )
}

export default OrderRecipt

const styles = StyleSheet.create({
  container: {
    borderLeftWidth: 1,
    borderColor: COLOR.gray.g300,
    width: 315,
    height: '100%',
    backgroundColor: COLOR.mono.white,
  },
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  innerContainer: {
    flexDirection: 'column',
    height: '100%',
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  tableInfoTitle: {
    height: 75,
  },
  tableName: {
    ...FONT.H5Bold,
  },
  orderDate: {
    ...FONT.txtSm,
    color: COLOR.gray.g600,
  },
  orderListHeader: {
    borderBottomWidth: 1,
    borderColor: COLOR.mono.black,
    paddingBottom: 4,
  },
  startControl: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: COLOR.gray.g050,
    padding: 12,
  },
  columFlex: {
    flex: 1,
  },
  buttonControl: {
    flex: 1,
    alignItems: 'center',
    height: 36,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  controlUnit: {
    ...FONT.txtSm,
    position: 'absolute',
    marginTop: 10,
    left: 8,
  },
  menuList: {
    overflow: 'scroll',
    height: 450,
    // scrollBarWidth: 'none',
  },
})
