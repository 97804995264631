import React, { useCallback } from 'react'
import { View } from 'react-native'
import { useLocation, useNavigate } from 'react-router-dom'

import { useToast } from '@rui/atoms'

import { useAstro, useWork } from '@rocket-mono/providers'
import WorkspaceScreen from '@rocket-screens/workspace'
import { useTranslation } from 'react-i18next'

const Home: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { show: showToastMessage } = useToast()
  const { astroNav } = useAstro()
  const { projectList, addRecent } = useWork()

  const projectPath = useCallback(
    (naviType: string) => {
      const path = pathname.split('/').pop() || ''
      if (naviType === 'TABLEORDER') return ['member', 'pos', 'menu'].includes(path) ? path : 'pos'
      return ['user', 'board', 'calendar'].includes(path) ? path : 'board'
    },
    [pathname],
  )

  const handlePressWork = useCallback(
    (projectId: string) => {
      console.debug('handlePressWork', { projectId })
      const project = projectList?.find(({ id }) => id === projectId)
      if (!project) {
        showToastMessage({
          type: 'Info',
          title: t('cardtodoedit.toast.delete'),
          position: 'BOTTOM_CENTER',
        })
      } else {
        const type = project?.type.code === 'TOP' ? 'TABLEORDER' : 'DEFAULT'
        if (projectId) {
          addRecent(projectId)
          navigate(`/project/${projectId}/${projectPath(type)}`)
        }
      }
    },
    [projectList],
  )
  const handlePressWorkCreate = useCallback((type?: string) => {
    console.debug('handlePressWorkCreate')
    astroNav.emit('project', {
      type: 'create_position',
      params: { type },
    })
  }, [])

  return (
    <View style={{ flex: 1 }}>
      <WorkspaceScreen i18n={i18n} onPressCreateWork={handlePressWorkCreate} onPressProject={handlePressWork} />
    </View>
  )
}

export default Home
