import { XEIcon } from '@rui/icons'
import { WorkModelItemProps, WorkModelList, WorkModelSectionType } from '../../components'

import { useCurrentUser } from '@rocket-mono/providers'
import { useWindowDimensions } from 'react-native'

import { Modal, View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { WorkIcon } from '@rocket/atoms'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  onPressCreateWork: (type: string) => void
  onPressClose: () => void
}

export const WorkmodelCreateView: React.FC<Props> = ({ onPressCreateWork, onPressClose }) => {
  const { t, i18n } = useTranslation()

  const { currentUser } = useCurrentUser()

  const businessData = useMemo(() => {
    const data: WorkModelItemProps[] = [
      {
        id: '3',
        title: t('workmodel.business.csp.title'),
        description: t('workmodel.business.csp.description'),
        iconProps: {
          icon: <WorkIcon.CSP size={24} />,
          backgroundColor: 'main.blue',
        },
        imageProps: {
          width: 164,
          marginRight: 20,
          uri: 'https://rocket.is/files/file_box/69e6749834cc477cb426c2506704180d.png',
        },
        labelProps: {
          list: [
            t('workmodel.en'),
            t('workmodel.es'),
            t('workmodel.de'),
            t('workmodel.ko'),
            t('workmodel.vi'),
            t('workmodel.mn'),
            t('workmodel.jp'),
          ],
        },
        onPress: () => {
          onPressCreateWork('CSP')
        },
      },
    ]
    data.push({
      id: '4',
      title: t('workmodel.business.top.title'),
      description: t('workmodel.business.top.description'),
      iconProps: {
        icon: <WorkIcon.TOP size={24} />,
        backgroundColor: 'sub.darkPurple',
      },
      imageProps: {
        width: 164,
        marginRight: 20,
        uri: 'https://rocket.is/files/file_box/b4f3cab221d7425d9e4a87ee763ba64a.png',
      },
      labelProps: {
        list: [
          t('workmodel.en'),
          t('workmodel.es'),
          t('workmodel.de'),
          t('workmodel.ko'),
          t('workmodel.vi'),
          t('workmodel.mn'),
          t('workmodel.jp'),
        ],
      },
      onPress: () => {
        onPressCreateWork('TOP')
      },
    })
    return data
  }, [currentUser])

  const { width } = useWindowDimensions()

  const [workModelList, setWorkModelList] = useState<WorkModelSectionType[]>([
    {
      title: t('workmodel.product.category'),
      isFold: false,
      data: [
        {
          id: '1',
          title: t('workmodel.product.dsp.title'),
          description: t('workmodel.product.dsp.description'),
          iconProps: {
            icon: <WorkIcon.DSP size={24} />,
            backgroundColor: 'main.navy',
          },
          imageProps: {
            width: 164,
            marginRight: 20,
            uri: 'https://rocket.is/files/file_box/692e2d68a21944498cbce1927933e8d5.png',
          },
          labelProps: {
            list: [
              t('workmodel.en'),
              t('workmodel.es'),
              t('workmodel.de'),
              t('workmodel.ko'),
              t('workmodel.vi'),
              t('workmodel.mn'),
              t('workmodel.jp'),
            ],
          },
          onPress: () => {
            onPressCreateWork('DSP')
          },
        },
        {
          id: '2',
          title: t('workmodel.product.wfp.title'),
          description: t('workmodel.product.wfp.description'),
          iconProps: {
            icon: <WorkIcon.WFP size={24} />,
            backgroundColor: 'main.red',
          },
          imageProps: {
            width: 164,
            marginRight: 20,
            uri: 'https://rocket.is/files/file_box/348bf215f84540d29bd8f171c75e0e5b.png',
          },
          labelProps: {
            list: [
              t('workmodel.en'),
              t('workmodel.es'),
              t('workmodel.de'),
              t('workmodel.ko'),
              t('workmodel.vi'),
              t('workmodel.mn'),
              t('workmodel.jp'),
            ],
          },
          onPress: () => {
            onPressCreateWork('WFP')
          },
        },
      ],
    },
    {
      title: t('workmodel.business.category'),
      isFold: false,
      data: businessData,
    },
  ])

  return (
    <View
      style={[
        { width: 560, backgroundColor: COLOR.mono.white, borderRadius: 8 },
        560 > width && { width: '100%', minWidth: '100%' },
      ]}
    >
      <Modal.Header
        textAlign="left"
        closeIcon={<XEIcon name={'close'} size={20} color="gray.g700" />}
        onPressClose={onPressClose}
      >
        {t('nav.makework')}
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <WorkModelList
          title={t('workmodel.subtitle')}
          list={workModelList}
          onPressSection={(idx, isFold) => {
            setWorkModelList((prev) =>
              prev.map((item, index) => {
                if (index === idx) {
                  return {
                    ...item,
                    isFold,
                  }
                } else {
                  return item
                }
              }),
            )
          }}
        />
      </Modal.Body>
    </View>
  )
}
