import { View } from '@rocket-mono/foundations'
import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import { Channel } from '@rocket/types'
import React from 'react'
import { StyleSheet, ViewStyle } from 'react-native'
import type { MemberInviteItemType } from '../InviteMemberItem'
import InviteMemberItem from '../InviteMemberItem'

interface Props {
  secureCdnUrl?: string
  list?: MemberInviteItemType[]
  containerStyle?: ViewStyle
  unarchiveList?: Channel[]
  archiveList?: Channel[]
  handleRemove: (index: number) => void
  handleValueChange: (idx: number, key: string, value: string, type?: string) => void
  handleBoardPopup: (index: number, selectedBoardList: string[]) => void
}

const MemberInviteList: React.FC<Props> = ({
  secureCdnUrl,
  list,
  unarchiveList,
  archiveList,
  handleRemove,
  handleValueChange,
  handleBoardPopup,
}) => {
  // const { t } = useTranslation()
  // const dimensions = Dimensions.get('window')
  // const [authority, setAuthority] = useState('default')
  // const [isPriorityInvite, setIsPriorityInvite] = useState(false)

  return (
    <View>
      {list?.map((item, idx) => (
        <InviteMemberItem
          key={`invite-member-item-${item.key}`}
          item={item}
          idx={idx}
          // authorityValue={authority}
          // renderAuthorityPop={renderAuthorityContents()}
          // renderBoardPop={renderBoardPopContents({ index: idx, item })}
          secureCdnUrl={secureCdnUrl}
          unarchiveList={unarchiveList}
          archiveList={archiveList}
          handleRemove={handleRemove}
          handleValueChange={(key, value, type) => handleValueChange(idx, key, value, type)}
          handleBoardPopup={(list) => handleBoardPopup(idx, list)}
        />
      ))}
    </View>
  )
}

export default MemberInviteList

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    paddingVertical: 12,
  },
  contentsArea: {
    flex: 1,
    marginLeft: 8,
  },
  nameInfoArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nameInfo: {
    flex: 1,
    marginLeft: 4,
    marginRight: 8,
  },
  nameInput: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  emailText: {
    ...FONT.txtXs,
    color: COLOR.gray.g500,
  },
  settingArea: {
    flexDirection: 'row',
    paddingRight: 40,
    marginTop: 8,
  },
  deleteButton: {
    margin: 4,
  },
  editButton: {
    padding: 4,
  },
  popover: {
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingVertical: 4,
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 0),
    zIndex: 100,
  },
})
