import * as React from 'react'
import { StyleSheet, Text, View, ViewStyle } from 'react-native'

import { COLOR, FONT, IconName } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import { Button } from '../Button'
import ButtonIcon from '../ButtonIcon'

export interface HeaderProps {
  title: string
  textAlignCenter?: boolean
  buttonBack?: boolean
  buttonClose?: boolean
  leftIconName?: IconName
  rightIconButtonName?: IconName
  rightTextButton?: string
  onBack?: () => void
  onDismiss?: () => void
  onPressRight?: () => void
  isBorder?: boolean
  containerStyle?: ViewStyle
}

const Header = ({
  title,
  textAlignCenter = false,
  buttonBack,
  buttonClose,
  leftIconName,
  rightIconButtonName,
  rightTextButton,
  onBack,
  onDismiss,
  onPressRight,
  isBorder = true,
  containerStyle,
}: HeaderProps) => {
  const [isHover, setIsHover] = React.useState(false)
  return (
    <View style={[styles.container, isBorder ? styles.border : {}, containerStyle]}>
      <View style={[styles.leftContainer, { zIndex: 10 }]}>
        {leftIconName && (
          <View>
            <XEIcon name={leftIconName} color="mono.black" size="20" />
          </View>
        )}

        {buttonBack && <ButtonIcon iconName="angle-left" iconSize={22} onPress={onBack} />}
      </View>
      <View style={[styles.titleContainer, textAlignCenter ? { alignItems: 'center' } : { alignItems: 'flex-start' }]}>
        <Text style={styles.titleText}>{title}</Text>
      </View>
      <View style={styles.rightContainer}>
        {rightIconButtonName && <ButtonIcon iconName={rightIconButtonName} iconSize={22} onPress={onPressRight} />}

        {buttonClose && <ButtonIcon iconName="close" iconSize={20} onPress={onDismiss} />}

        {rightTextButton && (
          <Button
            size="medium"
            backgroundColor="transparent.base"
            onHoverIn={() => setIsHover(true)}
            onHoverOut={() => setIsHover(false)}
            style={[isHover && { backgroundColor: COLOR.mono.paleWhite }]}
          >
            <Text style={styles.rightButtonText} onPress={onPressRight}>
              {rightTextButton}
            </Text>
          </Button>
        )}
      </View>
    </View>
  )
}

export default Header

const styles = StyleSheet.create({
  container: {
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: COLOR.mono.white,
  },
  leftContainer: {
    position: 'absolute',
    left: 0,
  },
  titleContainer: {
    flex: 1,
    paddingHorizontal: 40,
  },
  titleText: {
    ...FONT.H7Medium,
  },
  rightContainer: {
    position: 'absolute',
    right: 0,
    justifyContent: 'flex-end',
    zIndex: 10,
  },
  rightButtonText: {
    ...FONT.listRegular,
  },
  border: {
    borderBottomWidth: 1,
    borderBottomColor: COLOR.mono.lightGray,
  },
})
