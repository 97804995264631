import { i18n } from 'i18next'
import React from 'react'
import Providers from '../../providers'
import { WorkmodelCreateView } from './view'

interface Props {
  i18n: i18n
  onPressCreateWork: (type: string) => void
  onPressClose: () => void
}

export const WorkmodelCreateScreen: React.FC<Props> = ({ i18n, ...props }) => {
  return (
    <Providers i18n={i18n}>
      <WorkmodelCreateView {...props} />
    </Providers>
  )
}
