import { COLOR } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

interface Props {
  text: string
  required?: boolean
}
export const SectionLabel: React.FC<Props> = ({ text, required }) => {
  return (
    <View style={styles.sectionLabel}>
      <View style={styles.labelBlock}></View>
      <Text fontName="txtMdMedium">{text}</Text>
      {required && (
        <Text fontName="txtMdMedium" style={{ color: COLOR.main.red }}>
          *
        </Text>
      )}
    </View>
  )
}

export const styles = StyleSheet.create({
  sectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  labelBlock: {
    width: 3,
    height: 14,
    marginRight: 6,
    backgroundColor: COLOR.mono.black,
  },
})
