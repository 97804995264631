import { Button, TextField } from '@rui/atoms'
import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { NoContentsIcon, XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Platform, Pressable, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import type { EmailInviteMemberItem } from '../InviteMemberItem'
import InviteMemberItem from '../InviteMemberItem'

interface Props {
  list: EmailInviteMemberItem[]
  onPressAdd?: (item: EmailInviteMemberItem) => void
  onChangeItem?: (index: number, item: EmailInviteMemberItem) => void
  onPressDelete?: (index: number) => void
  onPressInvite?: () => void
  isEmailDescription?: boolean
  emailDescriptionPlaceholder?: string
}

const InviteMemberEmail = ({
  list,
  onPressAdd,
  onChangeItem,
  onPressDelete,
  onPressInvite,
  isEmailDescription = false,
  emailDescriptionPlaceholder = `ㅇㅇ님이 '기획됨' 보드에 초대합니다.`,
}: Props) => {
  const { t } = useTranslation()
  const chartWidth = Dimensions.get('window')
  const mobileView = Platform.OS === 'web' ? 425 > chartWidth.width : true
  const [inputNameValue, setInputNameValue] = React.useState<string>('')
  const [inputEmailValue, setInputEmailValue] = React.useState<string>('')
  const [errorNameFiled, setErrorNameFiled] = React.useState<string>('')
  const [errorMailFiled, setErrorMailFiled] = React.useState<string>('')
  const [validationEmail, setValidationEmail] = React.useState<boolean>(false)

  const inputNameRef = React.useRef<any>(null)
  const inputEmailRef = React.useRef<any>(null)

  const buttonActive = React.useMemo(() => {
    return list.length > 0
  }, [list])

  // 이메일 형식 체크
  const emailCheck = (email: string) => {
    const regEmail = new RegExp(/^([0-9a-zA-Z_\\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/)
    regEmail.exec(email) ? setValidationEmail(true) : setValidationEmail(false)
  }

  // const handleChangeNameChange = (
  //   e: NativeSyntheticEvent<TextInputChangeEventData>,
  // ) => {
  //   const value = e.nativeEvent.text
  //   setInputNameValue(value)
  // }
  // const handleInputNameChange = (_e: any) => {
  //   const ref = inputNameRef as React.RefObject<HTMLDivElement>
  //   if (ref && ref.current && Platform.OS === 'web') {
  //     const value = ref.current.innerText
  //     setInputNameValue(value)
  //   }
  // }
  // const handleChangeMailChange = (
  //   e: NativeSyntheticEvent<TextInputChangeEventData>,
  // ) => {
  //   const value = e.nativeEvent.text
  //   emailCheck(value)
  //   setInputEmailValue(value)
  // }
  // const handleInputMailChange = (_e: any) => {
  //   const ref = inputEmailRef as React.RefObject<HTMLDivElement>
  //   if (ref && ref.current && Platform.OS === 'web') {
  //     const value = ref.current.innerText
  //     emailCheck(value)
  //     setInputEmailValue(value)
  //   }
  // }

  const handleChangeMailChange = React.useCallback((value: string) => {
    emailCheck(value)
    setInputEmailValue(value)
  }, [])

  // 필드 값 검사
  const allChecked = () => {
    if (!inputNameValue) {
      setErrorNameFiled(t('emailinvitemember.error.name'))
    } else {
      setErrorNameFiled('')
    }
    if (!inputEmailValue) {
      setErrorMailFiled(t('emailinvitemember.error.email'))
    } else {
      validationEmail ? setErrorMailFiled('') : setErrorMailFiled(t('emailinvitemember.error.emailvalid'))
    }

    // 필드에 값이 있고 이메일 유효성 검사가 되었다면, 에러메시지 비우고 초대 버튼 동작
    if (inputNameValue && inputEmailValue && validationEmail) {
      onPressAdd &&
        onPressAdd({
          name: inputNameValue,
          email: inputEmailValue,
          editMode: false,
        })
      setErrorNameFiled('')
      setErrorMailFiled('')
      setInputNameValue('')
      setInputEmailValue('')
      if (Platform.OS === 'web') {
        const nameRef = inputNameRef as React.RefObject<HTMLDivElement>
        const mailRef = inputEmailRef as React.RefObject<HTMLDivElement>
        if (nameRef && nameRef.current && mailRef && mailRef.current) {
          nameRef.current.innerText = ''
          mailRef.current.innerText = ''
          nameRef.current.focus()
        }
      } else {
        const nameRef = inputNameRef as React.RefObject<TextInput>
        if (nameRef && nameRef.current) {
          nameRef.current.focus()
        }
      }
    }
  }

  //초대 버튼
  // const onPressInvite = () => {
  //   return console.log('초대하기 버튼')
  // }

  return (
    <View style={styles.container}>
      <View style={[styles.inviteMemberListArea]}>
        <View style={[styles.addMemberInput, mobileView && { flexDirection: 'column' }]}>
          <View style={[!mobileView ? { flex: 1 } : {}]}>
            <TextField
              value={inputNameValue}
              inputRef={inputNameRef}
              // height={36}
              placeholder={t('emailinvitemember.nameplaceholder')}
              autoFocus={true}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Enter') {
                  allChecked()
                }
              }}
              // onChange={(e) => handleChangeNameChange(e)}
              onChangeValue={setInputNameValue}
              // onInput={(e) => handleInputNameChange(e)}
              // errorMessage={errorNameFiled}
            />
          </View>
          <View style={[!mobileView ? { flex: 2, marginLeft: 8 } : { marginTop: 8 }]}>
            <TextField
              value={inputEmailValue}
              inputRef={inputEmailRef}
              // height={36}
              placeholder={t('emailinvitemember.emailplaceholder')}
              afterIcon={
                <View>
                  <Pressable onPress={() => allChecked()}>
                    <XEIcon name="search" color="gray.g700" size={20} />
                  </Pressable>
                </View>
              }
              // rightIcon={{
              //   name: 'plus',
              //   color: 'gray.g700',
              //   onPress: () => allChecked(),
              // }}
              // rightIconSize={20}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Enter') {
                  allChecked()
                }
              }}
              // onChange={(e) => handleChangeMailChange(e)}
              onChangeValue={handleChangeMailChange}
              // onInput={(e) => handleInputMailChange(e)}
              // errorMessage={errorMailFiled}
            />
          </View>
        </View>
        {list.length > 0 ? (
          <ScrollView>
            <View style={{ paddingBottom: 40 }}>
              {list.map((item, idx) => (
                <InviteMemberItem
                  key={`invite-member-item-${idx}`}
                  item={item}
                  idx={idx}
                  onPressDelete={(index) => {
                    onPressDelete && onPressDelete(index)
                  }}
                  onChangeItem={(item, index) => {
                    onChangeItem && onChangeItem(item, index)
                  }}
                  style={{
                    ...Platform.select({
                      native: { marginHorizontal: 24 },
                    }),
                  }}
                />
              ))}
            </View>
          </ScrollView>
        ) : (
          <View style={styles.blank}>
            <View>
              <NoContentsIcon
                type={'member'}
                textSize={'medium'}
                text={t('emailinvitemember.newmemberdescription')}
                iconWidth={115}
              />
            </View>
          </View>
        )}
      </View>
      <View>
        {isEmailDescription ? (
          <View style={[styles.inviteMessage, mobileView && styles.inviteMessageMobile]}>
            <TextInput
              multiline
              placeholder={emailDescriptionPlaceholder}
              placeholderTextColor={COLOR.gray.g450}
              style={{
                height: 72,
                borderWidth: 1,
                borderRadius: 4,
                borderColor: COLOR.gray.g200,
                backgroundColor: COLOR.mono.white,
                padding: 12,
              }}
            />
          </View>
        ) : (
          <></>
        )}
        {onPressInvite ? (
          <Button
            // wide={true}
            style={{ height: 50, borderRadius: 0, width: '100%' }}
            disabled={!buttonActive && true}
            onPress={() => {
              onPressInvite()
            }}
            text={
              <Text style={[styles.buttonText]}>
                {buttonActive
                  ? t('emailinvitemember.invitecount', {
                      count: list.length,
                    })
                  : t('emailinvitemember.invite')}
              </Text>
            }
          />
        ) : (
          <></>
        )}
      </View>
    </View>
  )
}

export default InviteMemberEmail

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: '100%',
    justifyContent: 'space-between',
  },
  inviteMemberListArea: { flex: 1 },
  addMemberInput: {
    flexDirection: 'row',
    marginBottom: 4,
    backgroundColor: COLOR.mono.white,
    ...Platform.select({
      native: {
        paddingHorizontal: 24,
      },
    }),
  },
  inviteMessage: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    backgroundColor: COLOR.gray.g050,
  },
  inviteMessageMobile: {
    backgroundColor: COLOR.mono.white,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    paddingVertical: 20,
    paddingHorizontal: 20,
    ...getBoxShadow(0, 0, 0, 0.05, 10, 0, -3, 0),
  },
  buttonText: {
    ...FONT.H7Bold,
    color: COLOR.mono.white,
  },
  blank: {
    flex: 1,
    minHeight: 300,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
