import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, Text, TextInput, View } from 'react-native'

interface Props {
  workType?: 'DSP' | 'WFP' | 'CSP'
  text: string
  admin: boolean
  onSubmit: (text: string) => void
}
const WorkGoals: React.FC<Props> = ({ workType, admin, text, onSubmit }) => {
  const { t } = useTranslation()
  const [content, setContent] = useState(text)
  const [editMode, setEditMode] = useState<boolean>(false)

  const goalInputRef = useRef<any>(null)
  useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (goalInputRef.current && !goalInputRef.current.contains(e.target as Node)) {
        setEditMode(false)
      }
    }
    if (Platform.OS === 'web') {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }
  }, [goalInputRef])

  useEffect(() => {
    if (editMode === false) {
      onSubmit(content)
    }
  }, [content, editMode])

  useEffect(() => {
    setContent(text)
  }, [text])

  return (
    <Pressable style={[styles.container, editMode && { backgroundColor: COLOR.primary.blue05 }]} ref={goalInputRef}>
      <Pressable style={styles.goals}>
        <XEIcon name="flag" size={20} color={'gray.g700'} style={{ position: 'absolute', left: 4, marginRight: 8 }} />
        <View style={styles.goalTextArea}>
          {editMode ? (
            <TextInput
              value={content}
              style={{ height: 36, paddingLeft: 32 }}
              autoFocus={true}
              onChangeText={setContent}
              onKeyPress={(e) => {
                if (e.nativeEvent.key === 'Enter') {
                  setEditMode(false)
                }
              }}
            />
          ) : (
            <Pressable onPressIn={() => admin && setEditMode(true)}>
              {text ? (
                <Text style={styles.goalText} numberOfLines={1} ellipsizeMode={'tail'}>
                  {text}
                </Text>
              ) : (
                <Text style={[styles.goalText, { color: COLOR.gray.g400 }]} numberOfLines={1} ellipsizeMode={'tail'}>
                  {workType === 'CSP' ? t('workmodal.csp.noticeplaceholder') : t('workmodal.basic.goalplaceholder')}
                </Text>
              )}
            </Pressable>
          )}
        </View>
      </Pressable>
      <View style={{ width: 32 }}>
        {admin && (
          <Pressable
            style={[styles.editButton, editMode && { backgroundColor: COLOR.main.blue }]}
            onPress={() => setEditMode(!editMode)}
          >
            <XEIcon name="pen" size={12} color={editMode ? 'mono.white' : 'gray.g700'} style={{ marginTop: -2 }} />
          </Pressable>
        )}
      </View>
    </Pressable>
  )
}

export default WorkGoals

const styles = StyleSheet.create({
  container: {
    width: '100%',
    // maxWidth: 755,
    marginRight: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 36,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 4,
  },
  goals: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  goalText: {
    ...FONT.txtSm,
    color: COLOR.gray.g700,
    paddingLeft: 32,
  },
  goalTextArea: {
    flex: 1,
  },
  editButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    marginRight: 8,
    backgroundColor: COLOR.gray.g150,
    borderRadius: 50,
  },
})
