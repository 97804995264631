import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Search from './Search'

import { Modal } from '@rocket-mono/foundations'
import type { ProjectMember } from '@rocket/types'
import { Button, Text } from '@rui/atoms'
import { getRealColor } from '@rui/foundations'
import { StyleSheet, View } from 'react-native'

export interface Props {
  memberList: ProjectMember[]
  onClickCloseButton: () => void
  onClickRequestButton: (userId: string) => void
}

const AUTH_PRIORITY = Object.freeze({
  OWNER: 0,
  MANAGER: 1,
  MEMBER: 2,
  GUEST: 3,
} as const)

const sortByAuth = (a: ProjectMember, b: ProjectMember): number => {
  return AUTH_PRIORITY[a.auth] - AUTH_PRIORITY[b.auth]
}

// const after7days = (): string => {
//   return moment().add(7, 'days').format('YY-MM-DD HH:mm')
// }

function WithdrawalModal({
  memberList: initialMemberList,
  onClickCloseButton,
  onClickRequestButton,
}: Props): JSX.Element {
  const { t } = useTranslation()
  const [selecteduUserId, setSelectedUserId] = React.useState('')

  return (
    <View style={styles.container}>
      <Modal.Header textAlign="center" onPressClose={onClickCloseButton}>
        {t('withdrawal.selectParticipant', '승인 대상 선정')}
      </Modal.Header>
      <Modal.Body isDirect>
        <Search
          sort={sortByAuth}
          title={t('withdrawal.explain', '나가기를 승인할 관리자를 선택해주세요')}
          memberList={initialMemberList}
          selectedUserId={selecteduUserId}
          setSelectedUserId={setSelectedUserId}
        />
        {/* <View style={styles.guideContainer}>
          <Text fontName="subTextRegular" fontColor="mono.darkGray">
            {t('withdrawal.guide', {
              date: after7days(),
            })}
          </Text>
        </View> */}
      </Modal.Body>
      <Modal.Footer height={80}>
        <View style={styles.footer}>
          <Button
            disabled={!selecteduUserId}
            onPress={() => {
              onClickRequestButton(selecteduUserId)
            }}
            text={<Text>{t('withdrawal.request', '요청')}</Text>}
          />
        </View>
      </Modal.Footer>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 505,
    height: 684,
    backgroundColor: getRealColor('mono.paleWhite'),
    borderRadius: 20,
  },
  // guideContainer: {
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   marginHorizontal: 20,
  // },
  footer: {
    flex: 1,
    height: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default WithdrawalModal
