import { LayoutNav, LayoutNavi } from '@rocket-screens/layout'
import React, { useCallback, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useAstro, useCurrentUser, useLayoutNav, useWork } from '@rocket-mono/providers'

const Nav: React.FC = () => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const { astroNav } = useAstro()
  const { currentUser } = useCurrentUser()

  const { projectCount, projectList, archiveList, guestList, favoriteList, addRecent, addFavorite, deleteFavorite } =
    useWork()
  const { visible, width, show, hide } = useLayoutNav()

  const [naviType, setNaviType] = useState<'DEFAULT' | 'TABLEORDER' | 'GUEST'>()

  const projectPath = useCallback(
    (naviType: string) => {
      const path = pathname.split('/').pop() || ''
      if (naviType === 'TABLEORDER') return ['member', 'pos', 'menu'].includes(path) ? path : 'pos'
      if (naviType === 'GUEST') return ['board'].includes(path) ? path : 'board'
      return ['user', 'board'].includes(path) ? path : 'board'
    },
    [pathname],
  )

  const handlePressWork = useCallback(
    (projectId: string, path?: string) => {
      const type = projectList?.find(({ id }) => id === projectId)?.type.code === 'TOP' ? 'TABLEORDER' : 'DEFAULT'
      console.log('handlePressWork', projectId, path, projectPath(type))
      setNaviType(type)
      if (projectId) {
        addRecent(projectId)
        navigate(`/project/${projectId}/${path ?? projectPath(type)}`)
      }
    },
    [projectList, projectPath],
  )

  const handlePressGuest = useCallback(() => {
    setNaviType('GUEST')
    navigate('/guest/board')
  }, [])

  const handlePressWorkCreate = useCallback(() => {
    astroNav.emit('project', {
      type: 'create_position',
      params: {},
    })
  }, [])

  useEffect(() => {
    if (projectId === undefined) {
      setNaviType('GUEST')
    } else {
      const type = projectList?.find(({ id }) => id === projectId)?.type.code === 'TOP' ? 'TABLEORDER' : 'DEFAULT'
      setNaviType(type)
    }
  }, [projectList])

  return (
    <>
      <View>
        <LayoutNav
          width={width}
          userId={String(currentUser.id)}
          projectCount={projectCount}
          projectList={projectList}
          archiveList={archiveList}
          guestList={guestList}
          favoriteList={favoriteList}
          onPressWork={handlePressWork}
          onPressGuest={handlePressGuest}
          onPressWorkCreate={handlePressWorkCreate}
          addFavorite={addFavorite}
          deleteFavorite={deleteFavorite}
        />
      </View>
      <View>
        {naviType !== undefined && (
          <LayoutNavi
            type={naviType}
            side={visible ? 'right' : 'left'}
            state={projectPath(naviType)}
            onSide={(side) => {
              side === 'left' ? hide() : show()
            }}
            onState={(state) => {
              if (projectId) handlePressWork(projectId, state)
            }}
          />
        )}
      </View>
    </>
  )
}

export default Nav
