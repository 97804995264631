import { View } from '@rocket-mono/foundations'
import { COLOR, useMobileView } from '@rocket-mono/libs'
import { useAstro, useCurrentUser, useModalDialog } from '@rocket-mono/providers'
import { Text } from '@rocket/atoms'
import { Button } from '@rui/atoms'
import { XEIcon } from '@rui/icons'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DimensionValue, StyleSheet } from 'react-native'
import { DropdownItemType, SettingProfile } from '../../components'
import SettingItem from './components/SettingItem'
import SettingTitle from './components/title'
import { langData } from './type'

export const stateData: DropdownItemType[] = [
  {
    labelIcon: { name: 'full-moon', size: 14, color: 'status.active' },
    label: 'Active',
    key: '1',
  },
  {
    labelIcon: { name: 'full-moon', size: 14, color: 'status.busy' },
    label: 'Busy',
    key: '2',
  },
  {
    labelIcon: { name: 'full-moon', size: 14, color: 'status.off' },
    label: 'Off',
    key: '3',
  },
]

interface Props {
  tab: string
  onPressItem: (tab: string) => void
  onPressBack?: () => void
  onLogout: () => void
  width?: DimensionValue
}
const SettingView: React.FC<Props> = ({ tab, width = 300, onPressItem, onPressBack, onLogout }) => {
  const isMobile = useMobileView(960)
  const { t } = useTranslation()

  const { astro, option } = useAstro()
  const { currentUser } = useCurrentUser()

  const { showDialogMessage } = useModalDialog()

  const userData = useMemo(() => {
    const { userName: name, userEmail: email, userState } = currentUser
    const profile = `${option.secureCdnUrl}/profile/${email}`
    return { name, email, profile, userState }
  }, [currentUser])

  const menuData = useMemo(
    () => [
      {
        title: t('setting.userinfo.title'), // '내 정보 수정',
        icon: 'user' as const,
        label: '',
        update: '',
        tab: 'edit',
      },
      // {
      //   title: t('setting.menu.social'), // '소셜 로그인 연동',
      //   icon: 'all' as const,
      //   label: '',
      //   update: '',
      //   tab: 'sns',
      // },
      {
        title: t('setting.language.title'), // '언어',
        icon: 'font' as const,
        label: langData.find(({ key }) => key === currentUser.lang)?.label,
        update: '',
        tab: 'lang',
      },
      {
        title: t('setting.timezone.title'), // '타임존',
        icon: 'time' as const,
        label: t('setting.timezone.auto'),
        update: '',
        tab: 'time',
      },
      // {
      //   title: t('setting.menu.notice'), // '공지사항 및 업데이트',
      //   icon: 'shield-checked' as const,
      //   label: '2.4v',
      //   update: '업데이트 (2023/11/11)',
      //   tab: 'notice',
      // },
      {
        title: t('setting.menu.logout'), // '로그아웃',
        icon: 'log-out' as const,
        label: '',
        update: '',
        tab: 'logout',
      },
    ],
    [t, currentUser],
  )

  const handlePressItem = useCallback((tab: string) => {
    if (tab === 'logout') {
      showDialogMessage({
        title: t('setting.logout.title'),
        message: t('setting.logout.message'),
        list: [
          {
            name: t('setting.logout.alllogout'),
            action: async () => {
              astro.logoutByAllSection().then(onLogout)
            },
          },
          {
            name: t('setting.logout.thislogout'),
            action: async () => {
              astro.logoutByCurrentSection().then(onLogout)
            },
          },
          {
            name: t('setting.logout.thiswidoutlogout'),
            action: async () => {
              astro.logoutByExceptCurrentSection()
            },
          },
        ],
        subList: [],
        cancelText: t('setting.logout.cancel'),
        onCancel: () => console.log('onCancel'),
      })
    } else {
      onPressItem(tab)
    }
  }, [])

  return isMobile ? (
    <View style={{ paddingHorizontal: 20 }}>
      <SettingTitle
        title={t('setting.title')}
        style={{ paddingLeft: 0 }}
        buttonIconElements={<Button onPress={onPressBack} text={<XEIcon name="close" size={20} color="gray.g700" />} />}
      />
      <SettingProfile data={userData} stateData={stateData} />
      <View style={styles.menu}>
        <SettingItem list={menuData} tab={tab} onPress={handlePressItem} />
      </View>
    </View>
  ) : (
    <View
      style={[
        { height: '100%', backgroundColor: COLOR.gray.g050, paddingHorizontal: 8, paddingVertical: 20 },
        { width },
      ]}
    >
      <Button
        style={[styles.button, { backgroundColor: COLOR.gray.g150 }]}
        onPress={onPressBack}
        text={
          <>
            <XEIcon name={'arrow-left'} size={16} color={'gray.g700'} />
            <Text>{t('setting.back')}</Text>
          </>
        }
      ></Button>
      <View style={styles.userInfo}>
        <SettingProfile data={userData} stateData={stateData} />
      </View>
      <View
        style={[
          styles.menu,
          {
            paddingTop: 20,
            borderTopWidth: 1,
            borderColor: COLOR.gray.g150,
          },
        ]}
      >
        <SettingItem list={menuData} tab={tab} onPress={handlePressItem} />
      </View>
    </View>
  )
}

export default SettingView

const styles = StyleSheet.create({
  menu: {
    paddingTop: 8,
  },
  userInfo: {
    marginTop: 32,
  },
  button: {
    flexDirection: 'row',
    paddingHorizontal: 12,
  },
})
