import { useContext } from 'react'
import { ChatsContext, ChatsProviderContext } from './ChatsProviderContext'

export const useChatsData = (): ChatsProviderContext => {
  const context = useContext<ChatsProviderContext | undefined>(ChatsContext)

  if (context === undefined) throw new Error('There must be a ChatsProvider as Ancestor of all Astro Hooks and HOCs')

  return context
}
