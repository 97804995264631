import { useMobileView } from '@rocket-mono/libs'
import * as React from 'react'
import { Platform, StyleSheet, TextInput, View } from 'react-native'

import { Icon } from '@rocket-atoms/icon'
import { Switch } from '@rocket-atoms/toggle'
import { DateRangePicker } from '@rocket-molecules/date'
import type { IColors } from '@rocket-mono/libs'
import { COLOR, FONT, changeItemAtIndex } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { WorkmodelDragList } from '../../WorkmodelDragList'
import WorkmodelPreviewHierarchy from '../../WorkmodelPreview/WorkmodelPreviewHierarchy'
import Container from '../container'
import { Header } from '../header'
import LayoutView from '../layout'
import type { CreateWorkModelHierarchyProps } from '../types'

const CreateWorkModelHierarchy = ({
  data,
  titleError,
  step,
  keyboardHeight,
  setStep,
  onPressNext,
  onPressBack,
  onChangeTitle,
  onChangeGoal,
  onChangeProjectElements,
  onChangeElement,
  onChangeRangeDate,
  onPressCreate,
  onPressAdd,
  onPressRemove,
}: CreateWorkModelHierarchyProps) => {
  const { t } = useTranslation()
  const { title, goal, projectElements, rangeDate } = data

  console.log('rangeDate', rangeDate)

  const stepper = [
    { step: 1, title: t('workmodal.basic.basesetting'), checked: false },
    { step: 2, title: t('workmodal.dsp.part'), checked: false },
  ]

  const [activated, setActivated] = React.useState(false)
  const [scrollEnabled, setScrollEnabled] = React.useState(true)
  const mobileView = useMobileView(1000)

  const dragList = React.useMemo(
    () =>
      projectElements.map((o) => ({
        text: o.name,
        labelColor: o.labelObject as IColors,
      })),
    [projectElements],
  )

  const previewList = React.useMemo(
    () =>
      projectElements.map((o) => ({
        title: o.name,
        labelColor: o.labelObject as IColors,
      })),
    [projectElements],
  )

  const [fieldError, setFieldError] = React.useState(false)
  const { show: showToastMessage } = useToast()

  const handlePressCreate = React.useCallback(() => {
    if (projectElements.filter((o) => o.name === '').length > 0) {
      setFieldError(true)
      showToastMessage({
        type: 'Danger',
        title: t('workmodal.error.subname'),
        position: 'BOTTOM_CENTER',
      })
    } else {
      setFieldError(false)
      onPressCreate()
    }
  }, [onPressCreate, projectElements])

  const onChangeList = React.useCallback(
    (from: number, to: number) => {
      onChangeProjectElements(changeItemAtIndex(projectElements, from, to))
    },
    [projectElements],
  )

  return (
    <Container
      isValid={true}
      step={step}
      submitStep={2}
      onPressNext={onPressNext}
      onPressBack={onPressBack}
      onPressCreate={handlePressCreate}
    >
      <Header
        title={t('workmodal.dsp.title')}
        info={t('workmodal.basic.settingchangedesc')}
        stepper={stepper}
        step={step}
        setStep={setStep}
      />
      <LayoutView
        isValid={true}
        step={step}
        submitStep={2}
        scrollEnabled={scrollEnabled}
        keyboardHeight={keyboardHeight}
        onPressNext={onPressNext}
        onPressBack={onPressBack}
        onPressCreate={handlePressCreate}
        previewElement={
          <WorkmodelPreviewHierarchy
            mobileView={mobileView}
            title={title}
            goal={goal || ''}
            list={previewList}
            step={step}
          />
        }
      >
        <View style={{ paddingHorizontal: 4 }}>
          {step === 1 ? (
            <>
              <View>
                <View style={styles.sectionLabel}>
                  <View style={styles.labelBlock}></View>
                  <Text style={styles.labelName}>{t('workmodal.basic.name')}</Text>
                  <Text style={[styles.labelName, { color: COLOR.main.red }]}>*</Text>
                </View>
                <View>
                  <TextInput
                    value={title}
                    style={[styles.inputStyle, titleError && { borderColor: COLOR.status.busy }]}
                    placeholder={t('workmodal.basic.nameplaceholder')}
                    placeholderTextColor={COLOR.gray.g400}
                    textAlignVertical="center"
                    underlineColorAndroid="transparent"
                    onChangeText={onChangeTitle}
                    maxLength={30}
                  />
                  {titleError && (
                    <View style={{ flexDirection: 'row' }}>
                      <Icon name="error" color="status.busy" />
                      <Text fontColor="status.busy">{t('workmodal.basic.nameplaceholder')}</Text>
                    </View>
                  )}
                </View>
              </View>
              <View style={[{ marginTop: 24 }, mobileView && { marginTop: 16 }]}>
                <View style={styles.sectionLabel}>
                  <View style={styles.labelBlock}></View>
                  <Text style={styles.labelName}>{t('workmodal.basic.goal')}</Text>
                </View>
                <TextInput
                  value={goal}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.basic.goalplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                  onChangeText={onChangeGoal}
                  maxLength={50}
                />
              </View>
            </>
          ) : step === 2 ? (
            <>
              <View style={{ paddingHorizontal: 4 }}>
                <View style={styles.sectionLabel}>
                  <View style={styles.labelBlock}></View>
                  <Text style={styles.labelName}>{t('workmodal.dsp.substructure')}</Text>
                </View>
                <WorkmodelDragList
                  list={dragList}
                  fieldError={fieldError}
                  setScrollEnabled={setScrollEnabled}
                  onChangeList={onChangeList}
                  onClickRemoveButton={onPressRemove}
                  onPressAdd={onPressAdd}
                  onChangeElement={onChangeElement}
                />
              </View>
              <View style={{ marginTop: 50 }}>
                <View style={[styles.flexBox, { alignItems: 'center' }]}>
                  <View style={styles.sectionLabel}>
                    <View style={styles.labelBlock}></View>
                    <Text style={styles.labelName}>{t('workmodal.basic.deadline')}</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Text
                      style={{
                        ...FONT.txtXs,
                        color: COLOR.gray.g700,
                        marginRight: 4,
                      }}
                    >
                      {!mobileView && t('workmodal.basic.deadlinedesc1')}
                      {t('workmodal.basic.deadlinedesc2')}
                      {!mobileView && t('workmodal.basic.deadlinedesc3')}
                    </Text>
                    <Switch size="sm" activated={activated} onChange={setActivated} />
                  </View>
                </View>
                {activated && <DateRangePicker rangeDate={rangeDate || {}} onChangeDate={onChangeRangeDate} />}
              </View>
            </>
          ) : (
            <></>
          )}
        </View>
      </LayoutView>
    </Container>
  )
}

export default CreateWorkModelHierarchy

export const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentBox: {
    position: 'relative',
    justifyContent: 'flex-start',
  },
  sectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  labelBlock: {
    width: 3,
    height: 14,
    marginRight: 6,
    backgroundColor: COLOR.mono.black,
  },
  labelName: {
    ...FONT.txtMdMedium,
  },
  inputStyle: {
    ...FONT.txtMd,
    width: '100%',
    height: 44,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 8,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
      native: {},
    }),
  },
})
