import { Avatar, Button, Text, TextField, Tooltip } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, TextInput, View } from 'react-native'
import { ProfileEdit, SnsSquareIcon, SnsType } from '../Icons'
import type { Props } from './types'

const WorkSettingUserInfoView = ({
  userName,
  userEmail,
  userProfile,
  userSns,
  onChangeName,
  onPressPassword,
  onPressProfile,
  onPressSave,
  onLeave,
  containerStyle,
}: Props) => {
  const { t } = useTranslation()
  const [name, setName] = React.useState(userName)
  const disabled = React.useMemo(() => name === userName, [name, userName])
  return (
    <View style={containerStyle}>
      <View>
        <Text style={styles.pageTitle}>{t('setting.userinfo.title')}</Text>
      </View>
      <View style={styles.contents}>
        <View style={styles.formGroup}>
          <Text style={styles.label}>{t('setting.userinfo.picturelabel')}</Text>
          <View style={{ alignSelf: 'flex-start' }}>
            <Pressable onPress={onPressProfile}>
              <Avatar profileUrl={userProfile} size="xxlarge" />
              <View style={styles.profileEdit}>
                <ProfileEdit size={32} />
              </View>
            </Pressable>
          </View>
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>{t('setting.userinfo.emaillabel')}</Text>
          <TextInput value={userEmail} editable={false} selectTextOnFocus={false} style={styles.input} />
        </View>
        <View style={styles.formGroup}>
          <Text style={styles.label}>{t('setting.userinfo.nicknamelabel')}</Text>
          <TextField
            value={name}
            onChangeValue={(text) => {
              setName(text)
              onChangeName(text)
            }}
            placeholder={t('setting.userinfo.inputplaceholder')}
            style={{ width: '100%' }}
            // height={36}
          />
        </View>
        {userSns.length > 0 ? (
          <View style={styles.formGroup}>
            <Text style={styles.label}>{t('setting.userinfo.snslabel')}</Text>
            <View style={styles.accountContainer}>
              {userSns.map((sns) => (
                <Tooltip key={sns.snsCd} text={sns.snsCd} direction="bottom" align="center">
                  <View style={styles.snsIcon}>
                    <SnsSquareIcon type={sns.snsCd as SnsType} size={32} />
                  </View>
                </Tooltip>
              ))}
            </View>
          </View>
        ) : (
          <></>
        )}
        <View style={styles.formGroup}>
          <Text style={styles.label}>{t('setting.userinfo.passwordlabel')}</Text>
          <Button
            // wide={true}
            // backgroundColor={'mono.white'}
            // borderColor={'gray.g300'}
            size="lg"
            onPress={onPressPassword}
            style={{ backgroundColor: COLOR.mono.white, borderColor: COLOR.gray.g300, borderWidth: 1, width: '100%' }}
            text={<Text>{t('setting.userinfo.passwordbutton')}</Text>}
          />
        </View>
        <View style={styles.bottomArea}>
          <Button
            onPress={() => {
              if (!disabled) {
                onPressSave()
              }
            }}
            size="md"
            style={{ paddingHorizontal: 12 }}
            text={<Text style={{ ...FONT.txtSm, color: COLOR.mono.white }}>{t('setting.userinfo.checkbutton')}</Text>}
          />
        </View>
      </View>
      {onLeave ? (
        <View style={{ marginTop: 20 }}>
          <Text style={styles.label}>{t('setting.userinfo.leavelabel')}</Text>
          <Button
            // backgroundColor={'transparent'}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              backgroundColor: COLOR.transparent.base,
            }}
            onPress={onLeave}
            text={
              <>
                <Text style={{ ...FONT.txtXs }}>{t('setting.userinfo.leavebutton')}</Text>
                <XEIcon name={'angle-right-min'} size={12} />
              </>
            }
          />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export default WorkSettingUserInfoView

const styles = StyleSheet.create({
  pageTitle: {
    ...FONT.H6Medium,
  },
  contents: {
    marginTop: 20,
  },
  input: {
    height: 38,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.gray.g100,
    paddingLeft: 12,
    borderRadius: 6,
  },
  bottomArea: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
    paddingTop: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g300,
  },
  formGroup: {
    marginBottom: 16,
  },
  label: {
    ...FONT.txtSm,
    marginBottom: 4,
  },
  profileEdit: {
    position: 'absolute',
    right: -4,
    bottom: -4,
  },
  accountContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    backgroundColor: COLOR.gray.g050,
    padding: 12,
    paddingHorizontal: 8,
    borderRadius: 8,
  },
  snsIcon: {
    marginHorizontal: 4,
  },
})
