import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { COLOR, FONT, getRealColor } from '@rui/foundations'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

const RuleButton = ({ count, onPress }: { count?: number | null; onPress?: () => void }) => {
  const [isHover, setIsHover] = React.useState(false)

  const isActive = React.useMemo(() => {
    return count !== null && count !== undefined && count > 0
  }, [count])

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        isHover && { backgroundColor: COLOR.gray.g050 },
        isActive ? { borderColor: COLOR.primary.blue200 } : {},
      ]}
      onPress={onPress}
    >
      <FontAwesomeIcon
        icon={faInfinity}
        size={16}
        color={isActive ? getRealColor('primary.blue500') : getRealColor('gray.g450')}
      />
      {isActive && (
        <View style={styles.badge}>
          <Text style={styles.count}>{count}</Text>
        </View>
      )}
    </Pressable>
  )
}

export default RuleButton

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g150,
    borderRadius: 6,
  },
  badge: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    right: -4,
    bottom: -4,
    minWidth: 16,
    height: 16,
    borderRadius: 20,
    paddingHorizontal: 4,
    backgroundColor: COLOR.primary.blue500,
  },
  count: {
    ...FONT.txt2XsBold,
    color: COLOR.mono.white,
  },
})
