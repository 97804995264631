import { useWorkProject } from '@rocket-mono/providers'
import type { ProjectMemberAuth } from '@rocket/types'
import { Text } from '@rui/atoms'
import { COLOR, useMobileView } from '@rui/foundations'
import { FAFreeSolidSvgIcons, NoContentsIcon } from '@rui/icons'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, View } from 'react-native'
import { AxisButton } from './Button'
import TableBodyCell from './TableBodyCell'
import TableBodyRow from './TableBodyRow'
import TableHeaderCell, { TableHeaderCellProps, TableHeaderCellType } from './TableHeaderCell'
import {
  WorkMembersTableInputTextItem,
  WorkMembersTableItem,
  WorkMembersTableSelectItem,
  WorkMembersTableStateItem,
  WorkMembersTableUserItem,
  WorkMembersTableUserProfileItem,
} from './WorkMembers'
import WorkMembersTableDropdown from './WorkMembersTableDropdown'
import WorkMembersTableMore from './WorkMembersTableMore'

const { faCircle, faArrowDownWideShort, faArrowUpWideShort } = FAFreeSolidSvgIcons

interface TableCellProps extends TableHeaderCellProps {
  cell?: (item: MemberInfoType, row: number) => JSX.Element
}

export interface MemberInfoType {
  id: string
  user: {
    id: string
    onlyRead: boolean
    isMe: boolean
    isNew: boolean
    isDelete: boolean
    isJoined: boolean
    name: string
    profile: string
    email: string
    accessLevel: ProjectMemberAuth
    ownershipTransfer: boolean
    state: { title?: string; setting?: string }
  }
  add: { team: string; position: string; role: string }
  static: {
    onlyRead: boolean
    board: {
      id: number
      isArchive: boolean
      invited: boolean
      selected: boolean
      title: string
      code: string
    }[]
    assign: number
  }
}

interface Props {
  isLoading: boolean
  tab: string
  searchKeyword: string
  isOwner: boolean
  isManager: boolean
  updateMemberName?: (memberId: string, memberName: string) => void
  updateMemberAuth?: (memberId: string, memberAuth: ProjectMemberAuth) => void
  updateMemberEtc?: (memberId: string, memberEtc: string) => void
  inviteCancel?: (memberId: string) => void
  inviteEdit?: (memberId: string, selected: string[]) => void
  list: MemberInfoType[]
  onPressMore?: (memberId: string, state: string, userId: string) => void
  onPressInviteMember?: () => void
}

export const CELL_WIDTH = [220, 120, 138, 120, 120, 120, 246, 140, 35]

type ProjectEtcType = { code: string }

const WorkMembersTable: React.FC<Props> = ({
  isLoading,
  tab,
  searchKeyword,
  isOwner,
  isManager,
  list,
  updateMemberName,
  updateMemberAuth,
  updateMemberEtc,
  inviteCancel,
  inviteEdit,
  onPressMore,
  onPressInviteMember,
}) => {
  const { t } = useTranslation()
  const mobileView = useMobileView(800)

  const [currentRow, setCurrentRow] = useState<string>()
  const [projectEtc, setProjectEtc] = useState<ProjectEtcType[]>([])
  const [auth, setAuth] = useState('')
  const [state, setState] = useState('')
  const [sortTeam, setSortTeam] = useState('')
  const [sortPosition, setSortPosition] = useState('')
  const [sortRole, setSortRole] = useState('')

  const [mobileHorizontalScrollWidth, setMobileHorizontalScrollWidth] = useState(0)

  const { currentProject } = useWorkProject()

  useEffect(() => {
    {
      if (currentProject) {
        let projectEtc: ProjectEtcType[] = []
        if (currentProject.etc) {
          projectEtc = JSON.parse(currentProject.etc)
        } else {
          projectEtc = [{ code: 'team' }, { code: 'position' }, { code: 'role' }]
        }

        setProjectEtc(projectEtc)
      }
    }
  }, [currentProject])

  // const updateProjectEtc = useCallback(
  //   (code: string) => {
  //     if (currentProject) {
  //       const idx = projectEtc.findIndex((o) => o.code === code)
  //       const etcArray =
  //         idx < 0
  //           ? [...projectEtc, { code }]
  //           : removeItemAtIndex(projectEtc, idx)
  //       const { id, goal, title } = currentProject
  //       const typeCode = currentProject.type.code
  //       const etc = JSON.stringify(etcArray)

  //       updateProject({ id, goal, title, etc, typeCode })
  //       setProjectEtc(etcArray)
  //     }
  //   },
  //   [currentProject, projectEtc],
  // )

  const newList = useMemo(() => {
    return list.filter((o) => o.user.isNew)
  }, [list])
  const filteredList = useMemo(() => {
    return list
      .filter((o) => !o.user.isNew)
      .sort((a, b) => {
        let sortA = ''
        let sortB = ''

        if (sortTeam !== '') {
          sortA = a.add.team ?? ''
          sortB = b.add.team ?? ''
        } else if (sortPosition !== '') {
          sortA = a.add.position ?? ''
          sortB = b.add.position ?? ''
        } else if (sortRole !== '') {
          sortA = a.add.role ?? ''
          sortB = b.add.role ?? ''
        }

        if ([sortTeam, sortPosition, sortRole].includes('asc')) {
          return sortA < sortB ? -1 : sortA > sortB ? 1 : 0
        } else if ([sortTeam, sortPosition, sortRole].includes('desc')) {
          return sortA < sortB ? 1 : sortA > sortB ? -1 : 0
        }

        // if (sortTeam && sortTeam === 'asc') {
        //   sortA = a.add.team ?? ''
        //   sortB = b.add.team ?? ''
        //   return sortA < sortB ? -1 : sortA > sortB ? 1 : 0
        // } else if (sortTeam && sortTeam === 'desc') {
        //   return a.add.team < b.add.team ? 1 : a.add.team > b.add.team ? -1 : 0
        // }
        // if (sortPosition && sortPosition === 'asc') {
        //   return a.add.position < b.add.position
        //     ? -1
        //     : a.add.position > b.add.position
        //     ? 1
        //     : 0
        // } else if (sortPosition && sortPosition === 'desc') {
        //   return a.add.position < b.add.position
        //     ? 1
        //     : a.add.position > b.add.position
        //     ? -1
        //     : 0
        // }
        // if (sortRole && sortRole === 'asc') {
        //   return a.add.role < b.add.role ? -1 : a.add.role > b.add.role ? 1 : 0
        // } else if (sortRole && sortRole === 'desc') {
        //   return a.add.role < b.add.role ? 1 : a.add.role > b.add.role ? -1 : 0
        // }
        return a.id < b.id ? -1 : a.id > b.id ? 1 : 0
      })
      .filter((o) => {
        if (auth === 'ALL') return true
        else if (auth) {
          const accessLevel = o.user.accessLevel === 'OWNER' ? 'MANAGER' : o.user.accessLevel
          return accessLevel === auth
        } else return true
      })
      .filter((o) => {
        if (state === 'ALL') return true
        else if (state) {
          return o.user.state.title === state
        } else return true
      })
  }, [auth, state, sortTeam, sortPosition, sortRole, list])

  const onPreeTab = useCallback((row: number, column: number) => {
    const action = 'member-table-tab'
    const payload = { row, column }
    window.postMessage(
      {
        action,
        payload,
      },
      '*',
    )
  }, [])
  const userProfileCells = useMemo<TableCellProps[]>(() => {
    return [
      {
        field: t('workmember.table.header.user'), //'유저명',
        width: 30,
        cell: (item: MemberInfoType, row: number) => {
          return (
            <WorkMembersTableUserProfileItem
              isDelete={item.user.isDelete}
              profileImage={item.user.profile}
              userName={item.user.name}
            />
          )
        },
      },
    ]
  }, [])
  const flowCells = useMemo<TableCellProps[]>(() => {
    const list: TableCellProps[] = [
      {
        field: t('workmember.table.header.user'), //'유저명',
        width: 220,
        cell: (item: MemberInfoType, row: number) => {
          return (
            <WorkMembersTableUserItem
              row={row}
              column={0}
              isLoading={isLoading}
              onlyRead={item.user.onlyRead}
              isMe={item.user.isMe}
              isOwner={item.user.accessLevel === 'OWNER'}
              isNew={item.user.isNew}
              isDelete={item.user.isDelete}
              isJoined={item.user.isJoined}
              profileImage={item.user.profile}
              userName={item.user.name}
              userEmail={item.user.email}
              onChangeName={(name) => {
                updateMemberName && updateMemberName(item.id, name)
              }}
              onPressTab={() => onPreeTab(row, 1)}
            />
          )
        },
      },
      {
        field: t('workmember.table.header.auth'), //'권한',
        width: 120,
        select: [
          { code: 'ALL', label: t('workmember.table.auth.all') /*'전체'*/ },
          {
            code: 'MEMBER',
            label: t('workmember.table.auth.member') /*'일반'*/,
            mark: t('workmember.table.auth.member') /*'일반'*/,
          },
          {
            code: 'MANAGER',
            label: t('workmember.table.auth.manager') /*'관리자'*/,
            mark: t('workmember.table.auth.manager') /*'관리자'*/,
          },
          {
            code: 'GUEST',
            label: t('workmember.table.auth.guest') /*'게스트'*/,
            mark: t('workmember.table.auth.guest') /*'게스트'*/,
          },
        ],
        onSelect: (code: string) => {
          setAuth(code)
        },
        cell: (item: MemberInfoType) => {
          const list = [
            item.user.accessLevel === 'OWNER'
              ? {
                  code: 'OWNER',
                  text: t('workmember.table.auth.owner') /*'소유자'*/,
                }
              : {
                  code: 'MANAGER',
                  text: t('workmember.table.auth.manager') /*'관리자'*/,
                },
            {
              code: 'MEMBER',
              text: t('workmember.table.auth.member') /*'일반'*/,
            },
            {
              code: 'GUEST',
              text: t('workmember.table.auth.guest') /*'게스트'*/,
            },
          ]
          const idx = list.findIndex((o) => o.code === item.user.accessLevel)
          const authText = list[idx].text

          return (
            <WorkMembersTableSelectItem
              isLoading={isLoading}
              idx={idx}
              onlyRead={
                tab === 'archive' ||
                !isManager ||
                item.user.isMe ||
                (item.user.ownershipTransfer && item.user.accessLevel === 'MANAGER') ||
                item.user.accessLevel === 'OWNER'
              }
              cellValue={
                <>
                  <Text>{`${authText}`}</Text>
                  {item.user.ownershipTransfer && item.user.accessLevel === 'MANAGER' && (
                    <Text fontName="txt3Xs">
                      {`\r\n`}
                      {t('workmember.table.auth.ownershiptransfer')}
                      {/*'소유권이전'*/}
                    </Text>
                  )}
                </>
              }
              onPressItem={(index) => {
                const auth: ProjectMemberAuth = list[index].code as ProjectMemberAuth
                updateMemberAuth && updateMemberAuth(item.id, auth)
              }}
              list={list}
              setCurrentRow={(is) => {
                setCurrentRow(is ? item.id : undefined)
              }}
            ></WorkMembersTableSelectItem>
          )
        },
      },
      {
        field: t('workmember.table.header.status'), //'상태',
        width: 170,
        select: [
          { code: 'ALL', label: t('workmember.table.status.all') /*'전체'*/ },
          {
            code: 'active',
            label: 'active',
            mark: 'active',
            icon: faCircle,
            iconColor: 'status.active',
          },
          {
            code: 'busy',
            label: 'busy',
            mark: 'busy',
            icon: faCircle,
            iconColor: 'status.busy',
          },
          {
            code: 'away',
            label: 'away',
            mark: 'away',
            icon: faCircle,
            iconColor: 'status.unavailable',
          },
          {
            code: 'invisible',
            label: 'invisible',
            mark: 'invisible',
            icon: faCircle,
            iconColor: 'status.inactive',
          },
          {
            code: 'off',
            label: 'off',
            mark: 'off',
            icon: faCircle,
            iconColor: 'status.off',
          },
        ],
        onSelect: (code) => {
          setState(code)
        },
        cell: (item: MemberInfoType) => {
          return (
            <WorkMembersTableItem>
              {item.user.isDelete ? (
                <></>
              ) : (
                <WorkMembersTableStateItem
                  isLoading={isLoading}
                  state={item.user.state.title}
                  setting={item.user.state.setting}
                  onPressInviteCancel={() => {
                    inviteCancel && inviteCancel(item.id)
                  }}
                />
              )}
            </WorkMembersTableItem>
          )
        },
      },
    ]
    if (projectEtc.map((o) => o.code).includes('team')) {
      list.push({
        field: t('workmember.table.header.team'), //'소속',
        isMark: false,
        // markValue: '공개',
        width: 110,
        select: [
          {
            icon: faArrowDownWideShort,
            code: 'asc',
            label: t('workmember.table.header.sortasc'), //'오름차 순 정렬',
            // mark: '공개',
          },
          {
            icon: faArrowUpWideShort,
            code: 'desc',
            label: t('workmember.table.header.sortdesc'), //'내림차 순 정렬',
            // mark: '공개',
          },
        ],
        onSelect: (code: string) => {
          setSortTeam(code)
          setSortRole('')
          setSortPosition('')
        },
        cell: (item: MemberInfoType, row: number) => {
          return (
            <WorkMembersTableInputTextItem
              row={row}
              column={1}
              isLoading={isLoading}
              onlyRead={!isManager}
              cellValue={item.add.team ?? ''}
              // cellValue={''}
              onChangeValue={(team) => {
                const { position, role } = item.add
                const etc = { team, position, role }
                if (item.add.team !== team && !(item.add.team === undefined && team === '') && updateMemberEtc)
                  updateMemberEtc(item.id, JSON.stringify(etc))
              }}
              onPressTab={() => onPreeTab(row, 2)}
            />
          )
        },
      })
    }
    if (projectEtc.map((o) => o.code).includes('position')) {
      list.push({
        field: t('workmember.table.header.position'), //'직책',
        isMark: false,
        // markValue: '공개',
        width: 110,
        select: [
          {
            icon: faArrowDownWideShort,
            code: 'asc',
            label: t('workmember.table.header.sortasc'), //'오름차 순 정렬',
            // mark: '공개',
          },
          {
            icon: faArrowUpWideShort,
            code: 'desc',
            label: t('workmember.table.header.sortdesc'), //'내림차 순 정렬',
            // mark: '공개',
          },
        ],
        onSelect: (code: string) => {
          setSortPosition(code)
          setSortRole('')
          setSortTeam('')
        },
        cell: (item: MemberInfoType, row: number) => {
          return (
            <WorkMembersTableInputTextItem
              row={row}
              column={2}
              isLoading={isLoading}
              onlyRead={!isManager}
              cellValue={item.add.position ?? ''}
              onChangeValue={(position) => {
                const { team, role } = item.add
                const etc = { team, position, role }
                if (
                  item.add.position !== position &&
                  !(item.add.position === undefined && position === '') &&
                  updateMemberEtc
                )
                  updateMemberEtc(item.id, JSON.stringify(etc))
              }}
              onPressTab={() => onPreeTab(row, 3)}
            />
          )
        },
      })
    }

    if (projectEtc.map((o) => o.code).includes('role')) {
      list.push({
        field: t('workmember.table.header.role'), //'역할',
        isMark: false,
        // markValue: '공개',
        // width: 110,
        select: [
          {
            icon: faArrowDownWideShort,
            code: 'asc',
            label: t('workmember.table.header.sortasc'), //'오름차 순 정렬',
            // mark: '공개',
          },
          {
            icon: faArrowUpWideShort,
            code: 'desc',
            label: t('workmember.table.header.sortdesc'), //'내림차 순 정렬',
            // mark: '공개',
          },
        ],
        onSelect: (code: string) => {
          setSortRole(code)
          setSortTeam('')
          setSortPosition('')
        },
        cell: (item: MemberInfoType, row: number) => {
          return (
            <WorkMembersTableInputTextItem
              row={row}
              column={3}
              isLoading={isLoading}
              onlyRead={!isManager}
              cellValue={item.add.role ?? ''}
              onChangeValue={(role) => {
                const { position, team } = item.add
                const etc = { team, position, role }
                if (item.add.role !== role && !(item.add.role === undefined && role === '') && updateMemberEtc)
                  updateMemberEtc(item.id, JSON.stringify(etc))
              }}
              onPressTab={() => onPreeTab(row + 1, 0)}
            />
          )
        },
      })
    }

    if (mobileView) {
      if (tab === 'member') {
        list.push({
          field: t('workmember.table.header.board'), //'참여된 보드',
          width: 200,
          cell: (item: MemberInfoType) => {
            const select = item.static.board.filter((o) => o.selected || o.invited).map((o) => o.code)
            if (isLoading) return <></>

            console.log(
              'item.add',
              item.static.board.map((o) => o.title),
              select,
            )
            return (
              <WorkMembersTableDropdown
                onlyRead={item.static.onlyRead}
                board={item.static.board}
                select={select}
                onPressEdit={(selected) => inviteEdit && inviteEdit(item.id, selected)}
                maxWidth={200}
                setCurrentRow={(is) => {
                  setCurrentRow(is ? item.id : undefined)
                }}
              />
            )
          },
        })
      }

      list.push({
        field: '',
        width: 35,
        cell: (item: MemberInfoType) => {
          if (item.user.isMe) return <></>
          return (
            <WorkMembersTableMore
              isOwner={isOwner}
              isManager={isManager}
              isArchive={tab !== 'member'}
              isDelete={item.user.isDelete}
              auth={item.user.accessLevel}
              ownershipTransfer={item.user.ownershipTransfer}
              onPressState={(state) => onPressMore && onPressMore(item.id, state, item.user.id)}
            />
          )
        },
      })
    }

    return list
  }, [tab, currentProject, projectEtc, mobileView, updateMemberAuth, updateMemberName, updateMemberEtc])
  const staticCells = useMemo(() => {
    const list: TableHeaderCellType[] = []

    if (tab === 'member') {
      list.push({
        field: t('workmember.table.header.board'), //'참여된 보드',
        width: 200,
        border: false,
        cell: (item: MemberInfoType) => {
          const select = item.static.board.filter((o) => o.selected || o.invited).map((o) => o.code)
          if (isLoading) return <></>

          console.log(
            'item.add',
            item.static.board.map((o) => o.title),
            select,
          )
          return (
            <WorkMembersTableDropdown
              onlyRead={item.static.onlyRead}
              board={item.static.board}
              select={select}
              onPressEdit={(selected) => inviteEdit && inviteEdit(item.id, selected)}
              maxWidth={200}
              setCurrentRow={(is) => {
                setCurrentRow(is ? item.id : undefined)
              }}
            />
          )
        },
      })
    }

    list.push({
      field: '',
      width: 35,
      border: false,
      cell: (item: MemberInfoType) => {
        if (item.user.isMe) return <></>
        return (
          <WorkMembersTableMore
            isOwner={isOwner}
            isManager={isManager}
            isArchive={tab !== 'member'}
            isDelete={item.user.isDelete}
            auth={item.user.accessLevel}
            ownershipTransfer={item.user.ownershipTransfer}
            onPressState={(state) => onPressMore && onPressMore(item.id, state, item.user.id)}
          />
        )
      },
    })

    return list
  }, [tab, isOwner, isManager, isLoading])

  if (mobileView) {
    return (
      <ScrollView
        style={{ flex: 1 }}
        horizontal
        onScroll={(event) => {
          setMobileHorizontalScrollWidth(event.nativeEvent.contentOffset.x)
        }}
      >
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={[styles.groupRow]}>
              <View style={[styles.row, styles.flowCells]}>
                {flowCells.map((cell, idx) => {
                  return <TableHeaderCell key={idx} {...cell} />
                })}
              </View>
            </View>
          </View>
          <View style={[styles.tableBody]}>
            <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
              {[...newList, ...filteredList].map((item, row) => (
                <TableBodyRow key={`member-list${row}`} currentRow={currentRow === item.id}>
                  {mobileHorizontalScrollWidth > 220 ? (
                    <View style={{ position: 'absolute', left: mobileHorizontalScrollWidth, zIndex: 1 }}>
                      {userProfileCells.map(({ cell, width }, index) => {
                        return (
                          <TableBodyCell key={index} width={width} border={false}>
                            {cell && cell(item, row)}
                          </TableBodyCell>
                        )
                      })}
                    </View>
                  ) : (
                    <></>
                  )}
                  <View style={[styles.row, styles.bodyRow, styles.flowCells, styles.flowBodyCells]}>
                    {flowCells.map(({ cell, width }, index) => {
                      return (
                        <TableBodyCell key={index} width={width}>
                          {cell && cell(item, row)}
                        </TableBodyCell>
                      )
                    })}
                  </View>
                </TableBodyRow>
              ))}
            </ScrollView>

            {searchKeyword.length > 0 && list.length === 0 && (
              <View
                style={{
                  position: 'absolute',
                  left: 200,
                  right: 200,
                  top: 120,
                  bottom: 100,
                  justifyContent: 'center',
                  alignSelf: 'center',
                  marginBottom: 52,
                }}
              >
                <NoContentsIcon
                  type={'search'}
                  textSize={'medium'}
                  text={t('nocontentsicon.search.text')}
                  iconWidth={72}
                />
              </View>
            )}

            {tab === 'archive' && searchKeyword.length === 0 && list.length === 0 && (
              <View
                style={{
                  position: 'absolute',
                  left: 200,
                  right: 200,
                  top: 120,
                  bottom: 100,
                  justifyContent: 'center',
                  alignSelf: 'center',
                  marginBottom: 52,
                }}
              >
                <NoContentsIcon
                  type={'member-excluded'}
                  textSize={'medium'}
                  text={t('nocontentsicon.memberarchive.text')}
                  iconWidth={72}
                />
              </View>
            )}

            {tab === 'member' && searchKeyword.length === 0 && list.length === 1 && (
              <View
                style={{
                  position: 'absolute',
                  left: 200,
                  right: 200,
                  top: 100,
                  bottom: 100,
                  justifyContent: 'center',
                  alignSelf: 'center',
                  marginBottom: 52,
                }}
              >
                <NoContentsIcon
                  type={'member'}
                  textSize={'medium'}
                  text={t('nocontentsicon.workmember.text')}
                  subText={t('nocontentsicon.workmember.subtext')}
                  iconWidth={115}
                />
                <View style={{ marginTop: 8, width: 150, alignSelf: 'center' }}>
                  <AxisButton
                    onPress={onPressInviteMember}
                    isWeb={false}
                    wide={true}
                    iconName="plus"
                    addText={t('nocontentsicon.workmember.button')}
                    style={{
                      height: 36,
                      paddingHorizontal: 12,
                    }}
                  />
                </View>
              </View>
            )}
          </View>
        </View>
      </ScrollView>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.table}>
        <View style={styles.tableHeader}>
          <View style={[styles.groupRow]}>
            <View style={[styles.row, styles.flowCells]}>
              {flowCells.map((cell, idx) => {
                return <TableHeaderCell key={idx} {...cell} />
              })}
            </View>
            <View style={[styles.row, styles.tableHeader, styles.staticCells]}>
              {staticCells.map((cell, idx) => {
                return <TableHeaderCell staticSize key={idx} {...cell} />
              })}
            </View>
          </View>
        </View>
        <View style={[styles.tableBody]}>
          <ScrollView style={{ flex: 1 }} showsVerticalScrollIndicator={false}>
            {[...newList, ...filteredList].map((item, row) => (
              <TableBodyRow key={`member-list${row}`} currentRow={currentRow === item.id}>
                <View style={[styles.row, styles.bodyRow, styles.flowCells, styles.flowBodyCells]}>
                  {flowCells.map(({ cell, width }, index) => {
                    return (
                      <TableBodyCell key={index} width={width}>
                        {cell && cell(item, row)}
                      </TableBodyCell>
                    )
                  })}
                </View>

                <View style={[styles.row, styles.bodySubRow, styles.staticCells]}>
                  {staticCells.map(({ cell, width, border }, index) => {
                    return (
                      <TableBodyCell key={index} width={width} border={border}>
                        {cell && cell(item)}
                      </TableBodyCell>
                    )
                  })}
                </View>
              </TableBodyRow>
            ))}
          </ScrollView>

          {searchKeyword.length > 0 && list.length === 0 && (
            <View
              style={{
                position: 'absolute',
                left: 200,
                right: 200,
                top: 120,
                bottom: 100,
                justifyContent: 'center',
                alignSelf: 'center',
                marginBottom: 52,
              }}
            >
              <NoContentsIcon
                type={'search'}
                textSize={'medium'}
                text={t('nocontentsicon.search.text')}
                iconWidth={72}
              />
            </View>
          )}

          {tab === 'archive' && searchKeyword.length === 0 && list.length === 0 && (
            <View
              style={{
                position: 'absolute',
                left: 200,
                right: 200,
                top: 120,
                bottom: 100,
                justifyContent: 'center',
                alignSelf: 'center',
                marginBottom: 52,
              }}
            >
              <NoContentsIcon
                type={'member-excluded'}
                textSize={'medium'}
                text={t('nocontentsicon.memberarchive.text')}
                iconWidth={72}
              />
            </View>
          )}

          {tab === 'member' && searchKeyword.length === 0 && list.length === 1 && (
            <View
              style={{
                position: 'absolute',
                left: 200,
                right: 200,
                top: 100,
                bottom: 100,
                justifyContent: 'center',
                alignSelf: 'center',
                marginBottom: 52,
              }}
            >
              <NoContentsIcon
                type={'member'}
                textSize={'medium'}
                text={t('nocontentsicon.workmember.text')}
                subText={t('nocontentsicon.workmember.subtext')}
                iconWidth={115}
              />
              <View style={{ marginTop: 8, width: 150, alignSelf: 'center' }}>
                <AxisButton
                  onPress={onPressInviteMember}
                  isWeb={false}
                  wide={true}
                  iconName="plus"
                  addText={t('nocontentsicon.workmember.button')}
                  style={{
                    height: 36,
                    paddingHorizontal: 12,
                  }}
                />
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default WorkMembersTable

const styles = StyleSheet.create({
  table: {
    // width: '100%',
    flex: 1,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g300,
    zIndex: 9,
  },
  tableHeader: {
    // width: '100%',
    height: 32,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g300,
    zIndex: 99,
  },
  tableBody: {
    flex: 1,
    // width: '100%',
    zIndex: 88,
  },
  groupRow: {
    // width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cell: {
    borderRightWidth: 1,
    borderColor: COLOR.gray.g100,
  },
  bodyRow: {
    flex: 1,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
  },
  bodySubRow: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
  },
  flowCells: {
    flex: 1,
  },
  flowBodyCells: {},
  staticCells: {
    borderLeftWidth: 1,
    borderLeftColor: COLOR.gray.g300,
    backgroundColor: COLOR.mono.white,
  },
  bottomCon: {
    height: 50,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
})
