import { COLOR, FONT, getRealColor } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import moment from 'moment'
import * as React from 'react'
import { Animated, StyleSheet, Text, View } from 'react-native'

interface Props {
  status?: 'WAITING_PAYMENT' | 'COOKING' | 'DONE' | 'SERVING_DONE'
  progress: number
  estimateDate?: Date
}
const TableStatusProgress = ({ status, progress, estimateDate }: Props) => {
  const progressValue = React.useRef(new Animated.Value(progress)).current

  const minute = React.useMemo<number | undefined>(() => {
    return estimateDate ? moment().diff(estimateDate, 'minutes') : undefined
  }, [estimateDate])

  console.log('minute', minute)

  const isOver = React.useMemo(() => {
    return minute !== undefined && minute > 0
  }, [minute])

  // ;'조리중' | '완료' | '초과' | '접수' | '대기'

  React.useEffect(() => {
    Animated.timing(progressValue, {
      toValue: progress,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }, [progress, progressValue])
  return (
    <>
      <View style={[{ height: 10, backgroundColor: getRealColor('gray.g050') }]}>
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: getRealColor('main.blue'),
              width: progressValue.interpolate({
                inputRange: [0, 100],
                outputRange: ['0%', '100%'],
                extrapolate: 'clamp',
              }),
            },
            status === undefined
              ? {
                  backgroundColor: getRealColor('transparent.base'),
                }
              : status === 'COOKING' && isOver
              ? { backgroundColor: getRealColor('main.red') }
              : status === 'WAITING_PAYMENT' && {
                  backgroundColor: getRealColor('transparent.base'),
                },
          ]}
        />
      </View>
      <View>
        {status === 'COOKING' && !isOver && (
          <Text style={styles.infoText}>
            <XEIcon name={'time-o'} size={12} color={'gray.g700'} />
            조리 예정 완료까지{' '}
            <Text style={[{ ...FONT.txtXsBold, color: COLOR.main.blue }]}>{minute && Math.abs(minute)}분</Text>{' '}
            남았습니다.
          </Text>
        )}
        {status === 'SERVING_DONE' && (
          <Text style={styles.infoText}>
            <XEIcon name={'check'} size={12} color={'gray.g700'} />
            조리 및 서빙이 <Text style={[{ ...FONT.txtXsBold, color: COLOR.main.blue }]}>완료</Text> 되었습니다.
          </Text>
        )}
        {isOver && (
          <Text style={styles.infoText}>
            <XEIcon name={'time-o'} size={12} color={'gray.g700'} />
            조리 예정 시간을 <Text style={[{ ...FONT.txtXsBold, color: COLOR.main.red }]}>{minute}분</Text>{' '}
            초과하였습니다.
          </Text>
        )}
        {status === 'DONE' && <Text style={styles.infoText}>주문 접수 단계입니다. 조리를 시작해 주세요.</Text>}
        {status === undefined && <Text style={styles.infoText}>테이블에 주문이 들어오지 않았습니다.</Text>}
      </View>
    </>
  )
}

export default TableStatusProgress

const styles = StyleSheet.create({
  infoText: {
    ...FONT.txtXs,
    color: COLOR.gray.g700,
    textAlign: 'center',
    marginVertical: 12,
  },
})
