import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import { styles } from './List'
import MultifulItem from './MultifulItem'
import type { DropdownItem } from './types'

interface Props {
  selected: string[]
  notification?: boolean
  isOpen: boolean
  buttonSize?: { width: number; height: number }
  onOpen?: (open: boolean) => void
  dropdownList: DropdownItem[]
  containerStyle?: ViewStyle
  footerComponent?: () => JSX.Element
  style?: ViewStyle
  editAccess?: boolean
  onPressItem?: (id: string) => void
}

const DropdownList = ({
  selected,
  notification,
  isOpen,
  buttonSize,
  onOpen,
  dropdownList,
  containerStyle,
  footerComponent,
  editAccess,
  style,
  onPressItem,
}: Props) => {
  return (
    <View
      style={[
        {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: buttonSize ? buttonSize.height : '100%',
          display: isOpen ? 'flex' : 'none',
        },
      ]}
    >
      <Pressable
        style={[
          {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
        ]}
        onPress={() => onOpen && onOpen(false)}
      />
      <View
        style={[
          containerStyle,
          style,
          {
            top: buttonSize && buttonSize.height + 4,
          },
          styles.container,
          notification && { borderColor: COLOR.primary.blue500 },
          !!footerComponent && { paddingBottom: 0 },
        ]}
      >
        {dropdownList.map((item) => (
          <MultifulItem
            item={item}
            key={`dropdown-list-${item.id}`}
            selected={selected}
            editAccess={editAccess}
            onPressItem={() => onPressItem && onPressItem(item.code)}
          />
        ))}
        {footerComponent && <View style={innerStyles.footerComponent}>{footerComponent()}</View>}
      </View>
    </View>
  )
}

export default React.memo(DropdownList)

const innerStyles = StyleSheet.create({
  footerComponent: {
    height: 'auto',
    padding: 8,
    marginTop: 4,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.gray.g100,
  },
})
