import * as React from 'react'
import { StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'

import { getRealColor } from '@rui/foundations'
import type { WorkModelIconProps } from './types'

export const WorkModelIcon: React.FC<WorkModelIconProps> = ({ icon, size = 24, backgroundColor }) => {
  return (
    <View
      style={[
        styles.container,
        {
          width: size,
          height: size,
          backgroundColor: getRealColor(backgroundColor),
        },
      ]}
    >
      {icon}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: 24,
    height: 24,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: 4,
  },
})
