import * as React from 'react'

import { UserState } from '@rocket/types'
import Context from './context'
import type { ProviderProps, UserStateDate } from './types'

// FIXME: astro is not used
const Provider = ({ publishState, children }: ProviderProps) => {
  const updateUserState = React.useCallback((userState: UserState, userStateDate?: UserStateDate) => {
    publishState(userState, userStateDate)
  }, [])

  return (
    <Context.Provider
      value={{
        updateUserState,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
