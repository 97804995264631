import { Modal, View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import type { IColors } from '@rui/foundations'
import { COLOR, getHexToRgba, getRealColor } from '@rui/foundations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, Platform, Pressable, Modal as RNModal, StyleSheet } from 'react-native'
import { useWorkBoardMember } from '../../providers/WorkBoardMemberProvider'
import DropdownListGroup, { DropdownGroupItem } from '../DropdownListGroup'
import InviteMemberEmail from '../InviteMember/InviteMemberEmail'
import { InviteMember, MemberList, MemberListItem } from '../MemberList'
import InputMemberSearch from '../MemberList/InputMemberSearch'
import MemberAddMenu from '../MemberList/MemberAddMenu'
import type { ViewProps } from './types'

const ModalView: React.FC<ViewProps> = ({ secureCdnUrl, onPressClose }) => {
  const { t } = useTranslation()
  const [code, setCode] = React.useState('')

  const {
    loading,
    currentUser,
    isAdmin,
    members,
    inviteMembers,
    deleteMember,
    cancelInvite,
    changeMemberManager,
    changeSearchKeyword,
    memberSearchKeyword,
    changeMemberSearchKeyword,
    element,
    step,
    changeStep,
    changeSelected,
    changeAllChecked,
    onInviteMembers,
    inviteEmailMemberList,
    addInviteEmailMemberList,
    changeInviteEmailMemberList,
    deleteInviteEmailMemberList,
    clearInviteEmailMemberList,
    onInviteEmailMembers,
    leaveMember,
  } = useWorkBoardMember()

  const DropdownList: DropdownGroupItem[] = React.useMemo(
    () => [
      {
        group: [
          {
            id: 1,
            title: t('memberlist.labelNormal'),
            code: 'normal',
          },
          {
            id: 2,
            title: t('memberlist.labelLeader'),
            code: 'leader',
          },
        ],
      },
      {
        group: [
          {
            id: 1,
            title: t('memberlist.labelRemove'),
            code: 'remove',
          },
        ],
      },
    ],
    [],
  )
  const [dropdownMenu, setDropdownMenu] = React.useState<{
    item: MemberListItem
    code: string
    pageY: number | string
    pageX?: number | string
    dropdownList: DropdownGroupItem[]
  } | null>(null)

  const [memberAddMenu, setMemberAddMenu] = React.useState<{
    pageX: number | string
    pageY: number | string
  } | null>(null)

  const isElementLabelObject = React.useMemo(() => {
    if (element) {
      if (element.labelObject) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }, [element])

  const labelContainerBackgroundStyle = React.useMemo(() => {
    if (element) {
      if (element.labelObject) {
        return {
          backgroundColor: getHexToRgba(getRealColor(element.labelObject as IColors), 0.15),
        }
      } else {
        return {
          backgroundColor: getHexToRgba(getRealColor('gray.g450'), 0.15),
        }
      }
    } else {
      return {}
    }
  }, [element])

  const labelBackgroundStyle = React.useMemo(() => {
    if (element) {
      if (element.labelObject) {
        return {
          backgroundColor: getRealColor(element.labelObject as IColors),
        }
      } else {
        return { backgroundColor: getRealColor('gray.g450') }
      }
    } else {
      return {}
    }
  }, [element])

  const { totalCount, leaderCount } = React.useMemo(() => {
    let totalCount = 0
    let leaderCount = 0
    if (!members) return { totalCount, leaderCount }
    members.forEach((member) => {
      if (member.positionLeader) {
        leaderCount++
      }
      totalCount++
    })
    return { totalCount, leaderCount }
  }, [members])

  const isInviteCheck = React.useMemo(() => {
    if (inviteMembers) {
      return inviteMembers.filter((item) => item.selected).length > 0
    }
    return false
  }, [inviteMembers])
  const inviteCount = React.useMemo(() => {
    if (inviteMembers) {
      return inviteMembers.filter((item) => item.selected).length
    }
    return 0
  }, [inviteMembers])

  // React.useEffect(() => {
  //   const listener = () => {
  //     console.log('window', window.innerHeight, window.innerWidth)
  //     setMemberAddMenu((prev) => {
  //       console.log(`calc(${window.innerHeight}px / 3)`)
  //       if (prev) {
  //         return {
  //           ...prev,
  //           pageY: `calc(${window.innerHeight}px / 3)`,
  //         }
  //       } else {
  //         return null
  //       }
  //     })
  //   }
  //   if (Platform.OS === 'web') {
  //     window.addEventListener('resize', listener)
  //   }
  //   return () => {
  //     if (Platform.OS === 'web') {
  //       window.removeEventListener('resize', listener)
  //     }
  //   }
  // }, [])

  const header = React.useMemo(() => {
    switch (step) {
      case 'init':
        return (
          <Modal.Header
            height={82}
            textAlign="left"
            onPressClose={() => {
              onPressClose()
            }}
          >
            <View style={{ width: '100%', paddingRight: 28 }}>
              <View style={styles.headerTopContainer}>
                <Text fontName="H7Medium" fontColor="gray.g900">
                  {t('workboardmemberview.init.header.title')}
                </Text>
                <Text fontName="txtXs" fontColor="gray.g700">
                  {t('workboardmemberview.init.header.subtitle', {
                    totalCount,
                    leaderCount,
                  })}
                </Text>
              </View>
              <View style={styles.headerBottomContainer}>
                <View style={[styles.labelContainer, labelContainerBackgroundStyle]}>
                  {isElementLabelObject ? <View style={[styles.label, labelBackgroundStyle]} /> : <></>}
                  <Text fontName="txtSmMedium" fontColor="mono.black" numberOfLines={1}>
                    {element?.name}
                  </Text>
                </View>
              </View>
            </View>
          </Modal.Header>
        )
      case 'member':
        return (
          <Modal.Header
            height={82}
            textAlign="left"
            onPressClose={() => {
              changeStep('init')
            }}
          >
            <View>
              <Text fontName="H7Medium" fontColor="gray.g900">
                {t('workboardmemberview.member.header.title')}
              </Text>
              <View style={styles.headerBottomContainer}>
                <View style={[styles.labelContainer, styles.subLabelContainer, labelContainerBackgroundStyle]}>
                  {isElementLabelObject ? (
                    <View style={[styles.label, styles.subLabel, labelBackgroundStyle]} />
                  ) : (
                    <></>
                  )}
                  <Text fontName="txtSmMedium" fontColor="mono.black" numberOfLines={1}>
                    {element?.name}
                  </Text>
                </View>
              </View>
            </View>
          </Modal.Header>
        )
      case 'work':
        return (
          <Modal.Header
            textAlign="left"
            onPressClose={() => {
              changeStep('init')
            }}
          >
            다른 워크에서 초대
          </Modal.Header>
        )
      case 'mail':
        return (
          <Modal.Header
            height={82}
            textAlign="left"
            onPressClose={() => {
              changeStep('init')
              clearInviteEmailMemberList()
            }}
          >
            <View>
              <Text fontName="H7Medium" fontColor="gray.g900">
                {t('workboardmemberview.mail.header.title')}
              </Text>
              <View style={styles.headerBottomContainer}>
                <View style={[styles.labelContainer, styles.subLabelContainer, labelContainerBackgroundStyle]}>
                  {isElementLabelObject ? (
                    <View style={[styles.label, styles.subLabel, labelBackgroundStyle]} />
                  ) : (
                    <></>
                  )}
                  <Text fontName="txtSmMedium" fontColor="mono.black" numberOfLines={1}>
                    {element?.name}
                  </Text>
                </View>
              </View>
            </View>
          </Modal.Header>
        )
      case 'link':
        return (
          <Modal.Header
            textAlign="left"
            onPressClose={() => {
              changeStep('init')
            }}
          >
            링크 초대
          </Modal.Header>
        )
      default:
        return <></>
    }
  }, [
    step,
    totalCount,
    leaderCount,
    labelContainerBackgroundStyle,
    isElementLabelObject,
    labelBackgroundStyle,
    element,
    onPressClose,
    changeStep,
    clearInviteEmailMemberList,
  ])

  const body = React.useMemo(() => {
    switch (step) {
      case 'init':
        return (
          <Modal.Body style={styles.bodyTopPadding}>
            <InputMemberSearch
              isAdmin={isAdmin}
              onAddMember={(pageX = 0, pageY = 0) => {
                setMemberAddMenu({
                  pageX: Platform.OS === 'web' ? 'calc(50vw - 145px)' : pageX,
                  pageY: pageY,
                })
              }}
              onTextChange={changeSearchKeyword}
              onAddMemberResize={(height) => {
                setMemberAddMenu((prev) => {
                  if (prev) {
                    return { ...prev, pageY: height }
                  } else {
                    return prev
                  }
                })
              }}
            />
            {members !== undefined ? (
              <MemberList
                secureCdnUrl={secureCdnUrl}
                isAdmin={isAdmin}
                list={members}
                onPress={(item, _index, pageY, pageX) => {
                  // console.log('memberlist', item, index, pageY, pageX)
                  setDropdownMenu({
                    item,
                    code: item.positionLeader ? 'leader' : 'normal',
                    pageY: pageY + 230,
                    pageX: Platform.OS === 'web' ? 'calc(50vw - 662px)' : pageX,
                    dropdownList: DropdownList,
                  })
                }}
                onPressCancel={(item) => {
                  cancelInvite(item.id)
                }}
                onMemberListResize={(item, pageY) => {
                  if (item.id === dropdownMenu?.item.id) {
                    setDropdownMenu((prev) => {
                      if (prev) {
                        return { ...prev, pageY }
                      } else {
                        return prev
                      }
                    })
                  }
                }}
                currentUserId={String(currentUser.id)}
                onPressLeave={(item, pageY, pageX) => {
                  setDropdownMenu({
                    item,
                    code: item.positionLeader ? 'leader' : 'normal',
                    pageY: pageY + 230,
                    pageX: Platform.OS === 'web' ? 'calc(50vw - 662px)' : pageX,
                    dropdownList: [
                      {
                        group: [
                          {
                            id: 1,
                            title: t('memberlist.labelLeave'),
                            code: 'leave',
                          },
                        ],
                      },
                    ],
                  })
                }}
              />
            ) : (
              <></>
            )}
            {dropdownMenu ? (
              <RNModal transparent>
                <DropdownListGroup
                  code={dropdownMenu?.code || ''}
                  isOpen={!!dropdownMenu}
                  offsetY={dropdownMenu?.pageY || 0}
                  dropdownList={dropdownMenu?.dropdownList || []}
                  onCode={(code) => {
                    if (dropdownMenu) {
                      console.log('code', code)
                      const { item } = dropdownMenu
                      if (code === 'remove') {
                        deleteMember(item.id)
                      } else if (code === 'leader') {
                        changeMemberManager(item.id, true)
                      } else if (code === 'normal') {
                        changeMemberManager(item.id, false)
                      } else if (code === 'leave') {
                        leaveMember(item.id)
                      }
                      setDropdownMenu(null)
                    }
                  }}
                  containerStyle={styles.dropdownListGroupContainer}
                  onDismiss={() => {
                    setDropdownMenu(null)
                  }}
                  topGap={25}
                />
              </RNModal>
            ) : (
              <></>
            )}
            {memberAddMenu ? (
              <MemberAddMenu
                {...memberAddMenu}
                list={[
                  {
                    icon: 'user-plus-o',
                    title: t('workboardmemberview.menu.member'), //'멤버에서 초대',
                    code: 'member',
                  },
                  // {
                  //   icon: 'apps',
                  //   title: t('workboardmemberview.menu.work'), //'다른 워크에서 초대',
                  //   code: 'work',
                  // },
                  {
                    icon: 'mail-o',
                    title: t('workboardmemberview.menu.mail'), //'메일 초대',
                    code: 'mail',
                  },
                  // {
                  //   icon: 'link',
                  //   title: t('workboardmemberview.menu.link'), //'링크 초대',
                  //   code: 'link',
                  // },
                ]}
                onPress={(code) => {
                  changeStep(code)
                  setMemberAddMenu(null)
                }}
                onClose={() => setMemberAddMenu(null)}
              />
            ) : (
              <></>
            )}
          </Modal.Body>
        )
      case 'member':
        return (
          <Modal.Body style={styles.bodyTopPadding}>
            <InputMemberSearch onTextChange={changeMemberSearchKeyword} />
            <InviteMember
              secureCdnUrl={secureCdnUrl}
              initCode={code}
              onCode={(code) => {
                setCode(code)
              }}
              keyword={memberSearchKeyword}
              inviteList={inviteMembers ?? []}
              invitedList={members ?? []}
              onInviteMember={changeSelected}
              onAddMember={(pageX = 0, pageY = 0) => {
                setMemberAddMenu({
                  pageX: Platform.OS === 'web' ? 'calc(50vw - 145px)' : pageX,
                  pageY: pageY,
                })
              }}
              onAddMemberResize={(pageY) => {
                setMemberAddMenu((prev) => {
                  if (prev) {
                    return { ...prev, pageY }
                  } else {
                    return prev
                  }
                })
              }}
              isAllChecked={inviteMembers && inviteCount === inviteMembers.length}
              onAllChecked={() => changeAllChecked(inviteCount)}
            />
            {memberAddMenu ? (
              <MemberAddMenu
                {...memberAddMenu}
                list={[
                  // {
                  //   icon: 'apps',
                  //   title: t('workboardmemberview.menu.work'), //'다른 워크에서 초대',
                  //   code: 'work',
                  // },
                  {
                    icon: 'mail-o',
                    title: t('workboardmemberview.menu.mail'), //'메일 초대',
                    code: 'mail',
                  },
                  // {
                  //   icon: 'link',
                  //   title: t('workboardmemberview.menu.link'), //'링크 초대',
                  //   code: 'link',
                  // },
                ]}
                onPress={(code) => {
                  changeStep(code)
                  setMemberAddMenu(null)
                }}
                onClose={() => setMemberAddMenu(null)}
              />
            ) : (
              <></>
            )}
          </Modal.Body>
        )
      case 'work':
        return (
          <Modal.Body>
            <Text>다른 워크에서 초대</Text>
          </Modal.Body>
        )
      case 'mail':
        return (
          <Modal.Body style={styles.bodyTopPadding}>
            <InviteMemberEmail
              list={inviteEmailMemberList}
              onPressAdd={addInviteEmailMemberList}
              onChangeItem={changeInviteEmailMemberList}
              onPressDelete={deleteInviteEmailMemberList}
            />
          </Modal.Body>
        )
      case 'link':
        return (
          <Modal.Body>
            <Text>링크 초대</Text>
          </Modal.Body>
        )
      default:
        return <></>
    }
  }, [
    step,
    changeStep,
    isAdmin,
    changeSearchKeyword,
    changeMemberSearchKeyword,
    members,
    inviteMembers,
    dropdownMenu,
    memberAddMenu,
    DropdownList,
    cancelInvite,
    deleteMember,
    changeMemberManager,
    changeSelected,
    inviteCount,
    changeAllChecked,
    inviteEmailMemberList,
    addInviteEmailMemberList,
    changeInviteEmailMemberList,
    deleteInviteEmailMemberList,
  ])

  const footer = React.useMemo(() => {
    switch (step) {
      case 'member':
        return (
          <Modal.Footer>
            <Pressable
              style={[styles.footerButton, isInviteCheck ? styles.backgroundBlue : styles.backgroundGray]}
              onPress={() => {
                if (isInviteCheck) {
                  onInviteMembers()
                }
              }}
            >
              <Text fontName="textRegular" fontColor="mono.white">
                {isInviteCheck
                  ? t('workboardmemberview.footer.invitecount', {
                      count: inviteCount,
                    })
                  : t('workboardmemberview.footer.invite')}
              </Text>
            </Pressable>
          </Modal.Footer>
        )
      case 'mail':
        return (
          <Modal.Footer>
            <Pressable
              style={[
                styles.footerButton,
                inviteEmailMemberList.length > 0 ? styles.backgroundBlue : styles.backgroundGray,
              ]}
              onPress={() => {
                if (inviteEmailMemberList.length > 0) {
                  onInviteEmailMembers()
                }
              }}
            >
              <Text fontName="textRegular" fontColor="mono.white">
                {inviteEmailMemberList.length > 0
                  ? t('workboardmemberview.footer.invitecount', {
                      count: inviteEmailMemberList.length,
                    })
                  : t('workboardmemberview.footer.invite')}
              </Text>
            </Pressable>
          </Modal.Footer>
        )
      default:
        return <></>
    }
  }, [step, isInviteCheck, inviteCount, onInviteMembers, inviteEmailMemberList, onInviteEmailMembers])

  return (
    <View style={styles.container}>
      {header}
      {body}
      {footer}
      {loading ? (
        <RNModal transparent>
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="large" color={getRealColor('mono.white')} />
          </View>
        </RNModal>
      ) : (
        <></>
      )}
    </View>
  )
}

export default ModalView

const styles = StyleSheet.create({
  container: {
    width: '100%',
    minHeight: 560,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
  },
  headerTopContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  headerBottomContainer: {
    marginTop: 4,
  },
  bodyTopPadding: {
    paddingTop: 12,
  },
  // @ts-ignore
  labelContainer: {
    height: 24,
    flexDirection: 'row',
    paddingHorizontal: 4,
    paddingVertical: 4,
    marginRight: 4,
    borderRadius: 4,
    alignItems: 'center',
    maxWidth: 360,
    ...Platform.select({
      web: {
        width: 'fit-content',
      },
    }),
  },
  // @ts-ignore
  dropdownListGroupContainer: {
    width: 120,
    ...Platform.select({
      web: {
        right: 'calc(calc(100vw / 2) - 190px)',
      },
    }),
  },
  label: {
    height: 16,
    width: 16,
    borderRadius: 4,
    marginRight: 4,
  },
  subLabelContainer: {
    height: 20,
  },
  subLabel: {
    height: 16,
    width: 16,
  },
  // footerContainer: {
  //   flex: 1,
  //   margin: 24,
  // },
  footerButton: {
    height: 50,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  backgroundBlue: {
    backgroundColor: '#357DE5',
  },
  backgroundGray: {
    backgroundColor: '#DDDDDD',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
})
