import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text, ViewStyle } from 'react-native'

interface Props {
  title: string
  isFold: boolean
  onPress: (is: boolean) => void
  containerStyle?: ViewStyle
}
const MenuCategory: React.FC<Props> = ({ title, isFold, onPress, containerStyle }) => {
  return (
    <Pressable
      style={[styles.container, containerStyle]}
      onPress={() => {
        onPress(!isFold)
      }}
    >
      <Text style={{ ...FONT.txtMd }}>{title}</Text>
      <XEIcon name={isFold ? 'angle-down' : 'angle-up'} size={12} />
    </Pressable>
  )
}

export default MenuCategory

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 36,
    paddingHorizontal: 20,
    backgroundColor: COLOR.gray.g050,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
  },
})
