import React from 'react'
import { G, Mask, Path, Svg } from 'react-native-svg'

interface Props {
  size: number
}

export const ProfileSvg: React.FC<Props> = ({ size }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 52 52" fill="none">
      <Path
        d="M0 25.9209C0 40.2367 11.6843 51.8419 26 51.8419C40.3157 51.8419 52 40.2367 52 25.9209C52 11.6052 40.3157 0 26 0C11.6843 0 0 11.6052 0 25.9209Z"
        fill="#C6CCE1"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 25.9209C1 39.6804 12.2326 50.8419 26 50.8419C39.7674 50.8419 51 39.6804 51 25.9209C51 12.1614 39.7674 1 26 1C12.2326 1 1 12.1614 1 25.9209ZM26 51.8419C11.6843 51.8419 0 40.2367 0 25.9209C0 11.6052 11.6843 0 26 0C40.3157 0 52 11.6052 52 25.9209C52 40.2367 40.3157 51.8419 26 51.8419Z"
        fill="#C6CCE1"
      />
      <Mask id="mask0_3897_481976" maskUnits="userSpaceOnUse" x={3} y={10} width={46} height={42}>
        <Path
          d="M26.0676 28.5986C31.1085 28.5986 35.1949 24.5246 35.1949 19.499C35.1949 14.4735 31.1085 10.3995 26.0676 10.3995C21.0267 10.3995 16.9403 14.4735 16.9403 19.499C16.9403 24.5246 21.0267 28.5986 26.0676 28.5986Z"
          fill="white"
        />
        <Path
          d="M24.7637 31.1998H27.3715C32.904 31.2011 38.2094 33.3932 42.121 37.2938C46.0326 41.1945 48.23 46.4843 48.23 52V52H3.90002C3.90002 46.4834 6.09816 41.1928 10.0109 37.292C13.9236 33.3912 19.2303 31.1998 24.7637 31.1998V31.1998Z"
          fill="white"
        />
      </Mask>
      <G mask="url(#mask0_3897_481976)">
        <Path
          d="M49.4 26C49.4 38.9235 38.9235 49.4 26 49.4C13.0766 49.4 2.60004 38.9235 2.60004 26C2.60004 13.0765 13.0766 2.60001 26 2.60001C38.9235 2.60001 49.4 13.0765 49.4 26Z"
          fill="white"
        />
      </G>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26 49.8419C39.2606 49.8419 50 39.1264 50 25.9209C50 12.7155 39.2606 2 26 2C12.7394 2 2 12.7155 2 25.9209C2 39.1264 12.7394 49.8419 26 49.8419ZM26 51.8419C40.3594 51.8419 52 40.2367 52 25.9209C52 11.6052 40.3594 0 26 0C11.6406 0 0 11.6052 0 25.9209C0 40.2367 11.6406 51.8419 26 51.8419Z"
        fill="#C6CCE1"
      />
    </Svg>
  )
}
