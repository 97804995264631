import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { useTableorderOrderDetail } from './hooks'

import { TableorderTable } from '@rocket/types'
import type { OrderItemProps } from '../OrderItem'
import OrderRecipt from '../OrderRecipt'
import { useTableorderCatalog, useTableorderTable } from '../providers'
import type { ViewProps } from './types'

const ScreenView: React.FC<ViewProps> = ({}) => {
  const [currentTable, setCurrentTable] = React.useState<TableorderTable>()

  const { readTable } = useTableorderTable()
  const { catalogMenuList, catalogOptionList } = useTableorderCatalog()
  const { orderItem, onPressClose, onPressOrderStart, onPressOrderCancel } = useTableorderOrderDetail()

  React.useEffect(() => {
    if (orderItem) {
      readTable(orderItem.tableId).then(setCurrentTable)
    }
  }, [orderItem?.tableId])

  const orderMenuList = React.useMemo<OrderItemProps[]>(
    () =>
      orderItem?.items.map((o) => {
        const menu = catalogMenuList?.find(({ id }) => id === o.catalogMenuId)
        return {
          menu: menu?.name || '',
          option: catalogOptionList
            .filter(({ id }) => o.catalogSelectedOptionIds.includes(id))
            .map(({ name }) => name)
            .join(', '),
          count: o.quantity,
          estimatedCookingMinute: menu?.estimatedCookingMinute || 0,
        }
      }) ?? [],
    [catalogMenuList, catalogOptionList, orderItem],
  )

  const handlePressStart = React.useCallback(
    (minute: number) => {
      orderItem && onPressOrderStart(orderItem.id, minute)
    },
    [orderItem],
  )
  const handlePressCancel = React.useCallback(() => {
    orderItem && onPressOrderCancel(orderItem.id)
  }, [orderItem])

  if (orderItem === undefined) return <></>

  return (
    <View style={[styles.container, { left: 210 }]}>
      <Pressable
        style={[
          styles.container,
          {
            left: 315,
            backgroundColor: COLOR.mono.black,
            opacity: 0.7,
          },
        ]}
        onPress={onPressClose}
      />
      <OrderRecipt
        tableName={currentTable?.name ?? ''}
        orderDate={orderItem.createdAt}
        menuList={orderMenuList || []}
        price={orderItem.price}
        onPressStart={handlePressStart}
        onPressCancel={handlePressCancel}
      />
    </View>
  )
}

export default ScreenView

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
  },
})
