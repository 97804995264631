import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import * as React from 'react'
import { Pressable, type ViewStyle } from 'react-native'
import { Dropdown } from '../../../foundations'

import type { TableorderMenuOptionGroupType } from '@rocket/types'
import { changeItemAtIndex, COLOR, removeItemAtIndex } from '@rui/foundations'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTableorderMenuOption } from '../../providers'
import { TableorderMenuDetailOptionItem } from './TableorderMenuDetailOptionItem'

interface Props {
  type: TableorderMenuOptionGroupType
  title: string
  optionIds: string[]
  handleChange: (key: string, value: string[]) => void
  containerStyle?: ViewStyle
  onPressOption?: (groupType: TableorderMenuOptionGroupType) => void
}

const TableorderMenuDetailOptionGroup: React.FC<Props> = ({ type, title, optionIds, handleChange, onPressOption }) => {
  const { menuOptionGroupList } = useTableorderMenuOption()
  const selectOptionGroup = React.useCallback(
    (type: TableorderMenuOptionGroupType) =>
      menuOptionGroupList
        .filter((o) => o.type === type)
        .map(({ id: key, name: label }) => ({
          key,
          label,
        })),
    [menuOptionGroupList],
  )

  const selectedCount = React.useMemo(() => {
    return selectOptionGroup(type).filter(({ key }) => optionIds.includes(key)).length
  }, [selectOptionGroup, type, optionIds])

  const handlePressOption = React.useCallback(() => {
    onPressOption && onPressOption(type)
  }, [onPressOption, type])

  return (
    <View style={{ marginTop: 4 }}>
      <View
        style={{
          height: 40,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text style={{ marginRight: 20 }} fontName="textRegular" fontColor="mono.black">
          {title}
        </Text>

        <View
          style={{
            flex: 1,
            marginTop: 4,
          }}
        >
          {selectOptionGroup(type).length > 0 ? (
            <Dropdown
              label=""
              multiple
              list={selectOptionGroup(type)}
              onSelect={({ key }) => {
                const idx = optionIds.findIndex((o) => o === key)
                handleChange('optionGroupIds', idx < 0 ? [...optionIds, key] : removeItemAtIndex(optionIds, idx))
              }}
            >
              <Text>
                {selectedCount > 0
                  ? `${selectedCount}개의 ${type === 'REQUIRED' ? '필수' : '선택'}옵션이 선택되었습니다`
                  : '옵션 없음'}
              </Text>
            </Dropdown>
          ) : (
            <Pressable onPress={handlePressOption}>
              <Text>옵션 없음</Text>
            </Pressable>
          )}
        </View>
      </View>
      <View style={{ marginVertical: 12 }}>
        <DragDropContext
          onDragEnd={(result: DropResult) => {
            if (result.destination) {
              const prevList = optionIds.filter(
                (id) =>
                  !selectOptionGroup(type)
                    .map((o) => o.key)
                    .includes(id),
              )
              const changeList = optionIds.filter((id) =>
                selectOptionGroup(type)
                  .map((o) => o.key)
                  .includes(id),
              )

              handleChange('optionGroupIds', [
                ...prevList,
                ...changeItemAtIndex(changeList, result.source.index, result.destination.index),
              ])
            }
          }}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {optionIds
                  .filter((id) => selectOptionGroup(type).find(({ key }) => key === id) !== undefined)
                  .map((id) => selectOptionGroup(type).find(({ key }) => key === id))
                  .map((item, index) =>
                    item !== undefined ? (
                      <Draggable key={`drag-${index}`} draggableId={`drag-${index}`} index={index}>
                        {(provided) => {
                          return (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <TableorderMenuDetailOptionItem
                                key={item.key}
                                name={item.label}
                                dragHandleProps={provided.dragHandleProps}
                                onPressDelete={() => {
                                  console.log('item', item)
                                  const idx = optionIds.findIndex((key) => key === item.key)

                                  if (idx >= 0) handleChange('optionGroupIds', removeItemAtIndex(optionIds, idx))
                                }}
                              />
                            </div>
                          )
                        }}
                      </Draggable>
                    ) : null,
                  )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </View>
      <View
        style={{
          flex: 1,
          borderBottomWidth: 1,
          borderBottomColor: COLOR.gray.g200,
        }}
      />
    </View>
  )
}

export default TableorderMenuDetailOptionGroup
