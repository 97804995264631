import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { View } from '@rocket-mono/foundations'
import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import { FAIcon, Text, WorkIcon, XEIcon } from '@rocket/atoms'
import { TextField } from '@rui/atoms'
import { Pressable, ScrollView, StyleSheet } from 'react-native'
import SelectMemberList from '../MemberList/selectMemberList'
import { MemberListItem } from '../MemberList/types'

interface Props {
  workName: string
  workType: string
  secureCdnUrl?: string
  height?: number
  inviteList: MemberListItem[]
  onPressClose: () => void
}
const MemberInviteWorkDetail = ({ workName, workType, secureCdnUrl, height, inviteList, onPressClose }: Props) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const inputRef = React.useRef(null)

  return (
    <View style={[styles.container, { maxHeight: height }]}>
      <View style={styles.headerArea}>
        <View style={styles.workTitle}>
          <View style={styles.workName}>
            <WorkIcon type={workType} size={20} />
            <Text style={styles.workNameText} numberOfLines={1} ellipsizeMode="tail">
              {workName}
            </Text>
          </View>
          <Pressable onPress={onPressClose}>
            <FAIcon iconName={faXmark} color="gray.g600" />
          </Pressable>
        </View>

        <View>
          <TextField
            placeholder={t('workinvite.invite.fromwork.detail.searchplaceholder')}
            // height={36}
            value={searchText}
            style={{ width: '100%' }}
            afterIcon={
              <View>
                {searchText ? (
                  <Pressable onPress={() => setSearchText('')}>
                    <XEIcon name="close-circle" color="gray.g400" size={16} />
                  </Pressable>
                ) : (
                  <></>
                )}
              </View>
            }
            // rightIcon={
            //   searchText
            //     ? {
            //         name: 'close-circle',
            //         color: 'gray.g400',
            //         onPress: () => setSearchText(''),
            //       }
            //     : undefined
            // }
            // rightIconSize={16}
            // rightIcon2={{ name: 'search', color: 'gray.g700' }}
            // rightIconSize2={16}
            // rightIconStyle2={{ marginTop: -2, marginLeft: 8 }}
            onChangeValue={(text) => {
              setSearchText(text)
            }}
          />
        </View>
      </View>

      <ScrollView style={styles.listArea}>
        <SelectMemberList secureCdnUrl={secureCdnUrl || ''} inviteList={inviteList} />
      </ScrollView>
    </View>
  )
}

export default MemberInviteWorkDetail

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 8,
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 0),
    paddingVertical: 12,
    backgroundColor: COLOR.mono.white,
  },
  headerArea: {
    backgroundColor: COLOR.mono.white,
    paddingBottom: 12,
    paddingHorizontal: 12,
  },
  workTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  workName: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  workNameText: {
    ...FONT.txtMdMedium,
    marginLeft: 4,
  },
  listArea: {
    flex: 1,
  },
})
