import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg'

const CreateWorkButtonInChatList: React.FC<{ onPress?: () => void }> = ({ onPress }) => {
  const { t } = useTranslation()

  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.inner}>
        <Svg width={39} height={38} viewBox="0 0 39 38" fill="none">
          <Path
            d="M38.6552 19C38.6552 29.4934 30.0019 38 19.3276 38C8.65326 38 0 29.4934 0 19C0 8.50659 8.65326 0 19.3276 0C30.0019 0 38.6552 8.50659 38.6552 19Z"
            fill="#5EBCCA"
          />
          <G clipPath="url(#clip0_2285_116435)">
            <Path
              d="M28.1262 8C28.45 8.11804 28.7433 8.26715 28.8899 8.61507C28.9999 8.87601 29.0366 9.13695 28.951 9.39789C28.4378 11.0132 27.9307 12.6286 27.393 14.2315C27.0814 15.1448 26.5437 15.9338 25.9144 16.6607C24.5946 18.1829 23.281 19.705 21.9551 21.221C21.8329 21.3576 21.8268 21.4633 21.8818 21.6372C22.7005 24.315 22.2423 26.7504 20.4704 28.9063C19.8166 29.7015 18.9979 30.3042 18.0753 30.7453C17.5254 31.0062 16.9755 30.6707 16.9571 30.0494C16.9266 29.0864 16.78 28.1421 16.4134 27.2474C15.8512 25.8806 14.9714 24.7685 13.7494 23.9546C12.5335 23.1407 11.1954 22.7493 9.74737 22.7493C9.46631 22.7493 9.19136 22.7804 8.9103 22.8052C8.24431 22.8612 7.84105 22.3455 8.06712 21.7118C9.03861 18.9968 10.9022 17.3193 13.6944 16.7353C14.6354 16.5427 15.5824 16.5737 16.5233 16.785C16.6516 16.816 16.725 16.785 16.8044 16.6856C18.0936 15.1945 19.3889 13.7158 20.672 12.2185C21.5213 11.2245 22.5172 10.4479 23.6965 9.90113C24.9246 9.32955 26.1527 8.74554 27.3747 8.16153C27.4908 8.10562 27.613 8.05592 27.729 8C27.8635 8 27.9918 8 28.1262 8ZM23.617 15.9214C24.6741 15.9587 25.4989 15.1448 25.5417 14.0203C25.5783 13.0076 24.7352 12.1378 23.6598 12.1129C22.6761 12.0943 21.8085 12.9206 21.784 14.0078C21.7657 15.0205 22.5722 15.8841 23.6109 15.9214H23.617Z"
              fill="white"
            />
            <Path
              d="M15.3621 27.0672C15.2521 27.2846 15.1849 27.5269 15.0321 27.7071C14.2012 28.6887 13.358 29.6579 12.5148 30.6209C12.1177 31.0745 11.5311 31.1242 11.1156 30.7576C10.6879 30.3849 10.6513 29.7822 11.0484 29.3162C11.8855 28.3346 12.7348 27.3592 13.5902 26.39C13.8896 26.0483 14.3112 25.9551 14.69 26.1104C15.0627 26.2657 15.3071 26.6385 15.301 27.0423C15.3193 27.0485 15.3376 27.061 15.356 27.0672H15.3621Z"
              fill="white"
            />
            <Path
              d="M12.2403 24.0725C12.5458 24.085 12.8513 24.2714 13.0163 24.6441C13.1996 25.048 13.1141 25.4207 12.8391 25.7438C12.2403 26.4396 11.6354 27.1355 11.0305 27.8313C10.7617 28.142 10.5051 28.4588 10.2179 28.7446C9.69246 29.2603 8.83096 29.018 8.62933 28.3097C8.53768 27.9804 8.58656 27.6636 8.81263 27.4026C9.68635 26.3899 10.5601 25.3835 11.4399 24.377C11.6232 24.1657 11.8676 24.0663 12.2464 24.0725H12.2403Z"
              fill="white"
            />
          </G>
          <Path
            d="M38.7246 30C38.7246 34.4183 35.1429 38 30.7246 38C26.3063 38 22.7246 34.4183 22.7246 30C22.7246 25.5817 26.3063 22 30.7246 22C35.1429 22 38.7246 25.5817 38.7246 30Z"
            fill="#1F2940"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.9746 34L29.9746 26L31.4746 26L31.4746 34L29.9746 34Z"
            fill="white"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.7246 30.75L26.7246 30.75L26.7246 29.25L34.7246 29.25L34.7246 30.75Z"
            fill="white"
          />
          <Defs>
            <ClipPath id="clip0_2285_116435">
              <Rect width={21} height={23} fill="white" transform="translate(8 8)" />
            </ClipPath>
          </Defs>
        </Svg>
        <Text style={{ ...FONT.txtMdMedium, marginLeft: 8 }}>{t('layoutsidenav.newwork')}</Text>
      </View>
      <XEIcon name={'angle-right'} size={12} style={{ marginTop: -2 }} />
    </Pressable>
  )
}

export default CreateWorkButtonInChatList

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    zIndex: 99,
  },
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
