import * as React from 'react'

import type { RenderItemType } from './types'

import type { UserState } from '@rocket/types'
import { ChatMessage } from '../../components'

interface Props {
  item: RenderItemType
  state?: UserState
  onPress?: (itemKey: string) => void
  onLoad?: (itemKey: string) => void
}

export const ChatsItem: React.FC<Props> = ({ item, state, onPress, onLoad }): JSX.Element => {
  const { key, profile, message, title } = item
  const isSkelaton = React.useMemo(
    () => profile === undefined || message === undefined || title === undefined,
    [profile, message, title],
  )

  React.useEffect(() => {
    if (onLoad) onLoad(key)
  }, [isSkelaton])

  return <ChatMessage {...item} state={state} isSkelaton={isSkelaton} onPress={() => onPress && onPress(key)} />
}
