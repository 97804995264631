/* eslint-disable @typescript-eslint/ban-ts-comment */
import { COLOR, FONT } from '@rui/foundations'
import { Platform, StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  container: {
    top: 0,
    width: '100%',
    flexDirection: 'row' as const,
    justifyContent: 'space-between' as const,
    alignItems: 'flex-start' as const,
    paddingHorizontal: 20,
    paddingVertical: 20,
    zIndex: 20,
    ...Platform.select({
      web: {
        height: 80,
        // position: 'absolute',
      },
      // native: { position: 'relative' },
    }),
  },
  containerMobile: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    backgroundColor: COLOR.gray.g050,
  },
  contentsArea: {
    flex: 3,
    flexDirection: 'row' as const,
    justifyContent: 'space-between' as const,
    alignItems: 'flex-end' as const,
    maxWidth: 1000,
    width: '100%',
    ...Platform.select({
      web: { marginVertical: 20 },
      native: { marginTop: 2 },
    }),
  },
  inner: {
    flexDirection: 'row' as const,
    alignItems: 'center' as const,
  },
  buttonIcon: {
    flexDirection: 'row' as const,
    justifyContent: 'center' as const,
    alignItems: 'center' as const,
    minWidth: 'auto',
    height: 36,
    paddingHorizontal: 12,
  },
  buttonIconMobile: {
    width: 36,
    paddingHorizontal: 0,
  },
  title: {
    marginBottom: 4,
  },
  infoTxt: {
    ...FONT.txtSm,
  },
  stickyContainer: {
    // @ts-ignore
    position: 'sticky' as const,
    display: 'flex' as const,
    flexDirection: 'row' as const,
    top: 0,
    width: '100%',
    height: 108,
    backgroundColor: 'rgba(255,255,255, .93)',
    justifyContent: 'space-between' as const,
    alignItems: 'flex-start' as const,
    zIndex: 30,
  },
  stickyContainerV2: {
    // @ts-ignore
    position: 'sticky' as const,
    display: 'flex' as const,
    flexDirection: 'row' as const,
    top: 0,
    width: '100%',
    height: 'auto',
    backgroundColor: 'rgba(255,255,255, .93)',
    justifyContent: 'space-between' as const,
    alignItems: 'flex-start' as const,
    zIndex: 30,
  },
})
