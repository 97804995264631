import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { WorkSettingTimezone } from '../../../components'

interface Props {
  onPressBack?: () => void
}
const TimezoneView: React.FC<Props> = ({ onPressBack }) => {
  const { t, i18n } = useTranslation()
  const timezoneData = useMemo(
    () => [
      { label: t('setting.timezone.auto') /*'자동으로 설정'*/, key: '1' },
      // {label: '한국 표준 시', key: '2'},
      // {label: 'USA 표준 시', key: '3'},
      // {label: '인도차이나 표준시', key: '4'},
    ],
    [t],
  )
  const timezoneDropdownList = useMemo(
    () => [
      {
        group: [
          {
            id: 1,
            title: t('setting.timezone.auto'), // '자동으로 설정',
            code: '1',
          },
        ],
      },
      // {
      //   group: [
      //     {
      //       id: 1,
      //       title: '한국 표준 시',
      //       code: '2',
      //     },
      //   ],
      // },
      // {
      //   group: [
      //     {
      //       id: 1,
      //       title: 'USA 표준 시',
      //       code: '3',
      //     },
      //   ],
      // },
      // {
      //   group: [
      //     {
      //       id: 1,
      //       title: '인도차이나 표준시',
      //       code: '4',
      //     },
      //   ],
      // },
    ],
    [t],
  )

  return (
    <WorkSettingTimezone
      data={timezoneData}
      appData={timezoneDropdownList}
      defaultIndex={0}
      onSelected={(code) => console.log('time onSelected', code)}
      onPressSave={() => console.log('time onPressSave')}
      onDismiss={onPressBack}
    />
  )
}

export default TimezoneView
