import { Avatar, Button, TextField, Tooltip } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, ScrollView, StyleSheet, Text, TextInput, View } from 'react-native'
import { ProfileEdit, SnsSquareIcon, SnsType } from '../Icons'
import Header from './header'
import { Props } from './types'

const WorkSettingUserInfoView = ({
  userName,
  userEmail,
  userProfile,
  userSns,
  onChangeName,
  onPressBack,
  onPressPassword,
  onPressProfile,
  onPressSave,
  onLeave,
  containerStyle,
}: Props) => {
  const { t } = useTranslation()
  const [name, setName] = React.useState(userName)
  const disabled = React.useMemo(() => name === userName, [name, userName])
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.contents}>
        <Header buttonBack title={t('setting.userinfo.title')} textAlignCenter onBack={onPressBack} isBorder={false} />
        <ScrollView style={styles.form}>
          <View>
            <View style={[styles.formGroup, { alignItems: 'center' }]}>
              <View>
                <Pressable onPress={onPressProfile}>
                  <Avatar profileUrl={userProfile} size="xlarge" />
                  <View style={styles.profileEdit}>
                    <ProfileEdit size={32} />
                  </View>
                </Pressable>
              </View>
            </View>
            <View style={styles.formGroup}>
              <Text style={styles.label}>{t('setting.userinfo.emaillabel')}</Text>
              <TextInput value={userEmail} editable={false} selectTextOnFocus={false} style={styles.input} />
            </View>
            <View style={styles.formGroup}>
              <Text style={styles.label}>{t('setting.userinfo.nicknamelabel')}</Text>
              <TextField
                value={name}
                onChangeValue={(text) => {
                  setName(text)
                  onChangeName(text)
                }}
                placeholder={t('setting.userinfo.inputplaceholder')}
                // height={36}
              />
            </View>
            {userSns.length > 0 ? (
              <View style={styles.formGroup}>
                <Text style={styles.label}>{t('setting.userinfo.snslabel')}</Text>
                {/* 연동된 계정 */}
                <View style={styles.accountContainer}>
                  {userSns.map((sns) => (
                    <Tooltip
                      key={sns.snsCd}
                      text={sns.snsCd}
                      direction="bottom"
                      align="center"
                      // gab={4}
                      // darkType
                    >
                      <View style={styles.snsIcon}>
                        <SnsSquareIcon type={sns.snsCd as SnsType} size={32} />
                      </View>
                    </Tooltip>
                  ))}
                </View>
              </View>
            ) : (
              <></>
            )}
            <View style={styles.formGroup}>
              <Text style={styles.label}>{t('setting.userinfo.passwordlabel')}</Text>
              <Button
                // wide={true}
                // backgroundColor={'mono.white'}
                // borderColor={'gray.g300'}
                size="lg"
                onPress={onPressPassword}
                style={{ backgroundColor: COLOR.mono.white, borderWidth: 1, borderColor: COLOR.gray.g300 }}
                text={<Text>{t('setting.userinfo.passwordbutton')}</Text>}
              />
            </View>
          </View>
          {onLeave ? (
            <View style={{ marginTop: 20 }}>
              <Text style={styles.label}>{t('setting.userinfo.leavelabel')}</Text>
              <Button
                // backgroundColor={'transparent'}
                style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: COLOR.transparent.base }}
                onPress={onLeave}
                text={
                  <>
                    <Text style={{ ...FONT.txtXs }}>{t('setting.userinfo.leavebutton')}</Text>
                    <XEIcon name={'angle-right-min'} size={12} />
                  </>
                }
              />
            </View>
          ) : (
            <></>
          )}
        </ScrollView>
      </View>
      <Button
        // wide={true}
        size={'lg'}
        style={{ borderRadius: 0 }}
        onPress={onPressSave}
        disabled={disabled}
        text={<Text style={{ ...FONT.H7Medium, color: COLOR.mono.white }}>{t('setting.userinfo.checkbutton')}</Text>}
      />
    </View>
  )
}

export default WorkSettingUserInfoView

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: COLOR.mono.white,
  },
  form: {
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  formGroup: {
    marginBottom: 16,
  },
  contents: {
    flex: 1,
  },
  input: {
    height: 38,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.gray.g100,
    paddingLeft: 12,
    borderRadius: 6,
  },
  label: {
    ...FONT.txtSm,
    marginBottom: 4,
  },
  profileEdit: {
    position: 'absolute',
    right: -4,
    bottom: -4,
  },
  accountContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
    backgroundColor: COLOR.gray.g050,
    padding: 12,
    paddingHorizontal: 8,
    borderRadius: 8,
  },
  snsIcon: {
    marginHorizontal: 4,
  },
})
