import * as React from 'react'
import { StyleSheet, View } from 'react-native'

import { useKeyboard, useMobileView } from '@rocket-mono/libs'

import { Text } from '@rocket/atoms'
import { Stepper, StepperProps } from '../components'

interface Props {
  title: string
  info: string
  stepper: StepperProps[]
  step: number
  setStep: (step: number) => void
}

export const Header: React.FC<Props> = ({ title, info, stepper, step, setStep }) => {
  const mobileView = useMobileView(1000)
  const { visible } = useKeyboard()

  if (mobileView && visible) return <></>
  return (
    <View style={[styles.header, mobileView && { paddingTop: 26, paddingHorizontal: 36 }]}>
      <View style={{ marginBottom: 20 }}>
        <Text fontName="H5Bold" style={styles.pageTitle}>
          {title}
        </Text>
        <Text fontName="txtXs">{info}</Text>
      </View>
      <Stepper mobileView={mobileView} list={stepper} listLength={2} here={step} onCode={setStep} />
    </View>
  )
}

export const styles = StyleSheet.create({
  header: {
    marginBottom: 16,
  },
  pageTitle: {
    marginBottom: 4,
  },
})
