import type {
  TableorderOrder,
  TableorderOrderBasket,
  TableorderOrderBasketItem,
  TableorderOrderBasketItemPayload,
  TableorderOrderBasketPayload,
  TableorderOrderCreatePayload,
  TableorderOrderStatus,
} from '@rocket/types'
import * as React from 'react'

import { removeItemAtIndex, replaceItemAtIndex } from '@rui/foundations'
import moment from 'moment'
import { useTableorderShop } from '../ShopProvider'
import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ astro, subscribe, children }: ProviderProps) => {
  const { shopId } = useTableorderShop()
  const [orderList, setOrderList] = React.useState<TableorderOrder[]>()

  const updateOrderList = React.useCallback((item: TableorderOrder) => {
    setOrderList((prev) => {
      if (prev === undefined) return [item]
      const idx = prev.findIndex(({ id }) => id === item.id)
      return idx < 0 ? [...prev, item] : replaceItemAtIndex(prev, idx, item)
    })
    return item.id
  }, [])

  const createOrder = React.useCallback((payload: TableorderOrderCreatePayload) => {
    return astro
      .createOrder(payload)
      .then(updateOrderList)
      .catch(() => Promise.reject())
  }, [])

  const updateOrderStatus = React.useCallback(
    (id: string, status: TableorderOrderStatus, estimatedCookingCompletionAt?: Date) => {
      const order = orderList?.find((o) => o.id === id)
      return new Promise<string>((resolve, reject) => {
        if (order && order.status === 'COOKING' && order.status === status) {
          reject('이미 조리중입니다.')
        }
        if (order && estimatedCookingCompletionAt !== undefined)
          astro
            .updateOrder(id, {
              ...order,
              estimatedCookingCompletionAt: moment(estimatedCookingCompletionAt).format('YYYY-MM-DDTHH:mm:ss'),
              status,
            })
            .then(updateOrderList)
            .then(resolve)
        else {
          astro.updateOrderStatus(id, { status }).then(updateOrderList).then(resolve)
        }
      })
    },
    [orderList],
  )

  const [basket, setBasket] = React.useState<TableorderOrderBasket>()
  const [basketItemList, setBasketItemList] = React.useState<TableorderOrderBasketItem[]>([])
  const clearBasket = React.useCallback(() => {
    setBasket(undefined)
    setBasketItemList([])
  }, [])
  const updateBasketItemList = React.useCallback((item: TableorderOrderBasketItem) => {
    setBasketItemList((prev) => {
      const idx = prev.findIndex(({ id }) => id === item.id)
      return idx < 0 ? [...prev, item] : replaceItemAtIndex(prev, idx, item)
    })
  }, [])
  const deleteBasketItemList = React.useCallback((item: TableorderOrderBasketItem) => {
    setBasketItemList((prev) => {
      const idx = prev.findIndex(({ id }) => id === item.id)
      return idx < 0 ? prev : removeItemAtIndex(prev, idx)
    })
  }, [])

  const createBasket = React.useCallback((payload: TableorderOrderBasketPayload) => {
    return astro
      .createBasket(payload)
      .then((res) => {
        console.log(res)
        return res
      })
      .then(setBasket)
  }, [])

  const createBasketItem = React.useCallback(
    (payload: TableorderOrderBasketItemPayload) => {
      if (basket) {
        return astro
          .createBasketItem(basket.id, payload)
          .then((res) => {
            console.log(res)
            return res
          })
          .then(updateBasketItemList)
      } else {
        return Promise.reject()
      }
    },
    [basket],
  )

  const increaseBasketItem = React.useCallback(
    (id: string) => {
      if (basket) {
        astro.increaseBasketItem(basket.id, id).then(updateBasketItemList)
      }
    },
    [basket],
  )
  const decreaseBasketItem = React.useCallback(
    (id: string) => {
      if (basket) {
        astro.decreaseBasketItem(basket.id, id).then(updateBasketItemList)
      }
    },
    [basket],
  )
  const deleteBasketItem = React.useCallback(
    (id: string) => {
      if (basket) {
        astro.deleteBasketItem(basket.id, id).then(deleteBasketItemList)
      }
    },
    [basket],
  )

  const fetchOrder = React.useCallback((orderId: string) => {
    astro.readOrder(orderId).then(updateOrderList)
  }, [])

  const fetchOrderList = React.useCallback((shopId: string) => {
    astro.readOrderListByShopId(shopId).then(setOrderList)
  }, [])

  React.useEffect(() => {
    fetchOrderList(shopId)
  }, [shopId])

  console.log('orderList', orderList)

  subscribe(
    [`/subscribe/table-order/shop/${shopId}/order/create`, `/subscribe/table-order/shop/${shopId}/order/update`],
    ({ body: orderId }: any) => {
      console.log(`/subscribe/table-order/shop/${shopId}/order/done`, orderId)
      fetchOrder(orderId)
    },
  )

  return (
    <Context.Provider
      value={{
        orderList,
        createOrder,
        basket,
        basketItemList,
        updateOrderStatus,
        createBasket,
        createBasketItem,
        increaseBasketItem,
        decreaseBasketItem,
        deleteBasketItem,
        clearBasket,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
