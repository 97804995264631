import { ChatsScreen, useChatsData } from '../ChatsScreen'

import { useAstro, useSubscription } from '@rocket-mono/providers'

import React, { useCallback, useEffect } from 'react'

interface Props {
  isShow: boolean
  setUnreadCount: (unread: number) => void
  onPressButtonCreate?: () => void
}

export const MessageListView: React.FC<Props> = ({ isShow, setUnreadCount, onPressButtonCreate }) => {
  const { astro, astroNav, option } = useAstro()

  const chatsScreenProps = useChatsData()

  const handlePressItem = useCallback(
    (itemKey: string) => {
      const { itemList } = chatsScreenProps
      const channel = itemList?.find((o) => o.key === itemKey)
      console.log('channel', channel)
      if (channel) {
        const action = 'openChat'
        const payload = { channelRoomId: channel.channelRoomId }
        astroNav.emit(action, payload)
      }
    },
    [astroNav, chatsScreenProps],
  )

  useEffect(() => {
    setUnreadCount(chatsScreenProps.itemList?.map((o) => o.unreadCount).reduce((a, b) => a + b, 0) || 0)
  }, [setUnreadCount, chatsScreenProps.itemList])

  if (!isShow) return null
  return (
    <ChatsScreen
      {...chatsScreenProps}
      secureCdnUrl={option.secureCdnUrl || ''}
      titleVisible={false}
      onPressItem={handlePressItem}
      onPressButtonCreate={onPressButtonCreate}
      astro={astro}
      subscribe={useSubscription}
    />
  )
}
