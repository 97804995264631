import * as React from 'react'
import { StyleSheet } from 'react-native'
import { useTableorderCatalog, useTableorderOrder, useTableorderShop, useTableorderTable } from '../../providers'

import { View } from '@rocket-mono/foundations'
import { Text, useToast } from '@rui/atoms'
import { COLOR } from '@rui/foundations'

import type { OrderItemProps } from '../../OrderItem'
import TableorderHeader from '../../TableorderHeader'

import { XEIcon } from '@rui/icons'
import moment from 'moment'
import OrderRecipt from '../../OrderRecipt'

interface Props {
  tableId: string
  orderId: string
  onPressClose: () => void
}

const TableorderOrderSuccessView: React.FC<Props> = ({ tableId, orderId, onPressClose }) => {
  const { show: showToastMessage } = useToast()

  const { shop } = useTableorderShop()
  const { tableList } = useTableorderTable()

  const { catalogMenuList, catalogOptionList } = useTableorderCatalog()
  const { orderList, updateOrderStatus } = useTableorderOrder()

  const currentTable = React.useMemo(() => tableList.find(({ id }) => id === tableId), [tableList, tableId])

  const orderItem = React.useMemo(() => orderList?.find(({ id }) => id === orderId), [orderList])

  const orderMenuList = React.useMemo<OrderItemProps[]>(() => {
    if (orderItem === undefined) return []
    return orderItem.items.map((o) => {
      const menu = catalogMenuList.find(({ id }) => id === o.catalogMenuId)
      return {
        menu: menu?.name || '',
        option: catalogOptionList
          .filter(({ id }) => o.catalogSelectedOptionIds.includes(id))
          .map(({ name }) => name)
          .join(', '),
        count: o.quantity,
        estimatedCookingMinute: menu?.estimatedCookingMinute || 0,
      }
    })
  }, [catalogMenuList, catalogOptionList, orderItem])

  const handleRefresh = React.useCallback(() => {
    window.location.reload()
  }, [])

  const onPressOrderCancel = React.useCallback(() => {
    orderItem && updateOrderStatus(orderItem.id, 'CANCELED').then(handleRefresh)
  }, [orderItem])

  const onPressOrderStart = React.useCallback(
    (minute: number) => {
      const estimatedCookingCompletionAt = moment().add(minute, 'minutes').toDate()
      orderItem &&
        updateOrderStatus(orderItem.id, 'COOKING', estimatedCookingCompletionAt)
          .catch((message) => {
            showToastMessage({
              type: 'Danger',
              title: message,
              position: 'TOP_RIGHT',
            })
          })
          .then(handleRefresh)
    },
    [orderItem],
  )

  return (
    <View style={styles.container}>
      <TableorderHeader
        storeName={shop?.name || ''}
        tableName={currentTable?.name || ''}
        tabCode=""
        onPress={onPressClose}
        iconName="close"
      />
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View
          style={{
            flex: 1,
            backgroundColor: COLOR.mono.paleWhite,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <XEIcon name="check-circle-o" color="main.blue" size={100} />
          <Text style={{ marginTop: 4 }} fontName="titleBold" fontColor="mono.black">
            주문이 추가되었습니다
          </Text>
          <Text style={{ marginTop: 22 }} fontName="listBold" fontColor="mono.black">
            조리 예상시간을 설정하고 조리를 시작해주세요.
          </Text>
          <Text style={{ marginTop: 4, textAlign: 'center' }} fontName="textRegular" fontColor="mono.black">
            {`조리 시작 버튼을 클릭하지 않고 본 페이지를 벗어나는 경우\r\n주문 리스트에서 주문 카드를 선택하여 시작해야\r\n테이블 주문 현황에 반영됩니다.`}
          </Text>
        </View>
        <View style={{ width: 316, backgroundColor: COLOR.mono.white }}>
          {orderItem && (
            <OrderRecipt
              headerVisible={false}
              tableName={currentTable?.name || ''}
              orderDate={orderItem.createdAt}
              menuList={orderMenuList}
              price={orderItem.price}
              onPressStart={onPressOrderStart}
              onPressCancel={onPressOrderCancel}
            />
          )}
        </View>
      </View>
    </View>
  )
}

export default TableorderOrderSuccessView

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
