import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { faUser } from '@fortawesome/free-solid-svg-icons'
import { View } from '@rocket-mono/foundations'
import { COLOR, FONT, getBoxShadow } from '@rocket-mono/libs'
import { FAIcon, Text, WorkIcon } from '@rocket/atoms'
import { DimensionValue, Pressable, ScrollView, StyleSheet } from 'react-native'
import { MemberListItem } from '../MemberList/types'

export interface WorkListType {
  id: string
  type: string
  name: string
  active?: number
  detailWork: MemberListItem[]
}

interface Props {
  scrollViewHeight?: DimensionValue
  workList: WorkListType[]
  onPress: (item: WorkListType) => void
}

const Item = ({ item, onPress }: { item: WorkListType; onPress: (item: WorkListType) => void }) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <Pressable
      onPress={() => onPress(item)}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[styles.itemContainer, isHover && styles.itemHoverContainer]}
    >
      <View style={styles.nameArea}>
        <WorkIcon type={item.type} size={20} />
        <Text style={styles.worknameStyle} numberOfLines={1} ellipsizeMode="tail">
          {item.name}
        </Text>
      </View>
      {item.active && (
        <View style={styles.stateLabel}>
          <FAIcon iconName={faUser} size={'xs'} color={'gray.g400'} />
          <Text style={{ marginLeft: 2, ...FONT.txtSm, color: COLOR.gray.g600 }}>{item.active}</Text>
        </View>
      )}
    </Pressable>
  )
}

const MemberInviteWorkList: React.FC<Props> = ({ scrollViewHeight, workList, onPress }) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Text style={styles.sectionTitle}>{t('workinvite.invite.fromwork.label')}</Text>
      <ScrollView
        style={{ paddingHorizontal: 2, height: scrollViewHeight }}
        contentContainerStyle={{ flex: 1, height: '100%' }}
      >
        {workList.map((item, idx) => (
          <View style={styles.itemWrap} key={`workList-item${idx}`}>
            <Item item={item} onPress={onPress} />
          </View>
        ))}
      </ScrollView>
    </View>
  )
}

export default MemberInviteWorkList

export const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 12,
    paddingVertical: 10,
    borderRadius: 8,
    backgroundColor: COLOR.mono.white,
  },
  nameArea: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemHoverContainer: {
    ...getBoxShadow(0, 0, 0, 0.15, 2, 0, 0, 0),
  },
  itemWrap: {
    marginBottom: 8,
  },
  sectionTitle: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g600,
    marginBottom: 8,
  },
  worknameStyle: {
    ...FONT.txtMdMedium,
    marginTop: 2,
    marginLeft: 8,
  },
  stateLabel: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    borderRadius: 4,
    backgroundColor: COLOR.gray.g100,
  },
})
