import * as React from 'react'

import { COLOR, FONT } from '@rui/foundations'
import { StyleSheet, Text, View } from 'react-native'

interface Props {
  storeName: string
  tableName: string
  height?: number
}
const CartTableInfo: React.FC<Props> = ({ storeName, tableName, height }) => {
  return (
    <View style={[styles.container, { height }]}>
      <Text style={styles.storeName}>{storeName}</Text>
      <View style={styles.flexBox}>
        <Text style={styles.tableLabel}>테이블</Text>
        <View style={styles.divLine}></View>
        <Text style={styles.tabelName}>{tableName}</Text>
      </View>
    </View>
  )
}

export default CartTableInfo

const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: COLOR.gray.g050,
  },
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  storeName: {
    ...FONT.txtMdBold,
    marginBottom: 4,
  },
  tableLabel: {
    ...FONT.H7Bold,
  },
  tabelName: {
    ...FONT.H7Medium,
    color: COLOR.gray.g600,
  },
  divLine: {
    width: 1,
    height: 16,
    backgroundColor: COLOR.gray.g200,
    marginHorizontal: 8,
  },
})
