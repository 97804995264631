import moment from 'moment'

export function isExpired(date: Date) {
  return date.getTime() - Date.now() < 0
}

export function relativeTime(date: Date, language?: string) {
  // const diff = date.getTime() - Date.now()
  // const diffAbs = Math.abs(diff)
  moment.locale(language)
  return moment(date).fromNow()

  // const formatter = new Intl.RelativeTimeFormat(language ?? 'ko', {
  //   style: 'long',
  // })

  // if (diffAbs < 1000 * 60) {
  //   return formatter.format(Math.floor(diff / 1000), 'second')
  // } else if (diffAbs < 1000 * 60 * 60) {
  //   return formatter.format(Math.floor(diff / (1000 * 60)), 'minute')
  // } else if (diffAbs < 1000 * 60 * 60 * 24) {
  //   return formatter.format(Math.floor(diff / (1000 * 60 * 60)), 'hour')
  // } else if (diffAbs < 1000 * 60 * 60 * 24 * 30) {
  //   return formatter.format(Math.floor(diff / (1000 * 60 * 60 * 24)), 'day')
  // } else if (diffAbs < 1000 * 60 * 60 * 24 * 30 * 12) {
  //   return formatter.format(
  //     Math.floor(diff / (1000 * 60 * 60 * 24 * 30)),
  //     'month',
  //   )
  // } else {
  //   return formatter.format(
  //     Math.floor(diff / (1000 * 60 * 60 * 24 * 30 * 12)),
  //     'year',
  //   )
  // }
}
