import * as React from 'react'

import { useTableorderMenu } from '../providers'
import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ astro, currentUser, children }: ProviderProps) => {
  const { menuForm, menuList, updateMenu, showMenuDetail, hideMenuDetail, newMenuDetail } = useTableorderMenu()
  const userName: string = React.useMemo(() => currentUser.userName, [currentUser])

  const onPressMenuDetail = React.useCallback(
    (categoryId: string, id?: string) => {
      hideMenuDetail()
      setTimeout(() => {
        const menu = menuList?.find((o) => (o.categoryId === categoryId && o.id) === id)
        if (menu) {
          showMenuDetail(menu)
        } else {
          newMenuDetail(categoryId)
        }
      }, 1)
    },

    [menuList, newMenuDetail, hideMenuDetail],
  )

  const toggleMenuSoldout = React.useCallback(
    (id: string) => {
      const menu = menuList?.find((o) => o.id === id)
      if (menu)
        updateMenu(id, {
          ...menu,
          isSoldout: !menu.isSoldout,
          deleteRequestImages: [],
        })
    },
    [astro, menuList],
  )
  const toggleMenuDisplay = React.useCallback(
    (id: string) => {
      const menu = menuList?.find((o) => o.id === id)
      if (menu)
        updateMenu(id, {
          ...menu,
          isDisplayed: !menu.isDisplayed,
          deleteRequestImages: [],
        })
    },
    [astro, menuList],
  )

  return (
    <Context.Provider
      value={{
        userName,
        menuForm,
        onPressMenuDetail,
        toggleMenuSoldout,
        toggleMenuDisplay,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
