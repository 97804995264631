/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { forwardRef } from 'react'
import type { SvgProps } from 'react-native-svg'
import { Circle, Path, Svg } from 'react-native-svg'

interface Props extends SvgProps {
  fillCircle?: string
  fillCheck?: string
}

const Check = ({ fillCircle = '#EEEEEE', fillCheck = 'white', ...props }: Props, ref: any) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" ref={ref} {...props}>
      <Circle cx="9" cy="9" r="9" fill={fillCircle} />
      <Path
        d="M8.17188 12.6719L4.82812 9.32812L5.82812 8.32812L8.17188 10.6562L13.1719 6L14.1719 7L8.17188 12.6719Z"
        fill={fillCheck}
      />
    </Svg>
  )
}

export default React.memo(forwardRef(Check))
