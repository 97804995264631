import { Icon } from '@rocket-atoms/icon'
import { ClickOutside, COLOR, getBoxShadow } from '@rocket-mono/libs'
import { useCurrentUser, useWorkCard } from '@rocket-mono/providers'
import React, { useCallback, useEffect, useState } from 'react'
import { Platform, StyleSheet, Text, TextInput, View } from 'react-native'

import SelectBox from '../../../../components/SelectBox'

import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Button'
import ButtonIcon from '../../../../components/ButtonIcon'

const OpinionForm = ({
  id,
  collectionId,
  groupId,
  content,
  isWriter,
  isPrivate,
}: {
  id: string
  collectionId: string
  groupId: string
  content: string
  isWriter: boolean
  isPrivate?: boolean
}) => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()
  const [visibleMore, setVisibleMore] = useState(false)
  const [editMode, setEditMode] = React.useState(false)
  const [height, setHeight] = React.useState(32)
  const [opinion, setOpinion] = React.useState(content)

  const { saveGatheringPiece, deleteGatheringPiece } = useWorkCard()

  const handlePressSubmit = useCallback(() => {
    if (opinion.trim()) {
      saveGatheringPiece({
        id,
        groupId,
        collectionId,
        content: opinion,
        type: 'OPINION',
        userId: String(currentUser.id),
      }).then(() => {
        setEditMode(false)
      })
    }
  }, [opinion, collectionId, groupId, saveGatheringPiece])

  const handlePressDelete = useCallback(() => {
    deleteGatheringPiece(collectionId, id)
  }, [id, collectionId, deleteGatheringPiece])

  useEffect(() => {
    setOpinion(content)
  }, [content])

  return (
    <>
      <TextInput
        multiline
        editable={editMode ? true : false}
        selectTextOnFocus={editMode ? true : false}
        value={!isWriter && isPrivate ? t('cardgathering.secret') : opinion}
        onChangeText={(text) => setOpinion(text)}
        onContentSizeChange={(event) => {
          // setHeight(event.nativeEvent.contentSize.height)
        }}
        style={[
          styles.opinionForm,
          !editMode && isWriter && { paddingRight: 36 },
          editMode && styles.editOpinion,
          !isWriter && isPrivate && styles.hiddenOpinion,
          {
            ...Platform.select({
              native: {
                height: height + 12,
              },
              web: {
                height,
              },
            }),
          },
        ]}
      />
      {!isWriter && isPrivate && <Icon name="lock-o" size={12} color={'gray.g500'} style={styles.lockIcon} />}
      {!editMode && isWriter && (
        <ButtonIcon
          iconName="ellipsis-h"
          iconSize={12}
          style={styles.opinionMore}
          onPress={() => setVisibleMore(true)}
        />
      )}
      {editMode && (
        <View style={styles.opinionEditControl}>
          <Button
            size="small"
            backgroundColor="transparent.base"
            onPress={() => {
              setOpinion(content)
              setEditMode(false)
            }}
            style={{ paddingHorizontal: 4 }}
          >
            <Text>{t('common.cancel')}</Text>
          </Button>
          <Button
            size="small"
            style={{
              flexDirection: 'row',
              marginLeft: 4,
              paddingHorizontal: 4,
            }}
            onPress={handlePressSubmit}
          >
            <Icon name="check-min" size={12} color={'mono.white'} />
            <Text style={{ color: COLOR.mono.white }}>{t('screen.workmodelupdate.dialog.save')}</Text>
          </Button>
        </View>
      )}
      {visibleMore && (
        <ClickOutside onClickOutside={() => setVisibleMore(false)}>
          <SelectBox style={{ top: 0, right: 16, zIndex: 14 }}>
            <SelectBox.Item
              text={t('card.collectunit.edit')}
              onPress={() => {
                setEditMode(true)
                setVisibleMore(false)
              }}
            />
            <SelectBox.Item text={t('cardauthority.delete')} onPress={handlePressDelete} />
          </SelectBox>
        </ClickOutside>
      )}
    </>
  )
}

export default OpinionForm

const styles = StyleSheet.create({
  opinionForm: {
    borderWidth: 1,
    borderColor: COLOR.gray.g100,
    borderRadius: 4,
    paddingVertical: 8,
    paddingHorizontal: 12,
    backgroundColor: COLOR.mono.white,
    color: COLOR.gray.g800,
  },
  hiddenOpinion: {
    height: 32,
    borderWidth: 0,
    backgroundColor: COLOR.gray.g050,
    color: COLOR.gray.g500,
    paddingLeft: 28,
    ...Platform.select({
      native: {
        paddingTop: 8,
      },
    }),
  },
  opinionEditControl: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 8,
  },
  lockIcon: {
    position: 'absolute',
    ...Platform.select({
      native: {
        top: 9,
      },
      web: {
        top: 10,
      },
    }),
    left: 12,
  },
  editOpinion: {
    borderColor: COLOR.gray.g450,
    ...getBoxShadow(0, 0, 0, 0.15, 1, 1, 0, 3),
  },
  opinionMore: {
    position: 'absolute',
    right: 0,
  },
})
