import * as React from 'react'
import { Image, TouchableOpacity, View } from 'react-native'

import { COLOR, getRealColor } from '@rui/foundations'
import { XEIcon } from '@rui/icons'

interface ImageThumbnailProps {
  uri?: string
  mimeType: string
  size: 'large' | 'regular' | 'small'
  type?: 'channel' | 'todo' | 'schedule' | 'apps'
  onPress?: (uri?: string) => void
}

const imageMimeTypes = [
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/x-icon',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/tiff',
  'image/webp',
]

const ImageThumbnail = ({ uri, mimeType, size, type, onPress }: ImageThumbnailProps) => {
  const isImage = React.useMemo(() => {
    return imageMimeTypes.includes(mimeType)
  }, [mimeType])

  const fileIconPosition = React.useMemo(() => {
    switch (size) {
      case 'small':
        return { top: '10%', left: '15%' }
      default:
        return { top: '20%', left: '22%' }
    }
  }, [size])

  const fileIcon = React.useMemo(() => {
    if (size !== 'large') {
      return 'file-zip'
    }

    if (
      /officedocument/.test(mimeType) ||
      mimeType === 'application/msword' ||
      mimeType === 'application/vnd.ms-powerpoint' ||
      mimeType === 'application/vnd.visio' ||
      mimeType === 'application/vnd.ms-excel'
    ) {
      return 'office'
    }

    if (mimeType === 'application/pdf' || mimeType === 'application/x-shockwave-flash') {
      return 'adobe'
    }

    if (/video/.test(mimeType)) {
      return 'youtube-play'
    }

    return 'file-text'
  }, [size, mimeType])

  const icon = React.useMemo(() => {
    if (size !== 'large') {
      return 'eye-o'
    }
    switch (type) {
      case 'todo':
        return 'check-square-o'
      case 'schedule':
        return 'emoticon'
      case 'apps':
        return 'apps'
      default:
        return 'message-o'
    }
  }, [size, type])

  const iconBgColor = React.useMemo(() => {
    if (size !== 'large' && !isImage) {
      return 'transparent'
    }

    if (size !== 'large') {
      return 'mono.paleBlack'
    }

    switch (type) {
      case 'todo':
        return 'main.turquoise'
      case 'schedule':
        return 'main.yellow'
      case 'apps':
        return 'main.pink'
      case 'channel':
        return 'main.red'
      default:
        return 'mono.paleBlack'
    }
  }, [type, size, isImage])

  const iconColor = React.useMemo(() => {
    if (size !== 'large' && !isImage) {
      return 'transparent'
    }
    return 'mono.white'
  }, [size, isImage])

  const containerSize = React.useMemo(() => {
    switch (size) {
      case 'large':
        return 50
      case 'regular':
        return 38
      default:
        return 30
    }
  }, [size])

  const fileIconSize = React.useMemo(() => {
    switch (size) {
      case 'large':
        return 30
      default:
        return 20
    }
  }, [size])

  const iconSize = React.useMemo(() => {
    switch (size) {
      case 'large':
        return 16
      default:
        return 14
    }
  }, [size])

  const iconRounded = React.useMemo(() => {
    switch (size) {
      case 'large':
        return 17
      case 'regular':
        return 12
      default:
        return 10
    }
  }, [size])

  const iconContainerPosition = React.useMemo(() => {
    switch (size) {
      case 'large':
        return { right: containerSize * -0.5, bottom: containerSize * -0.45 }
      case 'regular':
        return { right: containerSize * -0.45, bottom: containerSize * -0.45 }
      default:
        return { right: containerSize * -0.3, bottom: containerSize * -0.3 }
    }
  }, [size, containerSize])

  const iconPosition = React.useMemo(() => {
    switch (size) {
      case 'large':
        return { left: 5, top: 4 }
      case 'regular':
        return { left: 3, top: 2 }
      default:
        return { left: 4, top: 2 }
    }
  }, [size])

  return (
    <TouchableOpacity
      style={{
        width: containerSize,
        height: containerSize,
        borderRadius: iconRounded,
        overflow: 'hidden',
        backgroundColor: COLOR.mono.pale,
      }}
      onPress={() => onPress && onPress(uri)}
    >
      {isImage && (
        <Image
          source={{ uri }}
          style={{
            width: containerSize,
            height: containerSize,
            borderRadius: iconRounded,
          }}
        />
      )}
      {!isImage && (
        <XEIcon
          name={fileIcon}
          size={fileIconSize}
          color="mono.darkGray"
          // @ts-ignore
          style={{ position: 'absolute', ...fileIconPosition }}
        />
      )}
      <View
        style={{
          position: 'absolute',
          width: containerSize,
          height: containerSize,
          backgroundColor: getRealColor(iconBgColor),
          borderRadius: containerSize * 0.2,
          ...iconContainerPosition,
        }}
      >
        <XEIcon name={icon} color={iconColor} style={{ position: 'absolute', ...iconPosition }} size={iconSize} />
      </View>
    </TouchableOpacity>
  )
}

export default ImageThumbnail
