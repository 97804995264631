import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import {
  NativeSyntheticEvent,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextInput,
  TextInputChangeEventData,
  View,
  ViewStyle,
} from 'react-native'
import IconDelete from '../icons/IconDelete'
// import ImageProfile from '../ImageProfile'

export interface EmailInviteMemberItem {
  // profile: string
  name: string
  email: string
  editMode: boolean
}

interface Props {
  item: EmailInviteMemberItem
  idx: number
  onPressDelete: (index: number) => void
  onChangeItem: (index: number, item: EmailInviteMemberItem) => void
  style?: ViewStyle
}
const InviteMemberItem = ({ item, idx, onPressDelete, onChangeItem, style }: Props) => {
  const isMobile = Platform.OS === 'ios' || Platform.OS === 'android'
  const [isHover, setIsHover] = React.useState(false)
  const [name, setName] = React.useState(item.name)

  const handleUserNameChange = (e: NativeSyntheticEvent<TextInputChangeEventData>) => {
    const value = e.nativeEvent.text
    setName(value)
  }
  return (
    <Pressable
      style={[styles.container, style]}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      {/* <View style={{ paddingRight: 8 }}>
          <ImageProfile size={36} alt={item.name} uri={item.profile} />
        </View> */}
      <View style={{ flex: 1 }}>
        <View style={styles.userNameArea}>
          <View>
            <View style={styles.flexBox}>
              <View style={styles.nameArea}>
                {item.editMode ? (
                  <TextInput
                    autoFocus={true}
                    value={name}
                    onChange={(e) => handleUserNameChange(e)}
                    // onKeyPress={(e) => {
                    //   if (e.nativeEvent.key === 'Enter') {
                    //     // setEditMode(false)
                    //     onChangeItem(idx, { ...item, editMode: false })
                    //   }
                    // }}
                    onSubmitEditing={() => onChangeItem(idx, { ...item, name, editMode: false })}
                    onBlur={() => onChangeItem(idx, { ...item, name, editMode: false })}
                    style={[styles.editStyle, item.editMode && styles.editOnStyle]}
                  />
                ) : (
                  <Text
                    style={styles.nameStyle}
                    onPress={() => {
                      setName(item.name)
                      onChangeItem(idx, { ...item, editMode: true })
                    }}
                    numberOfLines={1}
                  >
                    {item.name}
                  </Text>
                )}
              </View>
              {!item.editMode && (
                <Pressable
                  onPress={() => {
                    setName(item.name)
                    onChangeItem(idx, { ...item, editMode: true })
                  }}
                  style={[
                    {
                      marginLeft: 2,
                    },
                    isMobile
                      ? { display: 'flex' }
                      : {
                          display: isHover ? 'flex' : 'none',
                        },
                  ]}
                >
                  <XEIcon name={'pen'} size={12} color={'gray.g500'} style={{ marginTop: -3, marginLeft: 4 }} />
                </Pressable>
              )}
            </View>
            <View>
              <Text style={styles.mailStyle}>{item.email}</Text>
            </View>
          </View>
          <View style={styles.flexBox}>
            {/* <View>
              <Text>전체 관리자 권한</Text>
            </View> */}
            <IconDelete onPressDelete={() => onPressDelete(idx)} />
          </View>
        </View>
        {/* <View style={{ paddingTop: 4 }}>
          <Text>워크선택</Text>
        </View> */}
      </View>
    </Pressable>
  )
}

export default InviteMemberItem

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    flexDirection: 'row',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  userNameArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameArea: {
    height: 20,
    maxWidth: 350,
  },
  nameWrap: {
    flexDirection: 'row',
  },
  nameStyle: {
    ...FONT.txtSm,
  },
  mailStyle: {
    ...FONT.txtXs,
    color: COLOR.gray.g400,
  },
  editStyle: {
    padding: 2,
  },
  editOnStyle: {
    backgroundColor: COLOR.gray.g050,
  },
})
