import { Text, TextField } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import type { IconButtonGroupProps } from '../ButtonGroup/IconButtonGroup'
import IconButtonGroup from '../ButtonGroup/IconButtonGroup'
import ButtonIcon from '../ButtonIcon'
import Switch from '../Switch'

interface Props {
  itemCode: string
  list: IconButtonGroupProps[]
  editMode: boolean
  onChangeText: (id: string, text: string) => void
  onChangeEditMode: (editMode: boolean) => void
  onPressAdd: () => void
  onPressItem: (id: string) => void
  onPressDelete: (id: string) => void
}

const TopControl: React.FC<Props> = ({
  itemCode,
  list,
  editMode,
  onChangeText,
  onChangeEditMode,
  onPressAdd,
  onPressItem,
  onPressDelete,
}) => {
  const [isEdit, setIsEdit] = React.useState(false)
  const [text, setText] = React.useState('')
  React.useEffect(() => {
    setText(list.find((o) => o.itemCode === itemCode)?.text || '')
  }, [itemCode, list])

  return (
    <View style={styles.container}>
      <View style={styles.flexBox}>
        {editMode && (
          <>
            <View style={styles.flexBox}>
              <View
                style={{
                  marginRight: 8,
                  height: 36,
                  width: 96,
                  justifyContent: 'center',
                }}
              >
                {isEdit ? (
                  <TextField
                    value={text}
                    placeholder=""
                    // borderType="solid"
                    style={{ ...FONT.txtMdMedium, height: 36, width: 96 }}
                    onBlur={() => {
                      onChangeText(itemCode, text)
                      setIsEdit(false)
                    }}
                    onChangeValue={setText}
                  />
                ) : (
                  <Text fontName="H7Bold" style={{ minWidth: 82, width: 96 }} numberOfLines={1}>
                    {text}
                  </Text>
                )}
              </View>
              <View style={styles.flexBox}>
                <ButtonIcon
                  iconName={isEdit ? 'check' : 'pen'}
                  iconSize={16}
                  iconColor={isEdit ? 'mono.white' : 'gray.g700'}
                  style={[
                    {
                      width: 32,
                      height: 32,
                      borderWidth: 1,
                      borderColor: COLOR.gray.g200,
                      borderRadius: 4,
                      marginRight: 4,
                    },
                    isEdit && {
                      backgroundColor: COLOR.main.blue,
                      borderColor: COLOR.main.blue,
                    },
                  ]}
                  onPress={() => {
                    if (isEdit) {
                      onChangeText(itemCode, text)
                      setIsEdit(false)
                    } else {
                      setIsEdit(true)
                    }
                  }}
                />
                <ButtonIcon
                  iconName="trash-o"
                  iconSize={16}
                  iconColor={'gray.g700'}
                  style={{
                    width: 32,
                    height: 32,
                    borderWidth: 1,
                    borderColor: COLOR.gray.g200,
                    borderRadius: 4,
                  }}
                  onPress={() => onPressDelete(itemCode)}
                />
              </View>
            </View>
            <View style={styles.divLine}></View>
          </>
        )}

        <IconButtonGroup
          itemCode={itemCode}
          list={list}
          iconMode={false}
          onPressAdd={editMode ? onPressAdd : undefined}
          onPressButton={onPressItem}
          onPressIcon={onPressDelete}
        />
      </View>
      <View style={styles.rightArea}>
        <Text fontName="textRegular" style={styles.labelText}>
          테이블 편집
        </Text>
        <Switch size="small" activated={editMode} onChange={onChangeEditMode} />
      </View>
    </View>
  )
}

export default TopControl

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  divLine: {
    width: 1,
    height: 24,
    backgroundColor: COLOR.gray.g200,
    marginHorizontal: 16,
  },
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightArea: {
    flexDirection: 'row',
  },
  labelText: {
    marginRight: 4,
  },
})
