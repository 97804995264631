import * as React from 'react'
import ComponentsBaseProviders from './components-base'

interface Props {
  children?: JSX.Element
}

const ComponentsProviders: React.FC<Props> = ({ children }) => {
  return (
    <ComponentsBaseProviders>
      <>{children}</>
    </ComponentsBaseProviders>
  )
}

export default ComponentsProviders
