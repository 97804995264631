import * as React from 'react'

import QRCode from 'easyqrcodejs'
import { View } from 'react-native'

interface QRCodeViewerProps {
  url: string
  size?: number
  correctLevel?: number
  onRenderingEnd?: (options: any, dataURL: string) => void
}
export const QRCodeViewer = ({
  url,
  onRenderingEnd,
  size = 200,
  correctLevel = QRCode.CorrectLevel.L,
}: QRCodeViewerProps) => {
  const code = React.createRef<View>()
  const download = React.createRef<View>()
  const sizeProps = React.useMemo(() => {
    return { width: size, height: size }
  }, [size])
  React.useEffect(() => {
    new QRCode(code.current, {
      text: url,
      ...sizeProps,
      correctLevel,
    })

    new QRCode(download.current, {
      text: url,
      onRenderingEnd,
      with: size * 10,
      correctLevel,
    })
  }, [code])
  return (
    <>
      <View style={[sizeProps, { overflow: 'hidden' }]} ref={code} />
      <View style={{ display: 'none' }} ref={download} />
    </>
  )
}
