import React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'

import { Icon, Text } from '@rocket/atoms'
import { SettingItemProps } from './types'

const SettingItemMobile: React.FC<SettingItemProps> = ({ item, onPress }) => {
  return (
    <Pressable onPress={() => onPress && onPress(item.tab)}>
      <View style={[styles.nav]}>
        <View style={{ marginRight: 8 }}>
          <Icon name={item.icon} size={20} color={'gray.g300'} style={{ marginTop: -2 }} />
        </View>
        <View style={[styles.menuArea, item.tab === 'logout' && { borderBottomWidth: 0 }]}>
          <View>
            <Text fontName="txtMdMedium">{item.title}</Text>
          </View>
          <View style={styles.opt}>
            <View style={{ alignItems: 'flex-end' }}>
              <Text fontName="txtXs" fontColor="gray.g500">
                {item.label}
              </Text>
              {item.tab === 'notice' && (
                <Text fontName="txtXs" fontColor="primary.blue500">
                  {item.update}
                </Text>
              )}
            </View>
            <View style={{ marginHorizontal: 4 }}>
              <Icon name={'angle-right-min'} size={16} color={'gray.g450'} />
            </View>
          </View>
        </View>
      </View>
    </Pressable>
  )
}

export default SettingItemMobile

const styles = StyleSheet.create({
  nav: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuArea: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  opt: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'right',
  },
})
