import * as React from 'react'

import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ astro, children }: ProviderProps) => {
  React.useEffect(() => {
    console.log('Init Provider', astro)
  }, [astro])
  return <Context.Provider value={{}}>{children}</Context.Provider>
}

export default Provider
