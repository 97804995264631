import { i18n } from 'i18next'
import React from 'react'
import Providers from '../../providers'
import { WorkmodelEditView } from './view'

interface Props {
  i18n: i18n
  projectId: string
  onPressClose: () => void
  onBack?: () => void
  onSuccess: (projectId: string) => void
  goHome?: () => void
}

export const WorkmodelEditScreen: React.FC<Props> = ({ i18n, ...props }) => {
  return (
    <Providers i18n={i18n}>
      <WorkmodelEditView {...props} />
    </Providers>
  )
}
