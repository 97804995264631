import { Context } from './context'
import { Provider } from './provider'
import { WorkmodelCreateView } from './view'

const WorkmodelCreateScreen = Object.assign(WorkmodelCreateView, {
  Provider: Provider,
  Consumer: Context.Consumer,
})

export { WorkmodelCreateScreen }
export * from './types'
export * from './usePosition'
