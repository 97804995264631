import * as React from 'react'
import { Pressable, StyleSheet, TextInput } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { XEIcon } from '@rui/icons'

import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import { COLOR } from '@rui/foundations'
import { IconDelete } from '../../icons'

interface Props {
  name: string
  price: string
  onChangeName?: (name: string) => void
  onChangePrice?: (price: string) => void
  onPressDelete?: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null
}

export const TableorderMenuOptionItem: React.FC<Props> = ({
  name,
  price,
  onChangeName,
  onChangePrice,
  onPressDelete,
  dragHandleProps,
}) => {
  return (
    <View style={styles.container}>
      <div style={{ width: 22 }} {...dragHandleProps}>
        <Pressable style={{ width: 22, alignItems: 'center' }}>
          <XEIcon name="drag-vertical" size="16" />
        </Pressable>
      </div>
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.input}
            value={name}
            onChangeText={onChangeName}
            placeholder="입력하세요"
            placeholderTextColor={COLOR.mono.gray}
          />
        </View>
        <View style={styles.inputContainer}>
          <TextInput
            style={[styles.input, { textAlign: 'right' }]}
            value={price}
            onChangeText={onChangePrice}
            placeholder="0"
            placeholderTextColor={COLOR.mono.gray}
          />
        </View>
        <Text style={{ width: 20 }} fontName="textMedium" fontColor="mono.paleBlack">
          원
        </Text>
      </View>
      <View style={{ width: 22 }}>
        <IconDelete onPressDelete={onPressDelete} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 34,
    marginBottom: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  inputContainer: {
    flex: 1,
    height: 34,
    marginRight: 4,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  input: {
    flex: 1,
    height: 34,
  },
})
