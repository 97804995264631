import React from 'react'
import SkeletonEditWorkModal from './EditWorkModal'
import SkeletonLinkInviteModal from './LinkInviteModal'
import SkeletonMemberInviteScreen from './MemberInviteScreen'
import SkeletonPlaceSearch from './PlaceSearch'
import SkeletonSideNav from './SideNav'
import SkeletonSideNavItem from './SideNav/item'
import SkeletonWorkCardNewTabView from './WorkCardNewTabView'
import SkeletonWorkCardView from './WorkCardView'
import SkeletonBoardCard from './BoardCard'
import SkeletonWorkflowRuleList from './WorkflowRuleList'
import SkeletonWorkCardEdit from './WorkCardEdit'

interface SkeletonProps {
  path: string
}

export const Skeleton: React.FC<SkeletonProps> = ({ path }) => {
  if (path.startsWith('/card/')) return <SkeletonWorkCardNewTabView />
  return <></>
}

export {
  SkeletonEditWorkModal,
  SkeletonLinkInviteModal,
  SkeletonMemberInviteScreen,
  SkeletonPlaceSearch,
  SkeletonSideNav,
  SkeletonSideNavItem,
  SkeletonWorkCardNewTabView,
  SkeletonWorkCardView,
  SkeletonBoardCard,
  SkeletonWorkflowRuleList,
  SkeletonWorkCardEdit
}
export * from './SkeletomParts'
