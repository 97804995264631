import { Icon } from '@rocket-atoms/icon'
import { Combobox } from '@rui/molecules'
import { COLOR, FONT, getBoxShadow, useMobileView } from '@rocket-mono/libs'
import { ClickOutside } from '@rui/foundations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import Button from '../Button'
import { CardRowGroup } from '../CardRowGroup'

interface Props {
  top?: number
  right?: number
  shareType: string
  shareLink: string
  popType?: boolean
  visible: boolean
  onPressClose: () => void
  onPressCopy: () => void
}
const WorkCardShareView: React.FC<Props> = ({
  top = 20,
  right = 20,
  shareType,
  shareLink,
  visible,
  onPressClose,
  onPressCopy,
}) => {
  const { t } = useTranslation()
  const mobileView = useMobileView(420)

  return (
    <ClickOutside onClickOutside={onPressClose}>
      <View style={{ width: mobileView? '95%' : 320, top, right, zIndex: 30, position: 'absolute', ...styles.container }}>
        <Combobox visible={visible} width={mobileView? '100%' : 320}>
          <Combobox.Body style={{ width: mobileView? '100%' : 320 }}>
            <View>
              <View style={styles.formGroup}>
                <CardRowGroup title={t('sharemodal.title')} containerStyle={styles.cardRowTitle} titleStyle={{...FONT.txtMd}} />
                <View style={styles.optionArea}>
                  <Text style={styles.optionLabel}>{t('sharemodal.authority')}</Text>
                  <Text style={styles.optionValue}>{shareType}</Text>
                </View>
              </View>
              <View style={styles.formSection}>
                <View style={styles.linkLabel}>
                  <Text numberOfLines={1} ellipsizeMode="tail" style={styles.linkText}>
                    {shareLink}
                  </Text>
                </View>
                <Button
                  onPress={onPressCopy}
                  size="medium"
                  style={{ height: 36, flexDirection: 'row', paddingHorizontal: 8 }}
                >
                  <Icon name={'link'} size={16} color={'mono.white'} />
                  <Text style={{ color: COLOR.mono.white, marginLeft: 4 }}>{t('addcontainer.copy')}</Text>
                </Button>
              </View>
            </View>
          </Combobox.Body>
        </Combobox>
      </View>
    </ClickOutside>
  )
}

export default WorkCardShareView

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
  },
  cardRowTitle: {
    marginTop: 0,
  },
  formSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 8,
    paddingBottom: 8,
    paddingHorizontal: 8,
  },
  linkLabel: {
    flex: 1,
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    height: 36,
    paddingHorizontal: 12,
    borderRadius: 4,
    marginRight: 4,
  },
  linkText: {
    ...FONT.txtSm,
  },
  formGroup: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  optionArea: {
    paddingVertical: 4,
  },
  optionLabel: {
    ...FONT.txtXsMedium,
    marginBottom: 8,
    color: COLOR.gray.g700,
  },
  optionValue: {
    ...FONT.txtSm,
  },
})
