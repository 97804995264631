import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import moment from 'moment'
import * as React from 'react'
import { SectionList, StyleSheet } from 'react-native'
import { Button } from '../../Button'
import BarButton from '../../Button/BarButton'
import OrderConfirmButton from '../../Button/OrderConfirmButton'
import OrderItem, { OrderItemProps } from '../../OrderItem'
import { useTableorderOrder, useTableorderTable } from '../../providers'
import TableInfoHeader from './TableInfoHeader'
import TableInfoPrice from './TableInfoPrice'
import TableStatusProgress from './TableStatusProgress'

export type OrderItemType = {
  id: string
  idx: number
  orderTime: Date
  estimatedTime?: Date
  complete: boolean
  menu: OrderItemProps[]
}
export interface TableInfoProps {
  tableName: string
  estimatedTime?: Date
  status?: 'WAITING_PAYMENT' | 'DONE' | 'COOKING' | 'SERVING_DONE'
  progress: number
  order?: OrderItemType[]
  price: {
    prePay: string
    postPay: string
    cash: string
    totalPrice: string
  }
}

interface Props {
  shopId: string
  tableId: string
  data: TableInfoProps
  allowableHeadcount: number
  onItemPress?: (item: OrderItemType, index: number) => void
}

const TableInfo = ({ shopId, tableId, data, allowableHeadcount }: Props) => {
  const { cleanupTable, openTable, tableList } = useTableorderTable()
  const { updateOrderStatus } = useTableorderOrder()

  const table = React.useMemo(() => tableList.find(({ id }) => id === tableId), [tableList, tableId])

  const menuList = React.useMemo(() => {
    return data.order?.map((item, idx) => {
      console.log('item.estimatedTime', item.estimatedTime)
      return {
        id: item.id,
        idx: item.idx,
        title: idx === 0 ? '주문' : '추가 주문',
        complete: item.complete,
        estimatedHour: item.estimatedTime !== null ? Math.abs(moment().diff(item.estimatedTime, 'hours')) : 0,
        estimatedMinute: item.estimatedTime !== null ? Math.abs(moment().diff(item.estimatedTime, 'minutes')) % 60 : 0,
        data: item.menu.map((el) => {
          return {
            ...el,
            complete: item.complete,
          }
        }),
      }
    })
  }, [data.order])

  const isClean = React.useMemo(
    () => menuList && menuList.length > 0 && menuList.filter(({ complete }) => !complete).length === 0,
    [menuList],
  )

  const isServingAll = React.useMemo(
    () => menuList && menuList.filter(({ complete }) => !complete).length > 0,
    [menuList],
  )
  const handleServingAll = React.useCallback(async () => {
    if (menuList) {
      for await (const { id } of menuList.filter(({ complete }) => !complete)) {
        await updateOrderStatus(String(id), 'SERVING_DONE')
      }
    }
  }, [menuList])

  return (
    <View style={styles.container}>
      <TableInfoHeader
        tableName={data.tableName}
        allowableHeadcount={allowableHeadcount}
        onPressQr={() => {
          if (table) {
            const { id, locationX: x, locationY: y } = table
            openTable({ id, x, y, readonly: true })
          }
        }}
        onPressOrder={() => {
          window.open(`/tableorder/${shopId}/order/${tableId}/customer`, '_blank')
        }}
      />
      <View style={styles.tableInfoContents}>
        <View style={styles.tableOrderList}>
          <View style={styles.orderItemList}>
            <View>
              <TableStatusProgress status={data.status} progress={0} estimateDate={data.estimatedTime} />
            </View>
            {menuList && (
              <SectionList
                sections={menuList}
                stickySectionHeadersEnabled
                keyExtractor={(_, index) => `order-section-${index}`}
                renderSectionHeader={({ section }) => {
                  return (
                    <View style={[styles.orderTab]}>
                      <View style={{ flexDirection: 'row' }}>
                        <Text>{section.title}</Text>
                        {section.estimatedMinute === null ? (
                          <Text fontName="subTextRegular" fontColor="mono.black">
                            {' '}
                            조리시간 미설정
                          </Text>
                        ) : (
                          !section.complete && (
                            <>
                              <XEIcon
                                name={'time-o'}
                                size={12}
                                color={section.estimatedMinute < 1 ? 'gray.g700' : 'main.red'}
                              />
                              <Text
                                fontName="subTextRegular"
                                fontColor={section.estimatedMinute < 1 ? 'mono.black' : 'main.red'}
                              >
                                {` `}
                                {`${section.estimatedHour}:${section.estimatedMinute < 0 ? ' ' : ''}${
                                  section.estimatedMinute
                                }:00`}
                                {/* {Math.abs(section.estimatedMinute) < 10
                                  ? `${Math.abs(section.estimatedHour)}:0${Math.abs(
                                      section.estimatedMinute,
                                    )}:00`
                                  : `00:${Math.abs(
                                      section.estimatedMinute,
                                    )}:00`} */}
                              </Text>
                            </>
                          )
                        )}
                      </View>
                      {section.complete ? (
                        <Button
                          size={'small'}
                          backgroundColor={'gray.g200'}
                          color={'gray.g700'}
                          style={{ ...FONT.txtSm, padding: 8 }}
                          onPress={() => {
                            updateOrderStatus(String(section.id), 'COOKING')
                          }}
                        >
                          완료 취소
                        </Button>
                      ) : (
                        <Button
                          size={'small'}
                          style={{ ...FONT.listBold, padding: 8 }}
                          onPress={() => {
                            updateOrderStatus(String(section.id), 'SERVING_DONE')
                          }}
                        >
                          서빙 완료
                        </Button>
                      )}
                    </View>
                  )
                }}
                renderSectionFooter={({ section }) => {
                  return <>{section.idx === 0 ? <View style={{ marginBottom: 40 }} /> : <></>}</>
                }}
                renderItem={({ item }) => {
                  return (
                    <View style={{ paddingHorizontal: 8 }}>
                      <OrderItem item={item} status={item.complete} />
                    </View>
                  )
                }}
              />
            )}
          </View>
        </View>

        <View style={styles.tableOrderControl}>
          <View>
            <OrderConfirmButton name={'서빙 완료'} disabled={!isServingAll} onPress={handleServingAll} />
            <View style={{ marginTop: 12 }}>
              <Button
                wide
                borderColor="gray.g300"
                backgroundColor="mono.white"
                color="mono.black"
                onPress={() => {
                  window.open(`/tableorder/${shopId}/order/${tableId}/additional`, '_blank')
                }}
              >
                추가 주문
              </Button>
            </View>
          </View>
          <TableInfoPrice
            prePay={data.price.prePay}
            postPay={data.price.postPay}
            cash={data.price.cash}
            totalPrice={data.price.totalPrice}
          />
        </View>
      </View>
      <View style={styles.bottomArea}>
        <BarButton title={'테이블 정리'} callToAction={true} disabled={!isClean} action={() => cleanupTable(tableId)} />
      </View>
    </View>
  )
}

export default TableInfo

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: 400,
    backgroundColor: COLOR.mono.white,
    ...getBoxShadow(0, 0, 0, 0.05, 5, -7, 5, 0),
  },
  tableInfoContents: {
    flex: 1,
    flexDirection: 'row',
    paddingBottom: 58,
  },
  tableOrderList: {
    flex: 1,
  },
  tableOrderControl: {
    // flex: 1,
    width: 152,
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 12,
    borderLeftWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  orderItemList: {
    flex: 1,
    overflow: 'scroll',
    paddingBottom: 20,
  },
  bottomArea: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 58,
  },
  orderTab: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLOR.gray.g100,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingVertical: 4,
    paddingHorizontal: 8,
  },
})
