import * as React from 'react'

import { Provider } from './provider'
import type { AstroType } from './types'
import View from './view'

import type { SubscribeType } from '@rocket-mono/libs'
import WorkBoardMemberProvider, { WorkBoardMemberAstroType } from '../../providers/WorkBoardMemberProvider'

interface Props {
  secureCdnUrl: string
  channelId: string
  roomId: string
  projectId: string
  projectElementId: string
  astro: AstroType & WorkBoardMemberAstroType
  onPressClose: () => void
  subscribe: SubscribeType
}
export const WorkBoardMemberView: React.FC<Props> = ({
  secureCdnUrl,
  channelId,
  projectId,
  projectElementId,
  roomId,
  astro,
  onPressClose,
  subscribe,
}) => {
  return (
    <WorkBoardMemberProvider
      astro={astro}
      channelId={channelId}
      roomId={roomId}
      projectId={projectId}
      projectElementId={projectElementId}
      subscribe={subscribe}
    >
      <Provider astro={astro} closeModal={onPressClose}>
        <View secureCdnUrl={secureCdnUrl} onPressClose={onPressClose} />
      </Provider>
    </WorkBoardMemberProvider>
  )
}
