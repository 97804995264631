import React, { useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'

import { Icon } from '@rocket-atoms/icon'
import { Text } from '@rocket/atoms'
import type { SettingItemProps } from './types'

const SettingItemWeb: React.FC<SettingItemProps> = ({ item, tab, onPress }) => {
  const [isHover, setIsHover] = useState(false)
  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={() => onPress && onPress(item.tab)}
    >
      <View
        style={[
          styles.nav,
          styles.navWeb,
          isHover && { backgroundColor: COLOR.gray.g100 },
          item.tab === tab && { backgroundColor: COLOR.primary.blue500 },
        ]}
      >
        <View style={{ marginRight: 8 }}>
          <Icon
            name={item.icon}
            size={20}
            color={item.tab === tab ? 'mono.white' : 'gray.g300'}
            style={{ marginTop: -2 }}
          />
        </View>
        <View>
          <View>
            <Text fontName="txtMd" fontColor={item.tab === tab ? 'mono.white' : 'mono.black'}>
              {item.title}
            </Text>
          </View>
        </View>
      </View>
    </Pressable>
  )
}

export default SettingItemWeb

const styles = StyleSheet.create({
  nav: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  navWeb: {
    height: 48,
    borderRadius: 8,
    marginBottom: 4,
    paddingHorizontal: 12,
  },
  menuArea: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
  },
})
