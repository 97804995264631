import { RangeDateType } from '@rocket-molecules/date'
import type { IColors } from '@rocket-mono/libs'
import { removeItemAtIndex, replaceItemAtIndex } from '@rocket-mono/libs'
import type { Project, ProjectCreation, ProjectPositionCreation, TableorderShopCreatePayload } from '@rocket/types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Context } from './context'
import type { ElementType, ProviderProps } from './types'

export const Provider = ({ astro, onPressBack, onSaveSuccess, onSaveFailed, children }: ProviderProps) => {
  const { t } = useTranslation()
  const [isSaving, setIsSaving] = React.useState(false)
  const [title, onChangeTitle] = React.useState('')
  const [goal, onChangeGoal] = React.useState('')
  const [position, onChangePosition] = React.useState<Omit<ProjectPositionCreation, 'projectId'>>()
  const [projectElements, onChangeProjectElements] = React.useState<ElementType[]>([])

  const [startElement, onChangeStartElement] = React.useState<ElementType>()
  const [endElement, onChangeEndElement] = React.useState<ElementType>()
  const [rangeDate, onChangeRangeDate] = React.useState<RangeDateType>({})
  const data = React.useMemo(
    () => ({
      title,
      goal,
      projectElements,
      rangeDate,
      startElement,
      endElement,
    }),
    [title, goal, projectElements, rangeDate, startElement, endElement],
  )

  const handleSaveSuccess = React.useCallback(
    (project: Project) => {
      setIsSaving(false)
      onSaveSuccess && onSaveSuccess(project)
      return project
    },
    [onSaveSuccess],
  )
  const handleSaveFailed = React.useCallback(
    (message: string) => {
      setIsSaving(false)
      onSaveFailed && onSaveFailed(message)
      return Promise.reject()
    },
    [onSaveFailed],
  )

  const createShop = React.useCallback((payload: TableorderShopCreatePayload) => {
    console.log('createShop', payload)
    return astro.createShop(payload)
  }, [])

  const onPressCreate = React.useCallback(
    (typeCode: string) => {
      return new Promise<Project>((resolve, reject) => {
        const { title, startElement, endElement } = data
        let { projectElements } = data

        if (startElement) projectElements = [startElement, ...projectElements]
        if (endElement) projectElements = [...projectElements, endElement]

        projectElements = projectElements.map((o, order) => ({ ...o, order }))

        const payload: ProjectCreation = {
          typeCode,
          ...data,
          projectElements,
        }

        console.log('astro.createProject', data, payload)

        if (!title) {
          reject(t('workmodal.error.workname'))
          return
        }

        if (projectElements.filter((o) => !o.name).length > 0) {
          reject(t('workmodal.error.subname'))
          return
        }

        if (!isSaving) {
          // astro.createShop({name: projectElemen})
          astro
            .createProject(payload)
            .then((project) => {
              if (position) {
                return astro
                  .createGroupProjectPosition({
                    ...position,
                    projectId: project.id,
                  })
                  .then(() => project)
              } else return project
            })
            .then((project) => {
              console.log('data', data.rangeDate)
              if (data.rangeDate.startDate || data.rangeDate.endDate) {
                const { startDate, endDate: dueDate } = data.rangeDate
                return astro.createProjectPeriod(project.id, { startDate, dueDate }).then(() => project)
              } else return project
            })
            .then(resolve)
        }
      })
        .then(handleSaveSuccess)
        .catch(handleSaveFailed)
    },
    [isSaving, data, position],
  )

  const onChangeElement = React.useCallback(
    (idx: number, item: { text?: string; labelColor?: IColors; focused?: boolean }) => {
      onChangeProjectElements((prev) => {
        const name = item.text || ''
        const labelObject = item.labelColor ? String(item.labelColor) : null
        const focused = item.focused
        const newItem = { ...prev[idx], name, labelObject, focused }
        return replaceItemAtIndex(prev, idx, newItem)
      })
    },
    [],
  )

  const onPressRemoveElement = React.useCallback((index: number) => {
    onChangeProjectElements((prev) => removeItemAtIndex(prev, index))
  }, [])

  const onPressAddElement = React.useCallback((element: ElementType) => {
    onChangeProjectElements((prev) => [...prev, element])
  }, [])

  return (
    <Context.Provider
      value={{
        data,
        createShop,
        onChangeTitle,
        onChangeGoal,
        onChangePosition,
        onChangeProjectElements,
        onChangeElement,
        onChangeStartElement,
        onChangeEndElement,
        onChangeRangeDate,
        onPressCreate,
        onPressBack,
        onPressAddElement,
        onPressRemoveElement,
      }}
    >
      {children}
    </Context.Provider>
  )
}
