import { SkeletonBar, SkeletonCircle } from '@rocket/skeleton'
import { Button } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { IconStateCircle } from '../../icons'

interface Props {
  isLoading: boolean
  state?: string
  setting?: string
  onPressInviteCancel?: () => void
}

const WorkMembersTableStateItem: React.FC<Props> = ({ isLoading, state, setting, onPressInviteCancel }) => {
  const { t } = useTranslation()
  return (
    <View style={styles.container}>
      {!isLoading ? (
        typeof state === 'undefined' ? (
          <View style={[styles.container, { alignItems: 'center' }]}>
            <View style={{ marginRight: 2 }}>
              <SkeletonCircle width={10} height={10} />
            </View>
            <SkeletonBar width={'30%'} height={8} />
          </View>
        ) : state === 'active' ? (
          <>
            <IconStateCircle state="active" sizing={12} style={{ marginTop: 3 }} />
            <View style={styles.gap}>
              <Text style={styles.txt}>active</Text>
            </View>
          </>
        ) : state === 'busy' ? (
          <>
            <IconStateCircle state="busy" sizing={12} style={{ marginTop: 3 }} />
            <View style={styles.gap}>
              <Text style={styles.txt}>busy</Text>
              <Text style={styles.settingTxt}>{t('workmember.table.status.busy', { setting })}</Text>
            </View>
          </>
        ) : state === 'away' ? (
          <>
            <IconStateCircle state="away" sizing={12} style={{ marginTop: 3 }} />
            <View style={styles.gap}>
              <Text style={styles.txt}>away</Text>
            </View>
          </>
        ) : state === 'invisible' ? (
          <>
            <IconStateCircle state="invisible" sizing={12} style={{ marginTop: 3 }} />
            <View style={styles.gap}>
              <Text style={styles.txt}>invisible</Text>
            </View>
          </>
        ) : state === 'off' ? (
          <>
            <IconStateCircle state="off" sizing={10} style={{ marginTop: 3 }} />
            <View style={styles.gap}>
              <Text style={styles.txt}>off</Text>
              <Text style={styles.settingTxt}>({setting})</Text>
            </View>
          </>
        ) : (
          <>
            <View
              style={[
                {
                  width: 12,
                  height: 12,
                  backgroundColor: COLOR.mono.white,
                  borderWidth: 2,
                  borderColor: COLOR.gray.g200,
                  borderRadius: 12,
                },
              ]}
            />
            <View style={styles.gap}>
              <Text style={styles.txt}>{t('workmember.table.status.invite')}</Text>
              <Button
                // backgroundColor="mono.white"
                // borderColor="gray.g300"
                // size="tiny"
                style={{
                  height: 20,
                  paddingHorizontal: 4,
                  backgroundColor: COLOR.mono.white,
                  borderColor: COLOR.gray.g300,
                }}
                onPress={onPressInviteCancel}
                text={
                  <Text style={{ ...FONT.txt2Xs, color: COLOR.gray.g700 }}>
                    {t('workmember.table.status.invitecancel')}
                  </Text>
                }
              />
            </View>
          </>
        )
      ) : (
        <View style={[styles.container, { alignItems: 'center' }]}>
          <View style={{ marginRight: 2 }}>
            <SkeletonCircle width={10} height={10} />
          </View>
          <SkeletonBar width={'30%'} height={8} />
        </View>
      )}
    </View>
  )
}

export default WorkMembersTableStateItem

const styles = StyleSheet.create({
  container: { flexDirection: 'row', width: '100%' },
  gap: { marginLeft: 2 },
  txt: {
    ...FONT.txtXs,
    color: COLOR.gray.g700,
    marginBottom: 1,
  },
  settingTxt: { ...FONT.txt2Xs, color: COLOR.gray.g600 },
})
