import type { UserState } from '@rocket/types'
import * as React from 'react'

import { View } from '@rocket-mono/foundations'
import { Avatar, Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { Pressable, StyleSheet } from 'react-native'
import { IconStateCircle } from '../Icons'
import { MessageUnread } from '../Message'
import type { MessageTextReplacer, ParserRule } from '../utils'
import { JSXToPlainText, MENTION_TEMPLATE, parseMessage } from '../utils'
import ChatLabel from './ChatLabel'
import ChatName from './ChatName'

import { SkeletonBar, SkeletonCircle } from '@rocket/skeleton'
import { useTranslation } from 'react-i18next'

interface Props {
  isSkelaton: boolean
  profile?: {
    url?: string
    alt: string
  }
  title?: {
    iconName?: 'forum-o' | 'user'
    title: string
    count?: number
  }
  label?: {
    labelName: string
    labelCode?: string
  }
  message?: string
  unreadCount: number
  date: Date
  onPress?: () => void
  state?: UserState
}
const ChatMessage = ({
  isSkelaton = true,
  profile,
  title,
  label,
  message,
  unreadCount,
  date,
  onPress,
  state,
}: Props) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = React.useState(false)

  const mentionReplacer: MessageTextReplacer = React.useCallback((source, alias, index) => {
    console.log('mentionReplacer', source, alias, index)
    const { chatRoomMemberNo: memberNo, memberName } = new RegExp(MENTION_TEMPLATE).exec(source)?.groups ?? {
      chatRoomMemberNo: '',
      memberName: '',
    }

    if (memberNo && memberName) {
      return (
        <Text key={`mention-${memberNo}-${alias}-${index}`} fontName="chatTextBold" fontColor="primary.blue500">
          @{memberName}
        </Text>
      )
    } else {
      return <Text key={`token-${alias}-${index}`}>{source}</Text>
    }
  }, [])

  const getMessageParse = React.useCallback(
    (bodyText: string) => {
      // console.log('bodyText', bodyText)
      const replacements: ParserRule[] = [
        {
          format: '<b>(.*?)<\\/b>',
          replacer: (source, timestamp, index) => (
            <Text key={`token-${timestamp}-${index}`} fontName="chatTextBold">
              {source.replace(/<b>(.*?)<\/b>/g, '$1')}
            </Text>
          ),
        },
        {
          format: MENTION_TEMPLATE,
          replacer: mentionReplacer,
        },
        // NOTE: 사용자 멘션 마크업에 대한 처리
        // example: <m chatRoomMemberNo=20905, memberName="김 로켓">
      ]

      return parseMessage(bodyText, replacements, {})
    },
    [MENTION_TEMPLATE, mentionReplacer],
  )

  return isSkelaton ? (
    <View style={styles.container}>
      <View style={[styles.messageContainer, { alignItems: 'center' }]}>
        <View style={styles.profile}>
          <SkeletonCircle width={40} height={40} />
        </View>
        <View style={styles.widthAuto}>
          <View>
            <SkeletonBar width={65} height={15} />
          </View>
          <View style={{ marginTop: 4 }}>
            <SkeletonBar width={145} height={12} />
          </View>
        </View>
      </View>
      <View style={styles.meta}>
        <View>
          <SkeletonBar width={32} height={10} />
        </View>
        <View style={{ marginTop: 4 }}>
          <SkeletonCircle width={20} height={20} />
        </View>
      </View>
    </View>
  ) : (
    <Pressable
      style={[styles.container, isHover && { backgroundColor: COLOR.gray.g050 }]}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={onPress}
    >
      <View style={styles.messageContainer}>
        <View style={styles.profile}>
          {!!profile && (
            <>
              <Avatar profileUrl={profile.url} size="large" userName={profile.alt} />
              {!!state && <IconStateCircle style={{ position: 'absolute', right: -2, bottom: -2 }} state={state} />}
            </>
          )}
        </View>
        <View style={styles.widthAuto}>
          {!!title && (
            <ChatName
              iconName={title.iconName}
              title={
                title.title
                  ? getMessageParse(title.title)
                      .map(JSXToPlainText)
                      .reduce((acc, cur) => acc + cur, '')
                  : ''
              }
              count={title.count}
            />
          )}
          <Text
            fontName="txtXs"
            fontColor="gray.g600"
            numberOfLines={label ? 1 : 2}
            ellipsizeMode="tail"
            style={styles.message}
          >
            {message
              ? getMessageParse(message)
                  .map(JSXToPlainText)
                  .reduce((acc, cur) => acc + cur, '')
              : ''}
          </Text>
          {!!label && <ChatLabel {...label} />}
        </View>
      </View>
      <View style={styles.meta}>
        <Text fontName="subTextThin" fontColor="mono.darkGray">
          {t('format.date.T', { date })}
        </Text>
        <View style={styles.unreadArea}>
          {!!unreadCount && unreadCount > 0 && <MessageUnread unreadCount={unreadCount} />}
        </View>
      </View>
    </Pressable>
  )
}

export default ChatMessage

const styles = StyleSheet.create({
  widthAuto: {
    flex: 1,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 12,
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  profile: {
    marginRight: 8,
  },
  message: {
    flex: 1,
    marginTop: 2,
    marginBottom: 4,
  },
  meta: {
    alignItems: 'flex-end',
    marginLeft: 8,
    marginTop: 4,
  },
  unreadArea: {
    height: 20,
    marginTop: 4,
  },
  mentionContainer: {
    backgroundColor: 'transparent',
  },
})
