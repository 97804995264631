import * as React from 'react'

import { TableorderTableInfoModal } from '../TableorderTableInfoModal'
import { Context } from './context'
import type { ProviderProps } from './types'

import { useModal } from '@rocket-mono/providers'
import { useTableorderShop, useTableorderTable } from '../providers'

export const Provider = ({ children }: ProviderProps) => {
  const [editMode, setEditMode] = React.useState(false)
  const onToggleEditMode = React.useCallback(() => {
    setEditMode((is) => !is)
  }, [])

  const { shopId, shopLayoutId } = useTableorderShop()
  const { tableInfo } = useTableorderTable()
  const { Modal, visible, close, open } = useModal('TableorderTableInfoModal')

  React.useEffect(() => (tableInfo !== undefined ? open() : close()), [tableInfo])

  return (
    <Context.Provider
      value={{
        editMode,
        onToggleEditMode,
      }}
    >
      {shopLayoutId !== undefined && tableInfo !== undefined && (
        <Modal visible={visible}>
          <TableorderTableInfoModal
            readonly={tableInfo.readonly || false}
            shopId={shopId}
            shopLayoutId={shopLayoutId}
            location={{ x: tableInfo.x, y: tableInfo.y }}
          />
        </Modal>
      )}
      {children}
    </Context.Provider>
  )
}
