import { WorkIcon } from '@rocket/atoms'
import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Platform, StyleSheet, Text, View } from 'react-native'
import type { CardButtonVerticalProps } from '../components'
import { BlockLabel, Button, CardButtonVertical, ImageCarousel, StickyHeader, type CarouselItem } from '../components'
import ExplainInfoBox from './info-box'
import type { ServiceProvider, WorkUpdate } from './types'

// interface CarouseItemProp {
//   id: number
//   uri: string
// }

interface Props {
  workIcon: string
  workTitle: string
  workInfo: string
  workPrice: string
  workDescription: string
  carouselItem?: CarouselItem[]
  cardButtonItem: CardButtonVerticalProps[]
  updates?: WorkUpdate[]
  serviceProvider?: ServiceProvider
  onBackPress: () => void
  onStartPress: () => void
  onInfoBoxMorePress?: () => void
  parentElement?: string
  headerOffsetTop?: number
  contentOffsetY?: number
}
const ExplainWorkModel = ({
  workIcon,
  workTitle,
  workInfo,
  workPrice,
  workDescription,
  cardButtonItem,
  carouselItem,
  updates,
  serviceProvider,
  onBackPress,
  onStartPress,
  onInfoBoxMorePress,
  parentElement,
  headerOffsetTop,
  contentOffsetY,
}: Props) => {
  const { t } = useTranslation()
  // const chartWidth = Dimensions.get('window').width
  const chartWidth = Dimensions.get('window').width
  const mobileView = Platform.OS === 'web' ? 960 > chartWidth : true

  const maxWidth = React.useMemo(() => (chartWidth < 960 ? chartWidth : 960), [chartWidth])
  const [ifScroll, setIfScroll] = React.useState(0)

  const [line, setLine] = React.useState(4)
  const [isActivated, setIsActivated] = React.useState(false)
  const handleLine = () => {
    isActivated ? setLine(mobileView ? 8 : 4) : setLine(Number.MAX_SAFE_INTEGER)
    setIsActivated((prev) => !prev)
  }

  React.useEffect(() => {
    if (contentOffsetY) setIfScroll(contentOffsetY)
  }, [contentOffsetY])

  React.useEffect(() => {
    const listener = () => {
      if (Platform.OS === 'web') {
        if (parentElement) {
          const elem = document.getElementById(parentElement)
          if (elem) {
            const contentOffsetY = elem.scrollTop
            setIfScroll(contentOffsetY)
          }
        } else {
          const contentOffsetY = window.pageYOffset

          setIfScroll(contentOffsetY)
        }
      }
    }
    if (Platform.OS === 'web') {
      if (parentElement) {
        const elem = document.getElementById(parentElement)
        if (elem) {
          elem.addEventListener('scroll', listener)
        }
      } else {
        window.addEventListener('scroll', listener)
      }
    }
    return () => {
      if (Platform.OS === 'web') {
        if (parentElement) {
          const elem = document.getElementById(parentElement)
          if (elem) {
            elem.removeEventListener('scroll', listener)
          }
        } else {
          window.removeEventListener('scroll', listener)
        }
      }
    }
  }, [])

  return (
    <View style={styles.container}>
      <StickyHeader
        workIcon={workIcon}
        workTitle={workTitle}
        workInfo={workInfo}
        workPrice={workPrice}
        offsetTop={ifScroll}
        onBackPress={onBackPress}
        onStartPress={onStartPress}
        headerOffsetTop={headerOffsetTop}
      >
        <View
          style={styles.pageContainer}
          // onScroll={(event) => {
          //   const scrolling = event.nativeEvent.contentOffset.y
          //   setIfScroll(scrolling)
          // }}
        >
          <View style={styles.frame}>
            <View style={{ maxWidth }}>
              <View style={mobileView && { paddingHorizontal: 32 }}>
                <View style={[styles.productTitleContainer, mobileView && { alignItems: 'flex-start' }]}>
                  <View style={{ marginRight: 20 }}>
                    <WorkIcon type={workIcon} size={mobileView ? 60 : 109} />
                  </View>
                  <View style={styles.inner}>
                    <View style={{ flex: 1 }}>
                      <Text style={[styles.title, mobileView && { ...FONT.H7Bold }]}>{workTitle}</Text>
                      <Text style={[styles.infoText, mobileView && { ...FONT.txtXs }]}>{workInfo}</Text>
                      <View style={styles.priceLabel}>
                        <Text style={[{ ...FONT.txtSm, color: COLOR.mono.white }, mobileView && { ...FONT.txt2Xs }]}>
                          {workPrice}
                        </Text>
                      </View>
                    </View>
                    {!mobileView && (
                      <View>
                        <Button
                          style={{
                            flexDirection: 'row',
                            paddingHorizontal: 12,
                          }}
                          onPress={onStartPress}
                        >
                          <Text style={{ ...FONT.txtSm, color: COLOR.mono.white }}>
                            {t('explainworkmodel.header.start')}
                          </Text>
                          <XEIcon name={'arrow-right'} size={16} color={'mono.white'} style={{ marginTop: -2 }} />
                        </Button>
                      </View>
                    )}
                  </View>
                </View>
              </View>
              {carouselItem ? (
                <View
                  style={
                    mobileView
                      ? {
                          marginBottom: 20,
                          marginTop: -80,
                        }
                      : { marginBottom: 20, marginTop: -100 }
                  }
                >
                  <ImageCarousel
                    items={carouselItem}
                    duration={3000}
                    transitionDuration={500}
                    loop={false}
                    showButtons={!mobileView}
                    showIndicators={true}
                    resizeMode={'contain'}
                    imageContentStyle={{ backgroundColor: 'rgba(0,0,0,0)' }}
                  />
                </View>
              ) : (
                <></>
              )}
              <View style={mobileView && { paddingHorizontal: 32 }}>
                <View style={styles.detailInfo}>
                  <Text numberOfLines={line} ellipsizeMode={'tail'}>
                    {workDescription}
                  </Text>
                  <View style={{ alignItems: 'center' }}>
                    <Button
                      backgroundColor={'transparent.base'}
                      style={{ flexDirection: 'row' }}
                      onPress={() => handleLine()}
                    >
                      <XEIcon name={isActivated ? 'angle-up' : 'angle-down'} size={12} color={'gray.g700'} />
                      <Text style={{ color: COLOR.gray.g700, marginLeft: 8 }}>{t('explainworkmodel.more')}</Text>
                    </Button>
                  </View>
                </View>

                <View style={styles.templetInfo}>
                  <Text style={[{ marginBottom: 12 }, mobileView ? { ...FONT.H7Medium } : { ...FONT.H5Medium }]}>
                    {t('explainworkmodel.selecttemplate')}
                  </Text>
                  <CardButtonVertical list={cardButtonItem} />
                </View>
              </View>
              <View style={mobileView ? { flexDirection: 'column' } : { flexDirection: 'row' }}>
                {updates ? (
                  <View style={!mobileView && { flex: 5 }}>
                    <ExplainInfoBox
                      title={t('explainworkmodel.update.title')}
                      moreText={t('explainworkmodel.update.moretext')}
                      onPress={() => {
                        onInfoBoxMorePress && onInfoBoxMorePress()
                      }}
                      mobileView={mobileView}
                    >
                      <View>
                        {updates.map((item, index) => (
                          <View style={styles.dl} key={`update-${index}`}>
                            <Text style={styles.dt2}>{item.version}</Text>
                            <Text style={styles.dd}>{item.description}</Text>
                          </View>
                        ))}
                      </View>
                    </ExplainInfoBox>
                  </View>
                ) : (
                  <></>
                )}
                {serviceProvider ? (
                  <View style={mobileView ? { marginTop: 8 } : { flex: 2, marginLeft: 20 }}>
                    <ExplainInfoBox title={t('explainworkmodel.serviceprovider.title')} mobileView={mobileView}>
                      <View>
                        <View>
                          <View style={styles.dl}>
                            <Text style={styles.dt}>{t('explainworkmodel.serviceprovider.producer')}</Text>
                            <Text style={styles.dd}>{serviceProvider.producer}</Text>
                          </View>
                          <View style={styles.dl}>
                            <Text style={styles.dt}>{t('explainworkmodel.serviceprovider.area')}</Text>
                            <Text style={styles.dd}>{serviceProvider.area}</Text>
                          </View>
                          <View style={styles.dl}>
                            <Text style={styles.dt}>{t('explainworkmodel.serviceprovider.language')}</Text>
                            <View
                              style={{
                                flex: 1,
                                flexWrap: 'wrap',
                                flexDirection: 'row',
                              }}
                            >
                              {serviceProvider.languages.map((lang, index) => (
                                <BlockLabel text={lang} fontName={'txt2Xs'} key={`language-${index}`} />
                              ))}
                            </View>
                          </View>
                        </View>
                      </View>
                    </ExplainInfoBox>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </View>
          </View>
        </View>
      </StickyHeader>
    </View>
  )
}

export default ExplainWorkModel

const styles = StyleSheet.create({
  container: {},
  pageContainer: {
    backgroundColor: COLOR.gray.g050,
  },
  frame: {
    alignItems: 'center',
  },

  inner: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  productTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 28,
  },

  title: {
    ...FONT.H4Bold,
  },

  infoText: {
    ...FONT.txtSm,
  },

  detailInfo: {
    paddingBottom: 64,
  },

  priceLabel: {
    alignSelf: 'flex-start',
    justifyContent: 'center',
    borderRadius: 30,
    backgroundColor: COLOR.main.darkNavy,
    marginTop: 12,
    paddingVertical: 4,
    paddingHorizontal: 12,
  },

  templetInfo: {
    alignItems: 'center',
    paddingBottom: 60,
  },

  dl: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  dt: {
    minWidth: 72,
    ...FONT.txtXs,
  },
  dt2: {
    minWidth: 40,
  },
  dd: {
    ...FONT.txtXs,
  },
})
