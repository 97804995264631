import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text } from 'react-native'
import { styles } from './List'
import type { DropdownItem } from './types'

interface ItemProps {
  item: DropdownItem
  selected: string[]
  editAccess?: boolean
  onPressItem?: () => void
}

const MultifulItem = ({ item, selected, editAccess, onPressItem }: ItemProps) => {
  const [isHover, setIsHover] = React.useState(false)
  const active = React.useMemo(() => selected.includes(item.code), [item, selected])

  return (
    <Pressable
      style={[
        styles.listButton,
        innerStyles.listButton,
        {
          backgroundColor: active ? COLOR.main.blue : isHover ? COLOR.gray.g050 : COLOR.mono.white,
        },
      ]}
      onPress={() => {
        if (editAccess) {
          onPressItem && onPressItem()
        }
      }}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      <>
        <Text
          style={[styles.listText, active ? { color: COLOR.mono.white } : { color: COLOR.mono.black }]}
          numberOfLines={1}
        >
          {item.title}
        </Text>
        {active && <XEIcon name="check" color="mono.white" size={12} />}
      </>
    </Pressable>
  )
}

export default MultifulItem

const innerStyles = StyleSheet.create({
  listButton: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g100,
  },
})
