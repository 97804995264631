import { View } from '@rocket-mono/foundations'
import { useMobileView } from '@rocket-mono/libs'
import React from 'react'

import SettingItemMobile from './mobile'
import { SettingListProps } from './types'
import SettingItemWeb from './web'

const SettingItem: React.FC<SettingListProps> = ({ list, tab, onPress }) => {
  const isMobile = useMobileView(960)
  return (
    <View>
      {list.map((item, idx) => {
        const key = `menu-${idx}`
        const props = { item, key, tab, onPress }

        return isMobile ? <SettingItemMobile {...props} /> : <SettingItemWeb {...props} />
      })}
    </View>
  )
}

export default SettingItem
