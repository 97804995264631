import { useMobileView } from '@rui/foundations'
import * as React from 'react'
import WorkSettingMobileView from './mobile'
import { Props } from './types'
import WorkSettingWebView from './web'

const View: React.FC<Props> = (props) => {
  const isMobile = useMobileView(960)

  if (isMobile) return <WorkSettingMobileView {...props} />
  else return <WorkSettingWebView {...props} />
}

export default View
