import { FONT } from '@rui/foundations'
import React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

interface WorkMemberTableItemType {
  children: React.ReactNode
}
const WorkMembersTableItem = ({ children }: WorkMemberTableItemType) => {
  return (
    <Pressable style={[cellStyles.cells]}>
      <View style={cellStyles.flexBox}>{children}</View>
    </Pressable>
  )
}

export default WorkMembersTableItem

export const cellStyles = StyleSheet.create({
  cells: {
    height: 48,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  cellInput: {
    width: '100%',
  },
  cellText: {
    ...FONT.txtSm,
  },
  flexBox: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
})
