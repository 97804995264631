import type { IColors, IconName } from '@rui/foundations'
import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import TabItem from './Item'

export type TopTabItem = {
  name: string
  icon?: IconName
  code: string
  ea?: string
  isShow?: boolean
}

interface Props {
  code?: string
  list: TopTabItem[]
  onCode?: (code: string) => void
  initCode?: string
  eaColor?: IColors
  style?: ViewStyle
  itemFlexAuto?: boolean
  pressCode?: () => string
  forceChangeCount?: number
}

function TopTab({
  code,
  list,
  onCode,
  initCode,
  eaColor,
  style,
  itemFlexAuto,
  pressCode,
  forceChangeCount = 0,
}: Props) {
  const [selected, setSelected] = React.useState(code ?? initCode ?? 'discussion')

  return (
    <View style={[styles.container, style]}>
      {list.map((item, idx) => (
        <TabItem
          item={item}
          code={code}
          onCode={onCode}
          eaColor={eaColor}
          itemFlexAuto={itemFlexAuto}
          pressCode={pressCode}
          selected={selected}
          setSelected={setSelected}
          forceChangeCount={forceChangeCount}
          key={`tab-${idx}`}
        />
      ))}
    </View>
  )
}

export default TopTab

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: COLOR.mono.lightGray,
  },
  buttonStyle: {
    flex: 1,
    borderBottomWidth: 3,
  },
  itemStyle: {
    height: 36,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 6,
    backgroundColor: COLOR.mono.paleWhite,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
  itemIcon: {
    marginRight: 4,
  },
  itemEa: {
    ...FONT.txtSm,
    marginLeft: 4,
  },
})
