import { Avatar, Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, View } from 'react-native'

interface Props {
  userId: string
  name: string
  email: string
  auth: string
  imageSrc?: string
  selected?: boolean
  onClick: (id: string) => void
}

function MemberItem({ userId, name, email, imageSrc, auth, selected = false, onClick }: Props): JSX.Element {
  const [hover, setHover] = React.useState(false)
  const _onClick = (): void => onClick(userId)
  const { t } = useTranslation()
  return (
    <Pressable
      style={[styles.container, hover ? styles.hover : {}]}
      onHoverIn={() => setHover(true)}
      onHoverOut={() => setHover(false)}
    >
      <View style={styles.innerLeftContainer}>
        <Avatar profileUrl={imageSrc} userName={name} size="large" />
        <View style={styles.innerLeftTextContainer}>
          <Text fontName="textBold" fontColor="mono.black">
            {name}
          </Text>
          <Text fontName="subTextRegular" fontColor="mono.darkGray" style={styles.userEmail}>
            {email}
          </Text>
        </View>
      </View>
      <View style={styles.innerRightContainer}>
        <Text fontName="textRegular" fontColor="mono.gray" style={styles.authority}>
          {t(`withdrawal.auth.${auth.toLocaleLowerCase()}`)}
        </Text>
        <Pressable
          style={selected ? styles.iconCheck : styles.iconCheckNone}
          onPress={_onClick}
          onHoverIn={() => setHover(true)}
          onHoverOut={() => setHover(false)}
        >
          <XEIcon name="check-min" size={20} color={'mono.white'} />
        </Pressable>
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  // @ts-ignore
  container: {
    ...Platform.select({
      web: {
        padding: '0.5rem',
      },
    }),
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      web: {
        cursor: 'default',
      },
    }),
  },
  hover: {
    backgroundColor: COLOR.mono.paleWhite,
  },
  innerLeftContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  // @ts-ignore
  innerLeftTextContainer: {
    ...Platform.select({
      web: {
        marginLeft: '0.75rem',
      },
    }),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  // @ts-ignore
  userEmail: {
    ...Platform.select({
      web: {
        paddingTop: '0.25rem',
      },
    }),
  },
  innerRightContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  // @ts-ignore
  authority: {
    ...Platform.select({
      web: {
        marginRight: '0.5rem',
      },
    }),
  },
  iconCheckNone: {
    width: 28,
    height: 28,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: COLOR.mono.lightGray,
  },
  iconCheck: {
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.main.blue,
    borderRadius: 30,
  },
})

export default React.memo(MemberItem)
