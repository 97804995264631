import { Text } from '@rui/atoms'
import type { FontName, IColors } from '@rui/foundations'
import { getRealColor } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

interface Props {
  text: string
  fontName: FontName
  fontColor: IColors
  labelColor: IColors
}

const RoundLabel: React.FC<Props> = ({ text, fontName, fontColor, labelColor }) => {
  return (
    <View style={[styles.priceLabel, { backgroundColor: getRealColor(labelColor) }]}>
      <Text fontName={fontName} fontColor={fontColor}>
        {text}
      </Text>
    </View>
  )
}

export default RoundLabel

const styles = StyleSheet.create({
  priceLabel: {
    alignSelf: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 60,
  },
})
