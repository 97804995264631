import { COLOR, IColors } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

interface Props {
  variant?: string
  iconColor: IColors
  checked: boolean
}

const CheckIcon: React.FC<Props> = ({ variant, iconColor, checked }) => {
  const circleSmall = variant === 'circleSmall'
  return (
    <View style={[styles.checkIcon, circleSmall && styles.checkIconCircleSmall, checked && styles.chckIconChecked]}>
      <XEIcon name={'check'} size={circleSmall ? 12 : 16} color={iconColor} />
    </View>
  )
}

export default CheckIcon

const styles = StyleSheet.create({
  checkIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    borderRadius: 30,
    backgroundColor: COLOR.gray.g100,
    marginRight: 4,
  },

  chckIconChecked: {
    backgroundColor: COLOR.sub.darkNavy,
    borderColor: COLOR.sub.darkNavy,
  },

  checkIconCircleSmall: {
    width: 16,
    height: 16,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
  },
})
