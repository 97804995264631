import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'

import { COLOR, FONT } from '@rui/foundations'

export interface UnreadProps {
  isWhisper?: boolean
  unreadCount: number
  onPressUnread?: () => void
}

const MessageUnread: React.FC<UnreadProps> = ({ isWhisper = false, unreadCount, onPressUnread }) => {
  return (
    <TouchableOpacity
      style={[
        styles.container,
        {
          backgroundColor: isWhisper ? COLOR.gray.g150 : COLOR.main.red,
        },
      ]}
      hitSlop={hitSlop}
      onPress={onPressUnread}
    >
      <Text style={styles.text} selectable={false}>
        {isWhisper ? 'S' : unreadCount}
      </Text>
    </TouchableOpacity>
  )
}

export default MessageUnread

const styles = StyleSheet.create({
  container: {
    width: 20,
    height: 20,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: { ...FONT.txt3XsBold, color: COLOR.mono.white },
})

const hitSlop = { top: 10, left: 10, right: 10, bottom: 10 }
