import * as React from 'react'

import { useCurrentUser } from '@rocket-mono/providers'
import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ children }: ProviderProps) => {
  const { t } = useTranslation()
  const { currentUser, updateName, updateProfile } = useCurrentUser()
  const { show: showToastMessage } = useToast()

  const onProfileSave = React.useCallback((file: File, done?: () => void) => {
    void updateProfile(file).then(() => {
      showToastMessage({
        type: 'Success',
        title: t('setting.userinfo.successmessage.profiletoast'), //'프로필 변경이 완료되었습니다.',
        position: 'BOTTOM_CENTER',
      })
      done && done()
    })
  }, [])

  const onSave = React.useCallback((name: string, lang: string, done?: () => void) => {
    void updateName(name, lang).then(() => {
      showToastMessage({
        type: 'Success',
        title: t('setting.userinfo.successmessage.namechangetoast'), //'이름 변경이 완료되었습니다.',
        position: 'BOTTOM_CENTER',
      })
      done && done()
    })
  }, [])

  return (
    <Context.Provider
      value={{
        currentUser,
        onProfileSave,
        onSave,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
