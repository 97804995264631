import { View } from '@rocket-mono/foundations'
import { getRealColor, IColors } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Pressable, TextInput } from 'react-native'
import { IconDelete } from '../../icons'

interface Props {
  name: string
  color: IColors
  onChangeName?: (name: string) => void
  onPressDelete?: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null
}

export const TableorderMenuLabelItem: React.FC<Props> = ({
  name,
  color,
  onChangeName,
  onPressDelete,
  dragHandleProps,
}) => {
  return (
    <View style={{ marginBottom: 12, flexDirection: 'row', alignItems: 'center' }}>
      <div {...dragHandleProps}>
        <Pressable style={{ width: 22, alignItems: 'center' }}>
          <XEIcon name="drag-vertical" size="16" />
        </Pressable>
      </div>
      <View
        style={{
          backgroundColor: getRealColor(color),
          width: 34,
          height: 34,
          borderRadius: 5,
          marginHorizontal: 8,
        }}
      />
      <TextInput
        style={{
          flex: 1,
          height: 34,
          borderWidth: 1,
          borderColor: getRealColor('gray.g200'),
        }}
        value={name}
        onChangeText={onChangeName}
      />
      <View style={{ width: 22 }}>{onPressDelete && <IconDelete onPressDelete={onPressDelete} />}</View>
    </View>
  )
}
