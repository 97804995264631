import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import type { FontName } from '@rui/foundations'
import { XEIcon } from '@rui/icons'

interface WorkModelCategoryProps {
  text: string
  textFont?: FontName
  index: number
  isFold: boolean
  onPress?: (is: boolean) => void
}

export const WorkModelCategory: React.FC<WorkModelCategoryProps> = ({
  text,
  textFont = 'txtSm',
  index,
  isFold,
  onPress,
}) => {
  return (
    <Pressable
      style={[styles.container, { marginTop: index > 0 ? 32 : 0 }]}
      onPress={() => onPress && onPress(!isFold)}
    >
      <Text fontName={textFont} fontColor="gray.g700">
        {text}
      </Text>
      <View style={{ width: 20 }}>
        <XEIcon name={isFold ? 'angle-down' : 'angle-up'} size={12} color={'gray.g300'} />
      </View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 24,
    backgroundColor: COLOR.mono.white,
  },
})
