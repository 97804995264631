import { i18n } from 'i18next'
import React from 'react'
import { I18nextProvider } from 'react-i18next'

interface Props {
  i18n: i18n
  children?: JSX.Element | JSX.Element[]
}
const Providers: React.FC<Props> = ({ children, i18n }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <>{children}</>
    </I18nextProvider>
  )
}

export default Providers
