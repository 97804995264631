import { XEIcon } from '@rocket/atoms'
import { TextField } from '@rui/atoms'
import { COLOR, useMobileView } from '@rui/foundations'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import { AxisButton } from './Button'
import TopTab from './TopTab'

interface Props {
  showTab: boolean
  tab?: string
  onTab?: (tab: string) => void
  searchText: string
  setSearchText?: (arg0: string) => void
  onPressInviteMember?: () => void
  memberCount: number
}
const WorkMembersHeader: React.FC<Props> = ({
  showTab,
  tab,
  onTab,
  searchText,
  setSearchText,
  onPressInviteMember,
  memberCount,
}) => {
  const { t } = useTranslation()
  const mobileView = useMobileView(800)
  const tabList = useMemo(
    () => [
      {
        name: `${t('workmember.header.membertitle', { memberCount })}`,
        code: 'member',
        isShow: true,
      },
      {
        name: `${t('workmember.header.archivetitle')}`,
        code: 'archive',
        isShow: true,
      },
    ],
    [memberCount],
  )

  return (
    <View
      style={[
        styles.container,
        mobileView ? { flexDirection: 'column-reverse', height: 60 + (showTab ? 52 : 0) } : { alignItems: 'center' },
      ]}
    >
      <View style={[styles.tabs, mobileView ? { alignSelf: 'flex-start' } : undefined]}>
        {showTab && (
          <TopTab
            list={tabList}
            initCode={tab}
            eaColor={'gray.g800'}
            onCode={(code) => onTab && onTab(code)}
            style={{ borderBottomWidth: 0 }}
            itemFlexAuto
          />
        )}
      </View>
      <View style={styles.funcArea}>
        <View style={[styles.searchArea, mobileView ? { flex: 1, marginLeft: 12 } : { minWidth: 316 }]}>
          {/* <FieldGroup
            data={[{ key: 'all', label: '전체' }]}
            searchText={searchText}
            rightIcon={'search'}
            setSearchText={(text) => setSearchText && setSearchText(text)}
            placeHolder={'이름 또는 이메일 검색'}
            onSelect={setSelected}
          /> */}
          <TextField
            value={searchText}
            placeholder={t('workmember.header.placeholder')}
            afterIcon={<XEIcon name="search" size={20} color="gray.g700" />}
            onChangeValue={setSearchText}
            style={{ width: '100%' }}
          />
        </View>
        <View>
          {showTab && (
            <AxisButton
              onPress={onPressInviteMember}
              isWeb={false}
              wide={true}
              iconName="plus"
              addText={t('workmember.header.invite')}
              style={{
                height: 36,
                paddingHorizontal: 12,
              }}
            />
          )}
        </View>
      </View>
    </View>
  )
}

export default WorkMembersHeader

const styles = StyleSheet.create({
  container: {
    height: 66,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g300,
  },
  tabs: {
    minWidth: 224,
    alignSelf: 'flex-end',
  },
  searchArea: {
    marginRight: 12,
  },
  funcArea: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 12,
    marginRight: 20,
  },
})
