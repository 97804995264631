import { useTranslation } from 'react-i18next'
import { Platform, StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native'

import { Defs, Pattern, Polygon, Rect, Svg } from 'react-native-svg'
import ButtonIcon from '../ButtonIcon'

import type { IColors } from '@rui/foundations'
import { COLOR, FONT, getRealColor } from '@rui/foundations'
import FileThumbnail from '../FileThumbnail'

interface ImageViewUnitProps {
  file: {
    name: string
    size: number
    mimeType: string
    uri?: string
  }
  uploaderName: string
  date: Date
  hasAuthDownload?: boolean
  hasAuthDelete?: boolean
  boxColor?: IColors
  hideShareButton?: boolean
  hideLinkButton?: boolean
  hideDownloadButton?: boolean
  onPressPreviewButton?: () => void
  onPressShareButton?: () => void
  onPressLinkButton?: () => void
  onPressDownloadButton?: () => void
  onPressDeleteButton?: () => void
  containerStyle?: StyleProp<ViewStyle>
}

const ImageViewUnit = ({
  file,
  uploaderName,
  date,
  hasAuthDelete = false,
  hasAuthDownload = false,
  hideShareButton = false,
  hideLinkButton = false,
  hideDownloadButton = false,
  boxColor = 'mono.paleWhite',
  onPressPreviewButton,
  onPressDeleteButton,
  onPressLinkButton,
  onPressDownloadButton,
  onPressShareButton,
  containerStyle,
}: ImageViewUnitProps) => {
  const { t } = useTranslation()
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.box}>
        <BoxBackground hasAuthDownload={hasAuthDownload} color={boxColor} />
        <View style={styles.innerLeftBox}>
          <FileThumbnail
            uri={file.uri}
            size="small"
            mimeType={hasAuthDownload ? file.mimeType : ''}
            onPress={onPressPreviewButton}
          />
          <View style={styles.textContainer}>
            <View style={styles.textTopContainer}>
              <Text style={styles.filename} numberOfLines={1} ellipsizeMode="middle">
                {hasAuthDownload ? file.name : t('imageviewunit.noauth')}
              </Text>
              <Text style={styles.fileSize}>
                {hasAuthDownload ? printFileSize(file.size) : `??${getFileSize(file.size).unit}`}
              </Text>
            </View>
            <Text style={styles.dateAndUploader}>
              {t('format.date.L', { date })} from {hasAuthDownload ? uploaderName : t('imageviewunit.private')}
            </Text>
          </View>
        </View>
        <View style={styles.buttons}>
          {hasAuthDownload && (
            <>
              {!hideShareButton && (
                <ButtonIcon
                  iconName="share-alt-o"
                  backgroundColor="mono.white"
                  iconColor="mono.darkGray"
                  rounded
                  containerSize={28}
                  onPress={onPressShareButton}
                  style={styles.button}
                />
              )}
              {(Platform.OS === 'android' || Platform.OS === 'ios') && !hideLinkButton && (
                <ButtonIcon
                  iconName="external-link"
                  backgroundColor="mono.white"
                  iconColor="mono.darkGray"
                  rounded
                  containerSize={28}
                  onPress={onPressLinkButton}
                  style={styles.button}
                />
              )}
              {!hideDownloadButton && (
                <ButtonIcon
                  iconName="download"
                  backgroundColor="mono.white"
                  iconColor="mono.darkGray"
                  rounded
                  containerSize={28}
                  onPress={onPressDownloadButton}
                  style={styles.button}
                />
              )}
            </>
          )}
        </View>
      </View>
      {hasAuthDelete && (
        <ButtonIcon
          iconName="close"
          backgroundColor="main.red"
          iconColor="mono.white"
          rounded
          containerSize={16}
          iconViewbox="0 -100 1000 1000"
          style={styles.deleteButton}
          onPress={onPressDeleteButton}
        />
      )}
    </View>
  )
}

export default ImageViewUnit

const BoxBackground = ({ hasAuthDownload, color }: { hasAuthDownload: boolean; color: IColors }) => {
  return (
    <View style={StyleSheet.absoluteFill}>
      <Svg height="100%" width="100%">
        <Defs>
          <Pattern id="stripes" viewBox="0 0 8 8" width="16" height="16" patternUnits="userSpaceOnUse">
            <Polygon points="0,0 4,0 0,4" fill={COLOR.mono.paleWhite}></Polygon>
            <Polygon points="0,8 8,0 8,4 4,8" fill={COLOR.mono.paleWhite}></Polygon>
            <Polygon points="0,4 0,8 8,0 4,0" fill={COLOR.mono.pale}></Polygon>
            <Polygon points="4,8 8,8 8,4" fill={COLOR.mono.pale}></Polygon>
          </Pattern>
        </Defs>
        <Rect
          fill={hasAuthDownload ? getRealColor(color) : 'url(#stripes)'}
          x="0"
          y="0"
          height="100%"
          width="100%"
          rx={10}
        />
      </Svg>
    </View>
  )
}

function getFileSize(x: number) {
  const s = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'] as const
  const e = Math.floor(Math.log(x) / Math.log(1024))
  return {
    size: (x / 1024 ** e).toFixed(0),
    unit: s[e],
  }
}

function printFileSize(x: number): string {
  const { size, unit } = getFileSize(x)

  return `${size}${unit}`
}

const styles = StyleSheet.create({
  container: { display: 'flex', flexDirection: 'row' },
  box: {
    height: 38,
    minWidth: 269,
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    flex: 1,
  },
  innerLeftBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  textContainer: { marginLeft: 5, flex: 1 },
  textTopContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  filename: { ...FONT.subTextRegular, color: COLOR.mono.black },
  fileSize: {
    ...FONT.subTextThin,
    color: COLOR.mono.paleBlack,
    marginTop: 4,
    marginLeft: 8,
  },
  dateAndUploader: { ...FONT.subTextThin, color: COLOR.mono.paleBlack },
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 50,
  },
  button: {
    marginLeft: 10,
  },
  deleteButton: {
    marginLeft: 10,
    alignSelf: 'center',
  },
})
