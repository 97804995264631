import { XEIcon } from '@rocket/atoms'
import { TextField } from '@rui/atoms'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, View, ViewStyle } from 'react-native'
import AddMemberButton from './addMemberButton'

interface Props {
  onTextChange?: (text: string) => void
  onAddMember?: (pageX?: number, pageY?: number) => void
  onAddMemberResize?: (height: number) => void
  isAdmin?: boolean
  containerStyle?: ViewStyle
}

const InputMemberSearch = ({
  onTextChange,
  onAddMember,
  isAdmin = false,
  containerStyle,
  onAddMemberResize,
}: Props) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  React.useEffect(() => {
    onTextChange && onTextChange(searchText)
  }, [searchText, onTextChange])
  const inputRef = React.useRef<any>(null)
  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.searchContainer}>
        <TextField
          value={searchText}
          inputRef={inputRef}
          // height={36}
          placeholder={t('memberlist.input.placeholder')}
          afterIcon={
            <View>
              <View>
                <XEIcon name="search" color="mono.black" size={20} />
              </View>
              {searchText ? (
                <Pressable
                  onPress={() => {
                    setSearchText('')
                    if (Platform.OS === 'web') {
                      const ref = inputRef as React.RefObject<HTMLDivElement>
                      if (ref && ref.current) {
                        ref.current.innerText = ''
                        ref.current.focus()
                      }
                    }
                  }}
                >
                  <XEIcon name="close-circle" color="main.red" size={20} />
                </Pressable>
              ) : (
                <></>
              )}
            </View>
          }
          // rightIconSize={20}
          // rightIcon={{
          //   name: 'search',
          //   color: 'mono.black',
          // }}
          // rightIconStyle={{
          //   marginLeft: 12,
          // }}
          // rightIcon2={
          //   searchText
          //     ? {
          //         name: 'close-circle',
          //         color: 'main.red',
          //         onPress: () => {
          //           setSearchText('')
          //           if (Platform.OS === 'web') {
          //             const ref = inputRef as React.RefObject<HTMLDivElement>
          //             if (ref && ref.current) {
          //               ref.current.innerText = ''
          //               ref.current.focus()
          //             }
          //           }
          //         },
          //       }
          //     : undefined
          // }
          // rightIconStyle2={{
          //   marginLeft: 12,
          //   marginTop: -2,
          // }}
          // rightIconSize2={20}
          onChangeValue={setSearchText}
          // onInput={(e) => {
          //   if (Platform.OS === 'web') {
          //     setSearchText(e.target.innerText)
          //   }
          // }}
          // autoComplete="off"
        />
      </View>
      {isAdmin && (
        <>
          <AddMemberButton onAddMember={onAddMember} onResize={onAddMemberResize} />
        </>
      )}
    </View>
  )
}

export default InputMemberSearch

const styles = StyleSheet.create({
  container: {},
  searchContainer: {
    height: 36,
    marginBottom: 16,
  },
})
