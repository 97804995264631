import { TableorderMenuOptionGroupType } from '@rocket/types'
import * as React from 'react'
import View from './view'

interface Props {
  groupType?: TableorderMenuOptionGroupType
  closeModal: () => void
}
export const TableorderMenuOptionModal: React.FC<Props> = ({ groupType, closeModal }) => {
  return <View groupType={groupType} closeModal={closeModal} />
}
