import * as React from 'react'

import { COLOR, FONT } from '@rui/foundations'
import { Image, StyleSheet, Text, View, ViewStyle } from 'react-native'

interface Props {
  uri?: string
  isSoldout?: boolean
  // state: string
  width?: number
  height?: number
  containerStyle?: ViewStyle
}
const MenuThumbnail: React.FC<Props> = ({ uri, isSoldout, width = 86, height = 86, containerStyle }) => {
  return (
    <View style={[styles.thumbnail, { width, height }, containerStyle]}>
      <Image source={{ uri: uri }} style={{ width, height, borderRadius: 8 }} />
      {isSoldout && (
        <View style={styles.stateSoldout}>
          <View style={styles.soldoutLabel}>
            <Text style={{ color: COLOR.mono.white, ...FONT.txtSm }}>품절</Text>
          </View>
        </View>
      )}
    </View>
  )
}

export default MenuThumbnail

const styles = StyleSheet.create({
  thumbnail: {
    overflow: 'hidden',
    width: 86,
    height: 86,
    marginRight: 8,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLOR.gray.g100,
  },
  stateSoldout: {
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0, .4)',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  soldoutLabel: {
    width: 48,
    height: 36,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: COLOR.main.red,
  },
})
