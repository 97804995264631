import { Text } from '@rui/atoms'
import type { FontName } from '@rui/foundations'
import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

interface Props {
  text: string
  fontName: FontName
}

const BlockLabel: React.FC<Props> = ({ text, fontName }) => {
  return (
    <View style={[styles.label]}>
      <Text fontName={fontName} style={{ color: COLOR.gray.g700 }}>
        {text}
      </Text>
    </View>
  )
}

export default BlockLabel

const styles = StyleSheet.create({
  label: {
    alignSelf: 'flex-start',
    paddingVertical: 4,
    paddingHorizontal: 4,
    borderRadius: 4,
    backgroundColor: COLOR.gray.g050,
    marginRight: 4,
    marginBottom: 4,
  },
})
