import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import { IconProfileLabelLeader } from '@rocket/atoms'
import { Avatar } from '@rui/atoms'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, Text, View } from 'react-native'
import type { MemberListItem, MemberStateType } from './types'

interface Props {
  secureCdnUrl: string
  item: MemberListItem
}

const MemberItem: React.FC<Props> = ({ secureCdnUrl, item }) => {
  const { t } = useTranslation()

  const statePretty = (state: MemberStateType) => {
    switch (state) {
      case '나':
        return t('memberlist.state.me')
      case '관리자':
        return t('memberlist.state.admin')
      case '소유자':
        return t('memberlist.state.owner')
      case '초대':
        return t('memberlist.state.invite')
      case '멤버':
        return t('memberlist.state.member')
      default:
        return ''
    }
  }
  return (
    <View style={styles.rowCenterContainer}>
      <View style={styles.imageProfileContainer}>
        {item.userId && item.userId !== 'null' ? (
          <Avatar profileUrl={`${secureCdnUrl}/profile/${item.email}`} userName={item.name} size="large" />
        ) : (
          <View style={{ width: 40, height: 40, backgroundColor: COLOR.gray.g150, borderRadius: 40 }}>
            {/* <NoProfileImage width={40} /> */}
          </View>
        )}
        {item.positionLeader && (
          <View style={styles.positionLabel}>
            <IconProfileLabelLeader />
          </View>
        )}
      </View>
      <View style={styles.userInfoStyle}>
        <View style={styles.userNameStyle}>
          {item.state ? (
            <View
              style={[
                styles.userStateStyle,
                item.state === '나' && {
                  backgroundColor: COLOR.main.blue,
                  borderColor: COLOR.transparent.base,
                },
              ]}
            >
              <Text
                style={[
                  styles.userStateText,
                  item.state === '나' && {
                    color: COLOR.mono.white,
                  },
                ]}
              >
                {statePretty(item.state)}
              </Text>
            </View>
          ) : (
            <></>
          )}
          <Text style={styles.userNameText} numberOfLines={1}>
            {item.name}
          </Text>
          {item.isNewInvite ? <View style={styles.userInvite} /> : <></>}
        </View>
        <Text style={styles.userMailText} numberOfLines={1}>
          {item.email}
        </Text>
      </View>
    </View>
  )
}

export default MemberItem

const styles = StyleSheet.create({
  rowCenterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-start',
    flexShrink: 1,
  },
  imageProfileContainer: {
    position: 'relative',
  },
  positionLabel: {
    position: 'absolute',
    bottom: -2,
    right: -4,
  },
  userInfoStyle: { marginLeft: 8, marginRight: 16, flex: 1 },
  userNameStyle: {
    flexDirection: 'row',
    marginBottom: 1,
    flex: 1,
  },
  userStateStyle: {
    height: 18,
    borderWidth: 1,
    borderColor: COLOR.mono.lightGray,
    borderRadius: 30,
    paddingHorizontal: 4,
    marginTop: 2,
    marginRight: 4,
  },
  userStateText: {
    ...FONT.chatSubTextThin,
    color: COLOR.mono.paleBlack,
    fontWeight: '400',
    lineHeight: 15,
  },
  userNameText: {
    ...FONT.textMedium,
    color: COLOR.mono.black,
    ...Platform.select({
      android: {
        alignSelf: 'center',
      },
    }),
  },
  userInvite: {
    height: 6,
    width: 6,
    backgroundColor: getRealColor('main.red'),
    borderRadius: 25,
  },
  userMailText: {
    ...FONT.subTextRegular,
    color: COLOR.mono.darkGray,
    fontWeight: '400',
    // whiteSpace: 'nowrap',
    // overflow: 'hidden',
    // textOverflow: 'ellipsis',
  },
})
