import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { View, type LayoutChangeEvent } from 'react-native'

import { useTranslation } from 'react-i18next'

import { WorkCardViewScreen } from '@rocket-mono/screen-card'

import { CardContentsProvider, CardTodoDetailProvider, CardTodoDetailScreen, TodoStepView } from '@rui/screen-card'

import {
  useAstro,
  useAuth,
  useCurrentUser,
  usePreview,
  useSubscription,
  useWork,
  useWorkCard,
  useWorkChannel,
  useWorkProject,
} from '@rocket-mono/providers'
import { File, TodoViewPermission } from '@rocket/types'
import { Skeleton, SkeletonWorkCardNewTabView } from '@rocket/skeleton'

import { uuid } from '@rui/utils'

type Preview = {
  id: string
  title: string
  uri: string
  uploadDate: string
  uploaderName: string
  uploaderProfile: string
  mimeType: string
}

interface Props {
  todoId: string
  window?: boolean
  isFold: boolean
  opened: boolean
  modal?: boolean
  maxWidth?: number
  currentProjectId: string
  onUnAuthorized?: () => void
  onPressWindow?: () => void
  onPressBack: () => void
  onPressClose: () => void
}

const CardTodoDetailView: React.FC<Props> = ({ todoId, onUnAuthorized, ...viewProps }) => {
  const { i18n } = useTranslation()
  const { astro, option } = useAstro()

  const { currentUser } = useCurrentUser()
  const { projectList } = useWork()
  const { currentProject, projectMemberList } = useWorkProject()
  const { currentChannel, currentChannelMembers } = useWorkChannel()
  const { currentCard } = useWorkCard()
  const { isAnonymous, showLoginModal } = useAuth()
  const { openPreview } = usePreview()

  const [todoTitle, setTodoTitle] = useState<string>()
  const [todoViewPermission, setTodoViewPermission] = useState<TodoViewPermission>()

  const projectElement = useMemo(
    () => currentProject?.elements.find((o) => o.id === currentChannel?.projectElementId),
    [currentProject, currentChannel],
  )

  const [shortenerHashCode, setShortenerHashCode] = useState<string>()

  const handlePreview = useCallback((previewList: Preview[], file: File) => {
    if (!currentProject || !currentChannel || !currentCard) return

    if (file.mimeType && !file.mimeType.includes('image') && !file.mimeType.includes('pdf')) return

    const fileKey = file.relatedDomain || ''
    const filename = file.name || ''
    const fileIndex = previewList.findIndex((item) => item.id === file.id)

    openPreview(
      'card',
      fileKey,
      filename,
      previewList.map((item) => ({
        ...item,
        workType: currentProject.type.code,
        workName: currentProject.title,
        boardName: currentChannel.roomName,
        cardName: currentCard.title,
      })),
      currentProject.type.code,
      currentProject.title,
      fileIndex,
    )
  }, [])

  const handleLoadFile = useCallback(
    (file: globalThis.File | Blob): Promise<string> => {
      console.log('handleLoadFile', file)

      const fileFormData = new FormData()
      fileFormData.append('file', file)

      const relatedDomain = 'textarea'
      const relatedDomainId = uuid()

      return new Promise((resolve, reject) => {
        astro
          .createFile({ relatedDomain, relatedDomainId, fileFormData })
          .then((res) => {
            console.log('handleLoadFile-res', res)
            if (res.path)
              astro.readFilePresignedUrl(res.path).then((url: string) => {
                console.log('handleLoadFile-readFilePresignedUrl', url)
                resolve(url)
              })
            else reject()
          })
          .catch((err) => {
            reject()
            console.log('handleLoadFile-err', err)
          })
      })
    },
    [astro],
  )

  useEffect(() => {
    if (currentCard) {
      const url = `${option.workLegacyUrl}/card/${currentCard.no}?todoId=${todoId}`
      astro
        .readShortenerUrlList(encodeURIComponent(url))
        .then((res) => res.find((o) => o.linkUrl === url))
        .then((res) => {
          return res ? res.hashCode : astro.createShortenerUrl(url).then((res) => res.hashCode)
        })
        .then(setShortenerHashCode)
    }
  }, [currentCard, astro])

  useEffect(() => {
    if (todoId) {
      astro
        .readTodo(todoId)
        .then(({ content, viewPermission }) => {
          setTodoTitle(content)
          setTodoViewPermission(viewPermission ?? 'WORK_MEMBER')
        })
        .catch((err) => {
          console.error('readTodo', err)
        })
    }
  }, [todoId])

  const handlePressCardLink = React.useCallback(({ cardNo, channelNo }) => {
    // console.log('onCardLinkPress', { cardNo, cardName, channelNo })
    // astro.readCard(cardNo, '').then((card) => console.log('card', { card }))
    window.open(`/card/${cardNo}?channelId=${channelNo}`, '_blank')
  }, [])
  const handlePressLink = React.useCallback((url: string) => {
    console.log('onLinkPress', { url })
    const uri = url.startsWith('http') ? url : `https://${url}`
    window.open(uri, '_blank')
  }, [])

  // const permissionCheck = useCallback(() => {}, [TodoViewPermission])
  useEffect(() => {
    console.log('permissionView', todoViewPermission)

    if (todoViewPermission !== undefined) {
      if (isAnonymous && todoViewPermission !== 'EVERYONE') {
        console.log('permissionView', todoViewPermission, 'onUnAuthorized')
        onUnAuthorized?.()
      }
      if (todoViewPermission === 'WORK_MEMBER') {
        const m = projectMemberList?.find((o) => o.userId === String(currentUser.id))
        if (m === undefined) {
          console.log('permissionView', todoViewPermission, 'onUnAuthorized', { currentUser, projectMemberList })
          onUnAuthorized?.()
        }
      }
      if (todoViewPermission === 'BOARD_MEMBER') {
        const m = currentChannelMembers.find((o) => o.userId === String(currentUser.id))
        if (m === undefined) {
          console.log('permissionView', todoViewPermission, 'onUnAuthorized', { currentUser, currentChannelMembers })
          onUnAuthorized?.()
        }
      }
    }
  }, [isAnonymous, todoViewPermission, currentUser, projectMemberList, currentChannelMembers])

  useSubscription(currentCard ? `/subscribe/v2/card/${currentCard.no}/todo/updated` : '', ({ body }) => {
    const jsonBody = JSON.parse(body)
    const { viewPermission } = jsonBody
    setTodoViewPermission(viewPermission)
  })

  const [offset, setOffset] = useState(0)
  const [maxWidth, setMaxWidth] = useState(0)

  const handleLayout = useCallback(({ nativeEvent }: LayoutChangeEvent) => {
    console.log('handleLayout', nativeEvent.layout)

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setOffset(-nativeEvent.layout.top + 20)
    setMaxWidth(nativeEvent.layout.width)
  }, [])

  if (!currentProject || !projectElement || !currentChannel) return null

  return (
    <CardTodoDetailProvider
      projectId={currentProject.id}
      boardId={currentChannel.id}
      i18n={i18n}
      astro={astro}
      cdnUrl={option.secureCdnUrl ?? ''}
      cardTitle={currentCard?.title ?? ''}
      todoId={todoId}
      userId={String(currentUser.id)}
      isAnonymous={isAnonymous}
      projectList={projectList ?? []}
      project={currentProject}
      projectElement={projectElement}
      projectMemberList={projectMemberList ?? []}
      channelMemberList={currentChannelMembers}
      onPressAnonymous={showLoginModal}
      onPreview={handlePreview}
      fallback={
        location.pathname.endsWith('/board') ? (
          <SkeletonWorkCardNewTabView isModal />
        ) : (
          <Skeleton path={location.pathname} />
        )
      }
      subscribe={useSubscription}
      onPressLink={handlePressLink}
      onPressCardLink={handlePressCardLink}
    >
      <CardContentsProvider
        offset={offset}
        maxWidth={maxWidth}
        projectId={currentProject.id}
        boardId={currentChannel.id}
        astro={astro}
        i18n={i18n}
        projectList={projectList ?? []}
        onLoadFile={handleLoadFile}
      >
        <WorkCardViewScreen
          i18n={i18n}
          todoId={todoId}
          todoTitle={todoTitle}
          shortenerHashCode={shortenerHashCode}
          {...viewProps}
          cardType={'TODO'}
          sideHeader={<TodoStepView type="SIDE" />}
          sideView={
            <>
              <CardTodoDetailScreen type="SIDE" />
            </>
          }
        >
          {(width) => (
            <View onLayout={handleLayout}>
              <CardTodoDetailScreen type={width > 800 ? 'SUMMARY' : undefined} />
            </View>
          )}
        </WorkCardViewScreen>
      </CardContentsProvider>
    </CardTodoDetailProvider>
  )
}

export default CardTodoDetailView
