/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Icon } from '@rocket-atoms/icon'
import type { IconName } from '@rocket-mono/libs'
import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import { Pressable, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'
import type { Axis } from '../Popup/AddPopup'

interface Props {
  iconName?: IconName
  iconSize?: number
  onPress?: () => void
  onAxis?: React.Dispatch<React.SetStateAction<Axis>>
  style?: ViewStyle
  isWeb?: boolean
  addText?: string
  addTextStyle?: TextStyle
  wide?: boolean
  isIconOnly?: boolean
}

// test
function AxisButton({
  iconName = 'plus',
  iconSize,
  onPress,
  onAxis,
  style,
  isWeb,
  addText,
  addTextStyle,
  wide = false,
  isIconOnly = false,
}: Props) {
  const buttonRef = React.useRef<View>(null)
  const onStyle = React.useCallback(
    // @ts-ignore hovered only used in web
    ({ hovered }: PressableStateCallbackType) => {
      return [
        styles.container,
        isIconOnly
          ? {
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-start',
              backgroundColor: '#f8f8f8',
              borderWidth: 0,
              paddingHorizontal: 10,
            }
          : {},
        style,
        hovered && styles.hover,
        { width: wide ? '100%' : 30 },
        { flexDirection: 'row' },
      ] as ViewStyle[]
    },
    [isIconOnly, style, wide],
  )

  if (isWeb)
    React.useEffect(() => {
      const onScroll = () => {
        if (onAxis) {
          buttonRef?.current?.measureInWindow((x, y) => {
            onAxis({ x, y })
          })
        }
      }

      const onResize = () => {
        onScroll()
        setTimeout(onScroll, 500)
      }
      document.addEventListener('scroll', onScroll)
      window.addEventListener('resize', onResize)
      return () => {
        document.removeEventListener('scroll', onScroll)
        window.removeEventListener('resize', onResize)
      }
    }, [])

  return (
    <View ref={buttonRef}>
      <Pressable
        onPress={() => {
          onPress && onPress()
          if (onAxis) {
            buttonRef?.current?.measureInWindow((x, y) => {
              onAxis({ x, y })
            })
          }
        }}
        style={onStyle}
        onLayout={() => {
          if (onAxis) {
            buttonRef?.current?.measureInWindow((x, y) => {
              onAxis({ x, y })
            })
          }
        }}
      >
        <Icon name={iconName} color="mono.paleBlack" size={isIconOnly ? '15' : iconSize ? iconSize : '12'} />
        {!isIconOnly ? (
          <View style={{ marginLeft: 4 }}>
            <Text style={addTextStyle ? addTextStyle : styles.addText}>{addText ?? '추가'}</Text>
          </View>
        ) : (
          <></>
        )}
      </Pressable>
    </View>
  )
}

AxisButton.defaultProps = {
  style: {},
}

export default React.memo(AxisButton)
const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderStyle: 'dotted',
    borderRadius: 5,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: COLOR.gray.g450,
    backgroundColor: COLOR.mono.white,
  },
  hover: {
    backgroundColor: '#fcfcfc',
  },
  addText: {
    ...FONT.txtSm,
    color: getRealColor('mono.paleBlack'),
  },
})
