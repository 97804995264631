import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

const ProfileEdit: React.FC<{ size: number }> = ({ size }) => {
  return (
    <View style={[styles.container, { width: size, height: size }]}>
      <XEIcon name={'camera'} size={16} color={'mono.white'} style={{ marginTop: -2 }} />
    </View>
  )
}

export default ProfileEdit

const styles = StyleSheet.create({
  container: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLOR.sub.darkNavy,
    borderWidth: 3,
    borderColor: COLOR.mono.white,
    borderRadius: 50,
  },
})
