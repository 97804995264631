import { TableorderMenuLabel } from '@rocket/types'
import { changeItemAtIndex, cutByteString, removeItemAtIndex, replaceItemAtIndex } from '@rui/foundations'
import * as React from 'react'
import { useTableorderShop } from '../ShopProvider'
import Context from './context'
import type { MenuLabelColor, MenuLabelType, ProviderProps } from './types'

const LABEL_NAME_ARRAY: string[] = ['대표', '인기', '추천']
const LABEL_COLOR_ARRAY: MenuLabelColor[] = ['main.turquoise', 'main.yellow', 'sub.lightBlue', 'main.pink']

const Provider = ({ astro, children }: ProviderProps) => {
  const { shopId } = useTableorderShop()
  const [menuLabelList, setMenuLabelList] = React.useState<TableorderMenuLabel[]>([])

  const [menuLabelForm, setMenuLabelForm] = React.useState<MenuLabelType[]>([])

  const fetchMenuLabelList = React.useCallback((shopId: string) => {
    astro
      .readMenuLabelList(shopId)
      .then((list) => {
        if (list.length === 0) {
          return Promise.all(
            Array.from({ length: 3 }, (_, sequenceNumber) => {
              const name = LABEL_NAME_ARRAY[sequenceNumber]
              const color = LABEL_COLOR_ARRAY[sequenceNumber]
              return astro.createMenuLabel({
                shopId,
                name,
                color,
                sequenceNumber,
              })
            }),
          )
        }

        return list
      })
      .then((list) => list.filter(({ isDeleted }) => !isDeleted))
      .then((list) => list.sort((a, b) => a.sequenceNumber - b.sequenceNumber))
      .then(setMenuLabelList)
  }, [])

  const saveMenuLabel = React.useCallback(() => {
    if (menuLabelList === undefined) return Promise.reject()

    return Promise.all([
      ...menuLabelList
        .map((o) => o.id)
        .filter(
          (id) =>
            !menuLabelForm
              .filter((o) => !!o.id)
              .map((o) => o.id)
              .includes(id),
        )
        .map((id) => astro.deleteMenuLabel(id)),
      ...menuLabelForm.map(({ id, name, color }, sequenceNumber) => {
        if (!!id) {
          astro.updateMenuLabel(id, {
            id,
            shopId,
            name,
            color,
            sequenceNumber,
          })
        } else {
          astro.createMenuLabel({ shopId, name, color, sequenceNumber })
        }
      }),
    ]).then(() => {
      fetchMenuLabelList(shopId)
    })
  }, [shopId, menuLabelList, menuLabelForm])
  const createMenuLabel = React.useCallback(() => {
    setMenuLabelForm((prev) => {
      const color = LABEL_COLOR_ARRAY.filter((o) => !prev.map((o) => o.color).includes(o))[0]
      return [...prev, { name: '', color }]
    })
  }, [])

  const updateMenuLabelName = React.useCallback((index: number, name: string) => {
    setMenuLabelForm((prev) => {
      const label = prev[index]
      const cutByte = cutByteString(name, 12)
      return replaceItemAtIndex(prev, index, { ...label, name: cutByte })
    })
  }, [])

  const deleteMenuLabel = React.useCallback((index: number) => {
    setMenuLabelForm((prev) => removeItemAtIndex(prev, index))
  }, [])
  const changeMenuLabel = React.useCallback((from: number, to: number) => {
    setMenuLabelForm((prev) => changeItemAtIndex(prev, from, to))
  }, [])

  React.useEffect(() => {
    fetchMenuLabelList(shopId)
  }, [shopId])

  React.useEffect(() => {
    if (menuLabelList !== undefined)
      setMenuLabelForm(
        menuLabelList.map(({ id, name, color }) => ({
          id,
          name,
          color: color as MenuLabelColor,
        })),
      )
  }, [menuLabelList])

  return (
    <Context.Provider
      value={{
        menuLabelList,
        menuLabelForm,
        saveMenuLabel,
        createMenuLabel,
        updateMenuLabelName,
        deleteMenuLabel,
        changeMenuLabel,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
