import React from 'react'

interface Props {
  children?: JSX.Element | JSX.Element[]
}
const Providers: React.FC<Props> = ({ children }) => {
  return <>{children}</>
}

export default Providers
