import * as React from 'react'

import { COLOR, FONT } from '@rui/foundations'
import { ScrollView, StyleSheet, Text, View, ViewStyle } from 'react-native'
import MenuThumbnail from './MenuThumbnail'
import OrderMenuOptionItem from './OrderMenuOptionItem'

export interface OrderConfirmationItemProps {
  state: string
  image: string
  title: string
  option?: {
    name: string
    price: string
  }[]
  count: string
  price: string
  priceNumber: number
}

interface Props {
  list: OrderConfirmationItemProps[]
  containerStyle?: ViewStyle
}
const OrderConfirmationItem: React.FC<Props> = ({ list, containerStyle }) => {
  const Item = ({ item }: { item: OrderConfirmationItemProps }) => {
    return (
      <View style={[styles.container]}>
        <MenuThumbnail uri={item.image} />
        <View style={styles.info}>
          <Text style={styles.menuName}>{item.title}</Text>
          {item.option?.map((el, idx) => (
            <OrderMenuOptionItem item={el} idx={idx} key={`option-${idx}`} />
          ))}
          <Text style={styles.count}>{item.count} 개</Text>
          <Text style={[styles.price]}>{item.price}원</Text>
        </View>
      </View>
    )
  }
  return (
    <ScrollView style={{ flex: 1 }}>
      <View style={containerStyle}>
        {list.map((item, idx) => (
          <Item item={item} key={`confirmationItem-${idx}`} />
        ))}
      </View>
    </ScrollView>
  )
}

export default OrderConfirmationItem

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g100,
  },
  info: {
    flex: 1,
  },
  menuName: {
    ...FONT.txtMdBold,
    marginVertical: 2,
  },
  count: {
    marginTop: 8,
    textAlign: 'right',
    ...FONT.txtSm,
  },
  price: {
    textAlign: 'right',
    ...FONT.H7Bold,
  },
})
