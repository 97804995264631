import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Button } from '../components'

interface Props {
  title: string
  onPress?: () => void
  moreText?: string
  children: React.ReactNode
  mobileView: boolean
}
const ExplainInfoBox = ({ title, onPress, moreText, children, mobileView }: Props) => {
  return (
    <View style={[styles.infoBox, mobileView && { borderRadius: 0, paddingHorizontal: 32 }]}>
      <View style={styles.infoBoxTitle}>
        <Text style={styles.infoBoxText}>{title}</Text>
        {onPress && (
          <Button backgroundColor={'transparent.base'} style={{ paddingHorizontal: 12 }} onPress={onPress}>
            <Text style={{ color: COLOR.primary.blue500 }}>{moreText}</Text>
          </Button>
        )}
      </View>
      <View style={{ paddingTop: 8 }}>{children}</View>
    </View>
  )
}

export default ExplainInfoBox

const styles = StyleSheet.create({
  infoBox: {
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 12,
    backgroundColor: COLOR.mono.white,
  },

  infoBoxTitle: {
    height: 36,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 4,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
  },

  infoBoxText: {
    ...FONT.txtSmMedium,
  },
})
