import * as React from 'react'
import { Image, StyleSheet, View } from 'react-native'
import Carousel from './Carousel'

export type MenuDetailPhotoItemType = {
  id: number
  type: 'IMAGE' | 'VIDEO'
  uri: string
}

interface Props {
  items: MenuDetailPhotoItemType[]
  visible?: boolean
  duration?: number
  height?: number
  onPress: (item: MenuDetailPhotoItemType) => void
}

const MenuDetailPhoto = ({ items, height = 400 }: Props) => {
  const [, setContainerWidth] = React.useState(0)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  // const [isHover, setIsHover] = React.useState(false)

  React.useEffect(() => {
    const elem: any = document.getElementById(`video-${currentIndex}`)
    if (elem) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      elem.currentTime = 0
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      elem.play()
    }
  }, [currentIndex])

  return (
    <View
      onLayout={(e) => {
        setContainerWidth(e.nativeEvent.layout.width)
      }}
    >
      {items.length === 1 ? (
        <Image source={{ uri: items[0].uri }} style={[styles.itemImage, { height }]} />
      ) : (
        <Carousel
          onIndex={(idx) => {
            setCurrentIndex(idx + 1)
          }}
        >
          {items.map((item, index) => (
            <View key={`${index}`}>
              {item.type === 'IMAGE' ? (
                <Image source={{ uri: item.uri }} style={[styles.itemImage, { height }]} />
              ) : (
                <></>
              )}
              {item.type === 'VIDEO' ? (
                <video width="100%" height={height} muted id={`video-${index}`} autoPlay>
                  <source src={item.uri} type="video/mp4" />
                </video>
              ) : (
                <></>
              )}
            </View>
          ))}
        </Carousel>
      )}
      {/* <Pressable
        style={[styles.expandIcon, isHover && styles.expandIconHover]}
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        onPress={() => {
          const idx = currentIndex === items.length ? 0 : currentIndex
          onPress(items[idx])
        }}
      >
        <Icon
          name={'arrows-alt'}
          size={16}
          color={'mono.white'}
          style={{ marginTop: -2 }}
        />
      </Pressable> */}
    </View>
  )
}

export default MenuDetailPhoto

const styles = StyleSheet.create({
  expandIcon: {
    position: 'absolute',
    right: 12,
    bottom: 12,
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    backgroundColor: 'rgba(0,0,0, .6)',
    borderRadius: 4,
  },
  expandIconHover: {
    backgroundColor: 'rgba(0,0,0, .8)',
  },
  itemImage: {
    width: '100%',
    resizeMode: 'cover',
  },
})
