import * as React from 'react'

import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { StyleSheet, Text, View } from 'react-native'

interface Props {
  totalPrice: string
}
const FooterPriceConfirm: React.FC<Props> = ({ totalPrice }) => {
  return (
    <View style={styles.container}>
      <Text style={{ ...FONT.txtSm }}>총 주문 금액</Text>
      <View style={styles.priceArea}>
        <Text style={styles.price}>{totalPrice}</Text>
        <Text style={styles.unit}>원</Text>
      </View>
    </View>
  )
}

export default FooterPriceConfirm

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    backgroundColor: COLOR.mono.white,
    paddingVertical: 12,
    paddingHorizontal: 16,
    ...getBoxShadow(0, 0, 0, 0.05, 6, 0, -4, 5),
  },
  priceArea: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  price: {
    ...FONT.H5Bold,
  },
  unit: {
    ...FONT.H6Medium,
    marginLeft: 4,
  },
})
