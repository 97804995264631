import { createContext } from 'react'
import type { PageContextType } from './types'

export const PageContext = createContext<PageContextType>({
  current: 0,
  total: 0,
  size: 0,
  state: 'idle',
  navigate: () => {
    throw new Error('PageContext.navigate() is not implemented')
  },
  onState: () => {
    throw new Error('PageContext.onState() is not implemented')
  },
})
