import React from 'react'

import Providers from '../../providers'
import MenuView from './view'

interface Props {
  shopId: string
  blocked?: boolean
  onProceedBlock?: () => void
  onResetBlock?: () => void
  onChangeBlock?: (is: boolean) => void
}

const MenuScreen: React.FC<Props> = ({ shopId, ...blockProps }) => {
  return (
    <Providers>
      <MenuView shopId={shopId} {...blockProps} />
    </Providers>
  )
}

export default MenuScreen
