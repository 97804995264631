import * as React from 'react'

import { replaceItemAtIndex } from '@rui/foundations'
import Context from './context'
import type { BoardType, ProviderProps } from './types'

const Provider = ({ projectId, astro, subscribe, children }: ProviderProps) => {
  const [boardList, setBoardList] = React.useState<BoardType[]>()

  const inviteBoardMember = React.useCallback(
    (channelId: string, userId: string, memberEmail: string, memberName: string) => {
      return astro.inviteChannel(channelId, userId, memberEmail, memberName).then(() =>
        fetchChannelMemberList(channelId).then((memberList) => {
          setBoardList((prev) => {
            if (prev) {
              const idx = prev.findIndex((o) => o.id === channelId)
              return idx < 0 ? prev : replaceItemAtIndex(prev, idx, { ...prev[idx], memberList })
            } else {
              return prev
            }
          })
        }),
      )
    },
    [],
  )

  const leaveBoardMember = React.useCallback((channelId: string, memberId: string) => {
    return astro.deleteChannelMember(channelId, memberId).then(() =>
      fetchChannelMemberList(channelId).then((memberList) => {
        setBoardList((prev) => {
          if (prev) {
            const idx = prev.findIndex((o) => o.id === channelId)
            return idx < 0 ? prev : replaceItemAtIndex(prev, idx, { ...prev[idx], memberList })
          } else {
            return prev
          }
        })
      }),
    )
  }, [])

  const fetchChannelList = React.useCallback(
    (init?: boolean) => {
      return astro
        .readChannelList({ type: 'G', projectId })
        .then((list) => {
          if (init) setBoardList(list)
          return list
        })
        .then((list) =>
          Promise.all(
            list.map((channel) =>
              fetchChannelMemberList(channel.id).then((memberList) => ({
                ...channel,
                memberList,
              })),
            ),
          ),
        )
    },
    [projectId],
  )

  const fetchChannelMemberList = React.useCallback((channelId: string) => astro.readChannelMemberList(channelId), [])

  subscribe(`/subscribe/project/${projectId}/update`, () => {
    fetchChannelList().then(setBoardList)
  })

  React.useEffect(() => {
    fetchChannelList(true).then(setBoardList)
  }, [projectId])

  console.log('boardList', boardList)
  return <Context.Provider value={{ boardList, inviteBoardMember, leaveBoardMember }}>{children}</Context.Provider>
}

export default Provider
