import * as React from 'react'

import { Provider } from './provider'
import type { AstroType } from './types'
import View from './view'

interface Props {
  id: string
  astro: AstroType
  onPressBack?: () => void
  onPressClose: () => void
  onBack?: () => void
  goHome?: () => void
  onSuccess: (id: string) => void
  skeletonComponent?: JSX.Element[] | JSX.Element
}
export const WorkmodelUpdateView: React.FC<Props> = ({
  id,
  astro,
  onPressBack,
  onPressClose,
  onBack,
  goHome,
  onSuccess,
  skeletonComponent,
}) => {
  return (
    <Provider id={id} astro={astro} closeModal={onPressClose} skeletonComponent={skeletonComponent}>
      <View
        astro={astro}
        onPressBack={onPressBack}
        onPressClose={onPressClose}
        onBack={onBack}
        goHome={goHome}
        onSuccess={onSuccess}
      />
    </Provider>
  )
}
