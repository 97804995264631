import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native'

interface Props {
  onAddMember?: (pageX?: number, pageY?: number) => void
  onResize?: (height: number) => void
}
const AddMemberButton = ({ onAddMember, onResize }: Props) => {
  const { t } = useTranslation()
  const [isHover, setIsHover] = useState(false)

  const ref = React.useRef<View>(null)

  React.useEffect(() => {
    const listener = () => {
      onResize &&
        ref.current &&
        ref.current.measure((_x, _y, _width, height, _pageX, pageY) => onResize(height + pageY))
    }
    if (Platform.OS === 'web') {
      window.addEventListener('resize', listener)
    }
    return () => {
      if (Platform.OS === 'web') {
        window.removeEventListener('resize', listener)
      }
    }
  }, [])

  return (
    <View style={{ width: '100%' }} ref={ref}>
      <View style={styles.addMemberButton}>
        <Pressable
          style={[styles.addMember, isHover && styles.addMemberHover]}
          onHoverIn={() => setIsHover(true)}
          onHoverOut={() => setIsHover(false)}
          onPress={() => {
            if (onAddMember) {
              if (ref.current) {
                ref.current.measure((_x, _y, _width, height, pageX, pageY) => {
                  onAddMember(pageX, height + pageY)
                })
              } else {
                onAddMember()
              }
            }
          }}
        >
          <XEIcon name={'plus'} size={16} color={'gray.g600'} style={{ marginTop: -2 }} />
        </Pressable>
        <Pressable
          style={{
            width: '100%',
            ...Platform.select({
              android: {
                height: 16,
              },
            }),
          }}
          onPress={() => {
            if (onAddMember) {
              if (ref.current) {
                ref.current.measure((_x, _y, _width, height, pageX, pageY) => {
                  onAddMember(pageX, height + pageY)
                })
              } else {
                onAddMember()
              }
            }
          }}
        >
          <Text style={styles.addText}>{t('memberlist.input.tooltipText')}</Text>
        </Pressable>
      </View>
    </View>
  )
}

export default AddMemberButton

const styles = StyleSheet.create({
  addMemberButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addMember: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    borderWidth: 1,
    borderColor: COLOR.gray.g150,
    backgroundColor: COLOR.gray.g050,
    borderRadius: 30,
  },
  addMemberHover: {
    backgroundColor: COLOR.gray.g100,
  },
  addText: {
    ...FONT.txtSmMedium,
    color: COLOR.gray.g600,
    marginLeft: 8,
    ...Platform.select({
      android: {
        lineHeight: 16,
      },
    }),
  },
})
