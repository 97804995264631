import React from 'react'

import { useAstro, useCurrentUser } from '@rocket-mono/providers'
import { TableorderMenuScreen } from '../../components'

interface Props {
  shopId: string
  blocked?: boolean
  onProceedBlock?: () => void
  onResetBlock?: () => void
  onChangeBlock?: (is: boolean) => void
}

const MenuView: React.FC<Props> = ({ shopId, ...blockProps }) => {
  const { astro } = useAstro()
  const { currentUser } = useCurrentUser()

  if (currentUser === undefined) return <></>
  return <TableorderMenuScreen shopId={shopId} astro={astro} currentUser={currentUser} {...blockProps} />
}

export default MenuView
