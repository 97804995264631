import { use100vh } from 'react-div-100vh'

import { View } from '@rocket-mono/foundations'

import { EmptyTemplate, ExplainWorkModel, WorkmodelCreateScreen } from '@rocket-screens/workmodel'
import { Project, ProjectPositionCreation } from '@rocket/types'

import { COLOR } from '@rocket-mono/libs'
import { useAstro } from '@rocket-mono/providers'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NativeScrollEvent, NativeSyntheticEvent, ScrollView, StyleSheet } from 'react-native'
import { translation } from '../../utils/explainmodel_translation'

interface Props {
  typeCode: string
  position?: Omit<ProjectPositionCreation, 'projectId'>
  onPressBack: () => void
  onSaveSuccess: (project: Project) => void
  onSaveFailed: (message: string) => void
}

const CreateWorkView: React.FC<Props> = ({ typeCode, position, onPressBack, onSaveFailed, onSaveSuccess }) => {
  const { t, i18n } = useTranslation()
  const height = use100vh()
  const { astro } = useAstro()

  const [detailView, setDetailView] = useState(true)
  const modelTranslation = useMemo(() => {
    const tr = translation[`${typeCode.toLowerCase()}_${i18n.language}`]

    return tr === undefined ? translation[`${typeCode.toLowerCase()}_en`] : tr
  }, [typeCode, i18n.language])

  console.log('modelTranslation', modelTranslation)

  const [contentOffsetY, setContentOffsetY] = useState(0)

  const handleScroll = useCallback((e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const contentOffsetY = e.nativeEvent.contentOffset.y
    setContentOffsetY(contentOffsetY > 100 ? 100 : 1)
  }, [])

  return (
    <View style={{ height: height ?? '100%', backgroundColor: COLOR.mono.white }}>
      <ScrollView
        onScroll={handleScroll}
        scrollEventThrottle={8}
        contentContainerStyle={{ flex: 1, backgroundColor: COLOR.mono.white }}
      >
        {detailView ? (
          !!typeCode && (
            <ExplainWorkModel
              contentOffsetY={contentOffsetY}
              workIcon={typeCode}
              workTitle={
                typeCode === 'DSP'
                  ? t(`workmodel.product.dsp.title`)
                  : typeCode === 'WFP'
                  ? t(`workmodel.product.wfp.title`)
                  : typeCode === 'CSP'
                  ? t(`workmodel.business.csp.title`)
                  : typeCode === 'TOP'
                  ? t(`workmodel.business.top.title`)
                  : ''
              }
              workInfo={
                typeCode === 'DSP'
                  ? t(`workmodel.product.dsp.description`)
                  : typeCode === 'WFP'
                  ? t(`workmodel.product.wfp.description`)
                  : typeCode === 'CSP'
                  ? t(`workmodel.business.csp.description`)
                  : typeCode === 'TOP'
                  ? t(`workmodel.business.top.description`)
                  : ''
              }
              workPrice={
                typeCode === 'DSP'
                  ? t(`workmodel.product.dsp.price`)
                  : typeCode === 'WFP'
                  ? t(`workmodel.product.wfp.price`)
                  : typeCode === 'CSP'
                  ? t(`workmodel.business.csp.price`)
                  : typeCode === 'TOP'
                  ? t(`workmodel.business.top.price`)
                  : ''
              }
              workDescription={
                typeCode === 'DSP'
                  ? `${t(`workmodel.product.dsp.information`)}\r\n${t(`workmodel.product.dsp.information1`)}\r\n${t(
                      `workmodel.product.dsp.information2`,
                    )}}`
                  : typeCode === 'WFP'
                  ? `${t(`workmodel.product.wfp.information`)}\r\n${t(`workmodel.product.wfp.information1`)}\r\n${t(
                      `workmodel.product.wfp.information2`,
                    )}}`
                  : typeCode === 'CSP'
                  ? t(`workmodel.business.csp.information`)
                  : typeCode === 'TOP'
                  ? `${t(`workmedel.business.top.information`)}\r\n${t(`workmedel.business.top.information1`)}`
                  : ''
              }
              cardButtonItem={[
                {
                  title: t(`workmodel.templates.empty.title`),
                  info: t(`workmodel.templates.empty.info`),
                  uri: (
                    <View style={styles.cardButtonContainer}>
                      <EmptyTemplate />
                    </View>
                  ),
                  ctaButton: true,
                  ctaText: t(`explainworkmodel.header.start`),
                  onPress: function () {
                    setDetailView(false)
                    // setExplainModalType(undefined)
                    // setTypeCode(typeCode)
                  },
                },
              ]}
              carouselItem={[
                { id: 0, uri: modelTranslation.image1 },
                { id: 1, uri: modelTranslation.image2 },
                { id: 2, uri: modelTranslation.image3 },
              ]}
              onBackPress={onPressBack}
              onStartPress={() => {
                setDetailView(false)
              }}
              parentElement="explain-modal"
              headerOffsetTop={70}
            />
          )
        ) : (
          <WorkmodelCreateScreen.Provider
            astro={astro}
            onPressBack={() => {
              setDetailView(true)
            }}
            onSaveSuccess={onSaveSuccess}
            onSaveFailed={onSaveFailed}
          >
            <WorkmodelCreateScreen.Consumer>
              {(context) => (context ? <WorkmodelCreateScreen {...context} typeCode={typeCode} /> : <></>)}
            </WorkmodelCreateScreen.Consumer>
          </WorkmodelCreateScreen.Provider>
        )}
      </ScrollView>
    </View>
  )
}

export default CreateWorkView

const styles = StyleSheet.create({
  cardButtonContainer: {
    marginTop: 40,
    width: 289,
    alignItems: 'center',
  },
})
