import * as React from 'react'
import { Dimensions, Modal, Platform, Pressable, StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native'

import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { CardIcon } from '@rocket-atoms/icon'
import { COLOR, FONT, getRealColor } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'

export type Axis = {
  x: number
  y: number
}

export type AppList = {
  cardType?: 'ATTEND' | 'MEETING' | 'MISSION' | 'COLLECTION' | 'TASKREPORT' | 'TOTAL' | 'DISCUSSION' | 'MESSAGE'
  title: string
  state: string
  disabled?: boolean
  divider?: boolean
}

interface Props {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  appList: AppList[]
  onPopupState?: (state: string) => void
  onAppsPress?: () => void
  containerStyle?: ViewStyle
  axis?: Axis
  bottomText?: string
  marginTop?: number
  marginRight?: number
  popAxisPosition?: string
  AxisWide?: number
}

const styles = StyleSheet.create({
  flexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rootContainer: {
    flex: 1,
  },
  backContainer: {
    flex: 1,
  },
  container: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
    width: 200,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  ifCardIcon: {
    marginRight: 8,
  },
  bottomTextArea: {
    paddingVertical: 4,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g150,
  },
  line: {
    borderTopWidth: 1,
    borderTopColor: COLOR.gray.g150,
  },
  button: {
    paddingHorizontal: 10,
    paddingVertical: 10,
  },
  listText: {
    ...FONT.txtSm,
  },
  buttonText: {
    ...FONT.txtXs,
    color: COLOR.gray.g600,
  },
  bottomButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addIcon: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderStyle: 'dashed',
    borderRadius: 6,
    marginRight: 8,
  },
})

function AddPopup({
  isOpen,
  setIsOpen,
  appList,
  onPopupState,
  onAppsPress,
  containerStyle,
  axis = { x: 0, y: 0 },
  bottomText,
  marginTop = 30,
  marginRight = 0,
  popAxisPosition,
  AxisWide,
}: Props) {
  const useAxis = React.useMemo(() => {
    return axis
  }, [axis])

  return (
    <Modal style={styles.rootContainer} transparent visible={isOpen}>
      <Pressable style={styles.backContainer} onPress={() => setIsOpen(false)} />
      <View
        testID="addPopup.view"
        style={[
          styles.container,
          containerStyle,
          AxisWide !== 0 && { width: AxisWide },
          Platform.select({
            ios: {
              shadowColor: 'rgb(50, 50, 50)',
              shadowOpacity: 0.5,
              shadowRadius: 4,
              shadowOffset: {
                height: 0,
                width: 0,
              },
            },
            android: {
              elevation: 10,
            },
            web: {
              boxShadow: `0px 0px 5px rgba(0, 0, 0, 0.1)`,
            },
          }),
          popAxisPosition === 'left' && {
            top: useAxis.y + marginTop + 5,
            left: useAxis.x - 180 - marginRight,
          },
          !popAxisPosition && {
            top: useAxis.y + marginTop + 5,
            left: Dimensions.get('window').width - 180 > useAxis.x ? useAxis.x : useAxis.x - 138 - marginRight,
          },
        ]}
      >
        {appList.map((app, idx) => (
          <View key={`app-${idx}`}>
            {app.divider && <View style={styles.line} />}
            <TouchableOpacity
              onPress={() => {
                if (!app.disabled) {
                  if (onPopupState) {
                    onPopupState(app.state)
                  }
                  setIsOpen(false)
                }
              }}
              style={[styles.flexbox, styles.button]}
            >
              {app.cardType && (
                <View style={styles.ifCardIcon}>
                  <CardIcon variant="ROUNDSQUARE" type={app.cardType} size="md" />
                </View>
              )}
              <Text style={styles.listText} fontColor={app.disabled ? 'mono.gray' : 'mono.black'}>
                {app.title}
              </Text>
            </TouchableOpacity>
          </View>
        ))}

        {bottomText && (
          <>
            <View style={styles.bottomTextArea}>
              <TouchableOpacity onPress={onAppsPress} style={[styles.button, styles.bottomButton]}>
                <View style={styles.flexbox}>
                  {appList[0].cardType && (
                    <View style={styles.addIcon}>
                      <FontAwesomeIcon icon={faPlus} size={11} color={getRealColor('gray.g400')} />
                    </View>
                  )}
                  <Text style={styles.buttonText}>{bottomText}</Text>
                </View>

                <View>
                  <FontAwesomeIcon icon={faArrowRight} size={11} color={getRealColor('gray.g400')} />
                </View>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </Modal>
  )
}

AddPopup.defaultProps = {
  containerStyle: {},
}

export default React.memo(AddPopup)
