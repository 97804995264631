/* eslint-disable @typescript-eslint/no-use-before-define, @typescript-eslint/no-non-null-assertion */

import { Astro } from '@rocket/astronaut'
import { Notification as AstroNotification, Project as AstroProject, ProjectMember } from '@rocket/types'

import {
  ApproveItem,
  ApproveNoticeMessageType,
  DenyItem,
  DenyNoticeMessageType,
  NotifyItem,
  NotifyLeaveType,
  RequestItem,
} from './types'

export interface InvitationHandlerProps {
  onAccept: () => void
  onReject: () => void
}

export function createRequestItem({
  // astro,
  notification,
  requestUser,
  thumbnailUri,
  project,
}: // userId,
{
  astro: Astro
  notification: AstroNotification
  targetUser?: ProjectMember
  requestUser?: ProjectMember
  project?: AstroProject
  userId: string
  thumbnailUri: string
  onDismiss: () => void
} & Partial<InvitationHandlerProps>): RequestItem {
  return {
    id: notification.id,
    userName: requestUser?.name ?? '',
    projectName: project?.title ?? notification.addonProject?.projectName ?? '',
    createdAt: notification.regDate,
    expirationDate: notification.addonProject?.expirationDate,
    type: notification.eventType,
    imageSrc: thumbnailUri,
    onAccept: () => {
      // approve(notification)
      console.debug('createRequestItem')
    },
    onReject: () => {
      // deny(notification)
      console.debug('createRequestItem')
    },
    onDismiss: () => {
      // deleteNotification(notification)
      console.debug('createRequestItem')
    },
  } as RequestItem
}

export function createDenyOrApproveItem({
  notification,
  requestUser,
  project,
  thumbnailUri,
  onDismiss,
}: {
  notification: AstroNotification
  project?: AstroProject
  requestUser?: ProjectMember
  thumbnailUri: string
  onDismiss: () => void
}): DenyItem | ApproveItem {
  return {
    id: notification.id,
    userName: requestUser?.name ?? notification.regUserName,
    projectName: project?.title ?? notification.addonProject?.projectName ?? '',
    createdAt: notification.regDate,
    type: notification.eventType as ApproveNoticeMessageType | DenyNoticeMessageType,
    imageSrc: thumbnailUri,
    onDismiss,
  }
}

export function createNotifyItem({
  notification,
  project,
  requestUser,
  thumbnailUri,
  onDismiss,
}: {
  notification: AstroNotification
  project?: AstroProject
  requestUser?: ProjectMember
  thumbnailUri: string
  onDismiss: () => void
}): NotifyItem {
  return {
    id: notification.id,
    userName: requestUser?.name ?? notification.regUserName,
    projectName: project?.title ?? notification.addonProject?.projectName ?? '',
    createdAt: notification.regDate,
    type: notification.eventType as NotifyLeaveType,
    imageSrc: thumbnailUri,
    onDismiss,
  }
}
