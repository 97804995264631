import { Modal } from '@rocket-mono/foundations'
import { Button } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import Dropdown from '../Dropdown'
import type { WorkSettingTimezoneType } from './types'

const WorkSettingTimezoneMobile: React.FC<WorkSettingTimezoneType> = ({
  defaultIndex,
  data,
  onSelected,
  onPressSave,
  onDismiss,
}) => {
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <Modal.Header textAlign="center" onPressClose={onDismiss}>
        {t('setting.timezone.title')}
      </Modal.Header>
      <Modal.Body style={styles.bodyTopPadding}>
        <View style={styles.contents}>
          <View style={styles.formGroup}>
            {data && onSelected && (
              <Dropdown
                defaultIndex={defaultIndex}
                label={'Select Item'}
                list={data}
                onSelect={(item) => {
                  onSelected(item.key)
                }}
              />
            )}
          </View>
          <View style={styles.bottomArea}>
            <Button
              onPress={onPressSave}
              style={{ paddingHorizontal: 12 }}
              text={<Text style={{ ...FONT.txtSm, color: COLOR.mono.white }}>{t('setting.button.apply')}</Text>}
            />
          </View>
        </View>
      </Modal.Body>
    </View>
  )
}

export default WorkSettingTimezoneMobile

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 424,
    minHeight: 160,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
  },
  bodyTopPadding: {
    paddingTop: 12,
  },
  pageTitle: {
    ...FONT.H6Medium,
  },
  contents: {
    marginTop: 20,
  },
  formGroup: {
    marginBottom: 12,
  },
  bottomArea: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 16,
    paddingTop: 8,
    borderTopWidth: 1,
    borderColor: COLOR.gray.g300,
  },
})
