import * as React from 'react'
import { StyleSheet, ViewStyle, StyleProp, View, TextStyle } from 'react-native'
import { COLOR, FONT } from '@rui/foundations'
import { useTranslation } from 'react-i18next'
import { Text } from '@rocket-atoms/text'
import { Avatar, Tooltip } from '@rui/atoms'


interface Props{ //보류, 진행중, 예정, 대기, 완료
  status: 'ON_HOLD' | 'IN_PROGRESS' | 'SCHEDULED' | 'PENDING' | 'COMPLETED'
  completer?: string
}

const TodoStatusLabel = ({status, completer}: Props) => {
  const { t } = useTranslation()

  const ContainerStyles: StyleProp<ViewStyle>[] = [
    styles.container,
    status === 'ON_HOLD' && {backgroundColor: COLOR.gray.g200},
    status === 'IN_PROGRESS' && {backgroundColor: COLOR.main.turquoise},
    status === 'SCHEDULED' && {backgroundColor: COLOR.main.yellow},
    status === 'PENDING' && {backgroundColor: COLOR.gray.g600},
    status === 'COMPLETED' && {backgroundColor: COLOR.primary.blue500},
  ]

  const TextStyle: StyleProp<TextStyle>[] = [
    styles.fontStyle,
    status === 'ON_HOLD' ? {color: COLOR.gray.g600} : {},
    status === 'SCHEDULED' ? {color: COLOR.main.yellow700} : {},
    completer ? {marginLeft: 2} : {}
  ]

  return(
    <View style={[ContainerStyles]}>
      {completer && 
        <Avatar size={'xxsmall'} profileUrl='completer' />
      }
      <Text style={[TextStyle]}>
        {status === 'ON_HOLD'? t('subtask.status.holding') :
         status === 'IN_PROGRESS'? t('subtask.status.doing') :
         status === 'SCHEDULED'? t('subtask.status.scheduled') :
         status === 'PENDING'? t('subtask.status.wait') :
         status === 'COMPLETED' ? t('subtask.status.complete') : ''
        }
      </Text>
    </View>
  )
}

export default TodoStatusLabel


const styles = StyleSheet.create({
  container:{
    flex: 1,
    alignSelf:'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 4,
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
  fontStyle:{
    ...FONT.txtXsMedium,
    color:COLOR.mono.white
  }
})