import * as React from 'react'

import Context from './context'
import { useWorkBoard } from './hooks'
import Provider from './provider'
import type { AstroType } from './types'
import View from './view'

interface Props {
  projectId?: string
  memberId?: string
  astro: AstroType
  progressCount?: number | null
  doneCount?: number | null
  ruleCount?: number | null
  onPressWorkEdit?: () => void
  onPressRoleEdit?: () => void
  children?: JSX.Element | JSX.Element[]
}
const WorkBoardScreen: React.FC<Props> = ({
  projectId,
  memberId,
  astro,
  progressCount,
  doneCount,
  ruleCount,
  onPressWorkEdit,
  onPressRoleEdit,
  children,
}) => {
  return (
    <Provider
      projectId={projectId}
      memberId={memberId}
      progressCount={progressCount}
      doneCount={doneCount}
      ruleCount={ruleCount}
      onPressWorkEdit={onPressWorkEdit}
      onPressRoleEdit={onPressRoleEdit}
      astro={astro}
    >
      <Context.Consumer>{(context) => context && <View>{children}</View>}</Context.Consumer>
    </Provider>
  )
}

export * from './types'
export { useWorkBoard, WorkBoardScreen }
