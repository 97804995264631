import { SelectList } from '@rocket-molecules/popselector'
import { SkeletonBar } from '@rocket/skeleton'
import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import React, { useCallback, useEffect } from 'react'
import { Dimensions, Pressable, View as RNView, StyleSheet, Text, View } from 'react-native'
import { cellStyles } from '.'

interface WorkMemberTableItemType {
  isLoading: boolean
  idx: number
  onlyRead?: boolean
  cellValue?: string | JSX.Element
  onPressItem?: (index: number) => void
  list: { code: string; text: string }[]
  setCurrentRow: (is: boolean) => void
}
const WorkMembersTableSelectItem = ({
  isLoading,
  idx,
  onlyRead,
  cellValue,
  onPressItem,
  list,
  setCurrentRow,
}: WorkMemberTableItemType) => {
  const dimensions = Dimensions.get('window')
  const cellRef = React.useRef<RNView>(null)
  const popoverRef = React.useRef<any>(null)
  const [cellEdit, setCellEdit] = React.useState(false)
  const [dropdownMeasure, setDropdownMeasure] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    pageX: 0,
    pageY: 0,
  })

  useEffect(() => {
    setCurrentRow(cellEdit)
  }, [cellEdit])

  React.useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (popoverRef.current && !popoverRef.current.contains(e.target as Node)) {
        setCellEdit(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef])

  const changeDropdownMeasure = React.useCallback(() => {
    cellRef.current?.measure((x: number, y: number, width: number, height: number, pageX: number, pageY: number) => {
      if (dimensions.height / 2 > pageY) setDropdownMeasure({ x, y, width, height, pageX, pageY })
      else {
        setDropdownMeasure({
          x,
          y,
          width,
          height: -height - 48,
          pageX,
          pageY,
        })
      }
    })
  }, [cellRef, dimensions])

  const handlePressItem = useCallback(
    (index: number) => {
      setCellEdit(false)
      onPressItem && onPressItem(index)
    },
    [onPressItem],
  )

  return (
    <>
      <Pressable
        ref={cellRef}
        onPress={() => {
          !onlyRead && setCellEdit(!cellEdit)
          changeDropdownMeasure()
        }}
        style={cellStyles.cells}
      >
        <View style={[cellStyles.flexBox, styles.cellBox, cellEdit && { backgroundColor: COLOR.gray.g050 }]}>
          {!isLoading ? (
            <>
              <Text style={styles.cellText}>{cellValue}</Text>
              {!onlyRead && (
                <Pressable
                  onPress={() => {
                    setCellEdit(!cellEdit)
                    changeDropdownMeasure()
                  }}
                >
                  <XEIcon
                    name={cellEdit ? 'angle-up' : 'angle-down'}
                    size={12}
                    color={'gray.g300'}
                    style={{ marginTop: -2 }}
                  />
                </Pressable>
              )}
            </>
          ) : (
            <SkeletonBar width={'80%'} height={12} />
          )}
        </View>
      </Pressable>
      {cellEdit && (
        <View
          ref={popoverRef}
          style={[
            styles.popover,
            {
              minWidth: dropdownMeasure.width + 8,
              top: dropdownMeasure.height - 4,
              left: -4,
            },
          ]}
        >
          <SelectList initialSelectedIndexes={[idx]} onPressItem={handlePressItem}>
            {list.map(({ code, text }, index) => (
              <SelectList.Item key={code} selected={idx === index} text={text} />
            ))}
          </SelectList>
        </View>
      )}
    </>
  )
}

export default WorkMembersTableSelectItem

export const styles = StyleSheet.create({
  cellBox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cellText: {
    ...FONT.txtXs,
    color: COLOR.gray.g700,
  },
  popover: {
    position: 'absolute',
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingVertical: 4,
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 0),
    zIndex: 100,
  },
})
