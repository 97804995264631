import * as React from 'react'

import { FragmentsLayout } from '../Layout'
import { TableorderMenuDetailView } from './components'

import { useModal } from '@rocket-mono/providers'
import type { TableorderMenuOptionGroupType, User } from '@rocket/types'
import {
  TableorderCatalogAstroType,
  TableorderCatalogProvider,
  TableorderFileAstroType,
  TableorderFileProvider,
  TableorderMenuAstroType,
  TableorderMenuProvider,
  TableorderShopAstroType,
  TableorderShopProvider,
} from '../providers'
import { TableorderMenuLabelModal } from '../TableorderMenuLabelModal'
import { TableorderMenuOptionModal } from '../TableorderMenuOptionModal'
import Context from './context'
import Provider from './provider'
import type { AstroType } from './types'
import View from './view'

interface Props {
  shopId: string
  astro: AstroType &
    TableorderFileAstroType &
    TableorderShopAstroType &
    TableorderMenuAstroType &
    TableorderCatalogAstroType
  currentUser: User
  blocked?: boolean
  onProceedBlock?: () => void
  onResetBlock?: () => void
  onChangeBlock?: (is: boolean) => void
}
export const TableorderMenuScreen: React.FC<Props> = ({
  shopId,
  astro,
  currentUser,
  blocked = false,
  onProceedBlock,
  onResetBlock,
  onChangeBlock,
}) => {
  const [optionGroupType, setOptionGroupType] = React.useState<TableorderMenuOptionGroupType>()
  const {
    Modal: MenuLabelModal,
    visible: isOpenLabel,
    close: closeLabel,
    open: openLabel,
  } = useModal('TableorderMenuLabelModal')

  const {
    Modal: MenuOptionModal,
    visible: isOpenOption,
    close: closeOption,
    open: openOption,
  } = useModal('TableorderMenuOptionModal')

  const handleCloseLabel = React.useCallback(() => {
    console.log('handleCloseLabel')
    closeLabel()
  }, [closeLabel])
  const handleCloseOption = React.useCallback(() => {
    console.log('handleCloseOption')
    closeOption()
  }, [closeOption])

  const handleOpenLabel = React.useCallback(() => {
    console.log('handleOpenLabel')
    setOptionGroupType(undefined)
    openLabel()
  }, [openLabel])
  const handleOpenOption = React.useCallback(
    (groupType?: TableorderMenuOptionGroupType) => {
      console.log('handleOpenOption')
      setOptionGroupType(groupType)
      openOption()
    },
    [openOption],
  )

  return (
    <TableorderShopProvider shopId={shopId} astro={astro}>
      <TableorderMenuProvider astro={astro}>
        <TableorderCatalogProvider astro={astro}>
          <MenuLabelModal visible={isOpenLabel}>
            <TableorderMenuLabelModal closeModal={handleCloseLabel} />
          </MenuLabelModal>
          <MenuOptionModal visible={isOpenOption}>
            <TableorderMenuOptionModal groupType={optionGroupType} closeModal={handleCloseOption} />
          </MenuOptionModal>
          <Provider astro={astro} currentUser={currentUser}>
            <Context.Consumer>
              {(context) =>
                context && (
                  <FragmentsLayout
                    FragmentsComponent={
                      context.menuForm ? (
                        <TableorderFileProvider astro={astro}>
                          <TableorderMenuDetailView
                            menuForm={context.menuForm}
                            onPressLabel={handleOpenLabel}
                            onPressOption={handleOpenOption}
                          />
                        </TableorderFileProvider>
                      ) : undefined
                    }
                    isBorder={false}
                  >
                    <TableorderFileProvider astro={astro}>
                      <View
                        blocked={blocked}
                        onProceedBlock={onProceedBlock}
                        onResetBlock={onResetBlock}
                        onChangeBlock={onChangeBlock}
                        onPressLabel={handleOpenLabel}
                        onPressOption={handleOpenOption}
                      />
                    </TableorderFileProvider>
                  </FragmentsLayout>
                )
              }
            </Context.Consumer>
          </Provider>
        </TableorderCatalogProvider>
      </TableorderMenuProvider>
    </TableorderShopProvider>
  )
}
