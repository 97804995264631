import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

export interface TableOrderTabProps {
  id: string
  title: string
}
interface Props {
  list: TableOrderTabProps[]
  code: string
  onCode?: (code: string) => void
}

const TableOrderTab: React.FC<Props> = ({ list, code, onCode }) => {
  const TabItem = ({ item }: { item: TableOrderTabProps; idx: number }) => {
    return (
      <Pressable
        style={[
          styles.buttonWrap,
          code === item.id
            ? { backgroundColor: COLOR.main.blue, borderColor: COLOR.main.blue }
            : { backgroundColor: COLOR.mono.white },
        ]}
        onPress={() => {
          onCode && onCode(item.id)
        }}
      >
        <Text style={[styles.buttonText, code === item.id ? { color: COLOR.mono.white } : { color: COLOR.mono.black }]}>
          {item.title}
        </Text>
      </Pressable>
    )
  }
  return (
    <View style={styles.container}>
      {list.map((item, idx) => (
        <TabItem item={item} idx={idx} key={`tab-idx${idx}`} />
      ))}
    </View>
  )
}

export default TableOrderTab

const styles = StyleSheet.create({
  container: {
    overflow: 'scroll',
    flexDirection: 'row',
    flexGrow: 0,
    width: '100%',
  },
  buttonWrap: {
    minWidth: 60,
    borderWidth: 1,
    borderColor: COLOR.mono.gray,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 16,
    marginRight: 8,
  },
  buttonText: {
    ...FONT.txtSm,
  },
})
