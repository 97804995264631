import { COLOR, FONT } from '@rui/foundations'
import { NoContentsIcon, XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Platform, Pressable, StyleSheet, Text, View } from 'react-native'
import MemberItem from './Item'
import AddMemberButton from './addMemberButton'
import type { InviteMemberListItem, MemberListItem } from './types'

interface Props {
  secureCdnUrl: string
  initCode?: string
  onCode?: (code: string) => void
  inviteList: InviteMemberListItem[]
  invitedList: MemberListItem[]
  onAddMember?: (pageX?: number, pageY?: number) => void
  onInviteMember?: (item: InviteMemberListItem, index: number) => void
  isAllChecked?: boolean
  onAllChecked?: () => void
  onAddMemberResize?: (height: number) => void
  keyword?: string
}

const InviteMember = ({
  secureCdnUrl,
  initCode,
  onCode,
  inviteList,
  invitedList,
  onAddMember,
  onInviteMember,
  isAllChecked = false,
  onAllChecked,
  onAddMemberResize,
  keyword,
}: Props) => {
  const { t } = useTranslation()
  const [addButtonHover, setAddButtonHover] = React.useState(false)
  const [seletedItem, setSelectedItem] = React.useState(initCode ?? '')

  return (
    <Pressable>
      <View style={styles.memberItemContainer}>
        <View style={styles.sectionTitleArea}>
          <Text style={styles.sectionTitle}>{t('memberlist.boardmembertoworkmember')}</Text>
          {inviteList.length > 0 && (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ ...FONT.txtXsMedium, marginRight: 4 }}>{t('memberlist.allcheck')}</Text>
              <Pressable
                style={isAllChecked ? styles.iconCheck : styles.iconCheckNone}
                onPress={() => {
                  console.log('전체 선택')
                  onAllChecked && onAllChecked()
                }}
              >
                <XEIcon name="check-min" size={20} color={'mono.white'} />
              </Pressable>
            </View>
          )}
        </View>
        {inviteList.length > 0 ? (
          <FlatList
            data={inviteList}
            keyExtractor={(_, index) => `member-list-invite-item_${index}`}
            renderItem={({ item, index }) => {
              return (
                <View>
                  <Pressable
                    style={[
                      styles.itemContainer,
                      seletedItem === item.id && {
                        backgroundColor: COLOR.gray.g050,
                      },
                    ]}
                    onHoverIn={() => {
                      setSelectedItem(item.id)
                      if (onCode) {
                        onCode(item.id)
                      }
                    }}
                    onHoverOut={() => {
                      setSelectedItem('')
                    }}
                    onPress={() => {
                      onInviteMember &&
                        onInviteMember(
                          {
                            ...item,
                            selected: !item.selected,
                          },
                          index,
                        )
                    }}
                  >
                    <MemberItem secureCdnUrl={secureCdnUrl} item={item} />
                    <View style={styles.rightAreaContainer}>
                      <View>
                        <Pressable
                          style={item.selected ? styles.iconCheck : styles.iconCheckNone}
                          onPress={() => {
                            onInviteMember && onInviteMember({ ...item, selected: !item.selected }, index)
                          }}
                        >
                          <XEIcon name="check-min" size={20} color={'mono.white'} />
                        </Pressable>
                      </View>
                    </View>
                  </Pressable>
                </View>
              )
            }}
          />
        ) : keyword ? (
          <View style={[styles.noContentArea, styles.justifyCenter]}>
            <View style={{ width: '100%' }}>
              <NoContentsIcon
                type={'search'}
                textSize={'medium'}
                text={t('nocontentsicon.search.text')}
                iconWidth={65}
              />
            </View>
          </View>
        ) : (
          <View style={styles.noContentArea}>
            <View style={{ width: '100%' }}>
              <NoContentsIcon
                type={'member-all'}
                textSize={'medium'}
                text={t('nocontentsicon.mamberall.text')}
                iconWidth={65}
              />
              <Pressable
                style={[styles.addButton, addButtonHover && { borderColor: COLOR.gray.g200 }]}
                onHoverIn={() => setAddButtonHover(true)}
                onHoverOut={() => setAddButtonHover(false)}
              >
                <AddMemberButton onAddMember={onAddMember} onResize={onAddMemberResize} />
              </Pressable>
            </View>
          </View>
        )}

        <View style={styles.sectionDiv}>
          <Text style={[styles.sectionTitle]}>{t('memberlist.invitedworkmember')}</Text>
          <FlatList
            data={invitedList}
            keyExtractor={(_, index) => `member-list-invited-item_${index}`}
            renderItem={({ item }) => {
              return (
                <View>
                  <View style={styles.itemContainer}>
                    <MemberItem secureCdnUrl={secureCdnUrl} item={item} />
                  </View>
                </View>
              )
            }}
          />
        </View>
      </View>
      {/* <Button wide={true} style={styles.bottomButton}>
        <Text>초대하기</Text>
      </Button> */}
    </Pressable>
  )
}

export default InviteMember

const styles = StyleSheet.create({
  memberItemContainer: {
    marginTop: 8,
  },
  noContentArea: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 220,
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 4,
    paddingHorizontal: 4,
    borderRadius: 4,
  },
  addButton: {
    width: '100%',
    borderWidth: 1,
    borderColor: COLOR.gray.g100,
    borderRadius: 8,
    padding: 8,
    ...Platform.select({ web: { marginTop: 24 }, native: { marginTop: 10 } }),
  },
  rightAreaContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'center',
  },
  sectionTitleArea: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 4,
  },
  sectionTitle: {
    ...FONT.txtSm,
    color: COLOR.mono.paleBlack,
  },
  sectionDiv: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g100,
    paddingTop: 20,
    marginTop: 20,
  },
  iconCheckNone: {
    width: 28,
    height: 28,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 30,
    borderColor: COLOR.mono.lightGray,
  },
  iconCheck: {
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.main.blue,
    borderRadius: 30,
  },
})
