import * as React from 'react'
import { Animated, StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'

import type { IColors } from '@rui/foundations'
import { FONT, getRealColor } from '@rui/foundations'

interface Props {
  unfillColor?: IColors
  fillColor?: IColors
  progress: number
  rounded?: boolean
  small?: boolean
  textColor?: IColors
  containerStyle?: StyleProp<ViewStyle>
  progressStyle?: StyleProp<ViewStyle>
  textStyle?: StyleProp<TextStyle>
  showText?: boolean
}

const Gauge = ({
  fillColor = 'mono.darkGray',
  unfillColor = 'mono.paleWhite',
  textColor = 'mono.black',
  rounded = false,
  small = false,
  progress,
  containerStyle,
  progressStyle,
  textStyle,
  showText = true,
}: Props) => {
  const progressValue = React.useRef(new Animated.Value(progress)).current

  React.useEffect(() => {
    Animated.timing(progressValue, {
      toValue: progress,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }, [progress, progressValue])

  return (
    <View style={[styles.container, containerStyle]}>
      <View
        style={[
          styles.progressBar,
          { backgroundColor: getRealColor(unfillColor) },
          { borderRadius: rounded ? 9999 : 0 },
          { height: small ? 8 : 10 },
          progressStyle,
        ]}
      >
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            {
              borderRadius: rounded ? 9999 : 0,
              backgroundColor: getRealColor(fillColor),
              width: progressValue.interpolate({
                inputRange: [0, 100],
                outputRange: ['0%', '100%'],
                extrapolate: 'clamp',
              }),
            },
          ]}
        />
      </View>
      {showText && (
        <Text style={[styles.text, { color: getRealColor(textColor) }, textStyle]}>
          {printClampProgress(progress)}%
        </Text>
      )}
    </View>
  )
}

export default Gauge

export const printClampProgress = (progress: number) => {
  return Math.round(Math.max(Math.min(progress, 100), 0))
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressBar: {
    flex: 1,
    width: '100%',
  },
  text: {
    marginLeft: 12,
    ...FONT.subTextRegular,
  },
})
