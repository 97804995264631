import { UserState } from '@rocket/types'
import { Avatar } from '@rui/atoms'
import { COLOR, FONT, useMobileView } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Dropdown from '../Dropdown'
import { useWorkSetting } from '../providers/WorkSettingProvider'
import WorkSettingStateView from '../WorkSettingStateView'
import { Props } from './types'

const getStateIndex = (userState: UserState) => {
  switch (userState) {
    case 'active':
      return 0
    case 'busy':
      return 1
    case 'off':
      return 2
    default:
      return 0
  }
}

const Mobile = ({ data, stateData }: Props) => {
  const { updateUserState } = useWorkSetting()

  const mobileView = useMobileView(768)
  const [stateType, setStateType] = React.useState<'BUSY' | 'OFF'>()
  const [stateIndex, setStateIndex] = React.useState(0)
  // const [selected, setSelected] = React.useState<DropdownItemType | undefined>()

  // console.log(selected)

  React.useEffect(() => {
    setStateIndex(getStateIndex(data.userState))
  }, [])

  React.useEffect(() => {
    setStateIndex(getStateIndex(data.userState))
  }, [data.userState])

  return (
    <View style={styles.containerMobile}>
      <View style={styles.innerContainerMobile}>
        <View style={[styles.profile, mobileView && { marginBottom: 0 }]}>
          <Avatar profileUrl={data.profile} userName={data.name} size="xlarge" />
        </View>
        <View>
          <View style={styles.userInfo}>
            <Text style={styles.userName}>{data.name}</Text>
            <Text style={styles.userEmail}>{data.email}</Text>
          </View>
          <View>
            <Dropdown
              label={'Select Item'}
              list={stateData}
              currentIndex={stateIndex}
              onSelect={(item) => {
                // console.log('item', item)
                if (item.key === '2') {
                  setStateType('BUSY')
                  // setStateIndex(1)
                } else if (item.key === '3') {
                  setStateType('OFF')
                  // setStateIndex(2)
                } else {
                  setStateIndex(0)
                  setStateType(undefined)
                  updateUserState('active')
                }
              }}
              listType={'line'}
              divide
              containerStyle={{ height: 32, borderWidth: 0, borderRadius: 4 }}
              isItemIconCheck={false}
            />
          </View>
        </View>
      </View>
      {stateType !== undefined && (
        <WorkSettingStateView
          visible={true}
          type={stateType}
          busyApplyStartTimestamp={data.busyApplyStartTimestamp}
          busyApplyEndTimestamp={data.busyApplyEndTimestamp}
          offApplyStartTimestamp={data.offApplyStartTimestamp}
          offApplyEndTimestamp={data.offApplyEndTimestamp}
          onChangeDate={(startDate, endDate) => {
            const userState: UserState = stateType === 'BUSY' ? 'busy' : 'off'
            updateUserState(userState, {
              startDate,
              endDate,
            })
            // setStateIndex(stateType === 'BUSY' ? 1 : 2)
            // setStateType(undefined)
          }}
          onClose={() => {
            setStateType(undefined)
          }}
        />
      )}
    </View>
  )
}

export default Mobile

const styles = StyleSheet.create({
  containerMobile: {
    borderRadius: 12,
    backgroundColor: COLOR.gray.g050,
    paddingHorizontal: 24,
    paddingVertical: 16,
  },
  innerContainerMobile: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  profile: {
    marginBottom: 8,
    justifyContent: 'center',
  },
  userInfo: {
    marginBottom: 12,
  },
  userName: {
    ...FONT.H7Medium,
  },
  userEmail: {
    ...FONT.txtSm,
    color: COLOR.gray.g600,
  },
})
