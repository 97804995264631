import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'

import type { IColors } from '@rui/foundations'
import { COLOR, FONT, getRealColor } from '@rui/foundations'

interface Props {
  name: string
  color: IColors
}

const TableorderMenuLabelItem: React.FC<Props> = ({ name, color }) => {
  return (
    <View style={[styles.labelContainer, { backgroundColor: getRealColor(color) }]}>
      <Text style={styles.labelText}>{name}</Text>
    </View>
  )
}

export default TableorderMenuLabelItem

const styles = StyleSheet.create({
  labelContainer: {
    borderRadius: 4,
    paddingHorizontal: 4,
    paddingVertical: 2,
    marginRight: 4,
    backgroundColor: COLOR.sub.lightBlue,
  },
  labelText: {
    ...FONT.txt2Xs,
    color: COLOR.mono.white,
  },
})
