import * as React from 'react'
import { Pressable } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'

interface Props {
  name: string
  selected: boolean
  onPress?: () => void
}

export const TableorderMenuGroupItem: React.FC<Props> = ({ name, selected, onPress }) => {
  return (
    <View style={{ marginBottom: 12 }}>
      <Pressable
        style={{
          height: 44,
          justifyContent: 'center',
          paddingHorizontal: 12,
          borderWidth: 1,
          borderColor: selected ? COLOR.main.blue : COLOR.mono.gray,
          borderRadius: 4,
        }}
        onPress={onPress}
      >
        <Text fontName="textBold" fontColor="mono.black">
          {name}
        </Text>
      </Pressable>
      {selected && (
        <View
          style={{
            flex: 1,
            minHeight: 100,
            marginTop: 8,
            backgroundColor: COLOR.gray.g050,
          }}
        >
          <Text>옵션이 적용된 메뉴</Text>
          <Text>TODO: 메뉴명, 메뉴명</Text>
        </View>
      )}
    </View>
  )
}
