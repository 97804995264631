import { View } from '@rocket-mono/foundations'
import type { TableorderMenu } from '@rocket/types'
import { COLOR } from '@rui/foundations'
import * as React from 'react'
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import { useTableorderMenu } from '../../providers'
import { useMenu } from '../hooks/useMenu'
import { TableorderMenuCategory } from './TableorderMenuCategory'
import { TableorderMenuCategoryMenuItem } from './TableorderMenuCategoryMenuItem'

interface Props {
  categoryId: string
  name: string
  menuList: TableorderMenu[]
  onPressItem?: () => void
  onPressUpdate?: (name: string) => void
  onPressDelete?: () => Promise<void>
  dragHandleProps?: DraggableProvidedDragHandleProps | null
}

export const TableorderMenuCategoryItem: React.FC<Props> = ({
  categoryId,
  name,
  menuList,
  onPressUpdate,
  onPressDelete,
  dragHandleProps,
}) => {
  const { changeMenuList } = useTableorderMenu()
  const { onPressMenuDetail } = useMenu()

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      if (result.destination) {
        changeMenuList(categoryId, result.source.index, result.destination.index)
      }
    },
    [categoryId],
  )

  const handlePressAddMenu = React.useCallback(() => {
    onPressMenuDetail && onPressMenuDetail(categoryId)
  }, [])

  return (
    <View style={{ marginBottom: 8, backgroundColor: COLOR.mono.white }}>
      <TableorderMenuCategory
        name={name}
        menuLength={menuList.length}
        onChangeName={onPressUpdate}
        onPressAddMenu={handlePressAddMenu}
        onPressDelete={onPressDelete}
        dragHandleProps={dragHandleProps}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-menu">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {menuList.length > 0 &&
                  menuList.map((item, index) => (
                    <Draggable key={`drag-menu-${index}`} draggableId={`drag-menu-${index}`} index={index}>
                      {(provided) => {
                        return (
                          <div style={{ height: 46 }} ref={provided.innerRef} {...provided.draggableProps}>
                            <TableorderMenuCategoryMenuItem
                              item={item}
                              dragHandleProps={provided.dragHandleProps}
                              onPressMenuDetail={onPressMenuDetail}
                            />
                          </div>
                        )
                      }}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </TableorderMenuCategory>
    </View>
  )
}
