import type { WorkModelFormType } from '@rocket-mono/providers'
import { WorkModelProvider } from '@rocket-mono/providers'
import * as React from 'react'

import { ModalBottom } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { TableorderShop } from '@rocket/types'
import { StyleSheet, View } from 'react-native'
import Context from './context'
import type { ModalBottomAlertType, ProviderProps } from './types'

export const Provider = ({ id, astro, skeletonComponent, children }: ProviderProps) => {
  const [isArchive, setIsArchive] = React.useState<boolean>()
  const [initialValues, setInitialValues] = React.useState<WorkModelFormType>()
  const [shopData, setShopData] = React.useState<TableorderShop>()
  const [modalBottomAlert, setModalBottomAlert] = React.useState<ModalBottomAlertType>()

  React.useEffect(() => {
    // setInitialValues({
    //   id: '1',
    //   title: 'test',
    //   goal: '',
    //   projectElements: [],
    //   rangeDate: {},
    //   typeCode: 'CSP',
    //   etc: '',
    // })
    if (id) {
      astro
        .readShopByProjectId(id)
        .then(setShopData)
        .catch((err) => console.error(err))
      astro
        .readProject(id)
        .then(({ id, title, goal, type, etc, members, isArchive }) => {
          setIsArchive(isArchive)
          return {
            id,
            title,
            goal,
            etc,
            typeCode: type.code,
            ownerUserId: members.find((o) => o.auth === 'OWNER')?.userId,
          }
        })
        .then((values) =>
          astro
            .readProjectPeriod(id)
            .then((period) => ({
              ...values,
              rangeDate: {
                startDate: period.startDate || undefined,
                endDate: period.dueDate || undefined,
              },
            }))
            .catch((err) => {
              console.error(`astro.readProjectPeriod(${id})`, err)
              return { ...values, rangeDate: {} }
            }),
        )
        .then((values) =>
          astro.readChannelList({ type: 'G', projectId: id }).then((channelList) =>
            astro
              .readProjectElements(id)
              .then((list) => {
                return Promise.all(
                  list.map((element) => {
                    const channel = channelList.find((o) => o.projectElementId === element.id)
                    console.log('readProjectElements- channel', channelList, channel)
                    if (channel) {
                      return astro
                        .readChannelMessageCount(channel.roomId)
                        .then((count) =>
                          count > 1
                            ? {
                                ...element,
                                isDelete: false,
                                closed: channel.closed,
                              }
                            : {
                                ...element,
                                isDelete: true,
                                closed: channel.closed,
                              },
                        )
                        .catch(() => ({
                          ...element,
                          isDelete: true,
                          closed: channel.closed,
                        }))
                    } else {
                      return { ...element, isDelete: true, closed: false }
                    }
                  }),
                )
              })
              .then((list) => list.filter(({ closed }) => !closed).sort((a, b) => (a.order || 0) - (b.order || 0)))
              .then((elements) => {
                return values.typeCode === 'WFP'
                  ? {
                      startElement: elements.shift(),
                      endElement: elements.pop(),
                      projectElements: elements,
                    }
                  : { projectElements: elements }
              })
              .then(({ projectElements, startElement, endElement }) => ({
                ...values,
                projectElements,
                startElement,
                endElement,
              })),
          ),
        )
        .then(setInitialValues)
    }
  }, [id])

  console.log('WorkmodelUpdateView', { initialValues, isArchive })

  return (
    <Context.Provider value={{ initialValues }}>
      {typeof initialValues === 'undefined' ? (
        skeletonComponent ? (
          <>{skeletonComponent}</>
        ) : (
          <></>
        )
      ) : isArchive !== undefined ? (
        <WorkModelProvider
          isArchive={isArchive}
          initialValues={initialValues}
          shopData={shopData}
          astro={astro}
          setModalBottomAlert={setModalBottomAlert}
        >
          {children}
        </WorkModelProvider>
      ) : (
        <></>
      )}
      <ModalBottom
        title={modalBottomAlert ? modalBottomAlert.message : ''}
        list={modalBottomAlert ? modalBottomAlert.list : []}
        cancelText={modalBottomAlert ? modalBottomAlert.cancelText : ''}
        visible={!!modalBottomAlert}
        onCancel={() => setModalBottomAlert(undefined)}
      />
      {modalBottomAlert ? <View style={styles.dimmedCover} /> : <></>}
    </Context.Provider>
  )
}

const styles = StyleSheet.create({
  dimmedCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: COLOR.dimmed.a40,
  },
})
