import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet } from 'react-native'
import { Button } from '../../Button'
import CartItem from '../../CartItem'
import { getNumberWithComma } from '../../utils'

import { TableorderTable, type TableorderOrderBasketItem, type TableorderOrderItemPayload } from '@rocket/types'
import { useTableorderCatalog, useTableorderOrder, useTableorderTable } from '../../providers'

interface Props {
  shopId: string
  tableId: string
  onPaymentSuccess: (orderId: string) => void
}

const TableorderOrderAdditionalBasketView: React.FC<Props> = ({ shopId, tableId, onPaymentSuccess }) => {
  const { catalogMenuList, catalogOptionList } = useTableorderCatalog()
  const { readTable } = useTableorderTable()

  const [currentTable, setCurrentTable] = React.useState<TableorderTable>()

  React.useEffect(() => {
    readTable(tableId).then(setCurrentTable)
  }, [tableId])

  // const currentTable = React.useMemo(
  //   () => tableList.find(({ id }) => id === tableId),
  //   [tableList, tableId],
  // )

  // const { menuOptionGroupList } = useTableorderMenuOption()
  const {
    createOrder,
    basket,
    basketItemList,
    createBasket,
    increaseBasketItem,
    decreaseBasketItem,
    deleteBasketItem,
  } = useTableorderOrder()

  const totalPrice = React.useMemo(
    () =>
      basketItemList
        .map(({ selectedCatalogOptionIds, quantity, catalogMenuId }) => {
          const menu = catalogMenuList.find(({ id }) => id === catalogMenuId)

          // const optionList = menuOptionGroupList
          //   .flatMap(({ options }) => options)
          //   .filter(({ id }) => selectedCatalogOptionIds.includes(id))

          const optionPrice = catalogOptionList
            .filter(({ id }) => selectedCatalogOptionIds.includes(id))
            .reduce((value, cur) => value + cur.price, 0)
          const menuPrice = menu?.price || 0
          const onePrice = menuPrice + optionPrice
          return onePrice * quantity
        })
        .reduce((prev, cur) => prev + cur, 0),
    [basketItemList, catalogMenuList, catalogOptionList],
  )

  const renderItem = React.useCallback(
    ({ item }: ListRenderItemInfo<TableorderOrderBasketItem>) => {
      const { id, quantity, catalogMenuId } = item
      const menu = catalogMenuList.find(({ id }) => id === catalogMenuId)

      const optionPrice = catalogOptionList
        .filter(({ originId: id }) => item.selectedCatalogOptionOriginIds.includes(id))
        .reduce((value, cur) => value + cur.price, 0)

      const menuPrice = menu?.price || 0
      const onePrice = menuPrice + optionPrice
      const totalPrice = onePrice * quantity
      const option = catalogOptionList
        .filter(({ originId: id }) => item.selectedCatalogOptionOriginIds.includes(id))
        .map(({ name, price }) => ({
          name,
          price: getNumberWithComma(price),
        }))

      const cartItem = {
        quantity,
        onePrice: getNumberWithComma(onePrice),
        status: '1',
        title: menu?.name || '',
        totalPrice: getNumberWithComma(totalPrice),
        option,
      }

      return (
        <View style={{ marginRight: 8 }}>
          <CartItem
            item={cartItem}
            increaseQuantity={() => increaseBasketItem(id)}
            decreaseQuantity={() => decreaseBasketItem(id)}
            onDelete={() => deleteBasketItem(id)}
          />
        </View>
      )
    },
    [catalogMenuList, catalogOptionList, increaseBasketItem, decreaseBasketItem, deleteBasketItem],
  )

  const keyExtractor = React.useCallback((item: TableorderOrderBasketItem) => `${item.id}`, [])

  const handlePressPayment = React.useCallback(() => {
    if (currentTable && basket && basketItemList.length > 0) {
      const { id: tableId, activeNumber: tableActiveNumber } = currentTable
      const { id: basketId } = basket
      const items: TableorderOrderItemPayload[] = basketItemList.map(
        ({ catalogCategoryId, catalogMenuId, quantity, selectedCatalogOptionIds: catalogSelectedOptionIds }) => ({
          catalogCategoryId,
          catalogMenuId,
          catalogSelectedOptionIds,
          quantity,
        }),
      )

      return createOrder({
        shopId,
        tableId,
        basketId,
        tableActiveNumber,
        customerRequest: '',
        paymentMethod: 'CASH',
        paymentWhen: 'POST',
        price: totalPrice,
        status: 'DONE',
        items,
      }).then(onPaymentSuccess)
    }

    return Promise.reject()
  }, [shopId, currentTable, basket, basketItemList, totalPrice])

  React.useEffect(() => {
    if (basket === undefined) {
      createBasket({
        shopId,
        status: 'EMPTY',
        tableId,
        tableActiveNumber: 1,
      })
    }
  }, [basket])

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: 'row',
          height: 40,
          alignItems: 'center',
          paddingHorizontal: 16,
        }}
      >
        <XEIcon name="cart-o" size={20} />
        <Text fontName="textMedium" fontColor="mono.black">
          장바구니
        </Text>
        <Text fontName="textMedium" fontColor="main.red">
          {basketItemList.length}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <FlatList
          style={{ paddingHorizontal: 16 }}
          data={basketItemList}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
        />
      </View>
      <View
        style={{
          height: 60,
          paddingHorizontal: 16,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Text fontName="textMedium" fontColor="mono.black">
          총 <Text fontColor="main.blue">{basketItemList.length}</Text> 개
        </Text>
        <Text fontName="titleBold" fontColor="mono.black">
          {getNumberWithComma(totalPrice)}원
        </Text>
      </View>
      <View>
        <Button wide style={{ borderRadius: 0 }} backgroundColor="main.navy" size="xlarge" onPress={handlePressPayment}>
          <Text fontName="subTitleBold" fontColor="mono.white">
            추가주문하기
          </Text>
        </Button>
      </View>
    </View>
  )
}

export default TableorderOrderAdditionalBasketView

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
