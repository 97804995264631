import type {
  TableorderMenu,
  TableorderMenuCategory,
  TableorderMenuCategoryUpdatePayload,
  TableorderMenuCreatePayload,
  TableorderMenuUpdatePayload,
} from '@rocket/types'
import * as React from 'react'

import { useModalDialog } from '@rocket-mono/providers'
import { useToast } from '@rui/atoms'
import { changeItemAtIndex, removeItemAtIndex, replaceItemAtIndex } from '@rui/foundations'
import { TableorderMenuLabelProvider } from '../MenuLabelProvider'
import { TableorderMenuOptionProvider } from '../MenuOptionProvider'
import { useTableorderShop } from '../ShopProvider'
import Context from './context'
import type { MenuFormType, ProviderProps } from './types'

const Provider = ({ astro, children }: ProviderProps) => {
  const { shopId } = useTableorderShop()
  const [menuForm, setMenuForm] = React.useState<MenuFormType>()

  const [categoryList, setCategoryList] = React.useState<TableorderMenuCategory[]>()
  const [menuList, setMenuList] = React.useState<TableorderMenu[]>()

  const { show: showToastMessage } = useToast()
  const { showDialogMessage } = useModalDialog()

  const newMenuDetail = React.useCallback(
    (categoryId: string) => {
      setMenuForm({
        categoryId,
        sequenceNumber: menuList?.length || 0,
        name: '',
        description: '',
        labelIds: [],
        optionGroupIds: [],
        isDisplayed: true,
        isSoldout: false,
        images: [],
      })
    },
    [menuList],
  )
  const showMenuDetail = React.useCallback((menu: TableorderMenu) => {
    console.log('showMenuDetail', menu)
    setMenuForm(menu)
  }, [])
  const hideMenuDetail = React.useCallback(() => {
    setMenuForm(undefined)
  }, [])

  const changeCategoryList = React.useCallback(
    async (from: number, to: number) => {
      if (categoryList !== undefined) {
        const list = changeItemAtIndex(categoryList, from, to)
        setCategoryList(list)
        let sequenceNumber = 0
        for (const { id, name } of list) {
          await astro.updateMenuCategory(id, {
            id,
            shopId,
            name,
            sequenceNumber,
          })
          sequenceNumber++
        }
        fetchCategoryList()
      }
    },
    [shopId, categoryList],
  )

  const createCategory = React.useCallback(() => {
    astro
      .createMenuCategory({
        name: '',
        shopId,
        sequenceNumber: categoryList?.length || 0,
      })
      .then((category) =>
        setCategoryList((prev) => {
          if (prev === undefined) return [category]
          return [...prev, category]
        }),
      )
      .catch((err) => {
        console.error(err)
      })
  }, [astro, categoryList])

  const updateCategory = React.useCallback(
    (id: string, payload: TableorderMenuCategoryUpdatePayload) => {
      astro
        .updateMenuCategory(id, payload)
        .then((category) =>
          setCategoryList((prev) => {
            if (prev === undefined) return [category]
            const idx = prev.findIndex((o) => o.id === category.id)
            return idx < 0 ? [...prev, category] : replaceItemAtIndex(prev, idx, category)
          }),
        )
        .catch((err) => {
          console.error(err)
        })
    },
    [astro],
  )

  const deleteCategory = React.useCallback(
    (id: string) => {
      astro
        .deleteMenuCategory(id)
        .then(({ id }) =>
          setCategoryList((prev) => {
            if (prev === undefined) return undefined
            const idx = prev.findIndex((o) => o.id === id)
            return idx < 0 ? prev : removeItemAtIndex(prev, idx)
          }),
        )
        .catch((err) => {
          console.error(err)
        })
    },
    [astro],
  )

  const createMenu = React.useCallback(
    (payload: TableorderMenuCreatePayload) =>
      astro
        .createMenu(payload)
        .then((menu) => {
          setMenuList((prev) => {
            if (prev === undefined) return [menu]
            const idx = prev.findIndex(({ id }) => id === menu.id)
            return idx < 0 ? [...prev, menu] : replaceItemAtIndex(prev, idx, menu)
          })
          return menu
        })
        .then((menu) => {
          showToastMessage({
            type: 'Success',
            title: '저장되었습니다.',
            position: 'TOP_RIGHT',
          })
          return menu
        })
        .then((menu) => {
          const list = [
            {
              name: '메뉴 추가하기',
              action: () => {
                hideMenuDetail()
                setTimeout(() => newMenuDetail(payload.categoryId), 500)
                showToastMessage({
                  type: 'Success',
                  title: '계속해서 메뉴를 추가합니다.',
                  position: 'TOP_RIGHT',
                })
              },
            },
          ]
          showDialogMessage({
            title: '메뉴가 추가되었습니다.',
            message: '계속해서 메뉴를 추가하시겠습니까?',
            list,
            cancelText: '메뉴화면으로 돌아가기',
            onCancel: () => {
              hideMenuDetail()
              showToastMessage({
                type: 'Success',
                title: '메뉴를 추가하였습니다.',
                position: 'TOP_RIGHT',
              })
            },
          })
          return menu
        }),
    [astro, showDialogMessage, showToastMessage],
  )
  const updateMenu = React.useCallback(
    (id: string, payload: TableorderMenuUpdatePayload) =>
      astro
        .updateMenu(id, payload)
        .then((menu) => {
          setMenuList((prev) => {
            if (prev === undefined) return [menu]
            const idx = prev.findIndex(({ id }) => id === menu.id)
            return idx < 0 ? [...prev, menu] : replaceItemAtIndex(prev, idx, menu)
          })
          return menu
        })
        .then((menu) => {
          showToastMessage({
            type: 'Success',
            title: '저장되었습니다.',
            position: 'TOP_RIGHT',
          })
          return menu
        }),
    [astro],
  )
  const deleteMenu = React.useCallback(
    (id: string) => {
      const list = [
        {
          name: '삭제하기',
          action: () => {
            astro
              .deleteMenu(id)
              .then((menu) => {
                setMenuList((prev) => {
                  if (prev === undefined) return [menu]
                  const idx = prev.findIndex(({ id }) => id === menu.id)
                  return idx < 0 ? prev : removeItemAtIndex(prev, idx)
                })
              })
              .then(hideMenuDetail)
              .catch((err) => {
                console.error(err)
              })
          },
        },
      ]
      showDialogMessage({
        title: '메뉴를 삭제하시겠습니까?',
        message: '삭제한 이후 메뉴판을 출판하셔야 삭제 내역이 반영됩니다.',
        list,
        cancelText: '화면으로 돌아가기',
        onCancel: () => {
          showToastMessage({
            type: 'Success',
            title: '메뉴를 삭제하였습니다.',
            position: 'TOP_RIGHT',
          })
        },
      })
    },
    [astro, showDialogMessage],
  )

  const changeMenuList = React.useCallback((categoryId: string, from: number, to: number) => {
    setMenuList((prev) => {
      if (prev === undefined) return undefined
      const prevList = prev.filter((o) => o.categoryId !== categoryId)
      const changeList = prev.filter((o) => o.categoryId === categoryId)
      return [...prevList, ...changeItemAtIndex(changeList, from, to)]
    })
  }, [])

  const fetchCategoryList = React.useCallback(() => {
    astro
      .readMenuCategoryList(shopId)
      .then((list) => {
        if (list.length === 0)
          return astro
            .createMenuCategory({
              name: '',
              shopId,
              sequenceNumber: 0,
            })
            .then((category) => [category])
        return list
      })
      .then((list) => list.filter(({ isDeleted }) => !isDeleted))
      .then((list) => list.sort((a, b) => a.sequenceNumber - b.sequenceNumber))
      .then(setCategoryList)
      .catch((err) => {
        console.error('fetchCategoryList', err)
      })
  }, [shopId])

  React.useEffect(() => {
    fetchCategoryList()
  }, [shopId])

  React.useEffect(() => {
    if (categoryList !== undefined)
      Promise.all(
        categoryList.map(({ id }) => astro.readMenuList(id).then((list) => list.filter(({ isDeleted }) => !isDeleted))),
      )
        .then((data) => data.flat())
        .then(setMenuList)
        .catch((err) => {
          console.error(err)
        })
  }, [categoryList])
  return (
    <Context.Provider
      value={{
        menuForm,
        categoryList,
        menuList,
        changeCategoryList,
        createCategory,
        updateCategory,
        deleteCategory,
        createMenu,
        updateMenu,
        deleteMenu,
        changeMenuList,
        newMenuDetail,
        showMenuDetail,
        hideMenuDetail,
      }}
    >
      <TableorderMenuLabelProvider astro={astro}>
        <TableorderMenuOptionProvider astro={astro}>{children}</TableorderMenuOptionProvider>
      </TableorderMenuLabelProvider>
    </Context.Provider>
  )
}

export default Provider
