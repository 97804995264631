import { PopListSectionTabs, SelectList } from '@rocket-molecules/popselector'
import { View } from '@rocket-mono/foundations'
import { Button, Text } from '@rui/atoms'
import { COLOR, getBoxShadow, removeItemAtIndex } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Pressable, View as RNView, StyleSheet } from 'react-native'
import { ListFooter } from './Dropdown'
import { cellStyles } from './WorkMembers'

interface Props {
  onlyRead?: boolean
  select: string[]
  board: { id: number; title: string; code: string; isArchive: boolean }[]
  onPressEdit?: (selected: string[]) => void
  maxWidth?: number
  setCurrentRow: (is: boolean) => void
}
const WorkMembersTableDropdown = ({ onlyRead = false, board, select, onPressEdit, maxWidth, setCurrentRow }: Props) => {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState<string[]>(select)
  // const [valueChange, setValueChange] = React.useState(false)

  const dimensions = Dimensions.get('window')
  const cellRef = React.useRef<RNView>(null)
  const popoverRef = React.useRef<any>(null)
  const [cellEdit, setCellEdit] = React.useState(false)
  const [dropdownMeasure, setDropdownMeasure] = React.useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    pageX: 0,
    pageY: 0,
  })

  useEffect(() => {
    setCurrentRow(cellEdit)
  }, [cellEdit])

  const valueChange = useMemo(
    () =>
      select.filter((o) => !selected.includes(o)).length > 0 || selected.filter((o) => !select.includes(o)).length > 0,
    [select, selected],
  )

  React.useEffect(() => {
    function handleClickOutside(e: MouseEvent): void {
      if (popoverRef.current && !popoverRef.current.contains(e.target as Node)) {
        setCellEdit(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popoverRef])

  const changeDropdownMeasure = React.useCallback(() => {
    cellRef.current?.measure((x: number, y: number, width: number, height: number, pageX: number, pageY: number) => {
      if (dimensions.height / 2 > pageY) setDropdownMeasure({ x, y, width, height, pageX, pageY })
      else {
        setDropdownMeasure({
          x,
          y,
          width,
          height: -(dimensions.height / 4) - 50,
          pageX,
          pageY,
        })
      }
    })
  }, [cellRef, dimensions])

  const unArchiveBoardList = useMemo(() => {
    return board.filter(({ isArchive }) => !isArchive)
  }, [board])

  const archiveBoardList = useMemo(() => {
    return board.filter(({ isArchive }) => isArchive)
  }, [board])

  const unArchiveAllChecked = useMemo(() => {
    if (unArchiveBoardList.length === 0) return false
    const selectedCount = unArchiveBoardList.filter(({ code }) => selected.includes(code)).length
    return selectedCount === unArchiveBoardList.length
  }, [unArchiveBoardList, selected])

  const archiveAllChecked = useMemo(() => {
    if (archiveBoardList.length === 0) return false
    const selectedCount = archiveBoardList.filter(({ code }) => selected.includes(code)).length
    return selectedCount === archiveBoardList.length
  }, [archiveBoardList, selected])

  const FooterButtonArea = () => {
    return (
      <ListFooter>
        <View style={{ padding: 8, backgroundColor: COLOR.gray.g100 }}>
          <Button
            // wide={true}
            // backgroundColor={valueChange ? 'primary.blue500' : 'mono.white'}
            // borderColor={valueChange ? 'primary.blue500' : 'gray.g300'}
            disabled={valueChange ? false : true}
            onPress={() => {
              setCellEdit(false)
              onPressEdit && onPressEdit(selected)
            }}
            style={{
              width: '100%',
              backgroundColor: valueChange ? COLOR.primary.blue500 : COLOR.gray.g300,
              borderColor: valueChange ? COLOR.primary.blue500 : COLOR.gray.g300,
            }}
            text={
              <Text style={[valueChange ? { color: COLOR.mono.white } : { color: COLOR.gray.g450 }]}>
                {t('workmember.table.board.button')}
              </Text>
            }
          />
        </View>
      </ListFooter>
    )
  }

  const handlePressItem = useCallback((code: string) => {
    console.log('handlePressItem', code)
    setSelected((prev) => {
      const idx = prev.findIndex((o) => o === code)
      return idx < 0 ? [...prev, code] : removeItemAtIndex(prev, idx)
    })
  }, [])

  useEffect(() => {
    setSelected(select)
  }, [select])

  return (
    <>
      <Pressable
        ref={cellRef}
        onPress={() => {
          !onlyRead && setCellEdit(!cellEdit)
          changeDropdownMeasure()
        }}
        style={cellStyles.cells}
      >
        <View style={[cellStyles.flexBox, styles.cellBox, cellEdit && { backgroundColor: COLOR.gray.g050 }]}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            {select.length === 0 ? (
              <>
                <XEIcon name={'info'} size={12} color={'primary.blue500'} style={{ marginRight: 4 }} />
                <Text fontName="txtXs" fontColor={'primary.blue500'}>
                  {t('workmember.table.board.noboard')}
                </Text>
              </>
            ) : (
              <>
                <XEIcon name={'tune'} size={12} style={{ marginRight: 4 }} />
                <Text fontName="txtXs">
                  {t('workmember.table.board.counting', {
                    count: select.length,
                  })}
                </Text>
              </>
            )}
          </View>
          {!onlyRead && (
            <Pressable
              onPress={() => {
                setCellEdit(!cellEdit)
                changeDropdownMeasure()
              }}
            >
              <XEIcon
                name={cellEdit ? 'angle-up' : 'angle-down'}
                size={12}
                color={'gray.g300'}
                style={{ marginTop: -2 }}
              />
            </Pressable>
          )}
        </View>
      </Pressable>
      {cellEdit && (
        <View
          ref={popoverRef}
          style={[
            styles.popover,
            {
              minWidth: dropdownMeasure.width + 8,
              top: dropdownMeasure.height - 4,
              left: -4,
              maxWidth,
              // dimensions.height -
              // dropdownMeasure.height -
              // dropdownMeasure.pageY -
              // 50,

              paddingBottom: 0,
            },
          ]}
        >
          <View style={{ overflow: 'scroll', maxHeight: dimensions.height / 4 }}>
            <PopListSectionTabs
              label={t('workmember.table.board.process')}
              itemCount={board.filter(({ isArchive }) => !isArchive).length}
              countTextColor={'main.red'}
              allChecked={unArchiveAllChecked}
              onPressAllCheck={() => {
                const boardCodeList = unArchiveBoardList.map(({ code }) => code)
                const checkedSelectedBoard = boardCodeList.filter((code) => selected.includes(code))

                if (checkedSelectedBoard.length === unArchiveBoardList.length) {
                  const newList = selected.filter((code) => !boardCodeList.includes(code))
                  setSelected(newList)
                } else {
                  setSelected((prev) => [...prev, ...boardCodeList])
                }
              }}
            >
              <SelectList
                initialSelectedIndexes={unArchiveBoardList
                  .map(({ code }, index) => ({ code, index }))
                  .filter(({ code }) => selected.includes(code))
                  .map(({ index }) => index)}
                onPressItem={(index) => handlePressItem(unArchiveBoardList[index].code)}
                isCheckbox
              >
                {unArchiveBoardList.map(({ code, title }) => (
                  <SelectList.Item key={code} selected={selected.includes(code)} text={title} />
                ))}
              </SelectList>
            </PopListSectionTabs>

            <PopListSectionTabs
              label={t('workmember.table.board.archive')}
              itemCount={board.filter(({ isArchive }) => isArchive).length}
              countTextColor={'gray.g450'}
              divStyle
              allChecked={archiveAllChecked}
              onPressAllCheck={() => {
                const boardCodeList = archiveBoardList.map(({ code }) => code)
                const checkedSelectedBoard = archiveBoardList.filter(({ code }) => selected.includes(code))

                if (checkedSelectedBoard.length === archiveBoardList.length) {
                  const newList = selected.filter((code) => !boardCodeList.includes(code))
                  setSelected(newList)
                } else {
                  setSelected((prev) => [...prev, ...boardCodeList])
                }
              }}
            >
              <SelectList
                initialSelectedIndexes={archiveBoardList
                  .map(({ code }, index) => ({ code, index }))
                  .filter(({ code }) => selected.includes(code))
                  .map(({ index }) => index)}
                selectedBackground={'gray.g300'}
                isCheckbox
                // onPressItem={(index) => handlePressItem(archiveBoardList[index].code)}
              >
                {archiveBoardList.map(({ code, title }) => (
                  <SelectList.Item key={code} selected={selected.includes(code)} text={title} />
                ))}
              </SelectList>
            </PopListSectionTabs>
          </View>
          {FooterButtonArea()}
        </View>
      )}
    </>
  )
}

export default WorkMembersTableDropdown

export const styles = StyleSheet.create({
  cellBox: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  popover: {
    position: 'absolute',
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingVertical: 4,
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 0),
    zIndex: 100,
  },
})
