import type { Project } from '@rocket/types'
import * as React from 'react'

import Context from './context'
import type { BoardFilterType, ProviderProps } from './types'

import { debounce } from 'lodash'

const Provider = ({
  projectId,
  memberId,
  astro,
  progressCount,
  doneCount,
  ruleCount,
  onPressWorkEdit,
  onPressRoleEdit,
  children,
}: ProviderProps) => {
  const [isAuth, setIsAuth] = React.useState(false)
  const [project, setProject] = React.useState<Project>()
  const [projectGoal, setProjectGoal] = React.useState<string>('')
  const [isArchive, setIsArchive] = React.useState(false)
  const [filterCode, setFilterCode] = React.useState<BoardFilterType>('PROGRESS')

  const toggleArchive = React.useCallback(() => {
    setIsArchive((is) => !is)
  }, [])

  const fetchUpdateProjectGoal = React.useCallback(
    debounce((goal: string, project?: Project) => {
      if (project) {
        const { id, title, type, etc } = project
        const typeCode = type.code
        astro.updateProject({ id, goal, title, etc, typeCode }).then(setProject)
      }
    }, 500),
    [],
  )

  const updateProjectGoal = React.useCallback(
    (goal: string, project?: Project) => {
      setProjectGoal(goal)
      fetchUpdateProjectGoal(goal, project)
    },
    [fetchUpdateProjectGoal],
  )

  const onChangeFilterCode = React.useCallback((code: string) => {
    setFilterCode(code as BoardFilterType)
  }, [])

  React.useEffect(() => {
    if (projectId) {
      astro.readProject(projectId).then((project) => {
        setProject(project)
        setProjectGoal(project.goal || '')
      })
      if (memberId)
        astro
          .readProjectMember(projectId, memberId)
          .then((member) => {
            if (member.auth === 'MANAGER' || member.auth === 'OWNER') {
              setIsAuth(true)
            }
          })
          .catch((e) => console.error('readProjectMember', e))
    }
  }, [astro, projectId, memberId])
  return (
    <Context.Provider
      value={{
        isAuth,
        project,
        projectGoal,
        filterCode,
        progressCount,
        doneCount,
        ruleCount,
        onChangeFilterCode,
        isArchive,
        toggleArchive,
        updateProjectGoal,
        onPressWorkEdit,
        onPressRoleEdit,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
