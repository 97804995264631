import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { StyleSheet } from 'react-native'

import WorkBoardSwitch from '../WorkBoardSwitch'
import WorkGoals from '../WorkGoals'

import { getRealColor } from '@rui/foundations'
import { useTranslation } from 'react-i18next'
import ButtonIconWithTooltip from '../ButtonIconWithTooltip'
import WorkBoardCardFilter from '../WorkBoardCardFilter'
import { useWorkBoard } from './hooks'
import RuleButton from './rule-button'
import type { ViewProps } from './types'

const ScreenView: React.FC<ViewProps> = ({ children }) => {
  const { t } = useTranslation()
  const {
    project,
    isAuth,
    projectGoal,
    updateProjectGoal,
    isArchive,
    toggleArchive,
    onPressWorkEdit,
    onChangeFilterCode,
    filterCode,
    progressCount,
    doneCount,
    onPressRoleEdit,
    ruleCount,
  } = useWorkBoard()

  const RenderButtons = React.useMemo(() => {
    if (project?.type.code === 'WFP') {
      return (
        <View
          style={{
            paddingLeft: 12,
            borderLeftWidth: 1,
            borderLeftColor: getRealColor('gray.g100'),
            flexDirection: 'row',
          }}
        >
          {/* <ButtonSvgIconWithTooltip
            Icon={InfinityIcon}
            iconWidth={16}
            iconHeight={8}
            iconColor="gray.g500"
            backgroundColor="gray.g100"
            tooltipText={t('screen.role.title')}
            tooltipPosition="bottom-center"
            onPress={onPressRoleEdit}
          /> */}
          <RuleButton count={ruleCount} onPress={onPressRoleEdit} />
          {isAuth && (
            <>
              <View style={{ width: 8 }} />
              <ButtonIconWithTooltip
                iconName="cog"
                iconColor="gray.g500"
                backgroundColor="gray.g100"
                tooltipText={t('screen.workmodelupdate.title')}
                tooltipPosition="top"
                onPress={onPressWorkEdit}
              />
            </>
          )}
        </View>
      )
    } else {
      return (
        isAuth && (
          <View
            style={{
              paddingLeft: 12,
              borderLeftWidth: 1,
              borderLeftColor: getRealColor('gray.g100'),
              flexDirection: 'row',
            }}
          >
            <ButtonIconWithTooltip
              iconName="cog"
              iconColor="gray.g500"
              backgroundColor="gray.g100"
              tooltipText={t('screen.workmodelupdate.title')}
              tooltipPosition="top"
              onPress={onPressWorkEdit}
            />
          </View>
        )
      )
    }
  }, [isAuth, onPressRoleEdit, onPressWorkEdit, project, t])

  return (
    <View style={styles.container}>
      <View style={styles.topContainer}>
        <WorkBoardSwitch
          containerStyle={{ marginRight: 4 }}
          activated={isArchive}
          onChange={toggleArchive}
          leftMode="board"
          leftTooltip={t('screen.board.tooltipswitch.progress')}
          rightMode="archive"
          rightTooltip={t('screen.board.tooltipswitch.archive')}
        />
        <View style={{ flex: 1, marginRight: 12 }}>
          <WorkGoals admin={isAuth} text={projectGoal} onSubmit={(text) => updateProjectGoal(text, project)} />
        </View>
        <View style={styles.buttonGroup}>
          <View style={{ marginRight: 12 }}>
            <View>
              <WorkBoardCardFilter
                filterCode={filterCode}
                progressCount={progressCount}
                doneCount={doneCount}
                onChangeFilterCode={onChangeFilterCode}
              />
            </View>
          </View>
          {RenderButtons}
        </View>
      </View>
      <View style={{ flex: 1 }}>{children}</View>
    </View>
  )
}

export default ScreenView

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 12,
  },
  topContainer: {
    height: 36,
    marginHorizontal: 20,
    flexDirection: 'row',
  },
  buttonGroup: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
