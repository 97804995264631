import { Modal, View } from '@rocket-mono/foundations'
import { useModalDialog } from '@rocket-mono/providers'
import { Text, useToast } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Button } from '../Button'

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import BarButton from '../Button/BarButton'
import { useTableorderMenuLabel } from '../providers'
import { TableorderMenuLabelItem } from './components/TableorderMenuLabelItem'

interface Props {
  closeModal: () => void
}

const ModalView: React.FC<Props> = ({ closeModal }) => {
  const [isReady, setIsReady] = React.useState(false)

  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const { menuLabelForm, saveMenuLabel, createMenuLabel, updateMenuLabelName, deleteMenuLabel, changeMenuLabel } =
    useTableorderMenuLabel()

  const handlePressSave = React.useCallback(() => {
    saveMenuLabel().then(() => {
      closeModal()
      setTimeout(
        () =>
          showToastMessage({
            type: 'Success',
            title: '라벨 수정이 완료되었습니다.',
            position: 'BOTTOM_CENTER',
          }),
        300,
      )
    })
  }, [saveMenuLabel])

  const handleUpdateLabelName = React.useCallback(
    (index: number, name: string) => {
      updateMenuLabelName(index, name)
    },
    [updateMenuLabelName],
  )
  const handlePressDelete = React.useCallback(
    (index: number) => {
      const list = [
        {
          name: '삭제',
          action: () => {
            deleteMenuLabel(index)
            showToastMessage({
              type: 'Danger',
              title: '라벨을 삭제하였습니다.',
              position: 'BOTTOM_CENTER',
            })
          },
        },
      ]
      showDialogMessage({
        title: '라벨을 삭제하시겠습니까?',
        message: '삭제하시면 해당 라벨을 적용하고 있는 메뉴의 레발도 같이 삭제됩니다.',
        list,
        cancelText: '취소',
        onCancel: hideDialogMessage,
      })
    },
    [deleteMenuLabel],
  )

  const handlePressAdd = React.useCallback(() => {
    if (menuLabelForm.length < 4) {
      createMenuLabel()
    } else {
      showToastMessage({
        type: 'Danger',
        title: '라벨은 최대 4개까지 추가할 수 있습니다.',
        position: 'BOTTOM_CENTER',
      })
    }
  }, [menuLabelForm, createMenuLabel])

  const onDragEnd = React.useCallback(
    (result: DropResult) => {
      if (result.destination) {
        changeMenuLabel(result.source.index, result.destination.index)
      }
    },
    [changeMenuLabel],
  )

  React.useEffect(() => {
    setIsReady(true)
  }, [])

  return (
    <View
      style={{
        width: 400,
        minHeight: 420,
        backgroundColor: COLOR.mono.white,
        borderRadius: 8,
      }}
    >
      <Modal.Header textAlign="center" onPressClose={closeModal}>
        라벨 관리
      </Modal.Header>
      <Modal.Body>
        <View style={styles.container}>
          <Text fontName="subTextRegular" fontColor="mono.paleBlack">
            라벨은 최대 4개까지 추가할 수 있습니다.
          </Text>
          <Button
            wide
            style={{
              marginTop: 4,
              flexDirection: 'row',
              alignItems: 'center',
            }}
            borderStyle="dashed"
            borderColor="mono.darkGray"
            backgroundColor="mono.white"
            onPress={handlePressAdd}
          >
            <XEIcon name="plus" color="mono.paleBlack" size={14} viewBox="0 -100 1000 1000" />
            <Text fontName="subTextMedium" fontColor="mono.paleBlack">
              {' '}
              라벨 추가
            </Text>
          </Button>
          <View style={{ marginTop: 12 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {isReady &&
                      menuLabelForm.map((item, index) => (
                        <Draggable key={`drag-${index}`} draggableId={`drag-${index}`} index={index}>
                          {(provided) => {
                            return (
                              <div style={{ height: 46 }} ref={provided.innerRef} {...provided.draggableProps}>
                                <TableorderMenuLabelItem
                                  key={item.id}
                                  name={item.name}
                                  color={item.color}
                                  dragHandleProps={provided.dragHandleProps}
                                  onChangeName={(name) => handleUpdateLabelName(index, name)}
                                  onPressDelete={index > 0 ? () => handlePressDelete(index) : undefined}
                                />
                              </div>
                            )
                          }}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </View>
        </View>
      </Modal.Body>
      <Modal.Footer>
        <BarButton
          // disabled={isCreateButtonDisabled}
          contanerStyle={{
            height: 50,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
          }}
          title="라벨 수정"
          action={handlePressSave}
          callToAction
        />
      </Modal.Footer>
    </View>
  )
}

export default ModalView

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.mono.white,
  },
})
