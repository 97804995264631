import React, { useEffect, useRef } from 'react'
import { Animated, Easing, Pressable, Modal as RNModal, StyleSheet, View } from 'react-native'

import { DivLine } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'

import { LayoutTopLogo } from '@rocket/atoms'

import Providers from '../providers'
import ActivityMenu from './components/ActivityMenu'
import LayoutTopAIIcon from './components/LayoutTopAIIcon'
import LayoutTopProfile from './components/LayoutTopProfile'
import LayoutTopWorkList from './components/LayoutTopWorkList'
import { Props } from './types'
import WorkSettingStateView from './views/WorkSettingStateView'

export const LayoutTop: React.FC<Props> = ({
  theme,
  onPressLogo,
  onPressProject,
  currentWork,
  currentWorkId,
  currentWorkType,
  workList,
  activityMenuList,
  code,
  onCode,
  profile,
  onProfileSetting,
  onUpdateUserState,
}) => {
  const [stateType, setStateType] = React.useState<'BUSY' | 'OFF'>()
  const colorAnim = useRef(new Animated.Value(0)).current
  const handleAnimation = () => {
    if (theme === 'dark') {
      Animated.timing(colorAnim, {
        toValue: 1,
        duration: 300,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(colorAnim, {
        toValue: 0,
        duration: 300,
        easing: Easing.linear,
        useNativeDriver: false,
      }).start()
    }
  }

  const colorChange = colorAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [`rgba(255,255,255, 1)`, `rgba(46,47,50, 1)`],
  })

  useEffect(() => {
    handleAnimation()
  }, [theme])

  return (
    <Providers>
      <Animated.View
        style={[styles.container, { backgroundColor: colorChange, borderWidth: theme === 'light' ? 1 : 0 }]}
      >
        <View style={styles.leftArea}>
          <Pressable onPress={onPressLogo}>
            <LayoutTopLogo theme={theme} size={20} />
          </Pressable>
          <DivLine theme={theme} height={18} marginHorizontal={16} />
          <LayoutTopWorkList
            theme={theme}
            workName={currentWork}
            workType={currentWorkType}
            currentWorkId={currentWorkId}
            workList={workList}
            onPressProject={onPressProject}
            isFold={false}
            onPressFold={console.log}
          />
        </View>
        <View style={styles.rightArea}>
          <View style={styles.nav}>
            {activityMenuList.map((item, idx) => (
              <ActivityMenu
                theme={theme}
                code={item.code}
                iconName={item.iconName}
                isUnread={item.isUnread}
                isCount={item.isCount}
                isAcitve={code === item.code}
                key={`headerMenu-${idx}`}
                onPress={() => {
                  onCode && onCode(item.code)
                }}
              />
            ))}
          </View>
          <DivLine theme={theme} height={18} marginHorizontal={16} />
          <View style={{ marginRight: 4 }}>
            <LayoutTopAIIcon active={false} />
          </View>
          {profile ? (
            <LayoutTopProfile
              userName={profile.name}
              userImageUrl={profile.image}
              userState={profile.state}
              updateUserState={(state) => {
                if (state === 'Active') {
                  onUpdateUserState && onUpdateUserState(state)
                } else {
                  setStateType(state === 'Busy' ? 'BUSY' : 'OFF')
                }
              }}
              onProfileSetting={onProfileSetting}
            />
          ) : (
            <></>
          )}
          {stateType && profile ? (
            <RNModal visible={true} transparent>
              <WorkSettingStateView
                visible={true}
                type={stateType}
                busyApplyStartTimestamp={profile.busyApplyStartTimestamp}
                busyApplyEndTimestamp={profile.busyApplyEndTimestamp}
                offApplyStartTimestamp={profile.offApplyStartTimestamp}
                offApplyEndTimestamp={profile.offApplyEndTimestamp}
                onChangeDate={(startDate, endDate) => {
                  console.log('onChangeDate', { startDate, endDate })
                  const userState = stateType === 'BUSY' ? 'Busy' : 'Off'
                  onUpdateUserState && onUpdateUserState(userState, startDate, endDate)
                }}
                onClose={() => {
                  setStateType(undefined)
                }}
              />
              {stateType ? <View style={styles.dimmed} /> : <></>}
            </RNModal>
          ) : (
            <></>
          )}
        </View>
      </Animated.View>
    </Providers>
  )
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 52,
    paddingHorizontal: 16,
    borderColor: COLOR.gray.g150,
  },
  leftArea: {
    flex: 1,
    flexGrow: 3,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 20,
  },
  rightArea: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  nav: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dimmed: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(42, 50, 55, 0.6)',
  },
})
