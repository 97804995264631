import { COLOR, IColors, getBoxShadow, removeItemAtIndex } from '@rocket-mono/libs'
import React from 'react'
import { DimensionValue, Platform, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import DropdownListItem, { DropdownItemProps } from './DropdownListItem'

export interface ItemListTypeProps {
  itemType?: 'DEFAULT' | 'CHECKED' | 'RADIOBOX'
  itemVariant?: 'DEFAULT' | 'FILLED'
  ifFilledBgColor?: IColors
  ifAfterChecked?: boolean
  ifAfterRadio?: boolean
}

interface Props extends ItemListTypeProps {
  list: DropdownItemProps[]
  configuration: 'DEFAULT' | 'DEFAULT-BORDER' | 'ONE' | 'NONE'
  ifRightShow?: boolean
  selected: DropdownItemProps[]
  setSelected: (arg0) => void
  buttonStandard?: { width: DimensionValue; height: number }
  setActived?: (arg0: boolean) => void
  currentIndex?: number | number[]
  onSelect?: (item: DropdownItemProps) => void
  afterOnSelect?: (item: DropdownItemProps) => void
  width?: DimensionValue
  itemStyle?: ViewStyle
  itemTextStyle?: TextStyle
}
const DropdownList: React.FC<Props> = ({
  list,
  configuration,
  ifRightShow,
  itemType,
  itemVariant,
  ifFilledBgColor,
  ifAfterChecked,
  ifAfterRadio,
  selected,
  setSelected,
  buttonStandard = { width: 220, height: 36 },
  setActived,
  currentIndex,
  afterOnSelect,
  onSelect,
  width,
  itemStyle,
  itemTextStyle,
}) => {
  const onPressItem = React.useCallback(
    (item: DropdownItemProps) => {
      if (afterOnSelect) {
        afterOnSelect(item)
      } else {
        if (currentIndex === undefined) {
          itemType === 'CHECKED'
            ? setSelected((prev) => {
                const idx = prev.findIndex((o) => o.code === item.code)
                return idx < 0 ? [...prev, item] : removeItemAtIndex(prev, idx)
              })
            : setSelected([item])

          itemType === 'DEFAULT' && setActived && setActived(false)
        }
        onSelect && onSelect(item)
      }
    },
    [currentIndex, onSelect],
  )

  return (
    <View
      style={[
        styles.wrap,
        styles.listContainer,
        configuration === 'DEFAULT-BORDER' && styles.listDefaultBorderContainer,
        configuration === 'ONE' && styles.listOneContainer,
        { top: buttonStandard.height + 4 },
        configuration === 'ONE' && { top: buttonStandard.height - 1, width: buttonStandard.width },
        ifRightShow && { left: 'auto', right: 0 },
        configuration === 'NONE' && styles.listNoneStyle,
        { width },
        { zIndex: 10 },
      ]}
    >
      {list.map((item, idx) => (
        <DropdownListItem
          key={idx}
          item={item}
          itemType={itemType}
          itemVariant={itemVariant}
          ifFilledBgColor={ifFilledBgColor}
          ifAfterChecked={ifAfterChecked}
          ifAfterRadio={ifAfterRadio}
          selected={selected}
          onPressItem={onPressItem}
          itemStyle={itemStyle}
          itemTextStyle={itemTextStyle}
        />
      ))}
    </View>
  )
}

export default DropdownList

const styles = StyleSheet.create({
  wrap: {
    position: 'relative',
  },
  listContainer: {
    ...(Platform.OS !== 'web' ? { marginTop: -30 } : {}),
    position: 'absolute',
    top: 30,
    left: 0,
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    ...getBoxShadow(0, 0, 0, 0.1, 4, 0, 0, 0),
    paddingVertical: 4,
  },
  listDefaultBorderContainer: {
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
  },
  listOneContainer: {
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  listNoneStyle: {
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    paddingVertical: 0,
    ...getBoxShadow(0, 0, 0, 0, 0, 0, 0, 0),
  },
})
