import * as React from 'react'

import { TableorderCatalog } from '@rocket/types'
import { useTableorderShop } from '../ShopProvider'
import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ astro, children }: ProviderProps) => {
  const { shopId } = useTableorderShop()

  const [currentCatalog, setCurrentCatalog] = React.useState<TableorderCatalog | null>()

  const catalogMenuList = React.useMemo(
    () => currentCatalog?.categories.flatMap(({ menus }) => menus).filter(({ isDisplayed }) => isDisplayed) ?? [],
    [currentCatalog],
  )

  const catalogOptionList = React.useMemo(
    () => currentCatalog?.optionGroups.flatMap(({ options }) => options) ?? [],
    [currentCatalog],
  )

  const fetchCatalog = React.useCallback(() => {
    astro
      .readTableorderCatalog(shopId)
      .then((catalog) => {
        const categories = catalog.categories.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        const labels = catalog.labels.sort((a, b) => a.sequenceNumber - b.sequenceNumber)

        const optionGroups = catalog.optionGroups.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
        return { ...catalog, categories, labels, optionGroups }
      })
      .then(setCurrentCatalog)
      .catch((err) => {
        console.error('astro.readTableorderCatalog', err)
        setCurrentCatalog(null)
      })
  }, [shopId])
  //

  const publishCatalog = React.useCallback(() => {
    return astro
      .reflectLatestCatalog(shopId)
      .then(fetchCatalog)
      .catch((err) => {
        console.error('astro.reflectLatestCatalog', err)
        throw new Error(err)
      })
  }, [])

  React.useEffect(() => {
    fetchCatalog()
  }, [shopId])

  return (
    <Context.Provider
      value={{
        currentCatalog,
        catalogMenuList,
        catalogOptionList,
        publishCatalog,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
