import { SecondaryButton } from '@rocket-mono/foundations'
import { SkeletonBar, SkeletonCircle } from '@rocket/skeleton'
import { Button } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import { NoContentsIcon } from '@rui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Platform, StyleSheet, Text, View, ViewStyle } from 'react-native'
import InviteMember from './InviteMember'
import MemberItem from './Item'
import type { InviteMemberListItem, MemberListItem, MemberPositionOptionsType, MemberStateType } from './types'

interface Props {
  secureCdnUrl: string
  list?: MemberListItem[]
  isAdmin?: boolean
  isOnlyMe?: boolean
  containerStyle?: ViewStyle
  itemContainerStyle?: ViewStyle
  onPress: (item: MemberListItem, idex: number, pageY: number, pageX?: number) => void
  onPressCancel: (item: MemberListItem) => void
  onMemberListResize?: (item: MemberListItem, pageY: number) => void
  currentUserId?: string
  onPressLeave?: (item: MemberListItem, pageY: number, pageX?: number) => void
  onListEndReached?: () => void
}

const MemberList: React.FC<Props> = ({
  secureCdnUrl,
  list,
  isAdmin = false,
  isOnlyMe = false,
  containerStyle,
  itemContainerStyle,
  onPress,
  onPressCancel,
  onMemberListResize,
  currentUserId,
  onPressLeave,
  onListEndReached,
}) => {
  const { t } = useTranslation()

  const normalButtonRef = React.useRef<View>(null)
  const currentUserButtonRef = React.useRef<View>(null)

  const onSkeleton = (item: MemberListItem) => {
    if (typeof item.isSkeleton === 'undefined') {
      return false
    }
    return item.isSkeleton
  }

  if (!list) return <></>

  return (
    <View style={{ flex: 1 }}>
      {list.length > 0 ? (
        <View style={[styles.memberItemContainer, isOnlyMe || isAdmin ? {} : { flex: 1 }, containerStyle]}>
          <FlatList
            data={list}
            keyExtractor={(item) => `member-list-item_${item.id}`}
            renderItem={({ item, index }) => {
              return onSkeleton(item) ? (
                <View style={itemContainerStyle}>
                  <View style={styles.rowCenterContainer}>
                    <View style={styles.imageProfileContainer}>
                      <SkeletonCircle width={40} height={40} />
                    </View>
                    <View style={styles.userInfoStyle}>
                      <View style={[styles.userNameStyle, { flex: 0 }]}>
                        <SkeletonBar width={180} height={14} />
                      </View>
                      <View style={{ marginTop: 4 }}>
                        <SkeletonBar width={115} height={10} />
                      </View>
                    </View>
                  </View>
                </View>
              ) : (
                <View style={itemContainerStyle}>
                  <View style={styles.itemContainer}>
                    <MemberItem secureCdnUrl={secureCdnUrl} item={item} />
                    {Platform.OS !== 'web' ? <View style={styles.viewDummy} /> : <></>}
                    {isAdmin ? (
                      <View style={styles.rightAreaContainer}>
                        <View
                          style={{
                            ...Platform.select({
                              native: { marginTop: -30 },
                            }),
                          }}
                        >
                          {item.invite ? (
                            <Button
                              // backgroundColor="mono.white"
                              // borderColor="gray.g200"
                              // size="small"
                              style={{
                                padding: 4,
                                backgroundColor: COLOR.mono.white,
                                borderColor: COLOR.gray.g200,
                                borderWidth: 1,
                                height: 28,
                                width: 28,
                              }}
                              onPress={() => onPressCancel(item)}
                              text={
                                <Text
                                  style={{
                                    ...FONT.txtXs,
                                    color: COLOR.gray.g700,
                                  }}
                                >
                                  {t('memberlist.cancelInvite')}
                                </Text>
                              }
                            />
                          ) : (
                            <View ref={normalButtonRef}>
                              <SecondaryButton
                                isOpen={item.isOpen}
                                onPress={() => {
                                  normalButtonRef.current?.measure((_x, _y, _width, _height, pageX, pageY) => {
                                    const newItem = {
                                      ...item,
                                      isOpen: !item.isOpen,
                                    }
                                    onPress(newItem, index, pageY - 230, pageX)
                                  })
                                }}
                                title={item.positionLeader ? t('memberlist.labelLeader') : t('memberlist.labelNormal')}
                                onResize={(pageY) => {
                                  onMemberListResize && onMemberListResize(item, pageY)
                                }}
                                isEnable
                              />
                            </View>
                          )}
                        </View>
                      </View>
                    ) : item.userId === currentUserId ? (
                      <View ref={currentUserButtonRef}>
                        <SecondaryButton
                          isOpen={item.isOpen}
                          onPress={() => {
                            // const newItem = {
                            //   ...item,
                            //   isOpen: !item.isOpen,
                            // }
                            // onPress(newItem, index, pageY - 230, pageX)
                            currentUserButtonRef.current?.measure((_x, _y, _width, _height, pageX, pageY) => {
                              onPressLeave?.(item, pageY - 230, pageX)
                            })
                          }}
                          title={item.positionLeader ? t('memberlist.labelLeader') : t('memberlist.labelNormal')}
                          onResize={(pageY) => {
                            onMemberListResize && onMemberListResize(item, pageY)
                          }}
                          isEnable
                        />
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
              )
            }}
            onEndReached={() => {
              onListEndReached && onListEndReached()
            }}
          />
        </View>
      ) : (
        <></>
      )}
      {list.length === 0 || isOnlyMe ? (
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <NoContentsIcon
            type={'member'}
            textSize={'medium'}
            text={t('nocontentsicon.member.text')}
            subText={t('nocontentsicon.member.subtext')}
            iconWidth={115}
          />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export {
  InviteMember,
  InviteMemberListItem,
  MemberItem,
  MemberList,
  MemberListItem,
  MemberPositionOptionsType,
  MemberStateType,
}

const styles = StyleSheet.create({
  memberItemContainer: {
    marginTop: 8,
  },
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
    flex: 1,
  },
  rowCenterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightAreaContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'center',
  },
  // noticeText: {
  //   ...FONT.textRegular,
  //   color: COLOR.mono.darkGray,
  // },
  // button: {
  //   minWidth: 40,
  //   paddingHorizontal: 8,
  //   height: 28,
  //   backgroundColor: COLOR.mono.paleWhite,
  //   borderColor: COLOR.mono.gray,
  // },
  // buttonChecked: {
  //   backgroundColor: COLOR.main.blue,
  //   borderColor: COLOR.main.blue,
  // },
  // buttonText: {
  //   ...FONT.subTextRegular,
  //   fontWeight: '400',
  // },
  // buttonTextChecked: {
  //   color: COLOR.mono.white,
  // },
  // onDropDown: {
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   backgroundColor: COLOR.gray.g050,
  //   paddingHorizontal: 8,
  //   paddingVertical: 4,
  //   borderRadius: 4,
  // },
  viewDummy: {
    width: 16,
  },
  imageProfileContainer: {
    position: 'relative',
    marginVertical: 8,
  },
  userInfoStyle: {
    marginLeft: 8,
    marginRight: 16,
    flex: 1,
  },
  userNameStyle: {
    flexDirection: 'row',
    marginBottom: 1,
    flex: 1,
  },
})
