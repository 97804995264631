import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

interface Props {
  onPressDelete?: () => void
}
const IconDelete = ({ onPressDelete }: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  return (
    <Pressable
      onPress={onPressDelete}
      style={{ padding: 4 }}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      <View style={[styles.removeIcon, isHover && styles.removeIconHover]}>
        <XEIcon name={'close'} size={12} color={'mono.white'} style={{ marginTop: -2 }} />
      </View>
    </Pressable>
  )
}

export default IconDelete

const styles = StyleSheet.create({
  removeIcon: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
    backgroundColor: COLOR.gray.g400,
    borderRadius: 30,
  },
  removeIconHover: {
    backgroundColor: COLOR.main.red,
  },
})
