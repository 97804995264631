import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { StyleSheet } from 'react-native'

export interface ListFooterProps {
  children: JSX.Element[] | JSX.Element
}

export const ListFooter: React.FC<ListFooterProps> = ({ children }) => {
  return <View style={styles.container}>{children}</View>
}

ListFooter.displayName = 'List.Footer'

const styles = StyleSheet.create({
  container: {
    width: '100%',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
})
