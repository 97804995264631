import * as React from 'react'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { bottomAlertMessageState, cardListState, placeholderPropsState, qrLinkState } from '../recoil'

import { Droppable } from 'react-beautiful-dnd-grid-support'

import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { COLOR, IColors } from '@rocket-mono/libs'
import { useAstro, useCurrentUser, useModal, useSubscription, useWorkProject } from '@rocket-mono/providers'
import { FAIcon } from '@rocket/atoms'
import { CardRelationResponse, CardType, Channel, ChannelMember, ListPage, ProjectElement } from '@rocket/types'
import { useToast } from '@rui/atoms'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Pressable } from 'react-native'
import BoardCard from './BoardCard'
import { BoardLayout } from './BoardLayout'
import { WorkBoardMemberView } from './WorkBoardMemberView'
import { useWorkBoard } from './WorkBoardScreen'

interface Props {
  channel: Channel
  projectElement?: ProjectElement
  isFirst?: boolean
  isLast?: boolean
  /** 보드관련 */
  isBoardFirst: boolean
  /** 보드관련 */
  isBoardLast: boolean
  resetChannelList: () => void
  handlePressBoardRole?: () => void
  extraCard: (card: CardRelationResponse) => Promise<CardRelationResponse>
}

const BoardComponent: React.FC<Props> = ({
  channel,
  projectElement,
  isFirst,
  isLast,
  isBoardFirst,
  isBoardLast,
  resetChannelList,
  handlePressBoardRole,
  extraCard,
}) => {
  const { astro, astroNav, option } = useAstro()
  const { t, i18n } = useTranslation()
  const { currentUser } = useCurrentUser()

  const { currentProject, currentProjectMember, currentAutomationList } = useWorkProject()

  const { show: showToastMessage } = useToast()

  const { isArchive: isBoardArchive, filterCode } = useWorkBoard()

  const placeholderProps = useRecoilValue(placeholderPropsState)

  const [isLoading, setIsLoading] = React.useState(true)
  const [element, setElement] = React.useState(projectElement)

  const [cardList, setCardList] = useRecoilState(cardListState(channel.id))

  const [pageInfo, setPageInfo] = React.useState<{ page: number; loading: boolean }>({
    page: 0,
    loading: true,
  })
  const [isPageLast, setIsPageLast] = React.useState(false)

  const [totalCardCount, setTotalCardCount] = React.useState<number>(0)
  const [doneCardCount, setDoneCardCount] = React.useState<number>(0)

  const { Modal, visible, close, open } = useModal('WorkBoardMemberListModal')

  const isArchive = React.useMemo(
    () => (currentProject !== null ? isBoardArchive || currentProject.isArchive : isBoardArchive),
    [isBoardArchive, currentProject],
  )

  console.log('channel', channel, projectElement, isArchive, isBoardArchive)

  const filteredCardList = React.useMemo(
    () =>
      cardList.filter(({ card }) => {
        if (filterCode === 'DONE') {
          return card.isDone === true ? true : false
        }

        if (filterCode === 'PROGRESS') {
          return card.isDone === undefined || card.isDone === false ? true : false
        }
        return true
      }),
    [cardList, filterCode],
  )

  const [memberList, setMemberList] = React.useState<ChannelMember[]>()

  const setBottomAlertMessage = useSetRecoilState(bottomAlertMessageState)

  const currentChannelMember = React.useMemo(() => {
    if (!memberList || !currentUser) return undefined
    return memberList.find((o) => o.userId === String(currentUser.id))
  }, [currentUser, memberList])

  const isDropDisabled = React.useMemo(() => {
    if (isArchive) return true
    if (currentProjectMember && (currentProjectMember.auth === 'MANAGER' || currentProjectMember.auth === 'OWNER')) {
      return false
    }
    if (memberList && currentUser) {
      const member = memberList.find((o) => o.userId === String(currentUser.id))
      if (member) return false
    }
    return true
  }, [isArchive, memberList, currentUser, currentProjectMember])

  const appList = React.useMemo(() => {
    if (isArchive) return undefined
    return [
      {
        cardType: 'MEETING' as 'ATTEND' | 'MEETING' | 'MISSION' | 'COLLECTION' | 'TASKREPORT' | 'TOTAL' | undefined,
        title: t(`system.ameeting`),
        state: 'ATTEND',
      },
      {
        cardType: 'MISSION' as 'ATTEND' | 'MEETING' | 'MISSION' | 'COLLECTION' | 'TASKREPORT' | 'TOTAL' | undefined,
        title: t(`system.atask`),
        state: 'MISSION',
      },
      {
        cardType: 'COLLECTION' as 'ATTEND' | 'MEETING' | 'MISSION' | 'COLLECTION' | 'TASKREPORT' | 'TOTAL' | undefined,
        title: t(`system.asurvey`),
        state: 'COLLECTION',
      },
    ]
  }, [currentProject, currentProjectMember])

  const isCSP = React.useMemo(() => {
    return currentProject && currentProject.type.code === 'CSP'
  }, [currentProject])

  const isGuest = React.useMemo(() => {
    return currentProjectMember && currentProjectMember.auth === 'GUEST'
  }, [currentProjectMember])

  const { moreList, moreList2 } = React.useMemo(() => {
    const moreList: any[] = []
    const moreList2: any[] = []

    if (currentProjectMember === undefined) return { moreList, moreList2 }

    // if (isCSP || isCSP === undefined) {
    //   return []
    // }

    if (
      !isArchive &&
      (currentChannelMember?.isOwner ||
        (currentProjectMember && (currentProjectMember.auth === 'MANAGER' || currentProjectMember.auth === 'OWNER')))
    )
      moreList.push({
        title: t(`workmember.table.board.archive`),
        state: 'openArchive',
      })

    if (
      isArchive &&
      (currentChannelMember?.isOwner ||
        (currentProjectMember && (currentProjectMember.auth === 'MANAGER' || currentProjectMember.auth === 'OWNER')))
    ) {
      moreList.push({
        title: t(`board.authchange`),
        state: 'openSetting',
      })
      if (currentProject && !currentProject.isArchive) {
        moreList.push({
          title: t(`board.unarchive`),
          state: 'openUnarchive',
        })
        moreList2.push({
          title: t(`board.delete`),
          state: 'openDelete',
        })
      }
    }
    return { moreList, moreList2 }
  }, [isCSP, isGuest, currentProject, currentChannelMember, currentProjectMember])

  const manager = React.useMemo(() => {
    if (memberList === undefined) return undefined
    const o = memberList.find((o) => o.isOwner)
    return o
      ? {
          url: `${option.secureCdnUrl}/profile/${o.email}`,
          name: o.name,
          opt: o.isOwner ? t(`board.manager`) : t(`board.member`),
          width: Number(t('board.managerwidth')),
        }
      : undefined
  }, [memberList])

  const relatedManager = React.useMemo(() => {
    if (isCSP && isGuest) return undefined
    return memberList
      ? memberList
          .filter((o) => !o.isOwner)
          .map((o) => ({
            url: `${option.secureCdnUrl}/profile/${o.email}`,
            name: o.name,
            opt: o.isOwner ? t(`board.manager`) : t(`board.member`),
            width: Number(t('board.managerwidth')),
          }))
      : undefined
  }, [memberList, isCSP, isGuest])

  const marginTop = React.useMemo(() => {
    if (isFirst) return 18
    if (isLast) return 10
    return 14
  }, [isFirst, isLast])

  const openCard = React.useCallback(
    (cardType: string) => {
      const action = 'openCard'
      const payload = {
        isWindow: false,
        isEdit: true,
        isFold: false,
        cardType: cardType as CardType,
        channelId: channel.id,
        projectId: currentProject?.id ?? '',
      }
      astroNav.emit(action, payload)
    },
    [channel, currentProject],
  )

  const isBoardSettingShow = React.useMemo(() => {
    if (memberList === undefined) return false
    if (currentProjectMember && (currentProjectMember.auth === 'MANAGER' || currentProjectMember.auth === 'OWNER')) {
      return true
    }
    const owner = memberList.find((o) => o.isOwner)
    return owner && currentUser && owner.userId === String(currentUser.id)
  }, [currentProjectMember, memberList, currentUser])

  const openChat = React.useCallback(() => {
    const action = 'openChat'
    const payload = { channelRoomId: channel.roomId }
    astroNav.emit(action, payload)
  }, [channel])

  const openSetting = React.useCallback(() => {
    open()
  }, [channel])

  const readCardList = React.useCallback(
    (channelId: string, page?: number) => {
      return astro
        .readCardRelationListPage({ boardId: Number(channelId), page: page !== undefined ? page : 0 })
        .then((res: ListPage<CardRelationResponse>) => {
          setIsPageLast(res.last)
          return res.content.filter((o) => o.card.isDel === 'N')
        })
        .catch(() => {
          return []
        })
    },
    [astro],
  )

  const readCardListPage = React.useCallback(
    (channelId: string) => {
      const promises: Promise<any>[] = []
      for (let i = 0; i <= pageInfo.page; i++) {
        promises.push(astro.readCardRelationListPage({ boardId: Number(channelId), page: i }))
      }
      return Promise.all(promises).then((res) => {
        return res.reduce((acc, res) => {
          setIsPageLast(res.last)
          return acc.concat(res.content.filter((o) => o.card?.isDel === 'N'))
        }, [])
      })
    },
    [astro, pageInfo],
  )

  const fetchProjectCount = React.useCallback(() => {
    astro
      .readCardRelationCount(Number(channel.projectId), Number(channel.id))
      .then((count: string) => {
        setTotalCardCount(Number(count))
      })
      .catch(() => setTotalCardCount(0))
    astro
      .readCardRelationCount(Number(channel.projectId), Number(channel.id), true)
      .then((count: string) => {
        setDoneCardCount(Number(count))
      })
      .catch(() => setDoneCardCount(0))
  }, [channel])

  const handleWorkBoardMemberClose = React.useCallback(() => {
    close()
    astro
      .readChannelMemberList(channel.id)
      .then(setMemberList)
      .catch(() => setMemberList([]))
  }, [])

  const labelColor = React.useMemo(() => {
    if (isArchive || currentProject === null || element === undefined) return 'mono.gray'
    return (
      (currentProject.type.code === 'WFP' && isFirst
        ? 'main.yellow'
        : currentProject.type.code === 'WFP' && isLast
        ? 'main.blue'
        : currentProject.type.code === 'WFP'
        ? 'main.turquoise'
        : (element.labelObject as IColors)) || 'mono.paleWhite'
    )
  }, [currentProject, element, isFirst, isLast])

  const ruleCount = React.useMemo(() => {
    if (currentAutomationList === undefined || currentAutomationList === null) return undefined
    const count = currentAutomationList.filter((o) => o.applyBoardId === Number(channel.id) && o.isEnabled).length
    return count > 0 ? count : undefined
  }, [channel, currentAutomationList])

  React.useEffect(() => {
    readCardList(channel.id, 0)
      .then((list: CardRelationResponse[]) => {
        setCardList((cards) => {
          return list.map((o: CardRelationResponse) => {
            const response = cards.find((c) => c.id === o.id)
            if (response) {
              const { card } = response
              const { assignees, todoTotalCount, todoDoneCount, gatheringTotalCount } = card
              return {
                ...o,
                card: {
                  ...card,
                  assignees,
                  todoTotalCount,
                  todoDoneCount,
                  gatheringTotalCount,
                  isSkeleton: false,
                },
              }
            } else {
              const { card } = o
              return {
                ...o,
                card: {
                  ...card,
                  isSkeleton: true,
                },
              }
            }
          })
        })
        return list
      })
      .then((list: CardRelationResponse[]) => {
        return Promise.all(
          list.map((o: CardRelationResponse) => extraCard({ ...o, card: { ...o.card, isSkeleton: false } })),
        )
      })
      .then((list: CardRelationResponse[]) => {
        setCardList(list)
        setIsLoading(false)
      })
    astro
      .readChannelMemberList(channel.id)
      .then(setMemberList)
      .catch(() => setMemberList([]))
  }, [])

  React.useEffect(() => {
    if (pageInfo.page === 0) return
    if (pageInfo.loading) return
    if (isPageLast) return
    readCardList(channel.id, pageInfo.page)
      .then((list: CardRelationResponse[]) => {
        setCardList((cards) => {
          return [
            ...cards,
            ...list.map((o: CardRelationResponse) => {
              const { card } = o
              return {
                ...o,
                card: {
                  ...card,
                  isSkeleton: true,
                },
              }
            }),
          ]
        })
        return list
      })
      .then((list: CardRelationResponse[]) => {
        return Promise.all(
          list.map((o: CardRelationResponse) => extraCard({ ...o, card: { ...o.card, isSkeleton: false } })),
        )
      })
      .then((list: CardRelationResponse[]) => {
        setCardList((cards) => {
          return cards.map((o) => {
            const card = list.find((c) => c.id === o.id)
            if (card) {
              return card
            } else {
              return o
            }
          })
        })
        setIsLoading(false)
        setPageInfo((prev) => ({ ...prev, loading: true }))
      })
  }, [channel, pageInfo, isPageLast])

  React.useEffect(() => {
    setElement(projectElement)
  }, [projectElement])

  useSubscription(`/subscribe/v2/update/${channel.roomId}`, () => {
    resetChannelList()
  })
  useSubscription(
    [
      `/subscribe/channel/${channel.id}/missions/add`,
      `/subscribe/channel/${channel.id}/missions/del`,
      `/subscribe/channel/${channel.id}/missions/update`,
    ],
    () => {
      setTimeout(
        () =>
          readCardListPage(channel.id)
            .then((list) => {
              return Promise.all(
                list.map((o: CardRelationResponse) => extraCard({ ...o, card: { ...o.card, isSkeleton: false } })),
              )
            })
            .then(setCardList),
        500,
      )
    },
  )

  useSubscription([...cardList.map((o) => `/subscribe/card/${o.card.no}/done`)], () => {
    setTimeout(
      () =>
        readCardListPage(channel.id)
          .then((list) => {
            return Promise.all(
              list.map((o: CardRelationResponse) => extraCard({ ...o, card: { ...o.card, isSkeleton: false } })),
            )
          })
          .then(setCardList),
      filterCode === 'PROGRESS' ? 2000 : 500,
    )
  })

  useSubscription(
    [
      ...cardList.map((o) => `/subscribe/card/${o.card.no}/undone`),
      ...cardList.map((o) => `/subscribe/cards/${o.card.no}/unshared-from-board`),
    ],
    () => {
      setTimeout(
        () =>
          readCardListPage(channel.id)
            .then((list) => {
              return Promise.all(
                list.map((o: CardRelationResponse) => extraCard({ ...o, card: { ...o.card, isSkeleton: false } })),
              )
            })
            .then(setCardList),
        500,
      )
    },
  )

  useSubscription([...cardList.map((o) => `/subscribe/cards/${o.card.no}/unshared-from-board`)], () => {
    setTimeout(
      () =>
        readCardListPage(channel.id)
          .then((list) => {
            return Promise.all(
              list.map((o: CardRelationResponse) => extraCard({ ...o, card: { ...o.card, isSkeleton: false } })),
            )
          })
          .then(setCardList),
      500,
    )
  })

  const [title, setTitle] = React.useState(channel.roomName)

  React.useEffect(() => {
    setTitle(channel.roomName)
  }, [channel.roomName])

  const onTitle = React.useCallback(
    (title: string) => {
      if (currentProject) {
        setTitle(title)
        const payload = {
          channelId: channel.id,
          projectId: channel.projectId,
          roomName: title,
          openDateTime: channel.openDateTime,
          leaderId: '',
          memberIds: [],
        }
        astro.updateChannel(payload)

        if (element) {
          astro.updateProjectElements(element.projectId, [{ ...element, name: title }]).then(([e]) => setElement(e))
        }
      }
    },
    [currentProject, channel, element],
  )

  const onLabelColor = React.useCallback(
    (color: IColors) => {
      if (element) {
        astro
          .updateProjectElements(element.projectId, [{ ...element, labelObject: color }])
          .then(([e]) => setElement(e))
      }
    },
    [element],
  )

  const setQrLink = useSetRecoilState(qrLinkState)

  const onMorePress = React.useCallback(
    (state: string) => {
      console.log('onMorePress', state, `${option.workLegacyUrl}/channel/${channel.roomId}`)
      if (state === 'qr') {
        setQrLink(`${option.workLegacyUrl}/channel/${channel.roomId}`)
      } else if (state === 'leave' && currentChannelMember) {
        astro.deleteChannelMember(channel.id, String(currentChannelMember.id))
      }

      if (state === 'openArchive') {
        setBottomAlertMessage({
          message: t('workmember.dialog.board.archive'),
          apply: {
            text: t('alert.apply'),
            action: () => {
              astro.archiveChannel(channel.id, `${moment.utc().format('YYYY-MM-DDTHH:mm:ss')}Z`).then(() => {
                resetChannelList()
                showToastMessage({
                  title: t('toast.confirmed'),
                  type: 'Success',
                  position: 'BOTTOM_RIGHT',
                })
              })
            },
          },
          cancel: {
            text: t('alert.cancel'),
            action: () => {},
          },
        })
      } else if (state === 'openSetting') {
        openSetting()
      } else if (state === 'openUnarchive') {
        setBottomAlertMessage({
          message: t('workmember.dialog.board.title'),
          apply: {
            text: t('alert.modify'),
            action: () => {
              astro.unarchiveChannel(channel.id).then(() => {
                resetChannelList()
                showToastMessage({
                  title: t('toast.confirmed'),
                  type: 'Success',
                  position: 'BOTTOM_RIGHT',
                })
              })
            },
          },
          cancel: {
            text: t('alert.cancel'),
            action: () => {},
          },
        })
      } else if (state === 'openDelete') {
        setBottomAlertMessage({
          message: t('workmember.dialog.board.delete'),
          apply: {
            text: t('alert.apply'),
            action: () => {
              astro.deleteChannel(channel.id).then(() => {
                resetChannelList()
                showToastMessage({
                  title: t('toast.confirmed'),
                  type: 'Success',
                  position: 'BOTTOM_RIGHT',
                })
              })
            },
          },
          cancel: {
            text: t('alert.cancel'),
            action: () => {},
          },
        })
      } else {
        const action = state
        const payload = { channelId: channel.id }
        window.postMessage(
          {
            action,
            payload,
          },
          '*',
        )
      }
    },
    [moreList, currentChannelMember, channel, openSetting],
  )

  const [screenHeigit, setScreenHeight] = React.useState(window.innerHeight)
  const boardHeight = React.useMemo(() => {
    return screenHeigit - 100 - marginTop - 16
  }, [screenHeigit])

  React.useLayoutEffect(() => {
    const resizeEvent = () => {
      setScreenHeight(() => window.innerHeight)
    }
    window.addEventListener('load', resizeEvent)
    window.addEventListener('resize', resizeEvent)

    return () => {
      window.removeEventListener('load', resizeEvent)
      window.removeEventListener('resize', resizeEvent)
    }
  }, [])

  /**
   * ADD_BOARD_MEMBER onboarding
   */
  React.useEffect(() => {
    if (
      currentProjectMember &&
      memberList &&
      currentProjectMember.auth === 'OWNER' &&
      memberList.length === 1 &&
      cardList.length === 1
    ) {
      astroNav.emit('onboarding', { type: 'ADD_BOARD_MEMBER' })
    }
  }, [astroNav, currentProjectMember, memberList, cardList])

  /**
   * ENTER_CHATTING onboarding
   */
  React.useEffect(() => {
    if (currentProjectMember && memberList && currentProjectMember.auth === 'OWNER' && memberList.length > 1) {
      astroNav.emit('onboarding', { type: 'ENTER_CHATTING' })
    }
  }, [astroNav, currentProjectMember, memberList])

  React.useEffect(() => {
    fetchProjectCount()
  }, [fetchProjectCount])

  if (currentProject === null) return <></>

  return (
    <div
      style={{
        userSelect: 'none',
        width: 270,
        marginLeft: isBoardFirst ? 20 : 0,
        marginRight: isBoardLast ? 20 : 10,
        marginTop,
      }}
    >
      <Droppable droppableId={`${channel.id}`} isDropDisabled={isDropDisabled}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <BoardLayout
              showLabel={currentProject && currentProject.type.code === 'DSP' ? true : false}
              containerStyle={{
                width: 270,
                marginRight: 10,
                height: boardHeight,
                zIndex: placeholderProps && placeholderProps.sourceId === channel.id ? 1 : 0,
              }}
              title={title}
              isEmpty={!isLoading && cardList.length === 0}
              onAddMember={isGuest ? undefined : openSetting}
              onPopupState={openCard}
              onTitle={onTitle}
              manager={manager}
              relatedManager={relatedManager}
              onClickChatButton={openChat}
              onPopupAppState={openCard}
              onInviteMemberModal={openSetting}
              onPopupMoreState={onMorePress}
              onMorePress={() => {}}
              appList={isArchive || (isCSP && isGuest) ? undefined : appList}
              totalCardCount={totalCardCount}
              doneCardCount={doneCardCount}
              isAuthority={isArchive ? false : true}
              moreList={moreList.length > 0 ? moreList : undefined}
              moreList2={moreList2.length > 0 ? moreList2 : undefined}
              boardLabel={labelColor}
              onAppsPress={() => {}}
              onIconPress={() => {}}
              onLabelColor={!currentProject ? undefined : currentProject.type.code !== 'DSP' ? undefined : onLabelColor}
              projectType={currentProject.type.code}
              // isBoardSettingShow={isBoardSettingShow}
              handlePressBoardRole={handlePressBoardRole}
              etcButton={
                isCSP ? (
                  <Pressable
                    onPress={() => {
                      onMorePress('qr')
                    }}
                    style={{ marginRight: 4 }}
                  >
                    <FAIcon iconName={faQrcode} size={'sm'} color={'gray.g600'} />
                  </Pressable>
                ) : undefined
              }
              ruleCount={ruleCount}
            >
              {filteredCardList.length !== 0 ? (
                <>
                  <div
                    style={{ flex: 1, overflow: 'auto', paddingRight: 17, marginRight: -17 }}
                    onScroll={(e) => {
                      const target = e.target as HTMLDivElement
                      const { scrollTop, clientHeight, scrollHeight } = target
                      if (scrollTop + clientHeight + 200 >= scrollHeight) {
                        setPageInfo((prev) =>
                          prev.loading
                            ? {
                                ...prev,
                                page: prev.page + 1,
                                loading: false,
                              }
                            : prev,
                        )
                      }
                    }}
                  >
                    {filteredCardList.map((item, index) => {
                      return (
                        <BoardCard
                          key={item.id}
                          index={index}
                          projectId={currentProject?.id}
                          channelId={channel.id}
                          channelRoomId={channel.roomId}
                          item={item}
                          isLast={cardList.length === index + 1}
                          isDragDisabled={isDropDisabled}
                        />
                      )
                    })}
                  </div>
                </>
              ) : undefined}
            </BoardLayout>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Modal
        visible={visible}
        borderType="round"
        animationType="fade"
        containerStyle={{ backgroundColor: COLOR.mono.white }}
      >
        <WorkBoardMemberView
          astro={astro}
          secureCdnUrl={option.secureCdnUrl ?? ''}
          projectId={channel.projectId}
          channelId={channel.id}
          roomId={channel.roomId}
          projectElementId={channel.projectElementId}
          onPressClose={handleWorkBoardMemberClose}
          subscribe={useSubscription}
        />
      </Modal>
    </div>
  )
}

export default BoardComponent
