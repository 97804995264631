import { View } from '@rocket-mono/foundations'
import { useMobileView } from '@rocket-mono/libs'
import { Button } from '@rui/atoms'
import { FAFreeSolidSvgIcons } from '@rui/icons'
import React from 'react'
import { StyleSheet } from 'react-native'
import SettingView from './view'
const { faClose } = FAFreeSolidSvgIcons

interface Props {
  children?: JSX.Element | JSX.Element[]
  tab: string
  onPressItem: (tab: string) => void
  onPressBack?: () => void
  onLogout: () => void
}
const Screen: React.FC<Props> = ({ children, ...viewProps }) => {
  const isMobile = useMobileView(960)

  return isMobile ? (
    <>
      <SettingView {...viewProps} />
      {children}
    </>
  ) : (
    <View style={styles.container}>
      <SettingView {...viewProps} />
      <View style={styles.contentsArea}>
        <View style={styles.conpanel}>{children}</View>
      </View>
      <View style={styles.closeButton}>
        <Button type="SECONDARYWHITE" configuration="icon" beforeIcon={faClose} onPress={viewProps.onPressBack} />
      </View>
    </View>
  )
}

export default Screen

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
  },
  contentsArea: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 124,
    paddingHorizontal: 32,
  },
  conpanel: {
    maxWidth: 700,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: 24,
    right: 24,
  },
})
