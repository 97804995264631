import type { TableorderTable } from '@rocket/types'
import * as React from 'react'

import { useDrag } from 'react-dnd'
import EditedTableCard from '../BoardTableCard/EditedTableCard'
import EditedTableCardEmpty from '../BoardTableCard/EditedTableCardEmpty'
import TableCard from '../BoardTableCard/TableCard'
import { useTableorderOrder } from '../providers'

interface Props {
  editMode: boolean
  table?: TableorderTable
  height: number
  onPressTable?: (id: string) => void
  onPressCreateTable?: () => void
}

export const TableorderBoardItem: React.FC<Props> = ({ editMode, table, height, onPressTable, onPressCreateTable }) => {
  const { orderList } = useTableorderOrder()
  const filteredOrderList = React.useMemo(
    () =>
      orderList?.filter(
        ({ tableId, tableActiveNumber }) => table && tableId === table.id && tableActiveNumber === table.activeNumber,
      ),
    [table, orderList],
  )

  const item = React.useMemo(
    () =>
      table && {
        state: filteredOrderList && filteredOrderList.length > 0 ? 'use' : undefined,
        id: table.id,
        name: table.name,
        capacity: String(table.allowableHeadcount),
        order: filteredOrderList,
        orderDate: filteredOrderList && filteredOrderList.length > 0 ? filteredOrderList[0].createdAt : undefined,
        orderStatus:
          filteredOrderList && filteredOrderList.length > 0
            ? filteredOrderList
                .map((o) => ({
                  ...o,
                  sortData:
                    o.status === 'WAITING_PAYMENT'
                      ? 0
                      : o.status === 'DONE'
                      ? 1
                      : o.status === 'COOKING'
                      ? 2
                      : o.status === 'SERVING_DONE'
                      ? 3
                      : 0,
                }))
                .sort((a, b) => a.sortData - b.sortData)[0].status
            : undefined,
      },
    [filteredOrderList, table],
  )
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'CARD',
      item,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [item],
  )

  if (editMode) {
    return !item ? (
      <EditedTableCardEmpty height={height} onPress={onPressCreateTable} />
    ) : (
      <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
        <EditedTableCard item={item} height={height} onPress={onPressCreateTable} />
      </div>
    )
  }

  if (!!item) return <TableCard item={item} height={height} onPress={() => onPressTable && onPressTable(item.id)} />

  return <></>
}
