import React, { useEffect, useMemo } from 'react'
import {
  NotificationItem,
  NotificationItemProps,
  NotificationList,
  RequestItem,
  useNotification,
} from '../../components'

export function isExpired(date: Date): boolean {
  return date.getTime() - Date.now() < 0
}

interface Props {
  isShow: boolean
  setUnreadCount: (count: number) => void
}
const NotificationListView: React.FC<Props> = ({ isShow, setUnreadCount }) => {
  const { checked, unchecked } = useNotification()

  useEffect(() => {
    setUnreadCount(unchecked?.length || 0)
  }, [checked, unchecked])

  const convert = React.useCallback(
    (item: NotificationItem): NotificationItemProps => {
      const period = (item as RequestItem).expirationDate ? new Date((item as RequestItem).expirationDate) : undefined

      switch (item.type) {
        case 'REQUEST_ATTEND':
        case 'REQUEST_LEAVE':
        case 'REQUEST_TRANSFER_OWNERSHIP':
        case 'REQUEST_JOIN':
          return {
            id: item.id,
            profileUrl: item.imageSrc || '',
            profileAlt: item.userName || '',
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt, // humanized
            period,
            newUnread: !item.isCheck,
            isExpired: period ? isExpired(period) : false,
          }
        case 'NOTIFY_LEAVE':
          console.log('NOTIFY_LEAVE', item)
          return {
            id: item.id,
            profileUrl: item.imageSrc || '',
            profileAlt: item.userName || '',
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt, // humanized
            period,
            newUnread: !item.isCheck,
            isExpired: period ? isExpired(period) : false,
          }
        case 'APPROVE_ATTEND':
        case 'APPROVE_LEAVE':
        case 'APPROVE_LEAVE_SELF':
        case 'APPROVE_TRANSFER_OWNERSHIP':
        case 'APPROVE_JOIN':
          return {
            id: item.id,
            profileUrl: item.imageSrc || '',
            profileAlt: item.userName || '',
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt, // humanized
            newUnread: !item.isCheck,
            period,
            isExpired: period ? isExpired(period) : false,
          }
        case 'DENY_ATTEND':
        case 'DENY_ATTEND_2':
        case 'DENY_LEAVE':
        case 'DENY_TRANSFER_OWNERSHIP':
          return {
            id: item.id,
            profileUrl: item.imageSrc || '',
            profileAlt: item.userName || '',
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt, // humanized
            newUnread: !item.isCheck,
            period,
            isExpired: period ? isExpired(period) : false,
          }
        case 'DENY_JOIN':
          return {
            id: item.id,
            profileUrl: item.imageSrc || '',
            profileAlt: item.userName || '',
            type: item.type,
            user: item.userName,
            work: item.projectName,
            // proposal: 'item.proposal',
            time: item.createdAt, // humanized
            newUnread: !item.isCheck,
            period: undefined,
            isExpired: false,
          }
        default:
          return {
            id: 'item.id',
            profileUrl: '',
            profileAlt: '',
            type: 'NOTIFY_LEAVE',
            user: 'item.user',
            work: 'item.projectName',
            // proposal: 'item.proposal',
            time: new Date(), // humanized
            period,
            newUnread: false,
            isExpired: period ? isExpired(period) : false,
          }
      }
    },
    [checked, unchecked],
  )

  React.useEffect(() => {
    console.log('noti screen: ', { checked, unchecked })
  }, [checked, unchecked])

  const notificationList = useMemo(() => {
    if (checked === undefined || unchecked === undefined) return undefined
    return [...unchecked.reverse(), ...checked.reverse()].map(convert)
  }, [checked, unchecked, convert])

  if (!isShow) return null
  return <NotificationList titleVisible={false} list={notificationList} />
}

export default NotificationListView
