import { COLOR } from '@rui/foundations'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import {
  Animated,
  Dimensions,
  FlatList,
  Image,
  Platform,
  Pressable,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
  useWindowDimensions,
} from 'react-native'
import { LeftIcon, RightIcon } from './icons'
import type { CarouselItem } from './types'

// const { width } = Dimensions.get('window')

interface ImageCarouselProps {
  items: CarouselItem[]
  duration?: number
  transitionDuration?: number
  loop?: boolean
  showButtons?: boolean
  showIndicators?: boolean
  onPressItem?: (idx: number, item: CarouselItem) => void
  resizeMode?: 'cover' | 'contain' | 'stretch' | 'repeat' | 'center'
  imageContentStyle?: ViewStyle
}

const ImageCarousel: FC<ImageCarouselProps> = ({
  items,
  duration = 3000,
  // transitionDuration = 500,
  loop = true,
  showButtons = true,
  showIndicators = true,
  onPressItem,
  resizeMode = 'cover',
  imageContentStyle,
}) => {
  const window = useWindowDimensions()
  const width = window.width > 600 ? 600 : window.width

  const SPACING = Platform.OS === 'web' ? 10 : 5
  const ITEM_LENGTH = Platform.OS === 'web' ? width * 1 : width * 0.9 // Item is a square. Therefore, its height and width are of the same length.
  const EMPTY_ITEM_LENGTH = (width - ITEM_LENGTH) / 2
  // const BORDER_RADIUS = 20
  const CURRENT_ITEM_TRANSLATE_Y = 48

  const scrollX = useRef(new Animated.Value(0)).current
  const [dataWithPlaceholders, setDataWithPlaceholders] = useState<CarouselItem[]>([])
  const [currentIndexNumber, setCurrentIndexNumber] = useState<number>(0)
  const [touch, setTouch] = useState<number>(0)
  const currentIndex = useRef<number>(0)
  const flatListRef = useRef<FlatList>(null)

  const viewabilityConfig = {
    itemVisiblePercentThreshold: 100,
  }

  const onViewableItemsChanged = useCallback(
    ({
      viewableItems,
    }: {
      viewableItems: {
        index: number | null
        isViewable: boolean
        item: CarouselItem
      }[]
    }) => {
      const itemsInView = viewableItems.filter(({ item }: { item: CarouselItem }) => item.uri)

      if (itemsInView.length === 0) {
        return
      }

      currentIndex.current = itemsInView[0].index ?? 0

      setCurrentIndexNumber(currentIndex.current - 1)
      setTouch((prev) => prev + 1)
    },
    [],
  )

  const viewabilityConfigCallbackPairs = useRef([{ viewabilityConfig, onViewableItemsChanged }])

  const handleOnPrev = () => {
    if (currentIndex.current === 1) {
      return
    }

    if (flatListRef.current) {
      flatListRef.current.scrollToIndex({
        animated: true,
        index: currentIndex.current - 1,
      })
    }
  }

  const handleOnNext = () => {
    if (currentIndex.current === items.length) {
      return
    }

    if (flatListRef.current) {
      flatListRef.current.scrollToIndex({
        animated: true,
        index: currentIndex.current + 1,
      })
    }
  }

  const handleOnIndex = (index: number) => {
    if (flatListRef.current) {
      flatListRef.current.scrollToIndex({
        animated: true,
        index: index + 1,
      })
    }
  }

  const getItemLayout = (_data: ArrayLike<CarouselItem> | null | undefined, index: number) => ({
    length: ITEM_LENGTH,
    offset: ITEM_LENGTH * (index - 1),
    index,
  })

  useEffect(() => {
    setDataWithPlaceholders([{ id: -1 }, ...items, { id: items.length }])
    currentIndex.current = 1
  }, [items])

  React.useEffect(() => {
    let time: NodeJS.Timeout
    if (loop) {
      time = setTimeout(() => {
        handleOnIndex(currentIndexNumber === items.length - 1 ? 0 : currentIndexNumber + 1)
      }, duration)
    }
    return () => clearTimeout(time)
  }, [loop, currentIndexNumber, items.length, duration, touch])

  const chartWidth = Dimensions.get('window').width
  const mobileView = Platform.OS === 'web' ? 960 > chartWidth : true

  return (
    <View style={styles.container}>
      {showButtons && currentIndexNumber !== 0 ? (
        <Pressable
          style={styles.prevButton}
          onPress={() => {
            handleOnPrev()
            onPressItem && onPressItem(currentIndexNumber - 1, items[currentIndexNumber - 1])
          }}
        >
          <LeftIcon />
        </Pressable>
      ) : (
        <></>
      )}
      <FlatList
        ref={flatListRef}
        data={dataWithPlaceholders}
        renderItem={({ item, index }) => {
          if (!item.uri) {
            return <View style={{ width: EMPTY_ITEM_LENGTH }} />
          }

          const inputRange = [(index - 2) * ITEM_LENGTH, (index - 1) * ITEM_LENGTH, index * ITEM_LENGTH]

          const translateY = scrollX.interpolate({
            inputRange,
            outputRange: [CURRENT_ITEM_TRANSLATE_Y, CURRENT_ITEM_TRANSLATE_Y, CURRENT_ITEM_TRANSLATE_Y],
            extrapolate: 'clamp',
          })

          return (
            <View style={[{ width: ITEM_LENGTH }, mobileView ? { paddingHorizontal: 20 } : { paddingHorizontal: 10 }]}>
              <Animated.View
                style={[
                  {
                    transform: [{ translateY }],
                  },
                  {
                    marginHorizontal: Platform.OS === 'web' ? 0 : SPACING * 3,
                    alignItems: 'center',
                    backgroundColor: 'white',
                  },
                  imageContentStyle,
                ]}
              >
                {typeof item.uri === 'string' ? (
                  <Image
                    source={{ uri: item.uri }}
                    style={[
                      {
                        width: '100%',
                        height: ITEM_LENGTH,
                        resizeMode: 'cover',
                      },
                      { resizeMode },
                    ]}
                  />
                ) : (
                  <>{item.uri}</>
                )}
              </Animated.View>
            </View>
          )
        }}
        getItemLayout={getItemLayout}
        horizontal
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item: CarouselItem) => `${item.id}`}
        bounces={false}
        decelerationRate={0}
        renderToHardwareTextureAndroid
        contentContainerStyle={{
          height: Platform.OS === 'web' ? ITEM_LENGTH * 0.7 : ITEM_LENGTH * 1.1, // CURRENT_ITEM_TRANSLATE_Y * 2 +
          alignItems: 'center',
          // marginBottom: CURRENT_ITEM_TRANSLATE_Y,
        }}
        snapToInterval={ITEM_LENGTH}
        snapToAlignment="start"
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], { useNativeDriver: false })}
        scrollEventThrottle={16}
        viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs.current}
      />
      {showButtons && currentIndexNumber !== items.length - 1 ? (
        <Pressable
          style={styles.nextButton}
          onPress={() => {
            handleOnNext()
            onPressItem && onPressItem(currentIndexNumber + 1, items[currentIndexNumber + 1])
          }}
        >
          <RightIcon />
        </Pressable>
      ) : (
        <></>
      )}
      {showIndicators ? (
        <View style={styles.footer}>
          <View style={styles.indicatorContainer}>
            {items.map((_, i) => (
              <TouchableOpacity
                key={`indicator-${i}`}
                onPress={() => {
                  handleOnIndex(i)
                  onPressItem && onPressItem(i, items[i])
                }}
              >
                <View
                  style={[styles.indicator, i === currentIndexNumber ? { backgroundColor: COLOR.main.blue } : null]}
                />
              </TouchableOpacity>
            ))}
          </View>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export default ImageCarousel
export type { CarouselItem }

const styles = StyleSheet.create({
  container: {},
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  indicatorContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: Platform.OS === 'web' ? 20 : 8,
  },
  indicator: {
    width: 10,
    height: 10,
    borderRadius: 5,
    marginHorizontal: 5,
    backgroundColor: COLOR.gray.g200,
  },
  prevButton: {
    position: 'absolute',
    top: '50%',
    left: -30,
    marginTop: -24,
    zIndex: 1,
  },
  nextButton: {
    position: 'absolute',
    top: '50%',
    marginTop: -24,
    right: -30,
    zIndex: 1,
  },
})
