import React, { useCallback, useState } from 'react'

import { replaceItemAtIndex } from '@rui/foundations'
import Context from './context'
import type { FileType, ProviderProps } from './types'

const Provider = ({ astro, children }: ProviderProps) => {
  const [fileList, setFileList] = useState<FileType[]>([])

  const addFileList = useCallback((file: FileType) => {
    setFileList((prev) => {
      const idx = prev.findIndex(({ filePath }) => filePath === file.filePath)
      return idx < 0 ? [...prev, file] : replaceItemAtIndex(prev, idx, file)
    })
  }, [])

  const readFileUrl = useCallback((filePath: string) => {
    return astro.readTableorderFileUrl(filePath)
  }, [])

  const uploadFile = useCallback(async (list: FileList) => {
    // addFileList(list)

    // list.
    const fileList: File[] = []

    for (let i = 0; i < list.length; i++) {
      const fileItem = list.item(i)
      if (fileItem !== null) {
        fileList.push(fileItem)
      }
    }

    Promise.all(
      fileList.map((file) => {
        const formData = new FormData()
        formData.append('file', file)
        return astro.createTableorderFile(formData).then((filePath) => ({ name: file.name, filePath }))
      }),
    )
      .then((fileList) => {
        fileList.map(addFileList)
      })
      .catch((err) => console.error('createfile', err))
  }, [])

  // useEff

  return (
    <Context.Provider value={{ fileList, changeFileList: setFileList, uploadFile, readFileUrl }}>
      {children}
    </Context.Provider>
  )
}

export default Provider
