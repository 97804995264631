import * as React from 'react'

import type { SubscribeType } from '@rocket-mono/libs'
import { WorkBoardAstroType, WorkBoardProvider, WorkMemberAstroType, WorkMemberProvider } from '../providers'
import Context from './context'
import Provider from './provider'
import type { AstroType } from './types'
import View from './view'

interface Props {
  projectId: string
  secureCdn: string
  astro: AstroType & WorkMemberAstroType & WorkBoardAstroType
  subscribe: SubscribeType
  onProjectLeave?: () => void
}
export const WorkMemberScreen: React.FC<Props> = ({ projectId, secureCdn, astro, subscribe, onProjectLeave }) => {
  return (
    <WorkMemberProvider projectId={projectId} astro={astro} subscribe={subscribe} onProjectLeave={onProjectLeave}>
      <WorkBoardProvider projectId={projectId} astro={astro} subscribe={subscribe}>
        <Provider astro={astro}>
          <Context.Consumer>
            {(context) => context && <View projectId={projectId} secureCdn={secureCdn} />}
          </Context.Consumer>
        </Provider>
      </WorkBoardProvider>
    </WorkMemberProvider>
  )
}
