import { Icon } from '@rocket-atoms/icon'
import { Switch } from '@rocket-atoms/toggle'
import { DateRangePicker } from '@rocket-molecules/date'
import type { IColors } from '@rocket-mono/libs'
import { COLOR, FONT, changeItemAtIndex, useMobileView } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import { useToast } from '@rui/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Platform, StyleSheet, TextInput, View } from 'react-native'
import { WorkmodelDragList } from '../../WorkmodelDragList'
import WorkmodelPreviewWorkflow from '../../WorkmodelPreview/WorkmodelPreviewWorkflow'
import Container from '../container'
import { Header } from '../header'
import LayoutView from '../layout'
import type { CreateWorkModelWorkflowProps } from '../types'

const CreateWorkModelWorkflow = ({
  data,
  titleError,
  step,
  keyboardHeight,
  setStep,
  onPressNext,
  onPressBack,
  onChangeTitle,
  onChangeGoal,
  onChangeProjectElements,
  onChangeRangeDate,
  onChangePreText,
  onChangeEndText,
  onPressCreate,
  onPressAdd,
  onPressRemove,
  onChangeElement,
}: CreateWorkModelWorkflowProps) => {
  const { t } = useTranslation()
  const { title, goal, projectElements, rangeDate, preText, endText } = data

  const stepper = [
    { step: 1, title: t('workmodal.basic.basesetting'), checked: false },
    { step: 2, title: t('workmodal.wfp.part'), checked: false },
  ]

  const mobileView = useMobileView(1000)

  const [contentsHeight, setContentsHeight] = React.useState(0)

  const [activated, setActivated] = React.useState(false)
  const [scrollEnabled, setScrollEnabled] = React.useState(true)

  const dragList = React.useMemo(
    () =>
      projectElements.map((o) => ({
        text: o.name,
      })),
    [projectElements],
  )

  const previewList = React.useMemo(() => {
    const [element] = projectElements

    return [
      { title: preText, labelColor: COLOR.main.yellow as IColors },
      {
        title: element ? element.name : '',
        labelColor: COLOR.main.turquoise as IColors,
      },
      { title: endText, labelColor: COLOR.main.blue as IColors },
    ]
  }, [preText, endText, projectElements])
  const [fieldError, setFieldError] = React.useState(false)
  const { show: showToastMessage } = useToast()

  const handlePressCreate = React.useCallback(() => {
    if (preText === '' || endText === '' || projectElements.filter((o) => o.name === '').length > 0) {
      setFieldError(true)
      showToastMessage({
        type: 'Danger',
        title: t('workmodal.error.subname'),
        position: 'BOTTOM_CENTER',
      })
    } else {
      setFieldError(false)
      onPressCreate()
    }
  }, [onPressCreate, preText, endText, projectElements])

  const onChangeList = React.useCallback(
    (from: number, to: number) => {
      onChangeProjectElements(changeItemAtIndex(projectElements, from, to))
    },
    [projectElements],
  )

  const endInputRef = React.useRef<TextInput>(null)
  const onPressEndTab = React.useCallback(() => {
    endInputRef.current?.focus()
  }, [endInputRef])

  return (
    <Container
      isValid={true}
      step={step}
      submitStep={2}
      onPressNext={onPressNext}
      onPressBack={onPressBack}
      onPressCreate={handlePressCreate}
    >
      <Header
        title={t('workmodal.wfp.title')}
        info={t('workmodal.basic.settingchangedesc')}
        stepper={stepper}
        step={step}
        setStep={setStep}
      />
      <LayoutView
        isValid={true}
        step={step}
        submitStep={2}
        scrollEnabled={scrollEnabled}
        keyboardHeight={keyboardHeight}
        onPressNext={onPressNext}
        onPressBack={onPressBack}
        onPressCreate={handlePressCreate}
        previewElement={
          <WorkmodelPreviewWorkflow
            mobileView={mobileView}
            title={title}
            goal={goal || ''}
            list={previewList}
            step={step}
          />
        }
      >
        <View
          onLayout={(e) => {
            setContentsHeight(e.nativeEvent.layout.height)
          }}
          style={{ paddingHorizontal: 4 }}
        >
          {step === 1 ? (
            <>
              <View>
                <View style={styles.sectionLabel}>
                  <View style={styles.labelBlock}>
                    <></>
                  </View>
                  <Text style={styles.labelName}>{t('workmodal.basic.name')}</Text>
                  <Text style={[styles.labelName, { color: COLOR.main.red }]}>*</Text>
                </View>
                <View>
                  <TextInput
                    value={title}
                    style={[styles.inputStyle, titleError && { borderColor: COLOR.status.busy }]}
                    placeholder={t('workmodal.basic.nameplaceholder')}
                    placeholderTextColor={COLOR.gray.g400}
                    textAlignVertical="center"
                    underlineColorAndroid="transparent"
                    onChangeText={onChangeTitle}
                  />
                  {titleError && (
                    <View style={{ flexDirection: 'row' }}>
                      <Icon name="error" color="status.busy" />
                      <Text fontColor="status.busy">{t('workmodal.basic.nameplaceholder')}</Text>
                    </View>
                  )}
                </View>
              </View>
              <View style={[{ marginTop: 24 }, mobileView && { marginTop: 16 }]}>
                <View style={styles.sectionLabel}>
                  <View style={styles.labelBlock}></View>
                  <Text style={styles.labelName}>{t('workmodal.basic.goal')}</Text>
                </View>
                <TextInput
                  value={goal}
                  style={[styles.inputStyle]}
                  placeholder={t('workmodal.basic.goalplaceholder')}
                  placeholderTextColor={COLOR.gray.g400}
                  textAlignVertical="center"
                  underlineColorAndroid="transparent"
                  onChangeText={onChangeGoal}
                />
              </View>
            </>
          ) : step === 2 ? (
            <>
              <View>
                <View style={styles.sectionLabel}>
                  <View style={styles.labelBlock}>
                    <></>
                  </View>
                  <Text style={styles.labelName}>{t('workmodal.wfp.workflow')}</Text>
                </View>
                <View>
                  <View
                    style={[
                      styles.flowLine,
                      {
                        height: contentsHeight - 150,
                        marginTop: -(contentsHeight - 100) / 2,
                      },
                    ]}
                  />
                  <View style={styles.flowGroup}>
                    <View style={[styles.groupLabel, mobileView && styles.groupLabelMobile]}>
                      <View
                        style={[
                          styles.labelRing,
                          mobileView && styles.labelRingMobile,
                          { borderColor: COLOR.main.yellow },
                        ]}
                      ></View>
                    </View>
                    <View
                      style={[
                        styles.groupWrap,
                        {
                          paddingBottom: 12,
                          borderBottomWidth: 1,
                        },
                      ]}
                    >
                      <Text style={{ ...FONT.txtSmMedium, marginBottom: 4 }}>{t('workmodal.wfp.preparation')}</Text>
                      <TextInput
                        value={preText}
                        style={[
                          styles.inputStyle,
                          fieldError &&
                            preText === '' && {
                              borderColor: COLOR.status.busy,
                            },
                        ]}
                        placeholder={t('workmodal.wfp.preparationplaceholder')}
                        placeholderTextColor={COLOR.gray.g400}
                        textAlignVertical="center"
                        underlineColorAndroid="transparent"
                        onChangeText={onChangePreText}
                      />
                    </View>
                  </View>
                  <View style={[styles.flowGroup, { minHeight: 210, paddingVertical: 12 }]}>
                    <View style={[styles.groupLabel, mobileView && styles.groupLabelMobile]}>
                      <View
                        style={[
                          styles.labelRing,
                          mobileView && styles.labelRingMobile,
                          { borderColor: COLOR.main.turquoise },
                        ]}
                      ></View>
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          ...FONT.txtSmMedium,
                          marginLeft: 28,
                          marginBottom: 4,
                        }}
                      >
                        {t('workmodal.wfp.working')}
                      </Text>
                      <WorkmodelDragList
                        list={dragList}
                        fieldError={fieldError}
                        setScrollEnabled={setScrollEnabled}
                        onPressAdd={onPressAdd}
                        onPressEndTab={onPressEndTab}
                        onClickRemoveButton={onPressRemove}
                        onChangeElement={onChangeElement}
                        onChangeList={onChangeList}
                      />
                    </View>
                  </View>
                  <View style={styles.flowGroup}>
                    <View
                      style={[
                        styles.groupLabel,
                        mobileView && styles.groupLabelMobile,
                        mobileView
                          ? {
                              marginTop: 18,
                              height: 48,
                              backgroundColor: COLOR.mono.white,
                              justifyContent: 'flex-start',
                            }
                          : { marginTop: 12 },
                      ]}
                    >
                      <View
                        style={[
                          styles.labelRing,
                          mobileView && styles.labelRingMobile,
                          { borderColor: COLOR.main.blue },
                        ]}
                      ></View>
                    </View>
                    <View
                      style={[
                        styles.groupWrap,
                        {
                          paddingTop: 12,
                          borderTopWidth: 1,
                        },
                      ]}
                    >
                      <Text style={{ ...FONT.txtSmMedium, marginBottom: 4 }}>{t('workmodal.wfp.complete')}</Text>
                      <TextInput
                        ref={endInputRef}
                        value={endText}
                        style={[
                          styles.inputStyle,
                          fieldError &&
                            endText === '' && {
                              borderColor: COLOR.status.busy,
                            },
                        ]}
                        placeholder={t('workmodal.wfp.completeplaceholder')}
                        placeholderTextColor={COLOR.gray.g400}
                        textAlignVertical="center"
                        underlineColorAndroid="transparent"
                        onChangeText={onChangeEndText}
                      />
                    </View>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 28 }}>
                <View style={[styles.flexBox, { alignItems: 'center' }]}>
                  <View style={styles.sectionLabel}>
                    <View style={styles.labelBlock}></View>
                    <Text style={styles.labelName}>{t('workmodal.basic.deadline')}</Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Text
                      style={{
                        ...FONT.txtXs,
                        color: COLOR.gray.g700,
                        marginRight: 4,
                      }}
                    >
                      {!mobileView && t('workmodal.basic.deadlinedesc1')}
                      {t('workmodal.basic.deadlinedesc2')}
                      {!mobileView && t('workmodal.basic.deadlinedesc3')}
                    </Text>
                    <Switch size="sm" activated={activated} onChange={setActivated} />
                  </View>
                </View>
                {activated && <DateRangePicker rangeDate={rangeDate || {}} onChangeDate={onChangeRangeDate} />}
              </View>
            </>
          ) : (
            <></>
          )}
        </View>
      </LayoutView>
    </Container>
  )
}

export default CreateWorkModelWorkflow

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentBox: {
    flex: 1,
    position: 'relative',
    justifyContent: 'flex-start',
  },
  sectionLabel: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  labelBlock: {
    width: 3,
    height: 14,
    marginRight: 6,
    backgroundColor: COLOR.mono.black,
  },
  labelName: {
    ...FONT.txtMdMedium,
  },
  inputStyle: {
    ...FONT.txtMd,
    width: '100%',
    height: 44,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 8,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
      native: {},
    }),
  },
  flowLine: {
    position: 'absolute',
    top: '50%',
    left: 6,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g200,
  },
  flowGroup: {
    flexDirection: 'row',
  },
  groupLabel: {
    width: 16,
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  groupLabelMobile: {
    marginLeft: -30,
    width: 44,
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  groupWrap: {
    flex: 1,
    marginLeft: 28,
    borderColor: COLOR.gray.g100,
  },
  labelRing: {
    width: 14,
    height: 14,
    borderRadius: 30,
    borderWidth: 3,
    backgroundColor: COLOR.mono.white,
    marginRight: 6,
  },
  labelRingMobile: {
    marginRight: 0,
  },
})
