import * as React from 'react'
import { useState } from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

import { TableorderCatalogOptionGroup } from '@rocket/types'

import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import { CheckIcon } from '../../../icons'

interface Props {
  item: TableorderCatalogOptionGroup
  optionIds: string[]
  onPress?: (optionId: string) => void
}
const MenuDetailOptionItem: React.FC<Props> = ({ optionIds, item, onPress }) => {
  const [isFold, setIsFold] = useState(item.type === 'REQUIRED')

  const selectCount = React.useMemo(() => {
    return item.options.filter(({ id }) => optionIds.includes(id)).length
  }, [optionIds])

  const handlePress = React.useCallback(
    (optionId: string) => {
      if (!item.isMultipleChoicesAvailable && selectCount > 0 && !optionIds.includes(optionId)) {
        item.options.filter(({ id }) => optionIds.includes(id)).map(({ id }) => onPress && onPress(id))
      }
      onPress && onPress(optionId)
    },
    [item, optionIds, selectCount, onPress],
  )

  return (
    <>
      <View style={styles.container}>
        <Pressable style={styles.optionTab} onPress={() => setIsFold(!isFold)}>
          <Text style={styles.optionName}>
            {item.type === 'REQUIRED' ? '[필수] ' : '[선택] '}
            {item.name}
          </Text>
          <XEIcon name={isFold ? 'angle-down' : 'angle-up'} size={14} />
        </Pressable>
        {isFold &&
          item.options.map((option, elIdx) => (
            <Pressable
              key={`detailOption-${elIdx}`}
              style={styles.optionContents}
              onPress={() => {
                handlePress(option.id)
              }}
            >
              <View style={[styles.flexbox, { paddingBottom: 4 }]}>
                <View>
                  <CheckIcon
                    checked={optionIds.includes(option.id)}
                    iconColor={optionIds.includes(option.id) ? 'mono.white' : 'gray.g300'}
                  />
                </View>
                <Text style={{ ...FONT.txtMd, color: COLOR.gray.g800 }}>{option.name}</Text>
              </View>
              <Text style={{ ...FONT.txtMd, color: COLOR.gray.g800 }}>+{option.price}원</Text>
            </Pressable>
          ))}
      </View>
    </>
  )
}

export default MenuDetailOptionItem

const styles = StyleSheet.create({
  flexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g150,
    paddingVertical: 8,
  },
  optionTab: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
  },
  optionName: {
    ...FONT.txtSmMedium,
  },
  optionContents: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 4,
  },

  //
  checkIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 28,
    height: 28,
    borderRadius: 30,
    backgroundColor: COLOR.gray.g100,
    marginRight: 4,
  },

  chckIconChecked: {
    backgroundColor: COLOR.main.blue,
  },
})
