/* eslint-disable react-native/no-inline-styles */
import * as React from 'react'
import { UIFile } from './UIFile'
import type { Props } from './type'

const File: React.FC<Props> = ({
  onPress,
  onDropFile,
  onPasteFile,
  onLoadFile,
  multiple,
  maxLength,
  accept,
  ...props
}) => {
  const fileRef = React.useRef<HTMLInputElement>(null)
  const [isDragging, setDragging] = React.useState(false)

  const onDragEnter = React.useCallback((e: React.DragEvent) => {
    preventToOpenNewTab(e)
  }, [])
  const onDragLeave = React.useCallback((e: React.DragEvent) => {
    preventToOpenNewTab(e)
    setDragging(false)
  }, [])

  const onDragOver = React.useCallback((e: React.DragEvent) => {
    preventToOpenNewTab(e)
    if (e.dataTransfer?.files) {
      setDragging(true)
    }
  }, [])

  const onDrop = React.useCallback(
    (e: React.DragEvent) => {
      preventToOpenNewTab(e)
      if (onDropFile) onDropFile(e.dataTransfer.files)
      setDragging(false)
    },
    [onDropFile],
  )

  const onPaste = React.useCallback(
    (e: React.ClipboardEvent) => {
      if (onPasteFile) onPasteFile(e.clipboardData.files)
    },
    [onPasteFile],
  )

  const handlePress = React.useCallback(() => {
    if (onPress) onPress()
    else fileRef?.current?.click()
  }, [])

  const onFileChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onLoadFile && e.target.files !== null) onLoadFile(e.target.files)
      fileRef.current?.value && (fileRef.current.value = '')
    },
    [onLoadFile],
  )

  return (
    <div
      style={{ width: '100%' }}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onPaste={onPaste}
    >
      <input
        ref={fileRef}
        type="file"
        onChange={onFileChange}
        style={{ display: 'none' }}
        multiple={multiple}
        maxLength={maxLength}
        accept={accept}
      />
      <UIFile {...props} onPress={handlePress} isDragging={isDragging} />
    </div>
  )
}

export default React.memo(File)

function preventToOpenNewTab(e: React.DragEvent) {
  e.preventDefault()
  e.stopPropagation()
}
