import { createContext } from 'react'
import type { NotificationContextType } from './types'

export const NotificationContext = createContext<NotificationContextType>({
  checked: [],
  unchecked: [],
  error: 'NO_ERROR',
  markNotificationAsRead: () => Promise.resolve(null),
  acceptProjectInvitation: () => Promise.resolve(),
  rejectProjectInvitation: () => Promise.resolve(),
  fetchNotifications: () => {},
})
