import { useAstro, useSubscription, useWork } from '@rocket-mono/providers'
import * as React from 'react'
import { ChatsScreen } from '../ChatsScreen'

interface Props {
  children?: JSX.Element | JSX.Element[]
}
const Provider: React.FC<Props> = ({ children }) => {
  const { astro, option } = useAstro()
  const { projectList, guestList } = useWork()

  return (
    <ChatsScreen.Provider
      projectListData={projectList}
      guestListData={guestList}
      astro={astro}
      secureCdnUrl={option.secureCdnUrl || ''}
      subscribe={useSubscription}
    >
      <>{children}</>
    </ChatsScreen.Provider>
  )
}

export default Provider
