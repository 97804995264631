import * as React from 'react'
import { Platform, ScrollView, StyleSheet, View } from 'react-native'

import { useWorkProject } from '@rocket-mono/providers'
import { Text } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import { WorkIcon } from '@rui/icons'
import { BarButton, ModalBottomSheetType } from '../../components'
import { MemberStateType } from '../../MemberList'
import SelectMemberList from '../../MemberList/selectMemberList'
import { MemberListItem } from '../../MemberList/types'

interface Props {
  secureCdnUrl: string
  leaveVisible: boolean
  onPressRequest: (requestUserId: string) => void
  onPressClose: () => void
}

export const ChatLeaving: React.FC<Props> = ({ secureCdnUrl, leaveVisible, onPressClose, onPressRequest }) => {
  const [selected, setSelected] = React.useState<string>()
  const { projectMemberList } = useWorkProject()

  console.log('projectMemberList', projectMemberList)

  const inviteMemberList: MemberListItem[] | undefined = React.useMemo(
    () =>
      projectMemberList
        ?.filter((o) => ['OWNER', 'MANAGER'].includes(o.auth))
        .map((o) => ({
          id: o.id,
          name: o.name,
          state: o.auth === 'OWNER' ? '소유자' : ('관리자' as MemberStateType),
          email: o.email,
          positionLeader: false,
          selected: o.id === selected,
          onInviteMember: (index) => setSelected(index),
        })),
    [projectMemberList, selected],
  )

  const handlePressRequest = React.useCallback(() => {
    if (selected !== undefined && !!selected) {
      const member = projectMemberList?.find((o) => o.id === selected)
      if (member) onPressRequest(member.userId)
    }
  }, [selected, projectMemberList])

  // const [inviteMemberList, setInviteMemberList] =
  //   React.useState<InviteMemberListItem[]>(_MemberList)
  return (
    <>
      <ModalBottomSheetType
        visible={leaveVisible}
        height="90%"
        containerStyle={
          Platform.OS === 'web'
            ? {
                width: 400,
                height: 700,
                right: 11,
                bottom: 51,
              }
            : {}
        }
      >
        <ModalBottomSheetType.Header textAlign="center" onPressClose={onPressClose}>
          워크 나가기 요청
        </ModalBottomSheetType.Header>
        <ModalBottomSheetType.Body>
          <ScrollView style={{ paddingVertical: 16 }}>
            <Text fontName="txtMdMedium" style={{ paddingHorizontal: 20 }}>
              나가기를 승인할 관리자를 선택해주세요
            </Text>
            <View style={{ paddingTop: 16 }}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingHorizontal: 20,
                }}
              >
                <WorkIcon.DSP size={16} />
                <Text
                  style={{
                    ...FONT.txtSmMedium,
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  워크명
                </Text>
              </View>
              <View
                style={{
                  marginTop: 8,
                  borderTopWidth: 1,
                  borderColor: COLOR.gray.g100,
                }}
              >
                {inviteMemberList && (
                  <SelectMemberList
                    secureCdnUrl={secureCdnUrl}
                    // selected={selected}
                    inviteList={inviteMemberList}
                    // onInviteMember={(index) => setSelected(index)}
                  />
                )}
              </View>
            </View>
          </ScrollView>
        </ModalBottomSheetType.Body>
        <ModalBottomSheetType.Footer>
          <BarButton
            contanerStyle={{
              height: 50,
              backgroundColor: COLOR.main.blue,
            }}
            title="나가기 요청"
            action={handlePressRequest}
            callToAction
            disabled={false}
          />
        </ModalBottomSheetType.Footer>
      </ModalBottomSheetType>
      {leaveVisible ? <View style={styles.dimmedCover} nativeID="chats-leave-view"></View> : <></>}
    </>
  )
}

const styles = StyleSheet.create({
  dimmedCover: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 99,
    backgroundColor: COLOR.dimmed.a40,
  },
})
