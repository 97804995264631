import Context from './context'
import Provider from './provider'
import View from './view'

const TableorderOrderDetailView = Object.assign(View, {
  Provider: Provider,
  Consumer: Context.Consumer,
})

export * from './hooks'
export { TableorderOrderDetailView }
