import { useModal } from '@rocket-mono/providers'
import { useMobileView } from '@rui/foundations'
import * as React from 'react'
import WorkSettingTimezoneMobile from './mobile'
import type { WorkSettingTimezoneType } from './types'
import WorkSettingTimezoneWeb from './web'

const WorkSettingTimezone = (props: WorkSettingTimezoneType) => {
  const isMobile = useMobileView(960)
  const { Modal, visible, close, open } = useModal('WorkSettingTimezone')

  React.useEffect(() => {
    if (isMobile) open()
  }, [isMobile])

  const handleSave = React.useCallback(() => {
    if (isMobile) {
      close()
      props.onPressSave && props.onPressSave()
    }
  }, [props.onPressSave])

  const handleClose = React.useCallback(() => {
    if (isMobile) {
      close()
      props.onDismiss && props.onDismiss()
    }
  }, [props.onDismiss])

  return isMobile ? (
    <Modal animationType={'fade'} visible={visible}>
      <WorkSettingTimezoneMobile {...props} onPressSave={handleSave} onDismiss={handleClose} />
    </Modal>
  ) : (
    <WorkSettingTimezoneWeb {...props} />
  )
}

export default WorkSettingTimezone
