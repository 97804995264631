import { useContext } from 'react'
import { Context, TableorderBoardContext } from '../context'

export const useEditMode = () => {
  const context = useContext<TableorderBoardContext | undefined>(Context)

  if (context === undefined)
    throw new Error('There must be a TableorderBoardContext as Ancestor of all Astro Hooks and HOCs')

  const { editMode, onToggleEditMode } = context

  return { editMode, onToggleEditMode }
}
