import * as React from 'react'

import View from './view'

interface Props {
  closeModal: () => void
}
export const TableorderMenuLabelModal: React.FC<Props> = ({ closeModal }) => {
  return <View closeModal={closeModal} />
}
