import React, { useEffect, useState } from 'react'

import { useModalDialog } from '@rocket-mono/providers'
import { TableorderShop, TableorderShopLayout } from '@rocket/types'
import { useToast } from '@rui/atoms'
import { removeItemAtIndex, replaceItemAtIndex } from '@rui/foundations'
import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ shopId, astro, children }: ProviderProps) => {
  const [shop, setShop] = useState<TableorderShop>()
  const [shopLayoutId, setShopLayoutId] = useState<string>()
  const [shopLayoutList, setShopLayoutList] = useState<TableorderShopLayout[]>()
  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const updateShopLayout = React.useCallback((shopLayout: TableorderShopLayout) => {
    setShopLayoutList((prev) => {
      if (prev === undefined) return [shopLayout]
      const idx = prev.findIndex((o) => o.id === shopLayout.id)
      return idx < 0 ? [...prev, shopLayout] : replaceItemAtIndex(prev, idx, shopLayout)
    })
  }, [])

  const deleteShopLayout = React.useCallback((shopLayout: TableorderShopLayout) => {
    setShopLayoutList((prev) => {
      if (prev === undefined) return prev
      const idx = prev.findIndex((o) => o.id === shopLayout.id)
      return idx < 0 ? prev : removeItemAtIndex(prev, idx)
    })
  }, [])

  const onChangeShopLayoutName = React.useCallback(
    (id: string, name: string) => {
      const layout = shopLayoutList?.find((o) => o.id === id)
      if (layout) {
        const { sequenceNumber } = layout
        astro
          .updateShopLayout(shopId, id, {
            id,
            shopId,
            name,
            sequenceNumber,
          })
          .then(updateShopLayout)
      }
    },
    [shopId, shopLayoutList],
  )

  const addShopLayoutList = React.useCallback(() => {
    const sequenceNumber = shopLayoutList?.length || 0
    const name = `${sequenceNumber + 1}층`
    if (sequenceNumber < 5) {
      astro
        .createShopLayout(shopId, {
          shopId,
          name,
          sequenceNumber,
        })
        .then((layout) => {
          setShopLayoutId(layout.id)
          return layout
        })
        .then(updateShopLayout)
    } else {
      showToastMessage({ type: 'Danger', title: '5층 까지만 추가할 수 있습니다.', position: 'BOTTOM_CENTER' })
    }
  }, [shopId, shopLayoutList])

  const deleteShopLayoutList = React.useCallback(
    (id: string) => {
      if (shopLayoutList && shopLayoutList.length > 1) {
        const list = [
          {
            name: '삭제',
            action: () => {
              astro
                .deleteShopLayout(shopId, id)
                .then((layout) => {
                  const list = shopLayoutList.filter((o) => o.id !== id)
                  setShopLayoutId(list[0].id)
                  return layout
                })
                .then(deleteShopLayout)
                .then(hideDialogMessage)
            },
          },
        ]
        showDialogMessage({
          title: '층을 삭제하시겠습니까?',
          message: '삭제하시면 해당 측에 생선된 모든 테이블도 같이 삭제됩니다.',
          list,
          cancelText: '취소',
          onCancel: hideDialogMessage,
        })
      }
    },
    [shopId, shopLayoutList],
  )

  useEffect(() => {
    astro.readShop(shopId).then(setShop)
    astro
      .readShopLayoutList(shopId)
      .then((list) => list.filter((o) => !o.isDeleted))
      .then((list) =>
        list.length > 0
          ? list
          : astro
              .createShopLayout(shopId, {
                shopId,
                name: '메인홀',
                sequenceNumber: 0,
              })
              .then((layout) => [layout]),
      )
      .then(setShopLayoutList)
  }, [shopId])

  useEffect(() => {
    if (shopLayoutId === undefined && shopLayoutList && shopLayoutList.length > 0) setShopLayoutId(shopLayoutList[0].id)
  }, [shopLayoutId, shopLayoutList])

  return (
    <Context.Provider
      value={{
        shop,
        shopId,
        shopLayoutId,
        shopLayoutList,
        onChangeShopLayoutId: setShopLayoutId,
        onChangeShopLayoutName,
        addShopLayoutList,
        deleteShopLayoutList,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
