import { View } from '@rocket-mono/foundations'
import { changeItemAtIndex, removeItemAtIndex } from '@rui/foundations'
import * as React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { StyleSheet } from 'react-native'
import RCFile from '../../File'
import { useTableorderFile } from '../../providers'
import { useMenu } from '../hooks/useMenu'
import { TableorderMenuDetailFileItem } from './TableorderMenuDetailFileItem'

interface Props {}

export const TableorderMenuDetailFileList: React.FC<Props> = ({}) => {
  const { userName } = useMenu()
  const { fileList, changeFileList, uploadFile } = useTableorderFile()

  return (
    <>
      <View style={styles.inputContainer}>
        <RCFile
          multiple
          size="SMALL"
          accept="image/*"
          onDropFile={uploadFile}
          onPasteFile={uploadFile}
          onLoadFile={uploadFile}
        />
      </View>
      <View style={styles.container}>
        <DragDropContext
          onDragEnd={(result: DropResult) => {
            if (result.destination) {
              changeFileList(changeItemAtIndex(fileList, result.source.index, result.destination.index))
            }
          }}
        >
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fileList.map((item, index) =>
                  item !== undefined ? (
                    <Draggable key={`drag-${index}`} draggableId={`drag-${index}`} index={index}>
                      {(provided) => {
                        return (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <TableorderMenuDetailFileItem
                              key={`file-${index}`}
                              index={index}
                              file={item}
                              uploaderName={userName}
                              dragHandleProps={provided.dragHandleProps}
                              onPressDelete={() => {
                                changeFileList(removeItemAtIndex(fileList, index))
                                console.log('delete')
                              }}
                            />
                          </div>
                        )
                      }}
                    </Draggable>
                  ) : null,
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: { marginTop: 12 },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 34,
    alignItems: 'center',
  },
})
