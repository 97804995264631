import { Modal } from '@rocket-mono/foundations'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, View } from 'react-native'
import PasswordView from '../PasswordView'
import { usePassword } from '../providers'
import type { ViewProps } from './types'

const PasswordModalView: React.FC<ViewProps> = ({ onPressClose }) => {
  const { t } = useTranslation()
  const { currentUser, onUpdatePassword } = usePassword()
  const isCurrentUserPassword = React.useMemo(() => {
    return currentUser.isPasswordExists
  }, [currentUser])

  return (
    <View style={styles.container}>
      <Modal.Header
        textAlign="center"
        onPressClose={() => {
          onPressClose()
        }}
        closeIcon={<XEIcon name="close" />}
      >
        {t('setting.password.title')}
      </Modal.Header>
      <Modal.Body style={styles.bodyTopPadding}>
        <PasswordView
          isCurrentUserPassword={isCurrentUserPassword}
          onPress={(password) => {
            onUpdatePassword(password.currentPassword, password.newPassword)
          }}
        />
      </Modal.Body>
      {/* {loading ? (
        <RNModal transparent>
          <View style={styles.loadingContainer}>
            <ActivityIndicator
              size="large"
              color={getRealColor('mono.white')}
            />
          </View>
        </RNModal>
      ) : (
        <></>
      )} */}
    </View>
  )
}

export default PasswordModalView

const styles = StyleSheet.create({
  container: {
    width: '100%',
    maxWidth: 424,
    minHeight: 560,
    backgroundColor: COLOR.mono.white,
    borderRadius: 8,
  },
  bodyTopPadding: {
    paddingTop: 12,
  },
})
