import { View } from '@rocket-mono/foundations'
import * as React from 'react'
import { TableorderOrderAdditionalScreen } from '../components'

import { useAstro, useSubscription } from '@rocket-mono/providers'
import { use100vh } from 'react-div-100vh'
import Providers from '../providers'

interface Props {
  shopId: string
  tableId?: string
}

const OrderAdditionalScreen: React.FC<Props> = ({ shopId, tableId }) => {
  const { astro } = useAstro()
  const height = use100vh()
  const handlePressClose = React.useCallback(() => {
    window.close()
  }, [])

  if (!tableId) return <></>
  return (
    <Providers>
      <View style={{ height: height || '100%' }}>
        <TableorderOrderAdditionalScreen
          shopId={shopId}
          tableId={tableId}
          astro={astro}
          subscribe={useSubscription}
          onPressClose={handlePressClose}
        />
      </View>
    </Providers>
  )
}

export default OrderAdditionalScreen
