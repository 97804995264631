import { Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, View } from 'react-native'

import type { WorkModelLabelProps } from './types'

export const WorkModelLabel: React.FC<WorkModelLabelProps> = ({ id = '', list, font = 'txt3Xs' }) => {
  return (
    <View style={styles.container}>
      {list.map((text, index) => (
        <View key={`${id}-${index}`} style={styles.label}>
          <Text fontName={font} fontColor="gray.g700">
            {text}
          </Text>
        </View>
      ))}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 6,
  },
  label: {
    backgroundColor: COLOR.gray.g050,
    marginRight: 4,
    marginBottom: 4,
    paddingHorizontal: 4,
    paddingVertical: 2,
    borderRadius: 4,
  },
})
