import { Button, Text } from '@rui/atoms'
import type { IconName } from '@rui/foundations'
import { COLOR, getBoxShadow, getRealColor } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Modal, Pressable, StyleSheet, View } from 'react-native'

export type MemberAddMenuItem = {
  icon: IconName
  title: string
  code: string
}

export type Props = {
  list: MemberAddMenuItem[]
  onPress: (code: string) => void
  onClose: () => void
  pageX: number | string
  pageY: number | string
}

const MemberAddMenu: React.FC<Props> = ({ list, pageX, pageY, onPress, onClose }) => {
  return (
    <Modal transparent>
      <Pressable style={styles.container} onPress={onClose} />
      <View
        style={[
          styles.backdrop,
          {
            top: Number(pageY),
            left: typeof pageX === 'number' ? pageX + 40 : Number(pageX),
          },
        ]}
      >
        {list.map((item) => (
          <Button
            key={`member-add-menu-${item.code}`}
            // backgroundColor="mono.white"
            // hoverStyle={{
            //   backgroundColor: getRealColor('gray.g050'),
            // }}
            style={[styles.item, { backgroundColor: COLOR.mono.white }]}
            onPress={() => onPress(item.code)}
            text={
              <>
                <XEIcon name={item.icon} size={16} color={'gray.g700'} />
                <Text fontName="txtSm" fontColor="gray.g700" style={styles.text}>
                  {item.title}
                </Text>
              </>
            }
          ></Button>
        ))}
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  backdrop: {
    position: 'absolute',
    backgroundColor: getRealColor('mono.white'),
    paddingVertical: 4,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: getRealColor('gray.g200'),
    ...getBoxShadow(0, 0, 0, 0.05, 7, 0, 0, 3),
  },
  item: {
    flexDirection: 'row',
    // width: 152,
    width: '100%',
    paddingHorizontal: 16,
    justifyContent: 'flex-start',
  },
  text: {
    marginLeft: 4,
  },
})

export default MemberAddMenu
