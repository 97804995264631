import * as React from 'react'
import { ScrollView, StyleSheet, useWindowDimensions, View } from 'react-native'

import { COLOR, getBoxShadow, useMobileView } from '@rocket-mono/libs'
import { Footer } from './footer'

interface Props {
  isValid: boolean
  step: number
  submitStep: number
  scrollEnabled: boolean
  onPressNext: () => void
  onPressBack: () => void
  onPressCreate: () => void
  previewElement: JSX.Element[] | JSX.Element
  keyboardHeight: number
  children: JSX.Element[] | JSX.Element
}
const LayoutView: React.FC<Props> = ({
  isValid,
  step,
  submitStep,
  scrollEnabled,
  onPressNext,
  onPressBack,
  onPressCreate,
  previewElement,
  keyboardHeight,
  children,
}) => {
  const mobileView = useMobileView(1000)
  const { height } = useWindowDimensions()

  return (
    <View style={[styles.flexBox, styles.contentsPanel, mobileView && styles.contentsPanelMobile]}>
      <View
        style={[
          styles.contentBox,
          { alignItems: 'center', marginRight: 24 },
          mobileView && styles.priviewContentBoxMobile,
        ]}
      >
        {previewElement}
      </View>
      <View
        style={[
          styles.contentBox,
          mobileView ? styles.entryContentBoxMobile : { marginRight: 70, flex: 1 },
          mobileView && {
            backgroundColor: COLOR.mono.white,
            position: 'absolute',
            bottom: keyboardHeight,
            width: '100%',
            maxHeight: height - keyboardHeight - 220,
          },
        ]}
      >
        <ScrollView
          style={mobileView && { paddingHorizontal: 36, maxHeight: 270 }}
          scrollEnabled={scrollEnabled}
          keyboardShouldPersistTaps={'handled'}
        >
          <View onStartShouldSetResponder={() => true}>
            {children}

            {mobileView ? (
              <Footer
                isValid={isValid}
                mobileView={mobileView}
                step={step}
                submitStep={submitStep}
                onPressNext={onPressNext}
                onPressBack={onPressBack}
                onPressCreate={onPressCreate}
              />
            ) : (
              <></>
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default LayoutView

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentsPanel: {
    minHeight: 400,
    flexDirection: 'row-reverse',
    alignContent: 'center',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingBottom: 40,
  },
  contentsPanelMobile: {
    flex: 1,
    minHeight: 'auto',
    flexDirection: 'column',
    paddingBottom: 0,
    borderBottomWidth: 0,
  },
  contentBox: {
    position: 'relative',
    justifyContent: 'flex-start',
  },
  priviewContentBoxMobile: {
    marginRight: 0,
    marginBottom: 24,
  },
  entryContentBoxMobile: {
    marginRight: 0,
    paddingTop: 28,
    paddingBottom: 16,
    backgroundColor: COLOR.mono.white,
    ...getBoxShadow(0, 0, 0, 0.05, 8, 0, -8, 5),
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
})
