import * as React from 'react'
import { Pressable, StyleSheet, View } from 'react-native'

import { SkeletonBar, SkeletonSquare } from '@rocket/skeleton'
import { Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { WorkIcon, XEIcon } from '@rui/icons'

export interface WorkListProps {
  isSkeleton?: boolean
  sectionKey: string
  title: string
  isFold: boolean
  isNoIcon: boolean
  typeCode?: string
  onPress?: (is: boolean) => void
  onPressSetting?: () => void
  onPressLeave?: () => void
}

const WorkList = ({ isSkeleton, title, isFold, typeCode, onPress, onPressSetting }: WorkListProps) => {
  const iconElement = React.useMemo(() => {
    const size = 18
    if (typeCode === 'CSP') return <WorkIcon.CSP size={size} />
    else if (typeCode === 'WFP') return <WorkIcon.WFP size={size} />
    else if (typeCode === 'DSP') return <WorkIcon.DSP size={size} />
    else return undefined
  }, [typeCode])

  return (
    <>
      <Pressable style={styles.container} onPress={() => onPress && onPress(!isFold)}>
        <View style={styles.workIcon}>
          {isSkeleton && (
            <View style={{ marginRight: 8 }}>
              <SkeletonSquare width={18} height={18} />
            </View>
          )}
          {!isSkeleton && !!iconElement && <View style={{ marginRight: 8 }}>{iconElement}</View>}
          <View style={styles.workTitle}>
            {isSkeleton ? (
              <SkeletonBar width={64} height={12} />
            ) : (
              <Text fontName="txtSmBold" style={styles.workName} numberOfLines={1}>
                {title}
              </Text>
            )}

            {!isSkeleton && !!onPressSetting && (
              <Pressable style={styles.buttonCog} onPress={onPressSetting}>
                <XEIcon name="cog" size={16} color={'gray.g300'} />
              </Pressable>
            )}
          </View>
        </View>
        <XEIcon name={isFold ? 'angle-down' : 'angle-up'} size={12} color={'gray.g300'} />
      </Pressable>
    </>
  )
}

export default WorkList

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 39,
    backgroundColor: 'rgba(255,255,255,255.9)',
    borderTopWidth: 1,
    borderColor: COLOR.gray.g100,
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  workIcon: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  workTitle: {
    flex: 1,
    flexDirection: 'row',
  },
  workName: {
    marginTop: 2,
  },
  buttonCog: {
    paddingHorizontal: 2,
    marginLeft: 4,
  },
})
