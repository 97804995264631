import * as React from 'react'

import { useMobileView } from '@rui/foundations'
import { ViewStyle } from 'react-native'
import { WorkSettingUserInfoProvider } from '../../components'
import MobileView from './mobile'
import { Provider } from './provider'
import { AstroType } from './types'
import WebView from './web'

interface Props {
  astro: AstroType
  profileFile?: File
  onProfile: () => void
  onLeave?: () => void
  onPressBack?: () => void
  onUploadFileDone?: () => void
  onNicknameDone?: () => void
  containerStyle?: ViewStyle
}
export const WorkSettingUserInfo: React.FC<Props> = ({
  astro,
  profileFile,
  onProfile,
  onLeave,
  onPressBack,
  onUploadFileDone,
  onNicknameDone,
  containerStyle,
}) => {
  const isMobile = useMobileView(960)
  return (
    <WorkSettingUserInfoProvider>
      <Provider>
        {isMobile ? (
          <MobileView
            astro={astro}
            profileFile={profileFile}
            onProfile={onProfile}
            onLeave={onLeave}
            onPressBack={onPressBack}
            onUploadFileDone={onUploadFileDone}
            onNicknameDone={onNicknameDone}
            containerStyle={containerStyle}
          />
        ) : (
          <WebView
            astro={astro}
            profileFile={profileFile}
            onProfile={onProfile}
            onLeave={onLeave}
            onPressBack={onPressBack}
            onUploadFileDone={onUploadFileDone}
            onNicknameDone={onNicknameDone}
            containerStyle={containerStyle}
          />
        )}
      </Provider>
    </WorkSettingUserInfoProvider>
  )
}
