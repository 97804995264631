import type { IconName } from '@rui/foundations'
import { COLOR, FONT, getBoxShadow, useMobileView } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import ButtonIcon from '../ButtonIcon'
import TableOrderTab, { TableOrderTabProps } from '../TableOrderTab'

interface Props {
  storeName: string
  tableName: string
  iconName: IconName
  onPress?: () => void
  tabList?: TableOrderTabProps[]
  tabCode: string
  tabOnCode?: (code: string) => void
}

const TableorderHeader: React.FC<Props> = ({
  storeName,
  tableName,
  iconName,
  onPress,
  tabList,
  tabCode,
  tabOnCode,
}) => {
  const isMobileView = useMobileView(800)
  return (
    <View style={styles.container}>
      <View style={styles.tableInfo}>
        <View style={[styles.flexbox, isMobileView && styles.flexboxMobile]}>
          {isMobileView ? (
            <>
              <Text style={[styles.storeName, isMobileView && styles.storeNameMobileView]}>{storeName}</Text>
              <View style={styles.mobileTableName}>
                <Text style={{ ...FONT.txtMdMedium }}>테이블</Text>
                <View style={styles.divLine}></View>
                <Text style={[isMobileView && styles.tableNameMobileView]}>{tableName}</Text>
              </View>
            </>
          ) : (
            <>
              <Text style={styles.tableName}>{tableName}</Text>
              <Text style={[styles.storeName, isMobileView && styles.storeNameMobileView]}>{storeName}</Text>
            </>
          )}
        </View>
        <ButtonIcon iconName={iconName} iconSize={20} iconColor={'gray.g700'} onPress={onPress} />
      </View>
      {tabList && tabList.length > 0 ? (
        <View>
          <TableOrderTab list={tabList} code={tabCode} onCode={tabOnCode} />
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

export default TableorderHeader

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
    backgroundColor: COLOR.mono.white,
    paddingVertical: 16,
    paddingLeft: 20,
    ...getBoxShadow(0, 0, 0, 0.02, 4, 0, 4, 5),
  },
  tableInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  flexbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flexboxMobile: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  tableName: {
    ...FONT.H5Bold,
  },
  storeName: {
    ...FONT.txtMdMedium,
    marginLeft: 8,
  },
  mobileTableName: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableNameMobileView: {
    ...FONT.txtMd,
    color: COLOR.gray.g700,
  },
  storeNameMobileView: {
    ...FONT.H6Bold,
    marginLeft: 0,
  },
  divLine: {
    width: 1,
    height: 12,
    backgroundColor: COLOR.gray.g200,
    marginHorizontal: 8,
  },
})
