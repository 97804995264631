import { Project, ProjectPositionCreation } from '@rocket/types'
import { i18n } from 'i18next'
import React from 'react'

import Providers from '../../providers'
import CreateWorkView from './view'

interface Props {
  i18n: i18n
  typeCode: string
  position?: Omit<ProjectPositionCreation, 'projectId'>
  onPressBack: () => void
  onSaveSuccess: (project: Project) => void
  onSaveFailed: (message: string) => void
}

const CreateWorkScreen: React.FC<Props> = ({ i18n, ...props }) => {
  return (
    <Providers i18n={i18n}>
      <CreateWorkView {...props} />
    </Providers>
  )
}

export default CreateWorkScreen
