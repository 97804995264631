import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { styleTableCard } from './BoardTableCard'

import type { TableCardProps } from './TableCard'
interface Props {
  item: TableCardProps
  width?: number
  height?: number
  onPress?: () => void
}

const EditedTableCard: React.FC<Props> = ({ item, width, height, onPress }) => {
  return (
    <Pressable
      style={[
        styleTableCard.cardContainer,
        styles.container,
        item.state === 'use' && styles.containerUsing,
        { width },
        { height },
      ]}
      onPress={onPress}
    >
      <View>
        <Text style={styleTableCard.infoText}>{item.capacity}인석</Text>
        <Text
          style={[
            styleTableCard.nameText,
            {
              color: COLOR.mono.black,
            },
          ]}
        >
          {item.name}
        </Text>
      </View>
    </Pressable>
  )
}

export default EditedTableCard

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.mono.white,
    borderColor: COLOR.mono.gray,
  },
  containerUsing: {
    backgroundColor: COLOR.mono.lightGray,
  },
})
