import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import type { IColors } from '@rui/foundations'
import { getRealColor } from '@rui/foundations'
import { WorkIcon } from '@rui/icons'
import * as React from 'react'
import { StyleSheet } from 'react-native'

const ChatLabel = ({ labelName, labelCode }: { labelName: string; labelCode?: string }) => {
  const iconElement = React.useMemo(() => {
    const size = 12
    if (labelCode === 'CSP') return <WorkIcon.CSP size={size} />
    else if (labelCode === 'WFP') return <WorkIcon.WFP size={size} />
    else if (labelCode === 'DSP') return <WorkIcon.DSP size={size} />
    else return undefined
  }, [labelCode])

  const labelColor = React.useMemo(() => {
    if (labelCode === 'CSP') return 'main.blue'
    else if (labelCode === 'WFP') return 'main.red'
    else if (labelCode === 'DSP') return 'main.navy'
    else return 'main.navy'
  }, [labelCode])

  const colorFillter = (color: IColors) => {
    if (color === 'main.navy' || color === 'main.red' || color === 'main.blue') {
      return 'mono.white'
    } else {
      return 'gray.g900'
    }
  }
  return (
    <View style={styles.container}>
      <View style={[styles.innerContainer, iconElement ? { paddingRight: 2 } : { paddingRight: 1 }]}>
        {!!iconElement && (
          <View style={[styles.iconLabel, { backgroundColor: labelColor && getRealColor(labelColor) }]}>
            {iconElement}
          </View>
        )}

        <Text
          fontName="txt2Xs"
          fontColor="gray.g900"
          style={[styles.name, { color: labelColor && getRealColor(colorFillter(labelColor)) }]}
        >
          {labelName}
        </Text>
        <View style={[styles.labelBack, { backgroundColor: labelColor && getRealColor(labelColor) }]}></View>
      </View>
    </View>
  )
}

export default ChatLabel

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    flexDirection: 'row',
  },
  labelBack: {
    position: 'absolute',
    width: '100%',
    height: 20,
    borderRadius: 4,
    opacity: 0.75,
  },
  innerContainer: {
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconLabel: {
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    marginLeft: 2,
    zIndex: 10,
  },
  name: {
    zIndex: 10,
    marginHorizontal: 2,
  },
})
