import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import * as React from 'react'
import { Dropdown } from '../../../foundations'

import { useForm } from '@rocket-mono/libs'
import { TableorderMenuOptionGroupType } from '@rocket/types'
import type { IColors } from '@rui/foundations'
import { COLOR, getRealColor, removeItemAtIndex } from '@rui/foundations'
import { ScrollView, StyleSheet, TextInput } from 'react-native'
import BarButton from '../../Button/BarButton'
import ButtonIcon from '../../ButtonIcon'
import { MenuFormType, useTableorderFile, useTableorderMenu, useTableorderMenuLabel } from '../../providers'
import MenuLabel from './MenuLabel'
import { TableorderMenuDetailFileList } from './TableorderMenuDetailFileList'
import TableorderMenuDetailInputGroup from './TableorderMenuDetailInputGroup'
import TableorderMenuDetailOptionGroup from './TableorderMenuDetailOptionGroup'

type MenuFormValidationProps = {
  name?: string
  price?: string
}
interface Props {
  menuForm: MenuFormType
  onPressLabel: () => void
  onPressOption: (groupType?: TableorderMenuOptionGroupType) => void
}

export const TableorderMenuDetailView: React.FC<Props> = ({ menuForm, onPressLabel, onPressOption }) => {
  const { fileList, changeFileList } = useTableorderFile()
  const { categoryList, createMenu, updateMenu, deleteMenu, hideMenuDetail } = useTableorderMenu()
  const { menuLabelList } = useTableorderMenuLabel()

  const { values, handleChange, handleSubmit } = useForm<MenuFormType, MenuFormValidationProps>({
    initialValues: menuForm,
    onSubmit: (values: MenuFormType) => {
      return new Promise((resolve) => {
        const { id, price, estimatedCookingMinute } = values
        if (!id) {
          createMenu({
            ...values,
            price: price || 0,
            estimatedCookingMinute: estimatedCookingMinute || 0,
            images: fileList.map(({ id, filePath: path }, sequenceNumber) => {
              return {
                id: id || '',
                path,
                sequenceNumber,
                isRepresentative: sequenceNumber === 0,
              }
            }),
          }).then((menu) => resolve(menu.id))
        } else {
          updateMenu(id, {
            id,
            ...values,
            price: price || 0,
            estimatedCookingMinute: estimatedCookingMinute || 0,
            images: fileList.map(({ id, filePath: path }, sequenceNumber) => {
              return {
                id: id || '',
                path,
                sequenceNumber,
                isRepresentative: sequenceNumber === 0,
              }
            }),
            deleteRequestImages: values.images.filter(
              ({ id }) =>
                !fileList
                  .filter(({ id }) => !!id)
                  .map(({ id }) => id)
                  .includes(id),
            ),
          }).then((menu) => resolve(menu.id))
        }
      })
    },
    validate: ({ name, price }: MenuFormType) => {
      const errors: MenuFormValidationProps = {}
      if (!name) {
        errors.name = '메뉴명은 필수 입력사항입니다.'
      }

      if (!price || Number.isNaN(price)) {
        errors.price = '메뉴 가격은 필수 입력사항입니다.'
      }
      return errors
    },
  })

  const selectCategory = React.useMemo(
    () =>
      categoryList?.map(({ id: key, name: label }) => ({
        key,
        label,
      })),
    [categoryList],
  )
  console.log('values', values)

  React.useEffect(() => {
    if (menuForm.images.length) {
      changeFileList(
        menuForm.images
          .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
          .map(({ id, path: filePath }) => ({
            id,
            name: id,
            filePath,
          })),
      )
    }
  }, [menuForm.images])

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.flex} fontName="textBold" fontColor="mono.black">
          메뉴 상세
        </Text>
        <View style={styles.closeIcon}>
          <ButtonIcon iconName="close" iconSize={20} containerSize={24} onPress={hideMenuDetail} />
        </View>
      </View>
      <ScrollView style={styles.flex}>
        <TableorderMenuDetailInputGroup title="메뉴 카테고리" containerStyle={styles.categoryContainer}>
          {selectCategory !== undefined ? (
            <Dropdown
              list={selectCategory}
              label={selectCategory.find(({ key }) => key === values.categoryId)?.label || '선택해주세요.'}
              onSelect={(item) => {
                handleChange('categoryId', item.key)
              }}
              defaultIndex={selectCategory.findIndex(({ key }) => key === values.categoryId)}
            />
          ) : (
            <></>
          )}
        </TableorderMenuDetailInputGroup>
        <TableorderMenuDetailInputGroup title="메뉴명" required>
          <View style={styles.inputContainer}>
            <View style={styles.inputBox}>
              <TextInput
                style={[styles.input]}
                value={values.name}
                onChangeText={(text) => handleChange('name', text)}
                placeholder="입력하세요."
                placeholderTextColor={COLOR.mono.gray}
              />
            </View>
          </View>
        </TableorderMenuDetailInputGroup>
        <TableorderMenuDetailInputGroup title="판매 가격" required>
          <>
            <View style={styles.inputContainer}>
              <View style={styles.inputBox}>
                <TextInput
                  style={[styles.input]}
                  value={values.price !== undefined ? String(values.price) : ''}
                  onChangeText={(text) => {
                    if (!isNaN(Number(text))) handleChange('price', Number(text))
                  }}
                  placeholder="입력하세요."
                  placeholderTextColor={COLOR.mono.gray}
                />
              </View>
              <Text style={styles.inputLabel} fontName="textMedium" fontColor="mono.paleBlack">
                원
              </Text>
            </View>
          </>
        </TableorderMenuDetailInputGroup>
        <TableorderMenuDetailInputGroup title="메뉴 이미지">
          <View>
            <TableorderMenuDetailFileList />
          </View>
        </TableorderMenuDetailInputGroup>

        <TableorderMenuDetailInputGroup title="메뉴 설명">
          <View style={[styles.inputContainer]}>
            <View style={[styles.inputBox, styles.inputTextareaBox]}>
              <TextInput
                multiline
                style={[styles.input, styles.inputTextarea]}
                value={values.description}
                onChangeText={(text) => handleChange('description', text)}
                placeholder="입력하세요."
                placeholderTextColor={COLOR.mono.gray}
              />
            </View>
          </View>
        </TableorderMenuDetailInputGroup>
        <TableorderMenuDetailInputGroup title="추가 정보" containerStyle={styles.etcContainer}>
          <View>
            <View style={styles.inputContainer}>
              <Text style={styles.inputLeftLabel} fontName="textRegular" fontColor="mono.black">
                예상 조리시간(분)
              </Text>
              <View style={styles.inputBox}>
                <TextInput
                  style={[styles.input]}
                  value={values.estimatedCookingMinute !== undefined ? String(values.estimatedCookingMinute) : ''}
                  onChangeText={(text) => handleChange('estimatedCookingMinute', Number(text))}
                  placeholder="입력하세요."
                  placeholderTextColor={COLOR.mono.gray}
                />
              </View>
            </View>
            <View style={[styles.inputContainer, { marginTop: 20 }]}>
              <Text style={styles.flex} fontName="textRegular" fontColor="mono.black">
                표시할 라벨
              </Text>
              <ButtonIcon iconName="cog" iconColor="mono.paleBlack" iconSize={17} onPress={onPressLabel} />
            </View>
            <View style={styles.inputContainer}>
              {menuLabelList?.map(({ id, name, color }) => {
                return (
                  <MenuLabel
                    key={id}
                    containerStyle={styles.menuLabelContainer}
                    color={getRealColor(color as IColors)}
                    label={name}
                    active={values.labelIds.includes(id)}
                    onActive={() => {
                      const { labelIds } = values
                      const idx = labelIds.findIndex((o: string) => o === id)
                      handleChange('labelIds', idx < 0 ? [...labelIds, id] : removeItemAtIndex(labelIds, idx))
                    }}
                  />
                )
              })}
            </View>
            <View style={[styles.inputContainer, { marginTop: 20 }]}>
              <Text style={styles.flex} fontName="textRegular" fontColor="mono.black">
                메뉴 옵션 설정
              </Text>
              <ButtonIcon iconName="cog" iconColor="mono.paleBlack" iconSize={17} onPress={() => onPressOption()} />
            </View>
            <TableorderMenuDetailOptionGroup
              type="REQUIRED"
              title="필수 옵션"
              optionIds={values.optionGroupIds}
              handleChange={handleChange}
              onPressOption={onPressOption}
            />
            <TableorderMenuDetailOptionGroup
              type="OPTIONAL"
              title="선택 옵션"
              optionIds={values.optionGroupIds}
              handleChange={handleChange}
              onPressOption={onPressOption}
            />
          </View>
        </TableorderMenuDetailInputGroup>
        {!!values.id && (
          <View style={styles.deleteButtonContainer}>
            <BarButton
              contanerStyle={styles.deleteButton}
              title="메뉴 삭제"
              action={() => !!values.id && deleteMenu(values.id)}
            />
          </View>
        )}
      </ScrollView>
      <View style={styles.saveButtonContainer}>
        <BarButton callToAction title="메뉴 저장" action={handleSubmit} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  container: { flex: 1 },
  header: {
    height: 64,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 20,
    marginRight: 16,
  },
  closeIcon: { width: 24, height: 24 },
  categoryContainer: { marginTop: 0 },
  etcContainer: {
    paddingTop: 20,
    borderTopWidth: 1,
    borderTopColor: COLOR.mono.gray,
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: 34,
    alignItems: 'center',
  },
  inputBox: {
    flex: 1,
    height: 34,
    marginRight: 4,
    borderWidth: 1,
    borderColor: COLOR.mono.gray,
  },
  inputTextareaBox: { height: 180 },
  input: {
    flex: 1,
    height: 34,
    paddingHorizontal: 12,
  },
  inputLeftLabel: { marginRight: 20 },
  inputLabel: { width: 20 },
  inputTextarea: { height: 180, paddingTop: 18 },
  saveButtonContainer: {
    height: 50,
  },
  deleteButtonContainer: {
    margin: 20,
    height: 36,
    borderRadius: 4,
  },
  deleteButton: { borderRadius: 4 },
  menuLabelContainer: { marginRight: 4 },
})
