import { Avatar } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Props } from './types'

const SettingProfile: React.FC<Props> = ({ data }) => {
  return (
    <View>
      <View style={styles.innerContainer}>
        <View style={[styles.profile]}>
          <Avatar profileUrl={data.profile} userName={data.name} size="xlarge" />
        </View>
        <View>
          <View style={styles.userInfo}>
            <Text style={styles.userName}>{data.name}</Text>
            <Text style={styles.userEmail}>{data.email}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

export default SettingProfile

const styles = StyleSheet.create({
  innerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  profile: {
    marginBottom: 8,
    justifyContent: 'center',
  },
  userInfo: {
    marginBottom: 12,
    alignItems: 'center',
  },
  userName: {
    ...FONT.H7Medium,
  },
  userEmail: {
    ...FONT.txtXs,
    color: COLOR.gray.g600,
  },
})
