import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import * as React from 'react'
import type { ViewStyle } from 'react-native'

interface Props {
  title?: string
  required?: boolean
  containerStyle?: ViewStyle
  children: JSX.Element
}

const TableorderMenuDetailInputGroup: React.FC<Props> = ({ title, required = false, containerStyle, children }) => {
  return (
    <View style={[containerStyle, { marginHorizontal: 20, marginTop: 20 }]}>
      <Text fontName="textMedium" fontColor="mono.paleBlack">
        {title}
        {required && (
          <Text fontName="textBold" fontColor="main.red">
            *
          </Text>
        )}
      </Text>
      <View style={{ marginTop: 4 }}>
        {children}
        {/* {Array.isArray(children) ? (
          children.map((el, index) => (
            <View
              key={`view-${index}`}
              style={{
                flex: 1,
                marginTop: 4,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {el}
            </View>
          ))
        ) : (
          <View
            style={{
              flex: 1,
              marginTop: 4,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {children}
          </View>
        )} */}
      </View>
    </View>
  )
}

export default TableorderMenuDetailInputGroup
