import { WorkmodelUpdateView } from '@rocket-screens/workmodel'
import * as React from 'react'
import { useWindowDimensions } from 'react-native'

import { useAstro } from '@rocket-mono/providers'

import { View } from '@rocket-mono/foundations'
import { COLOR } from '@rocket-mono/libs'
import { SkeletonEditWorkModal } from '@rocket/skeleton'
import { useTranslation } from 'react-i18next'

interface Props {
  projectId: string
  onPressClose: () => void
  onBack?: () => void
  onSuccess: (projectId: string) => void
  goHome?: () => void
}

export const WorkmodelEditView: React.FC<Props> = ({ projectId, onPressClose, onBack, onSuccess, goHome }) => {
  const { t } = useTranslation()
  const { astro } = useAstro()
  const { width } = useWindowDimensions()

  return (
    <View
      style={[
        { width: 560, backgroundColor: COLOR.mono.white, borderRadius: 8 },
        560 > width && { width: '100%', minWidth: '100%' },
      ]}
    >
      <WorkmodelUpdateView
        astro={astro}
        // containerStyle={{ width: '100%' }}
        // style={{ width: '100%' }}
        id={projectId}
        onSuccess={onSuccess}
        onPressClose={onPressClose}
        onBack={onBack}
        goHome={goHome}
        skeletonComponent={
          <View style={{ width: 530, height: 680 }}>
            <SkeletonEditWorkModal />
          </View>
        }
      />
    </View>
  )
}
