import { View } from '@rocket-mono/foundations'
import { COLOR, replaceItemAtIndex } from '@rui/foundations'
import * as React from 'react'
import { Modal } from 'react-native'
import { Context } from './context'
import type { ProviderProps } from './types'

export const Provider = ({ children }: ProviderProps) => {
  const [visibleList, setVisibleList] = React.useState<{ id: string; visible: boolean }[]>([])
  const visible = React.useMemo(() => visibleList.filter((o) => o.visible).length > 0, [visibleList])

  const isOpen = React.useCallback(
    (id: string) => {
      return visibleList.find((o) => o.id === id)?.visible || false
    },
    [visibleList],
  )

  const setIsOpen = React.useCallback((id: string, visible: boolean) => {
    setVisibleList((prev) => {
      const idx = prev.findIndex((o) => o.id === id)
      return idx < 0 ? [...prev, { id, visible }] : replaceItemAtIndex(prev, idx, { id, visible })
    })
  }, [])

  return (
    <Context.Provider value={{ isOpen, setIsOpen }}>
      <Modal visible={visible} transparent animationType="none">
        <View
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: COLOR.mono.black,
            opacity: 0.7,
          }}
        />
      </Modal>
      {children}
    </Context.Provider>
  )
}
