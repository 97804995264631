import * as React from 'react'

import { COLOR, FONT } from '@rui/foundations'
import { StyleSheet, Text, View } from 'react-native'

interface Props {
  item: { name: string; price: string }
  idx: number
}

const OrderMenuOptionItem: React.FC<Props> = ({ item }) => {
  return (
    <View>
      <View style={styles.flexBox}>
        <Text style={styles.optionText}>{item.name}</Text>
        <Text style={styles.optionText}>+{item.price}</Text>
      </View>
    </View>
  )
}

export default OrderMenuOptionItem

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  optionText: {
    ...FONT.txtXs,
    color: COLOR.gray.g600,
  },
})
