import { Tooltip } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, View } from 'react-native'
import { BoardFilterType } from '../WorkBoardScreen'
import { AllIcon, DoneIcon, ProgressIcon } from './icons'

interface Props {
  filterCode: BoardFilterType
  progressCount?: number | null
  doneCount?: number | null
  onChangeFilterCode: (code: BoardFilterType) => void
}

const CountView = ({ count, selected }: { count?: number | null; selected: boolean }) => {
  if (count === null || count === undefined || count === 0) return <></>
  return (
    <View style={[styles.countContainer, selected ? styles.selectedContainer : styles.unselectedContainer]}>
      <Text style={[styles.countText, selected ? styles.selectedText : styles.unselectedText]}>{count}</Text>
    </View>
  )
}

const WorkBoardCardFilter: React.FC<Props> = ({ filterCode, progressCount, doneCount, onChangeFilterCode }) => {
  const { t } = useTranslation()
  return (
    <View style={styles.rootContainer}>
      <View style={styles.container}>
        <Tooltip
          text={t('screen.board.filter.all')}
          direction="top"
          align="center"
          // darkType
          onPress={() => onChangeFilterCode('ALL')}
          zIndex={1}
        >
          <View
            style={[
              styles.button,
              {
                backgroundColor: filterCode === 'ALL' ? COLOR.main.blue : undefined,
              },
            ]}
          >
            <AllIcon borderColor={filterCode === 'ALL' ? COLOR.mono.white : undefined} />
          </View>
        </Tooltip>
        <View style={styles.line} />
        <Tooltip
          text={t('screen.board.filter.progress')}
          direction="top"
          align="center"
          // darkType
          onPress={() => onChangeFilterCode('PROGRESS')}
          zIndex={1}
        >
          <View
            style={[
              styles.buttonContainer,
              {
                backgroundColor: filterCode === 'PROGRESS' ? COLOR.main.blue : undefined,
              },
            ]}
          >
            <View style={[styles.button]}>
              <ProgressIcon borderColor={filterCode === 'PROGRESS' ? COLOR.mono.white : undefined} />
            </View>
            <CountView count={progressCount} selected={filterCode === 'PROGRESS'} />
          </View>
        </Tooltip>
        <View style={styles.line} />
        <Tooltip
          text={t('screen.board.filter.done')}
          direction="top"
          align="center"
          // darkType
          onPress={() => onChangeFilterCode('DONE')}
          zIndex={1}
        >
          <View
            style={[
              styles.buttonContainer,
              {
                backgroundColor: filterCode === 'DONE' ? COLOR.main.blue : undefined,
              },
            ]}
          >
            <View style={[styles.button]}>
              <DoneIcon borderColor={filterCode === 'DONE' ? COLOR.mono.white : undefined} />
            </View>
            <CountView count={doneCount} selected={filterCode === 'DONE'} />
          </View>
        </Tooltip>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  rootContainer: {
    flexDirection: 'row',
  },
  container: {
    height: 36,
    backgroundColor: COLOR.gray.g050,
    padding: 3,
    borderRadius: 4,
    flexDirection: 'row',
  },
  buttonContainer: {
    borderRadius: 4,
    flexDirection: 'row',
  },
  button: {
    width: 30,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  line: {
    width: 4,
  },
  countContainer: {
    borderRadius: 99,
    height: 16,
    paddingHorizontal: 4,
    marginLeft: -1,
    marginRight: 4,
    marginVertical: 7,
  },
  unselectedContainer: {
    backgroundColor: COLOR.gray.g150,
  },
  selectedContainer: {
    backgroundColor: 'rgba(0,0,0, 0.5)',
  },
  countText: {
    ...FONT.txt2XsBold,
  },
  unselectedText: {
    color: COLOR.gray.g450,
  },
  selectedText: {
    color: COLOR.gray.g200,
  },
})

export default WorkBoardCardFilter
