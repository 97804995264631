import type { TableorderOrder } from '@rocket/types'
import * as React from 'react'
import { useTableorderOrder } from '../providers'

import moment from 'moment'
import Context from './context'
import type { ProviderProps } from './types'

const Provider = ({ children }: ProviderProps) => {
  const { orderList, updateOrderStatus } = useTableorderOrder()
  const [orderItem, setOrderItem] = React.useState<TableorderOrder>()

  const onPressOrder = React.useCallback(
    (id: string) => {
      console.log('onPressOrder', id)
      const order = orderList?.find((o) => o.id === id)
      setOrderItem(order)
    },
    [orderList],
  )

  const onPressClose = React.useCallback(() => {
    setOrderItem(undefined)
  }, [])

  const onPressOrderCancel = React.useCallback(
    (id: string) => {
      updateOrderStatus(id, 'CANCELED').then(() => setOrderItem(undefined))
    },
    [orderList],
  )

  const onPressOrderStart = React.useCallback(
    (id: string, minute: number) => {
      const estimatedCookingCompletionAt = moment().add(minute, 'minutes').toDate()
      console.log('estimatedCookingCompletionAt', estimatedCookingCompletionAt)
      updateOrderStatus(id, 'COOKING', estimatedCookingCompletionAt).then(() => setOrderItem(undefined))
    },
    [orderList],
  )

  return (
    <Context.Provider
      value={{
        orderItem,
        onPressClose,
        onPressOrder,
        onPressOrderCancel,
        onPressOrderStart,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default Provider
