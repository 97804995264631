import React from 'react'
import { PageContext } from './context'
import type { PageState } from './types'

interface Props {
  children: React.ReactNode
  size?: number
  page?: number
  count?: number
}

export const PageProvider: React.FC<Props> = (props: Props) => {
  const [current, setCurrent] = React.useState(typeof props.page === 'number' ? props.page : 1)
  const [count] = React.useState(props.count || 0)
  const [size, setSize] = React.useState(props.size || 5)
  const [state, setState] = React.useState<PageState>('idle')

  const navigate = React.useCallback(
    (page: number) => {
      if (page < 1) {
        page = 1
      } else if (page > count / size) {
        page = Math.ceil(count / size)
      }
      setCurrent(page)
    },
    [size, count],
  )

  const onState = React.useCallback((state: PageState) => {
    setState(state)
  }, [])

  React.useEffect(() => {
    // props size change
    if (props.size && props.size !== size) {
      setSize(props.size)
    }
  }, [props.size, size])

  return (
    <PageContext.Provider
      value={{
        current,
        total: count,
        size,
        state,
        navigate,
        onState,
      }}
    >
      {props.children}
    </PageContext.Provider>
  )
}
