import React from 'react'
import { Trans } from 'react-i18next'
import { Text } from 'react-native'

export interface Props {
  userName: string
  projectName: string
}

interface TextProps {
  children?: React.ReactChild[] | React.ReactChild
}

const BlueBoldText = ({ children }: TextProps): JSX.Element => (
  <Text style={{ color: '#007aff', fontWeight: 'bold' }}>{children}</Text>
)

const BlackBoldText = ({ children }: TextProps): JSX.Element => (
  <Text style={{ color: '#000', fontWeight: 'bold' }}>{children}</Text>
)

/*
 * 알림 메세지 유형별로 렌더링해야 할 컴포넌트를 나열했습니다.
 * 알림 메세지 유형은 다음과 같습니다.
 * 참여 요쳥|거절|수락, 소유권 이전 요청|거절|수락, 나가기 요청|거절|수락, 나가기 알림
 * 새 유형이 있으면 여기에 추가해주세요.
 */

export const NOTICE_MESSAGE_COMPONENTS = {
  REQUEST_ATTEND: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notirequest.attend"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  REQUEST_TRANSFER_OWNERSHIP: ({
    userName,
    projectName,
  }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notirequest.transfer"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  REQUEST_JOIN: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notirequest.join"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  REQUEST_LEAVE: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notirequest.leave"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  APPROVE_ATTEND: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notiaccept.attend"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  APPROVE_TRANSFER_OWNERSHIP: ({
    userName,
    projectName,
  }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notiaccept.transfer"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  APPROVE_LEAVE: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notiaccept.leave"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  APPROVE_LEAVE_SELF: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notibot.leave"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  APPROVE_JOIN: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notiacceptjoin.leave"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  DENY_ATTEND: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notidecline.attend"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  DENY_ATTEND_2: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notidecline.attend2"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  DENY_TRANSFER_OWNERSHIP: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notidecline.transfer"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  DENY_LEAVE: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notidecline.leave"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  DENY_JOIN: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notideclinejoin.leave"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
  NOTIFY_LEAVE: ({ userName, projectName }: Props): JSX.Element => (
    <Trans
      i18nKey="notification.notibot.leave"
      values={{ userName, projectName }}
      components={{
        p: <Text />,
        bluebold: <BlueBoldText />,
        blackbold: <BlackBoldText />,
      }}
    />
  ),
}
