import { View } from '@rocket-mono/foundations'
import { Avatar, Button, Text } from '@rui/atoms'
import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { StyleSheet } from 'react-native'
import { useInterval } from '../hooks'
import { NOTICE_MESSAGE_COMPONENTS } from '../Notification/legacy/Message'
import UnreadDot from '../UnreadDot'
import { relativeTime } from '../utils'

type NOTICE_MESSAGE_TYPE = keyof typeof NOTICE_MESSAGE_COMPONENTS

export interface NotificationItemProps {
  id: string
  profileUrl: string
  profileAlt: string
  type: NOTICE_MESSAGE_TYPE
  user: string
  work: string
  time: Date
  newUnread: boolean
  period?: Date
  isExpired: boolean
  onAccept?: () => void
  onReject?: () => void
  onDismiss?: () => void
}

const Datetime: React.FC<{ date: Date }> = ({ date }) => {
  const { t } = useTranslation()
  return <Text>{t('format.date.LLL', { date })}</Text>
}

const NotificationItem = ({
  profileUrl,
  profileAlt,
  user,
  work,
  type,
  newUnread,
  time,
  period,
  isExpired,
  onAccept,
  onReject,
  onDismiss,
}: NotificationItemProps) => {
  const content = () => {
    const Message = NOTICE_MESSAGE_COMPONENTS[type]
    return <Message userName={user} projectName={work} />
  }
  const { t, i18n } = useTranslation()

  const [tick, setTick] = React.useState<{ id: number; time: Date }>({
    id: 0,
    time,
  })
  useInterval(() => {
    setTick((prev) => ({ id: prev.id++, time }))
  }, 1000)

  return (
    <View style={styles.container}>
      <View style={styles.contents}>
        <View style={styles.profile}>
          <Avatar size="medium" profileUrl={profileUrl} userName={profileAlt} />
          {newUnread && (
            <View style={styles.unread}>
              <UnreadDot />
            </View>
          )}
        </View>
        <View style={styles.message}>
          <Text style={styles.txtStyle}>{content()}</Text>
          <View style={styles.meta}>
            <Text style={styles.txtMeta}>
              <>
                <Text>{relativeTime(tick.time, i18n.language)}</Text>
                {/* <View style={styles.divLine}></View> */}
                <Text fontColor="gray.g200">|</Text>
                {period &&
                  (isExpired ? (
                    <Trans
                      i18nKey="notification.expiration"
                      values={{
                        date: t('format.date.LLL', { date: period }),
                      }}
                      components={{ datetime: <Datetime date={period} /> }}
                    />
                  ) : (
                    <Text style={styles.txtMeta}>
                      {t('notification.validuntil')} {t('format.date.LLL', { date: period })}
                    </Text>
                  ))}
              </>
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.buttonArea}>
        {isExpired === false &&
        ['REQUEST_ATTEND', 'REQUEST_TRANSFER_OWNERSHIP', 'REQUEST_LEAVE', 'REQUEST_JOIN'].indexOf(type) > -1 ? (
          <>
            <View style={{ marginRight: 4 }}>
              <Button
                // borderColor="gray.g300"
                // backgroundColor="mono.white"
                size="sm"
                onPress={onReject}
                style={{
                  paddingHorizontal: 8,
                  backgroundColor: COLOR.mono.white,
                  borderColor: COLOR.gray.g300,
                  borderWidth: 1,
                }}
                text={<Text fontColor="gray.g700">{t('notification.decline')}</Text>}
              />
            </View>
            <View>
              <Button
                size="sm"
                onPress={onAccept}
                style={{ paddingHorizontal: 8 }}
                text={<Text style={{ color: COLOR.mono.white }}>{t('notification.accept')}</Text>}
              />
            </View>
          </>
        ) : (
          <>
            <View>
              <Button
                size="sm"
                // borderColor="gray.g300"
                // backgroundColor="mono.white"
                onPress={() => {
                  onDismiss?.()
                  console.debug('item.onDismiss', {
                    onDismiss,
                  })
                }}
                style={{
                  paddingHorizontal: 8,
                  backgroundColor: COLOR.mono.white,
                  borderColor: COLOR.gray.g300,
                  borderWidth: 1,
                }}
                text={<Text>{t('notification.read')}</Text>}
              />
            </View>
          </>
        )}
      </View>
    </View>
  )
}

export default NotificationItem

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: COLOR.gray.g150,
    borderRadius: 8,
    padding: 12,
    marginBottom: 8,
  },
  contents: {
    flexDirection: 'row',
  },
  profile: {
    width: 48,
  },
  unread: {
    position: 'absolute',
    top: 0,
    right: 6,
  },
  message: {
    flex: 1,
  },
  meta: {
    flexDirection: 'row',
    paddingVertical: 4,
  },
  txtStyle: {
    ...FONT.txtSm,
    color: COLOR.gray.g600,
  },
  txtStrong: {
    ...FONT.txtSmMedium,
    color: COLOR.mono.black,
  },
  txtType: {
    color: COLOR.primary.blue700,
  },
  txtMeta: {
    ...FONT.txtXs,
    color: COLOR.gray.g450,
  },
  divLine: {
    width: 1,
    height: 10,
    backgroundColor: COLOR.gray.g200,
    marginTop: 3,
    marginHorizontal: 4,
  },
  buttonArea: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
})
