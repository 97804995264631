import type { IColors } from '@rui/foundations'
import { COLOR, FONT, getRealColor } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native'
import { TopTabItem } from '.'

interface Props {
  code?: string
  onCode?: (code: string) => void
  eaColor?: IColors
  item: TopTabItem
  itemFlexAuto?: boolean
  pressCode?: () => string
  forceChangeCount?: number
  style?: ViewStyle
  selected: string
  setSelected: (arg0: string) => void
}

const TabItem = ({
  code,
  onCode,
  pressCode,
  eaColor,
  item,
  itemFlexAuto,
  selected,
  setSelected,
  forceChangeCount = 0,
}: Props) => {
  const [isHovering, setIsHovering] = React.useState(false)

  React.useEffect(() => {
    if (forceChangeCount > 0 && pressCode) {
      setSelected(pressCode())
    }
  }, [forceChangeCount, pressCode])

  React.useEffect(() => {
    if (code !== undefined) setSelected(code)
  }, [code])

  return (
    <Pressable
      onHoverIn={() => setIsHovering(true)}
      onHoverOut={() => setIsHovering(false)}
      style={[
        styles.buttonStyle,
        itemFlexAuto && { flexBasis: 'auto' },
        {
          borderColor: selected === item.code ? COLOR.mono.black : COLOR.transparent.base,
        },
      ]}
      onPress={() => {
        if (item.isShow) {
          setSelected(item.code)
          if (onCode) {
            onCode(item.code)
          }
        }
      }}
    >
      <View
        style={[
          styles.itemStyle,
          {
            backgroundColor: isHovering ? COLOR.gray.g050 : COLOR.mono.white,
          },
          { opacity: item.isShow ? 1 : 0 },
        ]}
      >
        {item.icon && (
          <View style={[styles.itemIcon, { opacity: item.isShow ? 1 : 0 }]}>
            <XEIcon name={item.icon} color={selected === item.code ? 'mono.black' : 'mono.paleBlack'} size="16" />
          </View>
        )}
        <Text
          style={[
            {
              color: selected === item.code ? COLOR.mono.black : COLOR.gray.g600,
              opacity: item.isShow ? 1 : 0,
            },
            selected === item.code ? { ...FONT.txtSmMedium } : { ...FONT.txtSm },
          ]}
        >
          {item.name}
        </Text>
        <Text
          style={[
            styles.itemEa,
            eaColor ? { color: getRealColor(eaColor) } : { color: COLOR.main.red },
            { opacity: item.isShow ? 1 : 0 },
          ]}
        >
          {item.ea && item.ea}
        </Text>
      </View>
    </Pressable>
  )
}

export default TabItem

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: COLOR.mono.lightGray,
  },
  buttonStyle: {
    flex: 1,
    borderBottomWidth: 3,
  },
  itemStyle: {
    height: 36,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 6,
    backgroundColor: COLOR.mono.paleWhite,
    borderRadius: 8,
    paddingHorizontal: 8,
  },
  itemIcon: {
    marginRight: 4,
  },
  itemEa: {
    ...FONT.txtSm,
    marginLeft: 4,
  },
})
