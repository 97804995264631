import * as React from 'react'

import { TableorderCatalogMenu } from '@rocket/types'
import { COLOR, FONT, IColors } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import { Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native'

import { MenuLabel, MenuThumbnail } from '../../..'
import { useTableorderFile } from '../../../providers'

interface Props {
  containerStyle?: ViewStyle
  menu: TableorderCatalogMenu
  onPressItem: () => void
}

const MenuItem: React.FC<Props> = ({ containerStyle, menu, onPressItem }) => {
  const { readFileUrl } = useTableorderFile()
  const [previewImageUrl, setPreviewImageUrl] = React.useState<string>()

  React.useEffect(() => {
    const representative = menu.images.filter(({ isRepresentative }) => isRepresentative)
    if (representative.length > 0) {
      readFileUrl(representative[0].path).then(setPreviewImageUrl)
    }
  }, [menu])
  return (
    <Pressable style={[baseStyles.container, containerStyle]} onPress={onPressItem}>
      <View style={baseStyles.itemInfo}>
        {previewImageUrl !== undefined && <MenuThumbnail uri={previewImageUrl} isSoldout={menu.isSoldout} />}

        <View style={baseStyles.infoContainer}>
          <Text style={baseStyles.itemName}>{menu.name}</Text>
          <Text style={baseStyles.itemDetail} ellipsizeMode="tail" numberOfLines={2}>
            {menu.description}
          </Text>
          <View style={baseStyles.labels}>
            {menu.labels.map((label, idx) => (
              <MenuLabel key={idx} label={label.name} labelColor={label.color as IColors} />
            ))}
          </View>
          {menu.estimatedCookingMinute && (
            <Text style={baseStyles.itemTime}>
              <XEIcon name={'time-o'} size={10} color={'gray.g450'} /> 예상시간
              {menu.estimatedCookingMinute}분
            </Text>
          )}
        </View>
      </View>
      <View style={baseStyles.priceContainer}>
        {menu.isSoldout ? (
          <Text style={{ color: COLOR.main.red }}>품절 상품입니다.</Text>
        ) : (
          <>
            <Text style={baseStyles.price}>{menu.price}</Text>
            <Text style={baseStyles.priceUnit}>원</Text>
          </>
        )}
      </View>
    </Pressable>
  )
}

export default MenuItem

export const baseStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    alignItems: 'flex-end',
    paddingVertical: 12,
  },
  itemInfo: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  labels: {
    flexDirection: 'row',
    marginVertical: 4,
  },
  itemName: {
    ...FONT.H7Bold,
  },
  itemDetail: {
    height: 40,
    ...FONT.txtSm,
    color: COLOR.gray.g600,
  },
  itemTime: {
    ...FONT.txt3Xs,
    color: COLOR.gray.g450,
  },
  infoContainer: {
    flex: 1,
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  price: {
    ...FONT.txtMdBold,
  },
  priceUnit: {
    ...FONT.txtSmMedium,
  },
})
