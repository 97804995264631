import React from 'react'

import { NotificationProvider } from '../../components'

interface Props {
  children: JSX.Element
}

const NotificationListProvider: React.FC<Props> = ({ children }) => {
  return <NotificationProvider>{children}</NotificationProvider>
}

export default NotificationListProvider
