import { Icon } from '@rocket-atoms/icon'
import { Text } from '@rocket-atoms/text'
import { COLOR, removeItemAtIndex } from '@rocket-mono/libs'
import * as React from 'react'
import { DimensionValue, Platform, Pressable, View as RNView, StyleSheet, View, ViewStyle } from 'react-native'
import DropdownList from './list'
import type { DropdownItemType, DropdownMeasureType } from './types'

interface Props {
  readonly?: boolean
  multiple?: boolean
  containerStyle?: ViewStyle
  label: string
  currentIndex?: number | number[]
  defaultIndex?: number | number[]
  list: DropdownItemType[]
  onSelect?: (item: DropdownItemType) => void
  type?: string
  listType?: 'line' | 'fill' | 'basic'
  divide?: boolean
  listFullWide?: boolean
  buttonWidth?: DimensionValue
  buttonTextStyle?: ViewStyle
  afterOnSelect?: (item: DropdownItemType) => void
  isItemIconCheck?: boolean
  relativeList?: boolean
  children?: JSX.Element
}

const Dropdown: React.FC<Props> = ({
  readonly,
  multiple,
  containerStyle,
  label,
  currentIndex,
  defaultIndex,
  list,
  listType,
  onSelect,
  type,
  divide,
  listFullWide,
  buttonWidth,
  buttonTextStyle,
  afterOnSelect,
  isItemIconCheck = true,
  relativeList,
  children,
}) => {
  const buttonRef = React.useRef<RNView>(null)
  const [visible, setVisible] = React.useState(false)
  const [isHover, setIsHover] = React.useState(false)
  const [selected, setSelected] = React.useState<DropdownItemType[]>(
    defaultIndex !== undefined && typeof defaultIndex === 'number'
      ? [list[defaultIndex]]
      : list.filter((_, idx) => defaultIndex?.includes(idx)),
  )

  const [dropdownMeasure, setDropdownMeasure] = React.useState<DropdownMeasureType>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    pageX: 0,
    pageY: 0,
  })

  const toggleDropdown = (): void => {
    visible ? closeDropdown : openDropdown()
  }

  const changeDropdownMeasure = React.useCallback(() => {
    buttonRef.current?.measure((x: number, y: number, width: number, height: number, pageX: number, pageY: number) => {
      setDropdownMeasure({ x, y, width, height, pageX, pageY })
    })
  }, [buttonRef])
  const onLayout = React.useCallback(() => {
    // console.log('onLayout')
    changeDropdownMeasure()
  }, [changeDropdownMeasure])

  const onPressItem = React.useCallback(
    (item: DropdownItemType) => {
      console.log('onPressItem', item)
      if (afterOnSelect) {
        afterOnSelect(item)
      } else {
        if (currentIndex === undefined) {
          multiple
            ? setSelected((prev) => {
                const idx = prev.findIndex((o) => o.key === item.key)
                return idx < 0 ? [...prev, item] : removeItemAtIndex(prev, idx)
              })
            : setSelected([item])
        }
        onSelect && onSelect(item)
        setVisible(false)
      }
    },
    [currentIndex, onSelect],
  )

  const openDropdown = React.useCallback(() => {
    changeDropdownMeasure()
    setVisible(true)
  }, [changeDropdownMeasure])

  const closeDropdown = React.useCallback(() => {
    setVisible(false)
  }, [])

  React.useEffect(() => {
    if (currentIndex !== undefined) {
      setSelected(
        typeof currentIndex === 'number' ? [list[currentIndex]] : list.filter((_, idx) => currentIndex.includes(idx)),
      )
    }
  }, [currentIndex])

  return (
    <Pressable
      ref={buttonRef}
      style={[
        styles.button,
        containerStyle,
        !divide && visible
          ? {
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
            }
          : undefined,
        isHover && { borderColor: COLOR.gray.g450 },
        type === 'group' && {
          borderRightWidth: 0,
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderColor: COLOR.gray.g200,
        },
        buttonWidth ? { width: buttonWidth } : undefined,
      ]}
      onLayout={onLayout}
      onPress={!readonly ? toggleDropdown : undefined}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
    >
      <DropdownList
        data={list}
        listType={listType}
        selected={selected}
        visible={visible}
        dropdownMeasure={dropdownMeasure}
        onPressItem={onPressItem}
        closeDropdown={closeDropdown}
        divide={divide}
        listFullWide={listFullWide}
        isItemIconCheck={isItemIconCheck}
        relativeList={relativeList}
      />
      <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
        {selected && selected.length > 0 && selected[0].labelIcon && (
          <Icon
            name={selected[0].labelIcon?.name}
            size={selected[0].labelIcon?.size}
            color={selected[0].labelIcon?.color}
            style={{ marginRight: 4, marginTop: -2 }}
          />
        )}
        <Text
          fontName="txtSm"
          fontColor="mono.black"
          numberOfLines={1}
          ellipsizeMode={'tail'}
          style={[{ ...Platform.select({ native: { lineHeight: 16 } }) }, buttonTextStyle]}
        >
          {children !== undefined ? (
            <Text>{children}</Text>
          ) : (
            (selected && selected.length > 0 && selected[0].label) || label
          )}
        </Text>
      </View>

      <View style={{ marginTop: -2, paddingLeft: 8 }}>
        <Icon name={visible ? 'angle-up' : 'angle-down'} color="mono.black" size={12} />
      </View>
    </Pressable>
  )
}

export default Dropdown
export * from './types'

const styles = StyleSheet.create({
  button: {
    height: 36,
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: COLOR.mono.white,
    borderColor: COLOR.gray.g300,
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
})
