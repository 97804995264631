import { debounce, identity } from 'lodash'
import * as React from 'react'
// import { XEIcon } from '@rocket/common'

import { useTranslation } from 'react-i18next'
import MemberItem from './MemberItem'

import { getFontStyle, getRealColor } from '@rui/foundations'
import { search } from './util'

// import styles from './Search.style'
import type { ProjectMember } from '@rocket/types'
import { Text } from '@rui/atoms'
import { XEIcon } from '@rui/icons'
import { Platform, ScrollView, StyleSheet, TextInput, View } from 'react-native'

export interface Props {
  title: string
  memberList: ProjectMember[]
  selectedUserId: string
  setSelectedUserId: React.Dispatch<React.SetStateAction<string>>
  sort?: (a: ProjectMember, b: ProjectMember) => number
}

const memberListContainerStyle = {
  minHeight: 412,
}

interface UseSearchArgs {
  initialMemberList: ProjectMember[]
  compareFunc: (a: ProjectMember, b: ProjectMember) => number
}

interface UseSearchReturn {
  keyword: string
  memberList: ProjectMember[]
  onChangeKeyword: (text: string) => void
}

const useSearch = ({ initialMemberList, compareFunc }: UseSearchArgs): UseSearchReturn => {
  const searchData = React.useMemo(() => {
    return initialMemberList.reduce((acc, { email, name }) => {
      acc.push(email)
      acc.push(name)
      return acc
    }, [] as string[])
  }, [initialMemberList])

  const [memberList, setMemberList] = React.useState(initialMemberList)
  const [keyword, setKeyword] = React.useState('')
  const onChangeKeyword = (text: string): void => {
    setKeyword(text)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchKeyword = React.useCallback(
    debounce((_keyword: string, _searchData: string[]) => {
      const result = search(_keyword, _searchData)
      setMemberList(() =>
        initialMemberList
          .filter(({ name, email }) => result.includes(name) || result.includes(email))
          .sort(compareFunc),
      )
    }, 500),
    [search, compareFunc, initialMemberList],
  )

  React.useEffect(() => {
    searchKeyword(keyword, searchData)
  }, [keyword, initialMemberList, searchData, searchKeyword])

  return { memberList, keyword, onChangeKeyword }
}

function Search({
  title,
  sort = identity,
  selectedUserId,
  setSelectedUserId,
  memberList: initialMemberList,
}: Props): JSX.Element {
  const { t } = useTranslation()

  const { memberList, keyword, onChangeKeyword } = useSearch({
    initialMemberList,
    compareFunc: sort,
  })

  const onClickMemberItem = React.useCallback(
    (id: string): void => {
      setSelectedUserId((prevId) => (prevId === id ? '' : id))
    },
    [setSelectedUserId],
  )

  return (
    <View style={styles.section}>
      <Text fontName="textMedium" fontColor="mono.black">
        {title}
      </Text>
      <View style={[styles.memberListContainer, memberListContainerStyle]}>
        <View style={styles.inputContainer}>
          <XEIcon name="search" color="mono.black" size="15" style={styles.leftIcon} />
          <TextInput
            placeholder={t('withdrawal.search')}
            placeholderTextColor={getRealColor('mono.darkGray')}
            onChangeText={onChangeKeyword}
            value={keyword}
            style={styles.inputSearch}
            blurOnSubmit={false}
          />
        </View>
        <ScrollView style={styles.memberList}>
          {memberList.map((aMember) => (
            <MemberItem
              {...aMember}
              key={aMember.id}
              userId={String(aMember.userId)}
              selected={String(aMember.userId) === selectedUserId}
              onClick={onClickMemberItem}
            />
          ))}
        </ScrollView>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  // @ts-ignore
  section: {
    ...Platform.select({
      web: {
        marginBottom: '0.75rem',
      },
    }),
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    padding: 24,
  },
  // @ts-ignore
  memberListContainer: {
    ...Platform.select({
      web: {
        marginTop: '1.25rem',
        paddingTop: '1.25rem',
        paddingHorizontal: '0.25rem',
      },
    }),
    backgroundColor: 'white',
    flexDirection: 'column',
    flex: 1,
    borderRadius: 5,
    overflow: 'hidden',
  },
  // @ts-ignore
  inputContainer: {
    ...Platform.select({
      web: {
        paddingHorizontal: '1rem',
      },
    }),
  },
  // @ts-ignore
  leftIcon: {
    position: 'absolute',
    ...Platform.select({
      web: {
        marginVertical: '0.5rem',
        marginLeft: '0.75rem',
      },
    }),
  },
  // @ts-ignore
  inputSearch: {
    ...getFontStyle('textMedium'),
    backgroundColor: getRealColor('mono.pale'),
    color: getRealColor('mono.black'),
    ...Platform.select({
      web: {
        paddingLeft: '2.25rem',
        paddingRight: '0.75rem',
        paddingVertical: '0.5rem',
      },
    }),
    borderRadius: 9999,
  },
  // @ts-ignore
  memberList: {
    flexDirection: 'column',
    ...Platform.select({
      web: {
        marginTop: '0.5rem',
        // marginBottom: '3rem',
        paddingHorizontal: '1rem',
      },
    }),
  },
})

export default Search
