import React from 'react'

import { useAstro, useSubscription } from '@rocket-mono/providers'
import { TableorderBoardScreen } from '../components'
import Providers from '../providers'

interface Props {
  shopId: string
}

const BoardScreen: React.FC<Props> = ({ shopId }) => {
  const { astro } = useAstro()
  return (
    <Providers>
      <TableorderBoardScreen shopId={shopId} astro={astro} subscribe={useSubscription} />
    </Providers>
  )
}

export default BoardScreen
