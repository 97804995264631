import { useMobileView } from '@rocket-mono/libs'
import React, { useState } from 'react'

import Providers from '../../providers'
import LanguageView from './components/LanguageView'
import TimezoneView from './components/TimezoneView'
import UserInfoView from './components/UserInfoView'
import Provider from './provider'
import Screen from './screen'

import { i18n } from 'i18next'
interface Props {
  i18n: i18n
  onPressBack?: () => void
  onLogout: () => void
}
const WorkSettingScreen: React.FC<Props> = ({ i18n, ...props }) => {
  const isMobile = useMobileView(960)

  const [tab, setTab] = useState(isMobile ? '' : 'edit')

  return (
    <Providers i18n={i18n}>
      <Provider>
        <Screen tab={tab} onPressItem={setTab} {...props}>
          {tab === 'edit' ? (
            <UserInfoView onPressBack={() => setTab('')} />
          ) : tab === 'lang' ? (
            <LanguageView onPressBack={() => setTab('')} />
          ) : tab === 'time' ? (
            <TimezoneView onPressBack={() => setTab('')} />
          ) : (
            <></>
          )}
        </Screen>
      </Provider>
    </Providers>
  )
}

export default WorkSettingScreen
