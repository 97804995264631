import * as React from 'react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { View } from '@rocket-mono/foundations'
import { SubscribeType } from '@rocket-mono/libs'
import {
  TableorderCatalogAstroType,
  TableorderCatalogProvider,
  TableorderMenuAstroType,
  TableorderMenuProvider,
  TableorderOrderAstroType,
  TableorderOrderProvider,
  TableorderShopAstroType,
  TableorderShopProvider,
  TableorderTableAstroType,
  TableorderTableProvider,
} from '../providers'
import { TableorderOrderDetailView } from '../TableorderOrderDetailView'
import { Context } from './context'
import { Provider } from './provider'
import { TableorderBoardView } from './view'

interface Props {
  shopId: string
  astro: TableorderShopAstroType &
    TableorderTableAstroType &
    TableorderMenuAstroType &
    TableorderCatalogAstroType &
    TableorderOrderAstroType
  subscribe: SubscribeType
}
export const TableorderBoardScreen: React.FC<Props> = ({ shopId, astro, subscribe }) => {
  return (
    <TableorderShopProvider shopId={shopId} astro={astro}>
      <TableorderTableProvider astro={astro} subscribe={subscribe}>
        <Provider>
          <TableorderMenuProvider astro={astro}>
            <TableorderCatalogProvider astro={astro}>
              <TableorderOrderProvider astro={astro} subscribe={subscribe}>
                <TableorderOrderDetailView.Provider>
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <TableorderOrderDetailView />
                    <DndProvider backend={HTML5Backend}>
                      <Context.Consumer>{(context) => context && <TableorderBoardView />}</Context.Consumer>
                    </DndProvider>
                  </View>
                </TableorderOrderDetailView.Provider>
              </TableorderOrderProvider>
            </TableorderCatalogProvider>
          </TableorderMenuProvider>
        </Provider>
      </TableorderTableProvider>
    </TableorderShopProvider>
  )
}
