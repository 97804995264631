import * as React from 'react'
import { Pressable, StyleSheet, View, useWindowDimensions } from 'react-native'

import { Text } from '@rui/atoms'
import { COLOR, FONT, getBoxShadow } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import { WorkModelIcon } from './icon'
import { WorkModelImage } from './image'
import { WorkModelLabel } from './label'
import type { WorkModelItemProps } from './types'

export const WorkModelItem: React.FC<WorkModelItemProps> = ({
  id,
  title,
  // titleFont = 'H7Bold',
  description,
  // descriptionFont = 'txtSm',
  nameMarginBottom = 8,
  onPress,
  iconProps,
  imageProps,
  labelProps,
  isCheckIcon,
  height = 120,
}) => {
  const { width } = useWindowDimensions()
  const mobileView = 500 > width

  return (
    <Pressable style={[styles.container, { minHeight: height }]} onPress={() => onPress(id)}>
      <View
        style={[
          { marginRight: imageProps.marginRight, shadowOpacity: 0 },
          imageProps.width ? {} : { flex: 3 },
          { backgroundColor: '#F4F7FA' },
        ]}
      >
        <WorkModelImage {...imageProps} width={mobileView ? 100 : 160} height={height} />
      </View>
      <View
        style={{
          flex: 6,
          justifyContent: 'center',
          paddingTop: 8,
          paddingBottom: 4,
        }}
      >
        <View style={[styles.nameContainer, mobileView ? { marginBottom: 4 } : { marginBottom: nameMarginBottom }]}>
          <View style={mobileView && { marginTop: 2 }}>
            <WorkModelIcon size={mobileView ? 20 : 24} {...iconProps} />
          </View>
          <Text style={[{ flex: 1 }, mobileView ? { ...FONT.txtMdBold } : { ...FONT.H7Bold }]} fontColor="mono.black">
            {title}
          </Text>
        </View>
        <Text style={mobileView ? { ...FONT.txtXs } : { ...FONT.txtSm }} fontColor="mono.paleBlack">
          {description}
        </Text>
        <WorkModelLabel {...labelProps} id={id} />
      </View>
      {isCheckIcon ? (
        <View
          style={{
            width: 35,
            justifyContent: 'center',
          }}
        >
          <XEIcon name="angle-right" size={mobileView ? 16 : 20} color="gray.g700" />
        </View>
      ) : (
        <></>
      )}
    </Pressable>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLOR.mono.white,
    flexDirection: 'row',
    marginVertical: 10,
    borderRadius: 4,
    borderColor: COLOR.gray.g100,
    borderWidth: 1,
    ...getBoxShadow(0, 0, 0, 0.05, 5, 0, 2, 5),
  },
  nameContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
})
