import * as React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Checkbox } from '@rocket-atoms/checkbox'
import { BoardLabel, CardIcon, WorkIcon } from '@rocket-atoms/icon'
import { Radio } from '@rocket-atoms/radio'
import { COLOR, FONT, IColors } from '@rocket-mono/libs'
import { CardType } from '@rocket/types'
import { Avatar } from '@rui/atoms'
import { Lozenge } from '@rui/molecules'
import { Pressable, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native'

import { ItemListTypeProps } from '.'
import Icon from './icon'
export interface DropdownItemProps {
  beforeWorkIcon?: 'DSP' | 'WFP' | 'CSP' | 'TOP'
  beforeBoardLabel?: IColors
  beforeCardIcon?: CardType
  beforeLabel?: string
  beforeProfileImage?: string
  beforeIcon?: IconProp
  afterIcon?: IconProp
  iconColor?: IColors
  label: string
  disabled?: boolean
  code: string
}

interface Props extends ItemListTypeProps {
  item: DropdownItemProps
  selected?: DropdownItemProps[]
  onPressItem?: (arg0: DropdownItemProps) => void
  itemStyle?: ViewStyle
  itemTextStyle?: TextStyle
  checked?: boolean
  onChangeChecked?: (arg0: boolean) => void
}

const DropdownListItem: React.FC<Props> = ({
  item,
  itemType,
  itemVariant,
  ifFilledBgColor,
  ifAfterChecked,
  ifAfterRadio,
  selected,
  onPressItem,
  itemStyle,
  itemTextStyle,
  checked,
  onChangeChecked,
}) => {
  const isActive = selected?.find((o) => o.code === item.code) ? true : false
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={() => {
        onChangeChecked ? onChangeChecked(!selected) : !item.disabled && onPressItem && onPressItem(item)
      }}
      style={[
        styles.wrap,
        isHover && styles.wrapHover,
        (itemVariant === 'FILLED' && isActive) || (itemVariant === 'FILLED' && checked) ? styles.wrapFilledHover : {},
        itemVariant === 'FILLED' && isActive && ifFilledBgColor && { backgroundColor: ifFilledBgColor },
        item.disabled && styles.wrapDisabled,
        itemStyle,
      ]}
    >
      <View style={styles.inner}>
        <View style={styles.slotArea}>
          {!ifAfterChecked && itemType === 'CHECKED' && (
            <View style={styles.inputArea}>
              <Checkbox checked={checked ? checked : isActive} disabled={item.disabled} />
            </View>
          )}
          {!ifAfterRadio && itemType === 'RADIOBOX' && (
            <View style={styles.inputArea}>
              <Radio checked={checked ? checked : isActive} disabled={item.disabled} />
            </View>
          )}

          {item.beforeWorkIcon && <WorkIcon type={item.beforeWorkIcon} size={20} />}
          {item.beforeCardIcon && <CardIcon variant="ROUNDSQUARE" type={item.beforeCardIcon} size={'sm'} />}
          {item.beforeBoardLabel && <BoardLabel color={item.beforeBoardLabel} size={16} />}
          {item.beforeLabel && <Lozenge type={'DEFAULT'} label={item.label} labelType={item.beforeLabel as IColors} />}
          {item.beforeProfileImage && <Avatar profileUrl={item.beforeProfileImage} size={'xsmall'} />}
          {item.beforeIcon && (
            <Icon iconName={item.beforeIcon} variant={itemVariant} isSelected={isActive} iconColor={item.iconColor} />
          )}
        </View>
        {!item.beforeLabel && (
          <Text
            style={[
              styles.textStyle,
              isActive && { color: COLOR.primary.blue500 },
              (itemVariant === 'FILLED' && isActive) || (itemVariant === 'FILLED' && checked)
                ? { color: COLOR.mono.white }
                : {},
              item.disabled && { color: COLOR.gray.g300 },
              item.beforeCardIcon && { marginLeft: 4 },
              itemTextStyle,
            ]}
          >
            {item.label}
          </Text>
        )}
      </View>

      <View style={styles.slotRightArea}>
        {itemType === 'DEFAULT' && isActive && <Icon iconName={faCheck} variant={itemVariant} isSelected={isActive} />}
        {item.afterIcon && <Icon iconName={item.afterIcon} variant={itemVariant} isSelected={isActive} />}
        {ifAfterChecked && itemType === 'CHECKED' && (
          <View style={styles.inputAreaV2}>
            <Checkbox checked={checked ? checked : isActive} variant={'CIRCLE'} disabled={item.disabled} />
          </View>
        )}
        {ifAfterRadio && itemType === 'RADIOBOX' && (
          <View style={styles.inputAreaV2}>
            <Radio checked={checked ? checked : isActive} disabled={item.disabled} />
          </View>
        )}
      </View>
    </Pressable>
  )
}

export default DropdownListItem

const styles = StyleSheet.create({
  wrap: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  wrapHover: {
    backgroundColor: COLOR.gray.g050,
  },
  wrapFilledHover: {
    backgroundColor: COLOR.primary.blue500,
  },
  wrapDisabled: {
    backgroundColor: COLOR.opacity.blk10,
  },
  inner: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconArea: {
    marginRight: 4,
  },
  inputArea: {
    marginRight: 4,
  },
  inputAreaV2: {
    marginLeft: 4,
  },
  textStyle: {
    paddingRight: 16,
    ...FONT.txtMd,
  },
  slotArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 4,
  },
  slotRightArea: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 4,
  },
})
