import * as React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

import { Button, Modal, useModalBottom } from '@rocket-mono/foundations'
import { COLOR, useForm } from '@rocket-mono/libs'
import { useCurrentUser, useModalDialog, useWorkmodel } from '@rocket-mono/providers'
import { Icon, Text } from '@rocket/atoms'
import { useToast } from '@rui/atoms'
import { useTranslation } from 'react-i18next'
import { TabloeOrderFormType, TabloeOrderFormValidationProps } from '../CreateWorkModel/Tableorder'
import EditWorkModelCousult from '../EditWorkModel/EditWorkModelCousult'
import EditWorkModelHierarchy from '../EditWorkModel/EditWorkModelHierarchy'
import EditWorkModelTableorder from '../EditWorkModel/EditWorkModelTableorder'
import EditWorkModelWorkflow from '../EditWorkModel/EditWorkModelWorkflow'
import { AxisContainer } from '../components'
import { BoardDeleteInfo } from './BoardDeleteInfo'
import type { ViewProps } from './types'

const ModalView: React.FC<ViewProps> = ({ astro, onPressBack, onPressClose, onBack, goHome, onSuccess }) => {
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()
  const { show: showToastMessage } = useToast()
  const { show: showModalBottom, hide: hideModalBottom } = useModalBottom()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()
  const {
    isArchive,
    isChangeValue,
    data,
    shopData,
    deleteInfoVisible,
    handleSubmit,
    updateTitle,
    updateRangeDate,
    createElement,
    updateElement,
    updateElements,
    deleteElement,
    updateStartElement,
    updateEndElement,
    setDeleteInfoVisible,
  } = useWorkmodel()

  const [isOpen, setIsOpen] = React.useState(false)
  const isOwner = React.useMemo(() => {
    return data.ownerUserId === String(currentUser.id)
  }, [data.ownerUserId])
  const [isDate, setIsDate] = React.useState(true)

  const handleSave = React.useCallback(() => {
    const proc = {
      onSuccess: (projectId: string) => {
        // if (Platform.OS !== 'web') {
        //   showToastMessage({
        //     message: t('screen.workmodelupdate.toast.confirmed'),
        //     viewPosition: 'BOTTOM_CENTER',
        //   })
        // } else {
        //   showToastMessage({
        //     message: t('screen.workmodelupdate.toast.confirmed'),
        //     viewPosition: 'TOP_RIGHT',
        //   })
        // }
        const action = 'project'
        const params = { projectId }
        const payload = {
          type: 'edit',
          params,
        }
        window.postMessage(
          {
            action,
            payload,
          },
          '*',
        )
        onSuccess(projectId)
      },
      onFailed: onPressClose,
    }
    if (data.typeCode === 'TOP') {
      handleSubmitShop(proc)
    } else handleSubmit(proc)
  }, [data])

  const handlePressAdd = React.useCallback(() => {
    const { typeCode, projectElements } = data
    createElement({
      name: '',
      isMovable: true,
      labelObject: typeCode === 'DSP' || typeCode === 'CSP' ? 'sub.sky' : null,
      order: projectElements.length,
      isDelete: true,
    })
  }, [data])

  const handlePressDeleteInfo = React.useCallback(() => {
    setDeleteInfoVisible(true)
  }, [])

  const handleChangePreText = React.useCallback(
    (name: string) => {
      data.startElement && updateStartElement({ ...data.startElement, name })
    },
    [data.startElement],
  )

  const handleChangeEndText = React.useCallback(
    (name: string) => {
      data.endElement && updateEndElement({ ...data.endElement, name })
    },
    [data.endElement],
  )

  const handlePressClose = React.useCallback(() => {
    console.log('handlePressClose', { isChangeValue })
    if (isChangeValue) {
      const list = [
        {
          name: t('screen.workmodelupdate.dialog.exit'),
          action: () => {
            if (Platform.OS === 'web') {
              hideDialogMessage()
            } else {
              hideModalBottom()
            }
            onPressClose()
          },
        },
        {
          name: t('screen.workmodelupdate.dialog.save'),
          action: () => {
            hideModalBottom()
            handleSave()
          },
        },
      ]
      const title = t('screen.workmodelupdate.dialog.title')
      if (Platform.OS === 'web') {
        showDialogMessage({
          type: 'BOTTOM',
          title,
          list,
          cancelText: t('screen.workmodelupdate.dialog.cancel'),
          onCancel: hideDialogMessage,
        })
      } else {
        showModalBottom({
          title,
          list,
          onCancel: hideModalBottom,
        })
      }
    } else onPressClose()
  }, [isChangeValue])

  console.log('shopData', shopData)

  const {
    values: shopValues,
    handleChange: handleChangeShop,
    handleSubmit: handleSubmitShop,
  } = useForm<TabloeOrderFormType, TabloeOrderFormValidationProps>({
    initialValues: {
      businessName: shopData?.businessName || '',
      bankAccountName: shopData?.bankAccountName || '',
      bankAccountNumber: shopData?.bankAccountNumber || '',
      bankCode: shopData?.bankCode || '',
      businessRegistrationNumber: shopData?.businessRegistrationNumber || '',
      representativeBirthDate: shopData?.representativeBirthDate || '',
      representativeContactNumber: shopData?.representativeContactNumber || '',
      representativeName: shopData?.representativeName || '',
      responsibilityContactNumber: shopData?.responsibilityContactNumber || '',
    },
    onSubmit: (values: TabloeOrderFormType) => {
      console.log('TabloeOrderFormType', { ...values, name: data.title })
      return new Promise((resolve, reject) => {
        console.log('onSubmit', values)
        if (data.id !== undefined && shopData) {
          const { id: projectId, title, goal, typeCode, etc } = data
          astro
            .updateProject({ id: projectId, title, goal, typeCode, etc })
            .then(() =>
              astro.updateShop(shopData.id, {
                id: shopData.id,
                userId: currentUser.id,
                projectId,
                ...values,
                name: title,
              }),
            )
            .then(() => resolve(projectId))
            .catch((err) => reject(err))
        } else {
          reject()
        }
      })
    },
    validate: ({}: TabloeOrderFormType) => {
      const errors: TabloeOrderFormValidationProps = {}
      return errors
    },
  })

  return (
    <View style={Platform.OS === 'web' ? styles.webContainer : styles.container}>
      <BoardDeleteInfo visible={deleteInfoVisible} onPress={() => setDeleteInfoVisible(false)} />
      <Modal.Header
        textAlign="center"
        onPressBack={onPressBack}
        closeIcon={<Icon name="close" size={20} color="gray.g700" />}
        onPressClose={handlePressClose}
      >
        <View style={styles.headerContainer}>
          <Text fontName="subTitleBold" fontColor="gray.g900" style={styles.headerText}>
            {t('screen.workmodelupdate.title')}
          </Text>
          {isOwner ? (
            <AxisContainer
              isIconOnly
              axisIconName="ellipsis-h"
              iconSize={20}
              containerStyle={styles.headerButton}
              buttonAddButtonStyle={{ backgroundColor: COLOR.mono.white }}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              appList={
                isArchive
                  ? [
                      {
                        state: 'unarchive',
                        title: t('screen.workmodelupdate.more.unarchive.title'),
                      },
                      {
                        state: 'delete',
                        title: t('board.delete'),
                      },
                    ]
                  : [
                      {
                        state: 'archive',
                        title: t('screen.workmodelupdate.more.archive.title'),
                      },
                    ]
              }
              onPopupState={(state) => {
                if (state === 'archive') {
                  if (Platform.OS === 'web') {
                    showDialogMessage({
                      type: 'BOTTOM',
                      title: t('screen.workmodelupdate.more.archive.dialog.message'),
                      list: [
                        {
                          name: t('screen.workmodelupdate.more.archive.dialog.questionbutton'),
                          action: () => {
                            if (data.id) {
                              hideDialogMessage()
                              astro.archiveProject(data.id).then(() => {
                                setIsDate(false)
                                const duration = 1500 // 1.5초
                                showToastMessage(
                                  {
                                    type: 'Success',
                                    title: t('toast.confirmed'),
                                    position: 'BOTTOM_CENTER',
                                  },
                                  duration,
                                )
                                setTimeout(() => {
                                  onBack && onBack()
                                }, duration + 100)
                              })
                            }
                          },
                        },
                      ],
                      onCancel: hideDialogMessage,
                      cancelText: t('screen.workmodelupdate.more.archive.dialog.cancelbutton'),
                    })
                  } else {
                    showModalBottom({
                      title: t('screen.workmodelupdate.more.archive.dialog.message'),
                      list: [
                        {
                          name: t('screen.workmodelupdate.more.archive.dialog.questionbutton'),
                          action: () => {
                            if (data.id) {
                              hideModalBottom()
                              astro.archiveProject(data.id).then(() => {
                                setIsDate(false)
                                const duration = 1500 // 1.5초
                                showToastMessage(
                                  {
                                    type: 'Success',
                                    title: t('toast.confirmed'),
                                    position: 'BOTTOM_CENTER',
                                  },
                                  duration,
                                )
                                setTimeout(() => {
                                  onBack && onBack()
                                }, duration + 100)
                              })
                            }
                          },
                        },
                      ],
                      onCancel: hideModalBottom,
                      cancelText: t('screen.workmodelupdate.more.archive.dialog.cancelbutton'),
                    })
                  }
                } else if (state === 'unarchive') {
                  if (data.id) {
                    astro.unarchiveProject(data.id).then(() => {
                      setIsDate(false)
                      const duration = 1500 // 1.5초
                      showToastMessage(
                        {
                          type: 'Success',
                          title: t('toast.confirmed'),
                          position: 'BOTTOM_CENTER',
                        },
                        duration,
                      )
                      setTimeout(() => {
                        onBack && onBack()
                      }, duration + 100)
                    })
                  }
                } else if (state === 'delete') {
                  if (data.id) {
                    showDialogMessage({
                      type: 'BOTTOM',
                      title: t('board.delete'),
                      message: t('archivework.delete'),
                      list: [
                        {
                          name: t('board.delete'),
                          action: () => {
                            if (data.id) {
                              hideDialogMessage()

                              astro.deleteProject(data.id).then(() => {
                                setIsDate(false)
                                const duration = 1500 // 1.5초
                                showToastMessage(
                                  {
                                    type: 'Success',
                                    title: t('cardtodoedit.toast.delete'),
                                    position: 'BOTTOM_CENTER',
                                  },
                                  duration,
                                )
                                setTimeout(() => {
                                  goHome && goHome()
                                }, duration + 100)
                              })
                            }
                          },
                        },
                      ],
                      onCancel: hideDialogMessage,
                      cancelText: t('setting.state.cancel'),
                    })
                  }
                }
              }}
              onAppsPress={() => {
                console.log('onAppsPress')
              }}
              isWeb={false}
              popAxisPosition={'left'}
              addPopupMarginRight={-40}
            />
          ) : (
            <></>
          )}
        </View>
      </Modal.Header>
      <Modal.Body isDirect>
        {data.typeCode === 'CSP' ? (
          <EditWorkModelCousult
            data={data}
            onPressAdd={handlePressAdd}
            onChangeTitle={updateTitle}
            onChangeElement={updateElement}
            onChangeProjectElements={updateElements}
            onPressDelete={deleteElement}
            onPressDeleteInfo={handlePressDeleteInfo}
          />
        ) : data.typeCode === 'DSP' ? (
          <EditWorkModelHierarchy
            data={data}
            isDate={isDate}
            onPressAdd={handlePressAdd}
            onChangeTitle={updateTitle}
            onChangeElement={updateElement}
            onChangeProjectElements={updateElements}
            onPressDelete={deleteElement}
            onChangeRangeDate={updateRangeDate}
            onPressDeleteInfo={handlePressDeleteInfo}
          />
        ) : data.typeCode === 'WFP' ? (
          <EditWorkModelWorkflow
            data={{
              ...data,
              preText: data.startElement?.name || '',
              endText: data.endElement?.name || '',
            }}
            isDate={isDate}
            onPressAdd={handlePressAdd}
            onChangeTitle={updateTitle}
            onChangeElement={updateElement}
            onChangeProjectElements={updateElements}
            onPressDelete={deleteElement}
            onChangePreText={handleChangePreText}
            onChangeEndText={handleChangeEndText}
            onChangeRangeDate={updateRangeDate}
            onPressDeleteInfo={handlePressDeleteInfo}
          />
        ) : data.typeCode === 'TOP' ? (
          <EditWorkModelTableorder
            data={data}
            shopData={shopValues}
            onChangeValue={handleChangeShop}
            onPressAdd={handlePressAdd}
            onChangeTitle={updateTitle}
            onChangeElement={updateElement}
            onChangeProjectElements={updateElements}
            onPressDelete={deleteElement}
            onPressDeleteInfo={handlePressDeleteInfo}
          />
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <View style={styles.footerContainer}>
          <Button wide={true} onPress={handleSave}>
            <Text fontColor="mono.white">{t('screen.workmodelupdate.submit')}</Text>
          </Button>
        </View>
      </Modal.Footer>
    </View>
  )
}

export default ModalView

const styles = StyleSheet.create({
  container: { flex: 1 },
  webContainer: { width: '100%', minHeight: 680, borderRadius: 8 },
  footerContainer: {
    flex: 1,
    margin: 24,
  },
  headerContainer: {
    justifyContent: 'center',
    width: '100%',
  },
  headerText: {
    textAlign: 'center',
  },
  headerButton: {
    position: 'absolute',
    right: 20,
    width: 40,
  },
})
