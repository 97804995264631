import * as React from 'react'
import { Image, StyleSheet, View } from 'react-native'

import type { WorkModelImageProps } from './types'

export const WorkModelImage = ({
  uri,
  height = 120,
  width = '100%',
  paddingVertical = 20,
  paddingHorizontal = 20,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
}: WorkModelImageProps) => {
  const padding = React.useMemo(() => {
    if (paddingTop || paddingBottom || paddingLeft || paddingRight) {
      return {
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      }
    }

    return {
      paddingVertical,
      paddingHorizontal,
    }
  }, [paddingVertical, paddingHorizontal, paddingTop, paddingBottom, paddingLeft, paddingRight])

  return (
    <View style={[styles.container, { height, width, ...padding }]}>
      {typeof uri === 'string' ? <Image source={{ uri }} style={styles.image} /> : <>{uri}</>}
      {/* <SubstructureWork /> */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // borderRadius: 16,
    backgroundColor: '#F4F7FA',
    // paddingVertical: 15,
    // paddingHorizontal: 27,
  },
  textStyle: {
    fontSize: 24,
    fontWeight: '500',
  },
  image: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
})
