import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { Pressable, StyleSheet } from 'react-native'
import ImageViewUnit from '../../ImageViewUnit'
import { IconDelete } from '../../icons'
import { FileType, useTableorderFile } from '../../providers'

interface Props {
  index: number
  file: FileType
  uploaderName: string
  onPressDelete?: () => void
  dragHandleProps?: DraggableProvidedDragHandleProps | null
}

export const TableorderMenuDetailFileItem: React.FC<Props> = ({
  index,
  file,
  uploaderName,
  onPressDelete,
  dragHandleProps,
}) => {
  const { readFileUrl } = useTableorderFile()

  const [fileUrl, setFileUrl] = React.useState<string>()
  const [mimeType, setMimeType] = React.useState('')
  const [size, setSize] = React.useState(0)
  const previewFile = React.useMemo(() => {
    const { name } = file
    return { name, size, url: fileUrl, mimeType }
  }, [file, fileUrl, mimeType, size])

  React.useEffect(() => {
    if (fileUrl === undefined) {
      readFileUrl(file.filePath).then(setFileUrl)
    }
  }, [fileUrl, file])

  React.useEffect(() => {
    if (fileUrl !== undefined) {
      fetch(fileUrl).then((res) => {
        setMimeType(res.headers.get('Content-Type') || '')
        setSize(Number(res.headers.get('Content-Length')) || 0)
      })
    }
  }, [fileUrl])

  return (
    <View style={[styles.container, index === 0 && styles.containerMain]}>
      {index === 0 && (
        <View style={styles.header}>
          <View style={styles.iconContainer}>
            <XEIcon style={styles.icon} name="star" size={9} color="mono.white" />
          </View>
          <Text fontName="txt2XsMedium" style={styles.iconText}>
            대표 이미지
          </Text>
        </View>
      )}
      <View style={styles.itemContainer}>
        <div {...dragHandleProps}>
          <Pressable style={styles.dragIcon}>
            <XEIcon name="drag-vertical" size="16" />
          </Pressable>
        </div>
        <ImageViewUnit
          hasAuthDownload
          containerStyle={styles.flex}
          boxColor={index === 0 ? 'mono.white' : 'mono.paleWhite'}
          date={new Date()}
          file={previewFile}
          hideShareButton
          hideDownloadButton
          uploaderName={uploaderName}
        />
        <View style={styles.deleteIcon}>{onPressDelete && <IconDelete onPressDelete={onPressDelete} />}</View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    padding: 8,
  },
  containerMain: {
    backgroundColor: COLOR.mono.paleWhite,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#6C67C1',
  },
  flex: {
    flex: 1,
  },
  header: { flexDirection: 'row', marginLeft: 22, alignItems: 'center' },
  itemContainer: {
    height: 38,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    marginRight: 6,
    backgroundColor: '#6C67C1',
    width: 13,
    height: 13,
    borderRadius: 9,
    alignItems: 'center',
  },
  icon: { margin: 0, padding: 0 },
  iconText: { color: '#6C67C1' },
  dragIcon: { width: 22, alignItems: 'center' },
  deleteIcon: { width: 22 },
})
