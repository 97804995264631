export const emptytranslation: {
  [key: string]: {
    title: string
    info: string
    button: string
  }
} = {
  empty_ko: {
    title: '빈문서',
    info: '새로운 WORK를 만들고 파트를 자유롭게 구성합니다.',
    button: '시작하기',
  },
  empty_en: {
    title: 'Empty Document',
    info: 'Create a new WORK and compose parts freely.',
    button: 'Start',
  },
  empty_mn: {
    title: 'хоосон баримт бичиг',
    info: 'Шинэ БҮТЭЭЛ үүсгэж, хэсгүүдийг чөлөөтэй зохио.',
    button: 'эхэлцгээе',
  },
  empty_vi: {
    title: 'tài liệu trống',
    info: 'Tạo một CÔNG VIỆC mới và sáng tác các phần một cách tự do.',
    button: 'bắt đầu',
  },
}

export const translation: {
  [key: string]: {
    image1: string
    image2: string
    image3: string
  }
} = {
  // 프로젝트 관리
  dsp_ko: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/dsp_ko_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/dsp_ko_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/dsp_ko_03.png',
  },
  dsp_en: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/dsp_en_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/dsp_en_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/dsp_en_03.png',
  },
  dsp_mn: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/dsp_mn_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/dsp_mn_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/dsp_mn_03.png',
  },
  dsp_vi: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/dsp_vi_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/dsp_vi_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/dsp_vi_03.png',
  },
  dsp_de: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/dsp_de_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/dsp_de_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/dsp_de_03.png',
  },
  dsp_es: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/dsp_es_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/dsp_es_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/dsp_es_03.png',
  },
  dsp_ja: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/dsp_jp_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/dsp_jp_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/dsp_jp_03.png',
  },
  // 워크플로우
  wfp_ko: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/wfp_ko_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/wfp_ko_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/wfp_ko_03.png',
  },
  wfp_en: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/wfp_en_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/wfp_en_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/wfp_en_03.png',
  },
  wfp_mn: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/wfp_mn_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/wfp_mn_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/wfp_mn_03.png',
  },
  wfp_vi: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/wfp_vi_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/wfp_vi_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/wfp_vi_03.png',
  },
  wfp_de: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/wfp_de_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/wfp_de_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/wfp_de_03.png',
  },
  wfp_es: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/wfp_es_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/wfp_es_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/wfp_es_03.png',
  },
  wfp_ja: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/wfp_jp_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/wfp_jp_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/wfp_jp_03.png',
  },
  // 일대다 상담 채팅
  csp_ko: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/csp_ko_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/csp_ko_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/csp_ko_03.png',
  },
  csp_en: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/csp_en_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/csp_en_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/csp_en_03.png',
  },
  csp_mn: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/csp_mn_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/csp_mn_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/csp_mn_03.png',
  },
  csp_vi: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_03.png',
  },
  csp_de: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/csp_de_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/csp_de_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/csp_de_03.png',
  },
  csp_es: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/csp_es_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/csp_es_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/csp_es_03.png',
  },
  csp_ja: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/csp_jp_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/csp_jp_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/csp_jp_03.png',
  },
  // 테이블 오더
  top_ko: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/top_ko_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/top_ko_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/ko/top_ko_03.png',
  },
  top_en: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/top_en_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/top_en_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/en/top_en_03.png',
  },
  top_mn: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/top_mn_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/top_mn_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/mn/top_mn_03.png',
  },
  top_vi: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/vi/csp_vi_03.png',
  },
  top_de: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/top_de_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/top_de_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/de/top_de_03.png',
  },
  top_es: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/top_es_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/top_es_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/es/top_es_03.png',
  },
  top_ja: {
    image1: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/top_jp_01.png',
    image2: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/top_jp_02.png',
    image3: 'https://sqcqzgnqpuwu17041747.gcdn.ntruss.com/explain_modal/jp/top_jp_03.png',
  },
}
