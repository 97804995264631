import * as React from 'react'
import type { ChatsScreenTabType, RenderItemType, SectionItemType, UserStateType } from './types'

export interface ChatsProviderContext {
  userName: string
  userId: string
  tabCode: ChatsScreenTabType
  stateList: UserStateType[]
  sectionList: SectionItemType[]
  itemList?: RenderItemType[]
  searchKeyword?: string
  onChangeSearchKeyword?: (keyword: string) => void
  onLoadItem: (itemKey: string) => void
  onPressTab: (code: ChatsScreenTabType) => void
  onPressSection: (sectionKey: string) => void
  fetchProjectList: () => void
  fetchUnreadChannelList: () => void
  updateProject: (projectId: string) => void
  updateChannel: (channelRoomId: string) => void
  readChannel: (channelRoomId: string) => void
}
export const ChatsContext = React.createContext<ChatsProviderContext | undefined>(undefined)
