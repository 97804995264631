import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import type { DropdownItem } from './types'

interface Props {
  code: string
  dropdownList: DropdownItem[]
  onCode: (code: string) => void
  onDrop?: (dropped: boolean) => void
  containerStyle?: ViewStyle
  isEnable?: boolean
}

function DropdownDefault({ code, dropdownList, onCode, onDrop, containerStyle, isEnable }: Props) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = React.useState(false)
  const useSelectedItem = React.useMemo(() => {
    const selectItems = dropdownList.filter((item) => item.code === code)
    if (selectItems.length > 0) {
      return selectItems[0].title
    } else {
      return t('dropdown.initcode')
    }
  }, [dropdownList, code, t])
  return (
    <View style={[styles.container, containerStyle, { opacity: isEnable ? 1 : 0.5 }]}>
      <TouchableOpacity
        style={[
          styles.dropbutton,
          isOpen
            ? {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : undefined,
        ]}
        onPress={() => {
          if (isEnable) {
            setIsOpen(!isOpen)
            onDrop && onDrop(!isOpen)
          }
        }}
      >
        <Text style={styles.buttonText}>{useSelectedItem}</Text>
        <XEIcon name={isOpen ? 'angle-up' : 'angle-down'} color="mono.black" size={13} />
      </TouchableOpacity>
      {isOpen && (
        <View>
          {dropdownList.map((item, idx) => (
            <TouchableOpacity
              key={`dropdown-list-${item.id}`}
              style={[
                styles.listButton,
                {
                  backgroundColor:
                    item.code === code
                      ? COLOR.mono.brightBlue
                      : idx % 2 === 0
                      ? COLOR.mono.white
                      : COLOR.mono.whiteGray,
                },
              ]}
              onPress={() => onCode(item.code)}
            >
              <Text
                style={[
                  styles.listText,
                  {
                    color: item.code === code ? COLOR.mono.white : COLOR.mono.black,
                  },
                ]}
              >
                {item.title}
              </Text>
              {item.code === code && <XEIcon name="check" color="mono.white" size={13} />}
            </TouchableOpacity>
          ))}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {},
  dropbutton: {
    backgroundColor: COLOR.mono.white,
    borderColor: COLOR.gray.g300,
    borderWidth: 1,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
  listButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderColor: '#D5D5D5',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  buttonText: {
    ...FONT.txtSm,
    color: COLOR.mono.black,
  },
  listText: {
    ...FONT.txtSm,
    color: COLOR.mono.black,
  },
})

export default React.memo(DropdownDefault)
