import { faInfinity } from '@fortawesome/free-solid-svg-icons'
import { COLOR, FONT } from '@rocket-mono/libs'
import { FAIcon } from '@rocket/atoms'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'

const RuleButton = ({ count, onBoardRole }: { count?: number | null; onBoardRole?: () => void }) => {
  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[
        styles.container,
        isHover && { backgroundColor: COLOR.gray.g050 },
        count ? { borderColor: COLOR.primary.blue200 } : {},
      ]}
      onPress={onBoardRole}
    >
      <FAIcon iconName={faInfinity} size={'2xs'} color={count ? 'primary.blue500' : 'gray.g450'} />
      {count && (
        <View style={styles.badge}>
          <Text style={styles.count}>{count}</Text>
        </View>
      )}
    </Pressable>
  )
}

export default RuleButton

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g150,
    borderRadius: 6,
  },
  badge: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    right: -4,
    bottom: -4,
    minWidth: 16,
    height: 16,
    borderRadius: 20,
    paddingHorizontal: 4,
    backgroundColor: COLOR.primary.blue500,
  },
  count: {
    ...FONT.txt2XsBold,
    color: COLOR.mono.white,
  },
})
