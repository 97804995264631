import { COLOR, FONT } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import { Pressable, StyleSheet, Text, View, ViewStyle } from 'react-native'

interface Props {
  quantity: number
  setQuantity: (arg0: number) => void
  countStyle?: ViewStyle
  minQuantityNumber?: number
}
const QuantityButton = ({ quantity = 2, minQuantityNumber = 1, setQuantity, countStyle }: Props) => {
  return (
    <View style={styles.quantityContainer}>
      <Pressable
        style={[styles.controlButtons, quantity <= minQuantityNumber && styles.controlButtonsDisabled]}
        onPress={() => {
          quantity > minQuantityNumber && setQuantity(quantity - 1)
        }}
      >
        <XEIcon name={'minus-min'} size={22} color={'main.blue'} style={{ marginTop: -2 }} />
      </Pressable>
      <Text style={[styles.quantityCount, countStyle]}>{quantity}</Text>
      <Pressable style={styles.controlButtons} onPress={() => setQuantity(quantity + 1)}>
        <XEIcon name={'plus-min'} size={22} color={'main.blue'} style={{ marginTop: -2 }} />
      </Pressable>
    </View>
  )
}

export default QuantityButton

const styles = StyleSheet.create({
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  quantityCount: {
    minWidth: 40,
    textAlign: 'center',
    marginHorizontal: 4,
    ...FONT.H5Bold,
  },
  controlButtons: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 30,
  },
  controlButtonsDisabled: {
    backgroundColor: COLOR.gray.g050,
    borderColor: COLOR.gray.g150,
  },
})
