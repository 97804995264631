import * as React from 'react'

import type { TableorderTable } from '@rocket/types'
import Context from './context'
import Provider from './provider'
import View from './view'

interface Props {
  currentTable: TableorderTable
}
export const TableInfoView: React.FC<Props> = ({ currentTable }) => {
  return (
    <Provider>
      <Context.Consumer>{(context) => context && <View currentTable={currentTable} />}</Context.Consumer>
    </Provider>
  )
}
