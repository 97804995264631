import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'

export interface OrderItemProps {
  menu: string
  option?: string
  count: number
  estimatedCookingMinute: number
}

interface Props {
  item: OrderItemProps
  status?: boolean
}

const OrderItem: React.FC<Props> = ({ status, item }) => {
  return (
    <View style={[styles.orderItem, styles.flexBox, status && styles.styleComplete]}>
      <View style={styles.columFlex}>
        <Text style={[styles.menu, status && styles.menuComplete]}>{item.menu}</Text>
        <Text style={styles.option}>{item.option}</Text>
      </View>
      <View style={styles.columFlexV2}>
        <Text style={[styles.count, { marginLeft: 4, textAlign: 'right' }]}>{item.count}</Text>
      </View>
    </View>
  )
}

export default OrderItem

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  columFlex: {
    flex: 4,
  },
  columFlexV2: {
    flex: 1,
  },
  orderItem: {
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g150,
    paddingVertical: 8,
  },
  styleComplete: {
    opacity: 0.4,
  },
  menu: {
    ...FONT.txtMdBold,
  },
  menuComplete: {
    textDecorationLine: 'line-through',
  },
  option: {
    ...FONT.txtSm,
    color: COLOR.gray.g400,
  },
  count: {
    ...FONT.txtMdBold,
  },
})
