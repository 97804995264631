import { COLOR } from '@rocket-mono/libs'
import { Text } from '@rui/atoms'
import { FAFreeSolidSvgIcons, FAIcon } from '@rui/icons'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Pressable, StyleSheet, View } from 'react-native'
import MemberItem from './Item'
import type { MemberListItem } from './types'

const { faCheck } = FAFreeSolidSvgIcons

interface Props {
  secureCdnUrl: string
  inviteList: MemberListItem[]
  onAddMember?: () => void
}
const Item = ({ secureCdnUrl, item }) => {
  const { t } = useTranslation()

  const [isHover, setIsHover] = React.useState(false)

  return (
    <Pressable
      onPress={() => {
        if (item.state === '나') return
        if (item.selected || (typeof item.memberSelected !== undefined && !item.memberSelected)) {
          item.onInviteMember && item.onInviteMember(item.userId)
        }
      }}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      style={[isHover && { backgroundColor: COLOR.gray.g050 }]}
    >
      <View style={styles.itemContainer}>
        <MemberItem secureCdnUrl={secureCdnUrl} item={item} />
        {item.state !== '나' ? (
          item.selected || (typeof item.memberSelected !== undefined && !item.memberSelected) ? (
            <View style={styles.rightAreaContainer}>
              <View>
                <Pressable
                  style={[{ marginBottom: 4 }, item.selected ? styles.iconCheck : styles.iconCheckNone]}
                  onPress={() => {
                    item.onInviteMember && item.onInviteMember(item.userId)
                  }}
                >
                  <FAIcon iconName={faCheck} size={'sm'} color={item.selected ? 'mono.white' : 'transparent.base'} />
                </Pressable>
              </View>
            </View>
          ) : (
            <></>
          )
        ) : (
          <View>
            <Text fontName="textMedium">{t('checkparticipants.me')}</Text>
          </View>
        )}
      </View>
    </Pressable>
  )
}

const SelectMemberList = ({ secureCdnUrl, inviteList }: Props) => {
  return (
    <FlatList
      style={{ paddingHorizontal: 12 }}
      data={inviteList}
      keyExtractor={(_, index) => `member-list-invite-item_${index}`}
      renderItem={({ item }) => <Item secureCdnUrl={secureCdnUrl} item={item} />}
    />
  )
}

export default SelectMemberList

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 8,
    paddingRight: 4,
  },

  rightAreaContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'center',
  },

  iconCheckNone: {
    width: 28,
    height: 28,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: COLOR.mono.lightGray,
  },
  iconCheck: {
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLOR.main.blue,
    borderRadius: 30,
  },
})
