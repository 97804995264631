import * as React from 'react'

import { useAstro, useSubscription } from '@rocket-mono/providers'
import MemberInviteScreen from './components/MemberInviteScreen'
import { WorkMemberScreen } from './components/WorkMemberScreen'

interface Props {
  projectId?: string
  onProjectLeave: () => void
}

const ProjectMemberView: React.FC<Props> = ({ projectId, onProjectLeave }) => {
  const { astro, option } = useAstro()

  const [modal, setModal] = React.useState<{
    projectId: string
  }>()

  const onClose = () => {
    setModal(undefined)
  }

  const onMessage = (event: MessageEvent) => {
    const { action, payload } = event.data
    if (action === 'openModalInvitation') {
      setModal({ projectId: payload.projectId })
    }
  }

  React.useEffect(() => {
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [])
  return (
    <>
      <WorkMemberScreen
        astro={astro}
        projectId={projectId || ''}
        secureCdn={option.secureCdnUrl || ''}
        subscribe={useSubscription}
        onProjectLeave={onProjectLeave}
      />
      {modal ? <MemberInviteScreen onClose={onClose} /> : <></>}
    </>
  )
}

export default ProjectMemberView
