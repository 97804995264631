import * as React from 'react'
import { Path, Svg } from 'react-native-svg'

export const LeftIcon = () => {
  return (
    <Svg width="22" height="47" viewBox="0 0 22 47" fill="none">
      <Path d="M21 1L2 23.5L21 46" stroke="#B4BEC5" strokeWidth="2" />
    </Svg>
  )
}
