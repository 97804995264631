import { COLOR, FONT } from '@rui/foundations'
import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import { Button } from '../../../../components/Button'

interface OrderButtonProps {
  count: number
  onPress?: () => void
}
const BasketButtonBar: React.FC<OrderButtonProps> = ({ count, onPress }) => {
  return (
    <View style={styles.container}>
      <View style={styles.buttonArea}>
        <Button wide={true} backgroundColor="main.navy" style={{ height: 60, borderRadius: 0 }} onPress={onPress}>
          <Text style={styles.buttonText}>장바구니 보기</Text>
          <View style={styles.cartCount}>
            <Text style={styles.cartCountNum}>{count}</Text>
            <View style={styles.cartCountIcon}>
              <Svg width={29} height={32} viewBox="0 0 29 32" fill="none">
                <Path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.9294 2.73913H15.5765C17.5257 2.73913 19.1059 4.37426 19.1059 6.3913V8H9.39999V6.3913C9.39999 4.37426 10.9802 2.73913 12.9294 2.73913ZM6.75293 8V6.3913C6.75293 2.86148 9.51823 0 12.9294 0H15.5765C18.9876 0 21.7529 2.86148 21.7529 6.3913V8H26.5053C27.7019 8 28.6307 9.04376 28.4917 10.2323L26.153 30.2323C26.0352 31.2401 25.1813 32 24.1666 32H4.33897C3.32426 32 2.47036 31.2401 2.35251 30.2323L0.0137959 10.2323C-0.125185 9.04376 0.803636 8 2.00026 8H6.75293Z"
                  fill="#F85C60"
                />
              </Svg>
            </View>
          </View>
        </Button>
      </View>
    </View>
  )
}

export default BasketButtonBar

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  buttonArea: {
    flex: 1,
  },
  cartCount: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: 60,
    height: 60,
    right: 0,
  },
  cartCountNum: {
    ...FONT.txtSmBold,
    color: COLOR.mono.white,
    marginTop: 8,
    zIndex: 15,
  },
  cartCountIcon: {
    position: 'absolute',
  },
  buttonText: {
    ...FONT.H7Bold,
    color: COLOR.mono.white,
  },
})
