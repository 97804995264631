import { Text } from '@rui/atoms'
import { getBoxShadow, useMobileView } from '@rui/foundations'
import * as React from 'react'
import { FlatList, ListRenderItemInfo, Pressable, StyleSheet, View } from 'react-native'
import TopTab from '../../TopTab'
import { getNumberWithComma } from '../../utils'

import { useModalDialog } from '@rocket-mono/providers'
import { TableorderOrder, TableorderOrderBasketItem, TableorderOrderItemPayload, TableorderTable } from '@rocket/types'
import { useTranslation } from 'react-i18next'
import CartItem from '../../CartItem'
import CartTableInfo from '../../CartTableInfo'
import FooterPriceConfirm from '../../FooterPrice/FooterPriceConfirm'
import Header from '../../Header'
import OrderConfirmationItem, { OrderConfirmationItemProps } from '../../OrderConfirmationItem'
import {
  useTableorderCatalog,
  useTableorderFile,
  useTableorderMenuOption,
  useTableorderOrder,
  useTableorderShop,
  useTableorderTable,
} from '../../providers'

interface Props {
  tableId: string
  orderId?: string
  orderList: TableorderOrder[]
  onBack: () => void
  onSuccessPayment: (id: string) => void
}

const TableorderOrderBasketListView: React.FC<Props> = ({ tableId, orderId, orderList, onBack, onSuccessPayment }) => {
  const { t } = useTranslation()
  const isMobile = useMobileView(800)
  const [tab, setTab] = React.useState('cart')

  const { showDialogMessage } = useModalDialog()

  const { readFileUrl } = useTableorderFile()
  const { shopId, shop } = useTableorderShop()

  const { currentCatalog, catalogMenuList, catalogOptionList } = useTableorderCatalog()
  const { readTable } = useTableorderTable()
  const { createOrder, basket, basketItemList, increaseBasketItem, decreaseBasketItem, deleteBasketItem, clearBasket } =
    useTableorderOrder()
  const { menuOptionGroupList } = useTableorderMenuOption()

  const menuList = React.useMemo(() => currentCatalog?.categories.flatMap(({ menus }) => menus), [currentCatalog])

  const [currentTable, setCurrentTable] = React.useState<TableorderTable>()

  React.useEffect(() => {
    readTable(tableId).then(setCurrentTable)
  }, [tableId])

  const currentOrder = React.useMemo(() => {
    return orderList.length > 0 ? orderList[0] : undefined
  }, [orderId, orderList])

  const [orderItemList, setOrderItemList] = React.useState<OrderConfirmationItemProps[]>([])

  console.log('orderList-current', currentOrder, orderItemList)

  React.useEffect(() => {
    Promise.all(
      orderList
        .flatMap(({ items }) => items)
        .map((item) => {
          console.log('orderList-item', item)
          return new Promise<OrderConfirmationItemProps>((resolve, reject) => {
            const menu = menuList?.find(({ id }) => id === item.catalogMenuId)
            console.log('orderList-menu', { menuList, menu })
            if (menu) {
              const option = menu.optionGroups
                .flatMap(({ options }) => options)
                .filter(({ id }) => item.catalogSelectedOptionIds.includes(id))
                .map(({ name, price }) => ({
                  name,
                  price: getNumberWithComma(price),
                }))

              const title = menu.name ?? ''
              const price = menu.price ?? 0 * item.quantity

              const representative = menu.images.filter(({ isRepresentative }) => isRepresentative)

              if (representative.length > 0)
                readFileUrl(representative[0].path).then((image) =>
                  resolve({
                    state: 'sale',
                    image,
                    title,
                    count: `${item.quantity}`,
                    priceNumber: price,
                    price: getNumberWithComma(price),
                    option,
                  }),
                )
              else {
                resolve({
                  state: 'sale',
                  image: '',
                  title,
                  count: `${item.quantity}`,
                  priceNumber: price,
                  price: getNumberWithComma(price),
                  option,
                })
              }
            } else return reject()
          })
        }),
    ).then(setOrderItemList)
  }, [orderList, menuList])

  const totalPrice = React.useMemo(
    () =>
      basketItemList
        .map(({ selectedCatalogOptionIds, quantity, catalogMenuId }) => {
          const menu = catalogMenuList.find(({ id }) => id === catalogMenuId)

          const optionPrice = catalogOptionList
            .filter(({ id }) => selectedCatalogOptionIds.includes(id))
            .reduce((value, cur) => value + cur.price, 0)
          const menuPrice = menu?.price || 0
          const onePrice = menuPrice + optionPrice
          return onePrice * quantity
        })
        .reduce((prev, cur) => prev + cur, 0),
    [basketItemList, catalogMenuList, catalogOptionList],
  )

  const orderTotalPrice = React.useMemo(
    () => orderItemList.map(({ priceNumber }) => priceNumber).reduce((prev, cur) => prev + cur, 0),
    [orderItemList],
  )

  const renderItem = React.useCallback(
    ({ item, index }: ListRenderItemInfo<TableorderOrderBasketItem>) => {
      const { id, quantity, catalogMenuId } = item
      const menu = menuList?.find(({ id }) => id === catalogMenuId)

      const optionList = catalogOptionList.filter(({ id }) => item.selectedCatalogOptionIds.includes(id))

      const optionPrice = optionList.reduce((value, cur) => value + cur.price, 0)

      const menuPrice = menu?.price || 0
      const onePrice = menuPrice + optionPrice
      const totalPrice = onePrice * quantity
      const option = optionList.map(({ name, price }) => ({
        name,
        price: getNumberWithComma(price),
      }))

      const cartItem = {
        quantity,
        onePrice: getNumberWithComma(onePrice),
        status: '1',
        title: menu?.name || '',
        totalPrice: getNumberWithComma(totalPrice),
        option,
      }
      const borderBottomWidth = index === basketItemList.length - 1 ? 0 : 1

      return (
        <CartItem
          item={cartItem}
          containerStyle={{
            marginHorizontal: 20,
            borderBottomWidth,
          }}
          minQuantityNumber={0}
          increaseQuantity={() => increaseBasketItem(id)}
          decreaseQuantity={() => decreaseBasketItem(id)}
          onDelete={() => deleteBasketItem(id)}
        />
      )
    },
    [menuList, menuOptionGroupList, basketItemList],
  )

  const handlePressPayment = React.useCallback(() => {
    if (currentTable && basket && basketItemList.length > 0) {
      const { id: tableId, activeNumber: tableActiveNumber } = currentTable
      const { id: basketId } = basket
      const items: TableorderOrderItemPayload[] = basketItemList.map(
        ({ catalogCategoryId, catalogMenuId, quantity, selectedCatalogOptionIds: catalogSelectedOptionIds }) => ({
          catalogCategoryId,
          catalogMenuId,
          catalogSelectedOptionIds,
          quantity,
        }),
      )

      return createOrder({
        shopId,
        tableId,
        basketId,
        tableActiveNumber,
        customerRequest: '',
        paymentMethod: 'CASH',
        paymentWhen: 'POST',
        price: totalPrice,
        status: 'DONE',
        items,
      })
        .then((id) => {
          return new Promise<string>((resolve) => {
            const list = [
              {
                name: '주문서 보기',
                action: () => {
                  resolve(id)
                },
              },
            ]
            showDialogMessage({
              title: '결제 및 주문 완료',
              message: '주문해주셔서 감사합니다.\r\n맛있게 조리하여 가져다 드릴게요!',
              list,
            })
          })
        })
        .then(onSuccessPayment)
        .then(clearBasket)
    }

    return Promise.reject()
  }, [shopId, currentTable, basket, basketItemList, totalPrice])

  React.useEffect(() => {
    if (!!orderId) {
      setTab('order')
    }
  }, [orderId])

  return (
    <View
      style={{ flex: 1 }}
      onLayout={(event) => {
        console.log('event', event.nativeEvent.layout)
      }}
    >
      {isMobile ? (
        <>
          <Header buttonBack title={tab === 'cart' ? '장바구니' : '주문 내역'} textAlignCenter onBack={onBack} />
        </>
      ) : (
        <TopTab
          list={[
            {
              name: '장바구니',
              code: 'cart',
              ea: `${basketItemList.length}`,
              icon: 'cart-o',
              isShow: true,
            },
            { name: '주문서', code: 'order', icon: 'receipt', isShow: true },
          ]}
          code={tab}
          onCode={(code) => setTab(code)}
        />
      )}
      <View style={{ flex: 1 }}>
        {tab === 'cart' && (
          <>
            {isMobile && (
              <CartTableInfo storeName={shop?.name || ''} tableName={currentTable?.name || ''} height={80} />
            )}
            <FlatList
              data={basketItemList}
              keyExtractor={(_, idx) => `basket-item-${idx}`}
              renderItem={renderItem}
              style={{ flex: 1 }}
            />
            <FooterPriceConfirm totalPrice={`${totalPrice}`} />
            <Pressable style={styles.buttonStyle} onPress={handlePressPayment}>
              <Text fontName="subTitleBold" fontColor="mono.white">
                주문 결제하기
              </Text>
            </Pressable>
          </>
        )}
        {tab === 'order' && (
          <View style={{ margin: 20, flex: 1 }}>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 70 }}>
                <Text fontName="listBold" fontColor="mono.black">
                  테이블
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <View style={styles.leftBorder} />
                <Text fontName="listRegular" fontColor="mono.black">
                  {currentTable?.name || ''}
                </Text>
              </View>
              <View>{/* <OrderConfirmationInfo state={} time={orderTime} /> */}</View>
            </View>
            <View style={{ height: 10 }} />
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 70 }}>
                <Text fontName="listBold" fontColor="mono.black">
                  주문일시
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                }}
              >
                <View style={styles.leftBorder} />
                <Text fontName="listRegular" fontColor="mono.black">
                  {currentOrder
                    ? t('format.date.LLL', {
                        date: new Date(currentOrder.createdAt),
                      })
                    : ''}
                </Text>
              </View>
              <View></View>
            </View>
            <View style={styles.orderListContainer}>
              <OrderConfirmationItem list={orderItemList} containerStyle={styles.orderList} />
              <View style={styles.orderPriceContainer}>
                <Text fontName="txtSm">총 주문 금액</Text>
                <View style={{ width: 10 }} />
                <Text fontName="H5Bold" fontColor="mono.black">
                  {orderTotalPrice || 0}
                </Text>
                <Text fontName="H6Medium" fontColor="mono.black">
                  원
                </Text>
              </View>
            </View>
          </View>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  buttonStyle: {
    height: 60,
    backgroundColor: '#001B57',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftBorder: {
    marginTop: 4,
    marginBottom: 2,
    borderLeftColor: '#DDDDDD',
    borderLeftWidth: 1,
    marginRight: 8,
  },
  orderListContainer: {
    flex: 1,
    ...getBoxShadow(0, 0, 0, 0.07, 15, 0, 0, 5),
    marginTop: 20,
    overflow: 'hidden',
    borderRadius: 15,
  },
  orderList: {
    flex: 1,
    overflow: 'scroll',
    padding: 20,
    paddingBottom: 0,
  },
  orderPriceContainer: {
    height: 60,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
})

export { TableorderOrderBasketListView }
