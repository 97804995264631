import * as React from 'react'
import ContainerView from './containerView'

interface Props {
  isValid: boolean
  step: number
  submitStep: number
  onPressNext: () => void
  onPressBack: () => void
  onPressCreate: () => void
  children: JSX.Element[] | JSX.Element
}
const Container: React.FC<Props> = ({ children, ...props }) => {
  return <ContainerView {...props}>{children}</ContainerView>
}

export default Container
