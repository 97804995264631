import React from 'react'
import { Path, Svg } from 'react-native-svg'

interface Props {
  color: string
}

export const SwitchIconBoard: React.FC<Props> = ({ color }) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.07943 5.1741C7.42685 3.99446 9.12684 3.21106 10.9996 3.00458V4.92448C9.64917 5.11417 8.42033 5.685 7.42417 6.52529L6.07943 5.1741ZM12.9439 3V4.91864C16.4211 5.38562 19.1031 8.37789 19.1031 11.9996C19.1031 15.9452 15.9198 19.1438 11.993 19.1438C10.4929 19.1438 9.1013 18.6771 7.95423 17.8802L6.59606 19.2449C8.10012 20.3778 9.96855 21.049 11.993 21.049C16.9669 21.049 20.9991 16.9974 20.9991 11.9996C20.9991 7.32444 17.4708 3.47734 12.9439 3ZM6.55019 16.5967C5.80097 15.702 5.26624 14.62 5.02557 13.4306H3.09885C3.36977 15.1441 4.11934 16.6974 5.20556 17.9478L6.55019 16.5967ZM4.89831 11.5255C4.98496 10.1965 5.43326 8.96672 6.14528 7.93453L4.78734 6.57006C3.74504 7.96442 3.09427 9.67116 2.99902 11.5255H4.89831ZM10.8603 15.6498L17.1074 10.3513L15.8845 8.89547L10.9688 13.0646L8.63421 10.7188L7.29352 12.0659L10.8603 15.6498Z"
        fill={color}
      />
    </Svg>
  )
}

export const SwitchIconArchive: React.FC<Props> = ({ color }) => {
  return (
    <Svg width={26} height={26} viewBox="0 0 26 26" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.38149 4.42578H6.53422C5.78161 4.42578 5.4053 4.42578 5.11991 4.57689C4.88961 4.69883 4.70127 4.88717 4.57933 5.11747C4.42822 5.40286 4.42822 5.77917 4.42822 6.53178V19.4626C4.42822 20.2153 4.42822 20.5916 4.57933 20.8769C4.70127 21.1073 4.88961 21.2956 5.11991 21.4175C5.4053 21.5686 5.78161 21.5686 6.53422 21.5686H9.38149V4.42578ZM10.5243 21.5686H15.4773V4.42578H10.5243V21.5686ZM16.6202 4.42578V21.5686H19.4651C20.2177 21.5686 20.594 21.5686 20.8794 21.4175C21.1097 21.2956 21.298 21.1073 21.42 20.8769C21.5711 20.5916 21.5711 20.2153 21.5711 19.4626V6.53178C21.5711 5.77917 21.5711 5.40286 21.42 5.11747C21.298 4.88717 21.1097 4.69883 20.8794 4.57689C20.594 4.42578 20.2177 4.42578 19.4651 4.42578H16.6202ZM7.85633 8.23875C7.85633 8.65953 7.51521 9.00065 7.09442 9.00065C6.67364 9.00065 6.33252 8.65953 6.33252 8.23875C6.33252 7.81796 6.67364 7.47684 7.09442 7.47684C7.51521 7.47684 7.85633 7.81796 7.85633 8.23875ZM12.9998 9.00065C13.4206 9.00065 13.7617 8.65953 13.7617 8.23875C13.7617 7.81796 13.4206 7.47684 12.9998 7.47684C12.579 7.47684 12.2379 7.81796 12.2379 8.23875C12.2379 8.65953 12.579 9.00065 12.9998 9.00065ZM19.6672 8.23875C19.6672 8.65953 19.326 9.00065 18.9053 9.00065C18.4845 9.00065 18.1433 8.65953 18.1433 8.23875C18.1433 7.81796 18.4845 7.47684 18.9053 7.47684C19.326 7.47684 19.6672 7.81796 19.6672 8.23875ZM8.23851 15.8599H5.57185V16.8123H8.23851V15.8599ZM11.6677 15.8599H14.3344V16.8123H11.6677V15.8599ZM20.4281 15.8599H17.7614V16.8123H20.4281V15.8599ZM5.57185 17.5717H8.23851V18.5241H5.57185V17.5717ZM14.3342 17.5717H11.6676V18.5241H14.3342V17.5717ZM17.7613 17.5717H20.428V18.5241H17.7613V17.5717Z"
        fill={color}
      />
    </Svg>
  )
}
