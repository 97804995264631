import type { Channel, ChannelMember, Project, UserState } from '@rocket/types'
import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { removeItemAtIndex, replaceItemAtIndex } from '@rui/foundations'

import { useCurrentUser } from '@rocket-mono/providers'
import { parseSymbolicMessage } from '../../components'
import { ChatsContext } from './ChatsProviderContext'
import type {
  ChannelExtraType,
  ChatMessageType,
  ChatsProviderProps,
  ChatsScreenTabType,
  RenderItemType,
  SectionItemType,
  UserStateType,
} from './types'

export const ChatsProvider = ({
  astro,
  subscribe,
  children,
  secureCdnUrl,
  projectListData,
  guestListData,
}: ChatsProviderProps) => {
  const { currentUser } = useCurrentUser()
  const { t } = useTranslation()
  const [tabCode, onPressTab] = React.useState<ChatsScreenTabType>('WORK')
  const userName = React.useMemo(() => currentUser.userName, [currentUser])
  const userId = React.useMemo(() => String(currentUser.id), [currentUser])
  const [loadItems, setLoadItems] = React.useState<string[]>([])
  const [channelList, setChannelList] = React.useState<Channel[]>()
  const [channelExtraList, setChannelExtraList] = React.useState<ChannelExtraType[]>([])

  const [searchKeyword, onChangeSearchKeyword] = React.useState('')
  const [sectionsData, setSectionsData] = React.useState<SectionItemType[]>([])

  const [stateList, setStateList] = React.useState<UserStateType[]>([])
  const addUserState = React.useCallback((userState: UserStateType) => {
    setStateList((prev) => {
      const idx = prev.findIndex(({ userId }) => userId === userState.userId)
      return idx < 0 ? [...prev, userState] : replaceItemAtIndex(prev, idx, userState)
    })
  }, [])

  const [projectList, setProjectList] = React.useState<Project[] | undefined>(projectListData)
  const [guestList, setGuestList] = React.useState<Project[] | undefined>(guestListData)

  React.useEffect(() => {
    if (projectList && guestList)
      setSectionsData((prev) => {
        return [
          ...projectList.map((o) => ({
            key: o.id,
            title: o.title,
            typeCode: o.type.code,
            isLoading: sectionsData.length === 0,
            isShow: true,
            isFold: prev.find(({ key }) => key === o.id)?.isFold,
            isDefault: false,
            data: [],
          })),
          ...guestList.map((o) => ({
            key: o.id,
            title: o.title,
            typeCode: o.type.code,
            isLoading: sectionsData.length === 0,
            isShow: false,
            isFold: prev.find(({ key }) => key === o.id)?.isFold,
            isDefault: false,
            data: [],
          })),
          {
            key: 'GUEST',
            title: t('screen.chats.guestchat'),
            isLoading: false,
            isShow: true,
            isFold: prev.find(({ key }) => key === 'GUEST')?.isFold,
            isDefault: true,
            data: [],
          },
          {
            key: 'DIRECT',
            title: t('screen.chats.directchat'),
            isLoading: false,
            isShow: true,
            isFold: prev.find(({ key }) => key === 'DIRECT')?.isFold,
            isDefault: true,
            data: [],
          },
        ].map((o) => ({
          ...o,
          isFold: o.isFold === undefined ? true : o.isFold,
        }))
      })
  }, [projectList, guestList])

  const fetchProjectList = React.useCallback(() => {
    return Promise.all([
      astro.readProjectList(false).then((list) => list.sort((a, b) => Number(b.id) - Number(a.id))),
      astro.readProjectList(false, 0),
    ])
      .then(([projectList, guestList]) => {
        setProjectList(projectList)
        setGuestList(guestList)
      })
      .catch((err) => console.error(err))
  }, [])

  const updateProject = React.useCallback(
    (projectId: string) => {
      fetchProjectList()
      astro
        .readChannelList({ type: 'G', projectId })
        .then((list) => {
          setChannelList((prev) => {
            const prevList = prev ? prev.filter((o) => !list.map(({ id }) => id).includes(o.id)) : []
            return [...prevList, ...list]
          })
        })
        .catch((err) => console.error(err))
    },
    [astro, fetchProjectList],
  )

  const fetchChannelExtra = React.useCallback(
    (channelId: string, channelRoomId: string, roomType: string, isUnread: boolean) => {
      console.log('fetchChannelExtra')
      return Promise.all([
        channelId,
        isUnread ? astro.readChannelUnreadMessageCount(channelRoomId) : 0,
        astro.readChannelMessageCount(channelRoomId),
        astro
          .readChannelLastMessage(channelRoomId)
          .then((messageItem) => {
            console.log('fetchChannelExtra-last', messageItem)
            const isSymbol = !!messageItem.symbol
            const { key, args } = parseSymbolicMessage(messageItem.symbol)
            const content = isSymbol ? t(key, args) : messageItem.content
            return { ...messageItem, content }
          })
          .catch((e) => {
            console.log('fetchChannelExtra-last-error', e)
            return undefined
          }),
        astro.readChannelMemberList(channelId),
        roomType === 'S' ? astro.readChannelDiscussionOccurred(channelRoomId).catch(() => undefined) : undefined,
      ]).then(([key, unreadCount, messageCount, lastMessage, memberList, discussion]) => ({
        key,
        unreadCount,
        messageCount,
        lastMessage,
        memberList,
        discussion,
      }))
    },
    [astro],
  )

  const updateChannel = React.useCallback((channelRoomId: string) => {
    astro
      .readChannel(channelRoomId)
      .then((channel) => {
        setChannelList((prev) => {
          if (prev === undefined) return [channel]
          const idx = prev.findIndex(({ roomId }) => roomId === channelRoomId)
          return idx < 0 ? [...prev, channel] : replaceItemAtIndex(prev, idx, channel)
        })
        return channel
      })
      .then(({ id: key, roomId: channelRoomId, roomType, closed }) =>
        fetchChannelExtra(key, channelRoomId, roomType, !closed),
      )
      .then((channelExtra) => {
        setChannelExtraList((prev) => {
          const idx = prev.findIndex(({ key }) => key === channelExtra.key)
          return idx < 0 ? [...prev, channelExtra] : replaceItemAtIndex(prev, idx, channelExtra)
        })
      })
      .catch((err) => console.error(err))
  }, [])

  const readChannel = React.useCallback(
    (channelRoomId: string) => {
      const channel = channelList?.find(({ roomId }) => roomId === channelRoomId)
      if (channel) {
        setChannelExtraList((prev) => {
          const idx = prev.findIndex(({ key }) => key === channel.id)
          const newValue = { ...prev[idx], unreadCount: 0 }
          return idx < 0 ? prev : replaceItemAtIndex(prev, idx, newValue)
        })
      }
    },
    [channelList],
  )

  const deleteChannel = React.useCallback(
    (channelRoomId: string) => {
      const channel = channelList?.find(({ roomId }) => roomId === channelRoomId)
      if (channel) {
        setChannelList((prev) => {
          if (prev === undefined) return prev
          const idx = prev.findIndex(({ id }) => id === channel.id)
          return idx < 0 ? prev : removeItemAtIndex(prev, idx)
        })
        setChannelExtraList((prev) => {
          const idx = prev.findIndex(({ key }) => key === channel.id)
          return idx < 0 ? prev : removeItemAtIndex(prev, idx)
        })
      }
    },
    [channelList],
  )

  const fetchUnreadChannelList = React.useCallback(() => {
    channelList &&
      astro
        .readUnreadMessageList()
        .then((res) => {
          const set = new Set(res.map((o) => o.channelId))
          const channelIdList = [...set]
          return channelList.filter(({ id }) => channelIdList.includes(id))
        })
        .then((unreadChannelList) => {
          return Promise.all(
            unreadChannelList.map(({ id: key, roomId: channelRoomId, roomType, closed }) =>
              fetchChannelExtra(key, channelRoomId, roomType, !closed),
            ),
          )
        })
        .then((list) => {
          if (list.length > 0)
            setChannelExtraList((prev) => {
              const filteredPrev = prev.filter((o) => !list.map((o) => o.key).includes(o.key))
              return [...filteredPrev, ...list]
            })
        })
  }, [channelList])

  React.useEffect(() => {
    if (projectList === undefined || guestList === undefined) fetchProjectList()
  }, [astro, fetchProjectList])

  React.useEffect(() => {
    Promise.all([
      astro.readChannelList({ type: 'D' }).then((res) => res.filter((o) => o.projectId === 'null')),
      astro.readChannelList({ type: 'G' }),
      astro
        .readChannelList({ type: 'M' })
        .then((res) =>
          res.length === 0 ? astro.createChannel({ type: 'M', roomName: '' }).then((res) => [res]) : [res[0]],
        ),
    ])
      .then((list) => list.flat())
      .then((channelList) => {
        setChannelList(channelList)
      })
      .catch((err) => console.error(err))
  }, [])

  React.useEffect(() => {
    if (channelList) fetchUnreadChannelList()
  }, [channelList, fetchUnreadChannelList])

  subscribe(`/subscribe/v2/${currentUser.id}/rooms`, ({ body: channelRoomId }) => {
    updateChannel(channelRoomId)
  })
  subscribe(`/subscribe/${currentUser.id}/messages`, ({ body: channelRoomId }) => {
    readChannel(channelRoomId)
  })
  subscribe(`/subscribe/${currentUser.id}/rooms/leave`, ({ body: channelRoomId }) => {
    deleteChannel(channelRoomId)
  })
  subscribe(`/subscribe/${currentUser.id}/project`, ({ body: projectId }) => {
    updateProject(projectId)
  })
  subscribe('/subscribe/project/update', fetchProjectList)

  subscribe(
    stateList.map(({ userId }) => `/subscribe/${userId}/state`),
    ({ body, headers }) => {
      const state = String(body)
      const userState: UserState | undefined =
        state === '0'
          ? 'invisible'
          : state === '1'
          ? 'active'
          : state === '2'
          ? 'away'
          : state === '3'
          ? 'busy'
          : state === '4'
          ? 'off'
          : undefined

      const userId = headers.destination.split('/')[2]
      addUserState({ userId, userState })
    },
  )

  const itemList = React.useMemo<RenderItemType[] | undefined>(() => {
    return channelList
      ?.filter(({ roomName }) => (searchKeyword ? roomName.indexOf(searchKeyword) >= 0 : true))
      .filter(({ roomType, projectId }) => (roomType === 'D' && !projectId ? false : true))
      .map((data, _index, list) => {
        let projectId: string | undefined = data.projectId
        const channelExtra: ChannelExtraType | undefined = channelExtraList.find(({ key }) => key === data.id)
        if (channelExtra) {
          const { discussion } = channelExtra
          if (discussion) {
            const discussionChatroom = discussion.chatRoom
              ? discussion.chatRoom
              : discussion.chatRoomList && discussion.chatRoomList.length > 0
              ? discussion.chatRoomList[0]
              : undefined
            const channel = list.find((o) => o.roomId === discussionChatroom?.roomId)
            if (channel) projectId = channel.projectId
          }
        }
        return { ...data, projectId, channelExtra }
      })
      .map(
        ({
          id,
          roomId: channelRoomId,
          projectId: sectionKey,
          closed,
          lastMessageDate,
          roomType,
          roomName,
          channelExtra,
        }) => {
          const section = sectionsData.find(({ key }) => key === sectionKey)

          let message, profile, label

          let title:
            | {
                iconName?: 'forum-o' | 'user'
                title: string
                count?: number
              }
            | undefined = undefined
          let date: Date = lastMessageDate

          let type: ChatMessageType = roomType === 'D' ? 'DIRECT' : roomType === 'M' ? 'ME' : 'BOARD'

          if (section && !section.isShow) type = 'GUEST'

          if (channelExtra) {
            const { lastMessage, memberList, discussion } = channelExtra
            if (discussion) type = 'DISCUSSION'
            const directMember = memberList.find(({ userId }) => userId !== String(currentUser.id))
            const writer: ChannelMember | undefined = memberList.find(({ userId }) => {
              //@ts-ignore
              return userId === String(lastMessage?.writer?.userNo)
            })
            message = lastMessage?.content || ''

            const count =
              ['BOARD', 'DISCUSSION'].includes(type) && memberList.length > 0 ? memberList.length : undefined
            if (type === 'DIRECT') {
              profile = {
                userId: directMember?.userId,
                url: directMember ? `${secureCdnUrl}/profile/${directMember.email}` : undefined,
                alt: directMember?.name || '',
              }
              title = {
                iconName: 'user',
                title: directMember?.name || '',
                count,
              }
            } else if (type === 'ME') {
              profile = {
                userId: currentUser.userId,
                url: `${secureCdnUrl}/profile/${currentUser.userEmail}`,
                alt: currentUser.userName || '',
              }
              title = {
                iconName: 'user',
                title: t('screen.chats.tome'),
                count,
              }
            } else {
              profile = {
                userId: writer?.userId,
                url: writer ? `${secureCdnUrl}/profile/${writer.email}` : undefined,
                alt: lastMessage?.name || writer?.name || '',
              }
              title = {
                iconName: type === 'DISCUSSION' ? 'forum-o' : undefined,
                title: roomName,
                count,
              }
              label = section
                ? {
                    labelName: section.title,
                    labelCode: section.typeCode,
                  }
                : undefined
            }

            if (lastMessage) date = new Date(lastMessage.created)
          }

          return {
            key: id,
            channelRoomId,
            sectionKey,
            closed,
            label,
            type,
            title,
            profile,
            message,
            date,
            unreadCount: channelExtra?.unreadCount ?? 0,
            messageCount: channelExtra?.messageCount,
          }
        },
      )
  }, [sectionsData, channelList, channelExtraList, searchKeyword, currentUser, t, secureCdnUrl])

  const sectionList = React.useMemo<SectionItemType[]>(() => {
    return sectionsData
      .filter((o) => o.isShow)
      .map((o) => {
        let isLoading = o.isLoading
        const data: RenderItemType[] = []
        if (itemList) {
          if (o.key === 'DIRECT') {
            data.push(
              ...itemList.filter(({ type }) => type === 'ME'),
              ...itemList
                .filter(({ type }) => type === 'DIRECT')
                .sort((a, b) => {
                  const aCount = a.unreadCount > 0 ? 1 : 0
                  const bCount = b.unreadCount > 0 ? 1 : 0
                  const sortA = bCount - aCount
                  const sortB = a.date > b.date ? -1 : a.date < b.date ? 1 : 0
                  return sortA || sortB
                }),
            )
          } else if (o.key === 'GUEST') {
            data.push(...itemList.filter(({ type }) => type === 'GUEST'))
          } else {
            data.push(
              ...itemList.filter(({ sectionKey, type, closed }) => o.key === sectionKey && type === 'BOARD' && !closed),
            )
          }
          isLoading = false
        }

        return {
          ...o,
          isLoading,
          data,
        }
      })
  }, [sectionsData, itemList])

  React.useEffect(() => {
    if (channelList && loadItems.length > 0) {
      const loadChannelList = channelList.filter(({ id }) => loadItems.includes(id))
      setLoadItems([])
      Promise.all(
        loadChannelList
          .map((o) => {
            const extra = channelExtraList.find(({ key }) => key === o.id)
            return { ...o, extra }
          })
          .filter(({ extra }) => extra === undefined)
          .map(({ id: key, roomId: channelRoomId, roomType }) =>
            fetchChannelExtra(key, channelRoomId, roomType, false),
          ),
      )
        .then((list) => {
          if (list.length)
            setChannelExtraList((prev) => {
              const filteredPrev = prev.filter((o) => !list.map((o) => o.key).includes(o.key))
              return [...filteredPrev, ...list]
            })
        })
        .catch((err) => console.error(err))
    }
  }, [channelList, loadItems, channelExtraList, fetchChannelExtra])

  const onLoadItem = React.useCallback((itemKey: string) => {
    setLoadItems((prev) => {
      const idx = prev.findIndex((key) => key === itemKey)
      return idx < 0 ? [...prev, itemKey] : prev
    })
  }, [])

  const onPressSection = React.useCallback((sectionKey: string) => {
    setSectionsData((prev) => {
      const idx = prev.findIndex((o) => o.key === sectionKey)
      const section = prev[idx]
      const newSection = { ...section, isFold: !section.isFold }
      return replaceItemAtIndex(prev, idx, { ...newSection })
    })
  }, [])

  React.useEffect(() => {
    if (itemList) {
      const userIdList = itemList
        .map((o) => {
          return o?.profile?.userId
        })
        .filter((o) => o !== undefined && o !== null) as string[]

      const userIdSet = new Set(userIdList)
      const userIdSetList = [...userIdSet]
      Promise.all(
        userIdSetList.map((userId) => astro.readUserState(userId).then((userState) => ({ userId, userState }))),
      )
        .then(setStateList)
        .catch((err) => console.error(err))
    }
  }, [astro, itemList])

  return (
    <ChatsContext.Provider
      value={{
        sectionList,
        itemList,
        onLoadItem,
        onPressSection,
        userName,
        userId,
        tabCode,
        onPressTab,
        searchKeyword,
        onChangeSearchKeyword,
        stateList,
        fetchProjectList,
        fetchUnreadChannelList,
        updateProject,
        updateChannel,
        readChannel,
      }}
    >
      {children}
    </ChatsContext.Provider>
  )
}
