import React from 'react'
import Svg, { Defs, G, LinearGradient, Mask, Path, Stop } from 'react-native-svg'
export const EmptyTemplate: React.FC = () => (
  <Svg width={217} height={137} viewBox="0 0 217 137" fill="none">
    <Mask
      id="mask0_3487_445706"
      // style={{
      //   maskType: 'alpha',
      // }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={217}
      height={137}
    >
      <Path d="M0 0H217V137H0V0Z" fill="#D9D9D9" />
    </Mask>
    <G mask="url(#mask0_3487_445706)">
      <Path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.915 125.121C200.623 123.908 213.206 118.928 215.657 110.309C218.446 100.499 207.553 87.9368 187.918 75.8313L187.811 76.7139C188.732 77.2848 189.633 77.8565 190.514 78.4288C199.328 84.1544 206.08 89.912 210.294 95.3514C214.518 100.805 216.093 105.802 214.874 110.09C213.654 114.379 209.678 117.83 203.199 120.302C197.704 122.4 190.521 123.746 182.014 124.308L181.915 125.121ZM38.3006 36.2786C28.1094 36.6589 19.5895 38.0761 13.2886 40.481C6.81036 42.9535 2.83369 46.4045 1.6144 50.6929C0.395114 54.9814 1.96981 59.9785 6.19448 65.4321C10.4082 70.8714 17.16 76.629 25.9741 82.3546C26.6844 82.816 27.4078 83.277 28.1441 83.7375L27.9644 84.5771C8.71176 72.5876 -1.92882 60.1832 0.831456 50.4749C3.44872 41.2695 17.6232 36.2149 38.4744 35.4667L38.3006 36.2786Z"
        fill="#A3B7ED"
      />
      <Path
        d="M43.3406 10.6471C44.4521 5.64092 48.8919 2.07874 54.0199 2.07874H189.399C196.397 2.07874 201.595 8.55794 200.079 15.3892L172.412 140H14.6212L43.3406 10.6471Z"
        fill="url(#paint0_linear_3487_445706)"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.399 3.90197H54.0199C49.7465 3.90197 46.0467 6.87046 45.1205 11.0423L16.8936 138.177H170.949L198.299 14.994C199.563 9.3013 195.231 3.90197 189.399 3.90197ZM172.412 140L200.079 15.3892C201.595 8.55794 196.397 2.07874 189.399 2.07874H54.0199C48.8919 2.07874 44.4521 5.64092 43.3406 10.6471L14.6212 140H172.412Z"
        fill="black"
        fillOpacity={0.02}
      />
      <Path
        opacity={0.7}
        d="M43.2997 10.5806C44.4367 5.60631 48.8615 2.07874 53.9641 2.07874H189.312C196.338 2.07874 201.542 8.60676 199.976 15.4557L199.781 16.3125H41.9896L43.2997 10.5806Z"
        fill="#A3B7ED"
      />
      <Path
        opacity={0.5}
        d="M47.7769 53.2003C48.5703 49.9297 51.4988 47.6267 54.8643 47.6267H77.4504C82.174 47.6267 85.6514 52.0485 84.5378 56.639L64.9789 137.265H27.3838L47.7769 53.2003Z"
        fill="url(#paint1_linear_3487_445706)"
      />
      <Path
        opacity={0.5}
        d="M90.7256 53.3988C91.4433 50.0328 94.4165 47.6267 97.8582 47.6267H120.82C125.46 47.6267 128.92 51.9025 127.953 56.4404L120.137 93.096C119.419 96.462 116.446 98.8681 113.004 98.8681H90.0425C85.4026 98.8681 81.9423 94.5923 82.9099 90.0544L90.7256 53.3988Z"
        fill="url(#paint2_linear_3487_445706)"
      />
      <Path
        opacity={0.3}
        d="M52.5632 28.9194C52.5632 27.1226 54.0198 25.666 55.8166 25.666H159.926C161.723 25.666 163.18 27.1226 163.18 28.9194C163.18 30.7163 161.723 32.1729 159.926 32.1729H55.8166C54.0198 32.1729 52.5632 30.7163 52.5632 28.9194Z"
        fill="#A3B7ED"
      />
      <Path
        opacity={0.3}
        d="M50.9365 38.6797C50.9365 36.8829 52.3931 35.4263 54.1899 35.4263H124.952C126.749 35.4263 128.205 36.8829 128.205 38.6797C128.205 40.4765 126.749 41.9331 124.952 41.9331H54.1899C52.3931 41.9331 50.9365 40.4765 50.9365 38.6797Z"
        fill="#A3B7ED"
      />
      <Path
        d="M209.707 88.9494C209.707 91.4668 207.666 93.5075 205.149 93.5075C202.632 93.5075 200.591 91.4668 200.591 88.9494C200.591 86.4321 202.632 84.3914 205.149 84.3914C207.666 84.3914 209.707 86.4321 209.707 88.9494Z"
        fill="#CDD9FA"
      />
      <Path
        opacity={0.5}
        d="M135.085 53.3629C135.817 50.014 138.782 47.6267 142.21 47.6267H164.875C169.53 47.6267 172.994 51.9287 172 56.4764L157.394 123.324C156.662 126.673 153.697 129.061 150.269 129.061H127.604C122.949 129.061 119.485 124.759 120.479 120.211L135.085 53.3629Z"
        fill="url(#paint3_linear_3487_445706)"
      />
      <Path
        d="M56.8029 12.452C56.8029 13.5566 55.9075 14.452 54.8029 14.452C53.6983 14.452 52.8029 13.5566 52.8029 12.452C52.8029 11.3475 53.6983 10.452 54.8029 10.452C55.9075 10.452 56.8029 11.3475 56.8029 12.452Z"
        fill="white"
      />
      <Path
        d="M63.8029 12.452C63.8029 13.5566 62.9075 14.452 61.8029 14.452C60.6983 14.452 59.8029 13.5566 59.8029 12.452C59.8029 11.3475 60.6983 10.452 61.8029 10.452C62.9075 10.452 63.8029 11.3475 63.8029 12.452Z"
        fill="white"
      />
      <Path
        d="M70.8029 12.452C70.8029 13.5566 69.9075 14.452 68.8029 14.452C67.6983 14.452 66.8029 13.5566 66.8029 12.452C66.8029 11.3475 67.6983 10.452 68.8029 10.452C69.9075 10.452 70.8029 11.3475 70.8029 12.452Z"
        fill="white"
      />
      <Path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7824 85.3522C48.1218 95.7406 71.8853 105.738 98.8986 113.261C128.502 121.507 156.341 125.203 177.406 124.532C179.076 124.479 180.703 124.398 182.283 124.29L182.033 125.114C160.4 126.537 130.542 122.911 98.6782 114.036C71.6806 106.517 47.8963 96.5255 30.4902 86.1181L30.7824 85.3522Z"
        fill="#A3B7ED"
      />
      <Path
        d="M55.7803 88.106C55.7803 98.2131 47.5868 106.407 37.4798 106.407C27.3727 106.407 19.1793 98.2131 19.1793 88.106C19.1793 77.999 27.3727 69.8055 37.4798 69.8055C47.5868 69.8055 55.7803 77.999 55.7803 88.106Z"
        fill="#58C5E3"
      />
      <Path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.4798 102.241C45.2866 102.241 51.6152 95.9128 51.6152 88.106C51.6152 80.2992 45.2866 73.9706 37.4798 73.9706C29.673 73.9706 23.3443 80.2992 23.3443 88.106C23.3443 95.9128 29.673 102.241 37.4798 102.241ZM37.4798 103.153C45.79 103.153 52.5268 96.4163 52.5268 88.106C52.5268 79.7958 45.79 73.059 37.4798 73.059C29.1695 73.059 22.4327 79.7958 22.4327 88.106C22.4327 96.4163 29.1695 103.153 37.4798 103.153Z"
        fill="white"
      />
      <Path
        d="M38.1007 93.2796V91.9176H42.2029V85.0751H39.4628V82.3349H32.6203V91.9176H36.7226V93.2796H31.9473V94.6417H42.876V93.2796H38.1007ZM39.4628 86.4372H40.8248V87.8153H39.4628V86.4372ZM39.4628 89.1774H40.8248V90.5394H39.4628V89.1774ZM35.3605 90.5394H33.9984V89.1774H35.3605V90.5394ZM35.3605 87.8153H33.9984V86.4372H35.3605V87.8153ZM35.3605 85.0751H33.9984V83.713H35.3605V85.0751ZM36.7226 83.713H38.1007V85.0751H36.7226V83.713ZM36.7226 86.4372H38.1007V87.8153H36.7226V86.4372ZM36.7226 89.1774H38.1007V90.5394H36.7226V89.1774Z"
        fill="white"
      />
      <Path
        d="M217 53.8523C217 70.2151 203.735 83.4798 187.372 83.4798C171.01 83.4798 157.745 70.2151 157.745 53.8523C157.745 37.4894 171.01 24.2247 187.372 24.2247C203.735 24.2247 217 37.4894 217 53.8523Z"
        fill="#6258BA"
      />
      <Path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M187.372 78.4099C200.935 78.4099 211.93 67.4151 211.93 53.8523C211.93 40.2895 200.935 29.2946 187.372 29.2946C173.81 29.2946 162.815 40.2895 162.815 53.8523C162.815 67.4151 173.81 78.4099 187.372 78.4099ZM187.372 79.3216C201.439 79.3216 212.842 67.9186 212.842 53.8523C212.842 39.786 201.439 28.383 187.372 28.383C173.306 28.383 161.903 39.786 161.903 53.8523C161.903 67.9186 173.306 79.3216 187.372 79.3216Z"
        fill="white"
      />
      <Path
        d="M195.359 56.911V53.1052C195.359 52.853 195.263 52.6378 195.07 52.4598C194.892 52.2669 194.669 52.1704 194.402 52.1704H187.77V49.3216H189.661V43.624H183.964V49.3216H185.856V52.1704H179.223C178.956 52.1704 178.726 52.2669 178.533 52.4598C178.355 52.6378 178.266 52.853 178.266 53.1052V56.911H176.375V62.6086H182.072V56.911H180.158V54.0622H185.856V56.911H183.964V62.6086H189.661V56.911H187.77V54.0622H193.467V56.911H191.553V62.6086H197.251V56.911H195.359Z"
        fill="white"
      />
      <Path
        d="M75.6995 104.447C75.6995 109.482 71.618 113.563 66.5833 113.563C61.5486 113.563 57.4672 109.482 57.4672 104.447C57.4672 99.4123 61.5486 95.3308 66.5833 95.3308C71.618 95.3308 75.6995 99.4123 75.6995 104.447Z"
        fill="#B48BD3"
      />
      <Path
        d="M69.0668 102.799L66.4921 100.224L63.9175 102.799C63.5614 103.155 63.2944 103.561 63.1163 104.017C62.9383 104.466 62.8492 104.918 62.8492 105.374C62.8492 105.829 62.9383 106.285 63.1163 106.741C63.2944 107.197 63.5614 107.603 63.9175 107.959C64.2665 108.315 64.6654 108.582 65.114 108.76C65.5627 108.938 66.0221 109.027 66.4921 109.027C66.9622 109.027 67.4216 108.938 67.8703 108.76C68.3189 108.582 68.7178 108.315 69.0668 107.959C69.4229 107.603 69.6899 107.197 69.868 106.741C70.046 106.285 70.1351 105.829 70.1351 105.374C70.1351 104.918 70.046 104.466 69.868 104.017C69.6899 103.561 69.4229 103.155 69.0668 102.799ZM63.7573 105.545C63.7573 105.089 63.825 104.704 63.9603 104.391C64.1027 104.077 64.3021 103.792 64.5585 103.536L66.4921 101.56L68.4258 103.557C68.6822 103.814 68.878 104.095 69.0133 104.401C69.1558 104.708 69.227 105.089 69.227 105.545H63.7573Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M145.812 64.3358C140.676 64.3358 136.232 67.9081 135.128 72.9235L133.322 81.128C131.82 87.9541 137.016 94.4192 144.006 94.4192H157.665C157.254 96.2357 156.164 98.2857 154.098 99.433C156.223 99.3081 161.082 98.0934 163.957 94.4192H165.12C170.255 94.4192 174.7 90.8469 175.804 85.8315L177.61 77.6269C179.112 70.8009 173.915 64.3358 166.926 64.3358H145.812Z"
        fill="#1F2940"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.261 80.7449C148.017 80.7449 148.629 80.1327 148.629 79.3775C148.629 78.6223 148.017 78.0101 147.261 78.0101C146.506 78.0101 145.894 78.6223 145.894 79.3775C145.894 80.1327 146.506 80.7449 147.261 80.7449ZM147.261 81.6565C148.52 81.6565 149.54 80.6362 149.54 79.3775C149.54 78.1188 148.52 77.0984 147.261 77.0984C146.003 77.0984 144.982 78.1188 144.982 79.3775C144.982 80.6362 146.003 81.6565 147.261 81.6565Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M155.466 80.7449C156.221 80.7449 156.833 80.1327 156.833 79.3775C156.833 78.6223 156.221 78.0101 155.466 78.0101C154.711 78.0101 154.099 78.6223 154.099 79.3775C154.099 80.1327 154.711 80.7449 155.466 80.7449ZM155.466 81.6565C156.725 81.6565 157.745 80.6362 157.745 79.3775C157.745 78.1188 156.725 77.0984 155.466 77.0984C154.207 77.0984 153.187 78.1188 153.187 79.3775C153.187 80.6362 154.207 81.6565 155.466 81.6565Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M163.67 80.7449C164.426 80.7449 165.038 80.1327 165.038 79.3775C165.038 78.6223 164.426 78.0101 163.67 78.0101C162.915 78.0101 162.303 78.6223 162.303 79.3775C162.303 80.1327 162.915 80.7449 163.67 80.7449ZM163.67 81.6565C164.929 81.6565 165.95 80.6362 165.95 79.3775C165.95 78.1188 164.929 77.0984 163.67 77.0984C162.412 77.0984 161.391 78.1188 161.391 79.3775C161.391 80.6362 162.412 81.6565 163.67 81.6565Z"
        fill="white"
      />
    </G>
    <Defs>
      <LinearGradient
        id="paint0_linear_3487_445706"
        x1={113.987}
        y1={6.904}
        x2={111.531}
        y2={119.202}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#D5E0FF" />
        <Stop offset={1} stopColor="#F0F7FF" stopOpacity={0.81} />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_3487_445706"
        x1={59.793}
        y1={47.6267}
        x2={59.793}
        y2={118.389}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#D1DFFA" />
        <Stop offset={1} stopColor="#D9E4FA" stopOpacity={0.83} />
      </LinearGradient>
      <LinearGradient
        id="paint2_linear_3487_445706"
        x1={105.431}
        y1={47.6267}
        x2={105.431}
        y2={98.8681}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#D1DFFA" />
        <Stop offset={1} stopColor="#D9E4FA" stopOpacity={0.83} />
      </LinearGradient>
      <LinearGradient
        id="paint3_linear_3487_445706"
        x1={147.003}
        y1={47.6267}
        x2={147.003}
        y2={118.389}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#D1DFFA" />
        <Stop offset={1} stopColor="#D9E4FA" stopOpacity={0.83} />
      </LinearGradient>
    </Defs>
  </Svg>
)
