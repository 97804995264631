import { SkeletonBar } from '@rocket/skeleton'
import { COLOR } from '@rui/foundations'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, TextInput, View } from 'react-native'
import { cellStyles } from '.'

interface WorkMemberTableItemType {
  row: number
  column: number
  isLoading: boolean
  onlyRead?: boolean
  cellValue?: string
  onChangeValue?: (value: string) => void
  onPressTab: () => void
}
const WorkMembersTableInputTextItem = ({
  row,
  column,
  isLoading,
  onlyRead,
  cellValue,
  onChangeValue,
  onPressTab,
}: WorkMemberTableItemType) => {
  const { t } = useTranslation()
  const contentsInput = React.useRef<any>(null)
  const [contents, setContents] = React.useState(cellValue)
  const [cellHover, setCellHover] = React.useState(false)

  useEffect(() => {
    setContents(cellValue)
  }, [cellValue])

  useEffect(() => {
    const handleMessage = (message: MessageEvent) => {
      const { action, payload } = message.data
      if (action === 'member-table-tab' && payload.row === row && payload.column === column) {
        contentsInput.current.focus()
      }
    }
    window.addEventListener('message', handleMessage)
  }, [row, column])

  return !isLoading ? (
    <Pressable
      onHoverIn={() => setCellHover(true)}
      onHoverOut={() => setCellHover(false)}
      style={[cellStyles.cells, cellHover && { backgroundColor: COLOR.gray.g050 }]}
    >
      <TextInput
        ref={contentsInput}
        value={contents}
        editable={!onlyRead}
        placeholder={cellHover && !onlyRead ? t('workmember.table.inputplaceholder') : ''}
        placeholderTextColor={COLOR.gray.g400}
        style={[cellStyles.cellInput, cellStyles.cellText, cellStyles.flexBox]}
        autoComplete="off"
        onChangeText={(text) => {
          setContents(text)
        }}
        onKeyPress={(e) => {
          if (e.nativeEvent.key === 'Enter') {
            onChangeValue && onChangeValue(contents || '')
            setContents(contents)
          } else if (e.nativeEvent.key === 'Escape') {
            setContents(cellValue)
          } else if (e.nativeEvent.key === 'Tab') {
            onChangeValue && onChangeValue(contents || '')
            setContents(contents)
            onPressTab()
          }
        }}
        onBlur={() => {
          onChangeValue && onChangeValue(contents || '')
          setContents(contents)
        }}
      />
    </Pressable>
  ) : (
    <View style={cellStyles.cells}>
      <View style={[cellStyles.flexBox, { alignItems: 'flex-start' }]}>
        <SkeletonBar width={'70%'} height={12} />
      </View>
    </View>
  )
}

export default WorkMembersTableInputTextItem
