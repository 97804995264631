import React from 'react'
import { StyleSheet, View } from 'react-native'

interface Props {
  currentRow: boolean
  children: JSX.Element | JSX.Element[]
}

const TableBodyRow: React.FC<Props> = ({ children, currentRow }) => {
  return <View style={[styles.groupRow, currentRow && { zIndex: 1 }]}>{children}</View>
}

export default TableBodyRow

const styles = StyleSheet.create({
  groupRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
