import { View } from '@rocket-mono/foundations'
import { Text } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import React, { useState } from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { DropdownButton } from '../../Dropdown'
import List from '../../Dropdown/List'

// const PERSON_LIST = [0, 1, 2, 3, 4, 5, 6, 7, 8]

interface Props {
  tableName: string
  allowableHeadcount: number
  onChangePerson?: (count: number) => void
  onPressOrder?: () => void
  onPressQr?: () => void
}

const TableInfoHeader: React.FC<Props> = ({
  tableName,
  allowableHeadcount,
  onChangePerson,
  onPressOrder,
  onPressQr,
}) => {
  const [code, setCode] = useState('0')
  const [isOpen, setIsOpen] = useState(false)

  const dropdownList = React.useMemo(() => {
    return [
      { id: 0, title: '0명', code: '0' },
      { id: 1, title: '1명', code: '1' },
      { id: 2, title: '2명', code: '2' },
      { id: 3, title: '3명', code: '3' },
      { id: 4, title: '4명', code: '4' },
      { id: 5, title: '5명', code: '5' },
      { id: 6, title: '6명', code: '6' },
      { id: 7, title: '7명', code: '7' },
      { id: 8, title: '8명', code: '8' },
    ].filter((_o, idx) => idx <= allowableHeadcount)
  }, [allowableHeadcount])

  return (
    <View style={styles.tableInfoContainer}>
      <View style={[styles.flexBox, styles.tableInfoWrap]}>
        <Text fontName="textBold">테이블</Text>
        <View style={styles.divLine}></View>
        <Text fontName="textMedium" fontColor="gray.g700">
          {tableName}
        </Text>
        <View
          style={{
            flex: 1,
            justifyContent: 'flex-end',
            flexDirection: 'row',
          }}
        >
          {onPressQr && (
            <Pressable
              style={{
                width: 65,
                height: 24,
                flexDirection: 'row',
                borderWidth: 1,
                borderRadius: 30,
                borderColor: COLOR.gray.g200,
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 4,
              }}
              onPress={onPressQr}
            >
              <XEIcon name="qr-code" size={12} color="gray.g700" />
              <Text fontName="txtXs" fontColor="gray.g700">
                QR코드
              </Text>
            </Pressable>
          )}
          {onPressOrder && (
            <Pressable
              style={{
                width: 65,
                height: 24,
                flexDirection: 'row',
                borderWidth: 1,
                borderRadius: 30,
                borderColor: COLOR.gray.g200,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={onPressOrder}
            >
              <XEIcon name="external-link" size={12} color="gray.g700" />
              <Text fontName="txtXs" fontColor="gray.g700">
                메뉴판
              </Text>
            </Pressable>
          )}
        </View>
      </View>
      <View style={[styles.flexBox, styles.usersInfo]}>
        <View>
          <View style={[styles.flexBox, { marginBottom: 4 }]}>
            {/* <Icon name={'time-o'} size={14} />
            <Text style={{ ...FONT.txtSm }}>
              <>13:10</>
            </Text>
            <Text style={{ ...FONT.txtSm, color: COLOR.gray.g700 }}>
              (입장 후 13분)
            </Text> */}
          </View>
          <View style={[styles.flexBox, { marginBottom: 4 }]}>
            {/* <Icon name={'eye-o'} size={14} />
            <Text style={{ ...FONT.txtSm }}>User1, User2</Text>
            <Text style={{ ...FONT.txtSm, color: COLOR.gray.g700 }}>
              님 메뉴 보는중
            </Text> */}
          </View>
        </View>
        <View style={{ width: 150, zIndex: 10 }}>
          <DropdownButton
            code={code}
            isOpen={isOpen}
            onOpen={(open) => setIsOpen(open)}
            dropdownList={dropdownList}
            onDrop={() => console.log('gd')}
            isEnable
            style={{
              backgroundColor: COLOR.mono.white,
              borderWidth: 0,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            text={
              <Text>
                <Text fontName="titleBold">{code}</Text>
                <Text fontName="textMedium">명</Text>
              </Text>
            }
            // txtStyle={{ ...FONT.titleBold }}
          />
          <List
            code={code}
            isOpen={isOpen}
            offsetY={4}
            onOpen={(open) => setIsOpen(open)}
            dropdownList={dropdownList}
            onCode={(code) => {
              setCode(code)
              onChangePerson && onChangePerson(Number(code))
            }}
            style={{
              borderTopWidth: 1,
              borderColor: COLOR.gray.g150,
            }}
          />
        </View>
      </View>
    </View>
  )
}

export default TableInfoHeader

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableInfoContainer: {
    paddingVertical: 16,
    paddingHorizontal: 20,
    borderBottomWidth: 1,
    borderColor: COLOR.gray.g200,
    zIndex: 99,
  },
  tableInfoWrap: {
    marginBottom: 12,
  },
  divLine: {
    width: 1,
    height: 12,
    backgroundColor: COLOR.gray.g200,
    marginHorizontal: 8,
  },
  usersInfo: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
})
