import { COLOR, FONT, getBoxShadow, getRealColor } from '@rocket-mono/libs'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, Platform, StyleSheet, Text, View } from 'react-native'
import Svg, { Path, Rect } from 'react-native-svg'
import type { WorkmodelWorkTypeProps } from './types'

const WorkmodelPreviewHierarchy = ({ title, goal, list, step, mobileView }: WorkmodelWorkTypeProps) => {
  const { t } = useTranslation()
  const [item1, item2, item3] = list
  const [fadeAnim] = useState(new Animated.Value(0))

  React.useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 30,
      duration: 800,
      useNativeDriver: false,
    }).start()
  }, [])
  return (
    <View
      style={{
        position: 'relative',
        width: mobileView ? 318 : 476,
        height: mobileView ? 215 : 345,
      }}
    >
      <View style={[styles.preview, mobileView && styles.previewMobile]}>
        <View style={[styles.titleArea, mobileView && styles.titleAreaMobile]}>
          <Text style={[styles.title, mobileView && { ...FONT.txtXsBold, width: 230 }]} numberOfLines={1}>
            {title ? title : t('workpreview.base.untitle')}
          </Text>
        </View>
        <View style={[styles.goalTitleArea, mobileView && styles.goalTitleAreaMobile]}>
          <Text style={[styles.goal, mobileView && { ...FONT.txt3Xs, width: 200 }]} numberOfLines={1}>
            {t('workpreview.base.goal')}
          </Text>
        </View>
        <View style={[styles.goalArea, mobileView && styles.goalAreaMobile]}>
          <Text style={[styles.goal, mobileView && { ...FONT.txt3Xs, width: 200 }]} numberOfLines={1}>
            {goal}
          </Text>
        </View>
        <View style={[styles.board, styles.item1, mobileView && { top: 55, left: 59 }]}>
          <Text style={[styles.boardText, mobileView && { ...FONT.txt3Xs, width: 60 }]} numberOfLines={1}>
            {item1?.title}
          </Text>
        </View>
        <View style={[styles.board, styles.item2, mobileView && { top: 55, left: 144 }]}>
          <Text style={[styles.boardText, mobileView && { ...FONT.txt3Xs, width: 60 }]} numberOfLines={1}>
            {item2?.title || ''}
          </Text>
        </View>
        <View style={[styles.board, styles.item3, mobileView && { top: 55, left: 232 }]}>
          <Text style={[styles.boardText, mobileView && { ...FONT.txt3Xs, width: 60 }]} numberOfLines={1}>
            {item3?.title}
          </Text>
        </View>

        {step === 2 && (
          <Animated.View
            style={[
              commonStyles.infoBox,
              {
                right: mobileView ? 0 : -24,
                bottom: mobileView ? 0 : 0,
                ...Platform.select({
                  web: { marginBottom: fadeAnim },
                  native: { right: 0, bottom: 40 },
                }),
              },
            ]}
          >
            <Text style={commonStyles.infoText}>{t('workpreview.dsp.desc1')}</Text>
            <Text style={commonStyles.infoText}>{t('workpreview.dsp.desc2')}</Text>
            <Text style={commonStyles.infoText}>
              {t('workpreview.dsp.desc3')}
              <View
                style={{
                  flexDirection: 'row',
                  ...Platform.select({
                    native: { marginTop: -3 },
                  }),
                }}
              >
                <View style={[commonStyles.infoTextLabel, { borderColor: COLOR.main.turquoise }]}>
                  <Text style={commonStyles.infoLabelText}>{t('workpreview.dsp.desc4')}</Text>
                </View>
                <View style={[commonStyles.infoTextLabel, { borderColor: COLOR.sub.pink }]}>
                  <Text style={commonStyles.infoLabelText}>{t('workpreview.dsp.desc5')}</Text>
                </View>
                <View style={[commonStyles.infoTextLabel, { borderColor: COLOR.sub.green }]}>
                  <Text style={commonStyles.infoLabelText}>{t('workpreview.dsp.desc6')}</Text>
                </View>
              </View>
              {t('workpreview.dsp.desc7')}
            </Text>
          </Animated.View>
        )}
        <Svg width={mobileView ? 318 : 476} height={mobileView ? 215 : 345} viewBox="0 0 476 345" fill="none">
          <Rect x={0.5} y={0.5} width={475} height={344} rx={7.5} fill="white" stroke={COLOR.gray.g100} />
          {/* <Path
            d="M56.716 62.284H63.184V64.9H64.168V61.492H56.716V62.284ZM57.844 55.348H63.148V57.436H57.844V55.348ZM61 59.584V58.216H64.12V54.568H56.884V58.216H60.016V59.584H55.624V60.376H65.368V59.584H61Z"
            fill="black"
          />
          <Path
            d="M72.7981 59.38C71.2381 58.828 70.1821 57.472 70.1821 56.092V55.66H72.5461V54.856H66.7981V55.66H69.1981V56.092C69.1981 57.616 68.0821 59.056 66.4861 59.62L67.0021 60.4C68.2621 59.932 69.2461 58.948 69.7021 57.712C70.1821 58.828 71.1061 59.716 72.3061 60.148L72.7981 59.38ZM68.1181 61.948H74.3221V64.9H75.3181V61.156H68.1181V61.948ZM74.3221 54.124V56.932H72.2221V57.736H74.3221V60.568H75.3181V54.124H74.3221Z"
            fill="black"
          />
          <Path
            d="M76.7601 66.148H77.5641L81.1521 54.472H80.3601L76.7601 66.148Z"
            fill="black"
          />
          <Path
            d="M82.8183 62.284H89.2863V64.9H90.2703V61.492H82.8183V62.284ZM83.9463 55.348H89.2503V57.436H83.9463V55.348ZM87.1023 59.584V58.216H90.2223V54.568H82.9863V58.216H86.1183V59.584H81.7263V60.376H91.4703V59.584H87.1023Z"
            fill="black"
          />
          <Path
            d="M96.2364 60.22H98.5764V62.776H96.2364V60.22ZM96.0684 55.912H98.7444V59.428H96.0684V55.912ZM99.5604 62.776V60.22H101.384V59.428H99.7284V55.912H101.396V55.12H93.4164V55.912H95.0844V59.428H93.4404V60.22H95.2524V62.776H92.5404V63.58H102.308V62.776H99.5604Z"
            fill="black"
          /> */}
          <Rect x={118} y={46} width={341} height={30} rx={4} fill={COLOR.gray.g050} />
          <Path d="M39 1H5V268H39V1Z" fill="white" />
          <Path fillRule="evenodd" clipRule="evenodd" d="M38 344V1H39V344H38Z" fill={COLOR.gray.g200} />
          <Path
            d="M14 23.6719H26V25H14V23.6719ZM14 15.6719H26V17H14V15.6719ZM18.6719 18.3281H26V19.6719H18.6719V18.3281ZM18.6719 21H26V22.3281H18.6719V21ZM17.3281 22.3281V18.3281L14 20.3281L17.3281 22.3281Z"
            fill={COLOR.gray.g300}
          />
          <Rect x={7} y={72} width={26} height={26} rx={8} fill={COLOR.main.blue} />
          <Path
            d="M20 44.6719C20.3646 44.6719 20.6771 44.8021 20.9375 45.0625C21.1979 45.3229 21.3281 45.6354 21.3281 46C21.3281 46.3646 21.1979 46.6771 20.9375 46.9375C20.6771 47.1979 20.3646 47.3281 20 47.3281C19.6354 47.3281 19.3229 47.1979 19.0625 46.9375C18.8021 46.6771 18.6719 46.3646 18.6719 46C18.6719 45.6354 18.8021 45.3229 19.0625 45.0625C19.3229 44.8021 19.6354 44.6719 20 44.6719ZM20 43.3281C19.2604 43.3281 18.6302 43.5885 18.1094 44.1094C17.5885 44.6302 17.3281 45.2604 17.3281 46C17.3281 46.7396 17.5885 47.3698 18.1094 47.8906C18.6302 48.4115 19.2604 48.6719 20 48.6719C20.7396 48.6719 21.3698 48.4115 21.8906 47.8906C22.4115 47.3698 22.6719 46.7396 22.6719 46C22.6719 45.2604 22.4115 44.6302 21.8906 44.1094C21.3698 43.5885 20.7396 43.3281 20 43.3281ZM25.3281 54.6719H24C24 54.0781 23.901 53.5365 23.7031 53.0469C23.5052 52.5573 23.2292 52.1354 22.875 51.7812C22.5312 51.4271 22.1146 51.1562 21.625 50.9688C21.1354 50.7708 20.5938 50.6719 20 50.6719C19.4062 50.6719 18.8646 50.7708 18.375 50.9688C17.8854 51.1562 17.4635 51.4271 17.1094 51.7812C16.7656 52.1354 16.4948 52.5573 16.2969 53.0469C16.099 53.5365 16 54.0781 16 54.6719H14.6719C14.6719 53.8906 14.8021 53.1771 15.0625 52.5312C15.3333 51.875 15.7031 51.3125 16.1719 50.8438C16.651 50.3646 17.2135 49.9948 17.8594 49.7344C18.5156 49.4635 19.2292 49.3281 20 49.3281C20.7708 49.3281 21.4792 49.4635 22.125 49.7344C22.7812 49.9948 23.3438 50.3646 23.8125 50.8438C24.2917 51.3125 24.6615 51.875 24.9219 52.5312C25.1927 53.1771 25.3281 53.8906 25.3281 54.6719Z"
            fill="#333333"
          />
          <Path
            d="M24 117V116.328H22.6719V117H17.3281V116.328H16V117H14V128.328H26V117H24ZM24.6719 127H15.3281V120.328H24.6719V127ZM19.3281 125.672L22.6719 122.328L21.7188 121.391L19.3281 123.781L18.2812 122.719L17.3281 123.672L18.3906 124.719L19.3281 125.672Z"
            fill="#333333"
          />
          <Path
            d="M18.6719 90H22V80.6719H18.6719V90ZM14.6719 90H18V80.6719H14.6719V90ZM22.6719 80.6719V90H26V80.6719H22.6719Z"
            fill="white"
          />
          <Rect x={8.5} y={147.5} width={23} height={23} rx={7.5} fill="white" stroke="#999999" strokeDasharray="2 2" />
          <Path
            d="M24.5 158.504H20.5039V154.496H19.4961V158.504H15.5V159.5H19.4961V163.496H20.5039V159.5H24.5V158.504Z"
            fill="#666666"
          />
          <Path
            d="M55 90C55 87.7909 56.7909 86 59 86H179C181.209 86 183 87.7909 183 90V328H55V90Z"
            fill={COLOR.gray.g050}
          />
          {item1?.title ? <></> : <Rect x={77} y={97} width={80} height={9} rx={2} fill={COLOR.gray.g200} />}
          <Path
            d="M193 90C193 87.7909 194.791 86 197 86H317C319.209 86 321 87.7909 321 90V328H193V90Z"
            fill={COLOR.gray.g050}
          />
          <Path
            d="M331 90C331 87.7909 332.791 86 335 86H455C457.209 86 459 87.7909 459 90V328H331V90Z"
            fill={COLOR.gray.g050}
          />
          <Path
            d="M55 90C55 87.7909 56.7909 86 59 86H179C181.209 86 183 87.7909 183 90V90H55V90Z"
            fill={step !== 1 && item1?.labelColor ? getRealColor(item1.labelColor) : COLOR.gray.g200}
          />
          <Path
            d="M193 90C193 87.7909 194.791 86 197 86H317C319.209 86 321 87.7909 321 90V90H193V90Z"
            fill={step !== 1 && item2?.labelColor ? getRealColor(item2.labelColor) : COLOR.gray.g200}
          />
          <Path
            d="M331 90C331 87.7909 332.791 86 335 86H455C457.209 86 459 87.7909 459 90V90H331V90Z"
            fill={step !== 1 && item3?.labelColor ? getRealColor(item3.labelColor) : COLOR.gray.g200}
          />
          <Rect
            x={59}
            y={94}
            width={15}
            height={15}
            rx={4}
            fill={step !== 1 && item1?.labelColor ? getRealColor(item1.labelColor) : COLOR.gray.g200}
          />
          <Rect
            x={196}
            y={94}
            width={15}
            height={15}
            rx={4}
            fill={step !== 1 && item2?.labelColor ? getRealColor(item2.labelColor) : COLOR.gray.g200}
          />
          <Rect
            x={335}
            y={94}
            width={15}
            height={15}
            rx={4}
            fill={step !== 1 && item3?.labelColor ? getRealColor(item3.labelColor) : COLOR.gray.g200}
          />
          <Rect x={59} y={115} width={120} height={49} rx={4} fill={COLOR.gray.g100} />
          <Rect x={196} y={115} width={120} height={49} rx={4} fill={COLOR.gray.g100} />
          <Rect x={335} y={115} width={120} height={49} rx={4} fill={COLOR.gray.g100} />
          <Rect x={59} y={168} width={120} height={49} rx={4} fill={COLOR.gray.g100} />
          <Rect x={196} y={168} width={120} height={49} rx={4} fill={COLOR.gray.g100} />
          <Rect x={196} y={221} width={120} height={49} rx={4} fill={COLOR.gray.g100} />
          <Rect x={335} y={168} width={120} height={49} rx={4} fill={COLOR.gray.g100} />
          {item2?.title ? <></> : <Rect x={214} y={97} width={80} height={9} rx={2} fill={COLOR.gray.g200} />}
          {item3?.title ? <></> : <Rect x={353} y={97} width={80} height={9} rx={2} fill={COLOR.gray.g200} />}
        </Svg>
      </View>
    </View>
  )
}

export default WorkmodelPreviewHierarchy

const styles = StyleSheet.create({
  preview: {
    position: 'absolute',
    alignSelf: 'flex-start',
    borderRadius: 8,
    ...getBoxShadow(94, 88, 116, 0.2, 17, 3, 13, 0),
  },
  previewMobile: {
    width: 300,
    ...getBoxShadow(94, 88, 116, 0.1, 3, 3, 4, 0),
  },
  titleArea: {
    position: 'absolute',
    top: 16,
    left: 55,
    zIndex: 10,
  },
  titleAreaMobile: {
    top: 10,
    left: 44,
  },
  title: {
    width: 400,
    ...FONT.txtSmBold,
  },
  goalTitleArea: {
    position: 'absolute',
    top: 50,
    left: 55,
    width: 50,
    zIndex: 10,
  },
  goalTitleAreaMobile: {
    top: 30,
    left: 44,
    width: 40,
  },
  goalArea: {
    position: 'absolute',
    top: 52,
    left: 128,
    width: 310,
    zIndex: 10,
  },
  goalAreaMobile: {
    top: 30,
    left: 90,
  },
  goal: {
    ...FONT.txtXs,
  },
  board: {
    position: 'absolute',
    top: 93,
    zIndex: 10,
    width: 100,
    ...Platform.select({
      web: { marginTop: 1 },
    }),
  },
  boardText: {
    ...FONT.txt2Xs,
  },
  item1: {
    left: 77,
  },
  item2: {
    left: 214,
  },
  item3: {
    left: 353,
  },
})

export const commonStyles = StyleSheet.create({
  infoBox: {
    position: 'absolute',
    marginBottom: 0,
    alignSelf: 'flex-start',
    backgroundColor: COLOR.main.darkNavy,
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderRadius: 4,
    zIndex: 30,
    ...getBoxShadow(0, 0, 0, 0.25, 8, 0, 6, 0),
  },
  infoText: {
    ...FONT.txtXs,
    color: COLOR.mono.white,
  },
  infoTextLabel: {
    backgroundColor: COLOR.mono.white,
    borderRadius: 4,
    borderLeftWidth: 4,
    paddingLeft: 2,
    paddingRight: 4,
    paddingBottom: 1,
    marginRight: 4,
  },
  infoLabelText: {
    ...FONT.txt2XsMedium,
    color: COLOR.gray.g900,
  },
})
