import type { IconName } from '@rui/foundations'
import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text, ViewStyle } from 'react-native'

interface Props {
  iconName: IconName
  text: string
  onPress: () => void
  style?: ViewStyle
}
const OrderDurationControlButton = ({ iconName, text, onPress, style }: Props) => {
  const [isPress, setIsPress] = React.useState(false)
  return (
    <Pressable
      style={[styles.buttonControl, styles.flexBox, style, isPress && styles.buttonControlPress]}
      onPress={() => onPress()}
      onPressIn={() => setIsPress(true)}
      onPressOut={() => setIsPress(false)}
    >
      <XEIcon name={iconName} size={16} />
      <Text>{text}</Text>
    </Pressable>
  )
}

export default OrderDurationControlButton

const styles = StyleSheet.create({
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonControl: {
    flex: 1,
    alignItems: 'center',
    height: 36,
    backgroundColor: COLOR.mono.white,
    borderWidth: 1,
    borderColor: COLOR.gray.g300,
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  buttonControlPress: {
    borderColor: COLOR.gray.g500,
    backgroundColor: COLOR.gray.g050,
  },
})
