import * as React from 'react'

import { TableorderTableInfoView } from './view'

interface Props {
  readonly: boolean
  shopId: string
  shopLayoutId: string
  location: { x: number; y: number }
}
export const TableorderTableInfoModal: React.FC<Props> = ({ readonly, shopId, shopLayoutId, location }) => {
  return <TableorderTableInfoView readonly={readonly} shopId={shopId} shopLayoutId={shopLayoutId} location={location} />
}
