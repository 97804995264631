import { COLOR, FONT, useMobileView } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, StyleSheet, TextInput, View } from 'react-native'
import { SectionLabel } from '../CreateWorkModel/sectionLabel'
import { UpdateWorkModelTableorderProps } from './types'

const EditWorkModelTableorder = ({ data, shopData, onChangeTitle, onChangeValue }: UpdateWorkModelTableorderProps) => {
  const { t } = useTranslation()
  const { title } = data
  const mobileView = useMobileView(500)

  React.useEffect(() => {
    console.log('EditWorkModelTableorder')
  }, [])

  return (
    <View style={!!mobileView && styles.mobileContainer}>
      <ScrollView scrollEnabled={true} style={[styles.workInfoSection, !mobileView && { minHeight: 300 }]}>
        <View>
          <View>
            <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodal.top.name')}</Text>
            <TextInput
              value={title}
              placeholder={t('workmodal.basic.inputplaceholder')}
              maxLength={50}
              onChangeText={onChangeTitle}
              style={styles.inputStyle}
            />
            <Text
              style={{
                ...FONT.txt2Xs,
                color: COLOR.gray.g500,
                textAlign: 'right',
              }}
            >
              ({title.length}/50)
            </Text>
          </View>
          <View style={[styles.labelSection, mobileView ? { paddingBottom: 50 } : { paddingBottom: 32 }]}>
            <SectionLabel text={'상세정보'} />
            <View>
              <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodal.top.representativename')}</Text>
              <TextInput
                value={shopData.representativeName}
                onChangeText={(text) => onChangeValue('representativeName', text)}
                style={[styles.inputStyle]}
                placeholder={t('workmodal.top.representativenameplaceholder')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
              />
            </View>

            <View>
              <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodal.top.representativebirth')}</Text>
              <TextInput
                value={shopData.representativeBirthDate}
                onChangeText={(text) => onChangeValue('representativeBirthDate', text)}
                style={[styles.inputStyle]}
                placeholder={t('workmodal.top.representativebirthplaceholder')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
              />
            </View>
            <View>
              <Text style={[styles.labelName, { marginBottom: 2 }]}>
                {t('workmodal.top.companyregistrationnumber')}
              </Text>
              <TextInput
                value={shopData.businessRegistrationNumber}
                onChangeText={(text) => onChangeValue('businessRegistrationNumber', text)}
                style={[styles.inputStyle]}
                placeholder={t('workmodal.top.companyregistrationnumberplaceholder')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
              />
            </View>
            <View>
              <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodal.top.shopcontact')}</Text>
              <TextInput
                value={shopData.representativeContactNumber}
                onChangeText={(text) => onChangeValue('representativeContactNumber', text)}
                style={[styles.inputStyle]}
                placeholder={t('workmodal.top.shopcontactplaceholder')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
              />
            </View>
            <View>
              <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodal.top.contactperson')}</Text>
              <TextInput
                value={shopData.responsibilityContactNumber}
                onChangeText={(text) => onChangeValue('responsibilityContactNumber', text)}
                style={[styles.inputStyle]}
                placeholder={t('workmodal.top.contactpersonplaceholder')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
              />
            </View>
          </View>
          <View style={[styles.labelSection, mobileView ? { paddingBottom: 50 } : { paddingBottom: 32 }]}>
            <SectionLabel text={'은행 정보'} />
            <View>
              <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodal.top.bank')}</Text>
              <TextInput
                value={shopData.bankCode}
                onChangeText={(text) => onChangeValue('bankCode', text)}
                style={[styles.inputStyle]}
                placeholder={t('workmodal.top.bankchoose')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
              />
            </View>
            <View>
              <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodal.top.accountnumber')}</Text>
              <TextInput
                value={shopData.bankAccountNumber}
                onChangeText={(text) => onChangeValue('bankAccountNumber', text)}
                style={[styles.inputStyle]}
                placeholder={t('workmodal.top.accountnumbeplaceholder')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
              />
            </View>
            <View>
              <Text style={[styles.labelName, { marginBottom: 2 }]}>{t('workmodal.top.accountholdername')}</Text>
              <TextInput
                value={shopData.bankAccountName}
                onChangeText={(text) => onChangeValue('bankAccountName', text)}
                style={[styles.inputStyle]}
                placeholder={t('workmodal.top.accountholdernameplaceholder')}
                placeholderTextColor={COLOR.gray.g400}
                textAlignVertical="center"
                underlineColorAndroid="transparent"
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default EditWorkModelTableorder

export const styles = StyleSheet.create({
  mobileContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  workInfoSection: {
    flex: 1,
  },
  flexBox: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  labelSection: {
    marginTop: 12,
  },
  labelName: {
    ...FONT.txtSmMedium,
    marginBottom: 8,
  },
  inputStyle: {
    ...FONT.txtSm,
    width: '100%',
    height: 36,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderColor: COLOR.gray.g200,
    borderRadius: 4,
  },
  dateSection: {
    borderTopWidth: 1,
    borderColor: COLOR.gray.g200,
    paddingTop: 12,
  },
})
