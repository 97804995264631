import { useModalDialog } from '@rocket-mono/providers'
import { useToast } from '@rui/atoms'
import { removeItemAtIndex, useMobileView } from '@rui/foundations'
import { isEqual } from 'lodash'
import * as React from 'react'
import { ScrollView, View, ViewStyle } from 'react-native'
import { FooterPrice } from '../../FooterPrice'
import Header from '../../Header'
import MenuDetailPhoto, { MenuDetailPhotoItemType } from '../../MenuDetail/MenuDetailPhoto'
import { useTableorderCatalog, useTableorderFile, useTableorderOrder } from '../../providers'
import { getNumberWithComma } from '../../utils'
import MenuDetailInfo from './MenuDetail/info'
import MenuDetailOptionList from './MenuDetail/optionList'
import OrderButtonBar from './MenuDetail/orderButton'

interface Props {
  menuId: string
  catalogCategoryId: string
  onBack: (page?: string) => void
  onPhotoPress: (item: MenuDetailPhotoItemType) => void
  isFooterPrice?: boolean
  cartCount?: number
  orderSheet?: boolean
  onOrderSheetPress?: () => void
  containerStyle?: ViewStyle
}

const TableorderOrderMenuDetailView = ({
  menuId,
  catalogCategoryId,
  containerStyle,
  onBack,
  onPhotoPress,
  isFooterPrice = true,
  cartCount = 0,
  orderSheet = false,
  onOrderSheetPress,
}: Props) => {
  const mobileView = useMobileView(800)

  const { show: showToastMessage } = useToast()
  const { showDialogMessage, hideDialogMessage } = useModalDialog()

  const { currentCatalog, catalogMenuList, catalogOptionList } = useTableorderCatalog()
  const { createBasketItem, deleteBasketItem, basketItemList } = useTableorderOrder()

  const [quantity, setQuantity] = React.useState(1)
  const [optionIds, setOptionIds] = React.useState<string[]>([])

  const menu = React.useMemo(() => catalogMenuList.find(({ id }) => id === menuId), [menuId, catalogMenuList])

  const totalPrice = React.useMemo(
    () =>
      ((menu?.price || 0) +
        catalogOptionList.filter(({ id }) => optionIds.includes(id)).reduce((value, cur) => value + cur.price, 0)) *
      quantity,
    [menu, optionIds, quantity],
  )

  const handlePressOption = React.useCallback((id: string) => {
    setOptionIds((prev) => {
      const idx = prev.findIndex((o) => o === id)
      return idx < 0 ? [...prev, id] : removeItemAtIndex(prev, idx)
    })
  }, [])

  const handleAddBasket = React.useCallback(() => {
    if (menu) {
      const items = basketItemList.filter(
        (o) => o.catalogMenuId === menu.id && isEqual(o.selectedCatalogOptionIds.sort(), optionIds.sort()),
      )

      new Promise((resolve) => {
        if (items && items.length > 0) {
          const item = items[0]
          deleteBasketItem(item.id)
          createBasketItem({
            catalogMenuId: menu.id,
            catalogCategoryId: catalogCategoryId,
            catalogMenuOriginId: menu.originId,
            quantity: quantity + item.quantity,
            selectedCatalogOptionIds:
              currentCatalog?.optionGroups
                .flatMap(({ options }) => options)
                .filter(({ id }) => optionIds.includes(id))
                .map(({ id }) => id) || [],
            selectedCatalogOptionOriginIds:
              currentCatalog?.optionGroups
                .flatMap(({ options }) => options)
                .filter(({ id }) => optionIds.includes(id))
                .map(({ originId: id }) => id) || [],
          }).then(resolve)
        } else {
          createBasketItem({
            catalogMenuId: menu.id,
            catalogCategoryId: catalogCategoryId,
            catalogMenuOriginId: menu.originId,
            quantity,
            selectedCatalogOptionIds:
              currentCatalog?.optionGroups
                .flatMap(({ options }) => options)
                .filter(({ id }) => optionIds.includes(id))
                .map(({ id }) => id) || [],
            selectedCatalogOptionOriginIds:
              currentCatalog?.optionGroups
                .flatMap(({ options }) => options)
                .filter(({ id }) => optionIds.includes(id))
                .map(({ originId: id }) => id) || [],
          }).then(resolve)
        }
      }).then(() => {
        if (mobileView) {
          const list = [
            {
              name: '장바구니 확인',
              action: () => {
                onBack('basket')
              },
            },
          ]
          showDialogMessage({
            title: '장바구니에 담겼습니다.',
            list,
            cancelText: '계속주문',
            onCancel: hideDialogMessage,
          })
        } else {
          onBack()
          showToastMessage(
            {
              type: 'Success',
              title: '메뉴를 장바구니에 담았습니다.',
              position: mobileView ? 'TOP_CENTER' : 'TOP_RIGHT',
            },
            3000,
          )
        }
      })
    }
  }, [basketItemList, quantity, mobileView, currentCatalog, menu, optionIds])

  const { readFileUrl } = useTableorderFile()
  const [previewImageUrlList, setPreviewImageUrlList] = React.useState<string[]>()

  React.useEffect(() => {
    if (menu) {
      Promise.all(menu.images.map(({ path }) => readFileUrl(path))).then(setPreviewImageUrlList)
    }
  }, [menu])

  const requireGroupList = React.useMemo(
    () =>
      menu?.optionGroups
        .filter(
          ({ originId, type, options }) =>
            options.length > 0 && menu?.originOptionGroupIds.includes(originId) && type === 'REQUIRED',
        )
        .sort((a, b) => {
          const aSeq = menu.originOptionGroupIds.findIndex((o) => o === a.originId)
          const bSeq = menu.originOptionGroupIds.findIndex((o) => o === b.originId)
          return aSeq - bSeq
        }) || [],
    [menu],
  )

  const optionGroupList = React.useMemo(
    () =>
      menu?.optionGroups
        .filter(
          ({ originId, type, options }) =>
            options.length > 0 && menu?.originOptionGroupIds.includes(originId) && type === 'OPTIONAL',
        )
        .sort((a, b) => {
          const aSeq = menu.originOptionGroupIds.findIndex((o) => o === a.originId)
          const bSeq = menu.originOptionGroupIds.findIndex((o) => o === b.originId)
          return aSeq - bSeq
        }) || [],
    [menu],
  )

  const error = React.useMemo(() => {
    const error =
      requireGroupList
        .map((o) => {
          if (o.options.filter(({ id }) => optionIds.includes(id)).length > 0) return true
          return false
        })
        .filter((is) => !is).length > 0

    return error
  }, [requireGroupList, optionIds])

  if (!menu) return <></>
  return (
    <View style={[{ flex: 1 }, containerStyle]}>
      {mobileView ? (
        <Header
          title=""
          textAlignCenter
          buttonBack
          onBack={onBack}
          containerStyle={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: 1,
            backgroundColor: 'transparent',
          }}
          isBorder={false}
        />
      ) : (
        <></>
      )}
      <ScrollView style={{ flex: 1 }}>
        {mobileView && previewImageUrlList !== undefined ? (
          <MenuDetailPhoto
            items={previewImageUrlList?.map((uri, id) => ({
              id,
              uri,
              type: 'IMAGE',
            }))}
            onPress={onPhotoPress}
            height={250}
          />
        ) : (
          <></>
        )}
        <View style={{ padding: 20 }}>
          <MenuDetailInfo menu={menu} mobileView={mobileView} onBack={onBack} />
          <MenuDetailOptionList
            list={[...requireGroupList, ...optionGroupList]}
            optionIds={optionIds}
            onPress={handlePressOption}
          />
        </View>
      </ScrollView>
      <View>
        {isFooterPrice ? (
          <FooterPrice
            totalPrice={`${getNumberWithComma(totalPrice)}`}
            initQuantity={quantity}
            onQuantity={setQuantity}
          />
        ) : (
          <></>
        )}
        <OrderButtonBar
          disabled={error}
          cartCount={cartCount}
          orderSheet={orderSheet}
          soldout={menu.isSoldout}
          onOrderSheet={onOrderSheetPress}
          onOrder={handleAddBasket}
        />
      </View>
    </View>
  )
}

export default TableorderOrderMenuDetailView
