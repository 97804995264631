import { Icon } from '@rocket-atoms/icon'
import type { FontName } from '@rocket-mono/libs'
import { COLOR } from '@rocket-mono/libs'
import { Text } from '@rocket/atoms'
import * as React from 'react'
import { Pressable, StyleSheet } from 'react-native'

const AddButton = ({ text, fontName, onPress }: { text: string; fontName: FontName; onPress?: () => void }) => {
  const [isHover, setIsHover] = React.useState(false)
  return (
    <Pressable
      style={[
        styles.container,
        isHover && {
          backgroundColor: COLOR.gray.g050,
          borderColor: COLOR.gray.g300,
        },
      ]}
      onPress={onPress}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPressIn={() => setIsHover(true)}
      onPressOut={() => setIsHover(false)}
    >
      <Icon name={'plus'} size={14} color={'gray.g600'} style={{ marginRight: 4 }} />
      <Text fontName={fontName} style={{ color: COLOR.gray.g600 }}>
        {text}
      </Text>
    </Pressable>
  )
}

export { AddButton }

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: COLOR.gray.g400,
    borderRadius: 5,
    padding: 8,
  },
})
