import { View } from '@rocket-mono/foundations'
import { Tooltip } from '@rui/atoms'
import { COLOR } from '@rui/foundations'
import * as React from 'react'
import { Platform, Pressable, StyleSheet, ViewStyle } from 'react-native'
import { SwitchIconArchive, SwitchIconBoard } from '../icons/switchIcon'

export interface SelectSwitchTextProps {
  containerStyle?: ViewStyle
  activated: boolean
  scale?: number
  leftMode: string
  rightMode: string
  leftTooltip: string
  rightTooltip: string
  onChange: () => void
}

const Handler = ({
  onSwitch,
  activated,
  mode,
  tooltip,
}: {
  onSwitch: () => void
  activated: boolean
  mode: string
  tooltip: string
}) => {
  return (
    <View>
      <Tooltip
        // gab={5}
        // darkType={true}
        direction={'bottom'}
        align="center"
        text={tooltip}
        // textStyle={{ ...FONT.txtXs }}
        // containerStyle={styles.tooltip}
        onPress={onSwitch}
      >
        <View style={[styles.switchHandler, !activated && styles.switchHandlerActived]}>
          <View>
            {mode === 'board' && <SwitchIconBoard color={activated ? '#D3D7DC' : '#ffffff'} />}
            {mode === 'archive' && <SwitchIconArchive color={activated ? '#D3D7DC' : '#ffffff'} />}
          </View>
        </View>
      </Tooltip>
    </View>
  )
}

const WorkBoardSwitch: React.FC<SelectSwitchTextProps> = ({
  containerStyle,
  activated,
  scale = 1,
  leftMode,
  rightMode,
  leftTooltip,
  rightTooltip,
  onChange,
}) => {
  return (
    <View style={containerStyle}>
      <Pressable onPress={onChange} hitSlop={hitSlop}>
        <View
          style={[
            styles.container,
            {
              transform: [{ scale }],
            },
          ]}
        >
          <Handler onSwitch={onChange} activated={activated} mode={leftMode} tooltip={leftTooltip} />
          <Handler onSwitch={onChange} activated={!activated} mode={rightMode} tooltip={rightTooltip} />
        </View>
      </Pressable>
    </View>
  )
}

export default WorkBoardSwitch

const hitSlop = {
  top: 22,
  left: 22,
  right: 22,
  bottom: 22,
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 3,
    paddingVertical: 3,
    borderRadius: 4,
    backgroundColor: COLOR.gray.g100,
  },
  // @ts-ignore
  tooltip: {
    ...Platform.select({
      web: { maxWidth: 'max-content' },
    }),
  },
  switchHandler: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 5,
    backgroundColor: 'transparent',
  },
  switchHandlerActived: {
    backgroundColor: COLOR.main.blue,
  },
})
