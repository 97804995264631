import * as React from 'react'

import {
  TableorderCatalogAstroType,
  TableorderCatalogProvider,
  TableorderMenuAstroType,
  TableorderMenuProvider,
  TableorderOrderAstroType,
  TableorderOrderProvider,
  TableorderShopAstroType,
  TableorderShopProvider,
  TableorderTableAstroType,
  TableorderTableProvider,
} from '../providers'
import type { AstroType } from './types'
import View from './view'

import { SubscribeType } from '@rocket-mono/libs'

interface Props {
  shopId: string
  tableId: string
  astro: AstroType &
    TableorderShopAstroType &
    TableorderCatalogAstroType &
    TableorderMenuAstroType &
    TableorderTableAstroType &
    TableorderOrderAstroType
  subscribe: SubscribeType
  onPressClose: () => void
}
export const TableorderOrderAdditionalScreen: React.FC<Props> = ({
  shopId,
  tableId,
  astro,
  subscribe,
  onPressClose,
}) => {
  return (
    <TableorderShopProvider shopId={shopId} astro={astro}>
      <TableorderCatalogProvider astro={astro}>
        <TableorderMenuProvider astro={astro}>
          <TableorderTableProvider astro={astro} subscribe={subscribe}>
            <TableorderOrderProvider astro={astro} subscribe={subscribe}>
              <View tableId={tableId} onPressClose={onPressClose} />
            </TableorderOrderProvider>
          </TableorderTableProvider>
        </TableorderMenuProvider>
      </TableorderCatalogProvider>
    </TableorderShopProvider>
  )
}
