import * as React from 'react'
import { Animated, Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { View } from '@rocket-mono/foundations'
import { COLOR, IColors } from '@rocket-mono/libs'
import { useToast } from '@rui/atoms'

import { Text, WorkIcon } from '@rocket/atoms'
import { Bookmark } from '@rocket/types'
import { FAFreeSolidSvgIcons, FAIcon } from '@rui/icons'
import { useTranslation } from 'react-i18next'
import { LayoutSideNavWorkItemType } from '../types'

const { faThumbTack } = FAFreeSolidSvgIcons

const ITEM_HEIGHT = 36

interface Props {
  workData: LayoutSideNavWorkItemType
  sectionKey: string
  isShow: boolean
  onPress?: (work: LayoutSideNavWorkItemType) => void
  addFavorite: (projectId: string) => Promise<Bookmark>
  deleteFavorite: (projectId: string) => void
}

const WorkGroup = ({ workData, sectionKey, isShow, onPress, addFavorite, deleteFavorite }: Props) => {
  const { t } = useTranslation()
  const { show: showToastMessage } = useToast()

  const [isHover, setIsHover] = React.useState(false)
  const [isFavoriteHover, setIsFavoriteHover] = React.useState(false)
  const [isDisplay, setIsDisplay] = React.useState(true)

  const animatedHeight = React.useRef(new Animated.Value(isShow ? ITEM_HEIGHT : 0)).current

  const isType = React.useMemo(() => {
    return workData.type === 'DSP' || workData.type === 'WFP' || workData.type === 'CSP' || workData.type === 'TOP'
  }, [workData])

  const isFavoriteShow = React.useMemo<boolean>(() => {
    if (sectionKey === 'archive') {
      return workData.isFavorite
    }
    return workData.isFavorite || isHover
  }, [workData, isHover, sectionKey])

  const favoriteButtonStyle = React.useMemo<StyleProp<ViewStyle>>(() => {
    const style: StyleProp<ViewStyle> = []
    if (isFavoriteHover) {
      style.push({ backgroundColor: COLOR.opacity.w10 })
    }
    if (workData.isFavorite && isFavoriteHover) {
      style.push({ borderWidth: 1, borderColor: COLOR.primary.blue700 })
    }
    return style
  }, [workData, isFavoriteHover])

  const favoriteIconColor = React.useMemo<IColors>(() => {
    return workData.isFavorite ? 'primary.blue400' : 'opacity.w50'
  }, [workData, isFavoriteHover])

  React.useEffect(() => {
    const duration = 200
    if (typeof isShow === 'boolean') {
      if (isShow) {
        setIsDisplay(true)
        Animated.timing(animatedHeight, {
          toValue: ITEM_HEIGHT,
          duration,
          useNativeDriver: false,
        }).start()
      } else {
        Animated.timing(animatedHeight, {
          toValue: 0,
          duration,
          useNativeDriver: false,
        }).start(() => {
          setIsDisplay(false)
        })
      }
    }
  }, [isShow])

  return (
    <Animated.View style={{ height: animatedHeight, display: isDisplay ? 'flex' : 'none' }}>
      <Pressable
        onHoverIn={() => setIsHover(true)}
        onHoverOut={() => setIsHover(false)}
        style={[styles.workItemContainer, isHover && { backgroundColor: COLOR.opacity.w10 }]}
        onPress={() => onPress && onPress(workData)}
      >
        {isType ? (
          <View style={styles.icon}>
            <WorkIcon type={workData.type} size={16} />
          </View>
        ) : (
          <></>
        )}

        <View style={{ flex: 1 }}>
          <Text fontName="txtSm" fontColor="mono.white" numberOfLines={1} ellipsizeMode="tail" style={styles.itemText}>
            {workData.name}
          </Text>
        </View>

        {isFavoriteShow ? (
          <Pressable
            onHoverIn={() => {
              setIsHover(true)
              setIsFavoriteHover(true)
            }}
            onHoverOut={() => setIsFavoriteHover(false)}
            onPress={() => {
              if (workData.isFavorite) {
                deleteFavorite(String(workData.id))
              } else {
                addFavorite(String(workData.id)).catch((e) => {
                  const message =
                    e.status === 400
                      ? t('workspace.favoriteovertoast')
                      : e.status === 409
                      ? t('workspace.favoritealreadytoast')
                      : t('workspace.favoritealerrortoast')
                  showToastMessage({
                    title: message,
                    type: 'Danger',
                    position: 'BOTTOM_CENTER',
                  })
                })
              }
            }}
            style={[styles.btnFavorite, favoriteButtonStyle]}
          >
            <FAIcon iconName={faThumbTack} size="sm" color={favoriteIconColor} />
          </Pressable>
        ) : (
          <View style={styles.btnEmptyFavorite} />
        )}
      </Pressable>
    </Animated.View>
  )
}

export default WorkGroup

const styles = StyleSheet.create({
  workItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 28,
    paddingVertical: 8,
    paddingRight: 12,
    zIndex: 0,
  },
  icon: {
    width: 16,
    height: 16,
  },
  itemText: {
    marginLeft: 4,
  },
  btnFavorite: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  btnEmptyFavorite: {
    width: 20,
    height: 20,
  },
})
