import { View } from '@rocket-mono/foundations'
import type { TableorderCatalogOption, TableorderCatalogOptionGroup } from '@rocket/types'
import { Text } from '@rui/atoms'
import { COLOR, removeItemAtIndex } from '@rui/foundations'
import * as React from 'react'
import { FlatList, ListRenderItemInfo, StyleSheet } from 'react-native'
import OrderOptionButton from '../../OrderOptionButton'
import { getNumberWithComma } from '../../utils'

interface Props {
  error?: boolean
  group: TableorderCatalogOptionGroup
  updateOptionIds: (ids: string[], originIds: string[]) => void
}

const MenuOptionGroupItem: React.FC<Props> = ({ error = false, group, updateOptionIds }) => {
  const [selected, setSelected] = React.useState<{ id: string; originId: string }[]>([])

  const handlePressItem = React.useCallback(
    (id: string, originId: string) => {
      if (group.isMultipleChoicesAvailable) {
        setSelected((prev) => {
          const idx = prev.findIndex((val) => val.id === id)
          return idx < 0 ? [...prev, { id, originId }] : removeItemAtIndex(prev, idx)
        })
      } else {
        setSelected([{ id, originId }])
      }
    },
    [group.isMultipleChoicesAvailable],
  )

  React.useEffect(() => {
    updateOptionIds(
      selected.map(({ id }) => id),
      selected.map(({ originId: id }) => id),
    )
  }, [selected])

  const renderItem = React.useCallback(
    ({ item }: ListRenderItemInfo<TableorderCatalogOption>) => {
      return (
        <View style={{ marginRight: 8 }}>
          <OrderOptionButton
            name={item.name}
            price={getNumberWithComma(item.price)}
            selected={selected.map(({ id }) => id).includes(item.id)}
            onPress={() => handlePressItem(item.id, item.originId)}
          />
        </View>
      )
    },
    [selected],
  )

  const keyExtractor = React.useCallback((item: TableorderCatalogOption) => `${item.id}`, [])

  return (
    <View style={[styles.container, error && styles.errorStyle]}>
      <Text fontName="textRegular" fontColor="mono.black">
        ({group.type === 'REQUIRED' ? '필수' : '옵션'}) {group.name}
      </Text>
      <FlatList horizontal data={group.options} renderItem={renderItem} keyExtractor={keyExtractor} />
    </View>
  )
}

export default MenuOptionGroupItem

const styles = StyleSheet.create({
  container: { flex: 1, marginTop: 8, marginBottom: 12 },
  errorStyle: { borderWidth: 1, borderRadius: 8, borderColor: COLOR.main.red },
})
