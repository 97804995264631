import { useCallback, useContext } from 'react'
import type { ModalContext } from './context'
import { Context } from './context'
import { ModalBase } from './Modal'

function useModal(id: string) {
  const context = useContext<ModalContext | undefined>(Context)

  if (context === undefined) throw new Error('There must be a ModalProvider as Ancestor of all Modal Hooks and HOCs')

  const { isOpen, setIsOpen } = context

  const open = useCallback(() => {
    setIsOpen(id, true)
  }, [])

  const close = useCallback(() => {
    setIsOpen(id, false)
  }, [])

  return {
    Modal: ModalBase,
    open,
    close,
    isOpen: isOpen(id),
  }
}

export default useModal
