import { COLOR } from '@rui/foundations'
import { XEIcon } from '@rui/icons'
import * as React from 'react'
import { Pressable, StyleSheet, Text, View } from 'react-native'
import { styleTableCard } from './BoardTableCard'
interface Props {
  width?: number
  height?: number
  onPress?: () => void
}

const EditedTableCardEmpty = ({ width, height, onPress }: Props) => {
  const [isHover, setIsHover] = React.useState(false)
  const [isSelected, setIsSelected] = React.useState(false)
  return (
    <Pressable
      style={[
        styleTableCard.cardContainer,
        styles.container,
        isSelected && styles.containerFocus,
        { width },
        { height },
      ]}
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => {
        setIsHover(false)
        setIsSelected(false)
      }}
      onPress={() => {
        setIsSelected(true)
        onPress && onPress()
      }}
    >
      {isHover && (
        <View style={styles.add}>
          <XEIcon name="plus" color="gray.g500" size={16} />
          <Text style={[styleTableCard.nameText, styles.txt]}>테이블 추가</Text>
        </View>
      )}
    </Pressable>
  )
}

export default EditedTableCardEmpty

const styles = StyleSheet.create({
  container: {
    borderColor: '#DCE3EC',
    backgroundColor: '#EEF2F8',
    padding: 2,
  },
  containerUsing: {
    backgroundColor: COLOR.mono.lightGray,
  },
  containerFocus: {
    borderColor: COLOR.sub.lightBlue,
  },
  add: {
    flex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
    borderRadius: 8,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: '#DCE3EC',
    justifyContent: 'center',
    alignItems: 'center',
  },
  txt: {
    color: COLOR.gray.g500,
    marginTop: 4,
  },
})
