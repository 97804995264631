import React, { ForwardedRef, forwardRef, memo } from 'react'
import type { SvgProps } from 'react-native-svg'
import { Path, Svg } from 'react-native-svg'

interface Props extends SvgProps {
  borderColor?: string
}

const ProgressIcon = (
  { borderColor = '#B4BEC5', ...props }: Props,
  ref: ForwardedRef<Svg>,
) => {
  return (
    <Svg
      width={18}
      height={15}
      viewBox="0 0 18 15"
      fill="none"
      ref={ref}
      {...props}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V12C1.5 12.8284 2.17157 13.5 3 13.5H15C15.8284 13.5 16.5 12.8284 16.5 12V3C16.5 2.17157 15.8284 1.5 15 1.5ZM3 0C1.34315 0 0 1.34315 0 3V12C0 13.6569 1.34315 15 3 15H15C16.6569 15 18 13.6569 18 12V3C18 1.34315 16.6569 0 15 0H3Z"
        fill={borderColor}
      />
      <Path d="M4 3.5H7V5.5H4V3.5Z" fill={borderColor} />
      <Path d="M4 7H14V8.5H4V7Z" fill={borderColor} />
      <Path d="M4 10H14V11.5H4V10Z" fill={borderColor} />
    </Svg>
  )
}

export default memo(forwardRef(ProgressIcon))
