import { View } from '@rocket-mono/foundations'
import { Avatar, Text } from '@rui/atoms'
import { COLOR, ClickOutside } from '@rui/foundations'
import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import { cellStyles } from '.'
import { ProfileSvg } from './ProfileSvg'

interface WorkMemberTableUserProfileItemType {
  isDelete?: boolean
  profileImage: string
  userName: string
}
const WorkMembersTableUserProfileItem = ({
  isDelete = false,
  profileImage,
  userName,
}: WorkMemberTableUserProfileItemType) => {
  const [tooltip, setTooltip] = React.useState(false)

  return (
    <>
      <Pressable onPress={() => setTooltip(true)} style={[cellStyles.cells]}>
        <View style={[cellStyles.flexBox, styles.userBox, { paddingHorizontal: 0 }]}>
          <View style={styles.innerFlexBox}>
            {isDelete ? (
              <ProfileSvg size={28} />
            ) : (
              <Avatar
                // isLeader={isOwner}
                // size={28}
                size="xsmall"
                profileUrl={profileImage}
                userName={userName}
              />
            )}
          </View>
        </View>
      </Pressable>
      {tooltip && (
        <ClickOutside onClickOutside={() => setTooltip(false)}>
          <View style={{ position: 'absolute', top: 36, left: 2, flexDirection: 'row', zIndex: 100, borderRadius: 4 }}>
            <View
              style={{ backgroundColor: COLOR.mono.black, paddingHorizontal: 8, paddingVertical: 4, borderRadius: 4 }}
            >
              <Text fontName="txtXs" fontColor="mono.white">
                {userName}
              </Text>
            </View>
          </View>
        </ClickOutside>
      )}
    </>
  )
}

export default WorkMembersTableUserProfileItem

const styles = StyleSheet.create({
  userBox: {
    flexDirection: 'row',
  },
  innerFlexBox: {
    flex: 1,
    flexDirection: 'row',
  },
  innerCon: { flex: 1, marginLeft: 4, justifyContent: 'center' },
})
